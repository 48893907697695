import { useState } from 'react'

const usePager = () => {
  const [pages, setPages] = useState(0)
  const [pageSize, setPageSize] = useState(25)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [paged, setPaged] = useState([])

  const pager = (data, pageSize) => {
    setCurrentIndex(0)
    const _pages = Math.ceil(data.flat().length / pageSize)
    const arr = new Array(_pages).fill('')

    const result = arr.reduce((acc, cv, ci) => {
      const sliced =
        ci < _pages - 1
          ? data.flat().slice(ci * pageSize, (ci + 1) * pageSize)
          : data.flat().slice(ci * pageSize)
      return acc.concat([sliced])
    }, [])
    return result
  }

  const ssrPager = (data) => {
    const result = [...data]
    return result
  }

  const firstPage = (data) => {
    // const pagerResult = pager(data)
    const result = 10 //paged[0]
    setPaged(result)
  }

  const lastPage = (paged) => {
    const result = paged[paged.flat().length - 1]
    setPaged(result)
  }
  // const next = () => {}
  // const prev = () => {}

  return {
    firstPage,
    lastPage,
    // next,
    // prev,
    pager,
    pages,
    setPages,
    currentIndex,
    setCurrentIndex,
    paged,
    setPageSize,
    pageSize,
    setPaged,
    ssrPager,
  }
}
export default usePager
