const useObserver = () => {
  const startObserve = (elements, query) => {
    const exp = new RegExp(query, 'img')
    const _observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, idx) => {
          entry.target
            .querySelector('img')
            .classList.toggle('highlight', entry.isIntersecting)
          if (entry.isIntersecting) {
            _observer.unobserve(entry.target)
          }
        })
      },
      {
        threshold: 1,
        root: document.querySelector('.contents-scroll'),
      }
    )
    elements.forEach((entry) => {
      if (
        exp.test(entry.dataset.extracted) ||
        exp.test(entry.dataset.description) ||
        exp.test(entry.dataset.contributions)
      ) {
        _observer.observe(entry)
      }
    })
  }

  return { startObserve }
}
export default useObserver
