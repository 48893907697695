import React, { forwardRef, Fragment, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../../../../../styles/index.scss'
import CheckboxField from '../../../CheckboxField'

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = props.indeterminate
  }, [resolvedRef, props.indeterminate])

  return <CheckboxField id={props.id} ref={resolvedRef} {...props} />
})

IndeterminateCheckbox.defaultProps = {}

IndeterminateCheckbox.propTypes = {
  /**
   * Decsription of parameter
   */
  id: PropTypes.string,
  /**
   * Decsription of parameter
   */
  indeterminate: PropTypes.bool,
  /**
   * Decsription of parameter
   */
  ref: PropTypes.array,
}

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox'

export default IndeterminateCheckbox
