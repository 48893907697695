const useSort = () => {
  const sortAsc = (val, data) => {
    const result = data.sort((a, b) => {
      if (a[val] > b[val]) {
        return 1
      }

      if (a[val].value < b[val].value) {
        return -1
      }
      return 0
    })

    return result
  }

  const sortDesc = (val, data) => {
    const result = data.sort((a, b) => {
      if (a[val].value < b[val].value) {
        return 1
      }

      if (a[val].value > b[val].value) {
        return -1
      }
      return 0
    })
    return result
  }

  return {
    sortAsc,
    sortDesc,
  }
}
export default useSort
