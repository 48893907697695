import React, { Fragment, useContext } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import '../../styles/index.scss'
//Settings
import { SEARCH_HOST } from '../../config'
//Context
import UserContext from '../../contexts/UserContext'
//Components
import Avatar from '../Avatar/Avatar'
import CatalogSearch from '../CatalogSearch'
import Logo from '../Logo/Logo'
import { Button } from '../Button/Button'
import useScreenSize from '../../hooks/use-screen-size'
import useHeader from './use-header'
import FlyOutMenu, {
  FlyOutMenuBody,
  FlyOutMenuFooter,
} from '../FlyOutMenu/FlyOutMenu'

/**
 * A header helps users identify where they are and provides a quick,
 * organized way to reach the main sections of a website.
 *
 * USWDS: https://designsystem.digital.gov/components/header/
 *
 * DO:    It is recommended to include the "official government website"
 *        banner and a logo or site name.
 *
 * DO:    While a horizontal navigation bar is just one option for navigation design,
 *        it is one of the most visible and familiar ways of helping users navigate a site.
 */
const Header = () => {
  // Context
  const { loggedInUser, handleSetLoggedInUserId } =
    useContext(UserContext) || {}

  // Hooks
  /**
   * The navigation links are defined in the useHeader Hook
   * so they can be reused in both the mobile and regular menus
   */
  const {
    expanded,
    loggedInNavJSX,
    navJSX,
    notLoggedInNavJSX,
    setExpandedHandler,
  } = useHeader()
  const { screenSize } = useScreenSize()
  // extract pathname from location
  const { pathname } = useLocation()
  const displaySearch = pathname !== '/' && pathname !== '/advanced-search'
  return (
    <>
      <header
        className={[
          'bg-primary-darker',
          'display-flex',
          'flex-column',
          'overflow-hidden',
          'padding-x-1 desktop-lg:padding-x-4',
          'padding-y-1',
          'position-relative',
          'theme-primary-darker',
          'width-full',
        ].join(' ')}
        data-testid="nac-header"
        id="nac-header"
        role="banner"
      >
        <div
          className={[
            'display-flex',
            'flex-align-center',
            'flex-justify',
            screenSize === 'desktop' ? 'flex-gap-sm' : 'flex-gap-0',
            'desktop-lg:flex-gap-md',
            'flex-row',
          ].join(' ')}
        >
          {/** TO DO:
           *  ACCESSIBILITY: Skip Links.
           *  Include skip navigation links to allow users with
           *  screen readers to bypass long navigation lists.
           *  Make sure you include an id at the beginning of your
           *  main content and that it matches the skipnav link.
           *  (WebAIM: http://webaim.org/techniques/skipnav/)
           */}
          <div className={['margin-right-2'].join(' ')}>
            <Logo
              reduced={screenSize === 'mobile'} //pathname !== '/' &&
              layout="horizontal"
            ></Logo>
          </div>
          <div
            className={[
              'display-flex',
              'flex-column',
              'flex-grow-1',
              'margin-right-1',
            ].join(' ')}
          >
            {displaySearch &&
              (screenSize === 'desktop' || screenSize === 'tablet-lg') && (
                <CatalogSearch from="search" queryParam="query" />
              )}
          </div>
          {screenSize !== 'desktop' ? (
            <>
              <Button
                data-testid="nac-header--signup"
                iconName="menu"
                onClick={setExpandedHandler}
                reduced
                textOnly
                thin
                theme="white"
              >
                Menu
              </Button>
              {expanded && (
                <FlyOutMenu
                  hide={() => setExpandedHandler(false)}
                  id="main-menu"
                  title={
                    loggedInUser ? (
                      <div className="display-inline-flex flex-align-top flex-gap-sm flex-row">
                        <Avatar
                          onClick={() => setExpandedHandler(false)}
                          size="sm"
                          userName={loggedInUser.userName}
                        />
                        Hi, {loggedInUser.userName}
                      </div>
                    ) : (
                      ''
                    )
                  }
                  position={'right'}
                >
                  <FlyOutMenuBody id="main-menu">
                    <div
                      className={[
                        'display-flex',
                        'flex-align-center',
                        'flex-gap-sm',
                        'flex-column',
                        'padding-3',
                      ].join(' ')}
                      role="navigation"
                    >
                      <ol
                        className={[
                          'add-list-reset',
                          'display-flex',
                          'flex-justify-start',
                          'flex-gap-md',
                          'flex-column',
                          'width-full',
                        ].join(' ')}
                      >
                        {!loggedInUser ? (
                          <li
                            className={[
                              'border-bottom-1px',
                              'border-primary-dark',
                              'padding-bottom-3',
                            ].join(' ')}
                          >
                            <ol
                              className={[
                                'add-list-reset',
                                'display-flex',
                                'flex-align-center',
                                'flex-gap',
                                'flex-justify-start',
                                'flex-row',
                                'width-full',
                              ].join(' ')}
                            >
                              {notLoggedInNavJSX}
                            </ol>
                          </li>
                        ) : (
                          ''
                        )}
                        {navJSX}
                        {loggedInUser && (
                          <li
                            className={[
                              'border-top-1px',
                              'border-primary-dark',
                              'padding-y-3',
                            ].join(' ')}
                          >
                            <ol
                              className={[
                                'add-list-reset',
                                'display-flex',
                                'flex-align-center',
                                'flex-gap',
                                'flex-justify-start',
                                'flex-row',
                                'width-full',
                              ].join(' ')}
                            >
                              {loggedInNavJSX}
                            </ol>
                          </li>
                        )}
                      </ol>
                    </div>
                  </FlyOutMenuBody>
                  <FlyOutMenuFooter hide={() => setExpandedHandler(false)} />
                </FlyOutMenu>
              )}
            </>
          ) : (
            /**
             * ACCESSIBILITY: Use lists for your nav links
             *  Doing so enables screen readers to decipher header contents.
             */
            <div
              className={[
                'display-flex',
                'flex-align-center',
                'flex-gap-sm',
                'flex-row',
                // 'margin-left-auto',
              ].join(' ')}
              role="navigation"
            >
              <ol
                className={[
                  'add-list-reset',
                  'display-flex',
                  'flex-align-center',
                  'flex-gap-md',
                  'flex-row',
                ].join(' ')}
              >
                {navJSX}
              </ol>
              {/** TO DO: Should login buttons be part of the main navigation syntax? */}
            </div>
          )}
          <ol
            className={[
              'add-list-reset',
              'display-flex',
              'flex-align-center',
              'flex-gap-sm',
              'flex-row',
              'flex-shrink-0',
              'text-no-uppercase',
              'margin-left-2',
              'padding-left-2',
              'border-left-1px',
              'border-primary-dark',
            ].join(' ')}
          >
            {!loggedInUser ? (
              notLoggedInNavJSX
            ) : (
              <li
                className={[
                  'display-flex',
                  'flex-align-center',
                  'flex-row',
                  'flex-gap-xs',
                  screenSize !== 'desktop'
                    ? 'margin-left-05 margin-right-1'
                    : '',
                ].join(' ')}
              >
                <Avatar
                  isNaraStaff={loggedInUser.naraStaff === 1}
                  userName={loggedInUser.userName}
                />
                <div
                  className={[
                    'display-flex',
                    'flex-column',
                    'flex-gap-xs',
                    'margin-left-1',
                  ].join(' ')}
                >
                  <span
                    className={[
                      'display-block',
                      'text-white',
                      'font-sans-xs',
                    ].join(' ')}
                  >
                    {screenSize === 'desktop' && <>Welcome, </>}
                    {screenSize !== 'mobile' && (
                      <span className="text-bold">{loggedInUser.userName}</span>
                    )}
                  </span>
                  {screenSize === 'desktop' && loggedInNavJSX}
                </div>
              </li>
            )}
          </ol>
        </div>
        {displaySearch &&
          screenSize !== 'desktop' &&
          screenSize !== 'tablet-lg' && (
            <div className="tablet:margin-x-4">
              <CatalogSearch from="search" queryParam="query" />
            </div>
          )}
      </header>
    </>
  )
}

export default Header
