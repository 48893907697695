import React, { useContext } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import '../../../styles/index.scss'
import StatCards, { StatCard } from '../stat_cards'
import AccountContent, { ContentTitle } from '../account_content'
import AccountContext from '../../../contexts/AccountContext'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
import { ActivityFeed } from './activity_feed'

const AccountOverview = () => {
  return (
    <AccountContent>
      <ContentTitle iconName="activity" title="Contributions" />
      {/* <StatTotals />*/}
      <ActivityFeed />
    </AccountContent>
  )
}
export default AccountOverview

export const StatTotals = ({ ...props }) => {
  const { feedPage, userName } = useParams()
  const {
    contributionTotals,
    errorTotals: error,
    loadingTotals: loading,
  } = useContext(AccountContext) || {}

  if (!userName) return false

  return (
    <StatCards>
      {overviewSchema.map(({ field, icon, label, route }) => (
        <StatCard
          active={!feedPage ? route === '' : feedPage === route}
          className="height-full  text-normal"
          iconName={icon}
          key={route}
          label={
            parseInt(contributionTotals?.totals?.[field]) > 1 ||
            parseInt(contributionTotals?.totals?.[field]) === 0
              ? label[1]
              : label[0]
          }
          loading={loading}
          to={{
            /**
             * TO DO: Fix this!
             * We shouldn't have to build out this  path,
             * but just including "route" produces unexpected URL behavior.
             */
            pathname: `/accounts/${userName}/${route}`,
          }}
          stat={returnNumberWithCommas(
            // state?.contributionTotals?.totals?.[field] ||
            contributionTotals?.totals?.[field] || 0
          )}
        />
      ))}
    </StatCards>
  )
}
StatTotals.propTypes = {
  context: PropTypes.object,
}

export const overviewSchema = [
  {
    field: 'totalContributions',
    icon: 'activity',
    label: ['Contribution', 'Total Contributions'],
    route: '',
  },
  {
    field: 'totalTags',
    icon: 'tag',
    label: ['Tag', 'Total Tags'],
    route: 'tags',
  },
  {
    field: 'totalComments',
    icon: 'comment',
    label: ['Comment', 'Total Comments'],
    route: 'comments',
  },
  {
    field: 'totalTranscriptions',
    icon: 'transcription',
    label: ['Transcription', 'Total Transcriptions'],
    route: 'transcriptions',
  },
  /* {
    icon: 'text',
    label: ['Unique Record', 'Unique Records'],
    route: 'records',
  },*/
]
