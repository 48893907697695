import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const useSortDropdown = (schema) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const current = searchParams.get('sort')

  const [active, setActive] = useState(current)

  const updateValueHandler = (value) => {
    if (schema.filter((s) => s.value === value && s.default).length > 0) {
      searchParams.delete('sort')
      setSearchParams(searchParams)
    } else {
      searchParams.set('sort', value)
      setSearchParams(searchParams)
    }
  }

  const changeHander = (event) => {
    let value = event.target.value
    setActive(value)
    updateValueHandler(value)
  }

  return { active, changeHander }
}

export default useSortDropdown
