/**
 * Schema for validating comment inputs
 */
export const commentInputSchema = {
  autoComplete: 'off',
  id: 'addComment',
  label: 'comment',
  loggedIn: true,
  maxlength: 750,
  placeholder: 'Join the conversation',
  required: true,
  srText: 'Comment input field',
  type: 'text',
}
