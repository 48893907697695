import { useEffect, useState } from 'react'
import useHttp from '../../hooks/use-http'
import { SEARCH_URL } from '../settings/globals'

const useOnlineAvailability = (record) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [availability, setAvailability] = useState(null)
  const [counts, setCounts] = useState({})

  useEffect(() => {
    if (!record) return false
    const abortController = new AbortController()
    if (record?.naId) {
      setLoading(true)
      availabilityRequestHandler(abortController)
    }
    return () => {
      setCounts(null)
      abortController.abort()
    }
  }, [record])

  const availabilityErrorHandler = (error) => {
    if (error) {
      const abortController = new AbortController()
      childObjectsRequestHandler(abortController)
      setError(error)
    }
  }

  const childObjectsErrorHandler = (error) => {
    if (error) setError(error)
  }

  const availabilityResponseHandler = (results) => {
    let result = results?.[0]?.availability
    if (!result && !record?.digitalObjects) {
      const abortController = new AbortController()
      childObjectsRequestHandler(abortController)
    } else {
      setAvailability(result)
      setLoading(false)
    }
  }

  const { sendRequest: availabilityRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/online-availability/naId/${record?.naId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    availabilityResponseHandler,
    availabilityErrorHandler
  )

  const childObjectsResponseHandler = (results) => {
    const digitalObjectCount =
      results.body.aggregations.digitalObjectCount.value
    const levelOfDescriptionCounts =
      results.body.aggregations.levelOfDescription.buckets
    if (digitalObjectCount > 0) {
      let levels = {}
      levelOfDescriptionCounts.map(
        (level) => (levels[level.key] = level.doc_count)
      )
      setCounts({ digitalObjects: digitalObjectCount, ...levels })
      setAvailability('partiallyDigitized')
    } else {
      setAvailability('notDigitized')
    }
  }

  const { sendRequest: childObjectsRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?q=record.ancestors.naId:${record?.naId}&availableOnline=true&digitalObjectCount=true`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    childObjectsResponseHandler,
    childObjectsErrorHandler
  )

  return {
    availability,
    counts,
  }
}
export default useOnlineAvailability
