import { useContext, useEffect, useState } from 'react'
import { useLocation, useOutletContext, useParams } from 'react-router-dom'
//Context
import AccountContext from '../../../contexts/AccountContext'
import UserContext from '../../../contexts/UserContext'
import {
  compareNumbers,
  compareStrings,
} from '../../../helper-functions/sort-functions'
import useHttp from '../../../hooks/use-http'
import { SEARCH_URL } from '../../settings/globals'

const useUniqueTags = () => {
  //Context
  const { account, contributionTotals, uniqueTags, setUniqueTags } =
    useContext(AccountContext) || {}

  //State
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [lastRequestedTimestamp, setLastRequestedTimestamp] = useState(null)
  const [latestContributionTimestamp, setLatestContributionTimestamp] =
    useState(null)

  useEffect(() => {
    if (account?.userId) {
      const abortController = new AbortController()

      //If no totals have been queried yet, or the page is refreshed, check for latest totals
      if (
        !loading &&
        contributionTotals?.totals?.totalContributions >= 0 &&
        (uniqueTags?.userName !== account.userName || !uniqueTags)
      ) {
        setLoading(true)
        requestUniqueTagsCountsHandler(abortController)
      }
      return () => {
        setLoading(false)
        abortController.abort()
      }
    }
  }, [account, contributionTotals])

  const errorHandler = (error) => {
    setError('error', error)
  }

  const handleUniqueTagsCountsResponse = (results) => {
    setUniqueTags({
      uniqueTags: results,
      userName: account.userName,
    })
    setLoading(false)
  }

  const { sendRequest: requestUniqueTagsCountsHandler } = useHttp(
    {
      url: `${SEARCH_URL}/contributions/userId/${account?.userId}?report=uniqueCounts`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleUniqueTagsCountsResponse,
    errorHandler
  )

  const [sortValue, setSortValue] = useState('count:desc')

  const handleOnSortChange = (event) => {
    let value = event.target.value
    setSortValue(value)
    setLoading(true)
    try {
      switch (value) {
        case 'alpha:asc':
          uniqueTags?.uniqueTags.sort((a, b) => {
            return compareStrings(a.contribution, b.contribution)
          })
          break
        case 'alpha:desc':
          uniqueTags?.uniqueTags.sort((a, b) => {
            return compareStrings(a.contribution, b.contribution, false)
          })

          break
        case 'count:asc':
          uniqueTags?.uniqueTags
            .sort((a, b) => {
              return compareStrings(a.contribution, b.contribution)
            })
            .sort((a, b) => {
              return compareNumbers(a.count, b.count, false)
            })
          break
        case 'count:desc':
          uniqueTags?.uniqueTags
            .sort((a, b) => {
              return compareStrings(a.contribution, b.contribution)
            })
            .sort((a, b) => {
              return compareNumbers(a.count, b.count)
            })
          break
      }
    } catch (error) {
      setError(error)
      setLoading(false)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, [500])
    }
  }

  return { error, handleOnSortChange, loading, sortValue, uniqueTags }
}

export default useUniqueTags
