import { useState } from 'react'
import useEventListener from './use-event-listener'

const useHovered = (mouseMoveRef, buttonRef) => {
  const [active, setActive] = useState(false)

  const handleSetFocused = () => {
    if (!buttonRef || !buttonRef.current) return false
    if (buttonRef.current === document.activeElement) setActive(true)
    else setActive(false)
  }
  useEventListener('focusin', handleSetFocused)
  useEventListener('focus', handleSetFocused)

  const handleSetHovered = (event) => {
    if (!mouseMoveRef || !mouseMoveRef.current) return false
    if (mouseMoveRef.current.contains(event.target)) setActive(true)
    else setActive(false)
  }
  useEventListener('mousemove', handleSetHovered)

  return {
    active,
  }
}
export default useHovered
