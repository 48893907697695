import React, { Fragment, useEffect, useContext, useState } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../contexts/RecordContext'
import SearchContext from '../../contexts/SearchContext'
//Components
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import Highlighter from '../Highlighter/Highlighter'
//Helpers
import { returnIconAndLabelFromString } from '../../helper-functions/metadata-functions'
import { returnHumanDate } from '../../helper-functions/date-functions'
import { IconLabel } from '../IconLabel/IconLabel'
import { SEARCH_URL } from '../settings/globals'
import useHttp from '../../hooks/use-http'
import { returnMetaArrayLabels } from '../Templates/Results/result-ids'
import useDetectPrinting from '../../hooks/use-detect-printing'

const SectionPartOfCard = ({ ancestor, index, ...props }) => {
  const { controlGroup, record } = useContext(RecordContext) || {}
  const { activeQueryParams } = useContext(SearchContext) || {}
  const [variantData, setVariantData] = useState([])

  const { isPrinting } = useDetectPrinting()

  const {
    q,
    recordGroupNumber: rgn,
    collectionIdentifier: ci,
  } = activeQueryParams || {} //If a RG Number or Collection ID is specified, highlight it here
  const recordGroupNumber = rgn?.replace(/\s/g, '').split(',') || []
  const collectionIdentifier = ci?.replace(/\s/g, '').split(',') || []

  const handleResponse = (response) => {
    if (response.body.hits.hits[0]?._source?.record?.variantControlNumbers) {
      let numbers = []

      response.body.hits.hits[0]?._source?.record?.variantControlNumbers.map(
        (num) => {
          if (num.type === 'HMS/MLR Entry Number') numbers.push(num.number)
        }
      )
      setVariantData(numbers)
    }
  }

  const { sendRequest } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?naId_is=${ancestor.naId}&includeExtractedText=true`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleResponse
  )

  useEffect(() => {
    if (ancestor.levelOfDescription === 'series') sendRequest()
  }, [ancestor])

  return (
    // <Fragment key={ancestor.naId + index}>
    <Fragment>
      <div
        className="display-flex flex-column grid-col flex-align-stretch"
        data-testid={`nac-description__from--${ancestor.levelOfDescription}`}
      >
        <div className="usa-card__header padding-0">
          <IconLabel
            iconName={
              returnIconAndLabelFromString(ancestor.levelOfDescription).icon
            }
            color="base-darker"
          >
            {ancestor.levelOfDescription == 'recordGroup' ? (
              <span data-testid="nac-result_ancestor-number">
                Record Group{' '}
                <span>
                  <Highlighter
                    exact={recordGroupNumber.includes(
                      ancestor.recordGroupNumber?.toString()
                    )}
                    search={
                      recordGroupNumber.includes(
                        ancestor.recordGroupNumber?.toString()
                      )
                        ? ''
                        : q
                    }
                  >
                    {ancestor.recordGroupNumber}
                  </Highlighter>
                </span>
              </span>
            ) : ancestor.levelOfDescription == 'collection' ? (
              <span data-testid="nac-result_ancestor-number">
                Collection{' '}
                <span>
                  <Highlighter
                    exact={collectionIdentifier.includes(
                      ancestor.collectionIdentifier?.toString()
                    )}
                    search={
                      collectionIdentifier.includes(
                        ancestor.collectionIdentifier?.toString()
                      )
                        ? ''
                        : q
                    }
                  >
                    {ancestor.collectionIdentifier}
                  </Highlighter>
                </span>
              </span>
            ) : (
              ancestor.levelOfDescription
            )}
          </IconLabel>
          <h2
            className="usa-card__heading font-sans-md line-height-sans-2 padding-x-0 padding-top-1 padding-bottom-3"
            data-testid="nac-result_ancestor-title"
          >
            <Highlighter search={q}>{ancestor.title}</Highlighter>
          </h2>
        </div>
        <div className="usa-card__body padding-x-0 padding-bottom-3">
          <div className="width-full margin-bottom-1 font-sans-xs text-base text-bold">
            {ancestor.inclusiveStartDate
              ? returnHumanDate(ancestor.inclusiveStartDate)
              : '?'}{' '}
            {' – '}
            {ancestor.inclusiveEndDate
              ? returnHumanDate(ancestor.inclusiveEndDate)
              : '?'}
          </div>
          {/* TO DO: Enable if NAIDs need to be displayed */
          /*returnMetaArrayLabels([ancestor.naId], '', 'NAID', false, false)*/}
          {variantData.length > 0 &&
            returnMetaArrayLabels(
              variantData,
              '',
              'HMS/MLR',
              isPrinting,
              false
            )}
        </div>
        <div className="usa-card__footer padding-0">
          <Button
            size="3xs"
            outline
            aria-label={`Explore this ${
              returnIconAndLabelFromString(ancestor.levelOfDescription).label
            }`}
            data-testid={`nac-partOf-link--${ancestor.naId}`}
            to={{
              pathname: `/id/${ancestor.naId}`,
              state: {
                controlGroup: controlGroup,
                // from: { pathname: `/id/${record.naId}` },
              },
            }}
          >
            Explore this{' '}
            {returnIconAndLabelFromString(ancestor.levelOfDescription).label}
          </Button>
        </div>
      </div>
      {index < record.ancestors.length - 1 && (
        <div className="flex-column grid-col-auto margin-x-1">
          <Icon color="base-dark" iconName="doublerightarrow" />
        </div>
      )}
    </Fragment>
  )
}

SectionPartOfCard.propTypes = {
  ancestor: PropTypes.object,
  index: PropTypes.number,
}

export default SectionPartOfCard
