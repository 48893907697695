import React, { forwardRef, useEffect, useState } from 'react'
import '../../styles/index.scss'
//Prop Types
import PropTypes from 'prop-types'
import { inputOptionsSchemaPropTypes } from '../../schemas/input-schemas/input-schema-prop-types'
//Components
import { Icon } from '../Icon/Icon'
// import { SectionH2 } from '../Headings/section_h2'
import Select from './select'
import FormField from './FormField'
import DateInputField from './date_input_field'

/**
 * The Range field displays two inputs which represent a range.
 * The starting input should be first in the schema options array, followed by the ending input.
 * It is possible to submit the starting input alone unless both options are otherwise
 * specified as required in the options schema.
 * Typically these fields should both be of the same type. Examples include:
 *    - Date Range using a user-entered date input
 *    - Congress Range using select inputs with prepopulated options
 * Ranges are compared by a numeric value to determine which comes after the other.
 */

const RangeField = forwardRef(
  ({ innerRef, linked, onChange = () => {}, schema, ...props }, ref) => {
    if (schema.type !== 'range') return false

    const startOptions = schema?.options[0] || []
    const endOptions = schema?.options[1] || []

    const [startRange, setStartRange] = useState('')
    const [endRange, setEndRange] = useState('')
    const [endSchema, setEndSchema] = useState(endOptions)

    useEffect(() => {
      if (!linked) return false
      if (!startRange) {
        setEndSchema(endOptions)
      } else {
        //This method prevents mutation of the original schema object
        const newOptions = endOptions.options.filter((option) => {
          return parseInt(option.value) >= startRange
        })
        const newEndSchema = { ...endOptions, options: newOptions }
        setEndSchema(newEndSchema)

        if (!endRange || endRange < startRange)
          endRangeChangeHandler(null, startRange)
      }
    }, [startRange])

    const startRangeChangeHandler = (event, value) => {
      const num = parseInt(value) || null
      setStartRange(num)
      onChange(startOptions.id, num)
    }

    const endRangeChangeHandler = (event, value) => {
      const num = parseInt(value)
      setEndRange(num)
      onChange(endOptions.id, num)
    }

    // useEffect(() => {
    //   if (linked && !endRange) onChange(endOptions.id, startRange)
    // }, [startRange])

    const reset = () => {
      setStartRange(null)
    }

    return (
      <div
        className={[
          'tablet-lg:display-flex',
          'flex-align-start',
          'flex-row',
          'flex-gap-sm',
        ].join(' ')}
        role="main"
      >
        <div
          className={['display-flex', 'flex-column', 'width-full'].join(' ')}
        >
          {startOptions.label && <h3>{startOptions.label}</h3>}
          {startOptions.type === 'select' && (
            <Select
              onChange={startRangeChangeHandler}
              schema={startOptions.options}
              search={startOptions.searchable}
              selected={
                startOptions.options.filter((s) => s.id === String(startRange))
                  .length > 0
                  ? startOptions.options.filter(
                      (s) => s.id === String(startRange)
                    )
                  : startOptions.options.filter((s) => s.isPlaceholder)
              }
            />
          )}
          {startOptions.type === 'date' && (
            <DateInputField
              // onChange={startRangeChangeHandler}
              schema={startOptions}
              // search={startOptions.searchable}
              // selected={
              //   startOptions.options.filter((s) => s.id === String(startRange))
              //     .length > 0
              //     ? startOptions.options.filter(
              //         (s) => s.id === String(startRange)
              //       )
              //     : startOptions.options.filter((s) => s.isPlaceholder)
              // }
            />
          )}
        </div>
        <span
          className={[
            'display-flex',
            'flex-align-center',
            'tablet-lg:height-5',
            'tablet-lg:margin-top-3',
          ].join(' ')}
        >
          <Icon
            color={!startRange ? 'base-light' : 'base-dark'}
            iconName="bullet-hyphen"
            iconSize="xs"
          />
        </span>
        <div
          className={['display-flex', 'flex-column', 'width-full'].join(' ')}
        >
          {endOptions.label && <h3>{endOptions.label}</h3>}
          {endOptions.type === 'select' && (
            <Select
              disabled={!startRange}
              onChange={endRangeChangeHandler}
              schema={endSchema.options}
              search={endOptions.searchable}
              selected={
                endOptions.options.filter((s) => s.id === String(endRange))
                  .length > 0
                  ? endOptions.options.filter((s) => s.id === String(endRange))
                  : endOptions.options.filter((s) => s.isPlaceholder)
              }
            />
          )}
          {endOptions.type === 'date' && (
            <DateInputField
              // onChange={startRangeChangeHandler}
              schema={endOptions}
              // search={startOptions.searchable}
              // selected={
              //   startOptions.options.filter((s) => s.id === String(startRange))
              //     .length > 0
              //     ? startOptions.options.filter(
              //         (s) => s.id === String(startRange)
              //       )
              //     : startOptions.options.filter((s) => s.isPlaceholder)
              // }
            />
          )}
        </div>
      </div>
    )
  }
)

RangeField.defaultProps = {
  linked: true,
}

RangeField.propTypes = {
  /**
   * Specific ref to use which bypasses forward ref error
   */
  innerRef: PropTypes.func,
  /**
   * Determines whether the end options update dynamically based on the selected start value
   * If false, the start and end options will remain static throughout the interaction
   * If true, the end options will adjust to reflect changes to the start input
   */
  linked: PropTypes.bool,
  /**
   * Handles the updated values in the larger form
   */
  onChange: PropTypes.func,
  /**
   * Defined data used to build and validate the range select component
   */
  schema: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    options: inputOptionsSchemaPropTypes,
    reset: PropTypes.bool,
    sr: PropTypes.string,
    type: PropTypes.string,
  }),
}

RangeField.displayName = 'RangeField'
export default RangeField
