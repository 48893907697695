import React from 'react'
import PropTypes from 'prop-types'
import { description } from '../../settings/RecordPropTypes'
import '../../../styles/index.scss'

import { Icon } from '../../Icon/Icon'
import { IconLabel } from '../../IconLabel/IconLabel'
import Highlighter from '../../Highlighter/Highlighter'
import { useSearchParams } from 'react-router-dom'

const Ancestors = ({ record, recordGroup, series, ...props }) => {
  if (!record || !record?.ancestors) return ''

  const [searchParams] = useSearchParams()
  const q = searchParams?.get('q') || ''
  //If a RG Number or Collection ID is specified, highlight it here
  const recordGroupNumber =
    searchParams?.get('recordGroupNumber')?.replace(/\s/g, '').split(',') || []
  const collectionIdentifier =
    searchParams?.get('collectionIdentifier')?.replace(/\s/g, '').split(',') ||
    []

  const returnSeries = () => {
    let group = {}
    record.ancestors?.map((ancestor) => {
      if (ancestor.levelOfDescription == 'series')
        Object.assign(group, ancestor)
    })
    if (Object.keys(group).length === 0) return false
    return (
      <div className={['display-flex', 'flex-row', 'width-full'].join(' ')}>
        <IconLabel
          align="start"
          className="width-full"
          color="base-dark"
          iconName={recordGroup ? 'direct-child' : 'box'}
          iconPercent="70"
          iconSize="sm"
          size="2xs"
        >
          <span
            className={[
              'display-flex',
              'flex-align-center',
              'flex-gap-xs',
              'flex-row',
              'padding-right-4',
              'width-full',
            ].join(' ')}
          >
            {recordGroup && (
              <Icon color="base-dark" iconName="box" iconPercent="70" />
            )}
            Series:{' '}
            <strong className="ellipsed width-full">
              <span data-testid="nac-result_ancestor-series-title">
                <Highlighter search={q}>{group.title}</Highlighter>
              </span>
            </strong>
          </span>
        </IconLabel>
      </div>
    )
  }

  const returnRecordGroup = () => {
    let group = {}
    record?.ancestors?.map((ancestor) => {
      if (
        ancestor.levelOfDescription == 'recordGroup' ||
        ancestor.levelOfDescription == 'collection'
      )
        Object.assign(group, ancestor)
    })
    if (Object.keys(group).length === 0) return false

    return (
      <IconLabel
        align="start"
        color="base-dark"
        iconName="boxes"
        iconPercent="70"
        iconSize="sm"
        size="2xs"
      >
        <span className="margin-top-05">
          {group.levelOfDescription == 'recordGroup'
            ? 'Record Group '
            : 'Collection '}
          <strong>
            <span data-testid="nac-result_ancestor-number">
              <Highlighter
                exact={
                  recordGroupNumber.includes(
                    group.recordGroupNumber?.toString()
                  ) || collectionIdentifier.includes(group.collectionIdentifier)
                }
                search={
                  recordGroupNumber.includes(
                    group.recordGroupNumber?.toString()
                  ) || collectionIdentifier.includes(group.collectionIdentifier)
                    ? ''
                    : q
                }
              >
                {group.recordGroupNumber?.toString() ||
                  group.collectionIdentifier}
              </Highlighter>
            </span>
            :{' '}
            <span data-testid="nac-result_ancestor-title">
              <Highlighter search={q}>{group.title}</Highlighter>
            </span>
          </strong>
        </span>
      </IconLabel>
    )
  }

  return (
    <>
      <div className="flex-row grid-row">
        {recordGroup && returnRecordGroup()}
        {series && returnSeries()}
      </div>
    </>
  )
}

Ancestors.defaultProps = {
  recordGroup: false,
  series: true,
}

Ancestors.propTypes = {
  /**
   * The entire data object for a single record
   */
  record: description,
  /**
   * When true, includes the parent Record Group in the display
   */
  recordGroup: PropTypes.bool,
  /**
   * When true, includes the parent Series in the display
   */
  series: PropTypes.bool,
}

export default Ancestors
