import React, { useContext, useEffect, useRef, useState } from 'react'
import '../../styles/index.scss'
//Context
import ContributionContext from '../../contexts/ContributionContext'
import ObjectSearchContext from '../../contexts/ObjectSearchContext'
import SearchContext from '../../contexts/SearchContext'
//Components
import ContributionPanel from './contribution_panel'
import Pagination from '../utilities/Pagination'
import Truncate from '../utilities/Truncate'
import { Button } from '../Button/Button'
import { ContentsPanel } from './contents_panel'
import { ContributionBar } from './contribution_bar'
import { DownloadButton } from '../Button/variants/DownloadButton'
import { Icon } from '../Icon/Icon'
import { ListSeparator } from '../ListSeparator/ListSeparator'
import { ObjectPreview, returnFileFormat } from './object_preview'
import { ObjectTitle } from './object_title'
import { SEARCH_URL } from '../settings/globals'
//Helpers
import RecordContext from '../../contexts/RecordContext'
import useScreenSize from '../../hooks/use-screen-size'
import useUrlParams from '../../hooks/use-url-params'
import { useLocation } from 'react-router-dom'
import useInteractOverlay from './use-interact-overlay'
import UserContext from '../../contexts/UserContext'
import { ButtonAdminObjectOptions } from '../Button/variants/ButtonAdminObjectOptions'
import { imageTypes } from '../../helper-functions/metadata-functions'
import ListPanel from '../list-panel/list-panel'

const DigitalObjects = ({ ...props }) => {
  //Contexts
  const {
    activeObjectType,
    contentsPanel,
    filteredObjects,
    handleSetActiveObjectPage,
    handleSetActivePanel,
    handleSetContentsPanel,
    objectId,
    record,
  } = useContext(RecordContext)

  const { loadingContributions } = useContext(ContributionContext) || {}
  const { query, results } = useContext(ObjectSearchContext)
  const { searchQuery } = useContext(SearchContext) || {}
  const { accountsAccessArray } = useContext(UserContext) || {}
  const { state } = useLocation()

  const [transcriptionData, setTranscriptionData] = useState([])

  const [objectSearchFocus, setObjectSearchFocus] = useState(false)
  /* On initial load, set objectSearchFocus to true if there is an object search query */
  useEffect(() => {
    if (searchQuery) setObjectSearchFocus(true)
  }, [])
  const { screenSize } = useScreenSize()

  const objects = record?.digitalObjects
  if (!objects || objects?.length == 0) {
    return false
  }

  const objectData =
    filteredObjects &&
    filteredObjects.filter((item) => parseInt(item.objectId) === objectId)

  const loadTranscriptions = (transcription, s3Url) => {
    setTranscriptionData(transcription)
  }

  const getTranscriptionData = (s3Url) => {
    const cachedItem = JSON.parse(sessionStorage.getItem(s3Url))
    if (cachedItem) {
      loadTranscriptions(cachedItem, s3Url)
      return cachedItem
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ s3Url }),
    }
    fetch(`${SEARCH_URL}/fetch-coords`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          loadTranscriptions(data, s3Url)
          window.sessionStorage.setItem(s3Url, JSON.stringify(data))
        }
      })
      .catch(() => {
        console.log('Image may not have valid transcription data.')
      })
  }

  const objectUrl = objectData[0]?.objectUrl

  useEffect(() => {
    getTranscriptionData(objectUrl)
    // this will be the cachine mechanism but for now leaving this out since large data breaks this
    // if (localStorage.getItem(objectUrl)) {
    //   console.log('cache', JSON.parse(localStorage.getItem(objectUrl)))
    //   getTranscriptionData(JSON.parse(localStorage.getItem(objectUrl)))
    // } else {
    //   getTranscriptionData(objectUrl)
    // }
  }, [objectUrl])

  const { averageConfidenceScore } = transcriptionData

  //References
  // const objectSearchInputRef = useRef(null)
  // const objectSearchResultsRef = useRef(null)
  const contributionPanel = useRef(null)

  // const handleObjectSearchFocus = (event) => {
  //   if (!objectSearchInputRef?.current && !objectSearchResultsRef?.current)
  //     return false
  //   let focused = objectSearchFocus
  //   const clicked =
  //     (objectSearchInputRef?.current &&
  //       objectSearchInputRef?.current?.contains(event.target)) ||
  //     (objectSearchResultsRef?.current &&
  //       objectSearchResultsRef?.current?.contains(event.target))
  //   if (!objectSearchFocus && clicked) {
  //     focused = true
  //   }
  //   if (objectSearchFocus && !clicked) {
  //     focused = false
  //   }
  //   setObjectSearchFocus(focused)
  // }

  // useEffect(() => {
  //   window.addEventListener('click', handleObjectSearchFocus, true)
  //   return () => {
  //     window.removeEventListener('click', handleObjectSearchFocus, false)
  //   }
  // }, [])

  const handleObjectSearchResultClick = (type, page) => {
    handleSetActiveObjectPage(page)
    handleSetActivePanel(type)
    setObjectSearchFocus(false)
    handleSetContentsPanel(false)
    contributionPanel?.current
  }

  // Get current params and param functions
  const { params, removeParams, updateParams } = useUrlParams(state)
  const page = parseInt(params['objectPage']) || 1

  const nextObjectPageHandler = () => {
    if (!page || page === filteredObjects?.length) {
      handleSetActiveObjectPage(1)
      updateParams({
        objectPage: 1,
      })
    } else {
      handleSetActiveObjectPage(page + 1)
      updateParams({
        objectPage: page + 1,
      })
    }
  }

  const prevObjectPageHandler = () => {
    if (page === 1 || !page) {
      handleSetActiveObjectPage(filteredObjects?.length)
      updateParams({
        objectPage: filteredObjects?.length,
      })
    } else {
      handleSetActiveObjectPage(page - 1)
      updateParams({
        objectPage: page - 1,
      })
    }
  }

  const setObjectPageHandler = (page) => {
    handleSetActiveObjectPage(page)
    updateParams({
      objectPage: page,
    })
  }

  const { interact, preview, setInteractHandler } = useInteractOverlay()

  return filteredObjects && filteredObjects?.length > 0 && page ? (
    <div
      className={[
        'digital-objects',
        'object-view__wrapper',
        'no-print',
        //
        'bg-base-darkest',
        'flex-no-wrap',
        'flex-row',
        'grid-row',
        'minh-mobile-lg',
        'position-relative',
        'theme-base-darkest',
        'overflow-hidden',
      ].join(' ')}
      id="preview"
      ref={preview}
    >
      {contentsPanel && screenSize == 'mobile' ? (
        ''
      ) : (
        <div
          className={['flex-column', 'minw-0', 'width-full'].join(' ')}
          style={{
            width: `${
              contentsPanel && screenSize === 'tablet' ? '80%' : '100%'
            }`,
          }}
        >
          <div
            className={[
              'border-base-darker',
              'border-bottom-1px',
              'flex-align-center',
              'flex-justify',
              'flex-gap',
              'flex-no-wrap',
              'flex-row',
              'grid-row',
              'minh-button',
              'padding-bottom-0',
              'padding-top-0',
              'padding-x-2',
              'text-base-lighter',
            ].join(' ')}
          >
            <div className="width-full">
              {filteredObjects?.filter(({ contributions }) => contributions)
                .length > 0 && (
                <>
                  {/*
                    <ObjectSearch
                      focused={objectSearchFocus}
                      objects={filteredObjects}
                      onFocus={handleObjectSearchFocus}
                      //parentId={parentId}
                      ref={objectSearchInputRef}
                    />
                */}
                </>
              )}
            </div>
            <div
              className={[
                'display-flex',
                'flex-gap-xs',
                'flex-justify-end',
                'flex-row',
                'width-full',
              ].join(' ')}
            >
              {accountsAccessArray.accessGrantedToModeratorTools && (
                <ButtonAdminObjectOptions data-testid="nac-description--options" />
              )}
              {filteredObjects?.length > 1 && screenSize == 'mobile' && (
                <Button
                  aria-label="Access more details about these objects."
                  data-testid="nac-objects--toggle-details-button"
                  iconName="plus"
                  iconSize="xs"
                  onClick={() => handleSetContentsPanel()}
                  reduced
                  size="2xs"
                  textOnly
                  theme="base-lighter"
                  thin
                >
                  Show Details
                </Button>
              )}
            </div>
          </div>
          <div className="object-view__preview flex-row grid-row">
            {results?.length > 0 && objectSearchFocus ? (
              <ul
                // ref={objectSearchResultsRef}
                className={[
                  'preview-results',
                  'contents-scroll',
                  //
                  'bg-base-darkest-opacity-90',
                  'border-base-darker',
                  'border-right-1px',
                  'display-flex',
                  'flex-align-start',
                  'flex-column',
                  'flex-justify-start',
                  'grid-col-2',
                  'height-full',
                  //'left-0',
                  'overflow-y-scroll',
                  'padding-1',
                  'add-list-reset',
                  //'position-absolute',
                  'text-base-lightest',
                  'text-center',
                  //'top-0',
                  'width-card-lg',
                  'z-100',
                ].join(' ')}
              >
                {(results && results.length > 0) || query ? (
                  <>
                    {results.map((result, index) => (
                      <li
                        key={result.contributionId}
                        className={[
                          `${
                            index < results.length - 1
                              ? 'border-bottom border-base-darker'
                              : ''
                          }`,
                          'width-full',
                        ].join(' ')}
                      >
                        <div
                          className={[
                            'clickable',
                            'flex-align-start',
                            'flex-row',
                            'grid-row',
                            'hover:bg-gray-70-opacity-20',
                            'padding-y-105',
                          ].join(' ')}
                          onClick={() => {
                            handleObjectSearchResultClick(
                              result.contributionType,
                              result.target.pageNum
                            )
                          }}
                          tabIndex="0"
                        >
                          {result.contributor.userName == query ||
                          result.contributor.userId.toString() == query ? (
                            <>
                              {/* <Avatar
                                  contributor={result.contributor}
                                  size="md"
                                />*/}
                              <p
                                className={[
                                  'flex-column',
                                  'font-sans-2xs',
                                  'grid-col',
                                  'text-left',
                                  'width-full',
                                ].join(' ')}
                              >
                                <span className={['display-block'].join(' ')}>
                                  {result.contributor.userName}
                                </span>
                                <span
                                  className={[
                                    'display-block',
                                    'font-sans-3xs',
                                    'text-base-light',
                                    'margin-top-05',
                                  ].join(' ')}
                                >
                                  {result.contributionType == 'transcription' ||
                                  result.contributionType == 'comment' ? (
                                    <Truncate
                                      maxWords={15}
                                      term={query}
                                      highlight={false}
                                      exact={true}
                                    >
                                      {result.contribution}
                                    </Truncate>
                                  ) : (
                                    result.contribution
                                  )}
                                </span>
                                <span
                                  className={[
                                    'display-block',
                                    'font-sans-3xs',
                                    'text-base-light',
                                    'text-italic',
                                    'margin-top-05',
                                  ].join(' ')}
                                >
                                  <ListSeparator distance="05">
                                    <Icon
                                      className="opacity-50"
                                      color="base-lighter"
                                      iconName={result.contributionType}
                                      iconSize="xs"
                                      iconPercent="80"
                                    />
                                    <span>object {result.target.pageNum}</span>
                                  </ListSeparator>
                                </span>
                              </p>
                            </>
                          ) : (
                            <>
                              <Icon
                                className="margin-right-05 opacity-50"
                                color="base-lighter"
                                iconName={result.contributionType}
                                iconSize="sm"
                                iconPercent="80"
                              />
                              <p
                                href="#"
                                className={[
                                  'flex-column',
                                  'font-sans-2xs',
                                  'grid-col',
                                  'text-left',
                                  'width-full',
                                ].join(' ')}
                              >
                                {result.contributionType == 'transcription' ||
                                result.contributionType == 'comment' ? (
                                  <Truncate
                                    maxWords={15}
                                    term={query}
                                    highlight={false}
                                    exact={true}
                                  >
                                    {result.contribution}
                                  </Truncate>
                                ) : (
                                  result.contribution
                                )}
                                <span
                                  className={[
                                    'display-block',
                                    'font-sans-3xs',
                                    'text-base-light',
                                    'text-italic',
                                    'margin-top-05',
                                  ].join(' ')}
                                >
                                  object {result?.target?.pageNum}
                                </span>
                              </p>
                            </>
                          )}
                        </div>
                      </li>
                    ))}
                  </>
                ) : (
                  <li className="font-sans-3xs">
                    No results found for the term{' '}
                    <em>
                      <strong>{query}</strong>
                    </em>{' '}
                    in tags, transcriptions or comments
                  </li>
                )}
              </ul>
            ) : (
              ''
            )}
            {screenSize == 'mobile' && contentsPanel ? (
              ''
            ) : (
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'grid-col',
                  'height-full',
                  'position-relative',
                ].join(' ')}
              >
                <ContributionBar
                  extractedCount={
                    typeof objectData[0].extractedText === 'string' ||
                    typeof objectData[0]?.latestOET?.contribution === 'string'
                  }
                  contributions={
                    filteredObjects[page - 1] &&
                    filteredObjects[page - 1].contributions
                  }
                />
                {/* Temp Comment Out for now Coordinates Highlights sdasdsa */}
                <ObjectPreview
                  // hover={hover}
                  //  search={searchQuery}
                  interact={interact}
                  object={filteredObjects[page - 1]}
                  // type={activeObjectType}
                  setInteractHandler={setInteractHandler}
                  // ref={overlay}
                />
                <div
                  className={[
                    //'bottom-1',
                    'display-flex',
                    'flex-row',
                    'flex-align-center',
                    'flex-justify-center',
                    'grid-row',
                    'padding-2',
                    //'position-absolute',
                    'width-full',
                  ].join(' ')}
                >
                  <ObjectTitle
                    className={[
                      'bg-base-darkest-opacity-80',
                      'font-sans-xs',
                      'radius-md',
                      'text-base-lighter',
                      'text-center',
                      'width-auto',
                    ].join(' ')}
                    label={
                      returnFileFormat(
                        filteredObjects[page - 1].objectFilename
                      ) == 'pdf'
                    }
                    index={page}
                    object={filteredObjects[page - 1]}
                    type={activeObjectType}
                  />
                </div>
              </div>
            )}
            {!contentsPanel &&
              params['objectPanel'] &&
              params['objectPanel'] !== 'combo' && (
                <div>
                  <ContributionPanel
                    averageConfidenceScore={averageConfidenceScore}
                    filteredObjects={objectData}
                    contributions={
                      (filteredObjects[page - 1] &&
                        filteredObjects[page - 1].contributions) ||
                      []
                    }
                    ref={contributionPanel}
                    screenSize={screenSize}
                  />
                </div>
              )}
            {!contentsPanel &&
              params['objectPanel'] &&
              params['objectPanel'] === 'combo' && (
                <div>
                  <ContributionPanel
                    mode="transcription"
                    averageConfidenceScore={averageConfidenceScore}
                    filteredObjects={objectData}
                    contributions={
                      (filteredObjects[page - 1] &&
                        filteredObjects[page - 1].contributions) ||
                      []
                    }
                    ref={contributionPanel}
                    screenSize={screenSize}
                  />
                  <ContributionPanel
                    mode="extracted"
                    averageConfidenceScore={averageConfidenceScore}
                    filteredObjects={objectData}
                    contributions={
                      (filteredObjects[page - 1] &&
                        filteredObjects[page - 1].contributions) ||
                      []
                    }
                    ref={contributionPanel}
                    screenSize={screenSize}
                  />
                </div>
              )}
            <ListPanel query={query} digitalObjects={filteredObjects} />
          </div>
          <div
            className={[
              'border-base-darker',
              'border-top-1px',
              'display-flex',
              'flex-align-center',
              'flex-justify',
              'flex-gap',
              'flex-no-wrap',
              'flex-row',
              'minh-button',
              'padding-x-1',
              'text-base-lighter',
            ].join(' ')}
          >
            <div className={['width-full'].join(' ')}>
              <DownloadButton
                fileUrl={
                  filteredObjects[page - 1] &&
                  filteredObjects[page - 1].objectUrl
                }
              />
            </div>
            {filteredObjects?.length > 1 && (
              <Pagination
                current={page}
                handleNextObject={nextObjectPageHandler}
                handlePrevObject={prevObjectPageHandler}
                iconOnly={
                  (contentsPanel && screenSize !== 'desktop') ||
                  screenSize === 'mobile'
                }
                id="object"
                loop={true}
                reduced
                screenSize={screenSize}
                textOnly
                theme="white"
                total={filteredObjects?.length}
                updateCurrentObject={setObjectPageHandler}
              />
            )}
            <div className={['margin-left-auto', 'width-full'].join(' ')}></div>
          </div>
        </div>
      )}
      {filteredObjects?.length > 1 && (
        <ContentsPanel
          // active={contentsPanel}
          // handleSetContentsPanel={handleSetContentsPanel}
          // loaded={!loadingContributions}
          extractedData={objectData}
          loaded={true}
          screenSize={screenSize}
        />
      )}
    </div>
  ) : (
    ''
  )
}

DigitalObjects.defaultProps = {}

DigitalObjects.propTypes = {}

export default DigitalObjects
