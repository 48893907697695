import React, { useContext, useEffect, useState } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
import SearchContext from '../../contexts/SearchContext'
//Components
import { SectionHeading } from '../SectionHeading/SectionHeading'
//Helplers
import SectionFindingAids from './section_finding-aids'
import SectionMicroformPublications from './section_microform-publications'
import SectionOnlineResources from './section_online-resources'
import SectionSourceNotes from './section_source_notes'
import SectionRecordSource from './section_record_source'

const SectionResources = ({ ...props }) => {
  const recordContext = useContext(RecordContext)
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')
  const record = recordContext.record

  const [count, setCount] = useState(0)

  useEffect(() => {
    if (!record) return false
    setCount(
      (record.findingAids?.length || 0) +
        (record.microformPublications?.length || 0) +
        (record.onlineResources?.length || 0) +
        (record.sourceNotes?.length || 0)
    )
  }, [record])

  return (
    <div className="width-full">
      <SectionHeading copyLink count={count} {...props}>
        {count > 1 ? 'Additional Resources' : 'Additional Resource'}
      </SectionHeading>
      <div className={['display-flex', 'flex-column', 'flex-gap-md'].join(' ')}>
        {record.findingAids && <SectionFindingAids />}
        {record.microformPublications && <SectionMicroformPublications />}
        {record.onlineResources && <SectionOnlineResources />}
        {record.sourceNotes && <SectionSourceNotes />}
        {/*record.recordSource && <SectionRecordSource />*/}
      </div>
    </div>
  )
}

export default SectionResources
