import React, { Fragment } from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
//Components

import Prose from '../../../utilities/Prose'
import { IconLabel } from '../../../IconLabel/IconLabel'
import { ListSeparator } from '../../../ListSeparator/ListSeparator'
import Steps from './steps'

const HowToPage = ({ data, ...props }) => {
  return (
    <Fragment>
      {/** Page Title */}
      <div className={['display-flex', 'flex-column', 'flex-gap'].join(' ')}>
        <h2 className="margin-bottom-0">
          <IconLabel
            color="base-darker"
            iconName={data.icon}
            iconSize="lg"
            size="xl"
            strokeThickness="thicker"
          >
            <strong>{data.title}</strong>
          </IconLabel>
        </h2>
        {/** Page Details */}
        <div className={['font-sans-xs', 'text-bold'].join(' ')}>
          <ListSeparator color="base" size="xl">
            <span>{data.sections.length} sections</span>
            <span>
              {data.timeToRead} ({data.totalWordCount} words)
            </span>
          </ListSeparator>
        </div>

        {/** Page Description */}
        {data.description && <Prose>{data.description}</Prose>}
        {data.media &&
          data.media?.map((media) => (
            <Fragment key={media.caption || media.alt}>
              {media.type === 'image' && (
                <figure>
                  <img
                    className={[
                      'border-1px',
                      'border-base-lighter',
                      'overflow-hidden',
                      'radius-md',
                    ].join(' ')}
                    alt={media.alt}
                    src={media.src}
                  />
                  {media.caption && <figcaption>{media.caption}</figcaption>}
                </figure>
              )}
              {media.type === 'youtube' && (
                <figure>
                  <div className="video_wrapper">
                    <iframe
                      allowFullScreen
                      className={[
                        'border-1px',
                        'border-base-lighter',
                        'overflow-hidden',
                        'radius-md',
                        'width-full',
                      ].join(' ')}
                      frameBorder="0"
                      height="100%"
                      src={`https://www.youtube.com/embed/${media.src}`}
                      title="video"
                      width="100%"
                    />
                  </div>
                  {media.caption && (
                    <figcaption className="text-base-dark">
                      {media.caption}
                    </figcaption>
                  )}
                </figure>
              )}
            </Fragment>
          ))}
      </div>

      {/** Subsections */}
      {data.sections?.map((section) => (
        <div
          className={['display-flex', 'flex-column', 'flex-gap'].join(' ')}
          key={section.id}
        >
          <div
            className={['display-flex', 'flex-column', 'flex-gap'].join(' ')}
          >
            {/** Section Title */}
            <h3
              className={[
                'font-sans-lg',
                'margin-bottom-0',
                'text-base-darker',
                'text-bold',
              ].join(' ')}
            >
              {section.title}
            </h3>

            {/** Section Details */}
            <div
              className={['add-list-reset', 'font-sans-xs', 'text-bold'].join(
                ' '
              )}
            >
              <ListSeparator color="base">
                <span>{section.steps.length} steps in this section</span>
              </ListSeparator>
            </div>

            {/** Section Description */}
            {section.description && <Prose>{section.description}</Prose>}
            {section.media &&
              section.media?.map((media) => (
                <Fragment key={media.caption || media.alt}>
                  {media.type === 'image' && (
                    <figure className="padding-2">
                      <img
                        className={[
                          'border-1px',
                          'border-base-lighter',
                          'overflow-hidden',
                          'radius-md',
                        ].join(' ')}
                        alt={media.alt}
                        src={media.src}
                      />
                      {media.caption && (
                        <figcaption>{media.caption}</figcaption>
                      )}
                    </figure>
                  )}
                  {media.type === 'youtube' && (
                    <figure className="padding-2">
                      <div className="video_wrapper">
                        <iframe
                          allowFullScreen
                          className={[
                            'border-1px',
                            'border-base-lighter',
                            'overflow-hidden',
                            'radius-md',
                            'width-full',
                          ].join(' ')}
                          frameBorder="0"
                          height="100%"
                          src={`https://www.youtube.com/embed/${media.src}`}
                          title="video"
                          width="100%"
                        />
                      </div>
                      {media.caption && (
                        <figcaption className="text-base-dark">
                          {media.caption}
                        </figcaption>
                      )}
                    </figure>
                  )}
                </Fragment>
              ))}
          </div>
          {/** Section Questions (and Answers) */}
          <Steps data={section} key={section.steps[0].id} />
        </div>
      ))}
    </Fragment>
  )
}

HowToPage.defaultProps = {}

HowToPage.propTypes = {
  data: PropTypes.object,
}

export default HowToPage
