import { useEffect, useState } from 'react'
import {
  useSafeAsync,
  removeEmptyObjectFields,
} from '../helper-functions/query-functions'

const useHttp = (requestConfig, handleResponse, handleError = () => {}) => {
  const safeAsync = useSafeAsync()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  // const createAbortControler = new AbortController()

  let authorization = localStorage.getItem('loggedInUser')
  if (authorization) {
    authorization = btoa(authorization)
  }

  const sendRequest = (abortController, overrideRequestConfig) => {
    if (overrideRequestConfig) {
      requestConfig = { ...requestConfig, ...overrideRequestConfig }
    }
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, UPDATE, PATCH, PUT',
      'Access-Control-Allow-Headers': 'Content-Type',
      ...(requestConfig.headers || {}),
      authorization,
    }
    setIsLoading(true)
    setError(null)
    safeAsync(
      fetch(requestConfig.url, {
        method: requestConfig.method ? requestConfig.method : 'GET',
        headers: headers,
        body: requestConfig.body
          ? JSON.stringify(removeEmptyObjectFields(requestConfig.body))
          : null,
        // fire the request, with an abort signal,
        // which will permit premature abortion
        signal: abortController?.signal,
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          // Here you can decide to handle the abortion the way you want.
          // Throwing or never resolving are valid options
          if (abortController?.signal?.aborted) {
            return
          }
          throw response
        })
        .then((data) => {
          // Here you can decide to handle the aborted signal the way you want.
          // Throwing or never resolving are valid options
          if (abortController?.signal?.aborted) {
            return
          }
          data.responseTimestamp = new Date().getTime() //if (data.length > 0)
          handleResponse(data)
        })
        .catch((error) => {
          if (typeof error.text === 'function') {
            error.text().then((errorMessage) => {
              if (typeof errorMessage === 'string') {
                setError({ errorMessage })
              } else {
                setError(JSON.parse(errorMessage))
              }
            })
          } else setError(error)
        })
    )
    setIsLoading(false)
  }

  const abortHandler = (abortController) => {
    // abort the request in-flight
    // the request will be marked as "cancelled" in devtools
    abortController.abort()
  }

  //handleError must be handled in useEffect to prevent infinite re-renders
  useEffect(() => {
    handleError(error)
  }, [error])

  return {
    abortHandler,
    error,
    isLoading,
    sendRequest,
  }
}
export default useHttp
