import React, { Fragment, useEffect, useState } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import Tooltip from '../Tooltip/Tooltip'
import { IconLabel } from '../IconLabel/IconLabel'

const BarChart = ({ data, labels, filter, reduced, ...props }) => {
  let numbers = []

  useEffect(() => {
    numbers = []
    data.map((period) =>
      period.groups.map((group) => {
        group.points.map(
          (point, index) =>
            ((filter !== 0 && !filter) || filter === index) &&
            numbers.push(point.y)
        )
      })
    )
    setMax(Math.max(...numbers) + 4)
  }, [filter])
  const [max, setMax] = useState(Math.max(...numbers) + 4)

  const colors = ['accent-warm', 'primary-dark', 'accent-cool']
  const patterns = ['wiggle', 'rain', 'wave', 'plus']

  return (
    <div
      className={[
        'display-flex',
        'flex-gap',
        'flex-column',
        'height-full',
        'minh-card',
        'padding-2',
        'width-full',
      ].join(' ')}
      {...props}
    >
      {data.map((period, index) => (
        <div
          className={[
            'display-flex',
            'flex-column',
            // 'flex-gap',
            'height-full',
            'width-full',
          ].join(' ')}
          key={period.label + index}
        >
          {!reduced && <span>{period.label}</span>}

          <div
            className={[
              'display-flex',
              'flex-justify',
              reduced ? '' : 'flex-gap-xs',
              'flex-row',
              'height-full',
              'width-full',
            ].join(' ')}
          >
            {period.groups.map((group, index) => (
              <div
                className={[
                  'display-flex',
                  'flex-align-stretch',
                  'flex-column',
                  'flex-column-reverse',
                  reduced ? '' : 'flex-gap-xs',
                  'flex-justify-start',
                  'height-full',
                  'minw-05',
                  'width-full',
                ].join(' ')}
                key={(group.label || group.x) + index}
              >
                {!reduced && (
                  <span
                    className={[
                      'font-sans-3xs',
                      'height-105',
                      'text-base',
                      'text-center',
                      'width-full',
                    ].join(' ')}
                  >
                    {group.label || group.x}
                  </span>
                )}
                {(typeof filter !== 'undefined' &&
                  group.points[filter]?.y > 0) ||
                (typeof filter == 'undefined' &&
                  (group.points[0]?.y > 0 ||
                    group.points[1]?.y > 0 ||
                    group.points[2]?.y > 0)) ? (
                  <Tooltip
                    className={['height-full', 'width-full'].join(' ')}
                    content={group.points.map(
                      (point, j) =>
                        ((filter !== 0 && !filter) || filter === j) &&
                        point.y > 0 && (
                          <Fragment
                            key={
                              Math.random() +
                              (group.label || group.x) +
                              (labels[j].label || point.y) +
                              j
                            }
                          >
                            <div>
                              <IconLabel
                                iconName={labels[j].icon}
                                iconSize="xs"
                                theme="base-dark"
                              >
                                {point.y} {labels[j].label}
                                {point.y > 1 ? 's' : ''}
                              </IconLabel>
                            </div>
                          </Fragment>
                        )
                    )}
                    disabled={
                      group.points.filter((point) => point.y > 0)?.length === 0
                    }
                    display={null}
                    position="topCenter"
                  >
                    <div
                      className={[
                        'hover:bg-base-lightest',
                        'display-flex',
                        'flex-align-stretch',
                        'flex-column',
                        'flex-justify-end',
                        'height-full',
                        'width-full',
                      ].join(' ')}
                    >
                      {group.points.map((point, j) => (
                        <Fragment
                          key={
                            Math.random() +
                            (group.label || group.x) +
                            (labels[j].label || point.y) +
                            index
                          }
                        >
                          {((filter !== 0 && !filter) || filter === j) && (
                            <div
                              className={[
                                `bg-${colors[j]}`,
                                `pattern-${patterns[j]}`,
                                'display-block',
                                'position-relative',
                              ].join(' ')}
                              style={{
                                height: (point.y / max) * 100 + '%',
                              }}
                            ></div>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    className={[
                      'hover:bg-base-lightest',
                      'display-flex',
                      'flex-align-stretch',
                      'flex-column',
                      'flex-justify-end',
                      'height-full',
                      'width-full',
                    ].join(' ')}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
      {!reduced && (
        <>
          <hr />
          <div className={['display-flex', 'flex-gap', 'flex-row'].join(' ')}>
            {labels.map(
              (label, index) =>
                ((filter !== 0 && !filter) || filter === index) && (
                  <div
                    key={label.label}
                    className={[
                      'display-flex',
                      'flex-gap-xs',
                      'flex-row',
                      'font-sans-3xs',
                    ].join(' ')}
                  >
                    <span
                      className={[
                        `bg-${colors[index]}`,
                        'display-block',
                        'height-2',
                        `pattern-${patterns[index]}`,
                        'width-2',
                      ].join(' ')}
                    ></span>
                    {label.label}s
                  </div>
                )
            )}
          </div>
        </>
      )}
    </div>
  )
}

BarChart.defaultProps = {}

BarChart.propTypes = {
  /**
   * The data used to build the bar chart
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      groups: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          points: PropTypes.arrayOf(
            PropTypes.shape({
              y: PropTypes.number,
            })
          ),
          label: PropTypes.string,
        })
      ),
    })
  ),
  /**
   * Labels associated with each point
   */
  labels: PropTypes.array,
  /**
   * Index by which to filter the points
   */
  filter: PropTypes.number,
  /**
   * Minimizes the UI display
   */
  reduced: PropTypes.bool,
}

export default BarChart
