import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import '../../../styles/index.scss'
//Components
import { IconLabel } from '../../IconLabel/IconLabel'
import { OnlineAvailabilityTableButton } from './OnlineAvailability'
import StatusPill from '../../Pill/variants/StatusPill'
//Helpers
import { returnDateAndTimeObjFromTimestamp } from '../../../helper-functions/date-functions'
import {
  removeExtraWhiteSpace,
  validateInputFromSchema,
} from '../../form/Validate/Validate'
import { returnIconAndLabelFromString } from '../../../helper-functions/metadata-functions'
//Hooks
import useHttp from '../../../hooks/use-http'
import useInput from '../../../hooks/use-input'
//Settings
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  MAX_AVAILABILITY_RESULT_LIMIT,
  SEARCH_URL,
} from '../../settings/globals'
import { onlineAvailabilitySchema } from '../../OnlineAvailability/online-availability-schema'
import { useLocation } from 'react-router-dom'
import useUrlParams from '../../../hooks/use-url-params'
import { compareNumbers } from '../../../helper-functions/sort-functions'

const useOnlineAvailability = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { removeParams, params, updateParams } = useUrlParams()
  const sort = searchParams.get('sort')
  const naIds = searchParams.get('naId')

  const [data, setData] = useState([])
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingRecords, setLoadingRecords] = useState(false)
  const [tempLoading, setTempLoading] = useState(false)
  const [records, setRecords] = useState([])
  const [table, setTable] = useState([])
  const [tableRecords, setTableRecords] = useState([])
  const [total, setTotal] = useState(null)
  const [totalSearched, setTotalSearched] = useState(null)

  const [page, setPage] = useState(
    parseInt(searchParams.get('page')) || DEFAULT_PAGE
  )
  const [limit, setLimit] = useState(DEFAULT_LIMIT)
  const [status, setStatus] = useState('active')

  const [allNaIds, setAllNaIds] = useState(null)
  const [searchedNaIds, setSearchedNaIds] = useState(naIds?.split(',') || null)
  const [remainingNaIds, setRemainingNaIds] = useState(null)
  const [notFoundNaIds, setNotFoundNaIds] = useState(null)
  const availability = null
  // The value submitted in the availability activity search input
  const [submittedValue, setSubmittedValue] = useState(null)
  // Error thrown on search
  const [searchError, setSearchError] = useState(null)

  /**
   * Handle Search
   */
  const searchErrorHandler = (error) => {
    setSearchError(error)
  }

  const {
    isValid: enteredSearchIsValid,
    value: enteredSearch,
    inputBlurHandler: blurHandler,
    reset: resetInput,
    valueChangeHandler: changeHandler,
  } = useInput(
    (value) => validateInputFromSchema({ type: 'text' }, value),
    searchErrorHandler
  )

  const sanitizedInput = removeExtraWhiteSpace(
    DOMPurify.sanitize(enteredSearch)
  )

  const submitHandler = (event) => {
    event.preventDefault()
    setSubmittedValue(sanitizedInput)
    setPage(DEFAULT_PAGE)
    setErrors([])
    setData([])
    setRecords([])
    if (!sanitizedInput) {
      setSearchedNaIds(null)
      setRemainingNaIds(null)
      setNotFoundNaIds(null)
    } else {
      setSearchedNaIdsHandler(sanitizedInput)
    }
  }

  const returnArrayFromString = (string) => {
    return string
      .replace(/\s*,\s*/g, ',') // Remove whitespace between comma and characters
      .replace(/\s+/g, ',') // Replace all additional consecutive white spaces with a comma
      .replace(/[^0-9,]/g, '')
      .split(',')
      .filter(Boolean)
  }

  const returnDuplicates = (array) =>
    array.filter((item, index) => array.indexOf(item) !== index)

  const returnDiffBetweenArrays = (arrayA, arrayB, unique = true) => {
    let unaccountedFor = []
    arrayA.map((item) => {
      if (arrayB.indexOf(item) === -1) unaccountedFor.push(item)
    })
    return unaccountedFor
  }

  const setSearchedNaIdsHandler = (naIdString) => {
    if (!naIdString) return false
    // Remove duplicate naIds
    const oldNaIdArray = returnArrayFromString(naIdString)
    let newNaIdArray = [...new Set(oldNaIdArray)]
    const diff = returnDuplicates(oldNaIdArray)
    const diffCount = diff.length
    const diffNaIds = [...new Set(diff)]

    if (diffCount) {
      setErrorsHandler(
        {
          description: `${diffCount} duplicate NAIDs were found in your search, including: ${diffNaIds?.join(
            ', '
          )}.`,
          id: 'DUPLICATE_NAID_PARAMS',
          type: 'warning',
        },
        'DUPLICATE_NAID_PARAMS'
      )
    }
    if (newNaIdArray.length > MAX_AVAILABILITY_RESULT_LIMIT) {
      setErrorsHandler(
        {
          description: `The max number of NAIDs to search is ${MAX_AVAILABILITY_RESULT_LIMIT}, however ${
            newNaIdArray.length
          } were entered.
        The following ${
          newNaIdArray.length - MAX_AVAILABILITY_RESULT_LIMIT
        } NAIDs were not searched: ${newNaIdArray
            ?.slice(MAX_AVAILABILITY_RESULT_LIMIT)
            ?.join(', ')}.`,
          id: 'DUPLICATE_NAID_PARAMS',
          type: 'error',
        },
        'MAX_NAIDS_EXCEEDED'
      )
      newNaIdArray = newNaIdArray?.slice(0, MAX_AVAILABILITY_RESULT_LIMIT)
    }
    setSearchedNaIds(newNaIdArray)?.sort((a, b) =>
      compareNumbers(parseInt(a.naId), parseInt(b.naId), false)
    )
  }

  const setErrorsHandler = (error, idToRemove) => {
    if (!error) return false
    setErrors((prev) => [...prev.filter((x) => x.id !== idToRemove), error])
  }

  useEffect(() => {
    setTotal(null)
    setLoading(true)
  }, [searchedNaIds, submittedValue])

  useEffect(() => {
    if (loading) {
      availabilityRequestHandler()
    }
  }, [loading])

  const availabilityResponseHandler = (results) => {
    let flattenedAllNaIds = []
    let flattenedRemainingNaIds = []
    let flattenedResults = []
    if (!results?.length > 0) {
      setData([])
      setAllNaIds(searchedNaIds)
      setRemainingNaIds(searchedNaIds)
      setTotal(0)
    } else {
      let totalDeclared = 0
      let totalReturned = 0
      results.map((result, index) => {
        flattenedAllNaIds.push(String(result.naId))
        flattenedResults.push({
          availability: result.availability,
          naId: result.naId,
          status: result.status,
          updatedAt: result.updatedAt,
        })
        if (index === 0) {
          totalDeclared = result.total
          for (const [key, value] of Object.entries(result)) {
            if (parseInt(key)) {
              if (value === 0) {
                flattenedRemainingNaIds.push(String(key))
              }
              if (value === 1) totalReturned++
            }
          }
        }
      })
      setData(flattenedResults)

      setTotal(Math.max(totalDeclared, totalReturned))
      setAllNaIds(flattenedAllNaIds.concat(flattenedRemainingNaIds))
      setRemainingNaIds(flattenedRemainingNaIds)
    }

    setLoadingRecords(true)

    //
    // setTimeout(() => {
    //   setLoading(false)
    // }, 1000)
  }

  let queryParamsArray = []
  if (availability) queryParamsArray.push(`availability=${availability}`)
  if (limit) queryParamsArray.push(`limit=${limit}`)
  if (searchedNaIds) queryParamsArray.push(`naId=${searchedNaIds}`)
  if (sort) queryParamsArray.push(`sort=${sort}`)
  else queryParamsArray.push(`sort=naId:asc`)
  if (!searchedNaIds && page) queryParamsArray.push(`page=${page}`)
  if (status) queryParamsArray.push(`status=${status}`)
  let queryParams = ''
  if (queryParamsArray?.length > 0)
    queryParams = `?${queryParamsArray?.join('&')}`
  const { sendRequest: availabilityRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/online-availability${queryParams}`,
    },
    availabilityResponseHandler
  )

  useEffect(() => {
    let newRows = []
    data?.map(
      (
        {
          authorityType,
          availability,
          totalDigitalObjects,
          levelOfDescription,
          heading,
          naId,
          recordType,
          status,
          title,
          updatedAt,
        },
        index
      ) => {
        let schema = onlineAvailabilitySchema.filter(
          ({ id }) => id === availability
        )[0]
        newRows.push({
          actions: { naId: naId, availability: availability },
          availability: (
            <StatusPill
              // icon
              label={schema?.secondaryLabel || schema?.label}
              status={schema?.type}
            />
          ),
          date: (
            <div className="display-flex flex-column">
              <span>{returnDateAndTimeObjFromTimestamp(updatedAt).date}</span>
              <span>{returnDateAndTimeObjFromTimestamp(updatedAt).time}</span>
            </div>
          ),
          levelOfDescription: loadingRecords ? (
            <TableCellLoading />
          ) : levelOfDescription || recordType ? (
            <div
              className={['display-flex', 'flex-gap-xs', 'flex-column'].join(
                ' '
              )}
            >
              {
                returnIconAndLabelFromString(levelOfDescription || recordType)
                  .label
              }
              {totalDigitalObjects[0] > 0 && (
                <IconLabel color="base" iconName="image" size="xs">
                  {totalDigitalObjects[0]}
                </IconLabel>
              )}
            </div>
          ) : (
            <NotAvailableText reduced />
          ),
          naId: naId,
          title: loadingRecords ? (
            <TableCellLoading />
          ) : title ||
            `${
              returnIconAndLabelFromString(authorityType).label
            }: ${heading}` ? (
            <Link className="ellipsed-line-2" to={`/id/${naId}`}>
              {title ||
                `${
                  returnIconAndLabelFromString(authorityType).label
                }: ${heading}`}
            </Link>
          ) : (
            <NotAvailableText />
          ),
          status: <StatusPill status={status} />,
        })
      }
    )

    setTable(newRows)
  }, [data, loadingRecords])

  const columns = useMemo(
    () => [
      {
        Header: 'NAID',
        accessor: 'naId',
        id: 'naId',
        className: 'width-10',
      },
      {
        Header: 'Level',
        accessor: 'levelOfDescription',
        id: 'levelOfDescription',
        className: 'width-9',
      },
      {
        Header: 'Title',
        accessor: 'title',
        id: 'title',
        className: 'grid-col-3 tablet-lg:grid-col-4',
      },
      {
        Header: 'Status',
        accessor: 'availability',
        id: 'availability',
        className: 'width-15',
      },
      {
        Header: 'Updated',
        accessor: 'date',
        id: 'date',
        className: 'width-15 desktop-lg:width-card',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        id: 'actions',
        Cell: (cell) => {
          return (
            <OnlineAvailabilityTableButton
              naId={String(cell.value.naId)}
              availability={cell.value.availability}
              onUpdate={() => setLoading(true)}
            />
          )
        },
        className: 'grid-col-1', //screenSize !== 'desktop' ? 'width-15' : 'width-card',
      },
    ],
    [data]
  )

  useEffect(() => {
    if (allNaIds?.length > 0 && loadingRecords) {
      const abortController = new AbortController()
      recordsRequestHandler(abortController)
    }
  }, [allNaIds, loadingRecords]) //, loading

  const recordsResponseHandler = (results) => {
    const resultsArray = results.body.hits.hits
    const naIdsArray = results.body.aggregations?.naIds?.buckets.reduce(
      (a, b) => [...a, b.key],
      []
    )

    let flattenedRecords = []
    let containsObjects = []
    let newData = data?.filter((x) => {
      return { ...x }
    }) // Prevent mutation of original state

    resultsArray.map((result) => {
      const record = { ...result._source.record, ...result.fields }
      if (newData.length > 0)
        newData.map((d) => {
          if (parseInt(d.naId) === parseInt(record.naId)) {
            d.title =
              record.title || `${record.authorityType}: ${record.heading}`
            d.levelOfDescription =
              record.levelOfDescription || record.recordType
            d.totalDigitalObjects = record.totalDigitalObjects
          }
        })
      if (remainingNaIds.includes(String(record.naId)))
        flattenedRecords.push(record)
      if (record.totalDigitalObjects[0] > 0) containsObjects.push(record.naId)
    })

    if (newData.length > 0) setData(newData)
    setRecords(
      flattenedRecords
        .concat(newData)
        ?.sort((a, b) =>
          compareNumbers(parseInt(a.naId), parseInt(b.naId), false)
        )
    )

    setTimeout(() => {
      setLoadingRecords(false)
      setLoading(false)
    }, [500])

    // Let the user know which NAIDs could not befound so they can correct any mistakes
    const unaccountedFor = returnDiffBetweenArrays(remainingNaIds, naIdsArray)

    let newsearchedNaIds = searchedNaIds.filter((x) => {
      return { ...x }
    })
    setSearchedNaIds(
      newsearchedNaIds
        .filter((val) => !unaccountedFor.includes(val))
        .sort((a, b) =>
          compareNumbers(parseInt(a.naId), parseInt(b.naId), false)
        )
    )

    if (containsObjects?.length > 0) {
      setErrorsHandler(
        {
          description: `${
            containsObjects.length > 1
              ? `${containsObjects.length} records contain digital objects`
              : '1 record contains digital objects'
          }: ${containsObjects.join(', ')}.`,
          id: 'DIGITAL_OBJECTS_FOUND',
          type: 'warning',
        },
        'DIGITAL_OBJECTS_FOUND'
      )
    }
    if (unaccountedFor?.length > 0) {
      setErrorsHandler(
        {
          description: `${
            unaccountedFor.length > 1
              ? `${unaccountedFor.length} NAIDs were`
              : '1 NAID was'
          } not found in the database: ${unaccountedFor.join(
            ', '
          )}.\nCheck for errors, including misplaced commas or spaces, and resubmit your search.`,
          id: 'NAIDS_NOT_FOUND',
          type: 'error',
        },
        'NAIDS_NOT_FOUND'
      )
    }
  }

  const { sendRequest: recordsRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?naId=${allNaIds}&abbreviated=true&queriedNaIds=true&limit=1000`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    recordsResponseHandler
  )

  useEffect(() => {
    let newRows = []
    records?.map(
      ({
        authorityType,
        availability,
        totalDigitalObjects,
        levelOfDescription,
        heading,
        naId,
        recordType,
        title,
        updatedAt,
      }) => {
        let schema = onlineAvailabilitySchema.filter(
          ({ id }) => id === availability
        )[0]
        newRows.push({
          actionsWithout: { naId: naId, availability: availability },
          // id: naId,
          availabilityWithout: availability ? (
            <StatusPill
              // icon
              label={schema?.secondaryLabel || schema?.label}
              status={schema?.type}
            />
          ) : (
            <StatusPill label="None" status="inactive" />
          ),
          dateWithout: updatedAt ? (
            <div className="display-flex flex-column">
              <span>{returnDateAndTimeObjFromTimestamp(updatedAt).date}</span>
              <span>{returnDateAndTimeObjFromTimestamp(updatedAt).time}</span>
            </div>
          ) : (
            <NotAvailableText reduced />
          ),
          levelOfDescriptionWithout: (
            <div
              className={['display-flex', 'flex-gap-xs', 'flex-column'].join(
                ' '
              )}
            >
              {
                returnIconAndLabelFromString(levelOfDescription || recordType)
                  .label
              }
              {totalDigitalObjects[0] > 0 && (
                <IconLabel color="base" iconName="image" size="xs">
                  {totalDigitalObjects[0]}
                </IconLabel>
              )}
            </div>
          ),
          naIdWithout: naId,
          titleWithout: loadingRecords ? (
            <TableCellLoading />
          ) : title ||
            `${
              returnIconAndLabelFromString(authorityType).label
            }: ${heading}` ? (
            <Link className="ellipsed-line-2" to={`/id/${naId}`}>
              {title ||
                `${
                  returnIconAndLabelFromString(authorityType).label
                }: ${heading}`}
            </Link>
          ) : (
            <NotAvailableText />
          ),
          recordStatus: <NotAvailableText reduced />,
        })
      }
    )

    setTableRecords(newRows)
  }, [records, loadingRecords])

  const columnsRecords = useMemo(
    () => [
      {
        Header: 'NAID',
        accessor: 'naIdWithout',
        id: 'naIdWithout',
        className: 'width-10',
      },
      {
        Header: 'Level',
        accessor: 'levelOfDescriptionWithout',
        id: 'levelOfDescriptionWithout',
        className: 'width-9',
      },
      {
        Header: 'Title',
        accessor: 'titleWithout',
        id: 'titleWithout',
        className: 'grid-col-3 tablet-lg:grid-col-4',
      },
      {
        Header: 'Status',
        accessor: 'availabilityWithout',
        id: 'availabilityWithout',
        className: 'width-15',
      },
      {
        Header: 'Updated',
        accessor: 'dateWithout',
        id: 'dateWithout',
        className: 'width-15 desktop-lg:width-card',
      },
      {
        Header: 'Actions',
        accessor: 'actionsWithout',
        id: 'actionsWithout',
        Cell: (cell) => {
          return (
            <OnlineAvailabilityTableButton
              naId={String(cell.value?.naId)}
              availability={cell.value?.availability}
              onUpdate={() => setLoading(true)}
            />
          )
        },
        className: 'grid-col-1', //screenSize !== 'desktop' ? 'width-15' : 'width-card',
      },
    ],
    [records]
  )
  const pages = Math.ceil((totalSearched || total) / DEFAULT_LIMIT)

  const setPageHandler = (newPage) => {
    if (!loading && !loadingRecords) {
      setPage(newPage)
      if (!searchedNaIds?.length > 0) setLoading(true)
      else setTempLoading(true)
    }
  }

  useEffect(() => {
    updateParams({ page: page > 1 ? page : '' })
  }, [page])

  const searched = searchedNaIds?.sort((a, b) =>
    compareNumbers(parseInt(a), parseInt(b), false)
  )

  useEffect(() => {
    if (searchedNaIds?.length > 0) {
      setTotalSearched(records.length)
      updateParams({ naId: searched?.join(',') }) //, sort: 'naId:asc' })
    } else {
      setTotalSearched(null)
      updateParams({ naId: '' }) //, sort: '' })
    }
  }, [records])

  useEffect(() => {
    if (tempLoading)
      setTimeout(() => {
        setTempLoading(false)
      }, [500])
  }, [tempLoading])

  return {
    changeHandler,
    columns,
    columnsRecords,
    data,
    enteredSearch,
    errors,
    loading,
    loadingRecords,
    page,
    pages,
    searchedNaIds,
    setPageHandler,
    submitHandler,
    table,
    tableRecords,
    tempLoading,
    total,
    totalSearched,
  }
}

export default useOnlineAvailability

export const TableCellLoading = () => {
  return (
    <div className={['block-load', 'height-2', 'width-full'].join(' ')}></div>
  )
}

export const NotAvailableText = ({ reduced, ...props }) => {
  return (
    <span className="font-sans-2xs text-base-dark text-italic">
      {reduced ? 'N/A' : 'Not Available'}
    </span>
  )
}
NotAvailableText.propTypes = {
  reduced: PropTypes.bool,
}
