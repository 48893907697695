import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import '../../../../styles/index.scss'
//Components
import Prose from '../../../utilities/Prose'
import { Button } from '../../../Button/Button'
import { Alert } from '../../../Alert/Alert'

const Steps = ({ data, onClick, ...props }) => {
  return (
    <div
      className={[
        'bg-white',
        'display-flex',
        'flex-gap-md',
        'flex-column',
        'overflow-hidden',
        'padding-2',
        'radius-md',
        'shadow-sm',
      ].join(' ')}
      id={data.id}
    >
      {data.steps?.map((step, index) => (
        <Fragment key={step.id}>
          <div className={['display-flex', 'flex-row', 'flex-gap'].join(' ')}>
            <div
              className={[
                'step-number',
                //
                'border-1px',
                'border-base-dark',
                'display-inline-flex',
                'flex-align-start',
                'flex-justify-center',
                'flex-shrink-0',
                'font-sans-md',
                'radius-pill',
                'text-base-darker',
                'text-bold',
                'text-center',
              ].join(' ')}
            >
              {index + 1}
            </div>
            <div
              className={[
                'display-flex',
                'flex-column',
                'flex-gap-sm',
                'grid-col',
              ].join(' ')}
              key={step.id}
            >
              <h4
                className={[
                  'font-sans-md',
                  'margin-y-1',
                  'text-base-darker',
                  'text-bold',
                ].join(' ')}
              >
                {step.title}
              </h4>
              <Prose>{step.description}</Prose>
              {step.aside && <Alert>{step.aside}</Alert>}
            </div>
            <div
              className={['display-flex', 'flex-column', 'grid-col-4'].join(
                ' '
              )}
            >
              {step.media &&
                step.media?.map((media) => (
                  <Fragment key={media.caption || media.alt}>
                    {media.type === 'image' && (
                      <figure>
                        <img
                          className={[
                            'border-1px',
                            'border-base-lighter',
                            'overflow-hidden',
                            'radius-md',
                          ].join(' ')}
                          alt={media.alt}
                          src={media.src}
                        />
                        {media.caption && (
                          <figcaption>{media.caption}</figcaption>
                        )}
                      </figure>
                    )}
                    {media.type === 'youtube' && (
                      <figure>
                        <div className="video_wrapper">
                          <iframe
                            allowFullScreen
                            className={[
                              'border-1px',
                              'border-base-lighter',
                              'overflow-hidden',
                              'radius-md',
                              'width-full',
                            ].join(' ')}
                            frameBorder="0"
                            height="100%"
                            src={`https://www.youtube.com/embed/${media.src}`}
                            title="video"
                            width="100%"
                          />
                        </div>
                        {media.caption && (
                          <figcaption>{media.caption}</figcaption>
                        )}
                      </figure>
                    )}
                  </Fragment>
                ))}
            </div>
          </div>
          {index < data.steps.length - 1 && <hr />}
        </Fragment>
      ))}
    </div>
  )
}

Steps.defaultProps = {}

Steps.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func,
}

export default Steps
