import React, { useContext, useEffect, useMemo, useState } from 'react'
import AccountContext from '../../../contexts/AccountContext'
import { CellNumber } from './Insights'

const useInsights = (accountState) => {
  const [table, setTable] = useState([])

  const data = [
    {
      label: 'This Month',
      id: 'ThisMonth',
    },
    {
      label: 'This Year',
      id: 'ThisYear',
    },
    {
      label: 'All Time',
      id: '',
    },
  ]

  useEffect(() => {
    let newRows = []
    data?.map(({ label, id }) => {
      const totals = accountState?.contributionTotals?.totals
      newRows.push({
        columnHeader: label,
        comments: <CellNumber number={totals?.[`totalComments${id}`]} />,
        tags: <CellNumber number={totals?.[`totalTags${id}`]} />,
        transcriptions: (
          <CellNumber number={totals?.[`totalTranscriptions${id}`]} />
        ),
        totals: (
          <CellNumber number={totals?.[`total${id ? id : 'Contributions'}`]} />
        ),
      })
    })

    setTable(newRows)
  }, [accountState?.contributionTotals])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'columnHeader',
        id: 'columnHeader',
        // className: 'grid-col-1',
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        id: 'comments',
        className: 'text-center',
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        id: 'tags',
        className: 'text-center',
      },
      {
        Header: 'Transcriptions',
        accessor: 'transcriptions',
        id: 'transcriptions',
        className: 'text-center',
      },
      {
        Header: 'Totals',
        accessor: 'totals',
        id: 'totals',
        className: 'text-center',
      },
    ],
    [accountState?.contributionTotals]
  )
  return { columns, table }
}

export default useInsights
