import { useEffect, useState } from 'react'
import { validateInputFromSchema } from '../components/form/Validate/Validate'

const useInput = (validateValue, handleError = () => {}, initialValue) => {
  //State
  const [enteredValue, setEnteredValue] = useState(initialValue || '')
  const [isTouched, setIsTouched] = useState(false)
  const [error, setError] = useState(null)

  //Variables
  const valueIsValid = () => {
    try {
      validateValue(enteredValue)
    } catch (err) {
      if (enteredValue !== '') {
        return false
      }
    }
    return true
  }
  const hasErrorOnTouch = !valueIsValid && isTouched

  //Functions
  const checkForValidation = (value) => {
    try {
      validateValue(value)
    } catch (err) {
      if (enteredValue !== '') {
        return setError(err)
      }
    }
    return setError(null)
  }

  const ignoreBlankValuesOnSetTouched = (value) => {
    if (value === '') setIsTouched(false)
    else setIsTouched(true)
  }

  const valueChangeHandler = (event, value = null) => {
    const v = value !== null ? value : event?.target?.value
    ignoreBlankValuesOnSetTouched(v)
    setEnteredValue(v)
  }

  const inputBlurHandler = (event) => {
    ignoreBlankValuesOnSetTouched(event?.target?.value)
  }

  const reset = () => {
    setEnteredValue('')
    setIsTouched(false)
    setError(null)
    // error = null
  }

  //Assists in clearing API errors stored in the adjacent component
  //handleError must be handled in useEffect to prevent infinite re-renders

  useEffect(() => {
    checkForValidation(enteredValue)
  }, [enteredValue])

  useEffect(() => {
    if (!hasErrorOnTouch || !error) handleError(null)
    handleError(error)
  }, [error])

  return {
    error,
    hasErrorOnTouch,
    isValid: valueIsValid,
    value: enteredValue,
    inputBlurHandler,
    reset,
    valueChangeHandler,
  }
}
export default useInput
