const exactPhrase = (string) => {
  if (
    (string?.[0] == "'" && string[string.length - 1] == "'") ||
    (string?.[0] == '"' && string[string.length - 1] == '"')
  ) {
    return true
  } else return false
}

export function countWords(str) {
  return str.trim()?.split(/\s+/).length
}

export function truncateAfter(string, search) {
  if (!string || !search) return
  if (exactPhrase(search))
    return string?.slice(string?.indexOf(search) + search.length)
  else
    return string?.slice(
      string?.toLowerCase()?.indexOf(search?.toLowerCase()) + search.length
    )
}

export function truncateBefore(string, search) {
  if (!string || !search) return
  if (exactPhrase(search)) return string?.slice(0, string?.indexOf(search))
  else
    return string?.slice(
      0,
      string?.toLowerCase()?.indexOf(search?.toLowerCase())
    )
}

export function truncate(string, amount, reverse = false) {
  if (!string) return
  if (string?.split(' ').length <= amount) return string
  if (reverse)
    return (
      '...' + string?.split(' ').reverse().splice(0, amount).reverse().join(' ')
    )
  return string?.split(' ').splice(0, amount).join(' ') + '...'
}

export function getContributionsMap(digitalObject) {
  const contributionsMap =
    digitalObject?.contributions?.reduce((ac, cv) => {
      if (!ac[cv.contributionType]) {
        ac[cv.contributionType] = []
      }
      ac[cv.contributionType].push(cv.contribution.toLowerCase())
      return ac
    }, {}) || {}
  if (digitalObject.extractedText) {
    contributionsMap['extractedText'] = contributionsMap['extractedText'] || []
    contributionsMap['extractedText'].push(
      digitalObject.extractedText?.toLowerCase()
    )
  }
  if (digitalObject.otherExtractedText) {
    contributionsMap['otherExtText'] = digitalObject.otherExtractedText.map(
      (oet) => oet.contribution.toLowerCase()
    )
  }
  return contributionsMap
}

export function isDOMatched(digitalObject) {
  const searchCriteriaText = sessionStorage.getItem('searchCriteria')
  if (!searchCriteriaText) return false
  try {
    const searchCriteria = JSON.parse(searchCriteriaText)
    const contributionsMap = getContributionsMap(digitalObject)
    const criteriaMap = {
      comment: searchCriteria.commentContribution?.toLowerCase(),
      extractedText: searchCriteria.extractedTextContribution?.toLowerCase(),
      otherExtText: searchCriteria.extractedTextContribution?.toLowerCase(),
      tag: searchCriteria.tagContribution?.toLowerCase(),
      transcription: searchCriteria.transcriptionContribution?.toLowerCase(),
    }
    return !!Object.keys(criteriaMap).find((criteriaKey) => {
      let searchTerm = criteriaMap[criteriaKey]
      const quotedArray =
        searchTerm
          ?.match(/"(.*?)"/g)
          ?.map((v) => {
            searchTerm = searchTerm.replace(v, '')
            return v.replace(/"/g, '')
          })
          ?.filter(function (v) {
            return v !== ''
          }) || []
      const terms = [
        ...quotedArray,
        ...(searchTerm?.split(/\s+/) || []),
      ].filter((v) => !!v)
      return (
        terms.length > 0 &&
        !!contributionsMap?.[criteriaKey]?.find((c) =>
          terms.every((term) => c.includes(term))
        )
      )
    })
  } catch (e) {
    console.error(e)
  }
  return false
}
