export const searchInputSchema = {
  allow: ['string'],
  autoComplete: false,
  description: 'Filter options',
  icon: 'search',
  id: 'dropdown-search',
  maxLength: 200,
  // placeholder: 'Filter options',
  selectable: true,
  sr: 'Filter dropdown options',
  type: 'text',
}
