import React, { useEffect, useRef } from 'react'

export const ESCAPE_KEYS = ['27', 'Escape']
export const ENTER_KEYS = ['13', 'Enter']
export const SPACE_KEYS = ['32', 'Space']
export const TAB_KEYS = ['9', 'Tab']

const useEventListener = (eventName, handler, element = window) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const eventListener = (event) => savedHandler.current(event)
    element.addEventListener(eventName, eventListener)
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
export default useEventListener
