import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AccountContext from '../../../contexts/AccountContext'
import UserContext from '../../../contexts/UserContext'
import { SEARCH_URL } from '../../settings/globals'
import DOMPurify from 'dompurify'
import useInput from '../../../hooks/use-input'
import { usernameInputSchema } from '../../../schemas/input-schemas/input-schema-username'
import '../../../styles/index.scss'
import { Button } from '../../Button/Button'
import { IconLabel } from '../../IconLabel/IconLabel'
import AccountContent, { ContentTitle } from '../account_content'
// Helper Functions
import {
  validateInputFromSchema,
  removeExtraWhiteSpace,
  ValidationError,
} from '../../form/Validate/Validate'
import useHttp from '../../../hooks/use-http'

const Settings = () => {
  const { handleUsernameChange } = useContext(UserContext)
  const accountState = useContext(AccountContext)
  const navigate = useNavigate()
  // const [username, setUsername] = useState('')
  const [usernameInputError, setUsernameInputError] = useState(null)
  const [usernameEdit, setUsernameEdit] = useState(false)
  const [submitUsernameDisabled, setSubmitUsernameDisabled] = useState(true)

  const handleUsernameInputError = (error) => {
    setUsernameInputError(
      typeof error === 'string' ? new ValidationError(error) : error
    )
  }

  const {
    isValid: enteredUsernameIsValid,
    value: enteredUsername,
    valueChangeHandler: usernameChangeHandler,
  } = useInput(
    (value) => validateInputFromSchema(usernameInputSchema, value),
    handleUsernameInputError,
    usernameEdit ? accountState?.account?.userName : null
  )

  const updateUsernameResponseHandler = (response) => {
    handleUsernameChange(enteredUsername)
    accountState.setAccount(enteredUsername)
    navigate({ pathname: `/accounts/${enteredUsername}/settings` })
    setUsernameEdit(false)
  }

  const handleResponseError = (response) => {
    if (response?.error?.id === 'ER_DUP_ENTRY') {
      setUsernameInputError({
        message: 'Username already exists',
        type: 'error',
      })
    } else {
      setUsernameInputError({
        message: response?.error?.description,
        type: 'error',
      })
    }
  }

  const submitNewUsername = () => {
    sendChangeUsername()
  }

  const { sendRequest: sendChangeUsername } = useHttp(
    {
      url: `${SEARCH_URL}/users/username`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        userId: `${accountState?.userId}`,
        userName: removeExtraWhiteSpace(DOMPurify.sanitize(enteredUsername)),
      },
    },
    updateUsernameResponseHandler,
    handleResponseError
  )

  const openEditUsername = () => {
    setUsernameEdit(true)
    if (enteredUsername === accountState?.account?.userName) {
      setSubmitUsernameDisabled(true)
    }
  }

  const errorInputClasses =
    usernameInputError?.type === 'error'
      ? 'invalid border-error-dark bg-error-lighter'
      : usernameInputError?.type === 'warning'
      ? 'warning border-accent-warm-dark bg-accent-warm-lighter'
      : ''
  const errorMessageClasses =
    usernameInputError?.type === 'error'
      ? 'text-error-dark'
      : usernameInputError?.type === 'warning'
      ? 'text-warning-dark'
      : ''
  const specialCharactersRegex = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g
  useEffect(() => {
    setUsernameInputError(null)
    if (specialCharactersRegex.test(enteredUsername)) {
      setUsernameInputError({
        message: 'No special characters or spaces',
        type: 'error',
      })
    } else if (enteredUsername === accountState?.account?.userName) {
      setUsernameInputError({
        message: 'This is your current username',
        type: 'error',
      })
    } else if (enteredUsername === '') {
      setSubmitUsernameDisabled(true)
    } else {
      setSubmitUsernameDisabled(false)
    }
  }, [enteredUsername])

  useEffect(() => {
    if (usernameInputError?.type === 'error') {
      setSubmitUsernameDisabled(true)
    }
  }, [usernameInputError])

  return (
    <AccountContent>
      <ContentTitle iconName="gear" title="Settings" />
      <div>
        <label className={'text-bold'}>Username</label>
        <div
          className={'display-flex flex-wrap'}
          style={{
            justifyContent: 'left',
            alignItems: 'center',
          }}
        >
          <div
            className={[
              'display-grid',
              'flex-gap-sm',
              'font-sans-md',
              'grid-col-5',
              'margin-right-10',
            ].join(' ')}
          >
            {usernameEdit ? (
              <div
                style={{
                  minWidth: '10rem',
                }}
              >
                <input
                  className={[
                    'usa-input',
                    errorInputClasses,
                    'bg-white',
                    'border-base-dark',
                    'border-1px',
                    'margin-top-05',
                    'radius-md',
                  ].join(' ')}
                  data-testid="nac-change-username-input"
                  id={usernameInputSchema['id']}
                  type={usernameInputSchema['type']}
                  value={enteredUsername}
                  placeholder={usernameInputSchema['placeholder']}
                  label={usernameInputSchema['label']}
                  maxLength={usernameInputSchema['maxlength']}
                  minLength={usernameInputSchema['minlength']}
                  onChange={usernameChangeHandler}
                />
              </div>
            ) : (
              <>
                <div className={'grid-row'}>
                  <div className={'justify-content-center'}>
                    {accountState?.account?.userName}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={'flex-row'}>
            {usernameEdit ? (
              <>
                <Button
                  onClick={submitNewUsername}
                  disabled={submitUsernameDisabled}
                >
                  Submit
                </Button>
                <Button
                  onClick={() => {
                    setUsernameEdit(false)
                  }}
                  textOnly
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                aria-label="Change username"
                data-testid="nac-account-change-username"
                iconName="edit"
                iconSize="2xs"
                onClick={openEditUsername}
              >
                Change
              </Button>
            )}
          </div>
        </div>
        {usernameEdit ? (
          <p
            aria-atomic="true"
            aria-describedby={usernameInputSchema['id']}
            className={[
              errorMessageClasses,
              usernameInputError?.message ? 'active' : '',
              'anim-duration-500ms',
              'anim-opacity',
              'flex-row',
              'grid-row',
              'font-sans-3xs',
              'position-relative',
              'margin-top-1',
              'minh-2',
            ].join(' ')}
            data-testid="nac-username_error"
            role="alert"
          >
            <IconLabel
              color={
                usernameInputError?.type === 'error'
                  ? 'error-dark'
                  : usernameInputError?.type === 'warning'
                  ? 'warning-dark'
                  : 'base-darker'
              }
              iconName={
                usernameInputError?.type === 'error' ||
                usernameInputError?.type === 'warning'
                  ? 'triangle-exclaim'
                  : null
              }
              iconSize="xs"
              size="2xs"
            >
              {usernameInputError?.message}
            </IconLabel>
          </p>
        ) : (
          <></>
        )}
      </div>
    </AccountContent>
  )
}

export default Settings
