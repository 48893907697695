import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'

import '../../styles/index.scss'
import { Button } from '../Button/Button'
import Card from '../Card/Card'
import { Icon } from '../Icon/Icon'

const Toast = ({
  autoDelete,
  autoDeleteTime,
  children,
  maxToasts,
  position,
  toastList,
  ...props
}) => {
  const [list, setList] = useState(toastList)
  useEffect(() => {
    setList([...toastList])
  }, [toastList])

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        deleteToast(toastList[0].id)
      }
    }, autoDeleteTime)

    return () => {
      clearInterval(interval)
    }
  }, [toastList, autoDelete, autoDeleteTime, list])

  useEffect(() => {
    if (maxToasts && toastList.length > maxToasts) {
      deleteToast(toastList[0].id)
    }
  }, [list])

  const deleteToast = (id) => {
    const listItemIndex = list.findIndex((e) => e.id === id)
    const toastListItem = toastList.findIndex((e) => e.id === id)
    list.splice(listItemIndex, 1)
    toastList.splice(toastListItem, 1)
    setList([...list])
  }

  return (
    <Fragment>
      <div className={['position-fixed', position, 'z-top'].join(' ')}>
        {list.map((toast, i) => (
          <Card
            className={[
              `bg-${toast.color}-lighter`,
              'margin-bottom-1',
              'padding-0',
              'tablet-lg:padding-0',
            ].join(' ')}
            key={i}
          >
            <div
              className={[
                'notification',
                'display-flex',
                'flex-align-center',
                'flex-gap-sm',
                'flex-no-wrap',
                'flex-row',
                'font-sans-xs',
                'maxh-6',
                'overflow-hidden',
                'padding-1',
                'width-mobile',
              ].join(' ')}
            >
              {toast.iconName && (
                <Icon
                  color="white"
                  className={[`bg-${toast.color}-dark`, 'radius-pill'].join(
                    ' '
                  )}
                  iconName={toast.iconName}
                  iconPercent="60"
                  iconSize="md"
                />
              )}
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'font-sans-3xs',
                  'ellipsed-line-2',
                ].join(' ')}
              >
                {toast.description}
                {/*<Button
                  className="margin-top-auto padding-2px width-max-content"
                  // onClick={() => deleteToast(toast.id)}
                  reduced
                  size="3xs"
                  textOnly
                  thin
                >
                  Undo
                  </Button>*/}
              </div>

              <Button
                className={['margin-left-auto'].join(' ')}
                iconName="close"
                iconOnly
                iconSize="xs"
                onClick={() => deleteToast(toast.id)}
                reduced
                size="xs"
                srText="Remove this notification."
                textOnly
                // thin
              />
            </div>
          </Card>
        ))}
      </div>
    </Fragment>
  )
}

Toast.defaultProps = {
  autoDelete: false,
  autoDeleteTime: 3000,
  position: 'bottom-left',
  maxToasts: 2,
}

Toast.propTypes = {
  autoDelete: PropTypes.bool,
  autoDeleteTime: PropTypes.number,
  /**
   * The content provided to the component
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  maxToasts: PropTypes.number,
  position: PropTypes.string,
  toastList: PropTypes.array.isRequired,
}

export default Toast
