import React, { useContext } from 'react'
import '../../../styles/index.scss'
import AccountContext from '../../../contexts/AccountContext'
import Card from '../../Card/Card'
import { IconLabel } from '../../IconLabel/IconLabel'
import AccountContent, { ContentTitle } from '../account_content'

const Lists = () => {
  const accountState = useContext(AccountContext)

  return (
    <AccountContent>
      <ContentTitle iconName="list" title="Lists" />
    </AccountContent>
  )
}

export default Lists
