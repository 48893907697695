import React, { Fragment, useContext } from 'react'
import { useParams } from 'react-router-dom'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import { IconPropTypes } from '../settings/IconPropTypes'
//Components
import { Button } from '../Button/Button'
//Context
import AccountContext from '../../contexts/AccountContext'
import UserContext from '../../contexts/UserContext'
//Hooks
import useScreenSize from '../../hooks/use-screen-size'
import { Pill } from '../Pill/Pill'
// import useContributionsTotals from './public/use-contribution-totals'
import { returnNumberWithCommas } from '../../helper-functions/number-functions'

/**
 * Provides access to the various pages associated with the current displayed account
 */
export const AccountNav = () => {
  //Context
  const { loading } = useContext(AccountContext) || {}
  const { accountsAccessArray, isLoggedInUsersPage } =
    useContext(UserContext) || {}

  return (
    <div className="height-full">
      <div
        className={[
          'display-flex',
          'flex-align-self-start',
          'flex-gap',
          'flex-column',
          'minh-mobile-lg',
          'padding-1 tablet-lg:padding-2',
          'position-sticky',
          'text-base-darker',
          'top-0',
        ].join(' ')}
      >
        {loading ? (
          <div
            className={[
              'display-flex',
              'flex-column',
              loading ? 'flex-gap-xs' : '',
            ].join(' ')}
          >
            <div
              className={['block-load', 'height-2', 'width-8'].join(' ')}
            ></div>
            {[...Array(4)].map((e, i) => (
              <div
                className={['block-load', 'height-4', 'width-full'].join(' ')}
                key={i}
              ></div>
            ))}
          </div>
        ) : (
          <>
            <ActivityNav />
            {isLoggedInUsersPage && <AccountOptionsNav />}
            {accountsAccessArray.accessGrantedToModeratorTools &&
              isLoggedInUsersPage && <AccountModeratorNav />}
            {accountsAccessArray.accessGrantedToAccountAdminTools &&
              isLoggedInUsersPage && <AccountAdminNav />}
          </>
        )}
      </div>
    </div>
  )
}
AccountNav.propTypes = {}

export const ActivityNav = ({ children, ...props }) => {
  //Context
  const { accountsAccessArray } = useContext(UserContext) || {}

  //Hooks
  const { feedPage } = useParams()

  return (
    <div className={['display-flex', 'flex-column'].join(' ')}>
      <span
        className={[
          'display-none',
          'font-sans-xs',
          'mobile-lg:display-block',
          'text-base-dark',
        ].join(' ')}
      >
        Activity
      </span>
      <Button
        active={!feedPage || feedPage === ''}
        className="flex-justify width-full"
        iconName="activity"
        iconSize="xs"
        reduced
        size="xs"
        textAlign="left"
        textOnly={feedPage && feedPage !== ''}
        thin
        to={{ pathname: '' }}
      >
        <ActivityNavLabel id="totalContributions" label="Contributions" />
      </Button>
      <AccountNavButton iconName="tag" id="tags">
        <ActivityNavLabel id="totalTags" label="Tags" />
      </AccountNavButton>
      <AccountNavButton iconName="comment" id="comments">
        <ActivityNavLabel id="totalComments" label="Comments" />
      </AccountNavButton>
      <AccountNavButton iconName="transcription" id="transcriptions">
        <ActivityNavLabel id="totalTranscriptions" label="Transcriptions" />
      </AccountNavButton>
      {accountsAccessArray?.accessGrantedToAccountDetails && <LoggedInNav />}
    </div>
  )
}
ActivityNav.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

/**
 * Displays the contribution count to the right of the nav label
 */
export const ActivityNavLabel = ({ id, label, ...props }) => {
  const renderTotals = (contributionTotals, notificationTotals, id) => {
    const isNoties = id == 'totalNotifications'
    const renderVal =
      isNoties && notificationTotals
        ? returnNumberWithCommas(notificationTotals.notificationCount)
        : contributionTotals?.totals?.[id] >= 0 &&
          returnNumberWithCommas(contributionTotals?.totals?.[id])
    return isNoties && renderVal == 0 ? (
      <></>
    ) : (
      <Pill filled reduced size="3xs" theme="base-lighter" thin>
        {renderVal}
      </Pill>
    )
  }

  const {
    contributionTotals,
    notificationTotals,
    errorTotals: error,
    loadingTotals: loading,
  } = useContext(AccountContext) || {}
  return (
    <span
      className={[
        'display-inline-flex',
        'flex-align-center',
        'flex-gap-sm',
        'flex-row',
      ].join(' ')}
    >
      {label}
      {loading ? (
        <div
          className={[
            'block-load',
            'height-205',
            'no-margin',
            'radius-pill',
            'width-3',
          ].join(' ')}
        ></div>
      ) : (
        renderTotals(contributionTotals, notificationTotals, id)
      )}
    </span>
  )
}

ActivityNavLabel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
}

export const LoggedInNav = () => {
  return (
    <AccountNavButton iconName="action-bulb-on" id="insights" status="new">
      Insights
    </AccountNavButton>
  )
}
LoggedInNav.propTypes = {}

export const AccountOptionsNav = () => {
  return (
    <Fragment>
      <hr className={['border-base-lighter'].join(' ')} />
      <div className={['display-flex', 'flex-column'].join(' ')}>
        <span
          className={[
            'display-none',
            'font-sans-xs',
            'mobile-lg:display-block',
            'text-base-dark',
          ].join(' ')}
        >
          Account
        </span>
        <AccountNavButton iconName="list" id="lists" status="soon">
          Lists
        </AccountNavButton>
        <AccountNavButton
          iconName="action-cloud-down"
          id="downloads"
          status="soon"
        >
          Downloads
        </AccountNavButton>
        <AccountNavButton iconName="notification-bell-on" id="notifications">
          <ActivityNavLabel id="totalNotifications" label="Notifications" />
        </AccountNavButton>
        <AccountNavButton iconName="gear" id="settings" status="new">
          Settings
        </AccountNavButton>
      </div>
    </Fragment>
  )
}
AccountOptionsNav.propTypes = {}

export const AccountModeratorNav = () => {
  const { accountsAccessArray } = useContext(UserContext) || {}
  return (
    <Fragment>
      <hr className={['border-base-lighter'].join(' ')} />
      <div className={['display-flex', 'flex-column'].join(' ')}>
        <span
          className={[
            'display-none',
            'font-sans-xs',
            'mobile-lg:display-block',
            'text-base-dark',
          ].join(' ')}
        >
          Moderator Dashboard
        </span>
        <AccountNavButton
          iconName="tool"
          id="moderator-dashboard"
          status="soon"
        >
          Recent Moderations
        </AccountNavButton>
        <AccountNavButton
          iconName="tool-tag"
          id="moderator-dashboard/moderate-tags"
          state={{ contributionType: 'tag' }}
        >
          Moderate Tags
        </AccountNavButton>
        <AccountNavButton
          iconName="tool-comment"
          id="moderator-dashboard/moderate-comments"
          state={{ contributionType: 'comment' }}
        >
          Moderate Comments
        </AccountNavButton>
        <AccountNavButton
          iconName="tool-transcription"
          id="moderator-dashboard/moderate-transcriptions"
          state={{ contributionType: 'transcription' }}
        >
          Moderate Transcriptions
        </AccountNavButton>
        <AccountNavButton
          iconName="check"
          id="moderator-dashboard/online-availability"
        >
          Online Availability
        </AccountNavButton>
        {accountsAccessArray.accessGrantedToAccountAdminTools && (
          <AccountNavButton
            iconName="image"
            id="moderator-dashboard/background-images"
          >
            Background Images
          </AccountNavButton>
        )}
        <AccountNavButton
          iconName="home"
          id="moderator-dashboard/moderate-announcements"
        >
          Homepage Banner
        </AccountNavButton>
        <AccountNavButton
          iconName="square"
          id="moderator-dashboard/moderate-homepage-boxes"
        >
          Homepage Boxes
        </AccountNavButton>
      </div>
    </Fragment>
  )
}
AccountModeratorNav.propTypes = {}

export const AccountAdminNav = () => {
  return (
    <Fragment>
      <hr className={['border-base-lighter'].join(' ')} />
      <div className={['display-flex', 'flex-column'].join(' ')}>
        <span
          className={[
            'display-none',
            'font-sans-xs',
            'mobile-lg:display-block',
            'text-base-dark',
          ].join(' ')}
        >
          Admin Dashboard
        </span>
        <AccountNavButton iconName="group" id="moderator-dashboard/accounts">
          Accounts
        </AccountNavButton>
      </div>
    </Fragment>
  )
}
AccountAdminNav.propTypes = {}

export const AccountNavButton = ({
  children,
  iconName,
  id,
  state,
  status,
  ...props
}) => {
  //Hooks
  const { screenSize } = useScreenSize()
  const { feedPage, moderationPage } = useParams()
  const checkId = moderationPage ? `${feedPage}/${moderationPage}` : feedPage
  return (
    <Button
      active={checkId === id}
      className="flex-justify width-full"
      iconName={iconName}
      iconSize="xs"
      // reduced
      size="xs"
      textAlign="left"
      textOnly={checkId !== id}
      thin
      to={{ pathname: id, state: state }}
      {...props}
    >
      {children}
      {status && <AccountNavStatus status={status} />}
    </Button>
  )
}

AccountNavButton.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Defines the icon to use.
   */
  iconName: IconPropTypes,
  /**
   * Defines the page
   */
  id: PropTypes.string,
  /**
   *
   */
  state: PropTypes.object,
  /**
   * Indicates a new menu option
   */
  status: PropTypes.string,
}

const AccountNavStatus = ({ status }) => {
  let bg = 'base-lighter',
    label = status,
    theme = 'base-dark'
  switch (status) {
    case 'new':
      bg = 'success-lighter'
      label = 'New'
      theme = 'success-darker'
      break
    case 'soon':
      bg = 'warning-lighter'
      label = 'Upcoming'
      theme = 'warning-dark'

      break
  }
  return (
    <Pill
      className={[
        `bg-${bg}`,
        'flex-justify-center',
        'margin-left-auto',
        'text-uppercase',
        'font-sans-5xs',
      ].join(' ')}
      radius="md"
      reduced
      size="3xs"
      theme={theme}
    >
      {label}
    </Pill>
  )
}
AccountNavStatus.propTypes = { status: PropTypes.oneOf(['soon', 'new']) }
