import React, { useContext, useEffect, useState } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import UserContext from '../../contexts/UserContext'
//Components
import BasicLink from '../BasicLink'
//Helpers
import { returnIntegerFromString } from '../../helper-functions/number-functions'
import { Icon } from '../Icon/Icon'

/**
 * Avatar component displays the available image or generic icon for users.
 */
const Avatar = ({
  className,
  loading,
  isMadeByAdministrator,
  isNaraStaff,
  size,
  src,
  userName,
  ...props
}) => {
  const { loggedInUser } = useContext(UserContext) || {}
  const [source, setSource] = useState(src)

  useEffect(() => {
    if (!userName) return false
    if (!src)
      setSource(
        `/images/avatars/${userName
          .charAt(0)
          .toLowerCase()}-${returnIntegerFromString(
          userName || '',
          [1, 2]
        )}.png`
      )
  }, [userName])

  let avatarSize,
    borderSize,
    statusIconPosition,
    statusIconSize,
    statusIconStrokeThickness

  switch (size) {
    case 'xs':
      avatarSize = '3'
      borderSize = '2px'
      statusIconPosition = 'bottom-neg-1 left-half'
      statusIconSize = 'xs'
      break
    case 'sm':
      avatarSize = '4'
      borderSize = '2px'
      statusIconPosition = 'bottom-neg-1 left-half'
      statusIconSize = 'xs'
      break
    case 'md':
      avatarSize = '5'
      borderSize = '05'
      statusIconPosition = 'bottom-neg-105 left-half'
      statusIconSize = 'sm'
      break
    case 'lg':
      avatarSize = '10'
      borderSize = '1'
      statusIconPosition = 'bottom-neg-2 left-half'
      statusIconStrokeThickness = 'thick'
      statusIconSize = 'sm'
      break
    case 'xl':
      avatarSize = '15'
      borderSize = '1'
      statusIconPosition = 'bottom-neg-2 left-half'
      statusIconStrokeThickness = 'thick'
      statusIconSize = 'lg'
  }

  return (
    <BasicLink
      className={[
        `circle-${avatarSize}`,
        'display-inline-block',
        'position-relative',
        className,
      ].join(' ')}
      // Give an empty object to signal no link (disabled link)
      // If isMadeByAdministrator doesn't exist or is 0, link normally. Otherwise, disable.
      to={!isMadeByAdministrator ? { pathname: `/accounts/${userName}` } : {}}
      {...props}
    >
      <div
        className={[
          loading ? 'block-load' : '',
          `circle-${avatarSize}`,
          // isNaraStaff
          //   ? [`border-${borderSize}`, 'border-accent-cool-dark'].join(' ')
          //   : '',
          !loading
            ? userName == loggedInUser?.userName
              ? 'bg-primary-lighter nac-icon--primary'
              : [
                  'bg-primary',
                  'nac-icon--white',
                  'nac-icon',
                  'nac-icon--person',
                  'nac-icon--thin',
                  'nac-icon--bg-50',
                ].join(' ')
            : '',
          'overflow-hidden',
        ].join(' ')}
      >
        <img
          alt=""
          role="presentation"
          onError={(e) => {
            e.target.onerror = null
            e.target.style.display = 'none'
          }}
          className={[
            'height-full',
            'object-fit-cover',
            'transform-scale-4',
            'vertical-align-middle',
            'width-full',
          ].join(' ')}
          src={source}
        />
      </div>{' '}
      {isNaraStaff && (
        <Icon
          className={[
            'bg-accent-cool-light',
            'position-absolute',
            'left-5',
            'radius-pill',
            statusIconPosition,
          ].join(' ')}
          color="primary-dark"
          iconName="institution"
          iconPercent="50"
          iconSize={statusIconSize}
          strokeThickness={statusIconStrokeThickness}
        />
      )}
    </BasicLink>
  )
}
Avatar.defaultProps = {
  size: 'sm',
}

Avatar.propTypes = {
  /**
   * Additional classes to be applied to the link
   */
  className: PropTypes.string,
  /**
   * Forces the content loading state for the current avatar.
   * For use when the user data is being retrieved from the database.
   */
  loading: PropTypes.bool,
  /**
   * Do/do not link to the user page
   */
  isMadeByAdministrator: PropTypes.number,
  /**
   * Display NARA staff identifier
   */
  isNaraStaff: PropTypes.bool,
  /**
   * The size of the avatar image based on USWDS integer units
   */
  size: PropTypes.oneOf(['2xs', 'xs', 'sm', 'md', 'lg', 'xl']),
  /**
   * Optionally provide a predetermined image src to display
   */
  src: PropTypes.string,
  /**
   * Screen name of the user
   */
  userName: PropTypes.string,
}

export default Avatar
