export const commentsReducer = (state, action) => {
  let commentsArray, newComments, newCounts
  switch (action.type) {
    case 'FETCH_COMMENTS':
      newCounts = setCommentCounts(action.comments)
      return { comments: action.comments, counts: newCounts }

    case 'UPDATE_COUNTS':
      setCommentCounts(action)
      break

    case 'ADD_COMMENT':
      //Add child-level comment
      if (action.parentContributionId) {
        newComments = state.comments.map((comment) => {
          if (comment.contributionId == action.parentContributionId) {
            comment.children.push({
              userId: action.userId,
              fullName: action.fullName || '',
              userName: action.userName || '',
              contribution: action.contribution,
              contributionId: action.contributionId,
              contributionType: 'comment',
              madeByAdministrator: action.madeByAdministrator,
              naraStaff: action.naraStaff,
              parentContributionId: action.parentContributionId,
              recordType: 'contribution',
              updatedAt: action.updatedAt,
              status: 'active',
              targetNaId: action.targetNaId,
              targetObjectId: action.targetObjectId
                ? action.targetObjectId.toString()
                : null,
              createdAt: action.createdAt,
            })
          }
          return comment
        })
      }
      //Add parent-level comment
      else {
        newComments = [
          ...(state.comments || []),
          {
            userId: action.userId,
            fullName: action.fullName || '',
            userName: action.userName || '',
            targetObjectId: action.targetObjectId
              ? action.targetObjectId.toString()
              : null,
            targetNaId: action.targetNaId,
            children: [],
            contribution: action.contribution,
            contributionId: action.contributionId,
            createdAt: action.createdAt,
            contributionType: 'comment',
            recordType: 'contribution',
            madeByAdministrator: action.madeByAdministrator,
            naraStaff: action.naraStaff,
            status: 'active',
            target: {
              objectId: action.targetObjectId || null,
              naId: action.targetNaId,
            },
            updatedAt: action.createdAt,
          },
        ]
      }

      newCounts = setCommentCounts(newComments)
      return { comments: newComments, counts: newCounts }

    case 'REMOVE_COMMENT':
      newComments = state.comments
        .map((comment) => {
          if (comment.contributionId == action.parentContributionId) {
            comment.children = comment.children.filter(
              (child) => child.contributionId !== action.contributionId
            )
            return comment
          } else if (
            comment.contributionId == action.contributionId &&
            comment.children?.length > 0
          )
            comment.status == 'inactive'

          return comment
        })
        .filter((comment) => {
          if (comment.contributionId == action.contributionId) {
            if (comment.children?.length == 0) {
              return comment.contributionId !== action.contributionId
            } else {
              comment.status = 'inactive'
            }
          }
          return comment
        })
      newCounts = setCommentCounts(newComments)
      return { comments: newComments, counts: newCounts }

    case 'EDIT_COMMENT':
      newComments = state.comments.map((comment) => {
        if (comment.contributionId == action.contributionId) {
          comment.contribution = action.contribution
          comment.updatedAt = action.updatedAt
        }
        if (
          action.parentContributionId == comment.contributionId &&
          comment.children
        ) {
          comment.children.map((child) => {
            if (child.contributionId == action.contributionId) {
              child.contribution = action.contribution
              child.updatedAt = action.updatedAt
            }
          })
        }
        return comment
      })
      newCounts = setCommentCounts(newComments)
      return { comments: newComments, counts: newCounts }

    default:
      return { comments: state.comments, counts: state.counts }
  }
}

//Helpers
export const setCommentCounts = (comments) => {
  let descriptionCommentCount = 0
  let totalCommentCount = 0
  let objectCommentCounts = {}
  //Loop through the comments that are returned
  comments.map((comment) => {
    //Update the total comment count, inclulding child comments
    totalCommentCount++
    if (comment.children?.length > 0) {
      totalCommentCount += comment.children.length
    }
    //Update description-level comment count, including their child-level comments
    if (!comment.targetObjectId) {
      descriptionCommentCount++
      if (comment.children?.length > 0) {
        descriptionCommentCount += comment.children.length
      }
      //Update object-level comment count, including their child-level comments
    } else {
      //If the objectId already exists, update the count value
      if (objectCommentCounts[comment.targetObjectId])
        objectCommentCounts[comment.targetObjectId] =
          objectCommentCounts[comment.targetObjectId] +
          1 +
          (comment.children?.length || 0)
      //If the objectId doesn't exists, add a new entry
      else
        objectCommentCounts[comment.targetObjectId] =
          1 + (comment.children?.length || 0)
    }
    return comment
  })
  return {
    totalCommentCount: parseInt(totalCommentCount),
    descriptionCommentCount: parseInt(descriptionCommentCount),
    objectCommentCounts: objectCommentCounts,
  }
}
