import React, { useEffect, useState } from 'react'
import '../../styles/index.scss'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import DropdownField from './DropdownField'

const ResultsPerPage = ({ handleUpdateValue, ...props }) => {
  //Default Results Per Page options
  const values = ['20', '50', '75', '100']

  const { search } = useLocation()
  const query = queryString.parse(search)

  const [active, setActive] = useState('20')

  useEffect(() => {
    if (query.limit) setActive(query.limit)
  }, [])

  const handleOnChange = (event) => {
    let value = event.target.value
    handleUpdateValue(value)
  }

  return (
    <div className="display-flex margin-left-auto" {...props}>
      <DropdownField
        onChange={handleOnChange}
        value={values.includes(active) ? active : '--'}
        label="Results per page:"
        id="results-per-page"
        data-testid="nac-results--resultsperpage"
      >
        {!values.includes(active) && (
          <option data-testid="nac-results--resultsperpage-custom" value="--">
            --
          </option>
        )}
        {values.map((val) => (
          <option
            data-testid={`nac-results--resultsperpage-${val.toString()}`}
            key={val}
            value={val}
          >
            {val}
          </option>
        ))}
      </DropdownField>
    </div>
  )
}

ResultsPerPage.defaultProps = {
  handleUpdateValue: () => {},
}

ResultsPerPage.propTypes = {
  handleUpdateValue: PropTypes.func,
}
export default ResultsPerPage
