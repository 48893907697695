import React, { Fragment } from 'react'
import '../../styles/index.scss'
//PropTypes
import PropTypes from 'prop-types'
import { inputSchemaPropTypes } from '../../schemas/input-schemas/input-schema-prop-types'
//Components
import { IconLabel } from '../IconLabel/IconLabel'
import { ListSeparator } from '../ListSeparator/ListSeparator'

const InputMessage = ({ schema, message, ...props }) => {
  const messageMessageClasses =
    message?.type === 'error'
      ? 'text-error-dark'
      : message?.type === 'warning'
      ? 'text-warning-dark'
      : ''

  let examplesJSX = []
  if (schema.examples) {
    const length = schema.examples.length
    for (var num = 0; num < length; num++) {
      examplesJSX.push([<span key={num}>{schema.examples[num]}</span>])
    }
  }

  return (
    <div
      aria-atomic="true"
      aria-describedby={schema.id}
      className={[
        messageMessageClasses,
        //
        message?.message || schema.examples ? 'active' : '',
        'anim-duration-500ms',
        'anim-opacity',
        'display-flex',
        'flex-align-center',
        'flex-row',
        'font-sans-3xs',
        'position-relative',
        'margin-top-neg-205',
        'minh-205',
      ].join(' ')}
      data-testid={`nac-input_${schema.id}--message`}
      role="alert"
    >
      <IconLabel
        align="start"
        color={
          message?.type === 'error'
            ? 'error-dark'
            : message?.type === 'warning'
            ? 'warning-dark'
            : 'base-darker'
        }
        iconName={
          message?.type === 'error' || message?.type === 'warning'
            ? 'triangle-exclaim'
            : null
        }
        iconSize="xs"
        size="2xs"
      >
        {message ? (
          message.message
        ) : schema.examples?.length > 0 ? (
          <Fragment>
            <span className="usa-sr-only">For example,</span>
            <span aria-hidden className="margin-right-1">
              examples:
            </span>
            <ListSeparator
              className={schema.examples ? 'text-italic' : ''}
              gap="2px"
            >
              {examplesJSX}
            </ListSeparator>
          </Fragment>
        ) : (
          ''
        )}
      </IconLabel>
    </div>
  )
}

InputMessage.defaultProps = {
  schema: [],
}

InputMessage.propTypes = {
  /* Details used for building and validating a specific input field */
  schema: inputSchemaPropTypes,
  /* Current message object related to the input, containing the message and the message type */
  message: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.oneOf(['error', 'warning']),
  }),
}

InputMessage.displayName = 'InputMessage'
export default InputMessage
