import React, { useContext } from 'react'
import AccountContext from '../../../contexts/AccountContext'
import Card from '../../Card/Card'
import { Table } from '../../Table/Table'
import '../../../styles/index.scss'
import AccountContent, { ContentTitle } from '../account_content'
import { timeSince } from '../../../helper-functions/date-functions'
import useNotifications from './use-notifications'
import {
  DEFAULT_PAGE,
  DEFAULT_CONTRIBUTION_TABLE_LIMIT,
} from '../../settings/globals'
import { Button } from '../../Button/Button'

const Notifications = () => {
  const accountState = useContext(AccountContext)
  const {
    columns,
    table,
    limit,
    total,
    page,
    handleSetPage,
    showPrevious,
    setShowPrevious,
  } = useNotifications(accountState)

  const num = Math.ceil((accountState?.notificationTotals ? total : 0) / limit)
  const pageCount = num > 0 ? num : null
  return (
    <AccountContent>
      <ContentTitle iconName="notification-bell-on" title="Notifications" />
      <div className="overflow-x-scroll margin-x-neg-2 tablet:margin-x-neg-0 padding-x-0 tablet-lg:padding-x-2 padding-bottom-2 tablet:padding-bottom-0">
        <Card
          className="padding-0 tablet-lg:padding-0 margin-x-1 tablet-lg:margin-x-0"
          style={{ minWidth: '38rem' }}
        >
          <Table
            caption={
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'flex-gap-sm',
                  'text-bold',
                ].join(' ')}
              >
                <h2 className="margin-0">
                  {accountState?.notificationTotals?.notificationCount > 0
                    ? accountState?.notificationTotals?.notificationCount +
                      ` new
                notifications since -` +
                      timeSince(
                        accountState?.account?.lastViewedNotificationsAt
                      )
                    : 'No new notifications'}
                </h2>
              </div>
            }
            className="padding-0"
            columns={columns}
            data={table}
            pageCount={pageCount}
            initialPage={DEFAULT_PAGE}
            pageNum={page}
            setPage={handleSetPage}
            id="nac-user-notifications-table"
          />
        </Card>
      </div>
      {!showPrevious ? (
        <div>
          <Button outline size="xs" thin onClick={() => setShowPrevious(true)}>
            Show Previous Notifications
          </Button>
        </div>
      ) : (
        <></>
      )}
    </AccountContent>
  )
}

export default Notifications
