import React, { useContext } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import ContributionContext from '../../contexts/ContributionContext'
//Components
import TagInput from './contributions/tags/tag_input'
import TagGroup from './contributions/tags/tag_group'
import { SectionHeading } from '../SectionHeading/SectionHeading'

import tagSort from '../utilities/Sorting'

const SectionTags = ({ disabled, ...props }) => {
  const { tags: recordTags } = useContext(ContributionContext)

  const tags =
    recordTags
      ?.filter((tag) => !tag.targetObjectId && tag.status == 'active')
      .sort(tagSort) || []

  function tagCount(arr) {
    let c = 0
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].status == 'active') {
        c++
      }
    }
    return c
  }

  return disabled && tags.length === 0 ? (
    ''
  ) : (
    <div className={['width-full'].join(' ')} {...props}>
      <SectionHeading copyLink count={tagCount(tags)}>
        Tags <span className="usa-sr-only">Section</span>
      </SectionHeading>
      {!disabled && <TagInput tags={tags} />}
      <TagGroup tags={tags} />
    </div>
  )
}

export default SectionTags

SectionTags.defaultProps = {
  disabled: false,
}

SectionTags.propTypes = {
  /**
   * Stops contributions by preventing the users from accessing the input.
   */
  disabled: PropTypes.bool,
}
