export const formReducer = (state, action) => {
  let newFields,
    newParams,
    newTemp,
    newErr,
    newVis,
    value,
    index,
    paramString = []
  switch (action.type) {
    case 'SET_FORM':
      return {
        errors: action.errors || state.errors || {},
        params: action.params || state.params || {},
        // temp: action.temp || state.temp || {},
        fields: action.fields || state.fields || {},
        visible: action.visible || state.visible || {},
      }

    case 'SET_FORM_ERRORS':
      newErr = state.errors
      if (action.error) {
        if (
          newErr[action.id] &&
          newErr[action.message] === action.error.message
        )
          return state
        else newErr[action.id] = action.error
      } else if (newErr[action.id]) delete newErr[action.id]
      return { ...state, errors: newErr }

    case 'UPDATE_FORM_FIELD':
      if (!action.id && !action.value) return state
      newFields = { ...state }
      if (!action.id) {
        return {
          ...state,
          fields: action.value,
        }
      } else if (!action.value) {
        delete newFields.fields[action.id]
        return newFields
      } else {
        if (
          typeof action.value === 'string' &&
          action?.value?.indexOf('availableOnline') > -1 &&
          action?.value?.split('&')?.length > 1
        ) {
          newTemp = action?.value?.split('&')[1].split('=')
          if (newTemp[1] === 'false') {
            delete newFields.fields['availableOnline']
          } else newFields.fields[newTemp[0]] = newTemp[1]
          newFields.fields[action.id] = action?.value?.split('&')[0]
        } else newFields.fields[action.id] = action.value
      }

      return newFields

    default:
      return state
  }
}

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach(
    (k) => !obj[k] && typeof obj[k] !== 'undefined' && delete obj[k]
  )
  return obj
}

export const reduceObject = (obj) => {
  if (!obj || typeof obj === 'undefined') return
  removeEmpty(
    Object.assign(
      {},
      ...(function _flatten(o) {
        return [].concat(
          ...Object.keys(o).map((k) =>
            typeof o[k] === 'object' ? _flatten(o[k]) : { [k]: o[k] }
          )
        )
      })(obj)
    )
  )
}
