// Essentials
import React from 'react'
import '../styles/index.scss'
import PropTypes from 'prop-types'
// Components
import Modal, { ModalBody, ModalFooter } from './Modal/Modal'
import { Link } from 'react-router-dom'
import Logo from './Logo/Logo'
import { Button } from './Button/Button'

// Hooks
import useModal from '../hooks/use-modal'

function isValidHttpUrl(string) {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const isExternalURL = (href) => {
  if (isValidHttpUrl(href)) {
    return new URL(href).host !== location.host
  } else {
    return false
  }
}
export const isThirdPartyURL = (href) => {
  if (isValidHttpUrl(href)) {
    let url = new URL(href)
    return url.host !== location.host && url.host.indexOf('archives.gov') == -1
  } else {
    return false
  }
}

const BasicLink = ({
  className,
  children,
  href,
  srText,
  title,
  to,
  ...props
}) => {
  // Separate component from the rest of the BasicLink for readability
  const ExternalUrlModal = ({ ...props }) => {
    const { isShowing, toggle } = useModal()

    return (
      <>
        <a
          className={[
            'usa-link--external',
            'clickable',
            'display-inline-flex',
            'flex-align-center',
            'flex-gap-2px',
            className,
          ].join(' ')}
          data-testid={`link-external__${href}`}
          onClick={toggle}
          role="link"
          title={title || `Go to ${href} in a new tab.`}
          {...props}
        >
          {children}
          {srText && <span className="usa-sr-only">{srText}</span>}
        </a>
        {isShowing && (
          <Modal
            className="width-mobile-lg"
            hide={toggle}
            id={`external-link`}
            size="lg"
            title="You Are About to Leave the Catalog Website"
          >
            <ModalBody id={`external-url`}>
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'flex-align-start',
                  'flex-gap',
                  'theme-white',
                ].join(' ')}
                data-testid={`link-external__modal`}
              >
                {/* <Logo /> */}
                <div className={['text-center', 'width-full'].join(' ')}>
                  <p>If you choose to continue, you will be transferred to:</p>
                  <p>
                    <strong>{href}</strong>
                  </p>
                  <p>Thank you for visiting the National Archives online!</p>
                </div>
                <p
                  className={[
                    'font-sans-2xs',
                    'line-height-sans-2',
                    'text-base-dark',
                  ].join(' ')}
                >
                  We have provided a link to this site because it has
                  information that may interest you. This link is not an
                  endorsement by the National Archives of the opinions,
                  products, or services presented on this site, or any sites
                  linked to it. The National Archives is not responsible for the
                  legality or accuracy of information on this site, the
                  policies, or for any costs incurred while using this site.
                </p>
              </div>
            </ModalBody>
            <ModalFooter hide={toggle}>
              <div
                className={['flex-row', 'grid-row', 'flex-justify'].join(' ')}
              >
                <Button
                  className={[''].join(' ')}
                  data-testid={`link-external__close-modal`}
                  onClick={toggle}
                  outline
                >
                  Stay Here
                </Button>
                <Button className={[''].join(' ')}>
                  <a
                    className={['usa-link--white'].join(' ')}
                    href={href}
                    rel="noopener noreferrer"
                    role="link"
                    title={title || `Go to ${href} in a new tab.`}
                    target="_blank"
                  >
                    Leave the Catalog
                  </a>
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </>
    )
  }

  return to?.pathname ? (
    <Link
      className={className}
      state={to.state}
      title={title || `Go to ${to.pathname}.`}
      to={{ pathname: to.pathname, search: to.search }}
      {...props}
    >
      {children}
      {srText && <span className="usa-sr-only">{srText}</span>}
    </Link>
  ) : href ? (
    isThirdPartyURL(href) ? (
      <ExternalUrlModal />
    ) : (
      <a
        className={[
          isExternalURL(href) ? 'usa-link--external' : '',
          className,
        ].join(' ')}
        href={href}
        rel="noopener noreferrer"
        role="link"
        title={title || `Go to ${href} in a new tab.`}
        target="_blank"
        onClick={(event) => {
          event.preventDefault()
          window.open(href, '_blank')
        }}
        {...props}
      >
        {children}
        {srText && <span className="usa-sr-only">{srText}</span>}
      </a>
    )
  ) : (
    <span className={className} {...props}>
      {children}
    </span>
  )
}

BasicLink.defaultProps = {
  srText: '',
}

BasicLink.propTypes = {
  className: PropTypes.string,
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  href: PropTypes.string,
  srText: PropTypes.string,
  title: PropTypes.string,
  /**
   * The path and state object used to navigate to a specific page via the React Link component.
   */
  to: PropTypes.object,
}

export default BasicLink
