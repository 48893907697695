import React from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import { DEFAULT_MAX_CONTENT_WIDTH } from '../settings/globals'

const Section = ({ children, className, ...props }) => {
  return (
    <section className={[className].join(' ')} {...props}>
      <div
        className={[
          'display-flex',
          'flex-column',
          'flex-gap-lg',
          `maxw-${DEFAULT_MAX_CONTENT_WIDTH}`,
        ].join(' ')}
      >
        {children}
      </div>
    </section>
  )
}

Section.propTypes = {
  /**
   * Classes to apply to the section wrapper
   */
  className: PropTypes.string,
  /**
   * The content provided to the alert.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
}

export default Section
