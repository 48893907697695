import { useContext, useEffect, useState } from 'react'
import FormContext from '../../contexts/FormContext'
import SearchContext from '../../contexts/SearchContext'

const useRepeatableFields = (id, refs, schema) => {
  //Context
  const { formDispatch, formFields, setTouched, touched } =
    useContext(FormContext)
  const { activeQueryParams, handleActiveQueryParams, setSearchQuery } =
    useContext(SearchContext) || {}

  //State
  const [rules, setRules] = useState(
    schema?.filter((s) => s.isDefault || s.isPlaceholder) || [schema[0]]
  )
  const fields = formFields[id]
  const [values, setValues] = useState({})
  const [options, setOptions] = useState(
    schema.filter(
      (s) => values && !Object.keys(values).some((key) => key === s.id)
    )
  )
  const [removeRuleBool, setRemoveRuleBool] = useState(false)

  //Vars
  // Set to true when the repeatable fields group has at least one, non-blank value
  const fieldsAreEmpty = isNullOrEmpty(clearNullEmpties(fields))

  //Functions
  const addRule = () => {
    setTouched(true)
    // When adding a rule, default the next rule id to the first of the remaining options
    const newValues = { ...values, [options[0]?.id]: '' }
    setValues(newValues)
    if (!rules.find((r) => r.id === options[0]?.id)) {
      setRules([...rules, schema.find((s) => s.id === options[0]?.id)])
    }
  }

  const resetSearch = () => {
    sessionStorage.removeItem('search')
    setSearchQuery('')
    let newParams = Object.assign({}, activeQueryParams)
    newParams.q = ''
    handleActiveQueryParams(newParams)
  }

  const removeRule = (idToRemove) => {
    if (idToRemove === 'q') {
      resetSearch()
    }
    if (rules.length === 1) resetRules()
    else {
      const newValues = { ...values } // Prevent state mutation
      delete newValues[idToRemove]
      setValues(newValues)
      let newRules = []
      rules.map((r) => {
        if (idToRemove !== r.id) {
          newRules.push(r)
        }
      })
      setRules(newRules)
      formDispatch({
        type: 'REMOVE_FORM_FIELDS',
        id: idToRemove,
      })
      formDispatch({
        type: 'UPDATE_FORM_FIELD',
        id: id,
        value: newValues,
      })
      setRemoveRuleBool(true)
    }
  }

  // When rules are updated options are updated
  useEffect(() => {
    setOptions(
      schema.filter((s) => !Object.keys(values).some((key) => key === s.id))
    )
  }, [rules])

  const resetRules = () => {
    // Reset the values to be the empty default
    schema.map((s) => {
      if (s.isDefault || s.isPlaceholder) {
        setValues({ [s.id]: '' })
      }
    })

    // Execute the reset function for all related refs
    refs?.current.map((ref) => {
      ref?.reset()
    })

    formDispatch({
      type: 'UPDATE_FORM_FIELD',
      id: id,
      value: {},
    })
    setRules(
      schema?.filter((s) => s.isDefault || s.isPlaceholder) || [schema[0]]
    )
  }

  const resetFields = () => {
    resetSearch()
    resetRules()
  }

  const changeValueHandler = (idToChange, value, replace = null) => {
    // Prevent state mutation
    let newValues = { ...values }
    // Set the provided value for the provided key
    newValues[idToChange] = value
    // If replace exists, a new select option has been chosen
    // Remove the previous key/value pair and add a new specified key with the same value
    if (replace !== idToChange) {
      delete newValues[replace]
      // Update rules
      let newRules = [...rules]
      const ruleToAdd = schema.find((s) => s.id === idToChange)
      if (replace && ruleToAdd) {
        newRules = newRules.map((r) => (r.id === replace ? ruleToAdd : r))
      }
      setRules(newRules)
    }
    setValues(newValues)
    formDispatch({
      type: 'UPDATE_FORM_FIELD',
      id: id,
      value: newValues,
    })
  }

  useEffect(() => {
    schema.map((s) => {
      if (s.isDefault || s.isPlaceholder) {
        setValues({ [s.id]: '' })
      }
    })
  }, [])

  useEffect(() => {
    setOptions(
      schema.filter((s) => !Object.keys(values).some((key) => key === s.id))
    )
  }, [schema.length])

  useEffect(() => {
    if (!touched) resetRules(schema)
  }, [touched])

  return {
    addRule,
    changeValueHandler,
    fieldsAreEmpty,
    options,
    removeRule,
    resetRules,
    resetFields,
    rules,
    values,
    removeRuleBool,
    setRemoveRuleBool,
  }
}
export default useRepeatableFields

export const isNullOrEmpty = (obj) =>
  obj === null ||
  obj === '' ||
  typeof obj == 'undefined' ||
  (Object(obj) === obj && Object.keys(obj).length === 0)

export const clearNullEmpties = (obj) =>
  Object(obj) === obj
    ? Object.fromEntries(
        Object.entries(obj).flatMap(([k, v]) => {
          const val = clearNullEmpties(v)
          return isNullOrEmpty(val) ? [] : [[k, val]]
        })
      )
    : obj
