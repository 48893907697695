export const tagsReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_TAGS':
      return action.tags

    case 'ADD_TAG':
      return [...state, action.tag]

    case 'REMOVE_TAG':
      return state.filter((tag) => tag.contributionId !== action.contributionId)
    default:
      return state
  }
}
