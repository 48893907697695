import { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import queryString from 'query-string'
import { returnValidatedUrlParameters } from '../helper-functions/param-functions'

const useUrlParams = (state) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  /**
   * 1. SETUP
   * Build an array of all the params, where the key is the param name.
   * React router v6 does not provide a method to do this easily.
   */
  const params = useMemo(() => {
    let paramsArray = []
    searchParams.forEach((key, value) => {
      paramsArray[`${value}`] = key
    })
    /*
     * 2. VALIDATE
     * Validate each parameter against the expected params schema.
     * Any parameters that fail validation will be removed.
     * This includes parameters that do not align with exected prop types (string, number, etc)...
     * ...or params with an unexpected key.
     *  */
    const { validatedParams, paramsToRemove } =
      returnValidatedUrlParameters(paramsArray)

    paramsToRemove.map((p) => {
      searchParams.delete(p)
    })

    return validatedParams
  }, [searchParams])

  /*
   * 3. UPDATE URL
   * Update the url to reflect removed params by...
   * ...replacing the search, not pushing to the history
   * using the following functions:
   *  */

  /**
   * PARAM FUNCTIONS
   */

  /**
   * Adds params to the URL state.
   *
   * @param {Object} paramsToUpdate  Key value pairs for each param to add
   *                                 ex: { page: '1', contributionId: '1234567890' }
   */
  const updateParams = (paramsToUpdate) => {
    if (!paramsToUpdate || paramsToUpdate.length === 0) return false
    if (paramsToUpdate.constructor !== Object)
      return console.warn(
        `Warning: Failed prop type: Invalid prop 'paramsToUpdate' of type '${typeof paramsToUpdate}' supplied to the URL parameters, expected 'object'.`
      )

    // Validate params
    const { validatedParams } = returnValidatedUrlParameters(paramsToUpdate)
    if (!validatedParams || validatedParams.length === 0)
      navigate({ search: '' })

    // If existing and validated params are identical, do nothing.
    if (objectsAreIdentical(params, validatedParams)) return false

    // Check if params already exist.
    for (const [key, value] of Object.entries(paramsToUpdate)) {
      // If so, modify existing param.
      if (
        // (existingParams[key] && existingParams[key] !== value) ||
        value == '' ||
        !value
      ) {
        // If the value we are updating is empty, delete the param.
        searchParams.delete(key)
        // Otherwise update it.
      } else searchParams.set(key, value)
    }

    // Finally, update the URL state.
    setSearchParams(searchParams, { replace: true, state: state })
  }

  /**
   * Removes params from the URL state.
   *
   * @param {Array} paramsToRemove   Array of strings for each param name to remove
   *                                 ex: [ 'page', 'contributionId' ]
   */
  const removeParams = (paramsToRemove) => {
    if (!paramsToRemove || paramsToRemove.length === 0) return false
    if (paramsToRemove.constructor !== Array)
      return console.warn(
        `Warning: Failed prop type: Invalid prop 'paramsToRemove' of type '${typeof paramsToRemove}' supplied to the URL parameters, expected 'array'.`
      )
    // Check if params names exist.
    paramsToRemove.map((p) => {
      searchParams.delete(p)
    })
    setSearchParams(searchParams, { replace: true, state: state })
  }

  return { updateParams, removeParams, params }
}

export default useUrlParams

export const objectsAreIdentical = (first, second) => {
  for (var i in second) {
    if (!first[i] || second[i] !== first[i]) {
      return false
    }
  }
  return true
}
