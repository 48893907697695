import { useEffect, useState } from 'react'
import {
  useSafeAsync,
  removeEmptyObjectFields,
} from '../helper-functions/query-functions'

import axios from 'axios'

const useAxois = (requestConfig, handleResponse, handleError = () => {}) => {
  const safeAsync = useSafeAsync()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  let authorization = localStorage.getItem('loggedInUser')
  if (authorization) {
    authorization = btoa(authorization)
  }

  const sendRequest = () => {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, UPDATE, PATCH, PUT',
      'Access-Control-Allow-Headers': 'Content-Type',
      ...(requestConfig.headers || {}),
      authorization,
    }
    setIsLoading(true)
    setError(null)
    safeAsync(
      axios({
        method: requestConfig.method ? requestConfig.method : 'GET',
        url: requestConfig.url,
        headers: headers,
        data: requestConfig.body
          ? removeEmptyObjectFields(requestConfig.body)
          : null,
        timeout: requestConfig.timeout ? requestConfig.timeout : null,
      })
        .then((response) => {
          console.info('response' + JSON.stringify(response))
          if (response.status === 200) {
            return response.data
          } else {
            setError(
              'transcription api call failed with status:' + response.status
            )
          }
        })
        .then((data) => {
          console.info('data' + JSON.stringify(data))
          data.responseTimestamp = new Date().getTime()
          handleResponse(data)
        })
        .catch((error) => {
          if (error.response) {
            setError(
              'transcription api call failed with status:' +
                error.response.status
            )
          } else if (error.request) {
            setError(error.request)
          } else {
            setError(error.message)
          }
        })
    )
    setIsLoading(false)
  }

  //handleError must be handled in useEffect to prevent infinite re-renders
  useEffect(() => {
    handleError(error)
  }, [error])

  return {
    error,
    isLoading,
    sendRequest,
  }
}
export default useAxois
