import { useContext, useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
//Settings
import {
  TRANSCRIPTION_AUTO_SAVE_INTERVAL,
  TRANSCRIPTION_INACTIVITY_TIMEOUT_INTERVAL,
  SEARCH_URL,
} from '../components/settings/globals'
//Context
import ContributionContext from '../contexts/ContributionContext'
import UserContext from '../contexts/UserContext'
//Hooks
import useDebounce from './use-debounce'
import useHttp from './use-http'
import useModal from './use-modal'
import RecordContext from '../contexts/RecordContext'
import useAxois from './use-http-axios'

/**
 * Handles all transcription-related functionality including...
 * ...display, editing, saving, and publishing
 *
 * Contents:
 * 1. SET UP
 * 2. DEBOUNCE METHODS
 * 3. EDIT TRANSCRIPTION HANDLER
 * 4. SAVE TRANSCRIPTION HANDLER
 * 5. PUBLISH TRANSCRIPTION HANDLER
 */

const useTranscriptionEditor = () => {
  /**
   * CONTEXT
   */
  const {
    currentActiveTranscription,
    currentTranscription,
    enteredTranscription,
    enteredTranscriptionHandler,
    lockedTranscriptions,
    transcriptionsDispatch,
    transcriptionIsPublishing,
    transcriptionIsSaved,
    transcriptionIsSaving,
    unsavedEdits,
    setUnsavedEdits,
  } = useContext(ContributionContext)
  const { naId, objectId } = useContext(RecordContext)
  const { loggedInUser } = useContext(UserContext)

  /**
   * 1. SET UP
   */
  const [error, setError] = useState(null)
  const [requestingToEdit, setRequestingToEdit] = useState(false)
  const [saving, setSaving] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [cancelSaveDebounce, setCancelSaveDebounce] = useState(false)
  const [cancelSessionDebounce, setCancelSessionDebounce] = useState(false)
  const [parentContributionId, setParentContributionId] = useState(null)

  // Check if the current transcription is locked (being edited) by another user
  const [transcriptionIsLocked, setTranscriptionIsLocked] = useState(
    lockedTranscriptions?.contains(objectId)
  )

  // Track whether the current user is actively editing the current transcription
  const [loggedInUserIsEditing, setLoggedInUserIsEditing] = useState(
    currentTranscription?.userId === loggedInUser?.userId &&
      transcriptionIsLocked
  )

  useEffect(() => {
    if (objectId)
      setTranscriptionIsLocked(lockedTranscriptions?.contains(objectId))
  }, [lockedTranscriptions])

  // Each time the objectId updates:
  // 1. Make sure the global contribution context reflects the current object transcription.
  // ...by updating the currentTranscription and currentActiveTranscription states
  // 2. Track the initial parentContributionId for this digital object transcription...
  // ...so we can reference it each time we make a change
  useEffect(() => {
    if (objectId) {
      transcriptionsDispatch({
        type: 'UPDATE_CURRENT_TRANSCRIPTION',
        objectId: objectId,
      })
    }
    return () => setParentContributionId(null)
  }, [objectId])

  // Once the current user is editing, update the enteredTranscription value...
  // ...to be the current transcription text
  useEffect(() => {
    setParentContributionId(currentTranscription?.parentContributionId)
    if (!loggedInUserIsEditing) {
      if (isShowingSessionExpiringModal && !userHasTimedOut)
        toggleSessionExpiringModal(false)
    } else if (!enteredTranscription && currentTranscription?.contribution) {
      enteredTranscriptionHandler(
        DOMPurify.sanitize(currentActiveTranscription?.contribution)
      )
    }
  }, [currentTranscription, loggedInUserIsEditing])

  useEffect(() => {
    setLoggedInUserIsEditing(
      currentTranscription?.userId === loggedInUser?.userId &&
        transcriptionIsLocked
    )
  }, [transcriptionIsLocked])

  // Create the modal functions for use when the...
  // ...transcription session inactivity timer has lapsed
  const {
    isShowing: isShowingSessionExpiringModal,
    toggle: toggleSessionExpiringModal,
  } = useModal()
  const [userHasTimedOut, setUserHasTimedOut] = useState(false)

  /**
   * 2. DEBOUNCE METHODS
   */
  // We use the debounceValue and sessionDebounceValue to
  // ...prevent polluting the enteredTranscription value...
  // ...because we need to set them to an empty string in order...
  // ...to restart each debounce timeout.
  const [debounceValue, setDebouncedValue] = useState('')
  const [sessionDebounceValue, setSessionDebouncedValue] = useState(0)

  /**
   * These debounce methods measure a users inactivity.
   * Here, we are measuring debounce for two scenarios:
   * 1. Inactivity when typing (to trigger the autosave)
   *    After typing inactivity, the document will autosave, overwriting...
   * ...the current draft contribution with a new contribution text
   * 2. Inactivity within the session (to trigger the autosave AND unlock)
   *    Users are allowed to be inactive for 25 minutes within the session before the first alert.
   * ...If this time lapses, a warning is displayed giving them...
   * ...an additional 5 minutes to choose to continue editing, save and close,...
   * ...or discard[?] their work.
   * ...If the user doesn't interact with the alert...
   * ...their edit status will time out, and the current transcription...
   * ...with the status of "draft" will now have the satatus of "active"
   * Begin   debounced interval on edit button click + user typing action
   * Restart debounced interval on each keystroke, or when alert is canceled (i.e. user continues editing)
   * Remove  debounced interval on publish or cancel
   */
  const transcriptionSaveDebounced = useDebounce(
    debounceValue,
    TRANSCRIPTION_AUTO_SAVE_INTERVAL,
    cancelSaveDebounce
  )

  const transcriptionSessionDebounced = useDebounce(
    sessionDebounceValue,
    TRANSCRIPTION_INACTIVITY_TIMEOUT_INTERVAL,
    cancelSessionDebounce
  )

  // Handle the save debounce
  useEffect(() => {
    // Prevent the debounce interval from lapsing and activating the save...
    // ...when the user isn't editing the transcription
    if (loggedInUserIsEditing) {
      // No debounce value indicates the debounce was forced to restart.
      // In this case we need to make sure it has access to the transcription...
      // ...in case the user wants to save/close the transcription
      // ...so the system doesn't try to save an empty one.
      if (!debounceValue)
        setDebouncedValue(
          enteredTranscription
            ? enteredTranscription
            : currentTranscription?.contribution
        )

      if (transcriptionSaveDebounced && !transcriptionIsSaved) {
        // The debounce interval has lapsed, so save the new draft
        saveTranscriptionHandler()
      }
    }
  }, [transcriptionSaveDebounced])

  // Handle the session debounce
  useEffect(() => {
    // Prevent the debounce interval from lapsing and activting the save...
    // ...when the user isn't editing the transcription
    if (loggedInUserIsEditing) {
      if (sessionDebounceValue > 0 && cancelSessionDebounce === false) {
        // The debounce interval has lapsed, so save the new draft
        // TODO see if we remove this
        // saveTranscriptionHandler()
        toggleSessionExpiringModal(true)
      } else setUserHasTimedOut(false)
    }
  }, [transcriptionSessionDebounced])

  /**
   * 3. EDIT TRANSCRIPTION HANDLER
   * Enables textarea for editing
   * Locks transcription to prevent other users from editing
   */

  // Ask the API to create a new draft so the user can begin editing
  const editTranscriptionHandler = () => {
    // if (parentContributionId)
    if (isShowingSessionExpiringModal) toggleSessionExpiringModal(false)
    setRequestingToEdit(true)
    setError(null)
    //Prevent autosave from occuring until user begins typing
    setDebouncedValue('')
    setSessionDebouncedValue(0)
    setCancelSaveDebounce(true)
    setCancelSessionDebounce(true)
    editTranscriptionRequestHandler()
  }

  const editTranscriptionResponseHandler = (response) => {
    setRequestingToEdit(false)
    setSaving(false)
    // Handle additional errors
    if (!response || !response.contributionId) {
      setError('Nothing was returned. Please try again.')
      return false
    }
    enteredTranscriptionHandler(currentActiveTranscription?.contribution)
    setLoggedInUserIsEditing(true)
    setCancelSaveDebounce(false)
    setCancelSessionDebounce(false)
    setSessionDebouncedValue(sessionDebounceValue + 1)
    setUserHasTimedOut(false)

    // Update the global context to reflect the response
    transcriptionsDispatch({
      type: 'UPDATE_ALL_TRANSCRIPTIONS',
      currentTranscription: response,
    })
    transcriptionsDispatch({
      type: 'UPDATE_LOCKED_TRANSCRIPTIONS',
    })
    transcriptionsDispatch({
      type: 'UPDATE_CURRENT_TRANSCRIPTION',
      currentTranscription: response,
    })
  }

  const transcriptionErrorHandler = (error) => {
    if (error) {
      setCancelSaveDebounce(true)
      setCancelSessionDebounce(true)
      setRequestingToEdit(false)
      setPublishing(false)
      setSaving(false)
      setError(error)
    }
  }

  /**
   * Add a new draft to the database.
   * This alerts other users that the transcription is locked...
   * ...so they can't mess with it, and it prepares the database...
   * ...to accept the updated transcription
   * To do this we need to activate a new PUT request which...
   * ...includes the parentContributionId, and the existing contribution text
   */

  //TO DO: Make sure this request responds appropriately...
  // ...if the transcription is already locked by another user,...
  // ...i.e. prevent adding a new draft
  const {
    isLoading: requestToEditIsProcessing,
    sendRequest: editTranscriptionRequestHandler,
  } = useHttp(
    {
      url: `${SEARCH_URL}/transcriptions`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        transcription: currentTranscription?.contribution || '',
        targetNaId: parseInt(naId),
        targetObjectId: objectId,
        userId: loggedInUser?.userId,
        parentContributionId: parentContributionId,
      },
    },
    editTranscriptionResponseHandler,
    transcriptionErrorHandler
  )

  /**
   * 4. SAVE TRANSCRIPTION HANDLER
   */
  // Ask the API to overwrite the current draft...
  // ...so the user can progressively save their work...
  const saveTranscriptionHandler = () => {
    if (
      // !currentTranscription ||
      !loggedInUserIsEditing ||
      // enteredTranscription?.trim() === '' ||
      // !enteredTranscription ||
      transcriptionIsSaving
    ) {
      return false
    }
    setCancelSaveDebounce(true)
    setCancelSessionDebounce(true)

    if (!parentContributionId) {
      setParentContributionId(currentTranscription.parentContributionId)
    } else {
      setSaving(true)
      saveTranscriptionRequestHandler()
    }
  }

  const saveTranscriptionResponseHandler = (response) => {
    setSessionDebouncedValue(sessionDebounceValue + 1)
    setSaving(false)
    setCancelSaveDebounce(false)
    setCancelSessionDebounce(false)
    // Handle additional errors
    if (!response || !response.contributionId) {
      setError('Nothing was returned. Please try again.')
      return false
    }

    enteredTranscriptionHandler(response.contribution)

    // Update the global context to reflect the response
    transcriptionsDispatch({
      type: 'UPDATE_ALL_TRANSCRIPTIONS',
      currentTranscription: response,
    })
    transcriptionsDispatch({
      type: 'UPDATE_CURRENT_TRANSCRIPTION',
      currentTranscription: response,
    })
  }

  const publishTranscriptionResponseHandler = (response) => {
    setPublishing(false)
    setSaving(false)
    setCancelSaveDebounce(true)
    setCancelSessionDebounce(false)
    // Handle additional errors
    if (!response || !response.contributionId) {
      setError('Nothing was returned. Please try again.')
      // setCancelSessionDebounce(false)
      return false
    }
    setLoggedInUserIsEditing(false)

    // Update the global context to reflect the response
    transcriptionsDispatch({
      type: 'UPDATE_ALL_TRANSCRIPTIONS',
      currentTranscription: response,
    })
    transcriptionsDispatch({
      type: 'UPDATE_LOCKED_TRANSCRIPTIONS',
    })
    transcriptionsDispatch({
      type: 'UPDATE_ACTIVE_TRANSCRIPTIONS',
    })
    transcriptionsDispatch({
      type: 'UPDATE_CURRENT_TRANSCRIPTION',
      currentTranscription: response,
    })
  }

  /**
   * Overwrites the current draft with the new transcription.
   * Does not publish or unlock the transcription.
   */
  const {
    isLoading: requestToSaveIsProcessing,
    sendRequest: saveTranscriptionRequestHandler,
  } = useAxois(
    {
      url: `${SEARCH_URL}/transcriptions${publishing ? '?publish=true' : ''}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        transcription:
          enteredTranscription || enteredTranscription === ''
            ? enteredTranscription
            : currentActiveTranscription?.contribution,
        targetNaId: parseInt(naId),
        targetObjectId: objectId,
        userId: loggedInUser?.userId,
        parentContributionId: parentContributionId,
      },
      timeout: 6000,
    },
    saveTranscriptionResponseHandler,
    transcriptionErrorHandler
  )

  /**
   * 5. PUBLISH TRANSCRIPTION HANDLER
   * i.e. Save and close
   */

  // Ask the API to change the status of the current draft to "active"...
  // ...so the work can be transcriptionIsSaved and the transcription...
  // ...can be unlocked for others to edit
  const publishTranscriptionHandler = () => {
    if (!enteredTranscription || enteredTranscription?.trim() === '') {
      setError('You cannot save an empty transcription')
      cancelTranscriptionHandler()
      return false
    }

    setPublishing(true)
  }

  /**
   * Convert the current draft into the most recent active transcription the database.
   * This alerts other users that the transcription is now unlocked...
   * ...so they can edit it
   */
  const {
    isLoading: requestToPublishIsProcessing,
    sendRequest: publishTranscriptionRequestHandler,
  } = useHttp(
    {
      url: `${SEARCH_URL}/transcriptions?publish=true`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        transcription: enteredTranscription,
        targetNaId: parseInt(naId),
        targetObjectId: objectId,
        userId: loggedInUser?.userId,
        parentContributionId: parentContributionId,
      },
    },
    publishTranscriptionResponseHandler,
    transcriptionErrorHandler
  )

  /**
   * HANDLE CANCEL
   */
  // Ask the API to set the status of the current draft...
  // ...to inactive draft so it doesn't interfere with the UI...
  const transcriptionIsCancelingHandler = (bool) => {
    transcriptionsDispatch({
      type: 'UPDATE_CURRENT_TRANSCRIPTION_IS_CANCELING',
      transcriptionIsCanceling: bool,
    })
  }

  const cancelTranscriptionHandler = () => {
    if (
      loggedInUserIsEditing &&
      enteredTranscription &&
      enteredTranscription !== ''
    ) {
      setDebouncedValue(enteredTranscription)
      setSessionDebouncedValue(sessionDebounceValue + 1)
    }
    setCancelSaveDebounce(true)
    setCancelSessionDebounce(true)
    cancelTranscriptionRequestHandler()
  }

  const cancelTranscriptionResponseHandler = (response) => {
    // Handle additional errors
    if (!response || !response.contributionId) {
      transcriptionErrorHandler('An error occured. Please try again.')
      return false
    }
    setCancelSessionDebounce(false)
    transcriptionIsCancelingHandler(false)
    transcriptionsDispatch({
      type: 'UPDATE_CURRENT_USER_IS_EDITING',
      loggedInUserIsEditing: false,
    })
    transcriptionsDispatch({
      type: 'UPDATE_LOCKED_TRANSCRIPTIONS',
    })
    transcriptionsDispatch({
      type: 'UPDATE_CURRENT_TRANSCRIPTION',
      currentTranscription: response,
    })
    setLoggedInUserIsEditing(false)
    transcriptionsDispatch({
      type: 'REMOVE_TRANSCRIPTIONS_BY_ID',
      contributionId: response.contributionId,
    })
    transcriptionsDispatch({
      type: 'UPDATE_ACTIVE_TRANSCRIPTIONS',
    })
  }

  /**
   * Overwrites the current draft with the new transcription.
   * Does not publish or unlock the transcription.
   */
  const {
    isLoading: requestToCancelIsProcessing,
    sendRequest: cancelTranscriptionRequestHandler,
  } = useHttp(
    {
      url: `${SEARCH_URL}/transcriptions`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        userId: loggedInUser?.userId,
        contributionId: currentTranscription?.contributionId,
        parentContributionId: parentContributionId,
        status: 'inactive draft',
        targetNaId: parseInt(naId),
        targetObjectId: objectId,
        transcription: enteredTranscription
          ? enteredTranscription
          : currentTranscription?.contribution || '',
      },
    },
    cancelTranscriptionResponseHandler,
    transcriptionErrorHandler
  )

  useEffect(() => {
    transcriptionIsCancelingHandler(requestToCancelIsProcessing)
  }, [requestToCancelIsProcessing])

  useEffect(() => {
    transcriptionsDispatch({
      type: 'UPDATE_TRANSCRIPTION_EDIT_REQUEST_PENDING',
      transcriptionEditRequestIsPending:
        requestToEditIsProcessing || requestingToEdit,
    })
  }, [requestToEditIsProcessing, requestingToEdit])

  useEffect(() => {
    transcriptionsDispatch({
      type: 'UPDATE_CURRENT_TRANSCRIPTION_IS_PUBLISHING',
      transcriptionIsPublishing: requestToPublishIsProcessing || publishing,
    })
  }, [requestToPublishIsProcessing, publishing])

  useEffect(() => {
    transcriptionsDispatch({
      type: 'UPDATE_CURRENT_TRANSCRIPTION_IS_SAVING',
      transcriptionIsSaving: requestToSaveIsProcessing || saving,
    })
  }, [
    requestToSaveIsProcessing,
    saving,
    enteredTranscription,
    currentTranscription,
    requestToEditIsProcessing,
  ])

  useEffect(() => {
    transcriptionsDispatch({
      type: 'UPDATE_CURRENT_TRANSCRIPTION_IS_SAVED',
      transcriptionIsSaved:
        enteredTranscription === currentTranscription?.contribution &&
        !transcriptionIsSaving,
    })
  }, [
    enteredTranscription,
    currentTranscription,
    transcriptionIsSaving,
    requestToEditIsProcessing,
  ])

  // TO DO: Improve this logic. Right now this useEffect is...
  // ...acting as a failsafe for situations where...
  // ...the publishing variable was updated after the transcription was transcriptionIsSaved,...
  // ...but before the transcription saving variable was falsified.
  useEffect(() => {
    if (transcriptionIsPublishing && !unsavedEdits) {
      setCancelSaveDebounce(true)
      setCancelSessionDebounce(true)
      publishTranscriptionRequestHandler()
    }
  }, [transcriptionIsPublishing, unsavedEdits])

  useEffect(() => {
    if (!transcriptionIsSaving) setUnsavedEdits(false)
  }, [transcriptionIsSaving])

  useEffect(() => {
    if (requestToEditIsProcessing) {
      setUnsavedEdits(true)
      saveTranscriptionHandler()
    }
  }, [requestToEditIsProcessing])

  useEffect(() => {
    if (unsavedEdits) {
      setCancelSessionDebounce(true)
    }
  }, [unsavedEdits])

  useEffect(() => {
    saveTranscriptionHandler()
  }, [enteredTranscription])

  useEffect(() => {
    if (loggedInUserIsEditing && enteredTranscription) {
      setDebouncedValue(enteredTranscription)
      setSessionDebouncedValue(sessionDebounceValue + 1)
    } else {
      setDebouncedValue('')
      setSessionDebouncedValue(0)
    }
    // return () => {
    //   setDebouncedValue('')
    //   setSessionDebouncedValue(0)
    // }
    // if (loggedInUserIsEditing && enteredTranscription) {
    //   setCancelSaveDebounce(false)
    //   setCancelSessionDebounce(false)
    //   setDebouncedValue(enteredTranscription)
    //   setSessionDebouncedValue(sessionDebounceValue + 1)
    // }
    // return () => {
    //   setDebouncedValue('')
    //   setSessionDebouncedValue(0)
    // }
  }, [enteredTranscription, requestToEditIsProcessing])

  return {
    cancelTranscriptionHandler,
    editTranscriptionHandler,
    publishTranscriptionHandler,
    saveTranscriptionHandler,
    toggleSessionExpiringModal,
    loggedInUserIsEditing,
    error,
    isShowingSessionExpiringModal,
    parentContributionId,
    transcriptionIsLocked,
    userHasTimedOut,
    setUserHasTimedOut,
  }
}
export default useTranscriptionEditor
