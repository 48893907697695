import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

const useModal = () => {
  const location = useLocation()
  const [isShowing, setIsShowing] = useState(false)
  const [scroll, setScroll] = useState(0)

  const toggle = (bool) => {
    if (bool === true) {
      setIsShowing(true)
    } else if (bool === false) {
      setIsShowing(false)
    } else {
      setIsShowing(!isShowing)
    }
  }

  const keyPressHandler = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      setIsShowing(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyPressHandler, false)
    return () => {
      window.removeEventListener('keydown', keyPressHandler, false)
    }
  }, [])

  useEffect(() => {
    if (isShowing) {
      const offset = window.pageYOffset
      document.body.style.overflow = /moderate-announcements/i.test(
        location.pathname
      )
        ? 'scroll'
        : 'hidden'
      document.body.style.position = 'fixed'
      // Maintain offset so we don't jump to the top of the page
      document.body.style.top = `-${offset}px`
      document.body.style.width = '100vw'
      // Retain offset for use when we close the modal
      setScroll(offset)
    } else {
      document.body.removeAttribute('style')
      // Maintain scroll position so we don't jump to the top of the page
      window.scrollTo(0, scroll)
    }
  }, [isShowing])

  return {
    isShowing,
    toggle,
  }
}
export default useModal
