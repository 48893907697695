import React, { Fragment, useContext, useEffect, useState } from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import ContributionContext from '../../../../contexts/ContributionContext'
import DigitalObjectContext from '../../../../contexts/DigitalObjectContext'
import UserContext from '../../../../contexts/UserContext'
//Components
import CountdownTimer from '../../../CountdownTimer/CountdownTimer'
import LogInPrompt from '../LogInPrompt'
import Modal, { ModalBody, ModalFooter } from '../../../Modal/Modal'
import { Button } from '../../../Button/Button'
import { IconLabel } from '../../../IconLabel/IconLabel'
//Hooks
import useTranscriptionEditor from '../../../../hooks/use-transcription-editor'
import RouterPrompt from '../../../Modal/variants/RouterPrompt'
import { TRANSCRIPTION_INACTIVITY_COUNTDOWN_TIME } from '../../../settings/globals'
import Prose from '../../../utilities/Prose'
import RecordContext from '../../../../contexts/RecordContext'
import { useLocation } from 'react-router-dom'
import useUrlParams from '../../../../hooks/use-url-params'
import { ListSeparator } from '../../../ListSeparator/ListSeparator'
import BasicLink from '../../../BasicLink'

String.prototype.escapeSpecialChars = function () {
  return this.replace(/[\\]/g, '\\\\')
    .replace(/["]/g, '\\"')
    .replace(/[/]/g, '\\/')
    .replace(/[\b]/g, '\\b')
    .replace(/[\f]/g, '\\f')
    .replace(/[\n]/g, '\\n')
    .replace(/[\r]/g, '\\r')
    .replace(/[\t]/g, '\\t')
}

const TranscriptionActions = ({ ...props }) => {
  const { onloggedInUserIsEditing } = props
  //Contexts
  const {
    currentActiveTranscription,
    currentTranscription,
    currentTranscriptionIsLocked,
    enteredTranscription,
    transcriptionEditRequestIsPending,
    transcriptionError,
    transcriptionsDispatch,
    transcriptionIsCanceling,
    transcriptionIsPublishing,
    transcriptionIsSaved,
    transcriptionIsSaving,
  } = useContext(ContributionContext) || {}
  const { activePanel } = useContext(RecordContext) || {}
  const { loggedInUser } = useContext(UserContext) || {}
  const { state } = useLocation()
  const { removeParams, params } = useUrlParams(state)
  const objectPanel = params['objectPanel']

  //Hooks
  const {
    cancelTranscriptionHandler,
    editTranscriptionHandler,
    loggedInUserIsEditing,
    publishTranscriptionHandler,
    isShowingSessionExpiringModal,
    saveTranscriptionHandler,
    toggleSessionExpiringModal,
    userHasTimedOut,
    setUserHasTimedOut,
  } = useTranscriptionEditor()

  /////////////////
  // Track if the transcription session inactivity timer has lapsed

  //Handlers
  const transcriptionTimeOutHandler = (bool) => {
    setUserHasTimedOut(bool)
    if (bool && enteredTranscription && enteredTranscription !== '') {
      publishTranscriptionHandler()
    } else if (bool) {
      cancelTranscriptionHandler()
    }
    if (bool && !isShowingSessionExpiringModal) toggleSessionExpiringModal(true)
    transcriptionsDispatch({
      type: 'UPDATE_CURRENT_USER_TRANSCRIPTION_TIMEOUT',
      userHasTimedOut: bool,
    })
  }

  const transciptionSaveAndExitHandler = () => {
    if (enteredTranscription === '' || !enteredTranscription) {
      cancelTranscriptionHandler()
    } else {
      publishTranscriptionHandler()
    }
    toggleSessionExpiringModal(false)
  }

  const gotItHandler = () => {
    toggleSessionExpiringModal(false)
    setUserHasTimedOut(false)
  }

  if (loggedInUser?.userId) {
    //The user is logged in
    return (
      <div className="display-inline-flex flex-align-center flex-row width-full flex-wrap">
        {loggedInUserIsEditing ? (
          // The transcription is being edited
          <>
            <RouterPrompt
              when={loggedInUserIsEditing}
              cancelText="Continue Editing"
              confirmText="Leave and Discard Changes"
              title="Are you done editing?"
              onConfirm={cancelTranscriptionHandler}
              confirmWhen={!loggedInUserIsEditing}
            >
              <Prose>If you leave now, your work will be discarded.</Prose>
            </RouterPrompt>
            <div className={['display-flex', 'flex-row', 'flex-gap'].join(' ')}>
              {!transcriptionError ? (
                <Fragment>
                  {!transcriptionIsPublishing && (
                    <Button
                      aria-label="Close the editor and disregard any changes"
                      className={['flex-shrink-0', 'margin-right-auto'].join(
                        ' '
                      )}
                      data-testid="nac-transcriptions_cancel"
                      disabled={
                        transcriptionIsPublishing || transcriptionIsCanceling
                      }
                      iconAnimation={
                        transcriptionIsCanceling ? 'rotate-cw' : ''
                      }
                      iconName={
                        transcriptionIsCanceling
                          ? 'arrow-rotation-doubleright'
                          : null
                      }
                      iconSize="2xs"
                      size="2xs"
                      textOnly
                      onClick={() => removeParams(['objectPanel'])}
                    >
                      Cancel
                    </Button>
                  )}
                  {!transcriptionIsCanceling && (
                    <Button
                      aria-label="Publish and close the transcription editor"
                      className={['flex-shrink-0', 'margin-left-auto'].join(
                        ' '
                      )}
                      data-testid="nac-transcriptions_submit-save"
                      disabled={
                        transcriptionIsPublishing ||
                        transcriptionIsCanceling ||
                        enteredTranscription?.trim() === '' ||
                        currentActiveTranscription?.contribution?.trim() ===
                          '' ||
                        !enteredTranscription ||
                        !transcriptionIsSaved
                      }
                      iconAnimation={
                        transcriptionIsPublishing ? 'rotate-cw' : ''
                      }
                      iconName={
                        transcriptionIsPublishing
                          ? 'arrow-rotation-doubleright'
                          : null
                      }
                      iconSize="2xs"
                      size="2xs"
                      type="submit"
                      onClick={publishTranscriptionHandler}
                    >
                      Publish and Close
                    </Button>
                  )}
                  <div
                    className={[
                      'display-flex',
                      'flex-justify-center',
                      'text-center',
                      'width-full',
                    ].join(' ')}
                  >
                    {transcriptionIsPublishing ||
                    transcriptionIsCanceling ||
                    transcriptionIsSaving ||
                    !enteredTranscription?.trim() === '' ? (
                      <IconLabel
                        iconAnimation="rotate-cw"
                        iconName="arrow-rotation-doubleright"
                        size="2xs"
                        color="base-dark"
                      >
                        <strong>Saving...</strong>
                      </IconLabel>
                    ) : transcriptionIsSaved ? (
                      <IconLabel
                        iconName="check"
                        size="2xs"
                        color="success-dark"
                      >
                        <strong>Saved</strong>
                      </IconLabel>
                    ) : (
                      ''
                    )}
                  </div>
                </Fragment>
              ) : (
                <IconLabel color="error-dark" iconName="close" size="2xs">
                  {transcriptionError.message || transcriptionError}
                </IconLabel>
              )}
            </div>
          </>
        ) : currentTranscriptionIsLocked ? (
          // The transcription is locked (i.e. it is being edited by another user)
          <div
            className={[
              'font-sans-2xs',
              'padding-1',
              'text-base-dark',
              'text-bold',
            ].join(' ')}
          >
            This transcription cannot be edited at this time.
          </div>
        ) : (
          // The transcription is not being edited
          <Button
            aria-label={'Edit transcription'}
            data-testid={`nac-transcriptions_start`}
            disabled={transcriptionEditRequestIsPending}
            iconAnimation={transcriptionEditRequestIsPending ? 'rotate-cw' : ''}
            iconName={
              transcriptionEditRequestIsPending
                ? 'arrow-rotation-doubleright'
                : null
            }
            iconSize="2xs"
            outline
            size="2xs"
            type="submit"
            onClick={() => {
              onloggedInUserIsEditing()
              editTranscriptionHandler()
            }}
          >
            {currentActiveTranscription?.contribution
              ? 'Edit transcription'
              : 'Start transcribing'}
          </Button>
        )}
        <div
          className={['font-sans-2xs', 'margin-left-auto', 'text-normal'].join(
            ' '
          )}
        >
          <ListSeparator color="base" size="xl">
            <BasicLink
              className={['flex-align-center'].join(' ')}
              href="https://www.archives.gov/citizen-archivist/resources"
            >
              Need Help?
            </BasicLink>
            <BasicLink href="https://www.archives.gov/citizen-archivist/resources/tagging-policy">
              Policy
            </BasicLink>
          </ListSeparator>
        </div>
        {isShowingSessionExpiringModal &&
          (userHasTimedOut ? (
            <Modal
              hide={gotItHandler}
              id="transcription-session-expired"
              title="Your transcription session has expired."
            >
              <ModalBody id="transcription-session-expired">
                {enteredTranscription === '' || !enteredTranscription
                  ? 'Transcriptions cannot be empty. Your work has been discarded.'
                  : 'Any changes you made to this transcription have been saved, and these updates are now unlocked for everyone to view.'}
              </ModalBody>
              <ModalFooter hide={gotItHandler}>
                <div className={['display-flex', 'width-full'].join(' ')}>
                  <Button
                    className={['margin-left-auto '].join(' ')}
                    data-testid="nac-transcriptions-expired-got-it"
                    size="2xs"
                    onClick={gotItHandler}
                  >
                    Got it!
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          ) : (
            <Modal
              hide={() => {
                saveTranscriptionHandler()
                toggleSessionExpiringModal(false)
              }}
              id="transcription-session-expiring"
              title="Are you still here?"
            >
              <ModalBody id="transcription-session-expiring">
                <Prose>Your transcription session will expire in:</Prose>
                <div
                  className={[
                    'display-flex',
                    'flex-justify-center',
                    'margin-y-2',
                    'text-bold',
                    'width-full',
                  ].join(' ')}
                >
                  {
                    <CountdownTimer
                      targetDate={TRANSCRIPTION_INACTIVITY_COUNTDOWN_TIME}
                      expiredAction={() => transcriptionTimeOutHandler(true)}
                    />
                  }
                </div>
                <Prose>
                  {enteredTranscription === '' || !enteredTranscription
                    ? 'Transcriptions cannot be empty. If the session expires, your work will be discarded.'
                    : 'If the session expires, your work will be saved and made available for other users to access and edit.'}
                </Prose>
                <Prose>Do you want to continue editing?</Prose>
              </ModalBody>
              <ModalFooter
                hide={() => {
                  saveTranscriptionHandler()
                  toggleSessionExpiringModal(false)
                }}
              >
                <div className={['display-flex', 'width-full'].join(' ')}>
                  <Button
                    className={['margin-right-auto '].join(' ')}
                    data-testid="nac-transcriptions_almost-expired-leave"
                    size="2xs"
                    onClick={transciptionSaveAndExitHandler}
                    reduced
                    textOnly
                    theme="error-dark"
                  >
                    {enteredTranscription === '' || !enteredTranscription
                      ? 'Leave and Discard Changes'
                      : 'Save and Exit'}
                  </Button>
                  <Button
                    className={['margin-left-auto '].join(' ')}
                    data-testid="nac-transcriptions_almost-expired-continue"
                    size="2xs"
                    onClick={() => {
                      saveTranscriptionHandler()
                      toggleSessionExpiringModal(false)
                    }}
                    reduced
                  >
                    Continue Editing
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          ))}
      </div>
    )
  } else return <LogInPrompt></LogInPrompt>
}

TranscriptionActions.propTypes = {
  onloggedInUserIsEditing: PropTypes.function,
}

export default TranscriptionActions
