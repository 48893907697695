import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import '../../../styles/index.scss'
//Settings
import { DEFAULT_MAX_CONTENT_WIDTH, SEARCH_URL } from '../../settings/globals'
//Context
import RecordContext from '../../../contexts/RecordContext'
//Components
import { Alert } from '../../Alert/Alert'
import { Table } from '../../Table/Table'
//Helpers
import { returnIconAndLabelFromString } from '../../../helper-functions/metadata-functions'
import { returnFirstLastNameString } from '../../../helper-functions/string-functions'
import BasicLink from '../../BasicLink'
//Hooks
import useHttp from '../../../hooks/use-http'
import Card from '../../Card/Card'

import { IIIF_PATH_THUMB } from '../../settings/globals'
import { convertImageUrlToIiif } from '../../../helper-functions/string-functions'

/**
 * Related records are those which related in some way to the current record.
 * For people, related records include those to which the person has contributed, created, provided, or of which they were the subject.
 */
export const RelatedRecords = ({ ...props }) => {
  const recordContext = useContext(RecordContext)
  const record = recordContext.record

  //Related Record States
  const [related, setRelated] = useState([])
  const [loadingRelated, setLoadingRelated] = useState(false)
  const [localIdCount, setLocalIdCount] = useState(0)
  //Table Pagination States
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)

  const handleSetPage = (num) => {
    setPage(num)
  }

  const handleRelatedResponse = (results) => {
    if (!results) return
    const count = results.body.hits.total.value
    if (count == 0) return false
    setLocalIdCount(results.body.aggregations.localIdentifierCount.doc_count)
    let flattenedResults = []
    results.body.hits.hits.map((result, index) => {
      let r = result._source.record
      let role = null
      let cGroup = '*'
      if (r.subjects) {
        r.subjects.map((subject) => {
          if (subject.naId == record.naId) role = 'Subject'
        })
      }
      if (r.contributors) {
        r.contributors.map((contributor) => {
          if (contributor.naId == record.naId)
            role = `Contributor ${
              contributor.contributorType
                ? ` – ${contributor.contributorType}`
                : ''
            }`
        })
      }
      if (r.creators) {
        r.creators.map((creator) => {
          if (creator.naId == record.naId)
            role = `Creator ${
              creator.creatorType ? ` – ${creator.creatorType}` : ''
            }`
        })
      }
      if (r.donors) {
        r.donors.map((donor) => {
          if (donor.naId == record.naId) role = 'Donor'
        })
      }
      if (r.ancestors) {
        r.ancestors.map((ancestor) => {
          if (
            ancestor.levelOfDescription == 'recordGroup' ||
            ancestor.levelOfDescription == 'collection'
          )
            cGroup = String(ancestor.naId)
        })
      }

      flattenedResults.push({
        col1: {
          level: r.levelOfDescription,
          title: r.title,
          url:
            r.digitalObjects &&
            convertImageUrlToIiif(r.digitalObjects[0].objectUrl) +
              IIIF_PATH_THUMB,
          naId: r.naId,
          controlGroup: cGroup,
        },
        col2: returnIconAndLabelFromString(r.levelOfDescription).label,
        col3: r.naId,
        col4: r.localIdentifier,
        col5: role,
      })
    })
    setRelated(flattenedResults)
    setPages(Math.ceil(count / 10))
    setTimeout(function () {
      setLoadingRelated(false)
    }, 500)
  }

  let linkType = 'Role'
  const requestUrl = useMemo(() => {
    switch (record.authorityType) {
      case 'person':
        linkType = "Person's Role"
        return `record.donors.naId%3A${record.naId}%20OR%20record.creators.naId%3A${record.naId}%20OR%20record.contributors.naId%3A${record.naId}%20OR%20record.subjects.naId%3A${record.naId}`
      case 'topicalSubject':
        return `record.subjects.naId%3A${record.naId}`
      case 'specificRecordsType':
        return `record.subjects.naId%3A${record.naId}`
      case 'geographicPlaceName':
        return `record.subjects.naId%3A${record.naId}`
    }
  }, [record])

  const {
    error: relatedError,
    isLoading: relatedLoading,
    sendRequest: requestRelatedHandler,
  } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?limit=10&page=${
        page || 1
      }&q=${requestUrl}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleRelatedResponse
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Record Title',
        accessor: 'col1',
        Cell: (cell) => {
          return (
            <div className={['display-flex', 'flex-align-center'].join(' ')}>
              <div
                className={[
                  'bg-primary-lighter',
                  'display-block',
                  'height-full',
                  'margin-right-1',
                  'nac-icon',
                  'nac-icon--bg-80',
                  'nac-icon--lg',
                  'nac-icon--thick',
                  'nac-icon--primary-light',
                  `nac-icon--${
                    returnIconAndLabelFromString(cell.value.level).icon
                  } `,
                  'position-relative',
                ].join(' ')}
              >
                {cell.value.url ? (
                  <img
                    alt="Image description"
                    className="object-fit-cover width-full height-full"
                    loading="lazy"
                    src={cell.value.url}
                  />
                ) : (
                  ''
                )}
              </div>
              <Link
                className={`theme-white--link ellipsed-line-2`}
                data-testid={`nac-child-link--${cell.value.naId}`}
                to={{
                  pathname: '/id/' + cell.value.naId,
                }}
                state={{
                  controlGroup: cell.value.controlGroup,
                }}
              >
                {String(cell.value.title)}
              </Link>
            </div>
          )
        },
        id: 'title',
      },
      {
        Header: 'Level',
        accessor: 'col2',
        id: 'level',
        className: 'width-15',
      },
      {
        Header: 'National Archives ID',
        accessor: 'col3',
        id: 'naId',
        className: 'width-15',
      },
      {
        Header: 'Local ID',
        accessor: 'col4',
        id: 'localId',
        className: 'width-15',
      },
      {
        Header: linkType,
        accessor: 'col5',
        id: 'role',
        className: 'width-card',
      },
    ],
    []
  )

  useEffect(() => {
    if (!record) return false
    if (!requestUrl) return false
    if (
      record.authorityType !== 'organization' &&
      record.linkCounts &&
      (record.linkCounts.creator > 0 ||
        record.linkCounts.contributor > 0 ||
        record.linkCounts.donor > 0 ||
        record.linkCounts.subject > 0)
    )
      setLoadingRelated(true)
    requestRelatedHandler()
  }, [page])

  return related.length > 0 ? (
    <section className="bg-base-lighter-opacity-50">
      <div className={['margin-x-auto', 'maxw-desktop-lg'].join(' ')}>
        <div>
          <h2>
            Records related to
            {record?.authorityType === 'geographicPlaceName' ? ' the ' : ' '}
            {returnIconAndLabelFromString(record.heading).label}
          </h2>
          <Alert type="warning" data-testid="nac-related-records--alert">
            Note: The table below may not represent all of the records related
            to {record?.authorityType === 'geographicPlaceName' ? ' the ' : ' '}
            {record.name
              ? `${returnFirstLastNameString(record.name)}`
              : ` ${returnIconAndLabelFromString(record.heading).label}`}
            . To perform a related search{' '}
            <BasicLink
              to={{
                pathname: `/search`,
                search: `?q=${requestUrl}`,
              }}
            >
              click here
            </BasicLink>
            .
          </Alert>
        </div>
        <div className="tablet:padding-x-3 margin-x-neg-2 tablet:margin-x-neg-0">
          <div className="overflow-x-scroll padding-bottom-2 tablet:padding-bottom-0">
            <Card
              className={[
                'padding-0',
                'tablet-lg:padding-0',
                'margin-x-2',
                'tablet:margin-x-0',
              ].join(' ')}
              style={{ minWidth: '36rem' }}
            >
              <Table
                hideColumns={!localIdCount ? ['localId'] : null}
                columns={columns}
                data={related}
                pageNum={page}
                setPage={handleSetPage}
                loading={loadingRelated}
                pageCount={pages}
              />
            </Card>
          </div>
        </div>
      </div>
    </section>
  ) : (
    ''
  )
}
