import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/index.scss'
import { Fragment } from 'react'
import { ColorPropTypes } from '../../components/settings/ColorPropTypes'
import { defaultSizes } from '../settings/SizePropTypes'
import useDetectPrinting from '../../hooks/use-detect-printing'

/**
 * Separates child list elements with the specified delimeter
 */
export const ListSeparator = ({
  align,
  children,
  className,
  color,
  delimeter,
  display,
  distance,
  ellipsed,
  lastchild,
  nowrap,
  offset,
  size,
  ...props
}) => {
  children = React.Children.toArray(children).filter(Boolean)
  if (ellipsed) nowrap = true

  const { isPrinting } = useDetectPrinting()

  return isPrinting ? (
    <div className={['display-flex', 'flex-column'].join(' ')} {...props}>
      {children}
    </div>
  ) : (
    <div
      className={[
        'minw-0',
        `display-${display}`,
        nowrap ? 'flex-no-wrap' : 'flex-wrap',
        className,
      ].join(' ')}
      {...props}
    >
      {children.map((child, index) => (
        <Fragment key={Math.random()}>
          {offset - 1 >= index ? (
            child
          ) : (
            <span
              className={[
                ellipsed
                  ? 'minw-0 overflow-hidden ellipsed'
                  : `display-${display}`,
                display.includes('flex') ? 'flex-align-center' : '',
                lastchild || (index + 1 < children.length && !ellipsed)
                  ? `separator separator--${delimeter} 
                     separator-size--${size} 
                     separator-align--${align} 
                     separator-distance--${distance} 
                     ${color !== '' ? `separator-${color}` : ''}`
                  : '',
              ].join(' ')}
            >
              {child}
            </span>
          )}

          {ellipsed && (lastchild || index + 1 < children.length) && (
            <span
              className={[
                'display-inline-block',
                'width-4',
                'height-full',
                `separator separator--${delimeter}`,
                `separator-size--${size}`,
                `separator-align--${align}`,
                `separator-distance--${distance}`,
                `${color !== '' ? `separator-${color}` : ''}`,
              ].join(' ')}
            ></span>
          )}
        </Fragment>
      ))}
    </div>
  )
}

ListSeparator.defaultProps = {
  align: 'top',
  color: '',
  delimeter: 'pipe',
  display: 'inline-flex',
  distance: '1',
  lastchild: false,
  nowrap: false,
  offset: null,
  size: 'lg',
}

ListSeparator.propTypes = {
  /**
   * Defines the alignment of the separator in relation to the child elements
   */
  align: PropTypes.oneOf(['middle', 'text-bottom', 'text-top', 'top']),
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Defines additional classes to apply to the wrapper element
   */
  className: PropTypes.string,
  /**
   * Defines the color of the delimiter. This is based on a subset of the USWDS color utilities (https://designsystem.digital.gov/utilities/color/)
   */
  color: PropTypes.oneOf(ColorPropTypes),
  /**
   * Defines the separator to appear between child elements
   */
  delimeter: PropTypes.oneOf([
    'pipe',
    'bullet',
    'doublerightarrow',
    'plus',
    'minus',
    'forward-slash',
  ]),
  /**
   * Defines the alignment of the separator in relation to the child elements
   */
  display: PropTypes.oneOf([
    'inline',
    'block',
    'inline-flex',
    'inline-block',
    'flex',
  ]),
  /**
   * The margin on either side of the delimeter. This is based on a subset of the USWDS size indicators.
   */
  distance: PropTypes.oneOf(['1px', '2px', '05', '1', '105', '2']),
  /**
   * When true, the list ellements will not wrap, and an ellipsis will be applied to any overflow
   */
  ellipsed: PropTypes.bool,
  /**
   * When true, the delimitor will be added to the end of the list. The default is to remove the delimitor from the last list element.
   */
  lastchild: PropTypes.bool,
  /**
   * When true, prevents the list elements from wrapping
   */
  nowrap: PropTypes.bool,
  /**
   * Index odf the child element in which to begin displaying the delimeter. Offsetting by one, for example would allow the first child to act as a label.
   */
  offset: PropTypes.number,
  /**
   * Defines the size of the delimeter. This is based on a subset of the USWDS size indicators.
   */
  size: PropTypes.oneOf(defaultSizes),
}
