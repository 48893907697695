/**
 * Schema for validating transcription inputs
 */
export const transcriptionInputSchema = {
  autoComplete: 'off',
  id: 'addTranscription',
  label: 'transcription',
  loggedIn: true,
  maxlength: 4294967295,
  placeholder: 'Type the text exactly as it is in the image...',
  required: true,
  srText: 'Add a transcription',
  type: 'text',
}
