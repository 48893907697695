import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { LicenseInfo } from '@mui/x-license-pro'
import useHttp from '../../../../hooks/use-http'
import { DataGridPro } from '@mui/x-data-grid-pro'
import './AccountsAdmin.scss'
import { SERVER_URL } from '../../../settings/globals'
import { useDemoData } from '@mui/x-data-grid-generator'
LicenseInfo.setLicenseKey(
  'e8e551f4dd4c1fd71bfbda415d46ba7eTz04NDMwNixFPTE3Mzk1NjYzODkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)
const columns = [
  {
    field: 'userName',
    headerName: 'Username',
    width: 150,
  },
  {
    field: 'fullName',
    headerName: 'Full Name',
    width: 150,
    editable: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 110,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 110,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 110,
  },
  {
    field: 'createdAt',
    headerName: 'Registered Date',
    width: 110,
  },
]

export const Accounts = () => {
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
  })

  const [adminData, setAdminData] = useState({
    rows: [],
    rowCount: 0,
  })

  const [rows, setRows] = React.useState([])
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  })
  const [filterModel, setFilterModel] = React.useState({ items: [] })
  const [sortModel, setSortModel] = React.useState([])

  const onSubmit = async (data) => {
    Object.keys(data).forEach(
      (k) => data[k] == null || (data[k] == '' && delete data[k])
    )

    Object.keys(data).forEach((k) => {
      data[k] = `*${data[k].trim()}*`
    })

    setPaginationModel({
      searchQuery: new URLSearchParams(data).toString(),
      page: 0,
      pageSize: 10,
    })
  }

  const handleResponse = (response) => {
    setAdminData({
      rows: response.results,
      rowCount: response.metadata.totalCount,
    })
  }

  const pageString = paginationModel.searchQuery ? `&page=` : `page=`

  const search = paginationModel.searchQuery ? paginationModel.searchQuery : ''

  let sortParams = ''
  if (sortModel[0]) {
    sortParams = `&sortCol=${sortModel[0].field}&sortOrder=${sortModel[0].sort}`
  }

  const { sendRequest: searchUsers } = useHttp(
    {
      url: `${SERVER_URL}/proxy/users?${search}${pageString}${
        paginationModel.page + 1
      }${sortParams}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleResponse
  )

  useEffect(() => {
    searchUsers()
  }, [paginationModel, sortModel])

  const { data } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 100000,
    editable: true,
  })

  const preSetPaginationModel = (data) => {
    setPaginationModel({
      searchQuery: paginationModel.searchQuery,
      page: data.page,
      pageSize: data.pageSize,
    })
  }

  return (
    <div
      className="account-admin tablet:padding-x-3 padding-y-6 theme-primary-lighter"
      role="main"
    >
      <h2>Search Accounts:</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="account-admin-form">
            <div className="account-field">
              <div className="field-name">Username</div>
              <div className="field-input-wrapper">
                <input {...register('userName')} />
              </div>
            </div>
            <div className="account-field">
              <div className="field-name">Full Name</div>
              <div className="field-input-wrapper">
                <input {...register('fullName')} />
              </div>
            </div>
            <div className="account-field">
              <div className="field-name">Email</div>
              <div className="field-input-wrapper">
                <input {...register('email')} />
              </div>
            </div>
            <div className="account-field">
              <div className="field-name">Account Status</div>
              <div className="field-input-wrapper">
                <input {...register('status')} />
              </div>
            </div>
          </div>
          <input className="submit" type="submit" value="search" />
        </div>
      </form>
      <div className="results">
        {adminData.rowCount > 0 && (
          <DataGridPro
            columns={columns}
            rows={adminData.rows}
            rowCount={adminData.rowCount}
            resizable={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pagination
            sortingMode="server"
            filterMode="server"
            paginationMode="server"
            onPaginationModelChange={preSetPaginationModel}
            onSortModelChange={setSortModel}
          />
        )}
        {adminData.rowCount === 0 && <div>No results</div>}
      </div>
    </div>
  )
}
