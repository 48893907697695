import React, { useContext, useState, useEffect, Fragment, useRef } from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import DigitalObjectContext from '../../../../contexts/DigitalObjectContext'
import UserContext from '../../../../contexts/UserContext'
//Components
import ExpandCollapseList from '../../../utilities/ExpandCollapseList'
import Tag from './Tag'
import RecordContext from '../../../../contexts/RecordContext'

import tagSort from '../../../utilities/Sorting'

/* Handles the display of a group of tags
** This includes...
** ...conditionally displaying tags based on whether a user is logged in and has already contributed
** ...grouping all tags added by the current logged in user
** ...grouping all other tags

** Handles the "Remove Tag" functionality
*/

const TagGroup = ({ tags, label, ...props }) => {
  if (tags.length === 0) return false

  const recordContext = useContext(RecordContext)
  const { accountsAccessArray, loggedInUser } = useContext(UserContext)

  const objectId = tags?.[0]?.targetObjectId

  const [userTags, setUserTags] = useState([])
  const [otherTags, setOtherTags] = useState([])
  const [adminTags, setAdminTags] = useState([])

  const wrapper = useRef(null)
  const [parent, setParent] = useState(null)

  useEffect(() => {
    let user = [],
      other = [],
      admin = []
    if (tags.length > 0) {
      tags.map((tag) => {
        if (!tag) return
        if (!tag.contribution) return
        if (
          accountsAccessArray.accessGrantedToAccountAdminTools &&
          tag.madeByAdministrator === 1
        ) {
          admin.push(tag)
        } else if (tag.userId == loggedInUser?.userId) {
          user.push(tag)
        } else if (tag.userId !== loggedInUser?.userId) {
          other.push(tag)
        }
      })
    }
    setUserTags(user)
    setOtherTags(other.sort(tagSort))
    setAdminTags(admin)
  }, [tags, loggedInUser?.userId])

  useEffect(() => {
    if (wrapper.current) {
      setParent(wrapper.current)
    }
  }, [tags, wrapper])

  return (
    <div
      className={`flex-row grid-row ${
        objectId ? 'overflow-scroll height-full' : ''
      } width-full`}
    >
      <div
        className="flex-column grid-col padding-y-2"
        data-testid="nac-tags_added-tags-display"
        ref={wrapper}
      >
        {!label ? (
          <div
            className="flex-row grid-row padding-bottom-2"
            data-testid="nac-tags_current-user-added-tags"
          >
            {tags.map((tag) =>
              tag && tag.contribution ? (
                <Tag key={tag.contributionId} tag={tag} wrapper={parent} />
              ) : (
                ''
              )
            )}
          </div>
        ) : (
          <>
            {/* If the user is logged in */}
            {loggedInUser?.userId ? (
              <>
                {adminTags.length > 0 && (
                  <>
                    <div className="flex-row grid-row">
                      <h3>Added by admins ({adminTags.length})</h3>
                    </div>
                    <div
                      className="flex-row grid-row padding-bottom-2"
                      data-testid="nac-tags_current-user-added-tags"
                    >
                      {adminTags.map((tag) =>
                        tag && tag.contribution ? (
                          <Tag
                            key={tag.contributionId}
                            tag={tag}
                            wrapper={parent}
                          />
                        ) : (
                          ''
                        )
                      )}
                    </div>
                  </>
                )}
                {/* If the user has contributed */}
                {userTags.length > 0 && (
                  <>
                    <div className="flex-row grid-row">
                      <h3>Added by you ({userTags.length})</h3>
                    </div>
                    <div
                      className="flex-row grid-row padding-bottom-2"
                      data-testid="nac-tags_current-user-added-tags"
                    >
                      {userTags.map((tag) =>
                        tag && tag.contribution ? (
                          <Tag
                            key={tag.contributionId}
                            tag={tag}
                            wrapper={parent}
                          />
                        ) : (
                          ''
                        )
                      )}
                    </div>
                  </>
                )}
                {otherTags.length > 0 && (
                  <>
                    {adminTags.length > 0 || userTags.length > 0 ? (
                      <div className="flex-row grid-row">
                        <h3>Added by others ({otherTags.length})</h3>
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      className="flex-row grid-row"
                      data-testid="nac-tags_other-user-added-tags"
                    >
                      {otherTags.map((tag) =>
                        tag && tag.contribution ? (
                          <Tag
                            key={tag.contributionId}
                            tag={tag}
                            wrapper={parent}
                          />
                        ) : (
                          ''
                        )
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                {/* If the user is not logged in */}
                {/* If the contribution focus is an object */}
                {otherTags?.[0]?.targetObjectId ? (
                  <div className="flex-row grid-row">
                    {otherTags.map((tag) =>
                      tag &&
                      tag.contribution &&
                      parseInt(objectId) == parseInt(tag.targetObjectId) ? (
                        <Tag
                          key={tag.contributionId}
                          tag={tag}
                          wrapper={parent}
                        />
                      ) : (
                        ''
                      )
                    )}
                  </div>
                ) : (
                  <div className="flex-row grid-row">
                    <ExpandCollapseList flex={false} id="tags-list">
                      {otherTags.map((tag) =>
                        tag && tag.contribution ? (
                          <Tag
                            key={tag.contributionId}
                            tag={tag}
                            wrapper={parent}
                          />
                        ) : (
                          ''
                        )
                      )}
                    </ExpandCollapseList>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

TagGroup.defaultProps = {
  label: true,
  tags: [],
}

TagGroup.propTypes = {
  /**
   * Determines whether to display the labels
   */
  label: PropTypes.bool,
  /**
   * The array of tags
   */
  tags: PropTypes.array,
}

export default TagGroup
