const useScroll = () => {
  const handleScrollTo = (ref) => {
    if (typeof ref === 'string') {
      const refId = document.getElementById(ref)
      refId?.scrollIntoView({ behavior: 'smooth' })
    } else {
      ref?.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return {
    handleScrollTo,
  }
}
export default useScroll
