import React, { useContext, useMemo } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
import SearchContext from '../../contexts/SearchContext'
//Components
import { SectionHeading } from '../SectionHeading/SectionHeading'
import Highlighter from '../Highlighter/Highlighter'
import ExpandCollapseList from '../utilities/ExpandCollapseList'
import { compareStrings } from '../../helper-functions/sort-functions'
import useHighlightList from '../../hooks/use-highlight-list'

const SectionProgramAreas = ({ ...props }) => {
  const { record } = useContext(RecordContext) || {}
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  const programAreas = useMemo(() => {
    if (record.programAreas) {
      return record.programAreas.sort((a, b) => compareStrings(a, b))
    }
    return false
  }, [record])

  const { sortedArray, count } = useHighlightList(q, programAreas, null, 6)

  if (!programAreas) return false

  return (
    <div
      className={['flex-align-start', 'flex-column', 'width-full'].join(' ')}
      data-testid="nac-description__content--terms"
      {...props}
    >
      <div
        className={[
          'display-flex',
          'flex-no-wrap',
          programAreas.length > 0
            ? [
                'tablet-lg:flex-row',
                'tablet-lg:flex-gap-lg',
                'flex-column',
                'flex-gap-0',
              ].join(' ')
            : ['flex-row', 'flex-gap-lg'].join(' '),
        ].join(' ')}
      >
        <div className={['flex-column', 'width-full'].join(' ')}>
          <SectionHeading copyLink count={programAreas.length} {...props}>
            {programAreas.length > 1 ? 'Program Areas' : 'Program Area'}
          </SectionHeading>
          <ul className="add-list-reset display-flex flex-align-start flex-column">
            <ExpandCollapseList gap="sm" id="subjects-list" initial={count}>
              {sortedArray.map((area) => (
                <li key={area}>
                  <Highlighter search={q}>{area}</Highlighter>
                </li>
              ))}
            </ExpandCollapseList>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SectionProgramAreas
