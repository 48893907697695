import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import marked from 'marked'
import '../styles/index.scss'
import { Button } from './Button/Button'
import BannerHome from '../components/Templates/Banner'
import Card from './Card/Card'
import CatalogSearch from './CatalogSearch'
import Prose from './utilities/Prose'
import BasicLink from './BasicLink'
import { Icon } from './Icon/Icon'
import { DEFAULT_MAX_CONTENT_WIDTH, ROLLOVER_CONTENT } from './settings/globals'
import { SEARCH_URL, SERVER_URL } from './settings/globals'
import useHttp from '../hooks/use-http'

const CatalogHome = () => {
  const [pic_data, setPicData] = useState({})
  const [data, setData] = useState([])
  const homepageBoxElem = useRef(null)
  const handleHomepageBoxesResp = (resp) => {
    const { boxes } = resp
    setData([...boxes])
  }

  const { sendRequest: handleHomepageBoxes } = useHttp(
    {
      url: `${SERVER_URL}/proxy/homepageBoxes`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleHomepageBoxesResp
  )

  useEffect(() => {
    sendRequest()
    handleHomepageBoxes()
  }, [])

  const handleImageResponse = (response) => {
    setPicData(response)
  }
  function createMarkup(text) {
    const markedText = marked(text)
    // Add target="_blank" to all anchor tags
    const modifiedText = markedText.replace(
      /<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>/gi,
      `<a href="$1" target="_blank"$2>`
    )
    return { __html: modifiedText }
  }

  useEffect(() => {
    if (homepageBoxElem.current?.children.length === 3) {
      for (let i = 0; i < 3; i++) {
        if (homepageBoxElem.current?.children[i].querySelector('a')) {
          homepageBoxElem.current?.children[i]
            .querySelector('a')
            .setAttribute('target', '_blank')
        }
      }
    }
  }, [data])

  const { sendRequest } = useHttp(
    {
      url: `${SEARCH_URL}/image-carousel/random`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleImageResponse
  )

  let imageStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
  if (pic_data?.objectUrl) {
    imageStyle.backgroundImage = `url(${pic_data?.objectUrl})`
  }

  const icons = ['person', 'date', 'comment']

  const HomepageBox = () => {
    return data.map((box, index) => {
      return (
        <Card
          key={box.id}
          className={[
            // 'grid-col-12 tablet:grid-col-6 desktop-lg:grid-col-4',
            'padding-2',
            'margin-bottom-2',
            'hompage-boxes',
          ].join(' ')}
        >
          <p
            className={[
              'display-flex',
              'flex-align-center',
              'flex-column',
              'flex-gap',
              'margin-x-auto',
              'maxw-card-lg',
            ].join(' ')}
          >
            <Icon iconName={icons[index]} iconSize="md" />
            <h2 className="font-sans-md margin-bottom-0">
              <div
                className="markdown-format-result link-parser"
                dangerouslySetInnerHTML={{
                  __html: marked(box?.heading),
                }}
              ></div>
            </h2>
            <div
              className="markdown-format-result link-parser homepage-boxes"
              dangerouslySetInnerHTML={createMarkup(box?.text)}
            ></div>
          </p>
        </Card>
      )
    })
  }

  return (
    <div className={['bg-primary-lighter'].join(' ')}>
      <BannerHome />
      <div
        className={[
          'nac-body',
          'nac-home-bg',
          'bg-primary-lighter',
          'display-flex',
          'flex-column',
          'padding-2 tablet-lg:padding-4',
          'position-relative',
        ].join(' ')}
        role="main"
        data-testid="nac-body--basic-page"
        style={imageStyle}
      >
        <div
          className={[
            'bg-base-darker-opacity-40',
            'bottom-0',
            'left-0',
            'position-absolute',
            'right-0',
            'top-0',
          ].join(' ')}
        ></div>
        <div
          className={[
            'display-flex',
            'flex-column',
            'flex-gap',
            'flex-justify',
            'flex-align-stretch',
            `maxw-${DEFAULT_MAX_CONTENT_WIDTH}`,
            'width-full',
          ].join(' ')}
        >
          <Card
            className={[
              'bg-primary-dark',
              'border-0',
              'display-flex',
              'flex-column',
              'flex-justify',
              'flex-align-stretch',
              'width-full',
            ].join(' ')}
          >
            <CatalogSearch />
            <div
              className={[
                'flex-no-wrap',
                'margin-left-auto',
                'width-auto',
              ].join(' ')}
            >
              <a
                href="/advanced-search"
                data-testid="nac-link--advanced-search"
                className="usa-link--white font-sans-sm"
                style={{
                  marginRight: '10px',
                  textDecoration: 'underline',
                  color: '#e9f3f7',
                }}
              >
                Advanced Search
              </a>
            </div>
          </Card>
        </div>
      </div>
      {ROLLOVER_CONTENT && (
        <div
          className={[
            'bg-primary-lighter',
            'padding-2 tablet-lg:padding-4',
            'margin-top-neg-15',
            'width-full',
          ].join(' ')}
        >
          <div
            className={[
              'display-flex',
              'flex-column',
              'flex-gap',
              `maxw-${DEFAULT_MAX_CONTENT_WIDTH}`,
              'width-full',
            ].join(' ')}
          >
            <Card
              className={[
                'font-sans-2xs',
                'maxw-mobile',
                'margin-left-auto',
                'width-auto',
              ].join(' ')}
              reduced
            >
              <div
                className={[
                  'display-flex',
                  'flex-align-center',
                  'flex-gap-sm',
                  'flex-row',
                ].join(' ')}
              >
                <Icon iconName="image" iconSize="xs" />
                <Link
                  className="ellipsed"
                  title={pic_data?.title}
                  data-testid="background-image-link"
                  to={{
                    pathname: `/id/${pic_data?.naId}`,
                    search: `objectId=${pic_data?.objectId}`,
                  }}
                >
                  {pic_data?.title}
                </Link>
                {pic_data?.naId}
              </div>
            </Card>
            <div className={['margin-x-auto', 'width-full'].join(' ')}>
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'flex-gap-lg',
                  'font-sans-sm',
                  'text-base-darker',
                  'text-center',
                  'text-normal',
                  'width-full',
                ].join(' ')}
              >
                <div
                  ref={homepageBoxElem}
                  className={[
                    'tablet:display-flex',
                    'flex-justify-center',
                    'flex-row',
                    'flex-gap tablet-lg:flex-gap-md',
                    'line-height-sans-6',
                    'width-full',
                  ].join(' ')}
                >
                  <HomepageBox />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CatalogHome
