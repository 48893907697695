import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import { DEFAULT_LIMIT, SEARCH_URL } from '../../settings/globals'
//Components
import { Alert } from '../../Alert/Alert'
import { Button } from '../../Button/Button'
import { Icon } from '../../Icon/Icon'
import { Table } from '../../Table/Table'
import AccountContent, { ContentTitle } from '../account_content'
import Popover, { PopoverBody, PopoverFooter } from '../../Popover/Popover'
import Card from '../../Card/Card'
import RadioButtonField from '../../form/RadioButtonField'
import Tooltip from '../../Tooltip/Tooltip'
//Helpers
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
//Hooks
import useBackgroundImages from './use-background-images'
import useScreenSize from '../../../hooks/use-screen-size'
import useHttp from '../../../hooks/use-http'
import RouterPrompt from '../../Modal/variants/RouterPrompt'
import Prose from '../../utilities/Prose'
import Modal, { ModalBody, ModalFooter } from '../../Modal/Modal'

const BackgroundImages = () => {
  const { columns, data, errors, loading, page, pages, setPageHandler, table } =
    useBackgroundImages()

  return (
    <AccountContent>
      <ContentTitle iconName="image" title="Background Images" />
      <>
        <div
          className={[
            'display-flex',
            'flex-gap',
            'flex-column',
            'width-full',
          ].join(' ')}
        >
          {!loading && errors.length > 0 && (
            <div>
              {errors.map((error) => (
                <Alert key={error?.description} slim type={error?.type}>
                  {error?.description}
                </Alert>
              ))}
            </div>
          )}
          <div
            className={['display-flex', 'flex-gap-sm', 'flex-column'].join(' ')}
          >
            {data.length > 0 && (
              <div className="overflow-x-scroll margin-x-neg-2 tablet:margin-x-neg-0 padding-bottom-2 tablet-lg:padding-bottom-0">
                <Card
                  className={[
                    'padding-0',
                    'tablet-lg:padding-0',
                    'margin-x-2',
                    'tablet-lg:margin-x-0',
                  ].join(' ')}
                  style={{ minWidth: '40rem' }}
                >
                  <Table
                    className="padding-0"
                    columns={columns}
                    data={table}
                    id="nac-images-table"
                    loading={loading}
                    pageNum={page}
                    pageCount={pages}
                    setPage={setPageHandler}
                  />
                </Card>
              </div>
            )}
          </div>
        </div>
      </>
    </AccountContent>
  )
}

export default BackgroundImages

export const useBackgroundImagesActions = (onSuccess = () => {}) => {
  const [action, setAction] = useState(null)
  const [objectId, setObjectId] = useState(null)
  const [objectUrl, setObjectUrl] = useState(null)
  const [naId, setNaId] = useState(null)
  const [title, setTitle] = useState(null)
  const [error, setError] = useState(null)
  const [requesting, setRequesting] = useState(null)
  const [response, setResponse] = useState(null)

  let method = 'POST'
  switch (action) {
    case 'add':
      break
    case 'activate':
      break
    case 'deactivate':
      break
    case 'delete':
      method = 'DELETE'
      break
  }

  const backgroundImageHandler = (action, objectId, objectUrl, naId, title) => {
    if (!action || !objectId) return false
    setAction(action)
    setObjectId(objectId)
    if (objectUrl) setObjectUrl(objectUrl)
    if (naId) setNaId(naId)
    if (title) setTitle(title)
  }

  useEffect(() => {
    let sendRequest = false
    if (action && action === 'add' && objectId && objectUrl && naId && title)
      sendRequest = true
    else if (action && action !== 'add' && objectId) sendRequest = true

    if (sendRequest && !requesting && !response) {
      setRequesting(true)
      requestBackgroundImageHandler()
    }
  }, [action, objectId, objectUrl, naId, title])

  const errorHandler = (error) => {
    if (error) setError(error)
  }

  const responseHandler = (response) => {
    if (response) setResponse(response)
  }

  useEffect(() => {
    if (response) {
      onSuccess()
      setResponse(null)
      setRequesting(false)
    }
  }, [response])

  const { sendRequest: requestBackgroundImageHandler } = useHttp(
    {
      url: `${SEARCH_URL}/image-carousel/${action}?objectId=${objectId}${
        naId ? `&naId=${naId}` : ''
      }${objectUrl ? `&objectUrl=${objectUrl}` : ''}${
        title ? `&title=${title}` : ''
      }`,
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    responseHandler,
    errorHandler
  )

  return {
    backgroundImageHandler,
    error,
    requesting,
    response,
  }
}

export const BackgroundImageTableButton = ({
  objectId,
  onUpdate,
  status,
  ...props
}) => {
  return (
    <div
      className={[
        'display-flex',
        'flex-justify-start',
        'flex-row',
        'position-relative',
      ].join(' ')}
    >
      {status === 'active' ? (
        <ButtonBackgroundImageAction
          action="deactivate"
          objectId={objectId.toString()}
          onUpdate={onUpdate}
          reduced
        />
      ) : (
        <ButtonBackgroundImageAction
          action="activate"
          objectId={objectId.toString()}
          onUpdate={onUpdate}
          reduced
        />
      )}
      <ButtonBackgroundImageAction
        action="delete"
        objectId={objectId.toString()}
        onUpdate={onUpdate}
        reduced
      />
    </div>
  )
}

BackgroundImageTableButton.propTypes = {
  /**
   * Current status of the given objectId
   */
  status: PropTypes.string,
  /**
   * ObjectId of the image to action
   */
  objectId: PropTypes.string,
  /**
   * Function to run once the images status has been successfully updated
   */
  onUpdate: PropTypes.func,
}

export const ButtonBackgroundImageAction = ({
  action,
  objectId,
  objectUrl,
  naId,
  title,
  onUpdate,
  reduced,
}) => {
  const { screenSize } = useScreenSize()
  const [removing, setRemoving] = useState(false)

  const responseHandler = () => {
    setRemoving(false)
    onUpdate()
  }

  let color = 'primary'
  let label = ''
  let iconName = ''
  let onClick = () => {}
  let srText = ''
  let tip = ''
  switch (action) {
    case 'add':
      label = 'Add to list'
      iconName = 'plus'
      srText = 'Add to homepage background.'
      break
    case 'activate':
      label = 'Make Active'
      iconName = 'rotate-left'
      srText = 'Make this image visible in the homepage background.'
      break
    case 'deactivate':
      label = 'Make Inactive'
      iconName = 'stop'
      srText =
        'Prevent this image from appearing on the homepage background but keep in the list.'
      break
    case 'delete':
      color = 'error'
      label = 'Remove'
      iconName = 'trash'
      srText = 'Remove this image from the background images list.'
      break
  }

  const { backgroundImageHandler, error, requesting, response } =
    useBackgroundImagesActions(responseHandler)

  return (
    <>
      <Tooltip
        disabled={!reduced}
        className="flex-align-center"
        content={label}
      >
        <Button
          className={[
            'flex-justify-center',
            'height-full',
            reduced ? '' : 'margin-bottom-neg-105 margin-top-neg-105',
          ].join(' ')}
          disabled={requesting}
          iconName={iconName}
          iconOnly={reduced}
          iconPercent={screenSize !== 'desktop' ? '100' : '80'}
          iconSize={!reduced ? 'xs' : screenSize !== 'desktop' ? 'xs' : 'sm'}
          onClick={() =>
            action === 'delete'
              ? setRemoving(true)
              : backgroundImageHandler(action, objectId, objectUrl, naId, title)
          }
          // outline={!reduced}
          reduced
          srText={srText}
          textOnly //={reduced}
          theme={color}
          thin
          size="2xs"
        >
          {!reduced && label}
        </Button>
      </Tooltip>
      {removing && (
        <Modal
          hide={() => setRemoving(false)}
          id="modal-action-heroImage"
          title="Remove this Background Image?"
        >
          <ModalBody id="modal-action-heroImage">
            <div
              className={[
                'display-flex',
                'flex-column',
                'flex-gap',
                'text-left',
                'width-full',
              ].join(' ')}
            >
              <Prose>
                This will remove this image from the list of images displayed on
                the homepage background.
              </Prose>
              <Prose>
                Removed images can be added back to the list at any time.
              </Prose>
            </div>
          </ModalBody>
          <ModalFooter hide={() => setRemoving(false)}>
            <div className={['display-flex', 'width-full'].join(' ')}>
              <Button
                className={['margin-right-auto '].join(' ')}
                data-testid="nac-heroImage-cancel"
                iconSize="xs"
                onClick={() => setRemoving(false)}
                reduced
                size="2xs"
                textOnly
              >
                Cancel
              </Button>
              <Button
                className={['margin-left-auto '].join(' ')}
                data-testid="nac-heroImage-accept"
                size="2xs"
                onClick={() => backgroundImageHandler('delete', objectId)}
                reduced
              >
                Remove
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

ButtonBackgroundImageAction.defaultProps = {
  reduced: false,
}

ButtonBackgroundImageAction.propTypes = {
  /**
   * Specific action to take
   */
  action: PropTypes.oneOf(['add', 'activate', 'deactivate', 'delete']),
  /**
   * ObjectId of the image to action
   */
  objectId: PropTypes.string,
  /**
   * Function to run once the images status has been successfully updated
   */
  onUpdate: PropTypes.func,
  /**
   * Determines size and displayed content of the button
   */
  reduced: PropTypes.bool,
  objectUrl: PropTypes.string,
  naId: PropTypes.number,
  title: PropTypes.string,
}
