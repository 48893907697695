import React from 'react'
import '../../../../styles/index.scss'

const CommentStatusEdited = ({ ...props }) => {
  return (
    <span className="font-sans-3xs text-base-dark" {...props}>
      {' '}
      (edited)
    </span>
  )
}

CommentStatusEdited.defaultProps = {}

CommentStatusEdited.propTypes = {}

export default CommentStatusEdited
