import React, { useContext } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
import SearchContext from '../../contexts/SearchContext'
//Components
import { SectionHeading } from '../SectionHeading/SectionHeading'
import { Link } from 'react-router-dom'
import { DEFAULT_AUTHORITY_RECORD_INDEX } from '../settings/globals'

const SectionTerms = ({ ...props }) => {
  const recordContext = useContext(RecordContext)
  const searchContext = useContext(SearchContext)

  const record = recordContext.record
  const term = record.heading || ''
  const narrow = record.narrowerTerms || []
  const broad = record.broaderTerms || []
  const related = record.relatedTerms || []

  return (
    <div
      className={['flex-align-start', 'flex-column', 'width-full'].join(' ')}
      data-testid="nac-description__content--terms"
      {...props}
    >
      <div
        className={[
          'display-flex',
          'flex-no-wrap',
          related.length > 0
            ? [
                'tablet-lg:flex-row',
                'tablet-lg:flex-gap-lg',
                'flex-column',
                'flex-gap-0',
              ].join(' ')
            : ['flex-row', 'flex-gap-lg'].join(' '),
        ].join(' ')}
      >
        {broad.length > 0 || narrow.length > 0 ? (
          <div
            className={[
              'flex-column',
              related.length > 0
                ? [
                    'border-base-lighter',
                    'border-bottom',
                    'border-right-0',
                    'margin-bottom-3',
                    'tablet-lg:border-base-lighter',
                    'tablet-lg:border-bottom-0',
                    'tablet-lg:border-right',
                    'tablet-lg:padding-right-4',
                    'width-full',
                  ].join(' ')
                : ['grid-col', 'tablet-lg:grid-col-6'].join(' '),
            ].join(' ')}
          >
            <SectionHeading
              copyLink
              id="broad-narrow-terms"
              count={broad.length + narrow.length}
            >
              Broader and Narrower Terms
            </SectionHeading>
            {broad.length > 0 && (
              <div className="display-flex flex-column flex-align-start margin-bottom-3 border border-base-lighter radius-md padding-1 padding-bottom-0">
                <h3>Broader Term{broad.length > 1 ? 's' : ''}</h3>
                {broad.map((b) => (
                  <p
                    key={b.heading}
                    className="flex-row grid-row flex-align-center margin-bottom-2"
                  >
                    <span className="flex-column grid-col-auto flex-align-self-start nac-icons nac-icon-primary nac-icon__bookmark width-205 height-205 display-inline-flex margin-right-1"></span>
                    <Link
                      to={{ pathname: `/id/${b.naId}` }}
                      state={{
                        controlGroup: DEFAULT_AUTHORITY_RECORD_INDEX,
                      }}
                    >
                      {b.heading}
                    </Link>
                  </p>
                ))}
              </div>
            )}
            <div
              className={[
                'bg-base-lighter-opacity-40',
                'border',
                'border-base-lighter',
                'display-flex',
                'flex-align-start',
                'flex-column',
                'margin-bottom-3',
                'padding-1',
                'radius-md',
                broad.length > 0 ? 'margin-left-3' : 'margin-left-0',
              ].join(' ')}
            >
              <h3 className="text-base-dark">This Term</h3>
              <p
                key={term}
                className="flex-row grid-row flex-align-center margin-bottom-1"
              >
                <span className="flex-column grid-col-auto flex-align-self-start nac-icons nac-icon-ink nac-icon__bookmark width-205 height-205 display-inline-flex margin-right-1"></span>
                <span className="flex-column grid-col">{term}</span>
              </p>
            </div>
            {narrow.length > 0 && (
              <div
                className={`display-flex flex-column flex-align-start margin-bottom-3 border border-base-lighter radius-md padding-1 padding-bottom-0 ${
                  broad.length > 0 ? 'margin-left-6' : 'margin-left-3'
                }`}
              >
                <h3>Narrower Term{narrow.length > 1 ? 's' : ''}</h3>
                {narrow.map((n) => (
                  <p
                    key={n.heading}
                    className="flex-row grid-row flex-align-center margin-bottom-2"
                  >
                    <span className="flex-column grid-col-auto flex-align-self-start nac-icons nac-icon-primary nac-icon__bookmark width-205 height-205 display-inline-flex margin-right-1"></span>
                    <Link
                      to={{ pathname: `/id/${n.naId}` }}
                      state={{
                        controlGroup: DEFAULT_AUTHORITY_RECORD_INDEX,
                      }}
                    >
                      {n.heading}
                    </Link>
                  </p>
                ))}
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        {related.length > 0 ? (
          <div className={['flex-column', 'width-full'].join(' ')}>
            <SectionHeading copyLink id="related-terms" count={related.length}>
              Related Term{related.length > 1 ? 's' : ''}
            </SectionHeading>
            <div className="display-flex flex-column flex-align-start margin-bottom-3">
              {related.map((r) => (
                <p
                  key={r.heading}
                  className="flex-row grid-row flex-align-center margin-bottom-2"
                >
                  <span className="flex-column grid-col-auto flex-align-self-start nac-icons nac-icon-primary nac-icon__bookmark width-205 height-205 display-inline-flex margin-right-1"></span>
                  <Link
                    to={{ pathname: `/id/${r.naId}` }}
                    state={{
                      controlGroup: DEFAULT_AUTHORITY_RECORD_INDEX,
                    }}
                  >
                    {r.heading}
                  </Link>
                </p>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default SectionTerms
