import React, { Fragment } from 'react'

export const stepsToLogin = [
  {
    title: 'Step 1 brief description',
    description:
      'Lorem ipsum dolor sit amet. 33 quod magnam sit autem dolores non quod quis. Aut excepturi consequatur quasi tempora ex nobis nihil qui cumque earum sed perspiciatis internos et quos repellat.\n\nSed possimus quod et eaque voluptatum sit optio minima est earum obcaecati ab nulla veritatis. Est dolorem nihil ut vitae voluptatem quo odit accusantium ut dolorem enim. Aut temporibus omnis ut esse dignissimos 33 nulla doloremque. Et ipsam soluta qui eligendi enim nam corporis corporis.\n\nEt voluptate quasi est voluptas quia vel eligendi officia sed eaque accusamus qui dolor commodi non delectus nisi? Et inventore necessitatibus et alias facere aut atque repellendus. Aut illo perferendis et illo dolorem sed laudantium quas. Est repellendus possimus et expedita blanditiis aut reprehenderit ipsam est autem obcaecati sit perspiciatis ut repudiandae quod.',
    media: [
      {
        type: 'image',
        url: 'google.com',
      },
    ],
  },
  {
    title: 'Step 2 brief description',
    description:
      'Lorem ipsum dolor sit amet. 33 quod magnam sit autem dolores non quod quis. Aut excepturi consequatur quasi tempora ex nobis nihil qui cumque earum sed perspiciatis internos et quos repellat.',
  },
  {
    title: 'Step 3 brief description',
    description:
      'Lorem ipsum dolor sit amet. 33 quod magnam sit autem dolores non quod quis. Aut excepturi consequatur quasi tempora ex nobis nihil qui cumque earum sed perspiciatis internos et quos repellat.',
    media: [
      {
        type: 'image',
        url: 'google.com',
      },
    ],
  },
  {
    title: 'Step 4 brief description',
  },
  {
    title: 'Step 5 brief description',
    description:
      'Lorem ipsum dolor sit amet. 33 quod magnam sit autem dolores non quod quis. Aut excepturi consequatur quasi tempora ex nobis nihil qui cumque earum sed perspiciatis internos et quos repellat.\n\nSed possimus quod et eaque voluptatum sit optio minima est earum obcaecati ab nulla veritatis. Est dolorem nihil ut vitae voluptatem quo odit accusantium ut dolorem enim. Aut temporibus omnis ut esse dignissimos 33 nulla doloremque. Et ipsam soluta qui eligendi enim nam corporis corporis.',
  },
]

export const stepsToLogout = [
  {
    title: 'Step 1 brief description',
    description:
      'Lorem ipsum dolor sit amet. 33 quod magnam sit autem dolores non quod quis. Aut excepturi consequatur quasi tempora ex nobis nihil qui cumque earum sed perspiciatis internos et quos repellat.\n\nSed possimus quod et eaque voluptatum sit optio minima est earum obcaecati ab nulla veritatis. Est dolorem nihil ut vitae voluptatem quo odit accusantium ut dolorem enim. Aut temporibus omnis ut esse dignissimos 33 nulla doloremque. Et ipsam soluta qui eligendi enim nam corporis corporis.\n\nEt voluptate quasi est voluptas quia vel eligendi officia sed eaque accusamus qui dolor commodi non delectus nisi? Et inventore necessitatibus et alias facere aut atque repellendus. Aut illo perferendis et illo dolorem sed laudantium quas. Est repellendus possimus et expedita blanditiis aut reprehenderit ipsam est autem obcaecati sit perspiciatis ut repudiandae quod.',
    media: [
      {
        type: 'image',
        url: 'google.com',
      },
    ],
  },
  {
    title: 'Step 2 brief description',
    description:
      'Lorem ipsum dolor sit amet. 33 quod magnam sit autem dolores non quod quis. Aut excepturi consequatur quasi tempora ex nobis nihil qui cumque earum sed perspiciatis internos et quos repellat.',
  },
  {
    title: 'Step 3 brief description',
    description:
      'Lorem ipsum dolor sit amet. 33 quod magnam sit autem dolores non quod quis. Aut excepturi consequatur quasi tempora ex nobis nihil qui cumque earum sed perspiciatis internos et quos repellat.',
    media: [
      {
        type: 'image',
        url: 'google.com',
      },
    ],
  },
]

export const loginSteps = [
  {
    description:
      'Lorem ipsum dolor sit amet. 33 quod magnam sit autem dolores non quod quis.',
    id: 'account-log-in',
    steps: stepsToLogin,
    title: 'Subtitle for steps...',
  },
  {
    id: 'account-log-out',
    steps: stepsToLogout,
    title: 'Subtitle for steps...',
  },
]
