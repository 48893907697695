import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * React Router v6 doesn't ship with support for preventing transitions.
 * Once this issue is resolved, the following method must be updated
 * with the recommended way to prevent transitions.
 * For now, here's a very hacky not "approved" approach that "works".
 * https://ui.dev/react-router-preventing-transitions
 */
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'
import { Button } from '../../Button/Button'
import Modal, { ModalBody, ModalFooter } from '../Modal'

const RouterPrompt = ({
  cancelText,
  cancelWhen = true,
  children,
  confirmText,
  confirmWhen = true,
  error,
  onCancel,
  onConfirm,
  title,
  when,
  ...props
}) => {
  // const navigator = useNavigate()

  const { navigator } = useContext(NavigationContext)
  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')
  const [cancelWaiting, setCancelWaiting] = useState(false)
  const [confirmWaiting, setConfirmWaiting] = useState(false)
  const [closeTab, setCloseTab] = useState(false)

  const unblockRef = useRef()

  const handleShowModal = (event) => {
    if (event) {
      event.preventDefault()
      event.returnValue = title
      return title
    }
    setShowPrompt(true)
  }

  /* When the window is resized, update the screenSize state */
  useEffect(() => {
    if (when) {
      window.addEventListener('beforeunload', handleShowModal, (event) => {
        setCloseTab(true)
        handleShowModal(event)
      })
    }
    // window.addEventListener('unload', handleTabClosing)
    return () => {
      window.removeEventListener('beforeunload', handleShowModal, (event) => {
        setCloseTab(false)
        handleShowModal(event)
      })
      //   window.removeEventListener('unload', handleTabClosing)
    }
  }, [when])

  const handleCancel = () => {
    if (!cancelWhen) {
      setCancelWaiting(true)
      if (onCancel) onCancel()
    } else {
      if (!confirmWhen && onCancel) onCancel()
      setShowPrompt(false)
    }
  }

  const handleConfirm = () => {
    if (!confirmWhen) {
      setConfirmWaiting(true)
      if (onConfirm) onConfirm()
    } else {
      if (unblockRef) {
        unblockRef.current()
      }
      if (!confirmWhen && onConfirm) onConfirm()
      else {
        setShowPrompt(false)
        navigator(currentPath)
      }
    }
    if (closeTab) window.close()
  }

  useEffect(() => {
    if (cancelWaiting && cancelWhen) {
      handleCancel()
    }
    if (confirmWaiting && confirmWhen) {
      handleConfirm()
    }
  }, [cancelWaiting, cancelWhen, confirmWaiting, confirmWhen])

  useEffect(() => {
    //Reset waiting constants once the prompt is hidden
    if (!showPrompt) {
      setConfirmWaiting(false)
      setCancelWaiting(false)
    }
  }, [showPrompt])

  useEffect(() => {
    unblockRef.current = navigator.block((location) => {
      if (when) {
        setCurrentPath(location.pathname)
        handleShowModal()
        return false
      }
      return true
    })
    return () => {
      unblockRef.current && unblockRef.current()
    }
  }, [when])

  return showPrompt ? (
    <Modal
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      hide={handleCancel}
      id="leaving-page"
      title={title}
    >
      <>
        <ModalBody id="leaving-page">
          {error
            ? error
            : confirmWaiting || cancelWaiting
            ? 'processing...'
            : children}
        </ModalBody>
        <ModalFooter hide={handleCancel}>
          <div className="display-flex">
            {!cancelWaiting && !error && (
              <Button
                className={['flex-shrink-0', 'margin-right-auto '].join(' ')}
                data-testid="nac-transcriptions_leave-confirm"
                disabled={confirmWaiting}
                iconAnimation={confirmWaiting ? 'rotate-cw' : ''}
                iconName={confirmWaiting ? 'arrow-rotation-doubleright' : null}
                iconSize="2xs"
                size="2xs"
                onClick={handleConfirm}
                textOnly
                reduced
              >
                {confirmText}
              </Button>
            )}
            {!confirmWaiting && !error && (
              <Button
                className={['flex-shrink-0', 'margin-left-auto'].join(' ')}
                data-testid="nac-transcriptions_leave-cancel"
                disabled={cancelWaiting}
                iconAnimation={cancelWaiting ? 'rotate-cw' : ''}
                iconName={cancelWaiting ? 'arrow-rotation-doubleright' : null}
                iconSize="2xs"
                size="2xs"
                onClick={handleCancel}
                reduced
              >
                {cancelText}
              </Button>
            )}
            {error && (
              <Button
                aria-label="Save and close the transcription editor"
                className={['margin-left-auto'].join(' ')}
                data-testid="nac-transcriptions_leave-close"
                disabled={cancelWaiting}
                iconAnimation={cancelWaiting ? 'rotate-cw' : ''}
                iconName={cancelWaiting ? 'arrow-rotation-doubleright' : null}
                iconSize="2xs"
                size="2xs"
                onClick={handleCancel}
              >
                Close
              </Button>
            )}
          </div>
        </ModalFooter>
      </>
    </Modal>
  ) : (
    ''
  )
}

RouterPrompt.propTypes = {
  cancelText: PropTypes.string,
  cancelWhen: PropTypes.bool,
  /**
   * JSX element(s), The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  confirmText: PropTypes.string,
  confirmWhen: PropTypes.bool,
  /**
   * JSX element(s), The content provided to the component.
   */
  error: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  when: PropTypes.bool,
}

export default RouterPrompt
