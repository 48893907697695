import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import ClickAwayListener from 'react-click-away-listener'
import Popover, { PopoverBody, PopoverFooter } from '../../Popover/Popover'
import RadioButtonField from '../../form/RadioButtonField'
import { SEARCH_URL } from '../../settings/globals'
import { returnAbbreviatedQueryStringFromObject } from '../../../helper-functions/param-functions'
import { useSafeAsync } from '../../../helper-functions/query-functions'
import CircularProgress from '@mui/material/CircularProgress'
import ExportContext from '../../../contexts/ExportContext'
import { IconLabel } from '../../IconLabel/IconLabel'
import useHttp from '../../../hooks/use-http'
import useScreenSize from '../../../hooks/use-screen-size'
import SearchContext from '../../../contexts/SearchContext'
import useFormatter from '../../../hooks/use-formatters'
import PrintSearchResults from '../../PrintSearchResults.js'
import PopupWindow from '../../PopupWindow/PopupWindow.js'
import PrintDescriptionRecord from '../../description/PrintDescriptionRecord.js'
import { Link } from 'react-router-dom'

export const ExportButton = (props) => {
  const { activeResults: results } = useContext(SearchContext)
  const { screenSize } = useScreenSize()
  const [active, setActive] = useState(false)
  const [printActive, setPrintActive] = useState(false)
  const popoverRef = useRef(null)
  const popoverPrintRef = useRef(null)
  const [buttonHeight, setButtonHeight] = useState(0)
  const buttonRef = useRef(null)
  const printButtonRef = useRef(null)
  const [checkedDetails, setCheckedDetails] = useState('brief')
  const [checkedFormat, setCheckedFormat] = useState('csv')
  const [printThumbnail, setPrintThumbnail] = useState('yes')
  const safeAsync = useSafeAsync()
  const [timedOut, setTimedOut] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [printing, setPrinting] = useState(false)
  const [queryParams, setQueryParams] = useState()
  const [error, setError] = useState(false)
  const [data, setData] = useState([])
  const [displayExport, setDisplayExport] = useState(false)
  const { formatDateTime } = useFormatter()
  const [openPopUp, setOpenPopup] = useState(false)
  const [showFull, setShowFull] = useState(false)

  useEffect(() => {
    if (buttonRef?.current && !buttonHeight)
      setButtonHeight(buttonRef.current?.clientHeight)
  }, [buttonRef])

  const {
    allChecked,
    naIdsToIncludeInExport,
    naIdsToExcludeFromExport,
    setNaIdsToIncludeInExportHandler,
  } = useContext(ExportContext) || {}

  const disabled =
    (allChecked && naIdsToExcludeFromExport.length === props.total) ||
    (!allChecked && naIdsToIncludeInExport.length === 0)

  const [checkedFrom, setCheckedFrom] = useState(disabled ? 'page' : 'current')

  useEffect(() => {
    if (disabled) setCheckedFrom('page')
    else setCheckedFrom('current')
  }, [disabled])

  const clickExportHandler = () => {
    let params = props.search
    delete params.page
    params.limit =
      allChecked && naIdsToExcludeFromExport.length > 0
        ? 10000 - naIdsToExcludeFromExport.length
        : 10000
    if (checkedDetails === 'brief') params.abbreviated = true
    else delete params.abbreviated
    if (checkedFrom === 'current') {
      if (naIdsToExcludeFromExport.length > 0)
        params.q += ` AND NOT record.naId:(${naIdsToExcludeFromExport.join(
          ' OR '
        )})`
      if (naIdsToIncludeInExport.length > 0)
        params.naId = naIdsToIncludeInExport.join(',')
    } else if (checkedFrom === 'page') {
      params.naId = results
        .map((r) => {
          return r.naId
        })
        .join(',')
    }
    let paramsString = []
    for (const [key, value] of Object.entries(params)) {
      paramsString.push(`${key}=${value}`)
    }
    setQueryParams(paramsString.join('&'))
    setError(false)
    setExporting(true)
  }

  const responsePrintHandler = (data) => {
    let results = []
    data.body.hits.hits.map((result) => {
      let record = { ...result._source.record, ...result.fields }
      results.push(record)
    })

    setData([...results])
    extractNaids(results)
  }

  const errorPrintHandler = (error) => {
    if (error) {
      setError(true)
      setPrinting(false)
    }
  }

  const createQueryString = (queryObj) => {
    const result = Object.entries(queryObj)
      .map((_m) => {
        if (!_m.includes('')) {
          return _m
            .join(' ')
            .replace(/^/, '&')
            .replace(/(?<=[a-z]\s)/, '=')
            .replace(/\s/, '')
        }
      })
      .join('')
      .replace(/&/, '')
    return result
  }

  const getQueryParams = () => {
    let _query = {}
    if (!naIdsToIncludeInExport.join(',').length > 0) {
      props.search.limit = props.limit
      _query = { ...props.search }
      if (!/full/gi.test(checkedDetails)) {
        _query.abbreviated = true
      }
      _query = Object.keys(_query).reduce((ac, cv) => {
        ac[cv] = encodeURI(_query[cv])
        return ac
      }, {})
    } else {
      _query.naId = naIdsToIncludeInExport.join(',')
      _query.abbreviated = props.search.abbreviated = true
    }
    const result = createQueryString(_query)
    return result
  }

  const getQueryParamsMsg = () => {
    let _query = {}
    if (!naIdsToIncludeInExport.join(',').length > 0) {
      props.search.limit = props.limit
      _query = { ...props.search }
      _query = Object.keys(_query).reduce((ac, cv) => {
        ac[cv] = encodeURI(_query[cv])
        return ac
      }, {})
    } else {
      delete _query.abbreviated
      _query.naId = naIdsToIncludeInExport.join(',')
    }
    const result = createQueryString(_query)
    return result
  }

  const getQueryParamsFullMsg = () => {
    const _query = {}

    _query.naId = naIdsToIncludeInExport.join(',')

    const result = createQueryString(_query)
    return result
  }

  const { sendRequest: requestPrintHandler } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?${getQueryParams()}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    responsePrintHandler,
    errorPrintHandler
  )

  const extractNaids = (records) => {
    const _naids = records.map((item) => {
      return item.naId
    })
    setNaIdsToIncludeInExportHandler([..._naids])
  }

  const printFullPdf = () => {
    if (/page/gi.test(checkedFrom)) {
      requestPrintHandler()
    }
    setShowFull(true)
    setDisplayExport(true)
    setPrintActive(!printActive)
    handleClickPrintAway()
  }

  const printPDF = () => {
    requestPrintHandler()
    setDisplayExport(true)
    handleClickPrintAway()
  }

  const handleSetShowThumbnails = (e) => {
    setPrintThumbnail(e)
  }

  useEffect(() => {
    if (queryParams && exporting) requestExportHandler()
  }, [exporting])

  const responseExportHandler = (data) => {
    const count = data.body.hits.total.value
    // Elasticsearch timeout
    data.body.timed_out == true ? setTimedOut(true) : setTimedOut(false)
    if (timedOut && count == 0) throw 'NO_RESULTS_TIMED_OUT'
    else {
      let results = []
      data.body.hits.hits.map((result) => {
        let record = { ...result._source.record, ...result.fields }
        results.push(record)
      })
      if (checkedFormat === 'csv') {
        const parsedResults = flattenObject(results)
        const headers = findHeaders(parsedResults)
        convertToCSV(parsedResults, headers)
      }
      if (checkedFormat === 'json') {
        triggerDownload(JSON.stringify(results, null, 2), 'json')
      }
    }
  }

  const errorHandler = (error) => {
    if (error) {
      console.log(error)
      setExporting(false)
      setError(true)
    }
  }

  const { sendRequest: requestExportHandler } = useHttp(
    {
      /** TO DO: Set up endpoint for userName!!! */
      url: `${SEARCH_URL}/records/search?${queryParams}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    responseExportHandler,
    errorHandler
  )

  // Creates CSV file
  const convertToCSV = (data, headers) => {
    const delimiter = ','
    const csv = data.map((row) =>
      headers
        .map((fieldName) =>
          JSON.stringify(
            row[fieldName] === 0 ? 0 : row[fieldName] ?? ''
          ).replaceAll('\\"', '""')
        )
        .join(delimiter)
    )
    csv.unshift(headers.join(delimiter))
    const csvAsString = csv.join('\r\n')
    triggerDownload(csvAsString, 'csv')
  }

  // Returns an array of all the object keys used
  const findHeaders = (arr) => {
    const headers = []
    arr.forEach((result) => {
      Object.keys(result).forEach((header) => {
        if (!headers.includes(header)) {
          headers.push(header)
        }
      })
    })
    return headers
  }

  // Flattens each object in the parent json array and arrays inside
  const flattenObject = (arr) => {
    let results = []
    arr.forEach((result) => {
      let resultob = {}
      Object.entries(result).forEach((item) => {
        const [key, value] = item
        if (Array.isArray(value)) {
          resultob[key] = flattenArray(value)
        } else {
          resultob[key] = value
        }
      })
      results.push(flattenArray(resultob))
    })
    return results
  }

  // Flatten arrays of objects recursively
  const flattenArray = (ob) => {
    var toReturn = {}
    for (var i in ob) {
      if (!ob[i]) continue
      if (typeof ob[i] == 'object' && ob[i] !== null) {
        var flatObject = flattenArray(ob[i])
        for (var x in flatObject) {
          if (typeof flatObject[x] !== 'function') {
            if (!flatObject[x]) continue
            toReturn[i + '.' + x] = flatObject[x]
          }
        }
      } else {
        toReturn[i] = ob[i]
      }
    }
    return toReturn
  }

  // Triggers download of CSV file
  const triggerDownload = (string, format = 'csv') => {
    var date = new Date()
    const datetime =
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2) +
      ('0' + date.getSeconds()).slice(-2)
    const exportFileName = `catalog-export-${datetime}.${format}`
    var blob = new Blob([string], { type: `text/${format};charset=utf-8;` })
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportFileName)
    } else {
      var link = document.createElement('a')
      if (link.download !== null) {
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportFileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
    setExporting(false)
  }

  const handleClickAway = () => {
    if (active) {
      setActive(false)
    }
  }

  const handleClickPrintAway = () => {
    if (active) {
      setActive(false)
    }
  }

  const handleSetCheckedDetails = (value) => {
    if (value !== checkedDetails) {
      setCheckedDetails(value)
    }
  }

  const handleSetCheckedFrom = (value) => {
    if (value !== checkedFrom) {
      setCheckedFrom(value)
    }
  }

  const handleSetCheckedFormat = (value) => {
    if (value !== checkedFormat) {
      setCheckedFormat(value)
    }
  }

  // Clears selected naIds when switching between export selections such as current, all, or page
  useEffect(() => {
    setNaIdsToIncludeInExportHandler([])
  }, [checkedFrom])

  return (
    <div
      className={[
        'display-flex',
        'flex-align-center',
        'position-relative',
        props.className,
      ].join(' ')}
    >
      <Button
        active={printActive}
        data-testid="nac-objects--print-search-button"
        iconName="printer"
        iconOnly={screenSize === 'mobile'}
        iconSize="xs"
        onClick={() => setPrintActive(!printActive)}
        reduced
        ref={printButtonRef}
        thin
        theme="primary-dark"
        size="2xs"
        srText="Print options for selected"
      >
        Print
      </Button>
      <Button
        active={active}
        data-testid="nac-objects--export-search-button"
        iconName="out-right"
        iconOnly={screenSize === 'mobile'}
        iconSize="xs"
        onClick={() => setActive(!active)}
        reduced
        ref={buttonRef}
        thin
        theme="primary-dark"
        size="2xs"
        srText="Export options for the current page"
      >
        Export...
      </Button>
      {active && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popover
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="width-card-lg"
            hide={() => setActive(false)}
            id="export-options"
            position={[{ right: 0, top: buttonHeight }]}
            ref={popoverRef}
            tabIndex={0}
            title="Export Options"
          >
            <>
              <PopoverBody id="export-options-body">
                <div
                  className={[
                    'display-flex',
                    'flex-column',
                    'flex-gap-sm',
                  ].join(' ')}
                >
                  <fieldset
                    role="radiogroup"
                    id="exportFrom"
                    className="usa-fieldset flex-column grid-col padding-0 font-sans-2xs"
                  >
                    <label
                      className={`flex-row flex-align-center grid-row padding-bottom-1 text-base-dark text-normal font-sans-2xs`}
                      htmlFor="exportFrom"
                    >
                      Export:
                    </label>
                    <RadioButtonField
                      checked={checkedFrom}
                      data-testid="nac-export-search-brief"
                      disabled={exporting}
                      groupId="exportFrom"
                      handleSetChecked={handleSetCheckedFrom}
                      id="page"
                      label={'Current Page'}
                      value="page"
                    />
                    <RadioButtonField
                      checked={checkedFrom}
                      data-testid="nac-export-search-brief"
                      disabled={exporting}
                      groupId="exportFrom"
                      handleSetChecked={handleSetCheckedFrom}
                      id="all"
                      label={
                        <span>
                          <span>All Results </span>
                          <span style={{ fontWeight: 'normal' }}>
                            (Max 10,000)
                          </span>
                        </span>
                      }
                      value="all"
                    />
                    <RadioButtonField
                      checked={checkedFrom}
                      data-testid="nac-export-search-brief"
                      disabled={exporting || disabled}
                      groupId="exportFrom"
                      handleSetChecked={handleSetCheckedFrom}
                      id="current"
                      label={'Current Selection'}
                      value="current"
                    />
                  </fieldset>
                  <hr />
                  <fieldset
                    role="radiogroup"
                    id="exportDetails"
                    className="usa-fieldset flex-column grid-col padding-0 font-sans-2xs"
                  >
                    <label
                      className={`flex-row flex-align-center grid-row padding-bottom-1 text-base-dark text-normal font-sans-2xs`}
                      htmlFor="exportDetails"
                    >
                      Include:
                    </label>
                    <RadioButtonField
                      checked={checkedDetails}
                      data-testid="nac-export-search-brief"
                      disabled={exporting}
                      groupId="exportDetails"
                      handleSetChecked={handleSetCheckedDetails}
                      id="brief"
                      label={'Brief Details'}
                      value="brief"
                    />
                    <RadioButtonField
                      checked={checkedDetails}
                      data-testid="nac-export-search-full"
                      disabled={exporting}
                      groupId="exportDetails"
                      handleSetChecked={handleSetCheckedDetails}
                      id="full"
                      label={
                        <span>
                          <span>Full Details</span>
                        </span>
                      }
                      value="full"
                    />
                  </fieldset>
                  <hr />
                  <fieldset
                    role="radiogroup"
                    id="exportFormat"
                    className="usa-fieldset flex-column grid-col padding-0 font-sans-2xs"
                  >
                    <label
                      className={`flex-row flex-align-center grid-row padding-bottom-1 text-base-dark text-normal font-sans-2xs`}
                      htmlFor="exportFormat"
                    >
                      Format:
                    </label>
                    <RadioButtonField
                      checked={checkedFormat}
                      data-testid="nac-export-search-brief"
                      disabled={exporting}
                      groupId="exportFormat"
                      handleSetChecked={handleSetCheckedFormat}
                      id="csv"
                      label={'CSV'}
                      value="csv"
                    />
                    <RadioButtonField
                      checked={checkedFormat}
                      data-testid="nac-export-search-brief"
                      disabled={exporting}
                      groupId="exportFormat"
                      handleSetChecked={handleSetCheckedFormat}
                      id="json"
                      label={'JSON'}
                      value="json"
                    />
                  </fieldset>
                </div>
              </PopoverBody>
              <PopoverFooter hide={() => setActive(false)} id="export-options">
                <div
                  className={[
                    'display-flex',
                    'flex-align-center',
                    'flex-justify',
                    'margin-right-auto',
                    'position-relative',
                    'width-full',
                  ].join(' ')}
                >
                  <Button
                    data-testid="nac-export-csv"
                    disabled={exporting}
                    size="2xs"
                    onClick={() => {
                      clickExportHandler()
                    }}
                  >
                    Export
                  </Button>
                  {exporting ? (
                    <div
                      className={[
                        'display-flex',
                        'flex-align-center',
                        'flex-justify',
                        'margin-right-auto',
                        'position-relative',
                      ].join(' ')}
                    >
                      <CircularProgress
                        style={{
                          color: '#005EA2',
                          height: '20px',
                          width: '20px',
                          marginLeft: '4px',
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {error ? (
                    <p
                      aria-atomic="true"
                      className={[
                        'text-error-dark',
                        'flex-row',
                        'grid-row',
                        'font-sans-3xs',
                        'position-relative',
                        'margin-top-1',
                        'minh-2',
                      ].join(' ')}
                      data-testid="nac-export_error"
                      role="alert"
                    >
                      <IconLabel
                        color={'error-dark'}
                        iconName={'triangle-exclaim'}
                        iconSize="xs"
                        size="xs"
                      >
                        Error, try again
                      </IconLabel>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </PopoverFooter>
            </>
          </Popover>
        </ClickAwayListener>
      )}
      {printActive && (
        <ClickAwayListener onClickAway={handleClickPrintAway}>
          <Popover
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="width-card-lg"
            hide={() => setPrintActive(false)}
            id="print-options"
            position={[{ right: 0, top: buttonHeight }]}
            ref={popoverPrintRef}
            tabIndex={0}
            title="Print Options"
          >
            <>
              <PopoverBody id="print-options-body">
                <div
                  className={[
                    'display-flex',
                    'flex-column',
                    'flex-gap-sm',
                  ].join(' ')}
                >
                  <fieldset
                    role="radiogroup"
                    id="printFrom"
                    className="usa-fieldset flex-column grid-col padding-0 font-sans-2xs"
                  >
                    <label
                      className={`flex-row flex-align-center grid-row padding-bottom-1 text-base-dark text-normal font-sans-2xs`}
                      htmlFor="printFrom"
                    >
                      Export:
                    </label>
                    <RadioButtonField
                      checked={checkedFrom}
                      data-testid="nac-print-search-brief"
                      disabled={printing}
                      groupId="printFrom"
                      handleSetChecked={handleSetCheckedFrom}
                      id="page"
                      label={'Current Page'}
                      value="page"
                    />
                    <RadioButtonField
                      checked={checkedFrom}
                      data-testid="nac-export-search-brief"
                      disabled={
                        naIdsToIncludeInExport.length > 0 ? false : true
                      }
                      groupId="printFrom"
                      handleSetChecked={handleSetCheckedFrom}
                      id="current"
                      label={'Current Selection'}
                      value="current"
                    />
                  </fieldset>
                  <hr />
                  <fieldset
                    role="radiogroup"
                    id="exportDetails"
                    className="usa-fieldset flex-column grid-col padding-0 font-sans-2xs"
                  >
                    <label
                      className={`flex-row flex-align-center grid-row padding-bottom-1 text-base-dark text-normal font-sans-2xs`}
                      htmlFor="exportDetails"
                    >
                      Include:
                    </label>
                    <RadioButtonField
                      checked={checkedDetails}
                      data-testid="nac-export-search-brief"
                      disabled={printing}
                      groupId="printDetails"
                      handleSetChecked={handleSetCheckedDetails}
                      id="brief"
                      label={'Brief Details'}
                      value="brief"
                    />
                    <RadioButtonField
                      checked={checkedDetails}
                      data-testid="nac-export-search-full"
                      // disabled={true}
                      groupId="printDetails"
                      handleSetChecked={handleSetCheckedDetails}
                      id="full"
                      label={
                        <span>
                          <span>Full Details</span>
                        </span>
                      }
                      value="full"
                    />
                  </fieldset>
                  <hr />
                  <fieldset
                    role="radiogroup"
                    id="printFormat"
                    className="usa-fieldset flex-column grid-col padding-0 font-sans-2xs"
                  >
                    <label
                      className={`flex-row flex-align-center grid-row padding-bottom-1 text-base-dark text-normal font-sans-2xs`}
                      htmlFor="exportFormat"
                    >
                      Include Thumbnails:
                    </label>
                    <RadioButtonField
                      checked={printThumbnail}
                      data-testid="nac-export-print-brief"
                      // disabled={exporting}
                      groupId="includeThumbnails"
                      handleSetChecked={handleSetShowThumbnails}
                      id="showThumb"
                      label={'Yes'}
                      value="yes"
                    />
                    <RadioButtonField
                      checked={printThumbnail}
                      data-testid="nac-export-print-brief"
                      // disabled={exporting}
                      groupId="includeThumbnails"
                      handleSetChecked={handleSetShowThumbnails}
                      id="noShowThumb"
                      label={'No'}
                      value="no"
                    />
                  </fieldset>
                </div>
              </PopoverBody>
              <PopoverFooter
                hide={() => setPrintActive(false)}
                id="printer-options"
              >
                <div
                  className={[
                    'margin-right-auto',
                    'position-relative',
                    'width-full',
                  ].join(' ')}
                >
                  <Button
                    data-testid="nac-print-all"
                    disabled={
                      naIdsToIncludeInExport.length > 100 ||
                      (props.total > 100 && allChecked) ||
                      openPopUp
                        ? true
                        : false
                    }
                    size="2xs"
                    onClick={() => {
                      ;/full/gi.test(checkedDetails)
                        ? printFullPdf()
                        : printPDF()
                    }}
                  >
                    Print
                  </Button>
                  <div>
                    {naIdsToIncludeInExport.length > 100 ||
                      (props.total > 100 && allChecked && (
                        <div className="export-message">
                          To print more than 100 records, please use the Export
                          function.
                        </div>
                      ))}
                  </div>
                  {printing ? (
                    <div
                      className={[
                        'display-flex',
                        'flex-align-center',
                        'flex-justify',
                        'margin-right-auto',
                        'position-relative',
                      ].join(' ')}
                    >
                      <CircularProgress
                        style={{
                          color: '#005EA2',
                          height: '20px',
                          width: '20px',
                          marginLeft: '4px',
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {error ? (
                    <p
                      aria-atomic="true"
                      className={[
                        'text-error-dark',
                        'flex-row',
                        'grid-row',
                        'font-sans-3xs',
                        'position-relative',
                        'margin-top-1',
                        'minh-2',
                      ].join(' ')}
                      data-testid="nac-print_error"
                      role="alert"
                    >
                      <IconLabel
                        color={'error-dark'}
                        iconName={'triangle-exclaim'}
                        iconSize="xs"
                        size="xs"
                      >
                        Error, try again
                      </IconLabel>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </PopoverFooter>
            </>
          </Popover>
        </ClickAwayListener>
      )}
      {data.length > 0 && /brief/gi.test(checkedDetails) && (
        <PrintSearchResults
          window={window}
          msg={`Here are the selected results for your search for <a target='_blank' href=${`search?${getQueryParamsMsg()}
            }`}>${props.search.q}</a>
  on ${formatDateTime()[0]}. Click the link to see the full details
    in the Catalog at catalog.archives.gov/
  `}
          setThumbs={printThumbnail}
          data-page-total={null}
          data-result-total={null}
          data-testid="nac-results-print"
          limit={null}
          main
          page={null}
          setData={setData}
          results={data}
          total={0}
        />
      )}
      {showFull && /full/gi.test(checkedDetails) && (
        <PopupWindow
          window={window}
          msg={`Here are the selected results for your search for <a target='_blank' href=${`search?${getQueryParamsMsg()}
            }`}>${props.search.q}</a>
  on ${formatDateTime()[0]}. Click the link to see the full details
    in the Catalog at catalog.archives.gov/
  `}
          setThumbs={printThumbnail}
          setData={setShowFull}
        >
          <div>
            {naIdsToIncludeInExport.length > 0 &&
              naIdsToIncludeInExport.map((naId, idx) => {
                return (
                  <PrintDescriptionRecord
                    key={idx}
                    naId={naId}
                    checkedDetails={{
                      checked: checkedDetails,
                      allChecked: allChecked,
                      checkedFrom: checkedFrom,
                      pageDetails: {
                        limit: props.limit,
                        page: props.search.page,
                        q: props.search.q,
                      },
                    }}
                  />
                )
              })}
          </div>
        </PopupWindow>
      )}
    </div>
  )
}

ExportButton.defaultProps = {}

ExportButton.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  search: PropTypes.object,
  total: PropTypes.number,
  limit: PropTypes.number,
  handleClickPrintAway: PropTypes.func,
}
