import { useCallback, useContext, useEffect, useState } from 'react'
import FormContext from '../../contexts/FormContext'
//Helpers
import {
  compareNumbers,
  compareStrings,
} from '../../helper-functions/sort-functions'
import { reduceObject } from '../../reducers/form_reducer'

const useSelect = (
  disabled,
  error,
  multiSelect,
  onChange = () => {},
  options,
  schema,
  search,
  selected
) => {
  if (search && schema.length < 10) search = false
  //Context
  const { formFields } = useContext(FormContext) || {}

  //State
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)

  //Vars
  let defaultOption = schema?.filter((s) => {
    if (s.default) return s
  })

  let selectedIds = !multiSelect
    ? selected
    : selected?.reduce((a, b) => {
        return [a.id, b.id]
      }, [])

  const returnOption = () => {
    if (selected?.length > 1)
      return selected?.filter((s) => !s.isDefault && !s.isPlaceholder) || []
    else return selected || []
  }

  let selectedOption = selected ? returnOption() : defaultOption

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen)
  }

  const setSelectedHandler = (event, value) => {
    if (!multiSelect) {
      setIsOptionsOpen(false)
      setEnteredValue('')
    }
    onChange(event, value)
  }

  const keyDownHandler = (event, value) => {
    switch (event.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        event.preventDefault()
        setSelectedHandler(event, value)
        break
      case 'Delete':
        event.preventDefault()
        setIsOptionsOpen(false)
        onChange(event, schema[0].value)
        break
      case 'Backspace':
        event.preventDefault()
        setIsOptionsOpen(false)
        onChange(event, schema[0].value)
        break
      default:
        break
    }
  }
  const handleListKeyDown = (event) => {
    let currentIndex = 0
    schema.map((option, index) => {
      if (option.id === selectedOption[0].id) currentIndex = index
    })
    switch (event.key) {
      case 'Escape':
        event.preventDefault()
        setIsOptionsOpen(false)
        break
      case 'ArrowUp':
        event.preventDefault()
        onChange(
          event,
          currentIndex - 1 >= 0
            ? schema[currentIndex - 1].value
            : schema[schema.length - 1].value
        )
        break
      case 'ArrowDown':
        event.preventDefault()
        onChange(
          event,
          currentIndex == schema.length - 1
            ? schema[0].value
            : schema[currentIndex + 1].value
        )
        break
      default:
        break
    }
  }

  // If the list is visible, then the input becomes disabled, make sure we hide the list
  useEffect(() => {
    if (isOptionsOpen) setIsOptionsOpen(false)
  }, [disabled])

  useEffect(() => {
    if (isOptionsOpen) setEnteredValue('')
  }, [isOptionsOpen])

  const errorInputClasses =
    error?.type === 'error'
      ? 'invalid border-error-dark bg-error-lighter'
      : error?.type === 'warning'
      ? 'warning border-accent-warm-dark bg-accent-warm-lighter'
      : ''

  const [dropdownOptions, setDropdownOptions] = useState(schema)
  const [enteredValue, setEnteredValue] = useState('')
  const [resultCount, setResultCount] = useState(0)

  const optionSearchHandler = (event, val) => {
    event?.preventDefault()
    const value = event.target.value || val
    setEnteredValue(value)
  }

  const setDropdownOptionsHandler = () => {
    if (!enteredValue) {
      let newOptions = options
        ? [...new Set([...options, selectedOption[0] || defaultOption])]
        : schema

      const filteredOptions = newOptions
        ? newOptions.filter(
            (option) => option.isDefault || option.isPlaceholder
          )
        : []

      const sortedOptions = newOptions
        ? newOptions
            .filter((option) => !option.isDefault && !option.isPlaceholder)
            .sort((a, b) => {
              if (a.isDefault || a.isPlaceholder) return a
              if (a.order) compareNumbers(a.order, b.order)
              else return compareStrings(a.label, b.label)
            })
        : []

      setDropdownOptions([...filteredOptions, ...sortedOptions])
    } else {
      let count = 0
      const enteredArray = enteredValue.trim().split(' ')

      const filteredOptionsArray = schema.reduce((acc, a) => {
        if (a?.options) {
          const ch =
            a.options &&
            a.options.filter(
              (b) =>
                enteredArray.every((v) =>
                  b.label.toLowerCase().includes(v.toLowerCase())
                ) ||
                b.details?.filter((detail) =>
                  enteredArray.every((v) =>
                    detail.label.toLowerCase().includes(v.toLowerCase())
                  )
                )
            )
          if (ch && ch.length) acc.push({ ...a, options: ch })
          count += ch.length
        } else if (
          enteredArray.every((v) =>
            a.label.toLowerCase().includes(v.toLowerCase())
          ) ||
          a.details?.filter((detail) =>
            enteredArray.every((v) =>
              detail.label.toLowerCase().includes(v.toLowerCase())
            )
          ).length > 0
        ) {
          acc.push(a)
          count += 1
        }
        setResultCount(count)
        return acc
      }, [])

      const filteredOptions = filteredOptionsArray
        ? filteredOptionsArray.filter(
            (option) => option.isDefault || option.isPlaceholder
          )
        : []

      const sortedOptions = filteredOptionsArray
        ? filteredOptionsArray
            .filter((option) => !option.isDefault && !option.isPlaceholder)
            .sort((a, b) => {
              if (a.isDefault) return a
              if (a.order) compareNumbers(a.order, b.order)
              else return compareStrings(a.label, b.label)
            })
        : []

      setDropdownOptions([...filteredOptions, ...sortedOptions])
    }
  }

  useEffect(() => {
    setDropdownOptionsHandler()
  }, [enteredValue, schema])

  return {
    dropdownOptions,
    enteredValue,
    keyDownHandler,
    handleListKeyDown,
    isOptionsOpen,
    optionSearchHandler,
    resultCount,
    selectedIds,
    selectedOption,
    setSelectedHandler,
    toggleOptions,
  }
}
export default useSelect
