import { useEffect, useState } from 'react'
import useEventListener from './use-event-listener'

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(null)

  const handleSetSizes = () => {
    if (window.innerWidth > 1200) {
      setScreenSize('desktop')
    } else if (window.innerWidth > 800) {
      setScreenSize('tablet-lg')
    } else if (window.innerWidth > 640) {
      setScreenSize('tablet')
    } else {
      setScreenSize('mobile')
    }
  }
  useEffect(() => {
    handleSetSizes()
  }, [])
  /* When the window is resized, update the screenSize state */
  useEventListener('resize', handleSetSizes)

  return {
    screenSize,
  }
}
export default useScreenSize
