import React from 'react'
import '../../../styles/index.scss'
//Components
import { StatTotals } from './AccountOverview'
import { ActivityFeed } from './activity_feed'
import AccountContent, { ContentTitle } from '../account_content'
import Card from '../../Card/Card'

const AccountComments = () => {
  return (
    <AccountContent>
      <ContentTitle iconName="comment" title="Comments" />
      {/* <StatTotals />*/}
      <ActivityFeed />
    </AccountContent>
  )
}

export default AccountComments
