import React, { Fragment, useContext, useMemo } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import { SectionHeading } from '../SectionHeading/SectionHeading'
import ExpandCollapseList from '../utilities/ExpandCollapseList'
import { returnFormattedPhone } from '../../helper-functions/string-functions'
import { Alert } from '../Alert/Alert'
import Highlighter from '../Highlighter/Highlighter'
import SearchContext from '../../contexts/SearchContext'
import useHighlightList from '../../hooks/use-highlight-list'

const SectionReferenceUnits = ({ ...props }) => {
  const { record } = useContext(RecordContext) || {}
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  const referenceUnits = useMemo(() => {
    if (record.referenceUnits) {
      return record.referenceUnits
    }
    return false
  }, [record])

  if (!referenceUnits) return false

  const { sortedArray, count } = useHighlightList(
    q,
    referenceUnits,
    ['name', 'mailCode', 'address1', 'address2', 'city', 'state', 'postalCode'],
    2
  )

  return (
    <div className="width-full">
      <SectionHeading copyLink count={referenceUnits.length} {...props}>
        {referenceUnits.length > 1 ? 'Contacts' : 'Contact'}
      </SectionHeading>{' '}
      <div className={['display-flex', 'flex-column'].join(' ')}>
        <Alert>
          For questions, comments, or concerns, please contact the following:
        </Alert>

        <div
          className={[
            'display-flex',
            'flex-column',
            'flex-gap-md',
            'width-full',
          ].join(' ')}
        >
          <ExpandCollapseList
            gap="md"
            id="reference-units-list"
            initial={count}
          >
            {sortedArray.map((ref, i) => (
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'flex-gap',
                  'width-full',
                ].join(' ')}
                key={'reference ' + i}
                data-testid={`nac-description__reference-units--${i}`}
              >
                <div className="display-flex flex-column flex-gap-xs">
                  {ref.name ? (
                    <strong>
                      <Highlighter search={q}>{ref.name}</Highlighter>
                      {ref.mailCode ? (
                        <>
                          {' '}
                          (<Highlighter search={q}>{ref.mailCode}</Highlighter>)
                        </>
                      ) : (
                        ''
                      )}
                    </strong>
                  ) : (
                    <strong>{'Reference ' + i}</strong>
                  )}
                  {ref.address1 && (
                    <span>
                      {' '}
                      <Highlighter search={q}>{ref.address1}</Highlighter>
                    </span>
                  )}
                  {ref.address2 && (
                    <span>
                      {' '}
                      <Highlighter search={q}>{ref.address2}</Highlighter>
                    </span>
                  )}
                  {(ref.city || ref.state || ref.postalCode) && (
                    <span>
                      {ref.city ? (
                        <>
                          <Highlighter search={q}>{ref.city}</Highlighter>,{' '}
                        </>
                      ) : (
                        ''
                      )}
                      {ref.state ? (
                        <>
                          <Highlighter search={q}>{ref.state}</Highlighter>,{' '}
                        </>
                      ) : (
                        ''
                      )}
                      {ref.postalCode ? (
                        <Highlighter search={q}>{ref.postalCode}</Highlighter>
                      ) : (
                        ''
                      )}
                    </span>
                  )}
                </div>
                <div className="display-flex flex-column flex-gap-sm">
                  {ref.phone && (
                    <div className="display-flex flex-row flex-gap">
                      <p className="width-6">Phone:</p>
                      <a
                        className="margin-bottom-05"
                        href={`tel:1 ${returnFormattedPhone(ref.phone)}`}
                      >
                        {returnFormattedPhone(ref.phone)}
                      </a>
                    </div>
                  )}
                  {ref.fax && (
                    <div className="display-flex flex-row flex-gap">
                      <p className="width-6">Fax:</p>
                      <a
                        className="margin-bottom-05"
                        href={`tel:1 ${returnFormattedPhone(ref.fax)}`}
                      >
                        {returnFormattedPhone(ref.fax)}
                      </a>
                    </div>
                  )}
                  {ref.email && (
                    <div className="display-flex flex-row flex-gap">
                      <p className="width-6">Email:</p>
                      <a href={'mailto:' + ref.email}>{' ' + ref.email}</a>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </ExpandCollapseList>
        </div>
      </div>
    </div>
  )
}
export default SectionReferenceUnits
