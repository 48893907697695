import React, { useContext } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Settings
import { DEFAULT_MAX_COPY_WIDTH } from '../settings/globals'
//Context
import SearchContext from '../../contexts/SearchContext'
import Highlighter from '../Highlighter/Highlighter'

const Prose = ({ children, className, maxWidth, title, ...props }) => {
  if (!children) return false
  const searchContext = useContext(SearchContext)

  return (
    <div
      className={[
        'line-height-sans-4',
        `maxw-${maxWidth || DEFAULT_MAX_COPY_WIDTH}`,
        'white-space-pre-line',
        'word-break-word',
        className,
      ].join(' ')}
      {...props}
    >
      {title && (
        <h2>
          {title}
          <span className="usa-sr-only">, </span>
        </h2>
      )}
      {children}
    </div>
  )
}
Prose.defaultProps = {
  children: '',
  title: '',
}

Prose.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  className: PropTypes.string,
  maxWidth: PropTypes.oneOf(['card', 'card-lg', 'mobile', 'mobile-lg']),
  title: PropTypes.string,
}

export default Prose
