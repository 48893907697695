import React, { useContext } from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import {
  DEFAULT_PAGE,
  DEFAULT_CONTRIBUTION_TABLE_LIMIT,
} from '../../settings/globals'
import '../../../styles/index.scss'
//Context
import AccountContext from '../../../contexts/AccountContext'
//Components
import { ContentTitle } from '../account_content'
import Card from '../../Card/Card'
import { Table } from '../../Table/Table'
import { Button } from '../../Button/Button'
import Tooltip from '../../Tooltip/Tooltip'
import { Icon } from '../../Icon/Icon'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
//Hooks
import ModerationContent from '../moderation_content'
import Toast from '../../Toast/Toast'
import { StatFilters } from './ModeratorDashboard'
import ModerateContributionsContext from '../../../contexts/ModerateContributionsContext'
import useModerationTotals from './use-moderation-totals'

const ModerateComments = () => {
  //Context
  const accountState = useOutletContext() || {}
  if (!accountState) return false
  const {
    columns,
    data,
    loadingData,
    loadingSearchData,
    table,
    toastList,
    submitHandler,
    changeHandler,
    enteredSearch,
    handleSetPage,
    page,
    total,
  } = useContext(ModerateContributionsContext)

  //Hooks
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    data: totals,
    error: errorTotals,
    loading: loadingTotals,
  } = useModerationTotals('comment')

  return (
    <AccountContext.Provider value={accountState}>
      <ModerationContent>
        <ContentTitle iconName="tool-comment" title="Moderate Comments" />
        <div className="overflow-x-scroll">
          <StatFilters contributionType="comment" />
        </div>
        <div>
          <h2
            className={[
              'display-flex',
              'flex-align-center',
              'flex-gap-sm',
              'flex-row',
              'margin-bottom-0',
              'margin-right-auto',
            ].join(' ')}
            id="search-label"
          >
            Search comments
            <Tooltip
              content={`Search comments with specific text or by NAID`}
              delay={500}
              display="block"
              id={`#moderateCommentsSearchDescription`}
              position="topCenter"
            >
              <Button
                align="center"
                iconName="circle-questions"
                iconSize="xs"
                reduced
                shape="pill"
                srText="Contribution search details"
                textOnly
                theme="base-dark"
              />
            </Tooltip>
          </h2>
          <form onSubmit={submitHandler}>
            <label
              className={[
                'display-flex',
                'flex-align-center',
                'flex-no-wrap',
                'flex-row',
                'position-relative',
                'width-full desktop-lg:width-mobile',
              ].join(' ')}
            >
              <input
                aria-labelledby="search-label"
                className={[
                  'usa-input',
                  'bg-white',
                  'border-base-light',
                  'border-1px',
                  'display-inline-block',
                  'margin-top-0',
                  'maxw-full',
                  'minh-5',
                  'padding-105',
                  'padding-left-4',
                  'radius-md',
                  'width-full',
                ].join(' ')}
                value={enteredSearch}
                id="contributionCommentsSearch"
                name="contributionCommentsSearch"
                onChange={changeHandler}
                type="search"
              />
              <Icon
                className="left-2px position-absolute"
                color="base"
                iconName="search"
                iconPercent="60"
                iconSize="md"
                onClick={submitHandler}
              />
            </label>
          </form>
        </div>
        <hr className={['border-base-lighter'].join(' ')} />
        {total === 0 ? (
          <p>
            No comments returned for{' '}
            <strong>
              <em>{enteredSearch}</em>
            </strong>
          </p>
        ) : loadingSearchData ? (
          'Searching...'
        ) : (
          <>
            {enteredSearch && !loadingSearchData && total > 0 && (
              <div
                className={[
                  'font-sans-xs',
                  'margin-right-auto',
                  'text-right',
                ].join(' ')}
              >
                Showing{' '}
                {total < DEFAULT_CONTRIBUTION_TABLE_LIMIT || // NOTE: There may be additional logic improvements that could be made here
                totals?.['total'] < DEFAULT_CONTRIBUTION_TABLE_LIMIT
                  ? returnNumberWithCommas(total)
                  : `1–${
                      total > DEFAULT_CONTRIBUTION_TABLE_LIMIT
                        ? DEFAULT_CONTRIBUTION_TABLE_LIMIT
                        : total
                    } of
                    ${returnNumberWithCommas(total)}`}{' '}
                results for{' '}
                <strong>
                  <em>{enteredSearch}</em>
                </strong>
              </div>
            )}
          </>
        )}
        {data?.length > 0 ? (
          <div className="overflow-x-scroll margin-x-neg-2 tablet:margin-x-neg-0 padding-bottom-2 tablet-lg:padding-bottom-0">
            <Card
              className={[
                'padding-0',
                'tablet-lg:padding-0',
                'margin-x-2',
                'tablet-lg:margin-x-0',
              ].join(' ')}
              style={{ minWidth: '36rem' }}
            >
              <Table
                columns={columns}
                data={table}
                id="nac-moderate-comments-table"
                pageCount={
                  Math.ceil(total / DEFAULT_CONTRIBUTION_TABLE_LIMIT) ||
                  Math.ceil(
                    totals?.['total'] / DEFAULT_CONTRIBUTION_TABLE_LIMIT
                  )
                }
                pageNum={page}
                initialPage={DEFAULT_PAGE}
                setPage={handleSetPage}
                loading={loadingData || loadingSearchData}
              />
            </Card>
          </div>
        ) : (
          <div>No {searchParams.get('status')} comments</div>
        )}
      </ModerationContent>
      <Toast toastList={toastList} />
    </AccountContext.Provider>
  )
}

export default ModerateComments
