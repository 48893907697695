import React, { useEffect, useState } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'

const RadioButtonField = ({
  checked,
  disabled,
  groupId,
  handleSetChecked,
  id,
  label,
  value,
  ...props
}) => {
  const handleOnClick = () => {
    handleSetChecked(value)
  }

  return (
    <div
      className={[
        'usa-radio',
        //
        'bg-transparent',
        'width-full',
      ].join(' ')}
    >
      <input
        className="usa-radio__input"
        id={id}
        type="radio"
        name={groupId ? groupId : id}
        value={value}
        onChange={handleOnClick}
        checked={checked == value}
        disabled={disabled}
      />
      <label
        className={[
          'usa-radio__label',
          'nac-filter-item',
          //
          'display-inline-flex',
          'flex-align-start',
          'flex-gap',
          'flex-row',
          'font-sans-2xs',
          'margin-0',
          'padding-x-1',
          'padding-y-105',
          'text-base-darker',
          'text-bold',
          'width-full',
        ].join(' ')}
        data-testid={id}
        htmlFor={id}
        {...props}
      >
        {label}
      </label>
    </div>
  )
}

RadioButtonField.defaultProps = {
  checked: '',
  disabled: false,
  groupId: '',
  handleSetChecked: () => {},
  id: '',
  label: '',
  value: '',
}

RadioButtonField.propTypes = {
  checked: PropTypes.string,
  disabled: PropTypes.bool,
  groupId: PropTypes.string,
  handleSetChecked: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.string,
}

export default RadioButtonField
