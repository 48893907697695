import React, { Fragment, useContext } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import BasicLink from '../BasicLink'
import Prose from '../utilities/Prose'
import Tooltip from '../Tooltip/Tooltip'
//Helpers
import { compareStrings } from '../../helper-functions/sort-functions'
import SearchContext from '../../contexts/SearchContext'
import useHighlightList from '../../hooks/use-highlight-list'
import Highlighter from '../Highlighter/Highlighter'

const SectionOnlineResources = ({ ...props }) => {
  const recordContext = useContext(RecordContext)
  const record = recordContext.record
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  const onlineResourcesArray = record.onlineResources.sort((a, b) => {
    return compareStrings(a.description, b.description)
  })

  const { sortedArray, count } = useHighlightList(q, onlineResourcesArray, [
    'note',
    'description',
    // 'url',
  ])

  return (
    <div
      className={['flex-column', 'width-full'].join(' ')}
      data-testid="nac-description__online-resources"
    >
      <>
        <h3 id="onlineResources-label">
          <span>
            {record.onlineResources.length > 1 ? (
              <>
                Online Resources
                <span className="usa-sr-only">
                  . This section contains {record.onlineResources.length} items
                </span>
              </>
            ) : (
              'Online Resource'
            )}
          </span>
          <span className="usa-sr-only">,</span>
        </h3>
        <ol
          // aria-labelledby="onlineResources-label"
          className={[
            'border',
            'border-base-lighter',
            'font-sans-xs',
            'padding-left-2',
            'padding-top-2',
            'radius-md',
            'text-base-darkest',
          ].join(' ')}
        >
          {sortedArray.map(({ description, note, url }) => (
            <li
              key={description + note}
              className={[
                'margin-left-3',
                'margin-right-2',
                'maxw-tablet-lg',
                'padding-bottom-2',
                'padding-left-1',
              ].join(' ')}
            >
              <span
                className={['display-flex', 'flex-column', 'flex-gap-sm'].join(
                  ' '
                )}
              >
                <span
                  className={[
                    'display-inline-flex',
                    'flex-align-center',
                    'text-bold',
                    'width-full',
                  ].join(' ')}
                >
                  <BasicLink className="margin-right-1" href={url}>
                    <Highlighter search={q}>{description}</Highlighter>
                  </BasicLink>

                  {/*description && (
                      <Tooltip
                        content={description}
                        delay={500}
                        display="block"
                        position="bottomCenter"
                      >
                        <Icon
                          iconPercent="80"
                          color="base-dark"
                          iconName="circle-questions"
                          iconSize="sm"
                        />
                      </Tooltip>
                    )*/}
                  <span className="usa-sr-only">,</span>
                </span>
                {note && (
                  <Prose>
                    <Highlighter search={q}>{note}</Highlighter>
                  </Prose>
                )}
              </span>
            </li>
          ))}
        </ol>
      </>
    </div>
  )
}
export default SectionOnlineResources
