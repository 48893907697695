import React, { useEffect, useState, useRef } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import { ListSeparator } from '../ListSeparator/ListSeparator'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'
import { Icon } from '../Icon/Icon'

/**
 * A breadcrumb trail consists of a list of links to the parent pages of the current page in hierarchical order.
 * It helps users find their place within a website or web application.
 * Breadcrumbs are often placed horizontally before a page's main content.
 */
export const Breadcrumbs = ({ crumbs, delimeter, theme, ...props }) => {
  //Sets the state of the breadcrumbs based on user interaction
  const [titleExpand, setTitleExpand] = useState(true)
  //Determines whether each crumb is ellipsed or not
  const [titleEllipsed, setTitleEllipsed] = useState(false)
  //Determines whether each crumb is ellipsed or not
  const [wide, setWide] = useState(null)

  const myRefs = useRef([])
  const hierarchy_container = useRef(null)
  const button = useRef(null)

  const returnWidthObject = () => {
    let buttonWidth = button.current ? button.current.offsetWidth : 80
    let containerWidth = hierarchy_container.current
      ? hierarchy_container.current.offsetWidth - buttonWidth
      : 0
    let width = 0
    myRefs.current.map((ref) => {
      if (!ref) return false
      width += ref.offsetWidth
    })

    return { width: width, containerWidth: containerWidth }
  }

  const handleButtonClick = () => {
    setTitleExpand(!titleExpand)
    setTitleEllipsed(titleExpand)
  }

  const handleSetTitleEllipsed = () => {
    if (myRefs.length == 0) return
    const widthObj = returnWidthObject()
    const width = widthObj.width
    const containerWidth = widthObj.containerWidth
    if (width < containerWidth) setWide(false)
    else setWide(true)

    if (!titleExpand && width < containerWidth) {
      setTitleEllipsed(false)
    } else if (!titleExpand) setTitleEllipsed(true)
    else setTitleEllipsed(false)
  }

  useEffect(() => {
    handleSetTitleEllipsed()
  }, [crumbs])

  useEffect(() => {
    window.addEventListener('resize', handleSetTitleEllipsed, false)
    return () => {
      window.removeEventListener('resize', handleSetTitleEllipsed, false)
    }
  }, [titleExpand])

  return (
    <nav
      aria-expanded={!titleEllipsed}
      aria-label="breadcrumbs"
      className={[
        'breadcrumbs',
        'no-print',
        //
        'display-flex',
        'flex-row',
        'grid-row',
        'flex-no-wrap',
        'font-sans-xs',
        'text-base',
        'line-height-sans-6',
        `theme-${theme}`,
      ].join(' ')}
      data-testid="nac-breadcrumbs"
      ref={hierarchy_container}
    >
      <ListSeparator
        align="text-top"
        delimeter={delimeter}
        display={titleExpand ? 'inline' : 'flex'}
        ellipsed={!titleExpand}
      >
        {crumbs.map((crumb, index) => (
          <div
            className={[
              'display-inline',
              'width-auto',
              !titleExpand ? 'ellipsed' : '',
            ].join(' ')}
            key={crumb.title}
            ref={(el) => (myRefs.current[index] = el)}
          >
            {crumb.type && (
              <Icon
                className="margin-right-1"
                color="primary-lighter"
                iconName={crumb.type}
                iconSize="2xs"
              />
            )}
            {crumb.link ? (
              <Link
                className={`theme-${theme}--link`}
                data-testid={`nac-breadcrumb-link--${crumb.link}`}
                to={{
                  pathname: crumb.link,
                }}
                state={{
                  controlGroup: String(crumb.controlGroup),
                }}
              >
                {crumb.title}
              </Link>
            ) : (
              <span
                aria-current="page"
                className={`theme-${theme}--accent text-normal`}
              >
                {crumb.title}
              </span>
            )}
          </div>
        ))}
      </ListSeparator>
      {wide && (
        <div className="margin-left-2 margin-top-neg-1" ref={button}>
          <Button
            data-testid="nac-breadcrumbs--button-toggle"
            onClick={handleButtonClick}
            outline
            reduced
            size="3xs"
            theme="base"
            thin
          >
            {titleExpand ? 'Collapse' : 'Expand'}
          </Button>
        </div>
      )}
    </nav>
  )
}

Breadcrumbs.defaultProps = {
  crumbs: [],
  delimeter: 'doublerightarrow',
  theme: 'white',
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      controlGroup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  delimeter: PropTypes.string,
  theme: PropTypes.string,
}
