import React from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'

import Prose from '../utilities/Prose'
import { Alert } from '../Alert/Alert'
import { returnIconAndLabelFromString } from '../../helper-functions/metadata-functions'
import { levelOfDescription } from '../settings/RecordPropTypes'
import { returnNumberWithCommas } from '../../helper-functions/number-functions'
import {
  onlineAvailabilityAlertSchema,
  onlineAvailabilityPropTypes,
} from '../../schemas/input-schemas/input-schema-online-availability'

/**
 * This alert notifies a user when a parent-level record contains child-level records which have been fully or partially digitized.
 */
export const AlertOnlineAvailability = ({ counts, level, type, ...props }) => {
  const levelLabel = returnIconAndLabelFromString(level)?.label || 'record'
  let schema = onlineAvailabilityAlertSchema.filter(({ id }) => id === type)[0]
  const description =
    level === 'item' && type === 'notDigitized'
      ? 'This item has not been digitized.'
      : schema?.description?.replace('record ', `${levelLabel} `)
  if (!schema) return false

  return (
    <Alert
      className="width-full"
      data-testid="nac-record_online-availability-alert"
      id="online-availability"
      title={schema?.title || schema?.label}
      type={schema?.type}
    >
      <Prose>{description}</Prose>
      {counts && counts['digitalObjects'] > 0 && (
        <Prose>
          {/*counts['series'] && (
            <>
              <strong>{returnNumberWithCommas(counts['series'])} Series</strong>
              ,{' '}
            </>
          )*/}
          {counts['fileUnit'] && (
            <>
              <strong>
                {returnNumberWithCommas(counts['fileUnit'])} File Units
              </strong>
            </>
          )}
          {counts['fileUnit'] && counts['item'] && ' and '}
          {counts['item'] && (
            <>
              <strong>{returnNumberWithCommas(counts['item'])} Items</strong>{' '}
            </>
          )}
          {counts['digitalObjects'] && (
            <>
              (with a total of{' '}
              <strong>
                {returnNumberWithCommas(counts['digitalObjects'])} digital
                objects
              </strong>
              )
            </>
          )}{' '}
          from this {levelLabel} are currently online and ready to view.
        </Prose>
      )}
    </Alert>
  )
}

AlertOnlineAvailability.defaultProps = {
  level: 'record',
  type: 'notDigitized',
}

AlertOnlineAvailability.propTypes = {
  /**
   * The number of digital objects found associated with the child records
   */
  counts: PropTypes.object,
  /**
   * The level of description of the record on which this alert will be displayed.
   * Adjusts the language sed to accurately reflect the correct level.
   */
  level: PropTypes.oneOf([...levelOfDescription, 'record']),
  /**
   * Determines the online availability alert content.
   */
  type: PropTypes.oneOf(onlineAvailabilityPropTypes),
}
