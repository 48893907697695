import React from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import Card from '../Card/Card'
import { Icon } from '../Icon/Icon'
//Components

const StatCards = ({ children, ...props }) => {
  return (
    <div
      className={[
        'display-flex',
        // 'flex-justify',
        'flex-gap-sm',
        'flex-row',
        'width-full',
      ].join(' ')}
      {...props}
    >
      {children}
    </div>
  )
}

StatCards.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default StatCards

export const StatCard = ({
  color,
  iconName,
  label,
  loading,
  stat,
  ...props
}) => {
  return (
    <div className={['no-underline', 'width-full'].join(' ')}>
      <Card clickable {...props}>
        <Icon
          color={color ? color : 'base-dark'}
          iconName={iconName}
          iconSize="sm"
          strokeThickness="thick"
        />
        {loading ? (
          <div
            className={['block-load', 'height-2', 'width-6'].join(' ')}
          ></div>
        ) : (
          <span
            className={[
              'font-sans-md',
              'hover:text-normal',
              'text-bold',
              'text-base-darker',
            ].join(' ')}
          >
            {stat}
          </span>
        )}

        {/*loading ? (
          <div
            className={['block-load', 'height-1', 'width-full'].join(' ')}
          ></div>
        ) : (*/}
        <span
          className={[
            'font-sans-3xs',
            'hover:text-normal',
            'margin-top-auto',
            'text-base-dark',
          ].join(' ')}
        >
          {label}
        </span>
      </Card>
    </div>
  )
}

StatCard.defaultProps = {}

StatCard.propTypes = {
  color: PropTypes.string,
  iconName: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  stat: PropTypes.string,
}
