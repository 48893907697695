export const availabilitySortDropdownSchema = [
  { default: true, label: 'Date (Newest First)', value: 'date:desc' },
  { label: 'Date (Oldest First)', value: 'date:asc' },
  { label: 'NARA ID (Lowest First)', value: 'naId:desc' },
  { label: 'NARA ID (Highest First)', value: 'naId:asc' },
]

export const availabilityFilterDropdownSchema = [
  { default: true, label: 'All', value: '' },
  { label: 'None', value: 'none' },
  { label: 'Fully Digitized', value: 'fullyDigitized' },
  { label: 'Unrestricted Only', value: 'unrestrictedOnly' },
]

// export const contributionSortDropdownSchema = [
//   { default: true, label: 'Date (Newest First)', value: 'desc' },
//   { label: 'Date (Oldest First)', value: 'asc' },
// ]

export const contributionSortDropdownSchema = [
  { default: true, label: 'Date (Newest First)', value: 'desc' },
  { label: 'Date (Oldest First)', value: 'asc' },
  {
    label: 'Most Contributions',
    value: 'mostContributions',
  },
  {
    label: 'Least Contributions',
    value: 'leastContributions',
  },
]
