function tagSort(a, b) {
  let sortValue = 0
  if (a.contribution.toUpperCase() < b.contribution.toUpperCase()) {
    sortValue = -1
  } else if (a.contribution.toUpperCase() > b.contribution.toUpperCase()) {
    sortValue = 1
  }
  return sortValue
}

export default tagSort
