import { useState, useEffect } from 'react'

const useDetectPrinting = () => {
  const [isPrinting, setIsPrinting] = useState(false)

  const beforePrint = function () {
    setIsPrinting(true)
  }
  const afterPrint = function () {
    setIsPrinting(false)
  }

  // if (window.matchMedia) {
  //   const mediaQueryList = window.matchMedia('print')
  //   mediaQueryList.addListener((mql) => {
  //     if (mql.matches) {
  //       beforePrint()
  //     } else {
  //       afterPrint()
  //     }
  //   })
  // }

  useEffect(() => {
    window.addEventListener('beforeprint', beforePrint)
    return () => window.removeEventListener('beforeprint', beforePrint)
  }, [window])

  useEffect(() => {
    window.addEventListener('aftereprint', afterPrint)
    return () => window.removeEventListener('aftereprint', afterPrint)
  }, [window])

  window.addEventListener('afterprint', afterPrint)

  return { isPrinting }
}

export default useDetectPrinting
