import React, { Fragment } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'

const DropdownField = ({
  children,
  id,
  label,
  className,
  placeholder,
  value,
  icon,
  onChange,
  ...props
}) => {
  const handleChange = (event) => {
    onChange(event)
  }

  return (
    <>
      <label
        className={[
          'nowrap',
          'display-flex',
          'flex-row',
          'flex-align-center',
          icon ? 'padding-left-05' : 'padding-left-1',
          'padding-right-05',
          'text-base-dark',
          'text-normal',
          'font-sans-2xs',
          className,
        ].join(
          ' '
        )} /*border-base border-y-1px border-left-1px border-right-0 padding-right-05 radius-left-md padding-y-1 */
        htmlFor={id}
      >
        {icon && <Icon color="base" iconName={icon} />}
        {label}
      </label>
      <select
        className={[
          'outline-offset-0',
          'position-static',
          'transform-none',
          'usa-select',
          'border-0',
          'bg-transparent',
          'clickable',
          'width-auto',
          'margin-0',
          'line-height-sans-4',
          'height-auto',
          'nac-icon__down',
          'nac-icon-base-darker',
          'font-sans-2xs',
          'text-base-darker',
          'text-bold',
        ].join(
          ' '
        )} /*border-base border-y-1px border-right-1px border-left-0  radius-right-md margin-right-1 padding-y-1 padding-left-05 */
        name="sort-results"
        id={id}
        onChange={(event) => handleChange(event)}
        value={value}
        {...props}
      >
        {children}
      </select>
    </>
  )
}

DropdownField.defaultProps = {
  children: '',
  id: '',
  label: '',
  className: '',
  placeholder: '',
  value: '',
  icon: null,
  onChange: () => {},
}

DropdownField.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  onChange: PropTypes.func,
}

export default DropdownField
