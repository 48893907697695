import React, { Fragment, useContext } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import UserContext from '../../../contexts/UserContext'
//Components
import { Button } from '../../Button/Button'
import { DISABLE_LOGIN } from '../../settings/globals'
import BasicLink from '../../BasicLink'
import { ListSeparator } from '../../ListSeparator/ListSeparator'

const LogInPrompt = ({ children, label, ...props }) => {
  const { loggedInUser, login } = useContext(UserContext) || {}
  return (
    <div
      className={[
        'display-flex',
        'flex-align-center',
        'flex-gap-sm',
        'flex-row',
        'width-full',
        'flex-grow-0',
      ].join(' ')}
    >
      {!DISABLE_LOGIN && !loggedInUser ? (
        <>
          <div className="display-flex flex-shrink-0 margin-right-1">
            <Button
              data-testid="button-login--contributions"
              onClick={login}
              size="3xs"
              outline
              aria-label="Log in to tag, transcribe, and comment."
            >
              {label}
            </Button>
          </div>
        </>
      ) : (
        ''
      )}
      {children && <div className="flex-column grid-col">{children}</div>}
      <div
        className={[
          'display-flex',
          'font-sans-2xs',
          'flex-row',
          'text-normal',
        ].join(' ')}
      >
        <ListSeparator color="base" size="xl">
          <BasicLink
            className={['flex-align-center'].join(' ')}
            href="https://www.archives.gov/citizen-archivist/resources"
          >
            Need Help?
          </BasicLink>
          <BasicLink href="https://www.archives.gov/citizen-archivist/resources/tagging-policy">
            Policy
          </BasicLink>
        </ListSeparator>
      </div>
    </div>
  )
}

LogInPrompt.defaultProps = {
  children: '',
  label: 'Log in to contribute!',
}

LogInPrompt.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  label: PropTypes.string,
}

export default LogInPrompt
