import React, { Fragment } from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
//Components
import Faq from './faq'
import Prose from '../../../utilities/Prose'
import { IconLabel } from '../../../IconLabel/IconLabel'
import { ListSeparator } from '../../../ListSeparator/ListSeparator'

const FaqPage = ({ data, ...props }) => {
  if (!data) return false
  return (
    <Fragment>
      {/** Page Title */}
      <div className={['display-flex', 'flex-column', 'flex-gap'].join(' ')}>
        <h2 className="margin-bottom-0">
          <IconLabel
            color="base-darker"
            iconName={data.icon}
            iconSize="lg"
            size="xl"
            strokeThickness="thicker"
          >
            <strong>{data.title}</strong>
          </IconLabel>
        </h2>
        {/** Page Details */}
        <div className={['font-sans-xs', 'text-bold'].join(' ')}>
          <ListSeparator color="base" size="xl">
            <span>{data.sections.length} sections</span>
            <span>{data.totalQuestions} total faqs</span>
            <span>
              {data.timeToRead} ({data.totalWordCount} words)
            </span>
          </ListSeparator>
        </div>

        {/** Page Description */}
        {data.description && <Prose>{data.description}</Prose>}
      </div>

      {/** Subsections */}
      {data.sections.map((section) => (
        <div
          className={['display-flex', 'flex-column', 'flex-gap'].join(' ')}
          key={section.id}
        >
          <div
            className={['display-flex', 'flex-column', 'flex-gap'].join(' ')}
          >
            {/** Section Title */}
            <h3
              className={[
                'font-sans-lg',
                'margin-bottom-0',
                'text-base-darker',
                'text-bold',
              ].join(' ')}
            >
              {section.title}
            </h3>

            {/** Section Details */}
            <div
              className={['add-list-reset', 'font-sans-xs', 'text-bold'].join(
                ' '
              )}
            >
              <ListSeparator color="base">
                <span>{section.questions.length} faqs in this section</span>
              </ListSeparator>
            </div>

            {/** Section Description */}
            {section.description && <Prose>{section.description}</Prose>}
          </div>

          <div
            className={['bg-white', 'padding-2', 'radius-md', 'shadow-sm'].join(
              ' '
            )}
          >
            {/** Section Questions (and Answers) */}
            {section.questions.map((s, index) => (
              <Faq data={s} index={index} key={s.id} onClick={() => {}} />
            ))}
          </div>
        </div>
      ))}
    </Fragment>
  )
}

FaqPage.defaultProps = {}

FaqPage.propTypes = {
  data: PropTypes.object,
}

export default FaqPage
