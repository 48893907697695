import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/index.scss'
import { ColorPropTypes } from '../../components/settings/ColorPropTypes'
import { IconPropTypes } from '../../components/settings/IconPropTypes'
import { defaultSizes } from '../settings/SizePropTypes'

/**
 * Basic Icon component for use in a variety of scenarios
 */
export const Icon = ({
  className,
  color,
  iconAnimation,
  iconName,
  iconPercent,
  iconSize,
  strokeThickness,
  theme,
  ...props
}) => {
  if (!color && !theme) color = 'primary'
  if (!strokeThickness) strokeThickness = 'thin'
  if (!iconName) return false
  return (
    <span
      className={[
        'nac-icon',
        //
        className,
        iconAnimation ? `icon-${iconAnimation}` : '',
        'display-inline-flex',
        `nac-icon--${iconSize}`,
        `${theme ? `nac-icon-theme--${theme}` : ''}`,
        `${color ? `nac-icon--${color}` : ''}`,
        `nac-icon--${iconName}`,
        `nac-icon--${strokeThickness}`,
        `nac-icon--bg-${iconPercent}`,
      ].join(' ')}
      {...props}
    ></span>
  )
}

Icon.defaultProps = {
  iconSize: 'sm',
  strokeThickness: 'thin',
  iconPercent: '100',
}

Icon.propTypes = {
  /**
   * Classes passed from parent component
   */
  className: PropTypes.string,
  /**
   * Defines the color of the SVG stroke. This is based on a subset of the USWDS color utilities (https://designsystem.digital.gov/utilities/color/)
   */
  color: PropTypes.oneOf(ColorPropTypes),
  /**
   * Defines the CSS animation to apply to the icon
   */
  iconAnimation: PropTypes.oneOf(['rotate-cw']),
  /**
   * Defines the icon to use. Names of icons should describe the icon and not the function, for maximum flexibility
   */
  iconName: IconPropTypes,
  /**
   * Defines the background size in percent.
   * Allows for extra control over the size of the icon in relation to the label.
   */
  iconPercent: PropTypes.oneOf([
    '10',
    '20',
    '30',
    '40',
    '50',
    '60',
    '70',
    '80',
    '90',
    '100',
  ]),
  /**
   * Defines the size of the icon. This is based on a subset of the USWDS size indicators.
   */
  iconSize: PropTypes.oneOf(defaultSizes),
  /**
   * Defines the relative thickness of the SVG stroke.
   */
  strokeThickness: PropTypes.oneOf([
    'thinnest',
    'thinner',
    'thin',
    'thick',
    'thicker',
    'thickest',
  ]),
  /**
   * Defines the theme (specifically the background image) on top of which this icon will be displayed. This modifiees the color to use an accessible alternative.
   */
  theme: PropTypes.oneOf(ColorPropTypes),
}
