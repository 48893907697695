import React, { useContext, useEffect, useState } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import DropdownField from '../../form/DropdownField'
import UserPageContext from '../../../contexts/UserPageContext'
import BarChart from '../BarChart'
import Card from '../../Card/Card'
import { IconLabel } from '../../IconLabel/IconLabel'
import { convertCamelToSentence } from '../../../helper-functions/string-functions'
import { IconLabelConditionalDisplay } from '../../IconLabel/variants/IconLabelConditionalDisplay'

const BarChartCongressFilter = ({ data, ...props }) => {
  const { userPage } = useContext(UserPageContext) || {}

  //State
  const [congresses, setCongresses] = useState(() => {
    let newCongresses = []

    data.map((d, i) => {
      newCongresses?.push({
        x: i + 1,
        points: [{ y: d.doc_count }],
        label: d.key,
      })
    })
    return [
      {
        label: 'Congresses',
        groups: newCongresses,
      },
    ]
  })
  const pointLabels = [{ label: 'Record', icon: 'institution', id: 'congress' }]

  return (
    <>
      <div className={['display-flex', 'flex-column', 'width-full'].join(' ')}>
        <BarChart data={congresses} labels={pointLabels} reduced />
      </div>
    </>
  )
}

BarChartCongressFilter.defaultProps = {}

BarChartCongressFilter.propTypes = {
  data: PropTypes.array,
}

export default BarChartCongressFilter
