import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'

const useReactGA = () => {
  ReactGA.initialize([
    {
      trackingId: 'UA-17592594-36',
      gaOptions: {
        name: 'tracker1',
      },
    },
  ])

  ReactGA4.initialize('G-0VS4QBSPD8')

  const sendHitType = (hitType) => {
    ReactGA4.send({ ...hitType })
  }

  const sendPageView = (location, trackers) => {
    ReactGA4.sendPageView(location, trackers)
  }

  const sendEvent = (gaEvt) => {
    ReactGA4.event({ ...gaEvt })
  }

  return {
    sendEvent,
    sendHitType,
    sendPageView,
  }
}
export default useReactGA
