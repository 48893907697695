import React, { Fragment, useMemo } from 'react'
import '../../../styles/index.scss'
//Components
import DropdownField from '../../form/DropdownField'
import { Pill } from '../../Pill/Pill'
//Hooks
import useUniqueTags from './use-unique-tags'
//Helpers
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
import {
  arrayOfSizes,
  returnRandomArray,
} from '../../DigitalObjects/object-contributions-loader'
import Card from '../../Card/Card'

const UniqueTags = () => {
  const { uniqueTags, error, loading, sortValue, handleOnSortChange } =
    useUniqueTags()
  const widths = useMemo(() => {
    return returnRandomArray(arrayOfSizes, 10)
  }, [])

  return (
    <Fragment>
      <div
        className={[
          'display-flex',
          'flex-column',
          'flex-gap-sm',
          'overflow-hidden',
        ].join(' ')}
      >
        <div
          className={[
            'display-flex',
            'flex-align-center',
            'flex-gap-0 tablet-lg:flex-gap',
            'flex-column tablet-lg:flex-row',
          ].join(' ')}
        >
          <h2 className="margin-bottom-0 margin-right-auto">Unique tags</h2>
          <div
            className={[
              'display-flex',
              'flex-justify-end',
              'flex-row',
              'margin-left-auto',
              'margin-right-2px',
              'margin-top-2px',
              'text-right',
            ].join(' ')}
          >
            <DropdownField
              onChange={handleOnSortChange}
              value={sortValue}
              label="Sort by:"
              id="sort-results"
              data-testid="nac-results--sort"
            >
              <option
                data-testid="nac-results--sort-count-desc"
                value="count:desc"
              >
                Most Used
              </option>
              <option
                data-testid="nac-results--sort-count-asc"
                value="count:asc"
              >
                Least Used
              </option>
              <option
                data-testid="nac-results--sort-alpha:asc"
                value="alpha:asc"
              >
                Tag (Alphabetically, A–Z)
              </option>
              <option
                data-testid="nac-results--sort-alpha-desc"
                value="alpha:desc"
              >
                Tag (Alphabetically, Z–A)
              </option>
            </DropdownField>
          </div>
        </div>
        <Card>
          {loading ? (
            <div
              className={[
                'border-base-lighter',
                'border-1px',
                'display-flex',
                'flex-wrap',
                'maxh-card-lg',
                'overflow-hidden',
                'padding-1',
                'width-full',
              ].join(' ')}
            >
              {[...Array(20)].map((e, i) => (
                <div
                  className={[
                    'float-left',
                    'margin-bottom-05',
                    'margin-right-05',
                  ].join(' ')}
                  key={i}
                >
                  <div
                    className={[
                      'block-load',
                      'height-4',
                      'padding-x-4',
                      'radius-pill',
                      `width-${widths[i]}`,
                    ].join(' ')}
                  ></div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className={[
                'border-base-lighter',
                'border-1px',
                'contents-scroll',
                'display-block',
                'maxh-card-lg',
                'overflow-y-scroll',
                'padding-1',
                'radius-md',
                'width-full',
              ].join(' ')}
            >
              {uniqueTags?.uniqueTags?.map((contribution, index) =>
                contribution && contribution.contribution ? (
                  <Pill
                    aria-live="assertive"
                    className={[
                      'margin-right-1',
                      'margin-bottom-1',
                      'minh-4',
                      contribution.count === 1
                        ? 'padding-x-1'
                        : 'padding-left-1',
                    ].join(' ')}
                    key={contribution.contribution + index}
                    iconName="tag"
                    reduced
                    size="xs"
                    tabIndex="0"
                    theme="base-light"
                    thin
                  >
                    <span className="display-inline-flex text-base-darker">
                      <span className="usa-sr-only">The tag: </span>
                      {contribution.contribution}
                    </span>
                    {contribution.count > 1 && (
                      <Pill
                        className={['margin-left-1', 'padding-x-1'].join(' ')}
                        filled
                        reduced
                        size="3xs"
                        theme="primary-lighter"
                        thin
                      >
                        <span
                          className={['text-normal', 'text-primary'].join(' ')}
                        >
                          {returnNumberWithCommas(contribution.count)}
                        </span>
                      </Pill>
                    )}
                  </Pill>
                ) : (
                  ''
                )
              )}
            </div>
          )}
        </Card>
      </div>
      {/*<hr className={['border-base-lighter'].join(' ')} />*/}
    </Fragment>
  )
}

UniqueTags.defaultProps = {}

UniqueTags.propTypes = {}

export default UniqueTags
