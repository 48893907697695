import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'

const isFunction = (obj) => {
  return typeof obj == 'function'
}

const isObject = (obj) => {
  var type = typeof obj
  return type === 'function' || (type === 'object' && !!obj)
}

const hasWindowObject = () => {
  return typeof window !== 'undefined' && window.document
}

const disableReactDevTools = () => {
  if (hasWindowObject()) {
    if (!isObject(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      return
    }

    for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
      if (prop === 'renderers') {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map()
        continue
      }
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = isFunction(
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop]
      )
        ? Function.prototype
        : null
    }
  }
}

// Disable react tools when not on local or development
if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'dev') {
  disableReactDevTools()
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    ,
  </React.StrictMode>,
  document.getElementById('root')
)
