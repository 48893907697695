import React, { useContext } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'
import { returnFileFormat, returnObjectTypeFromObject } from './object_preview'
import { Pill } from '../Pill/Pill'
import { DownloadButton } from '../Button/variants/DownloadButton'
import useDownloadButton from '../Button/variants/use-download-button'
import { Button } from '../Button/Button'
import Highlighter from '../Highlighter/Highlighter'
import SearchContext from '../../contexts/SearchContext'

export const ObjectTitle = ({
  className,
  index,
  label,
  object,
  type,
  ...props
}) => {
  if (!object) return false
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  type = returnFileFormat(object.objectFilename) == 'pdf' ? 'PDF' : type
  const { handleObjectDownload } = useDownloadButton(
    object.objectUrl,
    object.objectFileName
  )
  return (
    <div
      className={[className, type === 'PDF' ? '' : 'ellipsed'].join(' ')}
      data-testid="nac-object-view--designator-and-description"
      title={`Object ${index} (${type}) ${
        object.objectDesignator || object.objectDescription ? ' – ' : ''
      }${
        object.objectDesignator ? `Designator: ${object.objectDesignator}` : ''
      }${object.objectDesignator && object.objectDescription ? '; ' : ''}${
        object.objectDescription
          ? `Description: ${object.objectDescription}`
          : ''
      }`}
    >
      {label && (
        <span aria-hidden className="text-bold">
          {type === 'PDF' ? (
            <div
              className={[
                'display-flex',
                'flex-align-center',
                'flex-gap-sm',
                'flex-row',
                'height-105',
              ].join(' ')}
            >
              <Pill
                className={[
                  'margin-left-1',
                  'radius-md',
                  'text-uppercase',
                ].join(' ')}
                filled
                radius="md"
                reduced
                size="4xs"
                theme="base-dark"
                thin
              >
                pdf
              </Pill>
              <Button
                data-testid="nac-object-viewer--download-button"
                href={object.objectUrl}
                iconName="download"
                iconSize="xs"
                id="contents-tab"
                onClick={handleObjectDownload}
                reduced
                target="_blank"
                textOnly
                size="2xs"
                theme="base-lighter"
                thin
              >
                Download the PDF
              </Button>
            </div>
          ) : (
            <>
              {index} ({type})
            </>
          )}
        </span>
      )}
      {((object.objectDescription &&
        object.objectDescription.indexOf('Download') < 0) ||
        object.objectDesignator) && (
        <span aria-hidden className={['text-base-light'].join(' ')}>
          {label && (object.objectDesignator || object.objectDescription) && (
            <span className="text-base margin-x-05">–</span>
          )}
          <span
            className={[
              'text-base-light',
              object.objectDesignator && object.objectDescription
                ? [
                    'display-inline',
                    'separator',
                    'separator--pipe',
                    'separator-size--lg',
                    'separator-align--top',
                    'separator-distance--05',
                    'separator-base',
                  ].join(' ')
                : '',
            ].join(' ')}
          >
            <Highlighter search={q}>{object.objectDesignator}</Highlighter>
          </span>
          <Highlighter search={q}>{object.objectDescription}</Highlighter>
        </span>
      )}
    </div>
  )
}

ObjectTitle.defaultProps = {
  className: '',
  index: 0,
  label: false,
  object: {},
  type: 'Image',
}

ObjectTitle.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  label: PropTypes.bool,
  object: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}
