import React, { Fragment } from 'react'
import BasicLink from '../../../../BasicLink'

export const loginStart = [
  {
    id: 'account-not-required',
    question: 'Do I have to make an account?',
    answer: (
      <p>
        No. A National Archives Catalog account is not required to use or search
        the Catalog. However, there are benefits to creating an account and
        logging in to search, such as contributing as a Citizen Archivist by
        adding tags, transcriptions, and comments.
      </p>
    ),
  },
  {
    id: 'required-information',
    question: 'What information do I need to provide?',
    answer: (
      <p>
        When you voluntarily register for a user account in the National
        Archives Catalog, we ask you to supply some personal information about
        yourself. An email address and username are required; other information
        is optional but may be needed to complete specific tasks. Identification
        of users allows us to support content contributed by the public and to
        provide a more personalized experience of our websites. When you provide
        your email address to us, we only use it for the purposes of
        communicating with you.
      </p>
    ),
  },
  {
    id: 'newsletter-subscription',
    question: 'How does the National Archives Catalog use my information?',
    answer: (
      <p>
        By creating a user account in the National Archives Catalog, you are
        granting the National Archives permission to email you, and your email
        address is automatically subscribed to the National Archives Catalog
        email newsletter. If there are any issues related to your user account,
        we may use your email address and username to contact you about the
        issues involved.
      </p>
    ),
  },
  {
    id: 'newsletter-unsibscribe',
    question: 'Can I unsubscribe from the newsletter?',
    answer: (
      <p>
        Yes. You can revoke your permission at any time using the Unsubscribe
        link found at the bottom of every email newsletter, or by emailing us at
        catalog@nara.gov. Please review our Privacy Policy on archives.gov for
        additional information. Email newsletters are serviced by a third party.
      </p>
    ),
  },
]
export const loginAbout = [
  {
    id: 'what-is-login',
    question: 'What is login.gov?',
    answer:
      'Login.gov is a service that offers secure and private online access to government programs, such as federal benefits, services and applications. With a login.gov account, you can sign into multiple government websites with the same email address and password.',
  },
  {
    id: 'why-use-login',
    question: 'Why is the National Archives using login.gov?',
    answer: (
      <div key="why">
        Login.gov uses two-factor authentication, and stronger passwords, that
        meet new{' '}
        <BasicLink className="display-inline" href="https://www.nist.gov/">
          National Institute of Standards of Technology
        </BasicLink>{' '}
        requirements for secure validation and verification. By using login.gov,
        you’ll get an extra layer of security to help protect your National
        Archives Catalog account against password compromises.
      </div>
    ),
  },
]

export const loginAction = [
  {
    id: 'existing-catalog-account',
    question:
      'I already have a Catalog account. What will happen to my existing account and contributions?',
    answer: (
      <Fragment key="existing">
        <p>
          Your existing account still does and will exist. We will migrate your
          existing National Archives Catalog account for you, and all of your
          existing contributions (tags, transcriptions, and comments) will
          remain associated or linked with it.
        </p>
        <p>
          Once you set up a login.gov account, we’ll link it back to your
          Catalog account. The only difference is you’ll use your login.gov
          email address, password and a one-time security code to sign into the
          Catalog.
        </p>
      </Fragment>
    ),
  },
  {
    id: 'your-next-steps',
    question: 'What do I need to do?',
    answer: [
      <Fragment key="next-steps">
        <p className="margin-bottom-4">
          You need to create a Login.gov account, if you don’t already have one.
          Your old Catalog username and password won’t work anymore. You’ll need
          to:
        </p>
        <ol
          className={[
            'display-flex',
            'flex-column',
            'flex-gap',
            'margin-bottom-4',
            'margin-left-4',
          ].join(' ')}
        >
          <li>
            <strong>
              <BasicLink href="https://www.usajobs.gov/Applicant/ProfileDashboard/Home/">
                Create a Login.gov account
              </BasicLink>
            </strong>
            <br />
            You only need to do this once.
          </li>
          <li>
            <strong>Enter an email address.</strong>
            <br />
            Use the same email address you use for Catalog, if you have an
            existing profile with us.
          </li>
          <li>
            <strong>Create a new password.</strong>
          </li>
          <li>
            <strong>Select your first method of authentication.</strong>
            <br />
            Having another way to sign in keeps your account more secure than
            using only a password. You can choose between text messages, phone
            calls, an authentication application, a security key, or backup
            codes. Government employees can also use their PIV card or CAC.
          </li>
          <li>
            <strong>Select a backup method of authentication.</strong>
            <br />
            You need to select a backup method, in case you don’t have access to
            your first choice (for example you don’t have access to your phone).
          </li>
        </ol>
        <p>
          Once you’ve finished setting up your login.gov account, you’ll go back
          to the National Archives Catalog to finish the process. Double check
          your Catalog account profile to make sure all of your information is
          accurate.
        </p>
        <p>
          You need to use your login.gov email address, password and one of the
          two-factor authentication methods you set up, every time you want to
          sign into the Catalog.
        </p>
      </Fragment>,
    ],
  },
  {
    id: 'existing-login-dot-gov-account',
    question: 'What if I already have a login.gov account?',
    answer:
      'If you already have a login.gov account, you don’t need to create a second one. Use your existing login.gov email address, password, plus one of the two-factor authentication methods you set up, to sign into the Catalog.',
  },
  {
    id: 'what-email',
    question: 'What email address do I use to create a login.gov account?',
    answer:
      'Use the email address attached to your existing Catalog account, so we can automatically link your Catalog account to your login.gov account. If you use a different email address, we won’t be able to automatically link your profile.',
  },
  {
    id: 'different-email',
    question:
      'Can I use a different email address from my Catalog account email address, when setting up my login.gov account?',
    answer:
      'When you first create your login.gov account, you need to use the same email address you currently use for the National Archives Catalog. This will let us link your Catalog user account information to your new account. If you want to change your email address, we recommend you do it AFTER you’ve successfully linked your profile and login.gov account.',
  },
  {
    id: 'login-sharing',
    question: 'Can I share a login.gov account with another person?',
    answer:
      'No. Each person needs their own login.gov account. If you’ve shared your Catalog account in the past, with a spouse or child, you can no longer do this with login.gov. Each person must set up their own login.gov account, with their own personal email address (not an email that you share with someone else).',
  },
]

export const loginFaq = [
  {
    // description:
    //   'Discover why Login.gov is an important solution for your account security.',
    id: 'before-you-start',
    questions: loginStart,
    title: 'What should I know before I create an account?',
  },
  {
    // description:
    //   'Discover why Login.gov is an important solution for your account security.',
    id: 'about-login-dot-gov',
    questions: loginAbout,
    title: 'What is Login.gov and why do I have to create an account?',
  },
  {
    // description:
    //   'Learn how to access the new Catalog website by setting up a login.gov account.',
    id: 'get-started',
    questions: loginAction,
    title: 'What do I need to do to get started?',
  },
  // {
  //   id: 'login-dot-gov-impact',
  //   questions: loginImpact,
  //   title: 'How will the new Login.gov impact my existing account?',
  // },
]
