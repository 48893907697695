import React, { Fragment } from 'react'
import BasicLink from '../components/BasicLink'
const config = require('../config.js')

export const convertCamelToSentence = (camelCase) => {
  if (!camelCase) return false
  const result = camelCase?.replace(/([A-Z])/g, ' $1')
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult.replace(/ /g, '')
}

export const convertSentenceToCamel = (sentenceCase) => {
  return sentenceCase
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase()
    })
    ?.replace(/\s+/g, '')
}

export const stripHtml = (htmlContent) => {
  return htmlContent.replace(/(<([^>]+)>)/gi, ' ')
}

export const returnFirstLastNameString = (name) => {
  if (name[name.length - 1] === ',' || name[name.length - 1] === '.')
    name = name.slice(0, -1)
  let firstName = name.split(',').slice(-1).join(' ')

  const lastName = name.split(',').slice(0, -1).join(' ')
  return firstName + ' ' + lastName
}

export const returnNumberWithOrdinalSuffix = (num) => {
  var j = num % 10,
    k = num % 100
  if (j == 1 && k != 11) {
    return num + 'st'
  }
  if (j == 2 && k != 12) {
    return num + 'nd'
  }
  if (j == 3 && k != 13) {
    return num + 'rd'
  }
  return num + 'th'
}

export const returnFirstLetterCapitalized = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const replaceURLs = (string) => {
  if (!string) return

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
  let urls = []
  let newString = string.replace(urlRegex, '')
  string.replace(urlRegex, function (url) {
    let href = url
    if (!href.match('^https?://')) {
      href = 'http://' + href
    }
    urls.push(
      <Fragment key={newString}>
        {newString}
        <BasicLink className="text-bold" href={href} key={newString}>
          Go to this source.
        </BasicLink>
      </Fragment>
    )
  })
  return <>{urls.length > 0 ? urls : newString}</>
}

export const convertImageUrlToIiif = (imageUrl) => {
  const objectUrlRegex =
    /(h.{3,4}:\/\/s3..*amazonaws.com)((?:\/[\w\-_]*)?\??(?:[\\.\w_]*)#?(?:[\\\w]*))/g
  const percentEncodeRegex = /\//g
  const objectUrl = imageUrl?.replace(
    objectUrlRegex,
    `${config.IIIF_HOST}/iiif/3`
  )
  const splitUrl = objectUrl?.split('iiif/3/')
  const percentEncodeUrl = splitUrl?.[1]?.replace(percentEncodeRegex, '%2F')
  const iiifUrl = `${splitUrl?.[0]}iiif/3/${percentEncodeUrl}`
  return iiifUrl
}

export const returnFormattedPhone = (fax) => {
  if (!fax) return false
  const regexObj = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  if (regexObj.test(fax)) {
    return fax.replace(regexObj, '($1) $2-$3')
  } else {
    return false
  }
}
