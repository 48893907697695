import React, { useContext } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import { SectionHeading } from '../SectionHeading/SectionHeading'

const SectionJson = ({ ...props }) => {
  const recordContext = useContext(RecordContext)
  return (
    <div className="width-full">
      <SectionHeading copyLink {...props}>
        JSON
      </SectionHeading>
      <pre
        tabIndex="0"
        className={[
          'border',
          'border-base-lighter',
          'bg-base-lighter-opacity-20',
          'maxh-viewport',
          'overflow-hidden',
          'overflow-scroll',
          'padding-1',
          'radius-md',
          'text-base-dark',
        ].join(' ')}
      >
        {JSON.stringify(recordContext.record, null, 2)}
      </pre>
    </div>
  )
}

export default SectionJson
