import React from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import {
  DEFAULT_MAX_CONTENT_WIDTH,
  DEFAULT_MAX_COPY_WIDTH,
} from '../settings/globals'

export const DescriptionLoader = ({ ...props }) => {
  return (
    <div
      className={['display-flex', 'flex-column', 'grid-col', 'width-full'].join(
        ' '
      )}
    >
      <div
        className={[
          'bg-base-light-opacity-30',
          'display-flex',
          'flex-column',
          'grid-col',
          'padding-x-3',
          'padding-y-5',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            'display-flex',
            'flex-column',
            'flex-gap-md',
            'grid-col',
            'margin-x-auto',
            `maxw-${DEFAULT_MAX_CONTENT_WIDTH}`,
            'width-full',
          ].join(' ')}
        >
          <div className={['flex-row', 'flex-gap-lg', 'grid-row'].join(' ')}>
            <div
              className={['block-load', 'height-1', 'width-card-lg'].join(' ')}
            ></div>
            <div
              className={['block-load', 'height-1', 'width-card'].join(' ')}
            ></div>
          </div>
          <div
            className={['block-load', 'height-2', 'width-10'].join(' ')}
          ></div>
          <div
            className={[
              'block-load',
              'height-4',
              'maxw-full',
              'width-tablet',
            ].join(' ')}
          ></div>

          <div className={['flex-row', 'flex-gap-lg', 'grid-row'].join(' ')}>
            <div
              className={['block-load', 'height-2', 'width-card'].join(' ')}
            ></div>
            <div
              className={['block-load', 'height-2', 'width-card-lg'].join(' ')}
            ></div>
          </div>
        </div>
      </div>

      <div
        className={[
          'display-flex',
          'flex-column',
          'grid-col',
          'padding-x-3',
          'padding-y-5',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            'display-flex',
            'flex-column',
            'flex-gap-md',
            'grid-col',
            'margin-x-auto',
            `maxw-${DEFAULT_MAX_CONTENT_WIDTH}`,
            'width-full',
          ].join(' ')}
        >
          <div
            className={[
              'display-flex',
              'flex-column',
              'flex-gap-sm',
              'grid-col',
              `maxw-${DEFAULT_MAX_COPY_WIDTH}`,
            ].join(' ')}
          >
            {[...Array(10)].map((e, i) => (
              <div
                className={['block-load', 'height-1', 'width-full'].join(' ')}
                key={i}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

DescriptionLoader.defaultProps = {}

DescriptionLoader.propTypes = {}
