import React from 'react'
import { Outlet } from 'react-router-dom'
import '../../styles/index.scss'
//Context
import AccountContext from '../../contexts/AccountContext'
//Components
import AccountSidebar from './account_sidebar'
import ErrorPage from '../Pages/ErrorPage/ErrorPage'
import Section from '../description/section'
//Hooks
import useAccount from './use-account'
import useScreenSize from '../../hooks/use-screen-size'

const Account = ({ ...props }) => {
  //Hooks
  const { screenSize } = useScreenSize()
  const account = useAccount()

  return account.error ? (
    <ErrorPage error={account.error} />
  ) : account ? (
    <main className={['bg-base-lighter-opacity-50', 'width-full'].join(' ')}>
      <Section
        className={[
          'margin-x-auto',
          'maxw-full',
          screenSize !== 'desktop' && screenSize !== 'tablet-lg'
            ? 'padding-0'
            : '',
        ].join(' ')}
      >
        <div
          className={[
            screenSize === 'desktop' || screenSize === 'tablet-lg'
              ? 'display-flex'
              : '',
            'flex-align-stretch',
            'flex-row',
            screenSize === 'mobile'
              ? 'flex-gap'
              : screenSize === 'tablet'
              ? 'flex-gap-md'
              : 'flex-gap-lg',
          ].join(' ')}
        >
          <AccountContext.Provider value={account}>
            <AccountSidebar />
            {screenSize !== 'desktop' && screenSize !== 'tablet-lg' ? (
              <div className="padding-2">
                <Outlet />
              </div>
            ) : (
              <Outlet />
            )}
          </AccountContext.Provider>
        </div>
      </Section>
    </main>
  ) : (
    ''
  )
}

Account.defaultProps = {}

Account.propTypes = {}

export default Account

// export const returnComponent = (value, components, field = 'component') => {
//   const block = userPagesSchema.filter((schema) => schema?.path === value)[0]
//   if (typeof components[block?.[field]] !== 'undefined') {
//     return React.createElement(components[block?.[field]], {
//       key: block?._uid,
//       data: block,
//     })
//   }
//   return React.createElement(
//     () => <div>The component {block?.[field]} has not been created yet.</div>,
//     { key: block?._uid }
//   )
// }
