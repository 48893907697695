import React, { useState, useCallback, useRef, useEffect } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'

import { pdfjs, Document, Page } from 'react-pdf'
import Card from '../Card/Card'
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js'

function highlightPattern(text, pattern) {
  const splitText = text.split(pattern)

  if (splitText.length <= 1) {
    return text
  }

  const matches = text.match(pattern)
  return splitText.reduce(
    (arr, element, index) =>
      matches[index]
        ? [...arr, element, <mark key={index}>{matches[index]}</mark>]
        : [...arr, element],
    []
  )
}

const PDFViewer = ({ file, ...props }) => {
  //Refs
  const refs = useRef([])
  const docRef = useRef()

  //State
  const [loading, setLoading] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [zoom, setZoom] = useState(1.0)
  const [docWidth, setDocWidth] = useState(0)
  const [docHeight, setDocHeight] = useState(0)

  useEffect(() => {
    setLoading(true)
    setPageNumber(1)
    setNumPages(null)
  }, [file])

  //Functions
  const textRenderer = useCallback(
    (textItem) => {
      return highlightPattern(textItem.str, 'the') //searchText
    },
    [searchText]
  )

  const documentLoadedHandler = ({ numPages }) => {
    setLoading(false)
    setNumPages(numPages)
  }

  useEffect(() => {
    if (!file || loading) return false
    try {
      refs.current.map((r) => observer.observe(r))
    } catch (error) {
      console.log(error)
    }

    const rect = docRef?.current?.getBoundingClientRect()
    if (rect) {
      setDocWidth(rect.width * 0.75)
    }
  }, [file, loading])

  const observer = new IntersectionObserver((entries, observer) => {
    const entry = entries[0]
    if (entry.isIntersecting)
      setPageNumber(parseInt(entry.target.getAttribute('data-index')) + 1)
  })

  return (
    <>
      <div
        className={[
          'bg-base-lighter',
          'contents-scroll',
          'display-flex',
          'flex-align-start',
          'flex-column',
          'height-full',
          'minh-0',
          'overflow-x-hidden',
          'overflow-y-scroll',
          'position-relative',
          'width-full',
        ].join(' ')}
        tabIndex="0"
      >
        <Document
          className="width-full"
          file={file}
          onLoadSuccess={documentLoadedHandler}
        >
          {loading ? (
            <>{/** TO DO: Fix this...not working */}</>
          ) : (
            <div
              className={[
                'display-flex',
                'flex-column',
                'flex-gap',
                'flex-justify-center',
                'margin-top-5',
                'width-full',
              ].join(' ')}
              ref={docRef}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div
                  className={[
                    'display-flex',
                    'flex-align-center',
                    'flex-column',
                    'flex-gap',
                    'flex-justify-center',
                    'overflow-hidden',
                  ].join(' ')}
                  data-index={index}
                  key={`page_${index + 1}`}
                  id={`page_${index + 1}`}
                  ref={(elem) => (refs.current[index] = elem)}
                >
                  <Page
                    // TO DO: Enable once issue with highlight alignment
                    // at various scales is solved
                    // customTextRenderer={textRenderer}
                    id={`page_${index + 1}`}
                    pageNumber={index + 1}
                    scale={zoom}
                    width={docWidth}
                  />
                </div>
              ))}
            </div>
          )}
        </Document>
      </div>

      {numPages && (
        <div className={['position-absolute', 'top-05', 'z-top'].join(' ')}>
          <Card
            className={['bg-base', 'font-sans-xs', 'text-white'].join(' ')}
            reduced
          >
            PDF Page {pageNumber} of {numPages}
          </Card>
        </div>
      )}
    </>
  )
}

PDFViewer.defaultProps = {}

PDFViewer.propTypes = {
  file: PropTypes.string,
}

export default PDFViewer
