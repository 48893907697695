import React from 'react'
import RepeatableField from '../../components/form/RepeatableField'
import { congressRangeSchema } from './congress-schema'
import { dateSearchToggleSchema } from './input-schema-dates'

// export const inputDropdownSelector = ['q', 'title', 'naId']

/**
 * ABOUT ADVANCED SEARCH SCHEMAS
 */

/**
 * Schema for building and validating keyword inputs
 */
export const qInputSchema = {
  allow: ['bool', 'stemming', 'string'],
  autoComplete: false,
  isDefault: true,
  examples: ['constitution', 'photo*', '"Abraham Lincoln"'],
  id: 'q',
  label: 'All fields',
  errorLabel: 'keyword',
  maxLength: 100,
  placeholder: 'Enter keywords or terms',
  notEmpty: true,
  selectable: true,
  sr: 'Keyword input field',
  type: 'text',
}

/**
 * Schema for building and validating title inputs
 */
export const titleInputSchema = {
  allow: ['bool', 'stemming', 'string'],
  autoComplete: false,
  description:
    'Search for records with the keywords in the titles and headings',
  examples: ['constitution', 'photo*', '"Abraham Lincoln"'],
  id: 'title',
  label: 'Title',
  errorLabel: 'title',
  maxLength: 200,
  notEmpty: true,
  placeholder: 'Enter a record title',
  selectable: true,
  sr: 'Record title input field',
  type: 'text',
}

/**
 * Schema for building and validating creator inputs
 */
export const creatorInputSchema = {
  allow: ['string', 'stemming', 'booleanOperators', 'exactPhrase'],
  autoComplete: true,
  examples: ['Mary', 'Web*', '"Mary Webster Wiley"'],
  id: 'creators',
  label: 'Creator',
  errorLabel: 'creator',
  maxLength: 100,
  placeholder: 'Enter the name of a creator',
  notEmpty: true,
  selectable: true,
  sr: 'Creator input field',
  type: 'text',
}

/**
 * Schema for building and validating geographic reference inputs
 */
export const geoReferenceInputSchema = {
  allow: ['string', 'stemming', 'booleanOperators', 'exactPhrase'],
  autoComplete: true,
  errorLabel: 'geographic reference',
  examples: ['Washington', 'Cal*'],
  id: 'geographicReference',
  label: 'Geographic Reference',
  maxLength: 100,
  placeholder: 'Enter the name of a geographic reference',
  notEmpty: true,
  selectable: true,
  sr: 'Geographic reference input field',
  type: 'text',
}

/**
 * Schema for building and validating person or org search inputs
 */
export const personOrOrgInputSchema = {
  allow: ['string', 'stemming', 'booleanOperators', 'exactPhrase'],
  autoComplete: true,
  errorLabel: 'authority heading',
  examples: ['Washington', 'Cal*', 'Department of Justice'],
  id: 'personOrOrg',
  label: 'Person or Organization',
  maxLength: 100,
  placeholder: 'Enter the name of a person or organization',
  notEmpty: true,
  selectable: true,
  sr: 'Person or organization input field',
  type: 'text',
}

/**
 * Schema for building and validating comment contribution search inputs
 */
export const commentContributionInputSchema = {
  allow: ['string', 'stemming', 'booleanOperators', 'exactPhrase'],
  autoComplete: true,
  errorLabel: 'comment contribution',
  examples: ['spacecraft', 'space*', 'space craft'],
  id: 'commentContribution',
  label: 'Comments',
  maxLength: 100,
  placeholder: 'Enter a keyword for comment',
  notEmpty: true,
  selectable: true,
  sr: 'Comment contribution input field',
  type: 'text',
}

/**
 * Schema for building and validating Extracted text contribution search inputs
 */
export const extractedTextInputSchema = {
  allow: ['string', 'stemming', 'booleanOperators', 'exactPhrase'],
  autoComplete: true,
  errorLabel: 'extracted text',
  examples: ['Abraham Lincoln', 'Abraham*'],
  id: 'extractedTextContribution',
  label: 'Extracted Text',
  maxLength: 100,
  placeholder: 'Enter a keyword for extracted text',
  notEmpty: true,
  selectable: true,
  sr: 'Extracted text contribution input field',
  type: 'text',
}

/**
 * Schema for building and validating Extracted text contribution search inputs
 */
export const tagContributionInputSchema = {
  allow: ['string', 'stemming', 'booleanOperators', 'exactPhrase'],
  autoComplete: true,
  isDefault: true,
  errorLabel: 'tag contribution',
  examples: ['spacecraft', 'space*', 'space craft'],
  id: 'tagContribution',
  label: 'Tags',
  maxLength: 100,
  placeholder: 'Enter a keyword for tag contribution',
  notEmpty: true,
  selectable: true,
  sr: 'Tag contribution input field',
  type: 'text',
}

/**
 * Schema for building and validating Extracted text contribution search inputs
 */
export const transcriptionContributionInputSchema = {
  allow: ['string', 'stemming', 'booleanOperators', 'exactPhrase'],
  autoComplete: true,
  errorLabel: 'transcription contribution',
  examples: ['Abraham Lincoln', 'Abraham*'],
  id: 'transcriptionContribution',
  label: 'Transcriptions',
  maxLength: 100,
  placeholder: 'Enter a keyword for transcription contribution',
  notEmpty: true,
  selectable: true,
  sr: 'Transcription contribution input field',
  type: 'text',
}

/**
 * Schema for building and validating collection ID inputs
 */
export const collectionIdentifierInputSchema = {
  allow: ['bool', 'stemming', 'string'],
  autoComplete: false,
  description:
    'Returns collections and descriptions related to any of the submitted collection IDs.',
  errorLabel: 'collection identifier',
  examples: ['JFK-164', 'WJC*', 'WJC-FCIP, WJC-WHOO'],
  id: 'collectionIdentifier',
  isDefault: true,
  label: 'Collection Identifier',
  maxLength: 100,
  placeholder: 'Enter one or more collection identifiers',
  notEmpty: true,
  selectable: true,
  sr: ' Collection Identifiers input field',
  type: 'text',
}

/**
 * Schema for building and validating record group numbers inputs
 */
export const recordGroupNumberInputSchema = {
  allow: ['bool', 'stemming', 'string'],
  autoComplete: false,
  description:
    'Returns record groups and descriptions related to any of the submitted record group numbers.',
  errorLabel: 'record group number',
  examples: ['255', '4*', '255, 404'],
  label: 'Record Group Number',
  id: 'recordGroupNumber',
  isDefault: true,
  maxLength: 100,
  notEmpty: true,
  placeholder: 'Enter one or more record group numbers',
  selectable: true,
  sr: 'Record Group Number input field',
  type: 'text',
}

/**
 * Schema for building and validating title inputs
 */
// export const headingInputSchema = {
//   allow: ['bool', 'stemming', 'string'],
//   autoComplete: false,
//   // examples: ['Constitution', 'Abraham Lincoln'],
//   description:
//     'Search for records with the keywords in the titles and headings',
//   id: 'title',
//   label: 'Heading',
//   maxLength: 200,
//   placeholder: 'Enter an authority heading',
//   selectable: true,
//   sr: 'Authority heading input field',
//   type: 'text',
// }

/**
 * Schema for building and validating naId inputs
 */
export const controlNumberInputSchema = {
  allow: ['array', 'number', 'string', 'stemming'],
  autoComplete: false,
  isDefault: true,
  description:
    'Searches control numbers of any type, including National Archives Identifiers, Local Identifiers, Record Center Transfer Numbers, and more.',
  examples: ['1667751', '21-A-1, 5635483'],
  id: 'controlNumbers',
  label: 'All Control Numbers',
  errorLabel: 'control number',
  maxLength: 100,
  notEmpty: true,
  order: 1,
  placeholder: 'Enter a Control Number',
  selectable: true,
  sr: 'Control Number input field',
  type: 'text',
}

/**
 * Schema for building and validating hms inputs
 */
export const variantControlTypeInputSchema = {
  allow: ['array', 'bool', 'string', 'stemming'],
  autoComplete: false,
  examples: ['HMS Asset ID'],
  id: 'variantControlType',
  label: 'Variant Control Type',
  maxLength: 200,
  notEmpty: true,
  order: 3,
  placeholder: 'Enter a Variant Control Type',
  selectable: true,
  sr: 'Variant Control Type input field',
  type: 'text',
}

/**
 * Schema for building and validating naId inputs
 */
export const naIdInputSchema = {
  allow: ['array', 'bool', 'number', 'stemming'],
  autoComplete: false,
  description:
    'Returns the record(s) with the NAID(s). This will not return records which are related to the record(s) with the NAID(s).',
  examples: ['1667751', '1667751, 340'],
  id: 'naId',
  isDefault: false,
  label: 'National Archives Identifier',
  errorLabel: 'NAID',
  maxLength: 100,
  notEmpty: true,
  order: 2,
  placeholder: 'Enter a National Archives Identifier',
  selectable: true,
  sr: 'National Archives Identifier input field',
  type: 'text',
}

/**
 * Schema for building and validating local identifier inputs
 */
export const localIdInputSchema = {
  allow: ['array', 'bool', 'number', 'stemming'],
  autoComplete: false,
  examples: ['75-IP-1-23', '21-A-1, 21-A-2'], //'75-IP*',
  id: 'localIdentifier',
  label: 'Local Identifier',
  errorLabel: 'local ID',
  maxLength: 100,
  notEmpty: true,
  order: 4,
  placeholder: 'Enter a Local Identifier',
  selectable: true,
  sr: 'Local Identifier input field',
  type: 'text',
}

/**
 * Schema for building and validating the levelOfDescription input
 * TO DO:
 */
export const levelOfDescriptionInputSchema = {
  allow: ['array', 'string'],
  description:
    'Returns descriptions which match any of the submitted levels of the archival hierarchy.',
  id: 'levelOfDescription',
  label: 'Level of Descriptions',
  maxLength: 50, // This number was derived from the length of all possible values concatenated with ', '
  multiSelect: true,
  options: [
    {
      isPlaceholder: true,
      id: 'level-isDefault',
      label: 'Select one or more levels of description',
      value: '',
    },
    {
      id: 'level-recordGroup',
      label: 'Record Group',
      order: 2,
      value: 'recordGroup',
    },
    {
      id: 'level-collection',
      label: 'Collection',
      order: 1,
      value: 'collection',
    },
    {
      id: 'level-series',
      label: 'Series',
      order: 3,
      value: 'series',
    },
    {
      id: 'level-fileUnit',
      label: 'File Unit',
      order: 4,
      value: 'fileUnit',
    },
    {
      id: 'level-item',
      label: 'Item',
      order: 5,
      value: 'item',
    },
  ],
  searchable: false,
  sr: 'Level of description multi-select dropdown field',
  type: 'select',
}

/**
 * Schema for building and validating the typeOfMaterials input
 */
export const typeOfMaterialsInputSchema = {
  allow: ['array', 'string'],
  description:
    'Returns descriptions with one or more of the submitted material types. Results may or may not contain digital object.',
  // 'Archival records can be labeled with one or more material types. Results with the most matches will be prioritized.',
  id: 'typeOfMaterials',
  label: 'Type of Material',
  maxLength: 180, // This number was derived from the length of all possible values concatenated with ', '
  multiSelect: true,
  options: [
    {
      isPlaceholder: true,
      id: 'type-isDefault',
      label: 'Select one or more material types',
      value: '',
    },
    {
      id: 'type-architectural-and-engineering-drawings',
      label: 'Architectural and Engineering Drawings',
      value: 'Architectural and Engineering Drawings',
    },
    {
      id: 'type-artifacts',
      label: 'Artifacts',
      value: 'Artifacts',
    },
    {
      id: 'type-data-files',
      label: 'Data Files',
      value: 'Data Files',
    },
    {
      id: 'type-maps-and-charts',
      label: 'Maps and Charts',
      value: 'Maps and Charts',
    },
    {
      id: 'type-moving-images',
      label: 'Moving Images',
      value: 'Moving Images',
    },
    {
      id: 'type-photographs-and-other-graphic-materials',
      label: 'Photographs and other Graphic Materials',
      value: 'Photographs and other Graphic Materials',
    },
    {
      id: 'type-sound-recordings',
      label: 'Sound Recordings',
      value: 'Sound Recordings',
    },
    {
      id: 'type-textual-records',
      label: 'Textual Records',
      value: 'Textual Records',
    },
    {
      id: 'type-web-pages',
      label: 'Web Pages',
      value: 'Web Pages',
    },
  ],
  reset: true,
  searchable: false,
  sr: 'Types of Materials multi-select dropdown field',
  type: 'select',
}

/**
 * Schema for building and validating the typeOfMaterials input
 */
export const fileFormatInputSchema = {
  allow: ['array', 'string'],
  id: 'objectType',
  label: 'File Format of Archival Materials',
  maxLength: 200,
  multiSelect: true,
  options: [
    {
      isPlaceholder: true,
      id: 'format-isDefault',
      label: 'Select one or more file formats',
      value: '',
    },
    {
      id: 'format-audioVisual',
      label: 'Audio Visual Formats',
      options: [
        {
          id: 'format-audioVisual-rm',
          label: 'RealMedia Video Stream (.rm)',
          value: '"RealMedia Video Stream"',
        },
        {
          id: 'format-audioVisual-avi',
          label: 'AVI File (.avi)',
          value: '"avi"',
        },
        {
          id: 'format-audioVisual-mov',
          label: 'MOV File (.mov)',
          value: '"mov"',
        },
        {
          id: 'format-audioVisual-mp4',
          label: 'MP4 File (.mp4)',
          value: '"mp4"',
        },
        {
          id: 'format-audioVisual-mpg',
          label: 'MPG File (.mpg)',
          value: '"mpg"',
        },
        {
          id: 'format-audioVisual-wmv',
          label: 'WMV File (.wmv)',
          value: '"wmv"',
        },
      ],
      order: 1,
    },
    {
      id: 'format-documents',
      label: 'Document and Text Formats',
      options: [
        {
          id: 'format-documents-ascii',
          label: 'ASCII Text (.txt) ',
          value: '"ascii"',
        },
        {
          id: 'format-documents-binary',
          label: 'Binary File ',
          value: '"binary"',
        },
        {
          id: 'format-documents-ebcdic-binary',
          label: 'EBCDIC Binary File',
          value: '"ebcdic binary"', // TO DO
        },
        {
          id: 'format-documents-ebcdic-text',
          label: 'EBCDIC Text File',
          value: '"ebcdic text"', // TO DO
        },
        {
          id: 'format-documents-doc',
          label: 'Microsoft Word Document (.doc, .docx)',
          value: '"Word"',
        },
        {
          id: 'format-documents-xls',
          label: 'Microsoft Excel Spreadsheet (.xls, .xlsx)',
          value: '"Excel"',
        },
        {
          id: 'format-documents-ppt',
          label: 'Microsoft PowerPoint Document (.ppt)',
          value: '"PowerPoint"',
        },
        {
          id: 'format-documents-pub',
          label: 'Microsoft Publisher Document (.pub)',
          value: '"Publisher"',
        },
        {
          id: 'format-documents-wri',
          label: 'Microsoft Write Document (.wri)',
          value: '"Write"',
        },
        {
          id: 'format-documents-rtf',
          label: 'Rich Text Format (.rtf)',
          value: '"rtf"',
        },
        {
          id: 'format-documents-txt',
          label: 'Standard Text Document (.txt)',
          value: '"txt"',
        },
        {
          id: 'format-documents-csv',
          label: 'Text Document, Comma-Separated Values (.csv)',
          value: '"csv"',
        },
        {
          id: 'format-documents-xml',
          label: '"Extensible Markup Language" (.xml)',
          value: '"xml"',
        },
      ],
      order: 2,
      selectAll: true,
    },
    {
      id: 'format-images',
      label: 'Image Formats',
      options: [
        {
          id: 'format-images-bmp',
          label: 'Bitmap Image (.bmp)',
          value: '"bmp"',
        },
        {
          id: 'format-images-gif',
          label: 'GIF Image (.gif)',
          value: '"gif"',
        },
        {
          id: 'format-images-jpg',
          label: 'JPEG Image (.jpg, .jpeg)',
          value: '"jpg"',
        },
        {
          id: 'format-images-jp2',
          label: 'JPEG 2000 Image (.jp2)',
          value: '"jp2"',
        },
        {
          id: 'format-images-png',
          label: 'PNG Image (.png)',
          value: '"png"',
        },
        {
          id: 'format-images-tiff',
          label: 'TIFF Image (.tiff)',
          value: '"tiff"',
        },
        {
          id: 'format-images-dng',
          label: 'DNG Image (.dng)',
          value: '"dng"',
        },
      ],
      order: 3,
    },
    {
      id: 'format-sound',
      label: 'Sound Formats',
      options: [
        {
          id: 'format-sound-mp3',
          label: 'MP3 Sound File (.mp3)',
          value: '"mp3"',
        },
        {
          id: 'format-sound-wav',
          label: 'WAV Sound File (.wav)',
          value: '"wav"',
        },
      ],

      order: 4,
    },
    {
      id: 'format-other',
      label: 'Other Formats',
      options: [
        {
          id: 'format-pdf',
          label: 'Portable Document Format (.pdf)',
          value: '"pdf"',
        },
        {
          id: 'format-zip',
          label: 'Compressed File (.zip)',
          value: '"zip"',
        },
        {
          id: 'format-html',
          label: 'Web Page (.html, .htm)',
          value: '"html"', // OR World Wide Web Page
        },
      ],
      order: 5,
    },
  ],
  reset: true,
  searchable: true,
  sr: 'Types of Materials multi-select dropdown field',
  type: 'select',
}

/**
 * Schema for building and validating the levelOfDescription input
 * TO DO:
 */
export const materialLocationsInputSchema = {
  allow: ['array', 'string'],
  id: 'referenceUnits',
  label: 'Location of Archival Materials',
  maxLength: 1990,
  multiSelect: true,
  options: [
    {
      isPlaceholder: true,
      id: 'locations-isDefault',
      label: 'Select one or more material locations',
      value: '',
    },
    {
      details: [{ label: 'Hoffman Estates, Illinois' }],
      id: 'locations-barackObamaPresidentialLibrary',
      label: 'Barack Obama Presidential Library',
      value: '"Barack Obama Presidential Library"',
    },
    {
      details: [{ label: 'Abilene, Kansas' }],
      id: 'locations-dwightDEisenhowerLibrary',
      label: 'Dwight D. Eisenhower Library',
      value: '"Dwight D. Eisenhower Library"',
    },
    {
      details: [{ label: 'College Park, Maryland' }],
      id: 'locations-donaldJTrumpPresidentialLibrary',
      label: 'Donald J. Trump Presidential Library',
      value: '"Donald J. Trump Presidential Library"',
    },
    {
      details: [{ label: 'Hyde Park, New York' }],
      id: 'locations-franklinDRooseveltLibrary',
      label: 'Franklin D. Roosevelt Library',
      value: '"Franklin D. Roosevelt Library"',
    },
    {
      details: [{ label: 'College Station, Texas' }],
      id: 'locations-georgeBushLibraryCollege',
      label: 'George Bush Library',
      value: '"George Bush Library"',
    },
    {
      details: [{ label: 'Dallas, Texas' }],
      id: 'locations-georgeWBushLibrary',
      label: 'George W. Bush Library',
      value: '"George W. Bush Library"',
    },
    {
      details: [{ label: 'Ann Arbor, Michigan' }],
      id: 'locations-geraldRFordLibrary',
      label: 'Gerald R. Ford Library',
      value: '"Gerald R. Ford Library"',
    },
    {
      details: [{ label: 'Grand Rapids, Michigan' }],
      id: 'locations-geraldRFordMuseum',
      label: 'Gerald R. Ford Museum',
      value: '"Gerald R. Ford Museum"',
    },
    {
      details: [{ label: 'Independence, Missouri' }],
      id: 'locations-harrySTrumanLibrary',
      label: 'Harry S. Truman Library',
      value: '"Harry S. Truman Library"',
    },
    {
      details: [{ label: 'West Branch, Indiana' }],
      id: 'locations-herbertHooverLibrary',
      label: 'Herbert Hoover Library',
      value: '"Herbert Hoover Library"',
    },
    {
      details: [{ label: 'Atlanta, Georgia' }],
      id: 'locations-jimmyCarterLibrary',
      label: 'Jimmy Carter Library',
      value: '"Jimmy Carter Library"',
    },
    {
      details: [{ label: 'Boston, Massachusetts' }],
      id: 'locations-johnFKennedyLibrary',
      label: 'John F. Kennedy Library',
      value: '"John F. Kennedy Library"',
    },
    {
      details: [
        { label: 'affiliated', type: 'pill', style: 'info' },
        { label: 'Washington, DC' },
      ],
      id: 'locations-libraryOfCongressPrintsAndPhotographs',
      label: 'Library of Congress, Prints and Photographs',
      value: '"Library of Congress Prints and Photographs Division"',
    },
    {
      details: [{ label: 'Austin, Texas' }],
      id: 'locations-lyndonBainesJohnsonLibrary',
      label: 'Lyndon Baines Johnson Library',
      value: '"Lyndon Baines Johnson Library"',
    },
    {
      details: [{ label: 'Washington, DC' }],
      id: 'locations-centerForLegislativeArchives',
      label: 'National Archives I, Center for Legislative Archives',
      value: '"Center for Legislative Archives"',
    },
    {
      details: [{ label: 'Washington, DC' }],
      id: 'locations-presidentialMaterialsDivision',
      label: 'National Archives I, Presidential Materials Division',
      value: '"Presidential Materials Division"',
    },
    {
      details: [{ label: 'Washington, DC' }],
      id: 'locations-nationalArchivesITextualReference',
      label: 'National Archives at Washington, DC - Textual Reference',
      value: '"National Archives at Washington DC - Textual Reference"',
    },
    {
      details: [{ label: 'College Park, Maryland' }],
      id: 'locations-cartographic',
      label: 'National Archives II, Cartographic',
      value: '"National Archives at College Park - Cartographic"',
    },
    {
      details: [{ label: 'College Park, Maryland' }],
      id: 'locations-electronicRecords',
      label: 'National Archives II, Electronic Records',
      value: '"National Archives at College Park - Electronic Records"',
    },
    {
      details: [{ label: 'College Park, Maryland' }],
      id: 'locations-FOIA',
      label: 'National Archives II, FOIA',
      value: '"National Archives at College Park - FOIA"',
    },
    {
      details: [{ label: 'College Park, Maryland' }],
      id: 'locations-motionPictures',
      label: 'National Archives II, Motion Pictures',
      value: '"National Archives at College Park - Motion Pictures"',
    },
    {
      details: [{ label: 'College Park, Maryland' }],
      id: 'locations-stillPictures',
      label: 'National Archives II, Still Pictures',
      value: '"National Archives at College Park - Still Pictures"',
    },
    {
      details: [{ label: 'College Park, Maryland' }],
      id: 'locations-nationalArchivesIItextualReference',
      label: 'National Archives II, Textual Reference',
      value: '"National Archives at College Park - Textual Reference"',
    },
    {
      details: [{ label: 'College Park, Maryland' }],
      id: 'locations-collegePark',
      label: 'National Archives II, College Park',
      value: '"National Archives at College Park"',
    },
    // {
    //   details: [{ label: 'Anchorage, Alaska' }],
    //   id: 'locations-nationalArchivesAtAnchorage',
    //   label: 'National Archives at Anchorage',
    //   value: '"National Archives at Anchorage"',
    // },
    {
      details: [{ label: 'Atlanta, Georgia' }],
      id: 'locations-nationalArchivesAtAtlanta',
      label: 'National Archives at Atlanta',
      value: '"National Archives at Atlanta"',
    },
    {
      details: [{ label: 'Boston, Massachusetts' }],
      id: 'locations-nationalArchivesAtBoston',
      label: 'National Archives at Boston',
      value: '"National Archives at Boston"',
    },
    {
      details: [{ label: 'Chicago, Illinois' }],
      id: 'locations-nationalArchivesAtChicago',
      label: 'National Archives at Chicago',
      value: '"National Archives at Chicago"',
    },
    {
      details: [{ label: 'Denver, Colorado' }],
      id: 'locations-nationalArchivesAtDenver',
      label: 'National Archives at Denver',
      value: '"National Archives at Denver"',
    },
    {
      details: [{ label: 'Fort Worth, Texas' }],
      id: 'locations-nationalArchivesAtFortWorth',
      label: 'National Archives at Fort Worth',
      value: '"National Archives at Fort Worth"',
    },
    {
      details: [{ label: 'Kansas City, Missouri' }],
      id: 'locations-nationalArchivesAtKansasCity',
      label: 'National Archives at Kansas City',
      value: '"National Archives at Kansas City"',
    },
    {
      details: [{ label: 'New York, New York' }],
      id: 'locations-nationalArchivesAtNewYork',
      label: 'National Archives at New York',
      value: '"National Archives at New York"',
    },
    {
      details: [{ label: 'Philadelphia, Pennsylvania' }],
      id: 'locations-nationalArchivesAtPhiladelphia',
      label: 'National Archives at Philadelphia',
      value: '"National Archives at Philadelphia"',
    },
    {
      details: [{ label: 'Perris, California' }],
      id: 'locations-nationalArchivesAtRiverside',
      label: 'National Archives at Riverside',
      value: '"National Archives at Riverside"',
    },
    {
      details: [{ label: 'San Francisco, California' }],
      id: 'locations-nationalArchivesAtSanFrancisco',
      label: 'National Archives at San Francisco',
      value: '"National Archives at San Francisco"',
    },
    {
      details: [{ label: 'Seattle, Washington' }],
      id: 'locations-nationalArchivesAtSeattle',
      label: 'National Archives at Seattle',
      value: '"National Archives at Seattle"',
    },
    {
      details: [{ label: 'St. Louis, Missouri' }],
      id: 'locations-nationalArchivesAtStLouis',
      label: 'National Archives at St. Louis',
      value: '"National Archives at St. Louis"',
    },
    {
      details: [{ label: 'St. Louis, Missouri' }],
      id: 'locations-civilianPersonnelRecords',
      label: 'National Personnel Records Center, Civilian Personnel Records',
      value: '"National Personnel Records Center - Civilian Personnel Records"',
    },
    {
      details: [{ label: 'St. Louis, Missouri' }],
      id: 'locations-militaryPersonnelRecords',
      label: 'National Personnel Records Center, Military Personnel Records',
      value: '"National Personnel Records Center - Military Personnel Records"',
    },
    {
      details: [
        { label: 'affiliated', type: 'pill', style: 'info' },
        { label: '' },
      ],
      id: 'locations-commissionOfPublicRecords',
      label:
        'New Mexico State Records Center and Archives, Commission of Public Records',
      value:
        '"New Mexico Commission of Public Records State Records Center and Archives"',
    },
    {
      details: [
        { label: 'affiliated', type: 'pill', style: 'info' },
        { label: 'Oklahoma City, Oklahoma' },
      ],
      id: 'locations-oklahomaHistoricalSociety',
      label: 'Oklahoma Historical Society',
      value: '"Oklahoma Historical Society"',
    },
    {
      details: [
        { label: 'affiliated', type: 'pill', style: 'info' },
        { label: '' },
      ],
      id: 'locations-historicalAndMuseumCommission',
      label: 'Pennsylvania State Archives, Historical and Museum Commission',
      value: '"Historical and Museum Commission"',
    },
    {
      details: [{ label: 'Yorba Linda, California' }],
      id: 'locations-richardNixonLibrary',
      label: 'Richard Nixon Library',
      value: '"Richard Nixon Library"',
    },
    {
      details: [{ label: 'College Park, Maryland' }],
      id: 'locations-richardNixonLibraryCollegePark',
      label: 'Richard Nixon Library',
      value: '"Richard Nixon Library - College Park"',
    },
    {
      details: [{ label: 'Simi Valley, California' }],
      id: 'locations-ronaldReaganLibrary',
      label: 'Ronald Reagan Library',
      value: '"Ronald Reagan Library"',
    },
    {
      details: [
        { label: 'affiliated', type: 'pill', style: 'info' },
        { label: 'Washington, DC' },
      ],
      id: 'locations-uSGovernmentPrintingOffice',
      label: 'U.S. Government Printing Office',
      value: '"U.S. Government Printing Office"',
    },
    {
      details: [
        { label: 'affiliated', type: 'pill', style: 'info' },
        { label: 'West Point, New York' },
      ],
      id: 'locations-uSMilitaryAcademyArchives',
      label: 'United States Military Academy Archives',
      value: '"United States Military Academy Archives"',
    },
    {
      details: [
        { label: 'affiliated', type: 'pill', style: 'info' },
        { label: 'Denton, Texas' },
      ],
      id: 'locations-universityOfNorthTexasLibraries',
      label: 'University of North Texas Libraries',
      value: '"University of North Texas Libraries"',
    },
    {
      details: [{ label: 'Little Rock, Arkansas' }],
      id: 'locations-williamJClintonLibrary',
      label: 'William J. Clinton Library',
      value: '"William J. Clinton Library"',
    },
    {
      details: [
        { label: 'affiliated', type: 'pill', style: 'info' },
        { label: 'United States Naval Academy, Annapolis, Maryland' },
      ],
      id: 'locations-williamWJeffriesMemorialArchives',
      label: 'William W. Jeffries Memorial Archives',
      value:
        '"United States Naval Academy William W. Jeffries Memorial Archives"',
    },
    {
      details: [
        { label: 'affiliated', type: 'pill', style: 'info' },
        { label: 'National Park Service, Yellowstone National Park, Wyoming' },
      ],
      id: 'locations-yellowstoneNationalParkArchives',
      label: 'Yellowstone National Park Archives',
      value: '"National Park Service Yellowstone National Park Archives"',
    },
  ],
  searchable: true,
  sr: 'material locations multi-select dropdown field',
  type: 'select',
}

/**
 * Use the schemas above to specify which inputs are in which arrays:
 */
export const keywordSearchSchema = {
  id: 'keywords',
  label: 'Keywords',
  schema: [
    qInputSchema,
    titleInputSchema,
    creatorInputSchema,
    geoReferenceInputSchema,
    personOrOrgInputSchema,
  ],
  type: 'repeatable',
}

export const contributionSearchSchema = {
  id: 'contributions',
  label: 'Contributions',
  schema: [
    commentContributionInputSchema,
    extractedTextInputSchema,
    tagContributionInputSchema,
    transcriptionContributionInputSchema,
  ],
  description:
    'Returns descriptions and authority records with contributions related to the search keywords. Results may or may not contain digital objects, depending on the contribution type selected.',
  type: 'repeatable',
}

export const controlNumberSearchSchema = {
  id: 'controlNumbers',
  label: 'Control Numbers',
  schema: [
    controlNumberInputSchema,
    naIdInputSchema,
    localIdInputSchema,
    // variantControlTypeInputSchema,
  ],
  type: 'repeatable',
}

export const advancedSearchSchema = [
  //Add all inputs for this form in the order in which they should appear
  keywordSearchSchema,
  contributionSearchSchema,
  controlNumberSearchSchema,
  collectionIdentifierInputSchema,
  recordGroupNumberInputSchema,
  dateSearchToggleSchema,
  levelOfDescriptionInputSchema,
  materialLocationsInputSchema,
  typeOfMaterialsInputSchema,
  fileFormatInputSchema,
  congressRangeSchema,
]

/**
 * Schema for building and validating the dataSource input
 */
export const advancedSearchConditionsSchema = {
  id: 'dataSource',
  label: 'Data Source',
  options: [
    {
      id: 'source-all',
      label: 'Everything',
      value: '',
    },
    {
      id: 'source-description',
      label: 'All Archival Descriptions',
      value: 'description&availableOnline=false',
      show: [
        'beginCongress',
        'collectionIdentifier',
        'congress',
        'controlNumbers',
        'creators',
        'endCongress',
        'geographicReference',
        'personOrOrg',
        'levelOfDescription',
        'localIdentifier',
        'naId',
        'objectType',
        'q',
        'dateSearchToggle',
        'startDate',
        'endDate',
        'recordGroupNumber',
        'referenceUnits',
        'title',
        'typeOfMaterials',
        'commentContribution',
        'extractedTextContribution',
        'tagContribution',
        'transcriptionContribution',
      ],
      isDefault: true,
    },
    {
      id: 'source-description-objects',
      label: 'Archival Descriptions with Digital Content',
      value: 'description&availableOnline=true',
      show: [
        'beginCongress',
        'collectionIdentifier',
        'congress',
        'controlNumbers',
        'creators',
        'endCongress',
        'geographicReference',
        'personOrOrg',
        'levelOfDescription',
        'localIdentifier',
        'naId',
        'objectType',
        'q',
        'dateSearchToggle',
        'startDate',
        'endDate',
        'recordGroupNumber',
        'referenceUnits',
        'title',
        'typeOfMaterials',
        'commentContribution',
        'extractedTextContribution',
        'tagContribution',
        'transcriptionContribution',
      ],
    },
    {
      id: 'source-authority',
      label: 'Authority Records',
      value: 'authority&availableOnline=false',
      show: [
        'controlNumbers',
        'personOrOrg',
        'naId',
        'q',
        'title',
        'commentContribution',
        'tagContribution',
      ],
    },
  ],
  sr: 'Data source radio field',
  type: 'button-group',
}

const selectableOptions = []
advancedSearchSchema.map((schema) => {
  if (schema.selectable)
    selectableOptions.push({
      id: schema.id,
      label: schema.label,
      schema: schema,
      value: schema.id,
    })
})

export const selectableSchema = {
  id: 'selectable',
  options: selectableOptions,
}
