import React from 'react'
import { Link } from 'react-router-dom'
import '../../styles/index.scss'
import PropTypes from 'prop-types'

const Card = ({
  active,
  children,
  clickable,
  className,
  reduced,
  to,
  ...props
}) => {
  return to ? (
    <Link
      state={to.state}
      tabIndex="0"
      title={`Visit ${to.pathname}.`}
      to={{ pathname: to.pathname, search: to.search }}
      {...props}
    >
      <div
        className={[
          'bg-white',
          active
            ? 'border-2px border-primary'
            : 'border-1px border-base-lighter',
          clickable ? 'clickable' : '',
          'display-flex',
          'flex-column',
          'flex-gap-sm',
          'grid-col-3',
          'hover:bg-base-lightest',
          reduced
            ? 'padding-05 tablet-lg:padding-1'
            : 'padding-1 tablet-lg:padding-2',
          'radius-md',
          'shadow-xs',
          'width-full',
          className,
        ].join(' ')}
      >
        {children}
      </div>
    </Link>
  ) : (
    <div
      className={[
        'bg-white',
        active ? 'border-2px border-primary' : 'border-1px border-base-lighter',
        clickable ? 'clickable' : '',
        'display-flex',
        'flex-column',
        'flex-gap-sm',
        'grid-col-3',
        reduced
          ? 'padding-05 tablet-lg:padding-1'
          : 'padding-1 tablet-lg:padding-2',
        'radius-md',
        'shadow-xs',
        'width-full',
        className,
      ].join(' ')}
      {...props}
    >
      {children}
    </div>
  )
}

Card.defaultProps = {
  active: false,
  clickable: false,
  reduced: false,
}

Card.propTypes = {
  /**
   * Defines if the card is active
   */
  active: PropTypes.bool,
  /**
   * The content provided to the component
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * Defines if the card can be clicked
   */
  clickable: PropTypes.bool,
  /**
   * Additional classes supplied to the component
   */
  className: PropTypes.string,
  /**
   * Determines the amount of visual padding added to the card
   */
  reduced: PropTypes.bool,
  /**
   * The path and state object used to navigate to a specific page via the React Link component.
   */
  to: PropTypes.object,
}

export default Card
