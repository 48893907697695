import React from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
//Components
import { Pill } from '../Pill'

const StaticPill = ({ label, reduced, ...props }) => {
  return reduced ? (
    <span className="bg-accent-cool-light display-inline-block circle-2"></span>
  ) : (
    <Pill
      className={[
        'padding-x-1',
        'maxh-205',
        'text-normal',
        'text-primary-dark',
      ].join(' ')}
      filled
      reduced
      size="3xs"
      theme="accent-cool-light"
      thin
    >
      {label}
    </Pill>
  )
}

StaticPill.defaultProps = {
  reduced: false,
  label: '',
}

StaticPill.propTypes = {
  reduced: PropTypes.bool,
  label: PropTypes.string,
}

export default StaticPill
