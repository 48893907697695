import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import { inputSchemaPropTypes } from '../../schemas/input-schemas/input-schema-prop-types'
//Context
import FormContext from '../../contexts/FormContext'
//Components
import { Button } from '../Button/Button'
import InputMessage from './input_message'
import RangeField from './RangeField'
import Select from './select'
import TextInput from './text_input'
import Tooltip from '../Tooltip/Tooltip'
//Hooks
import useInput from '../../hooks/use-input'
//Helper Functions
import {
  validateInputFromSchema,
  ValidationError,
} from '../form/Validate/Validate'
import DateInputField from './date_input_field'

const FormField = forwardRef(({ onChangeHandler, schema, ...props }, ref) => {
  const { formDispatch, formFields, formParams, setTouched, touched } =
    useContext(FormContext) || {}
  const inputRef = useRef(null)
  const [inputError, setInputError] = useState(null) //State
  const [selected, setSelected] = useState(
    schema.type === 'select'
      ? schema.options?.filter((o) => o.isPlaceholder) || [schema.options?.[0]]
      : null
  )
  const [dateSearchSelection, setDateSearchSelection] = useState('range')

  //Functions
  const selectHandler = (event, id) => {
    if (!onChangeHandler) setTouched(true)
    let newOption = [...selected]
    schema.options.map((s) => {
      if (s.options) {
        s.options.map((option) => {
          if (id === option.id) {
            if (schema.multiSelect) {
              if (newOption.filter((o) => o.id === option.id).length > 0)
                newOption = newOption.filter((o) => o.id !== option.id)
              else newOption.push(option)
            } else newOption = option
          }
        })
      } else if (id === s.id) {
        if (schema.multiSelect) {
          if (newOption.filter((o) => o.id === s.id).length > 0) {
            newOption = newOption.filter((o) => o.id !== s.id)
          } else newOption.push(s)
        } else newOption = [s]
      }
    })
    setSelected(newOption)
  }

  const storedValue = formFields?.[schema.id] ? formFields[schema.id] : ''

  const inputErrorHandler = (error) => {
    setInputError(
      typeof error === 'string' ? new ValidationError(error) : error
    )
  }

  const {
    isTouched: inputIsTouched,
    isValid: enteredValueIsValid,
    value: enteredValue,
    inputBlurHandler: blurHandler,
    valueChangeHandler: changeHandler,
    // focusHandler,
    reset: resetHandler,
  } = useInput(
    (value) => validateInputFromSchema(schema, value),
    inputErrorHandler,
    storedValue
  )

  const reset = () => {
    resetHandler()
    if (!onChangeHandler) {
      if (schema.type === 'text' && inputRef?.current) {
        inputRef.current.value = ''
        formDispatch({
          type: 'REMOVE_FORM_FIELDS',
          id: schema.id,
        })
      }
      if (schema.type === 'select') {
        formDispatch({
          type: 'REMOVE_FORM_FIELDS',
          id: selected?.[0].id,
        })
        setSelected(
          schema.options.filter((o) => o.isPlaceholder) || [schema.options[0]]
        )
      }
    } else
      setSelected(
        schema.options.filter((o) => o.isPlaceholder) || [schema.options[0]]
      )
  }

  useEffect(() => {
    if (!onChangeHandler)
      formDispatch({
        type: 'SET_FORM_ERRORS',
        id: schema.id,
        error: inputError || null,
      })
  }, [inputError])

  useEffect(() => {
    updateValue('exactDate', '')
    updateValue('startDate', '')
    updateValue('endDate', '')
    updateValue('recurringDateMonth', '')
    updateValue('recurringDateDay', '')
  }, [dateSearchSelection])

  useEffect(() => {
    updateValue(schema.id, enteredValue)
  }, [enteredValue])

  const inputChangeHandler = (event) => {
    changeHandler(event)
  }

  useEffect(() => {
    if (!onChangeHandler) {
      changeHandler(null, enteredValue)
      updateValue(
        schema.id,
        selected?.length > 1
          ? selected?.reduce((a, b) => [...a, b.value], [])
          : selected?.[0].value
      )
    } else onChangeHandler(null, enteredValue)
  }, [selected])

  const updateValue = (id, value) => {
    if (onChangeHandler) onChangeHandler(null, value)
    else
      formDispatch({
        type: 'UPDATE_FORM_FIELD',
        //
        id: id,
        value: value,
      })
  }

  //https://reactjs.org/docs/hooks-reference.html#useimperativehandle
  useImperativeHandle(ref, () => ({
    error: inputError,
    reset: reset,
    value: enteredValue,
  }))

  /**
   * When the form is reset, clear the input
   */
  useEffect(() => {
    if (!touched) reset()
  }, [touched])

  const disable =
    // (schema.type === 'text' && (!enteredValue || enteredValue === '')) ||
    (schema?.options?.filter((o) => formFields?.[o.id]).length === 0 &&
      !formFields?.[schema.id]) ||
    typeof formFields?.[schema.id] === 'undefined'

  return (
    <label
      className={['display-flex', ' flex-gap', 'flex-column'].join(' ')}
      htmlFor={schema.id}
    >
      <div
        className={[
          'display-flex',
          'flex-align-center',
          'flex-justify',
          'flex-row',
          'flex-gap-xs',
          'minh-button',
          'margin-bottom-neg-2',
        ].join(' ')}
      >
        <h2
          className={[
            'display-flex',
            'flex-gap',
            'flex-row',
            'margin-bottom-0',
          ].join(' ')}
        >
          {schema.label}{' '}
          {schema.description && (
            <Tooltip
              content={schema.description}
              delay={500}
              display="block"
              id={'#' + schema.id + 'description'}
              position="topCenter"
            >
              <Button
                align="center"
                iconName="circle-questions"
                iconOnly
                iconSize="xs"
                reduced
                shape="pill"
                srText={`${schema.label} details`}
                textOnly
                theme="base-dark"
              />
            </Tooltip>
          )}
        </h2>
        {!onChangeHandler &&
          schema.type !== 'text' &&
          schema.type !== 'date-search-toggle' && (
            <Button
              className={['margin-bottom-neg-2', 'margin-top-neg-2'].join(' ')}
              disabled={disable}
              onClick={reset}
              reduced
              textOnly
              thin
            >
              Reset selection
            </Button>
          )}
      </div>
      <div
        className={['display-flex', ' flex-gap-sx', 'flex-column'].join(' ')}
      >
        {schema.type === 'text' && (
          <TextInput
            onBlur={blurHandler}
            onChange={inputChangeHandler}
            error={inputError}
            ref={inputRef}
            schema={schema}
            {...props}
          />
        )}
        {schema.type === 'select' && (
          <Select
            onBlur={blurHandler}
            onChange={selectHandler}
            error={inputError}
            multiSelect={schema.multiSelect || false}
            ref={inputRef}
            schema={schema.options}
            search={schema.searchable}
            selected={selected}
            {...props}
          />
        )}
        {schema.type === 'range' && (
          <RangeField
            onBlur={blurHandler}
            onChange={updateValue}
            linked
            schema={schema}
            {...props}
          />
        )}
        {schema.type === 'date-search-toggle' && (
          <div
            className={[
              'bg-base-lightest-opacity-50',
              'border-1px',
              'border-base-light',
              'display-flex',
              'flex-column',
              'padding-1',
              'radius-md',
            ].join(' ')}
          >
            <div
              className={['display-flex', 'flex-gap-0', 'flex-row'].join(' ')}
            >
              {schema.options?.map((option, index) => (
                <div key={option.id} id={option.id}>
                  <Button
                    className={[
                      index === 0 ? 'radius-left-md' : '',
                      index === schema.options.length - 1
                        ? 'radius-right-md'
                        : '',
                      index !== schema.options.length - 1
                        ? 'margin-right-neg-2px'
                        : '',
                      'height-full',
                    ].join(' ')}
                    key={index}
                    onClick={() => {
                      setDateSearchSelection(option.id)
                    }}
                    outline={dateSearchSelection !== option.id}
                    shape="0"
                  >
                    {option.label}
                  </Button>
                </div>
              ))}
            </div>
            {schema.options
              ?.filter((option) => option.id === dateSearchSelection)
              .map((option) => (
                <div key={`${option.id}-field`} id={`${option.id}-field`}>
                  {option.show.map((searchType) => (
                    <div
                      className={[
                        'display-flex',
                        'flex-gap-sm',
                        'flex-column',
                      ].join(' ')}
                      key={searchType.id}
                    >
                      <DateInputField key={searchType.id} schema={searchType} />
                    </div>
                  ))}
                </div>
              ))}
          </div>
        )}

        <InputMessage schema={schema} message={inputError} />
      </div>
    </label>
  )
})

FormField.defaultProps = {}

FormField.propTypes = {
  /**
   * Optional function to handle the change within the parent component
   */
  onChangeHandler: PropTypes.func,
  /**
   * Defines the input and validation
   */
  schema: inputSchemaPropTypes,
}

FormField.displayName = 'FormField'
export default FormField
