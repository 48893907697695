/**
 *
 */

const useDownloadButton = (fileUrl, fileName) => {
  if (!fileUrl) {
    console.warn('No file was provided to download')
    return false
  }

  const handleObjectDownload = (event) => {
    event.preventDefault()
    let link = document.createElement('a')
    link.href = fileUrl
    link.target = '_blank'
    link.download = fileName

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
  }

  return { handleObjectDownload }
}
export default useDownloadButton
