import React from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
//Components
import { Pill } from '../Pill'
import { Icon } from '../../Icon/Icon'
import Tooltip from '../../Tooltip/Tooltip'
import useScreenSize from '../../../hooks/use-screen-size'

const StatusPill = ({
  className,
  icon,
  label,
  responsive,
  status,
  ...props
}) => {
  let borderColor = 'base'
  let fillColor = 'base-lightest'
  let iconName = ''
  let defaultLabel = ''
  switch (status) {
    case 'disabled':
      borderColor = 'base-dark'
      defaultLabel = 'Disabled'
      fillColor = 'base-lightest'
      iconName = 'stop'
      break
    case 'flagged':
      borderColor = 'error-dark'
      defaultLabel = 'Flagged'
      fillColor = 'error-lighter'
      iconName = 'flag'
      break
    case 'unmoderated':
      borderColor = 'warning-dark'
      defaultLabel = 'Not Reviewed'
      fillColor = 'warning-lighter'
      iconName = 'thumb-up-off'
      break
    case 'reviewed':
      borderColor = 'accent-cool-darker'
      defaultLabel = 'Reviewed'
      fillColor = 'accent-cool-lighter'
      iconName = 'thumb-up'
      break
    case 'active':
      borderColor = 'accent-cool-darker'
      defaultLabel = 'Active'
      fillColor = 'accent-cool-lighter'
      break
    case 'inactive':
      borderColor = 'base-dark'
      defaultLabel = 'Inactive'
      fillColor = 'base-lightest'
      break
    case 'success':
      borderColor = 'success-darker'
      fillColor = 'success-lighter'
      iconName = 'check'
      break
    case 'warning':
      borderColor = 'warning-dark'
      fillColor = 'warning-lighter'
      iconName = 'triangle-exclaim'
      break
  }
  if (!label) label = defaultLabel

  const { screenSize } = useScreenSize()

  return (
    <Pill
      className={[
        `bg-${fillColor}`,
        'flex-justify-center',
        'text-uppercase',
        'font-sans-5xs',
        className,
      ].join(' ')}
      iconSize={icon ? '2xs' : null}
      iconName={icon ? iconName : null}
      radius="md"
      reduced
      size={icon ? '3xs' : null}
      theme={borderColor}
      {...props}
    >
      {responsive ? label.charAt(0) : label}
    </Pill>
  )
}

StatusPill.defaultProps = {
  icon: false,
}

StatusPill.propTypes = {
  /**
   * Additional classes to apply to the wrapper
   */
  className: PropTypes.string,
  /**
   * Whether to display the predefined icon
   */
  icon: PropTypes.bool,
  /**
   * The display label
   */
  label: PropTypes.string,
  responsive: PropTypes.bool,
  status: PropTypes.oneOf([
    'disabled',
    'flagged',
    'unmoderated',
    'reviewed',
    'inactive',
    'active',
    'success',
    'warning',
  ]),
}

export default StatusPill
