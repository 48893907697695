/*
TODO: Improve objectId handling to minimize propType discrepancies
(Avoiding overuse/confusing use of parseInts)
*/

import { compareDates } from '../helper-functions/sort-functions'
/**
 * Be warned - when working with data in relation to this reducer keep the following in mind:
 * 1. objectId is sometimes transfered as a string, and other times an integer
 **** To mitigate issues, I wrap every objectId in parseInt()
 */
Array.prototype.contains = function (element) {
  return this.indexOf(element) > -1
}

export const transcriptionsReducer = (state, action) => {
  //Reusable variables for switch statement
  let active, all, current, currentActive, locked, currentLocked, id

  switch (action.type) {
    case 'FETCH_TRANSCRIPTIONS':
      all = action.allTranscriptions || state.allTranscriptions
      current = returnCurrentActiveTranscriptionFromId(action.objectId, all)
      locked = returnLockedObjectIdArray(all)
      all?.sort((a, b) =>
        compareDates(
          a.updatedAt || a.createdAt || a.responseTimestamp,
          b.updatedAt || b.createdAt || b.responseTimestamp
        )
      )
      return {
        activeTranscriptions: returnActiveObjectIdArray(all),
        allTranscriptions: all,
        currentActiveTranscription: current,
        currentTranscription: returnCurrentTranscriptionFromId(
          action.objectId,
          all
        ),
        currentTranscriptionIsLocked: locked?.contains(
          parseInt(current?.targetObjectId || action?.objectId)
        ),
        loggedInUserIsEditing: false,
        lockedTranscriptions: locked,
        parentContributionId: current?.parentContributionId,
        transcriptionIsCanceling: false,
        transcriptionIsSaved: false,
        transcriptionIsSaving: false,
        transcriptionIsPublishing: false,
        userHasTimedOut: false,
      }

    case 'UPDATE_ACTIVE_TRANSCRIPTIONS':
      if (!state.allTranscriptions) return state
      // Return an array of objectIds for any transcription...
      // ...being edited (i.e. for which a draft exists)
      active = returnActiveObjectIdArray(state.allTranscriptions)
      return {
        ...state,
        activeTranscriptions: active,
      }

    case 'UPDATE_ALL_TRANSCRIPTIONS':
      if (!state.allTranscriptions) return state
      all = state.allTranscriptions
      if (action.currentTranscription) {
        current = action.currentTranscription
        // Remove duplicate objects
        all = Array.from(new Set(all.map((e) => JSON.stringify(e))))
          .map((e) => JSON.parse(e))
          // Remove inactive draft states
          .filter((transcription) => {
            if (current.status === 'active')
              return (
                transcription.parentContributionId !==
                current.parentContributionId
              )
            else return transcription
          })
        // Add the current object
        all.push(current)

        all?.sort((a, b) =>
          compareDates(
            a.updatedAt || a.createdAt || a.responseTimestamp,
            b.updatedAt || b.createdAt || b.responseTimestamp
          )
        )
      } else {
        if (action.transcriptions) all = action.transcriptions
      }

      return {
        ...state,
        allTranscriptions: all,
      }

    case 'UPDATE_CURRENT_TRANSCRIPTION':
      if (!state.allTranscriptions) return state
      all = state.allTranscriptions
      // If a transcription is provided, add it to the allTranscriptions object
      if (action.currentTranscription) {
        current = action.currentTranscription
        all.push(current)
      } else if (action.objectId) {
        // If an objectId is provided, filter all available transcriptions

        current = returnCurrentTranscriptionFromId(
          parseInt(action.objectId || current?.targetObjectId),
          all
        )
      }
      currentActive = returnCurrentActiveTranscriptionFromId(
        parseInt(current?.targetObjectId),
        all
      )

      return {
        ...state,
        currentTranscription: current,
        currentActiveTranscription: currentActive,
        currentTranscriptionIsLocked: state.lockedTranscriptions?.contains(
          parseInt(current?.targetObjectId)
        ),
      }

    case 'UPDATE_LOCKED_TRANSCRIPTIONS':
      if (!state.allTranscriptions) return state
      // Return an array of objectIds for any transcription...
      // ...being edited (i.e. for which a draft exists)
      locked = returnLockedObjectIdArray(state.allTranscriptions)
      if (!locked) locked = []
      return {
        ...state,
        lockedTranscriptions: locked,
      }

    case 'REMOVE_TRANSCRIPTIONS_BY_ID':
      if (!state.allTranscriptions || !action.contributionId) return state
      all = state.allTranscriptions.filter((transcription) => {
        return transcription.contributionId !== action.contributionId
      })
      return {
        ...state,
        allTranscriptions: all,
        currentTranscriptionIsLocked: false,
      }

    /**
     * Stores the correct parentContributionId, which is needed...
     * ...as part of the transcription put request to ensure...
     * ...transcriptions follow the correct historic sequence
     */
    case 'UPDATE_TRANSCRIPTION_PARENT_ID':
      if (action.parentContributionId) id = [action.parentContributionId]
      else {
        if (action.objectId) current = action.objectId
        else
          current =
            state.currentActiveTranscription?.['targetObjectId'] ||
            state.currentActiveTranscription?.[
              'transcription.targetObjectId'
            ] ||
            null
        if (!current || !state.allTranscriptions) return state
        id = state.allTranscriptions
          ?.map((transcription) => {
            if (
              (transcription.targetObjectId || transcription.targetObjectId) ==
              current
            ) {
              return transcription.parentContributionId
            }
          })
          .filter((transcription) => transcription)
      }
      return {
        ...state,
        parentContributionId: id[0], //Make sure only one value is saved
      }

    case 'UPDATE_CURRENT_TRANSCRIPTION_ERROR':
      return {
        ...state,
        transcriptionError: action.transcriptionError,
      }

    case 'UPDATE_TRANSCRIPTION_EDIT_REQUEST_PENDING':
      return {
        ...state,
        transcriptionEditRequestIsPending:
          action.transcriptionEditRequestIsPending,
      }

    case 'UPDATE_CURRENT_TRANSCRIPTION_IS_CANCELING':
      return {
        ...state,
        transcriptionIsCanceling: action.transcriptionIsCanceling,
      }

    case 'UPDATE_CURRENT_TRANSCRIPTION_IS_PUBLISHING':
      return {
        ...state,
        transcriptionIsPublishing: action.transcriptionIsPublishing,
      }

    case 'UPDATE_CURRENT_TRANSCRIPTION_IS_SAVED':
      return {
        ...state,
        transcriptionIsSaved: action.transcriptionIsSaved,
      }

    case 'UPDATE_CURRENT_TRANSCRIPTION_IS_SAVING':
      return {
        ...state,
        transcriptionIsSaving: action.transcriptionIsSaving,
      }

    case 'UPDATE_CURRENT_USER_IS_EDITING':
      return {
        ...state,
        loggedInUserIsEditing: action.loggedInUserIsEditing,
      }

    case 'UPDATE_CURRENT_USER_TRANSCRIPTION_TIMEOUT':
      return {
        ...state,
        userHasTimedOut: action.userHasTimedOut,
      }

    default:
      return state
  }
}

//Helpers
const returnCurrentActiveTranscriptionFromId = (objectId, all) => {
  let current = []
  if (objectId)
    current = all
      .filter((transcription) => {
        return (
          parseInt(transcription.targetObjectId) === parseInt(objectId) &&
          transcription?.status === 'active'
        )
      })
      .sort((a, b) => compareDates(a.createdAt, b.createdAt))[0]
  return current
}

const returnCurrentTranscriptionFromId = (objectId, all) => {
  let current = []
  if (objectId)
    current = all
      .filter((transcription) => {
        return (
          parseInt(transcription.targetObjectId) === parseInt(objectId) &&
          transcription.status != 'inactive'
        )
      })
      .sort((a, b) =>
        compareDates(
          a.updatedAt || a.createdAt || a.responseTimestamp,
          b.updatedAt || b.createdAt || b.responseTimestamp
        )
      )[0]
  return current
}

/**
 * Transcription versions with status of "active" indicates...
 * ...the transcription is displayed to all users as the...
 * ...most recent published version
 *
 * Transcriptions that are locked, i.e. users other than the...
 * ...active editor cannot edit it, still display the latest active version...
 * ...to all non-editing users.
 */
const returnActiveObjectIdArray = (transcriptions) => {
  if (!transcriptions) return null
  let activeIds = transcriptions
    .map((transcription) => {
      if (transcription?.status === 'active') {
        return parseInt(transcription.targetObjectId)
      }
    })
    .filter((transcription) => {
      if (transcription) return transcription
    })
  //Create an array of unique objectIds
  return [...new Set(activeIds)]
}

/**
 * Transcription versions with status of "draft" indicates...
 * ...a transcription is being actively edited, and thus...
 * ...should be locked to all other users
 *
 * Transcriptions are locked to all users as soon as...
 * ...a single user has chosen to add/edit an unlocked transcription.
 *
 * While that user is actively editing the transcription, and as long as...
 * ...no pause exceeds the predetermined auto-unlock time limit,...
 * ...the transcription will remain locked.
 */
const returnLockedObjectIdArray = (transcriptions) => {
  let userId = null
  if (localStorage.getItem('loggedInUser')) {
    userId = JSON.parse(localStorage.getItem('loggedInUser')).userId
  }
  if (!transcriptions) return null
  let lockedIds = []
  let groups = transcriptions.reduce(
    (groups, item) => ({
      ...groups,
      [item.targetObjectId]: [...(groups[item.targetObjectId] || []), item],
    }),
    {}
  )
  Object.values(groups).map((value) => {
    value.sort((a, b) =>
      compareDates(
        a.updatedAt || a.createdAt || a.responseTimestamp,
        b.updatedAt || b.createdAt || b.responseTimestamp
      )
    )
    // only lock transcription if it isn't the current users
    if (value[0].status === 'draft' && value[0].userId !== userId)
      lockedIds.push(parseInt(value[0].targetObjectId))
  })
  //Create an array of unique objectIds
  return [...new Set(lockedIds)]
}
