import React from 'react'
import '../../../styles/index.scss'
import { Button } from '../../Button/Button'
import HelpCenterWrapper from './help-center-wrapper'
import { help } from './help-center-data/help-center-full-data'
import Card from '../../Card/Card'
import { Link } from 'react-router-dom'
import { Icon } from '../../Icon/Icon'
import { IconLabel } from '../../IconLabel/IconLabel'
import { ListSeparator } from '../../ListSeparator/ListSeparator'

const HelpCenter = ({ ...props }) => {
  return (
    <HelpCenterWrapper>
      {help.pages.map((h) => (
        <Card className="padding-0 overflow-hidden" reduced key={h.id}>
          <div
            className={['display-flex', 'flex-row', 'flex-gap-sm'].join(' ')}
          >
            <div
              className={[
                'height-auto',
                'maxw-mobile-lg',
                'position-relative',
                'width-auto',
              ].join(' ')}
            >
              <img
                className={[
                  'height-full',
                  'object-fit-cover',
                  'vertical-align-middle',
                  'width-full',
                ].join(' ')}
                src="/images/misc/35810078.png"
              />
              <Card
                className={[
                  'bottom-2',
                  'font-sans-2xs',
                  'maxw-card-lg',
                  'position-absolute',
                  'right-2',
                  'width-auto',
                ].join(' ')}
                reduced
              >
                <div
                  className={[
                    'display-flex',
                    'flex-align-center',
                    'flex-gap-sm',
                    'flex-row',
                  ].join(' ')}
                >
                  <Icon iconName="image" iconSize="xs" />
                  <Link
                    className="ellipsed"
                    title="Photograph of a National Archives Consultant Helping a Researcher"
                    data-testid="background-image-link"
                    to={{
                      pathname: `/id/35810078`,
                    }}
                  >
                    Photograph of a National Archives Consultant Helping a
                    Researcher
                  </Link>
                </div>
              </Card>
            </div>
            <div
              className={[
                'display-flex',
                'flex-align-start',
                'flex-gap-sm',
                'flex-column',
                'padding-x-2',
                'padding-y-4',
              ].join(' ')}
            >
              <h2
                className={[
                  'display-inline-flex',
                  'flex-align-center',
                  'flex-gap-sm',
                  'flex-row',
                ].join(' ')}
              >
                <IconLabel
                  iconName="circle-questions"
                  strokeThickness="thick"
                  theme="white"
                >
                  <span className={['font-sans-lg'].join(' ')}>
                    Frequently Asked Questions
                  </span>
                </IconLabel>
              </h2>
              <ul className={['add-list-reset' /*'margin-left-4'*/].join(' ')}>
                {h.pages.map((p) => (
                  <li
                    key={p.id}
                    className={[
                      'display-flex',
                      'flex-gap-sm',
                      'flex-column',
                    ].join(' ')}
                  >
                    {/*<h3>{p.title}</h3>*/}
                    <ul
                      className={[
                        'add-list-reset',
                        'display-flex',
                        'flex-gap',
                        'flex-column',
                        'font-sans-sm',
                        // 'margin-left-2',
                      ].join(' ')}
                    >
                      {p.sections.map((s) => (
                        <>
                          {/*
                      <li key={s.id}>{s.title}</li>*/}
                          {s.questions.map((q) => (
                            <>
                              <li key={q.id}>
                                <Link
                                  to={{
                                    pathname: `${h.id}/${p.id}`,
                                    hash: q.id,
                                  }}
                                >
                                  {q.question}
                                </Link>
                              </li>
                            </>
                          ))}
                        </>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Card>
      ))}
    </HelpCenterWrapper>
  )
}

HelpCenter.defaultProps = {}

HelpCenter.propTypes = {}

export default HelpCenter
