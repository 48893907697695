import { loginSteps } from '../HowToPage/how-to-data/how-to-login'
import { searchSteps } from '../HowToPage/how-to-data/how-to-search'

/**
 * This is where we aggregate each How To section into a consolidated How To page object array.
 * Here we provide top-level details that will be displayed on the main How To page,...
 * ...as well as identify which sections to include.
 * Any individual sections flagged to be highlighted in the how-to data...
 * ...(such as the most frequently used how-tos) will be aggregated at the top level here,...
 * ...along with the overall word count, and time to read.
 */

export const howto = {
  breadcrumb: 'How-To',
  icon: 'book',
  id: 'how-to',
  pages: [],
  title: 'How-To',
}

/**
 * @param {string} breadcrumbs Label displayed in the breadcrumbs at the top of the How To page
 */
howto.pages.push(
  {
    breadcrumb: 'Accounts',
    component: 'howto',
    description: 'See how to set up your account and adjusts your settings.',
    icon: 'name-tag',
    id: 'accounts',
    sections: loginSteps,
    title: 'Accounts',
  },
  {
    breadcrumb: 'Search',
    component: 'howto',
    icon: 'search',
    id: 'search',
    sections: searchSteps,
    title: 'Search',
  },
  {
    breadcrumb: 'Contribute',
    component: 'howto',
    icon: 'transcription',
    id: 'contribute',
    sections: loginSteps,
    title: 'Contribute',
  }
)

// Add additional information to each faq object for display
let totalWordCount = 0

const countWords = (content) => {
  let wordCount = 0
  if (typeof content === 'string')
    wordCount += content.trim().split(/\s+/).length
  else if (typeof content === 'object')
    [content].map((cont) => {
      if (typeof cont === 'string') wordCount += cont.trim().split(/\s+/).length
      else
        [cont.props.children].map((c) => {
          if (typeof c === 'string') wordCount += c.trim().split(/\s+/).length
          else if (typeof c.props?.children === 'string')
            wordCount += c.props?.children.trim().split(/\s+/).length
          else c.props?.children.map((c2) => {})
        })
    })
  return wordCount
}

const returnTimeToRead = (wordCount) => {
  const timeDecimal = Math.round(wordCount / 200)
  if (timeDecimal > 0) return `${timeDecimal} minute read`
  else return 'quick read'
  // const timeArray = timeDecimal.toString().split('.')
  // return `${timeArray.length > 1 && `${timeArray[0]} minute, `}${
  //   timeArray.length > 1 ? `${timeArray[1]} second` : `${timeArray[0]} second`
  // } read`
}

howto.pages.map((group) => {
  totalWordCount = 0
  totalWordCount += countWords(group.label)
  totalWordCount += countWords(group.description)
  group.sections?.map((section) => {
    totalWordCount += countWords(section.title)
    totalWordCount += countWords(section.description)
    totalWordCount += countWords(section.aside)
    section.steps?.map((step, index) => {
      step.id = `step-${index + 1}`
      totalWordCount += countWords(step.title)
      totalWordCount += countWords(step.description)
      totalWordCount += countWords(step.aside)
    })
  })
  group.totalWordCount = totalWordCount
  group.timeToRead = returnTimeToRead(totalWordCount)
})
