import React, { useContext, Fragment, useEffect, useRef, useState } from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import UserContext from '../../../../contexts/UserContext'
import ContributionContext from '../../../../contexts/ContributionContext'
//Components
import Tooltip from '../../../Tooltip/Tooltip'
import { Icon } from '../../../Icon/Icon'
import { Pill } from '../../../Pill/Pill'
//Helpers
import { timeSince } from '../../../../helper-functions/date-functions'
import ScrollContext from '../../../../contexts/ScrollContext'
import RecordContext from '../../../../contexts/RecordContext'
import SearchContext from '../../../../contexts/SearchContext'
//Hooks
import useHttp from '../../../../hooks/use-http'
//Settings
import { SEARCH_URL } from '../../../settings/globals'
import { useNavigate } from 'react-router-dom'
import StaffPill from '../../../Pill/variants/StaffPill'
import StaticPill from '../../../Pill/variants/StaticPill'

const Tag = ({ tag, wrapper, ...props }) => {
  const contributionContext = useContext(ContributionContext)
  const recordContext = useContext(RecordContext)
  const scrollContext = useContext(ScrollContext)
  const searchContext = useContext(SearchContext)
  const { loggedInUser, accountsAccessArray, displayAdminWorkbench } =
    useContext(UserContext)

  const ref = useRef()
  const navigate = useNavigate()
  const [active, setActive] = useState(false)

  const id = recordContext?.activeContributionId || null

  useEffect(() => {
    if (!ref.current) return false
    if (!id) return false
    const element = document.getElementById(id)
    if (tag.contributionId === id && element) {
      const handler1 = setTimeout(() => {
        let isObject = false
        if (tag.targetObjectId) {
          scrollContext.handleScrollTo('preview')
          isObject = true
        }

        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: isObject ? 'nearest' : 'center',
            inline: isObject ? 'start' : 'center',
          })
          setActive(true)
        }, 500)
      }, 500)
      return () => {
        clearTimeout(handler1)
      }
    }
  }, [ref, id])

  const loggedInUserTag =
    loggedInUser?.userId === tag.userId ||
    (accountsAccessArray.accessGrantedToAccountAdminTools &&
      tag.madeByAdministrator)

  const handleRemoveTag = () => {
    contributionContext.tagsDispatch({
      type: 'REMOVE_TAG',
      contributionId: tag.contributionId,
    })
  }

  const { sendRequest: removeTagRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/contributions?remove=true`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        actionNotes:
          accountsAccessArray.accessGrantedToAccountAdminTools &&
          tag.madeByAdministrator === 1
            ? `Admin with ID ${loggedInUser?.userId} removed admin tag.`
            : 'User deleted their own tag.',
        contributionId: tag.contributionId,
        userId: loggedInUser?.userId,
      },
    },
    handleRemoveTag
  )

  // const handleKeypress = (event) => {
  //   if (event.key === 'Enter') {
  //     handleRemoveTag()
  //   }
  // }

  return (
    <Tooltip
      content={[
        <Fragment key={tag.contributionId}>
          <span className="usa-sr-only">Created by </span>
          {tag.madeByAdministrator === 1
            ? 'NARA Citizen Archivist'
            : tag.contributor
            ? tag.contributor.partnerFullName
            : tag.userName}{' '}
          {!tag.contributor && tag.naraStaff ? <StaffPill /> : ''}
          {tag.contributor ? <StaticPill label={'NARA Partner'} /> : ''}
          {tag.aiMachineGenerated ? (
            <StaticPill label={'AI / Machine-Generated'} />
          ) : (
            ''
          )}
          {', '}
          {timeSince(tag.createdAt)}
        </Fragment>,
      ]}
      direction="bottom"
      wrapper={wrapper}
    >
      <Pill
        aria-label={`${tag.contribution} by ${tag.userName}`}
        aria-live="assertive"
        className={[
          'margin-bottom-1',
          'margin-right-1',
          active ? 'active-highlight' : '',
          'highlightable',
        ].join(' ')}
        iconName="tag"
        id={tag.contributionId}
        ref={ref}
        role="complementary"
        size="xs"
        tabIndex="0"
        theme={loggedInUserTag ? 'primary-dark' : 'base'}
        thin
      >
        <span className="display-inline-flex">
          <a
            aria-label={`Search for all records with the tag ${tag.contribution}`}
            className={`text-${
              loggedInUserTag ? 'primary-dark' : 'base-darkest'
            }`}
            data-testid="nac-tags_tag-link"
            href="#"
            onClick={() => {
              navigate(
                {
                  pathname: '/search',
                  search: `page=1&contribution=${encodeURIComponent(
                    tag.contribution
                  )}&contributionType=tag`,
                },
                { replace: true }
              )
              searchContext.handleActiveQueryParams()
            }}
            tabIndex="0"
          >
            <span
              className={[
                'display-inline-flex',
                loggedInUserTag ? 'text-primary-dark' : 'text-base-darker',
              ].join(' ')}
            >
              <span className="usa-sr-only">The tag: </span>
              {tag.contribution}
            </span>
          </a>
          {loggedInUserTag ||
          (accountsAccessArray.accessGrantedToAccountAdminTools &&
            tag.madeByAdministrator) ? (
            <a
              tabIndex="0"
              onClick={() => {
                if (loggedInUser.userId) {
                  if (
                    accountsAccessArray.accessGrantedToAccountAdminTools &&
                    tag.madeByAdministrator === 1
                  )
                    removeTagRequestHandler()
                  else {
                    confirm(
                      'Are you sure you want to delete this tag?\n\n"' +
                        tag.contribution +
                        '"'
                    )
                      ? removeTagRequestHandler()
                      : ''
                  }
                }
              }}
              //onKeyPress={(event) => handleKeypress(event)}
              className="clickable display-flex flex-align-center margin-left-1"
              title="click to remove tag"
            >
              <Icon iconName="close" iconSize="xs" />
            </a>
          ) : (
            ''
          )}
        </span>
      </Pill>
    </Tooltip>
  )
}

Tag.defaultProps = {
  tag: {},
}

Tag.propTypes = {
  tag: PropTypes.object.isRequired,
  wrapper: PropTypes.object,
}

export default Tag
