import { faq } from './faq-full-data'
import { howto } from './how-to-full-data'

/**
 * This is where we aggregate ...
 */

export const help = { breadcrumb: 'Help', id: 'help', pages: [] }

help.pages.push(faq) //,howto
