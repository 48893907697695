import React, { useContext, useEffect, useRef, useState } from 'react'
import '../../../styles/index.scss'
import { Button } from '../Button'
import ClickAwayListener from 'react-click-away-listener'
import PropTypes from 'prop-types'
import Popover, { PopoverBody } from '../../Popover/Popover'
import RadioButtonField from '../../form/RadioButtonField'
import ExportContext from '../../../contexts/ExportContext'
import CheckboxField from '../../form/CheckboxField'
import { Icon } from '../../Icon/Icon'

export const SelectOptionsButton = ({
  className,
  results,
  total,
  ...props
}) => {
  const [active, setActive] = useState(false)
  const popoverRef = useRef(null)
  const [buttonHeight, setButtonHeight] = useState(0)
  const buttonRef = useRef(null)

  useEffect(() => {
    if (buttonRef?.current && !buttonHeight)
      setButtonHeight(buttonRef.current?.clientHeight)
  }, [buttonRef])

  const handleClickAway = () => {
    if (active) {
      setActive(false)
    }
  }

  const {
    allChecked,
    naIdsToIncludeInExport,
    naIdsToExcludeFromExport,
    clearCheckedHandler,
    setAllCheckedHandler,
    setNaIdsToIncludeInExportHandler,
  } = useContext(ExportContext) || {}

  return (
    <div
      className={[
        // 'nowrap',
        'display-flex',
        'flex-row',
        'flex-align-center',
        'flex-gap-sm',
        'position-relative',
        props.className,
      ].join(' ')}
    >
      <div className={['display-flex', 'flex-gap-0', 'flex-row'].join(' ')}>
        <div>
          <Button
            className={[
              'margin-right-neg-1px',
              'radius-left-md',
              'height-full',
            ].join(' ')}
            onClick={() => {
              setAllCheckedHandler(!allChecked)
            }}
            outline
            reduced
            shape="0"
            size="2xs"
            theme="base-light"
            thin
          >
            <CheckboxField
              checked={
                (allChecked && naIdsToExcludeFromExport.length === 0) ||
                naIdsToIncludeInExport.length === total
              }
              className={[
                'font-sans-2xs',
                'padding-left-3',
                'padding-top-2px',
                'text-bold',
                'text-base-darker',
              ].join(' ')}
              label="Select All"
              indeterminate={
                (allChecked &&
                  naIdsToExcludeFromExport.length > 0 &&
                  naIdsToExcludeFromExport.length !== total) ||
                (!allChecked &&
                  naIdsToIncludeInExport.length > 0 &&
                  naIdsToIncludeInExport.length < total)
                //||
                // naIdsToIncludeInExport.length < 10000
              }
              // label={<span className="usa-sr-only">Select All</span>}
            />
          </Button>
        </div>
        <div>
          <Button
            active={active}
            className={['radius-right-md', 'height-full'].join(' ')}
            data-testid="nac-objects--select-options-search-button"
            onClick={() => setActive(!active)}
            ref={buttonRef}
            srText="Select Options ..."
            outline
            reduced
            shape="0"
            size="2xs"
            theme="base-light"
            thin
          >
            <Icon color="base-darker" iconName="option2" iconSize="xs" />
          </Button>
        </div>
      </div>

      {active && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popover
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="width-card-lg"
            hide={() => setActive(false)}
            id="select-options"
            position={[{ left: 0, top: buttonHeight - 10 }]}
            ref={popoverRef}
            tabIndex={0}
            title="Select Options"
          >
            <>
              <PopoverBody
                className="flex-gap-sm padding-1"
                id="select-options-body"
              >
                <Button
                  className="width-full"
                  data-testid="nac-select-export-current"
                  onClick={() => {
                    setNaIdsToIncludeInExportHandler(
                      results.map((a) => {
                        return a.naId
                      })
                    )
                    setActive(false)
                  }}
                  id="current"
                  reduced
                  textAlign="left"
                  textOnly
                  thin
                >
                  Current Page
                </Button>
                <Button
                  className="width-full"
                  data-testid="nac-select-export-all"
                  onClick={() => {
                    setAllCheckedHandler(true)
                    setNaIdsToIncludeInExportHandler([], true)
                    setActive(false)
                  }}
                  id="full"
                  reduced
                  textAlign="left"
                  textOnly
                  thin
                >
                  All Results (Max 10,000)
                </Button>

                <Button
                  className="width-full"
                  onClick={() => {
                    clearCheckedHandler()
                    setActive(false)
                  }}
                  reduced
                  textAlign="left"
                  textOnly
                  thin
                >
                  Deselect All
                </Button>
              </PopoverBody>
            </>
          </Popover>
        </ClickAwayListener>
      )}
    </div>
  )
}

SelectOptionsButton.defaultProps = {}

SelectOptionsButton.propTypes = {
  className: PropTypes.string,
  results: PropTypes.array,
  total: PropTypes.number,
}
