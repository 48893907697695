import { useContext } from 'react'
import HighlighterContext from '../contexts/HighlighterContext'

const useHighlightList = (
  search,
  array,
  fields,
  initialCount = 5,
  sort = true
) => {
  const { displayHighlights } = useContext(HighlighterContext)

  if (!search || !array) return { sortedArray: array, count: initialCount }
  const quotedArray =
    search
      ?.match(/"(.*?)"/g)
      ?.map((v) => v.replace(/"/g, ''))
      ?.filter(function (v) {
        return v !== ''
      }) || []

  // Get individual remaining strings, but remove booleans and other modifiers
  let searchArray = []
  if (quotedArray.length > 0) {
    searchArray =
      search
        ?.replace(/"(.*?)"/g, '')
        ?.replace(/\s*(\b(and|or|\*)\b)\s*/gi, ' ')
        ?.replace(/\s*(\b(not)\b)\s*\w*\s*/gi, ' ')
        ?.split(' ')
        ?.filter(function (v) {
          return v !== ''
        }) || []
  } else
    searchArray =
      search
        ?.replace(/\s*(\b(and|or|\*)\b)\s*/gi, ' ')
        ?.replace(/\s*(\b(not)\b)\s*\w*\s*/gi, ' ')
        ?.split(' ') || []

  const combinedTerms = [...quotedArray, ...searchArray]

  let highlightedArray = []
  let index = 0
  let newArray =
    array?.filter((a, i) => {
      let matched = false
      combinedTerms.map((t) => {
        if (
          (fields &&
            fields?.filter((f) => a[f]?.indexOf(t) > -1)?.length > 0) ||
          (typeof a == 'string' && a?.indexOf(t) > -1)
        ) {
          highlightedArray.push(a)
          matched = true
          index = i
        }
      })
      if (!sort || (sort && !matched)) return a
    }) || []

  highlightedArray = fields
    ? [
        ...new Map(
          highlightedArray?.map((item) => [item[fields?.[0]], item])
        ).values(),
      ]
    : [...new Set(highlightedArray)]

  let sortedArray = []
  if (sort) sortedArray = [...highlightedArray, ...newArray]
  else sortedArray = newArray

  let count = initialCount

  if (!sort) {
    if (index + 1 > initialCount) count = index + 1
  } else if (highlightedArray.length > initialCount)
    count = highlightedArray.length
  return {
    sortedArray: displayHighlights ? sortedArray : array,
    count: displayHighlights ? count : initialCount,
  }
}

export default useHighlightList
