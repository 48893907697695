import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../../../styles/index.scss'
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs'
import { help } from './help-center-data/help-center-full-data'
import FaqPage from './FaqPage/FaqPage'
import HelpCenterWrapper from './help-center-wrapper'
import HowToPage from './HowToPage/HowToPage'

const HelpCenterSection = ({ ...props }) => {
  const { pageId, sectionId } = useParams()
  const [pageContent, setPageContent] = useState(null)
  const [sectionContent, setSectionContent] = useState(null)

  const components = {
    faq: FaqPage,
    howto: HowToPage,
  }

  const returnComponent = (block) => {
    if (typeof components[block.component] !== 'undefined') {
      return React.createElement(components[block.component], {
        key: block._uid,
        data: block,
      })
    }
    return React.createElement(
      () => (
        <div>The component {block.component} has not been created yet.</div>
      ),
      { key: block._uid }
    )
  }

  useEffect(() => {
    if (pageId && sectionId) {
      const thisPage = help.pages.filter((page) => {
        return page.id === pageId
      })[0]
      setPageContent(thisPage)

      const thisSection = thisPage.pages.filter((page) => {
        return page.id === sectionId
      })[0]
      setSectionContent(thisSection)
    }
  }, [pageId, sectionId])

  return (
    sectionContent &&
    pageContent && (
      <HelpCenterWrapper>
        {/** Breadcrumbs */}
        <Breadcrumbs
          crumbs={[
            { title: 'Help', link: '/help' },
            { title: pageContent.breadcrumb, link: `/help/${pageId}` },
            { title: sectionContent.breadcrumb },
          ]}
          delimeter="forward-slash"
        />

        {returnComponent(sectionContent)}
        {/*pageId === 'faq' && <FaqPage data={pageContent} />*/}
      </HelpCenterWrapper>
    )
  )
}

HelpCenterSection.defaultProps = {}

HelpCenterSection.propTypes = {}

export default HelpCenterSection
