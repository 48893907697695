import React, { Fragment, useContext } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import '../../../styles/index.scss'
import {
  DEFAULT_CONTRIBUTION_FEED_LIMIT,
  DEFAULT_CONTRIBUTION_TABLE_LIMIT,
  IIIF_PATH_MD,
} from '../../settings/globals'
//Components
import Card from '../../Card/Card'
import { Button } from '../../Button/Button'
//Hooks
import useActivityFeed from './use-activity-feed'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
import Avatar from '../../Avatar/Avatar'
import AccountContext from '../../../contexts/AccountContext'
import {
  returnHumanDateFromTimestamp,
  timeSince,
} from '../../../helper-functions/date-functions'
import { returnIconAndLabelFromString } from '../../../helper-functions/metadata-functions'
import { Pill } from '../../Pill/Pill'
import Prose from '../../utilities/Prose'
import ExpandCollapseString from '../../utilities/ExpandCollapseString'
import {
  convertCamelToSentence,
  convertImageUrlToIiif,
} from '../../../helper-functions/string-functions'
import CommentStatusEdited from '../../description/contributions/comments/comment_status_edited'
import ExpandCollapseList from '../../utilities/ExpandCollapseList'
import { IconLabel } from '../../IconLabel/IconLabel'
import StaffPill from '../../Pill/variants/StaffPill'
import { contributionSortDropdownSchema } from '../../form/Dropdown/dropdown-schemas'
import Tooltip from '../../Tooltip/Tooltip'
import { Table } from '../../Table/Table'
import useContributionTable from './use-contribution-table'
import useScreenSize from '../../../hooks/use-screen-size'
import { DropdownSort } from '../../form/Dropdown/variants/DropdownSort'
import { Icon } from '../../Icon/Icon'
import Highlighter from '../../Highlighter/Highlighter'

export const ActivityFeed = () => {
  const accountState = useContext(AccountContext)
  const { feedPage } = useParams()

  const {
    changeHandler,
    displayLoadMore,
    enteredSearch,
    feed,
    page,
    loadingFeed,
    loadingMore,
    loadMoreHandler,
    loadingRecords,
    loadingSearched,
    setFeedPageHandler,
    submitHandler,
    submittedValue,
    totalDisplayed,
    totalResults,
    view,
    viewClickHandler,
  } = useActivityFeed()
  const { screenSize } = useScreenSize()

  return (
    <>
      <div
        className={[
          'display-flex',
          'flex-gap',
          'flex-column',
          'width-full',
        ].join(' ')}
      >
        {accountState?.contributionTotals?.totals?.[
          `total${
            feedPage === '' ? 'Contributions' : convertCamelToSentence(feedPage)
          }`
        ] === 0 ? (
          `No ${feedPage ? feedPage : 'contributions'}.`
        ) : (
          <>
            <div
              className={['display-flex', 'flex-gap-0', 'flex-column'].join(
                ' '
              )}
            >
              <div
                className={[
                  'display-flex',
                  'flex-align-center',
                  'flex-gap-0 tablet-lg:flex-gap',
                  'flex-column tablet-lg:flex-row',
                  'margin-bottom-0',
                  'margin-right-auto',
                  'width-full',
                ].join(' ')}
              >
                <h2
                  className={[
                    'display-flex',
                    'flex-align-center',
                    'flex-gap-sm',
                    'flex-row',
                    'margin-bottom-0',
                    'margin-right-auto',
                  ].join(' ')}
                  id="search-label"
                >
                  Search latest {feedPage || 'contributions'}
                  <Tooltip
                    content={`Search for ${
                      feedPage || 'contributions'
                    } with specific text or by NAID.`}
                    delay={500}
                    display="block"
                    id={`#${feedPage}description`}
                    position="topCenter"
                  >
                    <Button
                      align="center"
                      iconName="circle-questions"
                      iconSize="xs"
                      reduced
                      shape="pill"
                      srText="Contribution search details"
                      textOnly
                      theme="base-dark"
                    />
                  </Tooltip>
                </h2>
              </div>

              <div
                className={[
                  'display-flex',
                  'flex-gap-sm',
                  'flex-justify',
                  'flex-column desktop-lg:flex-row',
                ].join(' ')}
              >
                <form
                  className={[
                    'display-flex',
                    'flex-align-start',
                    'flex-column',
                    'width-full',
                  ].join(' ')}
                  onSubmit={submitHandler}
                >
                  <label
                    className={[
                      'display-flex',
                      'flex-align-center',
                      'flex-no-wrap',
                      'flex-row',
                      'position-relative',
                      'width-full desktop-lg:width-mobile',
                    ].join(' ')}
                  >
                    <input
                      aria-labelledby="search-label"
                      className={[
                        'usa-input',
                        'bg-white',
                        'border-base-light',
                        'border-1px',
                        'display-inline-block',
                        'margin-top-0',
                        'maxw-full',
                        'minh-5',
                        'padding-105',
                        'padding-left-4',
                        'radius-md',
                        'width-full',
                      ].join(' ')}
                      defaultValue={enteredSearch}
                      id="contributionSearch"
                      name="contributionSearch"
                      onChange={changeHandler}
                      type="search"
                    />
                    <Icon
                      className="left-2px position-absolute"
                      color="base"
                      iconName="search"
                      iconPercent="60"
                      iconSize="md"
                      onClick={submitHandler}
                    />
                  </label>
                </form>
                <div
                  className={[
                    'display-flex',
                    screenSize !== 'desktop' ? 'flex-wrap' : '',
                    'flex-align-center',
                    'flex-gap-sm',
                    'flex-row',
                    'margin-left-auto',
                  ].join(' ')}
                >
                  <div className="display-flex">
                    <DropdownSort
                      id="contribution"
                      schema={contributionSortDropdownSchema}
                    />
                  </div>
                  <div className="display-flex">
                    <form className={['margin-left-auto'].join(' ')}>
                      <label
                        className={[
                          'nowrap',
                          'display-flex',
                          'flex-align-center',
                          'flex-gap-sm',
                          'flex-row',
                          'margin-top-neg-1 margin-bottom-neg-1',
                          'padding-left-1',
                          'padding-right-05',
                          'text-base-dark',
                          'text-normal',
                          'font-sans-2xs',
                        ].join(' ')}
                        htmlFor="id"
                      >
                        View:{' '}
                        <div
                          className={[
                            'nowrap',
                            'display-flex',
                            'flex-row',
                            'flex-align-center',
                          ].join(' ')}
                        >
                          <Button
                            className={['radius-left-md'].join(' ')}
                            iconName="layout-feed"
                            iconSize="xs"
                            onClick={() => viewClickHandler('feed')}
                            outline={view !== 'feed'}
                            reduced
                            shape="0"
                            size="2xs"
                            theme="base-darker"
                            thin
                          >
                            Feed
                          </Button>
                          <Button
                            className={['radius-right-md'].join(' ')}
                            iconName="layout-table"
                            iconSize="xs"
                            onClick={() => viewClickHandler('table')}
                            outline={view !== 'table'}
                            reduced
                            shape="0"
                            size="2xs"
                            theme="base-darker"
                            thin
                          >
                            Table
                          </Button>
                        </div>
                      </label>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <hr className={['border-base-lighter'].join(' ')} />
            {totalDisplayed === 0 ? (
              <p>
                No {feedPage || 'contributions'} returned for{' '}
                <strong>
                  <em>{submittedValue}</em>
                </strong>
              </p>
            ) : loadingSearched ? (
              'Searching...'
            ) : (
              <>
                {submittedValue && !loadingSearched && (
                  <div
                    className={[
                      'font-sans-xs',
                      'margin-right-auto',
                      'text-right',
                    ].join(' ')}
                  >
                    Showing{' '}
                    {totalResults < DEFAULT_CONTRIBUTION_FEED_LIMIT
                      ? returnNumberWithCommas(totalResults)
                      : `1–${
                          totalResults > page * DEFAULT_CONTRIBUTION_FEED_LIMIT
                            ? page * DEFAULT_CONTRIBUTION_FEED_LIMIT
                            : totalResults
                        } of
                    ${returnNumberWithCommas(totalResults)}`}{' '}
                    results for{' '}
                    <strong>
                      <em>{submittedValue}</em>
                    </strong>
                  </div>
                )}
                <Card
                  className={[
                    view === 'table' ? 'padding-0 tablet-lg:padding-0' : '',
                  ].join(' ')}
                >
                  {view === 'feed' &&
                    (loadingFeed ? (
                      <LoadingFeed />
                    ) : (
                      <>
                        {' '}
                        <div className="display-flex flex-gap flex-column">
                          {feed?.map((item, index) => (
                            <FeedItem
                              feed={feed}
                              item={item}
                              key={item?.contributionId + index}
                              index={index}
                              search={submittedValue || ''}
                            />
                          ))}
                        </div>
                        {loadingMore && <LoadingMore />}
                        {displayLoadMore && (
                          <div
                            className={[
                              'display-flex',
                              'flex-align-center',
                              'flex-column',
                              'padding-x-2',
                              'width-full',
                            ].join(' ')}
                          >
                            <Button
                              disabled={loadingRecords || loadingMore}
                              onClick={loadMoreHandler}
                              outline
                              reduced
                              size="2xs"
                              thin
                            >
                              Load more...
                            </Button>
                          </div>
                        )}
                      </>
                    ))}
                  {view === 'table' && (
                    <ContributionTable
                      data={feed}
                      loading={loadingMore}
                      pages={Math.ceil(
                        totalResults / DEFAULT_CONTRIBUTION_TABLE_LIMIT
                      )}
                      search={submittedValue || ''}
                      setPage={setFeedPageHandler}
                    />
                  )}
                </Card>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export const ContributionTable = ({
  data,
  loading,
  pages,
  search,
  setPage,
  ...props
}) => {
  const { columns, table } = useContributionTable(data, search)
  return (
    <Table
      className="padding-0"
      columns={columns}
      data={table}
      id="nac-user-contribution-table"
      loading={loading}
      pageCount={pages}
      setPage={setPage}
    />
  )
}

ContributionTable.propTypes = {
  /**
   * Latest response data
   */
  data: PropTypes.array,
  /**
   * Whether to show the table loading state
   */
  loading: PropTypes.bool,
  /**
   * Total number of pages based on the total results and the current display limit per page
   */
  pages: PropTypes.number,
  /**
   * Current submitted search value for highlighting
   */
  search: PropTypes.string,
  /**
   * The function yb which to set the current response page
   */
  setPage: PropTypes.func,
}

export const FeedItem = ({
  collapse,
  item,
  index,
  reduced,
  reducedDate,
  search,
  ...props
}) => {
  if (reduced && reducedDate !== false) reducedDate = true
  return item ? (
    <Card {...props}>
      <div className={['display-flex', 'flex-column', 'flex-gap'].join(' ')}>
        {item?.contributionType === 'comment' ? (
          <FeedComment item={item} reduced={reduced} />
        ) : (
          <>
            <FeedHeader
              item={item}
              reduced={reduced}
              reducedDate={reducedDate}
            />
            <FeedBody
              collapse={collapse}
              index={index}
              item={item}
              search={search}
            />
            {item?.objectUrl && item?.contributionType !== 'tag' && (
              <FeedImage item={item} />
            )}
          </>
        )}
      </div>
    </Card>
  ) : (
    ''
  )
}
FeedItem.defaultProps = {
  collapse: true,
  reduced: false,
}

FeedItem.propTypes = {
  /**
   * Whether to collapse long contributions or not
   */
  collapse: PropTypes.bool,
  item: PropTypes.object,
  index: PropTypes.number,
  reduced: PropTypes.bool,
  reducedDate: PropTypes.bool,
  /**
   * Current submitted search value for highlighting
   */
  search: PropTypes.string,
}

const FeedHeader = ({ children, item, reduced, reducedDate, ...props }) => {
  if (reduced && reducedDate !== false) reducedDate = true
  const { account } = useContext(AccountContext)
  return (
    <div
      aria-live="assertive"
      className={[
        'display-flex',
        'flex-gap-sm',
        'flex-row',
        reduced ? '' : 'margin-bottom-05 padding-x-2',
        'width-full',
      ].join(' ')}
      {...props}
    >
      <Avatar
        // isNaraStaff={item?.naraStaff === 1}
        userName={item?.userName}
        size={reduced ? 'xs' : 'sm'}
      />
      <div
        className={[
          'font-sans-3xs',
          reduced
            ? [
                'display-inline-flex',
                'flex-align-center',
                'flex-gap-xs',
                'flex-row',
                'flex-wrap',
              ].join(' ')
            : [
                'display-flex',
                'flex-column',
                'flex-gap-xs',
                'margin-top-neg-2px',
                'minh-5',
                'width-full',
              ].join(' '),
          'text-base-dark',
        ].join(' ')}
      >
        <span
          className={[
            'font-sans-2xs',
            reduced
              ? 'margin-right-1 text-base-dark'
              : 'ellipsed-line-2 text-base-darker',
          ].join(' ')}
        >
          <UserNameDisplay user={item} reduced={reduced} showPills={!reduced} />
          {!reduced && (
            <>
              {item &&
              ((item?.contributionType === 'transcription' &&
                item?.parentContributionId !== item?.contributionId) ||
                item?.createdAt !== item?.updatedAt)
                ? item?.contributionType === 'comment' &&
                  item?.parentContributionId
                  ? ' updated a reply to '
                  : ' updated '
                : item?.contributionType === 'comment' &&
                  item?.parentContributionId
                ? ' replied to '
                : ' added '}
              {item?.parentComment?.length > 0 &&
              item?.parentComment[0].userId === account?.userId ? (
                'their own comment '
              ) : (
                <>a {item?.contributionType} </>
              )}
              on{' '}
              {item?.targetPageNum
                ? `object ${item?.targetPageNum} of ${item?.level ? 'the' : ''} 
               `
                : ''}
              <Link
                to={`/id/${item?.targetNaId}?${
                  item?.targetObjectId
                    ? `objectPanel=${item?.contributionType}&contributionId=${item?.contributionId}`
                    : `contributionId=${item?.contributionId}`
                }`}
              >
                {item?.level
                  ? `${returnIconAndLabelFromString(item?.level).label}: `
                  : ''}
                {item?.recordTitle
                  ? item?.recordTitle
                  : item?.targetObjectId
                  ? 'this digital object'
                  : 'this record'}
              </Link>
            </>
          )}
        </span>

        <span className={['font-sans-3xs'].join(' ')}>
          {!reducedDate &&
            returnHumanDateFromTimestamp(
              item?.updatedAt || item?.createdAt
            )}{' '}
          ({timeSince(item?.updatedAt || item?.createdAt)})
        </span>

        {children}
      </div>
    </div>
  )
}

FeedHeader.defaultProps = {
  reduced: false,
}

FeedHeader.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * The individual contribution to display
   */
  item: PropTypes.object,
  /**
   * Changes the spacing and sizes of the display
   */
  reduced: PropTypes.bool,
  reducedDate: PropTypes.bool,
}

export const FeedBody = ({ collapse, index, item, search, ...props }) => {
  return (
    <>
      <div
        className={[
          'display-flex',
          'flex-align-start',
          'flex-column',
          'flex-gap',
          'minw-0',
          'padding-x-2',
        ].join(' ')}
      >
        {item?.contributionType === 'tag' && (
          <Pill
            className={['margin-right-1', 'minh-4', 'padding-x-1'].join(' ')}
            iconName="tag"
            reduced
            size="xs"
            tabIndex="0"
            theme="base-light"
            thin
          >
            <span className="display-inline-flex text-base-darker">
              <span className="usa-sr-only">The contribution: </span>
              <Highlighter search={search}>{item?.contribution}</Highlighter>
            </span>
          </Pill>
        )}

        {item?.contributionType === 'transcription' && (
          <Prose
            className={[
              'bg-base-lightest',
              'padding-2',
              'radius-md',
              'width-full',
            ].join(' ')}
          >
            <ExpandCollapseString
              disabled={!collapse}
              bg="base-lightest"
              more={`show all ${
                item?.contribution?.split('\n')?.length
              } lines...`}
              less="show less..."
            >
              <ul className="display-flex flex-column new-lines flex-gap-sm">
                {item?.contribution.split('\n').map((cont, i) => (
                  <li
                    className={['new-line', 'numbered'].join(' ')}
                    key={cont + index + i}
                  >
                    <Highlighter search={search}>{cont}</Highlighter>
                  </li>
                ))}
              </ul>
            </ExpandCollapseString>
          </Prose>
        )}
      </div>
    </>
  )
}

FeedBody.defaultProps = {
  collapse: true,
}

FeedBody.propTypes = {
  /**
   * Whether to collapse long contributions or not
   */
  collapse: PropTypes.bool,
  /**
   * Index of the feed item
   */
  index: PropTypes.number,
  /**
   * The individual contribution to display
   */
  item: PropTypes.object,
  /**
   * Current submitted search value for highlighting
   */
  search: PropTypes.string,
}

const FeedImage = ({ item, ...props }) => {
  if (!item?.objectUrl) return false

  return (
    <div
      className={[
        'display-flex',
        'flex-column',
        'flex-gap',
        'flex-grow',
        'flex-justify-stretch',
        'width-full',
      ].join(' ')}
      {...props}
    >
      <div
        className={[
          'bg-primary-lighter',
          'position-relative',
          'display-flex',
          'flex-column',
          'nac-icon',
          'nac-icon',
          'nac-icon--primary-light',
          'nac-icon--thick',
          'height-card-lg',
          'nac-icon--bg-10',
          `nac-icon--${returnIconAndLabelFromString(item?.recordType).icon}`,
        ].join(' ')}
      >
        <img
          alt=""
          className={['height-full', 'object-fit-cover', 'width-full'].join(
            ' '
          )}
          loading="lazy"
          src={convertImageUrlToIiif(item?.objectUrl) + IIIF_PATH_MD}
        />
        <Pill
          className={['bottom-2', 'right-2', 'position-absolute'].join(' ')}
          filled
          iconName={returnIconAndLabelFromString(item?.objectType).icon}
          iconSize="xs"
          size="2xs"
          theme="white"
          thin
        >
          {`${item?.objectType} ${item?.objectNumber} of ${item?.objectsTotal}`}
        </Pill>
      </div>
    </div>
  )
}
FeedImage.propTypes = {
  /**
   * The individual contribution to display
   */
  item: PropTypes.object,
}

export const LoadingMore = () => {
  return (
    <div
      className={['display-flex', 'flex-column', 'flex-gap', 'width-full'].join(
        ' '
      )}
    >
      {[...Array(1)].map((e, i) => (
        <div
          className={['block-load', 'height-4', 'width-full'].join(' ')}
          key={i}
        ></div>
      ))}
    </div>
  )
}
export const LoadingFeed = () => {
  return (
    <div
      className={['display-flex', 'flex-column', 'flex-gap', 'width-full'].join(
        ' '
      )}
    >
      {[...Array(5)].map((e, i) => (
        <div
          className={['block-load', 'height-4', 'width-full'].join(' ')}
          key={i}
        ></div>
      ))}
    </div>
  )
}

const FeedComment = ({ item, reduced, ...props }) => {
  const { account } = useContext(AccountContext)
  /**
   * TO DO: Update userName once user table is JOINed in the response
   */
  const originalCommenterId = item?.parentComment?.[0]?.userId
  const originalCommenterDisplayName =
    item?.parentComment?.[0]?.userName ||
    item?.parentComment?.[0]?.userId.split('-')[0]
  let originalCommenterLink = []
  if (
    item?.parentComment?.length > 0 &&
    originalCommenterId !== account?.userId
  )
    originalCommenterLink.push(
      <Fragment key={originalCommenterId}>
        <Link to={`/accounts/${originalCommenterId}`}>
          <strong>{originalCommenterDisplayName}</strong>
        </Link>
        &#39;s comment on
      </Fragment>
    )

  return (
    <Fragment>
      <FeedHeader item={item} reduced={reduced} />
      {item?.objectUrl && item?.contributionType !== 'tag' && (
        <FeedImage item={item} />
      )}
      <div
        className={[
          'display-flex',
          'flex-align-start',
          'flex-column',
          'flex-gap',
          'minw-0',
          'padding-x-2',
        ].join(' ')}
      >
        {item?.parentComment?.length > 0 && (
          <FeedParentComment
            item={item?.parentComment[0]}
            key={item?.parentComment[0].contributionId}
          />
        )}
        <Prose className={['radius-md', 'height-full', 'width-full'].join(' ')}>
          {item?.contribution}
          {item?.createdAt !== item?.updatedAt && <CommentStatusEdited />}
        </Prose>
      </div>
      {item?.replies?.length > 0 && <FeedReplyComment item={item} />}
    </Fragment>
  )
}
FeedComment.propTypes = {
  /**
   * The individual contribution to display
   */
  item: PropTypes.object,
  reduced: PropTypes.bool,
}

const FeedParentComment = ({ item, ...props }) => {
  const { account } = useContext(AccountContext)
  /**
   * TO DO: Update userName once user table is JOINed in the response
   */
  return (
    <div
      className={[
        'bg-base-lightest-opacity-50',
        'border-1px',
        'border-base-lighter',
        'display-flex',
        'flex-align-center',
        'flex-gap-sm',
        'flex-row',
        'padding-1',
        'radius-md',
        'text-base-dark',
        'width-full',
      ].join(' ')}
      key={item?.contributionId}
      {...props}
    >
      <FeedHeader
        account={
          account.userId === item?.userId
            ? account
            : {
                userId: item?.userId,
              }
        }
        item={item}
        reduced
      >
        <Prose className={['font-sans-2xs'].join(' ')}>
          {item?.contribution}
          {item?.createdAt !== item?.updatedAt && <CommentStatusEdited />}
        </Prose>
      </FeedHeader>
    </div>
  )
}
FeedParentComment.propTypes = {
  /**
   * The individual contribution to display
   */
  item: PropTypes.object,
}

const FeedReplyComment = ({ item, ...props }) => {
  /**
   * TO DO: Update userName once user table is JOINed in the response
   */
  return (
    <div className="padding-x-2">
      <div
        className={[
          'bg-base-lightest-opacity-50',
          'border-1px',
          'border-base-lighter',
          'display-flex',
          'flex-column',
          'flex-gap-sm',
          'padding-1',
          'radius-md',
          'text-base-dark',
        ].join(' ')}
      >
        <IconLabel iconName="comment" size="xs" color="base-darker">
          {item.replies?.length > 1
            ? `${item.replies?.length} replies`
            : `${item.replies?.length} reply`}
        </IconLabel>

        <hr />
        <ExpandCollapseList initial={1}>
          {item.replies.map((reply) => (
            <FeedHeader
              item={reply}
              key={reply.contributionId}
              {...props}
              reduced
            >
              <Prose className={['font-sans-2xs'].join(' ')}>
                {reply.contribution}{' '}
                {reply.createdAt !== reply.updatedAt && <CommentStatusEdited />}
              </Prose>
            </FeedHeader>
          ))}
        </ExpandCollapseList>
      </div>
    </div>
  )
}

FeedReplyComment.propTypes = {
  /**
   * The individual contribution to display
   */
  item: PropTypes.object,
}

/**
 * UserNameDisplay component displays the name of the specified user based on their settings.
 * If the user has opted to display their full name, it will be shown.
 * Otherwise, their username will be displayed.
 * Staff members will also display a pill.
 */
export const UserNameDisplay = ({ reduced, showPills, user, ...props }) => {
  /**
   * TO DO: Update userName once user table is JOINed in the response
   */
  return user ? (
    <span>
      {user.userName && (
        <>
          <strong>@{user.userName} </strong>
          {showPills && user.naraStaff === 1 && <StaffPill reduced={reduced} />}
        </>
      )}
    </span>
  ) : (
    ''
  )
}
UserNameDisplay.defaultProps = {
  showPills: false,
  reduced: false,
}

UserNameDisplay.propTypes = {
  /**
   * Information about the user
   */
  user: PropTypes.object,
  /**
   * Optionally display associated pills, such as the NARA Staff pill
   */
  showPills: PropTypes.bool,
  reduced: PropTypes.bool,
}
