import React, { Fragment, useContext, useMemo } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import Prose from '../utilities/Prose'
//Helpers
import { replaceURLs } from '../../helper-functions/string-functions'
import ExpandCollapseString from '../utilities/ExpandCollapseString'
import ExpandCollapseList from '../utilities/ExpandCollapseList'
import SearchContext from '../../contexts/SearchContext'

const SectionSourceNotes = ({ ...props }) => {
  const recordContext = useContext(RecordContext)
  const record = recordContext.record
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')
  const sourceNotes = useMemo(() => {
    return record.sourceNotes
  }, [record])

  return (
    <div className={['flex-column', 'width-full'].join(' ')}>
      <>
        <h3 id="sourceNotes-label">
          <span>
            {sourceNotes.length > 1 ? (
              <>
                Source Notes
                <span className="usa-sr-only">
                  . This section contains {sourceNotes.length} items
                </span>
              </>
            ) : (
              'Source Note'
            )}
          </span>
          <span className="usa-sr-only">,</span>
        </h3>
        <ol
          // aria-labelledby="sourceNotes-label"
          className={[
            'border',
            'border-base-lighter',
            'font-sans-xs',
            'padding-left-2',
            'padding-top-2',
            'radius-md',
            'text-base-darkest',
          ].join(' ')}
        >
          <ExpandCollapseList initial={5}>
            {sourceNotes.map((note, index) => (
              <li
                key={note}
                className={[
                  'margin-left-3',
                  'margin-right-2',
                  'maxw-tablet-lg',
                  'padding-bottom-2',
                  'padding-left-1',
                ].join(' ')}
              >
                {note && <Prose>{replaceURLs(note)}</Prose>}
              </li>
            ))}
          </ExpandCollapseList>
        </ol>
      </>
    </div>
  )
}
export default SectionSourceNotes
