//ICON PROP TYPES
import PropTypes from 'prop-types'

export const iconSets = [
  {
    type: 'contextual',
    title: 'Description Levels',
    desc: 'These icons help visually describe the archival hierarchy of records, which mimic the physical storage of records.',
  },
  { type: 'contextual', title: 'Authorities', desc: '' },
  { type: 'contextual', title: 'Record Types', desc: '' },
  { type: 'contextual', title: 'Specific Record Types', desc: '' },
  { type: 'contextual', title: 'Copy Status', desc: '' },
  { type: 'contextual', title: 'Access and Use Restrictions', desc: '' },
  { type: 'contextual', title: 'Contributions', desc: '' },
  { type: 'contextual', title: 'Contact and Communication', desc: '' },
]

const set1 = iconSets[0].title
const set2 = iconSets[1].title
const set3 = iconSets[2].title
const set4 = iconSets[3].title
const set5 = iconSets[4].title
const set6 = iconSets[5].title
const set7 = iconSets[6].title
const set8 = iconSets[7].title

export const iconList = [
  { name: 'action-bulb-on', label: '', set: '' },
  { name: 'action-cloud-down', label: '', set: '' },
  { name: 'action-outRight', label: '', set: '' },
  { name: 'activity', label: '', set: '' },
  {
    name: 'arch',
    label: 'Architectural and Engineering Drawings',
    set: set3,
  },
  { name: 'extractedtext', label: '', set: '' },
  { name: 'arrow-rotation-doubleright', label: '', set: '' },
  { name: 'asterisk', label: '', set: '' },
  { name: 'at', label: '', set: set8 },
  { name: 'audio', label: 'Sound Recordings', set: set3 },
  { name: 'book', label: 'Bound Volume', set: set4 },
  { name: 'bookmark', label: 'Topical Subject Authority', set: set2 },
  { name: 'boxes', label: 'Record Group', set: set1 },
  { name: 'boxes', label: 'Collection', set: set1 },
  { name: 'box', label: 'Series', set: set1 },
  { name: 'box-3d', label: 'Artifact', set: '' },
  { name: 'bullet', label: '', set: '' },
  { name: 'bullet-hyphen', label: '', set: '' },
  {
    name: 'camera',
    label: 'Photographs and other Graphic Materials',
    set: set3,
  },
  { name: 'cassett', label: 'Audio Cassett', set: set4 },
  { name: 'cd', label: 'CD', set: set4 },
  { name: 'cd', label: 'DVD', set: set4 },
  { name: 'check', label: 'Check', set: '' },
  { name: 'chevron-down', label: '', set: '' },
  { name: 'chevron-up', label: '', set: '' },
  { name: 'circle-questions', label: '', set: '' },
  // { name: 'clock', label: '', set: '' },
  { name: 'close', label: '', set: '' },
  { name: 'comment', label: '', set: '' },
  { name: 'message', label: '', set: set7 },
  { name: 'data', label: 'Data Files', set: set3 },
  { name: 'date', label: '', set: '' },
  { name: 'direct-child', label: '', set: '' },
  { name: 'document-download', label: '', set: '' },
  { name: 'doublerightarrow', label: '', set: '' },
  // { name: 'down', label: '', set: '' },
  { name: 'down-right', label: '', set: '' },
  { name: 'download', label: '', set: '' },
  { name: 'edit', label: '', set: '' },
  { name: 'eye', label: '', set: '' },
  { name: 'eye-off', label: '', set: '' },
  { name: 'forward-slash', label: '', set: '' },
  { name: 'fax', label: '', set: set8 },
  { name: 'flag', label: '', set: '' },
  { name: 'flag-off', label: '', set: '' },
  { name: 'funnel', label: '', set: '' },
  { name: 'folder', label: 'File Unit', set: set1 },
  { name: 'gear', label: '', set: '' },
  { name: 'globe', label: '', set: '' },
  { name: 'grid', label: '', set: '' },
  { name: 'group', label: 'Organization Authority', set: set2 },
  { name: 'home', label: '', set: '' },
  { name: 'image', label: '', set: '' },
  // { name: 'info', label: '', set: '' },
  { name: 'info-circle', label: '', set: '' },
  { name: 'items', label: 'Item', set: set1 },
  { name: 'layout-feed', label: '', set: '' },
  { name: 'layout-table', label: '', set: '' },
  { name: 'left-down', label: '', set: '' },
  { name: 'left-up', label: '', set: '' },
  { name: 'letter', label: '', set: set8 },
  { name: 'link', label: '', set: '' },
  { name: 'list', label: '', set: '' },
  { name: 'location', label: 'Geographic Place Name Authority', set: set2 },
  { name: 'locked', label: '', set: '' },
  { name: 'logo-facebook', label: '', set: '' },
  { name: 'logo-twitter', label: '', set: '' },
  { name: 'unlocked', label: '', set: '' },
  { name: 'unlocked-question', label: '', set: '' },
  { name: 'unlocked-asterisk', label: '', set: '' },
  { name: 'map', label: 'Maps and Charts', set: set3 },
  { name: 'megaphone', label: '', set: '' },
  { name: 'message-circle', label: '', set: set7 },
  { name: 'message-square', label: '', set: set7 },
  { name: 'menu', label: '', set: '' },
  { name: 'minus', label: '', set: '' },
  { name: 'institution', label: '', set: '' },
  { name: 'name-tag', label: 'Roles', set: '' },
  // { name: 'narrow', label: '', set: '' },
  // { name: 'negative', label: '', set: '' },
  { name: 'newtab', label: '', set: '' },
  { name: 'next', label: '', set: '' },
  { name: 'no', label: '', set: '' },
  { name: 'notification-bell-on', label: '', set: '' },
  { name: 'note', label: '', set: '' },
  // { name: 'object-comment', label: '', set: '' },
  // { name: 'object-tag', label: '', set: '' },
  // { name: 'object-transcription', label: '', set: '' },
  { name: 'option2', label: '', set: '' },
  { name: 'option', label: '', set: '' },
  { name: 'option-gear', label: '', set: '' },
  { name: 'option-sliders', label: '', set: '' },
  { name: 'out-right', label: '', set: '' },
  // { name: 'paper', label: '', set: set4 },
  { name: 'person', label: 'Person Authority', set: set2 },
  { name: 'phone', label: '', set: set8 },
  {
    name: 'photo',
    label: 'Photographic Print',
    set: set4,
  },
  {
    name: 'photo',
    label: 'Photomechanical Print',
    set: set4,
  },
  {
    name: 'pipe',
    label: '',
    set: '',
  },
  { name: 'plus', label: '', set: '' },
  { name: 'preservation', label: '', set: set5 },
  { name: 'prev', label: '', set: '' },
  { name: 'printer', label: '', set: '' },

  // { name: 'reel', label: 'Video Open Reel', set: set4 },
  // { name: 'reel', label: 'Audio Tape/Reel', set: set4 },
  { name: 'reference', label: '', set: set5 },
  { name: 'reproduction', label: '', set: set5 },
  { name: 'rotate-left', label: '', set: set5 },
  { name: 'rotate-left-clock', label: '', set: set5 },
  // { name: 'restricted-fully', label: '', set: set6 },
  // { name: 'restricted-partially', label: '', set: set6 },
  // { name: 'restricted-possibly', label: '', set: set6 },
  { name: 'search', label: '', set: '' },
  { name: 'stop', label: '', set: '' },
  { name: 'share', label: '', set: '' },
  { name: 'square', label: '', set: '' },
  // { name: 'slide', label: 'Slide', set: set4 },
  // { name: 'sort', label: '', set: '' },
  { name: 'triangle-exclaim', label: '', set: '' },
  { name: 'tag', label: '', set: set7 },
  // { name: 'tag-nara', label: '', set: '' },
  { name: 'target', label: 'Specific Record Type Authority', set: set2 },
  { name: 'text', label: '', set: '' },
  { name: 'thumb-up', label: '', set: '' },
  { name: 'thumb-up-off', label: '', set: '' },
  { name: 'tool', label: '', set: '' },
  { name: 'tool-comment', label: '', set: '' },
  { name: 'tool-tag', label: '', set: '' },
  { name: 'tool-transcription', label: '', set: '' },
  { name: 'transcription', label: '', set: set7 },
  { name: 'transcription-locked', label: '', set: set7 },
  // { name: 'transcription-check', label: '', set: '' },
  // { name: 'transcription-ex', label: '', set: '' },
  // { name: 'transparency', label: 'Transparency', set: set4 },
  { name: 'trash', label: '', set: '' },
  // { name: 'unrestricted', label: '', set: '' },
  // { name: 'vhs', label: 'Video Cassette', set: set4 },
  { name: 'video', label: '', set: set3 },
  // { name: 'wide', label: '', set: '' },
]

export const iconNameArray = () => {
  let nameArray = []
  for (const i in iconList) {
    nameArray.push(iconList[i].name)
  }
  return nameArray
}

let nameArray = []
for (const i in iconList) {
  nameArray.push(iconList[i].name)
}
nameArray.push(null)

export const iconLabelArray = () => {
  let labelArray = []
  for (const i in iconList) {
    labelArray.push(iconList[i].label)
  }
  return labelArray
}

export let IconPropTypes = PropTypes.oneOf(nameArray)
