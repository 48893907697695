import React from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p>{value < 10 ? `0${value}` : value}</p>
      <span>{type}</span>
    </div>
  )
}

DateTimeDisplay.propTypes = {
  value: PropTypes.number,
  type: PropTypes.string,
  isDanger: PropTypes.bool,
}

export default DateTimeDisplay
