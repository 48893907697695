import React, { Fragment } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'

export const ResultsLoader = ({ ...props }) => {
  return (
    <>
      <div
        className={[
          'display-flex',
          'flex-row',
          'grid-row',
          'height-1',
          'margin-y-1',
          'padding-y-3',
          //'tablet:flex-row',
          //'tablet:grid-row',
          'width-full',
        ].join(' ')}
      >
        <div
          className={['block-load', 'height-1', 'width-card'].join(' ')}
        ></div>

        <div
          className={[
            'block-load',
            'height-1',
            'margin-left-auto',
            'width-card',
          ].join(' ')}
        ></div>
      </div>
      <div
        className={[
          'display-flex',
          'flex-gap',
          'flex-column',
          'grid-col',
          'tablet:flex-row',
          'tablet:grid-row',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            'display-none',
            'flex-gap-sm',
            'flex-column',
            'tablet:display-flex',
            'margin-right-1',
            'margin-y-3',
            'width-card-lg',
          ].join(' ')}
        >
          <div
            className={[
              'block-load',
              'height-1',
              'margin-bottom-1',
              'width-10',
            ].join(' ')}
          ></div>
          {[...Array(4)].map((e, i) => (
            <div
              className={['block-load', 'height-2', 'width-full'].join(' ')}
              key={i}
            ></div>
          ))}
        </div>
        <div
          className={[
            'display-flex',
            'flex-align-start',
            'flex-gap',
            'flex-column',
            'grid-col',
            'padding-1',
            'padding-y-3',
            'width-full',
          ].join(' ')}
        >
          <div
            className={[
              'display-flex',
              'flex-row',
              'grid-row',
              'height-1',
              'margin-bottom-1',
              'width-full',
            ].join(' ')}
          >
            <div
              className={['block-load', 'height-1', 'width-card'].join(' ')}
            ></div>

            <div
              className={[
                'block-load',
                'height-1',
                'margin-left-auto',
                'width-card',
              ].join(' ')}
            ></div>
          </div>
          {[...Array(6)].map((e, i) => (
            <div
              className={[
                'flex-row',
                'flex-gap',
                'grid-row',
                'padding-1',
                'width-full',
              ].join(' ')}
              key={i}
            >
              <div
                className={['block-load', 'width-10', 'height-10'].join(' ')}
              ></div>
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'grid-col',
                  'margin-bottom-2',
                  'width-full',
                ].join(' ')}
              >
                <div
                  className={['block-load', 'height-2', 'width-full'].join(' ')}
                ></div>
                <div
                  className={['block-load', 'height-2', 'width-card'].join(' ')}
                ></div>
                {[...Array(3)].map((e, i) => (
                  <Fragment key={i}>
                    <div
                      className={['block-load', 'height-1', 'width-full'].join(
                        ' '
                      )}
                    ></div>
                  </Fragment>
                ))}
                <div
                  className={['block-load', 'height-1', 'width-card'].join(' ')}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

ResultsLoader.defaultProps = {}

ResultsLoader.propTypes = {}
