/**
 * Description....
 * @param {array} array Array of objects representing initial filter structure
 *
 * @return Array of filter objects with child objects sorted by declared type
 */
export function sortRecursiveFilters(filters) {
  return filters.map((filter) => {
    return {
      ...filter,
      options: filter.options
        .filter((option) => {
          return option.count > 0
        })
        .sort((a, b) => {
          if (!filter.orderBy || filter.orderBy == 'count')
            return compareNumbers(a[filter.orderBy], b[filter.orderBy])
          if (filter.orderBy == 'title') {
            return compareStrings(a[filter.orderBy], b[filter.orderBy])
          }
        }),
    }
  })
}

/**
 * A comparative sorting oporation for ordering arrays by number
 * @param {number} a First number from array to compare
 * @param {number} b Second number from array to compare
 * @param {bool} asc Boolean for whether the items should be in ascending (true, 0-9) or descending (false, 9-0) order
 *
 * @return Array of filtered objects sorted by declared type
 */
export function compareNumbers(a, b, asc = true) {
  if (!a && !b) return false
  if (asc == true) return b - a
  else return a - b
}

/**
 * A comparative sorting oporation for ordering arrays by string
 * @param {string} a First string from array to compare
 * @param {string} b Second string from array to compare
 * @param {bool} asc Boolean for whether the items should be in ascending (true, 0-9) or descending (false, 9-0) order
 *
 * @return Array of filtered objects sorted by declared type
 */
export function compareStrings(a, b, asc = true) {
  if (parseInt(a) && parseInt(b))
    return compareNumbers(parseInt(a), parseInt(b), asc)
  else if (asc == true) {
    if (b > a) return -1
    if (b < a) return 1
    return 0
  } else {
    if (a > b) return -1
    if (a < b) return 1
    return 0
  }
}

/**
 * A comparative sorting oporation for ordering arrays by string
 * @param {string} a First timestamp from array to compare
 * @param {string} b Second timestamp from array to compare
 * @param {bool} asc Boolean for whether the items should be in ascending (true, 0-9) or descending (false, 9-0) order
 *
 * @return Array of filtered objects sorted by date
 */
export function compareDates(a, b, asc = true) {
  if (asc == true) return new Date(b) - new Date(a)
  else return new Date(a) - new Date(b)
}

export const sortByHeadingAndDate = (a, b) => {
  if (a.authorityType === 'person') return compareStrings(a.heading, b.heading)
  else if (a.authorityType === 'organization')
    return compareDates(
      a.establishDate?.logicalDate ||
        a.abolishDate?.logicalDate ||
        a.birthDate?.logicalDate ||
        a.deathDate?.logicalDate,
      b.establishDate?.logicalDate ||
        b.abolishDate?.logicalDate ||
        b.birthDate?.logicalDate ||
        b.deathDate?.logicalDate,
      false
    )
}
