import React, { forwardRef, Fragment, useEffect, useRef, useState } from 'react'
import '../../styles/index.scss'
//PropTypes
import PropTypes from 'prop-types'
import { inputSchemaPropTypes } from '../../schemas/input-schemas/input-schema-prop-types'
//Components
import { Button } from '../Button/Button'
import { selectableSchema } from '../../schemas/input-schemas/input-schema-advanced-search'
import TextInput from './text_input'
import { searchInputSchema } from '../../schemas/input-schemas/select-search-schema'
import useSelect from './use-select'
import useEventListener, { TAB_KEYS } from '../../hooks/use-event-listener'
import { Pill } from '../Pill/Pill'
import CheckboxField from './CheckboxField'
import { ListSeparator } from '../ListSeparator/ListSeparator'
import Highlighter from '../Highlighter/Highlighter'

const Select = forwardRef(
  (
    {
      className,
      disabled,
      error,
      multiSelect,
      onChange,
      options,
      schema,
      search,
      selected,
      ...props
    },
    ref
  ) => {
    if (!schema) return false
    //State
    const [isButtonFocused, setIsButtonFocused] = useState(false)
    //Hooks
    const {
      dropdownOptions,
      enteredValue,
      keyDownHandler,
      handleListKeyDown,
      isOptionsOpen,
      optionSearchHandler,
      resultCount,
      selectedIds,
      selectedOption,
      setSelectedHandler,
      toggleOptions,
    } = useSelect(
      disabled,
      error,
      multiSelect,
      onChange,
      options,
      schema,
      search,
      selected
    )
    //Refs
    const buttonRef = useRef()
    const searchRef = useRef()
    const wrapperRef = useRef()

    // This function allows the display to correctly focus on the appropriate elements.
    const [scrolling, setScrolling] = useState(true)
    const setScrollingHandler = (e) => {
      if (e.target === wrapperRef.current) setScrolling(true)
      else setScrolling(false)
    }

    return (
      <div
        className={[
          'bg-white',
          'border-1px',
          disabled ? 'border-base-light' : 'border-base-dark',
          isButtonFocused ? 'outline-2px' : '',
          'focus:border-2px focus:border-primary',
          'display-flex',
          'flex-align-stretch',
          'flex-column',
          'margin-bottom-3',
          'overflow-hidden',
          'position-relative',
          'radius-md',
          'width-full',
          className,
        ].join(' ')}
        onKeyPress={(event) => {
          if (event.key === 'Enter') toggleOptions(event)
        }}
        ref={ref}
      >
        <Button
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          className={[
            'add-border-reset',
            'text-left',
            'display-inline-block',
            'flex-align-center',
            isOptionsOpen ? 'expanded' : '',
            'margin-bottom-neg-05',
            'margin-top-neg-2px',
            'outline-none',
            'width-full',
            // 'z-top',
          ].join(' ')}
          data-testid={`nac_select_${schema?.[0]?.id}`}
          disabled={disabled}
          iconName={isOptionsOpen ? 'chevron-up' : 'chevron-down'}
          iconPosition="right"
          iconSize="xs"
          onBlur={() => {
            setIsButtonFocused(false)
          }}
          onMouseDown={() => {
            setIsButtonFocused(true)
            toggleOptions()
          }}
          onFocus={() => {
            setIsButtonFocused(true)
          }}
          onKeyDown={handleListKeyDown}
          ref={buttonRef}
          shape={isOptionsOpen ? '0' : 'md'}
          size="sm"
          textAlign="left"
          textOnly
          theme={selectedOption?.[0]?.isPlaceholder ? 'base' : 'base-darkest'}
          thin
          type="button"
        >
          <div className="display-flex ellipsed flex-align-center flex-gap-sm flex-no-wrap flex-row">
            {/*selectedOption && selectedOption.length > 1 ? (
              <span className="display-block">
                {selectedOption.length} selected:
              </span>
            ) : (
              ''
            )*/}
            <ListSeparator delimeter="plus" distance="2px">
              {selectedOption.map((s) => (
                <span key={s.id}>{s.label}</span>
              ))}
            </ListSeparator>
          </div>
        </Button>
        {search && isOptionsOpen && (
          <div
            className={[
              'bg-base-lightest',
              'border-base-lighter',
              'border-top-1px',
              'display-flex',
              'flex-gap-sm',
              'flex-column',
              'margin-bottom-neg-05',
              'margin-top-05',
              'padding-1',
            ].join(' ')}
          >
            <TextInput
              // onBlur={blurHandler}
              onChange={optionSearchHandler}
              // error={inputError}
              ref={searchRef}
              schema={searchInputSchema}
            />
            {(enteredValue || dropdownOptions.length < schema.length) && (
              <div className={['font-sans-xs', 'text-italic'].join(' ')}>
                Showing{' '}
                {resultCount === 1 ? '1 option' : `${resultCount} options`}
              </div>
            )}
          </div>
        )}
        <ul
          aria-activedescendant={
            schema.filter(({ id }) => selectedIds?.indexOf(id) > -1) ||
            schema?.[0]?.id
          }
          className={[
            'options',
            //
            'add-list-reset',
            'border-base-lighter',
            'border-top-1px',
            'contents-scroll',
            isOptionsOpen ? 'display-block' : 'display-none',
            'margin-top-05',
            'maxh-card-lg',
            'overflow-y-auto',
            'shadow-xs',
            'font-sans-xs',
          ].join(' ')}
          id={schema.id}
          onKeyDown={handleListKeyDown}
          onMouseDown={setScrollingHandler}
          ref={wrapperRef}
          role="listbox"
          tabIndex={-1}
        >
          {dropdownOptions?.map(
            (option, index) =>
              option &&
              option.id !== '' &&
              !option.isPlaceholder && (
                <li
                  aria-selected={selected?.id == option?.id}
                  className={[
                    selected?.id == option?.id ? 'bg-base-lighter' : '',
                    !option.options
                      ? [
                          'clickable',
                          'hover:bg-base-lightest',
                          'focus:bg-base-lightest',
                          scrolling ? 'outline-none' : 'outline-offset-neg-2px',
                          'padding-105',
                        ].join(' ')
                      : '',
                  ].join(' ')}
                  data-testid={`nac-select_option_${option?.id}`}
                  key={schema.id + '_' + option.id}
                  id={option?.id}
                  onKeyDown={(event) => {
                    if (option.options) return
                    setScrollingHandler(event)
                    keyDownHandler(event, option?.id)
                  }}
                  onClick={(event) => {
                    if (option.options) return
                    setScrollingHandler(event)
                    setSelectedHandler(event, option?.id)
                  }}
                  role="option"
                  tabIndex={option.options ? -1 : 0}
                >
                  {option.options ? (
                    <>
                      <span
                        className={[
                          'font-sans-xs',
                          'margin-top-2',
                          'padding-x-105 padding-y-05',
                          'text-base-dark',
                        ].join(' ')}
                      >
                        {option.label}
                      </span>
                      <ul
                        className={[
                          index < dropdownOptions.length - 1
                            ? [
                                'border-bottom-1px',
                                'border-base-lighter',
                                'margin-0',
                                'padding-bottom-2',
                              ].join(' ')
                            : '',
                        ].join(' ')}
                      >
                        {option.options.map((o, i) => (
                          <li
                            aria-selected={selected?.id == o?.id}
                            className={[
                              selected?.id == o?.id ? 'bg-base-lighter' : '',
                              'clickable',
                              'hover:bg-base-lightest',
                              'focus:bg-base-lightest',
                              scrolling
                                ? 'outline-none'
                                : 'outline-offset-neg-2px',
                              'padding-105',
                              o.length === index + 1 ? 'radius-bottom-md' : '',
                            ].join(' ')}
                            data-testid={`nac-select_option_${o?.id}`}
                            id={o.id}
                            key={o.id}
                            onKeyDown={(event) => {
                              setScrollingHandler(event)
                              keyDownHandler(event, o.id)
                            }}
                            onClick={(event) => {
                              setScrollingHandler(event)
                              setSelectedHandler(event, o.id)
                            }}
                            role="option"
                            tabIndex={0}
                          >
                            {multiSelect ? (
                              <CheckboxField
                                checked={
                                  selectedOption.filter(
                                    (opt) => opt.label === o.label
                                  ).length > 0
                                }
                                key={o.id}
                                groupId={schema.id}
                                // id={o.id}
                              >
                                <SelectLabel option={o} search={enteredValue} />
                              </CheckboxField>
                            ) : (
                              <SelectLabel option={o} search={enteredValue} />
                            )}
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : multiSelect ? (
                    <CheckboxField
                      checked={
                        selectedOption.filter((opt) => {
                          if (opt.label === option.label) return opt
                        }).length > 0
                      }
                      key={option.id}
                      groupId={schema.id}
                      // id={option.id}
                    >
                      <SelectLabel option={option} search={enteredValue} />
                    </CheckboxField>
                  ) : (
                    <SelectLabel option={option} search={enteredValue} />
                  )}
                </li>
              )
          )}
        </ul>
      </div>
    )
  }
)

Select.defaultProps = {
  disabled: false,
  search: false,
}

Select.propTypes = {
  /**
   * Additional classes to apply to the component wrapper
   */
  className: PropTypes.string,
  /**
   * Determines whether the input can be interacted with
   * */
  disabled: PropTypes.bool,
  /**
   * Specific input error information
   * */
  error: PropTypes.object,
  /**
   * Allows for multiple options to be selected from the dropdown
   */
  multiSelect: PropTypes.bool,
  /**
   * Additional function to handle change event in parent component
   */
  onChange: PropTypes.func,
  /**
   * An array of predefined select options, if they differ from those provided by the schema
   * Mainly used for repeatable fields where it is important to prevent selecting the same select option multiple times.
   */
  options: PropTypes.array,
  /**
   * The specific schema associated with the selected field id
   * used to build and validate the input
   */
  schema: PropTypes.arrayOf(inputSchemaPropTypes),
  search: PropTypes.bool,
  selected: PropTypes.array,
}

Select.displayName = 'Select'
export default Select

export const SelectLabel = ({ option, search, ...props }) => {
  return (
    <>
      <div>
        <Highlighter search={search}>{option.label}</Highlighter>
      </div>
      {option.details && (
        <div
          className={[
            'display-inline-flex',
            'flex-align-center',
            'flex-gap-sm',
            'flex-row',
          ].join(' ')}
        >
          {option.details.map(({ label, type }) => (
            <Fragment key={label}>
              {type === 'pill' ? (
                <Pill
                  filled={search !== '' && label.indexOf(search) > -1}
                  thin
                  radius="md"
                  reduced
                  size="4xs"
                  theme={
                    search !== '' && label.indexOf(search) > -1
                      ? 'accent-cool-light'
                      : 'accent-cool-darker'
                  }
                >
                  {label}
                </Pill>
              ) : (
                <span className="font-sans-2xs text-base-dark">
                  <Highlighter search={search}>{label}</Highlighter>
                </span>
              )}
            </Fragment>
          ))}
        </div>
      )}
    </>
  )
}

SelectLabel.propTypes = {
  option: PropTypes.object,
  search: PropTypes.string,
}
