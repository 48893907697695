import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../Button/Button'
import DataGrid from '../../DataGrid/DataGrid'
import marked, { use } from 'marked'
import useFormatter from '../../../hooks/use-formatters'
import { SERVER_URL } from '../../settings/globals'
import Toast from '../../Toast/Toast'
import BannerModal from '../../BannerModal/BannerModal'
import useModal from '../../../hooks/use-modal'

//imgs

import inactivate from '../../../styles/assets/uswds/img/usa-icons/do_not_disturb.svg'
import edit from '../../../styles/assets/uswds/img/usa-icons/edit.svg'
import check_circle_outline from '../../../styles/assets/uswds/img/usa-icons/check_circle_outline.svg'
import preview from '../../../styles/assets/uswds/img/usa-icons/visibility.svg'

import useHttp from '../../../hooks/use-http'
import './announcements.scss'

const Announcments = () => {
  const [data, setData] = useState([])
  const { formatDateTime } = useFormatter()

  const [respTotalCount, setRespTotalCount] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  //Pagination / Sorting
  const [sortBy, setSortBy] = useState('status')
  const [orderBy, setOrderBy] = useState('asc')
  const [parentPageNumber, setParentPageNumber] = useState(1)
  const [announcement, setAnnouncement] = useState(false)
  const [limit, setLimit] = useState(20)
  const [updateToast, setupdateToast] = useState(null)
  const { isShowing, toggle } = useModal()
  const [showPreview, setShowPreview] = useState(false)
  const [activeCount, setActiveCount] = useState(0)

  const handleAnnouncmentResponse = (resp) => {
    const {
      announcements,
      metadata: { totalCount, activeCount },
    } = resp
    setData([...announcements])
    setRespTotalCount(totalCount)
    setParentPageNumber(1)
    setActiveCount((prev) => (prev = activeCount))
  }

  // Hook for fetching announcements
  const { sendRequest: fetchAnnouncements } = useHttp(
    {
      url: `${SERVER_URL}/proxy/announcements?limit=${limit}&page=${parentPageNumber}&sortCol=${sortBy}&sortOrder=${orderBy}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleAnnouncmentResponse
  )

  const handleStatusUpdateResponse = (resp) => {
    setupdateToast(
      resp.status === 'ACTIVE'
        ? 'Announcement was successfully activated'
        : resp.status === 'DRAFT'
        ? 'Announcement was successfully set to draft'
        : 'Announcement was successfully inactivated'
    )
    getAnnouncements() // Refresh the list
  }

  const handlePaginationResponse = (resp) => {
    const {
      announcements,
      metadata: { totalCount, activeCount },
    } = resp
    setData([...announcements])
  }

  const { sendRequest: updateAnnouncement } = useHttp(
    {},
    handleStatusUpdateResponse,
    (error) => {
      if (error) {
        setupdateToast(
          'There was an error updating the announcement. Please try again later.'
        )
      }
    }
  )

  const { sendRequest: fetchPagination } = useHttp(
    {},
    handlePaginationResponse,
    (error) => {
      if (error) {
        setupdateToast(
          'There was an error fetching the announcements. Please try again later.'
        )
      }
    }
  )

  useEffect(() => {
    setData([...data])
  }, [activeCount])

  const getAnnouncements = () => {
    setParentPageNumber(1)

    fetchAnnouncements()
  }

  useEffect(() => {
    getAnnouncements()
  }, [limit, orderBy, sortBy])

  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const previews = useRef([])

  useEffect(() => {
    const _totalPages = Math.ceil(respTotalCount / limit)
    let _pageNum = parentPageNumber
    let result = _pageNum < totalPages ? ++_pageNum : _pageNum
    setTotalPages(_totalPages)
    setHasNext(parentPageNumber < _totalPages)
  }, [data, parentPageNumber, respTotalCount])

  useEffect(() => {
    const _totalPages = Math.ceil(respTotalCount / limit)
    let _pageNum = parentPageNumber
    let result = _pageNum < totalPages ? --_pageNum : _pageNum
    setTotalPages(_totalPages)
    setHasPrev(parentPageNumber > 1)
  }, [data, parentPageNumber, respTotalCount])

  const next = async () => {
    const update = parentPageNumber + 1
    setParentPageNumber(update)

    // Hook for fetching announcements
    fetchPagination(null, {
      url: `${SERVER_URL}/proxy/announcements?limit=${limit}&page=${update}&sortCol=${sortBy}&sortOrder=${orderBy}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const prev = () => {
    const update = parentPageNumber - 1
    setParentPageNumber(update)

    // Hook for fetching announcements
    fetchPagination(null, {
      url: `${SERVER_URL}/proxy/announcements?limit=${limit}&page=${update}&sortCol=${sortBy}&sortOrder=${orderBy}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  const deactivateAnnouncement = (e) => {
    const { announcement_id, user_id } = JSON.parse(
      e.currentTarget.parentElement.parentElement.parentElement.dataset
        .announcment
    )

    try {
      updateAnnouncement(null, {
        url: `${SERVER_URL}/proxy/announcements/${announcement_id.value}`,
        method: 'PUT',
        body: { status: 'INACTIVE', user_id: user_id.value },
        headers: { 'Content-Type': 'application/json' },
      })
    } catch (e) {
      console.log('Error is', e)
    }
  }

  const setAnnouncementData = (e) => {
    const { announcement_id, user_id } = JSON.parse(
      e.currentTarget.parentElement.parentElement.parentElement.parentElement
        .dataset.announcment
    )
    setAnnouncement(announcement_id)
  }

  const setAnnouncementPreviewData = (e) => {
    const { announcement_id, user_id } = JSON.parse(
      e.currentTarget.parentElement.parentElement.dataset.announcment
    )
    setAnnouncement(announcement_id)
  }

  const activateAnnouncement = (e) => {
    const { announcement_id, user_id } = JSON.parse(
      e.currentTarget.parentElement.parentElement.parentElement.dataset
        .announcment
    )
    try {
      updateAnnouncement(null, {
        url: `${SERVER_URL}/proxy/announcements/${announcement_id.value}`,
        method: 'PUT',
        body: { status: 'ACTIVE', user_id: user_id.value },
        headers: { 'Content-Type': 'application/json' },
      })
    } catch (e) {
      console.log('Error is', e)
    }
  }

  let initialData = false
  if (data.length && announcement) {
    initialData = data.find((row) => row.announcement_id === announcement.value)
  }

  useEffect(() => {
    const banner = document.querySelector('.preview-banner a')
    if (banner) {
      banner.setAttribute('target', '_blank')
      banner.classList.add('icon-popout-window-white')
    }
  }, [showPreview])

  useEffect(() => {
    setTimeout(() => {
      const rows = Array.from(document.querySelectorAll('tbody tr a'))
      rows.forEach((item) => {
        item.setAttribute('target', '_blank')
        item.classList.add('icon-popout-window')
      })
    }, 1)
  }, [data])

  return (
    <div className="announcements">
      <BannerModal
        data={data}
        edit
        announcement={announcement}
        toggle={toggle}
        getAnnouncements={getAnnouncements}
        isShowing={isShowing}
        onClose={() => {}}
      />
      {updateToast !== null && (
        <Toast
          toastList={[
            {
              description: updateToast,
              autoDelete: true,
              autoDeleteTime: 1000,
            },
          ]}
        />
      )}
      <div>
        {showPreview && initialData && (
          <div className="preview-banner">
            <div
              dangerouslySetInnerHTML={{
                __html: marked(initialData.announcement_text),
              }}
            />
            <Button
              className={[
                'announcement-btn',
                'position-absolute',
                'close-modal',
                'right-05',
                'top-05',
              ].join(' ')}
              displayClass="block"
              iconName="close"
              iconOnly
              iconSize="xs"
              onClick={() => {
                setShowPreview(false)
              }}
              outline
              reduced
              srText="Close modal"
            />
          </div>
        )}
      </div>
      <DataGrid
        title={'Results'}
        actions={false}
        showAdmin={<></>}
        data={data}
        prev={prev}
        next={next}
        setLimit={setLimit}
        getAnnouncements={getAnnouncements}
        hasNext={hasNext}
        hasPrev={hasPrev}
        setSortBy={setSortBy}
        setOrderBy={setOrderBy}
        setParentPageNumber={setParentPageNumber}
        displayPager={true}
        showSort={true}
        displayHeaders={{
          edit1: {
            type: 'string',
            sort: false,
            label: '',
            value: 'test',
            isVisible: true,
            formatter() {
              return (
                <div
                  className="action"
                  onClick={(e) => {
                    setAnnouncementPreviewData(e)
                    setShowPreview(true)
                  }}
                >
                  <img className="preview-button" src={preview} />
                </div>
              )
            },
          },
          announcement_text: {
            type: 'string',
            sort: 'announcement_text',
            label: 'Announcement',
            value: '',
            isVisible: true,
            formatter() {
              return (
                <div dangerouslySetInnerHTML={{ __html: marked(this.value) }} />
              )
            },
          },
          status: {
            type: 'string',
            sort: 'status',
            label: 'Status',
            value: '',
            isVisible: true,
            formatter() {
              if (/^active$/i.test(this.value)) {
                return (
                  <div
                    data-status={this.value}
                    className=" active usa-pill--success-darker border-success-darker border-2px display-inline-flex flex-align-center radius-md theme-success-darker usa-pill--success-darker padding-2px font-sans-3xs bg-success-lighter flex-justify-center margin-left-auto text-uppercase font-sans-5xs"
                  >
                    <span
                      title="Active"
                      className="text-success-darker text-bold"
                    >
                      active
                    </span>
                  </div>
                )
              } else if (/^inactive$/i.test(this.value)) {
                return (
                  <div
                    data-status={this.value}
                    className="inactive usa-pill--base-darker bg-base-lighter border-2px display-inline-flex flex-align-center radius-md theme-success-darker usa-pill--success-darker padding-2px font-sans-3xs bg-success-lighter flex-justify-center margin-left-auto text-uppercase font-sans-5xs"
                  >
                    <span
                      title="Inactive"
                      className="theme-base-lighter--text text-bold"
                    >
                      inactive
                    </span>
                  </div>
                )
              } else if (/^draft$/i.test(this.value)) {
                return (
                  <div
                    data-status={this.value}
                    className="draft usa-pill--warning-dark border-warning-dark border-2px display-inline-flex flex-align-center radius-md theme-warning-dark usa-pill--warning-dark padding-2px font-sans-3xs bg-warning-lighter flex-justify-center margin-left-auto text-uppercase font-sans-5xs"
                  >
                    <span title="Draft" className="text-warning-dark text-bold">
                      draft
                    </span>
                  </div>
                )
              }
            },
          },
          edit: {
            type: 'string',
            sort: '',
            label: 'Actions',
            value: '',
            isVisible: true,
            formatter() {
              return (
                <div className="action">
                  <span className="action-item edit">
                    <img
                      title="Edit"
                      src={edit}
                      onClick={(e) => {
                        setAnnouncementData(e)
                        toggle()
                      }}
                    />
                  </span>
                  <span
                    className="action-item inactivate"
                    onClick={deactivateAnnouncement}
                  >
                    <img title="Inactivate" src={inactivate} />
                  </span>
                  <span
                    className={`action-item active ${activeCount} ${
                      activeCount > 1 ? ' no-display' : ''
                    }`}
                    onClick={activateAnnouncement}
                  >
                    <img title="Make active" src={check_circle_outline} />
                  </span>
                </div>
              )
            },
          },
          created_at: {
            type: 'string',
            sort: 'created_at',
            label: 'Date Created',
            value: '',
            isVisible: true,
            formatter() {
              return (
                <div>
                  <div className="action-date">
                    {formatDateTime(this.value)[0]}
                  </div>
                  <div className="action-time">
                    {formatDateTime(this.value)[1]}
                  </div>
                </div>
              )
            },
          },
          updated_at: {
            type: 'string',
            sort: 'updated_at',
            label: 'Date Updated',
            value: '',
            isVisible: true,
            formatter() {
              return (
                <div>
                  <div className="action-date">
                    {formatDateTime(this.value)[0]}
                  </div>
                  <div className="action-time">
                    {formatDateTime(this.value)[1]}
                  </div>
                </div>
              )
            },
          },
          announcement_id: {
            type: 'string',
            sort: 'announcement_id',
            label: 'Announcement ID',
            value: '',
            isVisible: false,
            formatter() {
              return <div data-announcement_id={this.value} />
            },
          },
          user_id: {
            type: 'string',
            sort: 'user_id',
            label: 'User Id',
            value: '',
            isVisible: false,
            formatter() {
              return this.value
            },
          },
          user_name: {
            type: 'string',
            sort: 'user_name',
            label: 'User',
            value: '',
            isVisible: true,
            formatter() {
              return this.value
            },
          },
          notes: {
            type: 'string',
            sort: 'notes',
            label: 'Notes',
            value: '',
            isVisible: true,
            formatter() {
              return this.value
            },
          },
        }}
      ></DataGrid>
    </div>
  )
}

export default Announcments
