import React, { forwardRef, useEffect, useState } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
const CheckboxField = forwardRef(
  (
    {
      checked,
      children,
      className,
      groupId,
      id,
      indeterminate,
      label,
      selectionHandler,
      revision,
      groupRevision,
      value,
      ...props
    },
    ref
  ) => {
    const [selected, setSelected] = useState(checked || false)
    const [localRevision, setLocalRevision] = useState(0)
    const [localGroupRevision, setLocalGroupRevision] = useState(0)

    const handleOnClick = (event) => {
      event.preventDefault()
      selectionHandler(value)
    }

    const handleOnKeypress = (event) => {
      event.preventDefault()
      selectionHandler(value)
    }

    useEffect(() => {
      if (revision > localRevision) {
        setSelected(false)
        setLocalRevision(revision)
      }
    }, [revision])

    useEffect(() => {
      if (groupRevision > localGroupRevision) {
        setSelected(false)
        setLocalGroupRevision(groupRevision)
      }
    }, [groupRevision])

    return (
      <div className="usa-checkbox">
        <input
          className={[
            'usa-checkbox__input',
            indeterminate || ref?.current?.indeterminate ? 'indeterminate' : '',
          ].join(' ')}
          checked={checked}
          id={id}
          name={groupId ? groupId : id}
          onChange={selectionHandler && handleOnClick}
          onKeyPress={handleOnKeypress}
          ref={ref}
          type="checkbox"
          value={value}
          {...props}
        />
        <label
          className={[
            'usa-checkbox__label',
            children ? 'details' : '',
            className,
          ].join(' ')}
          htmlFor={id}
        >
          {children ? children : label}
        </label>
      </div>
    )
  }
)

CheckboxField.defaultProps = {
  groupId: '',
  id: '',
  label: '',
  selectionHandler: () => {},
  revision: 0,
  groupRevision: 0,
  value: '',
}

CheckboxField.propTypes = {
  /**
   * Defines if the input is checked from the parent
   */
  checked: PropTypes.bool,
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * Additional classes to apply to the wrapper
   */
  className: PropTypes.string,
  groupId: PropTypes.string,
  id: PropTypes.string.isRequired,
  indeterminate: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectionHandler: PropTypes.func,
  revision: PropTypes.number,
  groupRevision: PropTypes.number,
  value: PropTypes.string,
}

CheckboxField.displayName = 'CheckboxField'

export default CheckboxField
