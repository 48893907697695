import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import '../../../styles/index.scss'
//Components
import { Button } from '../Button'
import Popover, { PopoverBody, PopoverFooter } from '../../Popover/Popover'
import { IconLabelConditionalDisplay } from '../../IconLabel/variants/IconLabelConditionalDisplay'
import { Link } from 'react-router-dom'
import RecordContext from '../../../contexts/RecordContext'
import ClickAwayListener from 'react-click-away-listener'
import useHttp from '../../../hooks/use-http'
import { SEARCH_URL } from '../../settings/globals'
import StatusPill from '../../Pill/variants/StatusPill'
import UserContext from '../../../contexts/UserContext'
import { ButtonBackgroundImageAction } from '../../Account/restricted/BackgroundImages'
import { imageTypes } from '../../../helper-functions/metadata-functions'

/**
 * The Edit Button provides a popover of options for moderators to change aspects of the current record
 */
export const ButtonAdminObjectOptions = ({ children, ...props }) => {
  //Context
  const { filteredObjects, objectId, record } = useContext(RecordContext)
  const { loggedInUser, accountsAccessArray } = useContext(UserContext)

  //State
  const [active, setActive] = useState(false)
  const [buttonHeight, setButtonHeight] = useState(0)
  const [status, setStatus] = useState(null)
  const [loadingStatus, setLoadingStatus] = useState(false)

  //Refs
  const popoverRef = useRef(null)

  // Vars
  const title = record.title
  const naId = record.naId
  const objectUrl = record.digitalObjects.filter(
    (o) => o.objectId == objectId
  )[0]?.objectUrl

  const handleBlur = (event) => {
    if (!event.relatedTarget && !popoverRef?.current?.contains(event.target)) {
      setActive(false)
    }
  }

  const handleClickAway = () => {
    if (active) {
      setActive(false)
    }
  }

  const heroImageStatusResponseHandler = (response) => {
    if (response && response.length > 0) setStatus(response[0].status)
    else setStatus(null)
  }

  const { sendRequest: heroImageStatusRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/image-carousel/all?objectId=${objectId}`,
    },
    heroImageStatusResponseHandler
  )

  useEffect(() => {
    if (active && !loadingStatus && !status) heroImageStatusRequestHandler()
    else setStatus(null)
  }, [active])

  return (
    <div
      className={[
        'display-flex',
        'flex-align-center',
        'position-relative',
      ].join(' ')}
    >
      <Button
        aria-label="Access admin object options."
        data-testid="nac-objects--edit-object-button"
        iconName="locked"
        iconSize="xs"
        onClick={() => setActive(!active)}
        reduced
        size="2xs"
        textOnly
        theme="base-lighter"
        thin
      >
        {children}
      </Button>
      {active && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popover
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="width-card-lg"
            hide={() => setActive(false)}
            id="edit-page"
            onBlur={handleBlur}
            position={[{ right: 0, top: buttonHeight }]}
            title="Manage Options"
            ref={popoverRef}
            tabIndex={0}
          >
            <PopoverBody id="edit-page" reduced>
              <div
                className={[
                  'display-flex',
                  'flex-justify',
                  'flex-gap-sm',
                  'flex-column',
                  'padding-2',
                  'width-full',
                ].join(' ')}
              >
                <IconLabelConditionalDisplay displayTo="Moderators" />
                <ul
                  className={[
                    'add-list-reset',
                    'display-flex',
                    'flex-gap-sm',
                    'flex-column',
                    'font-sans-3xs',
                    'margin-bottom-1',
                    'minw-0',
                  ].join(' ')}
                >
                  <li
                    className={[
                      'display-inline-flex',
                      'flex-gap-xs',
                      'flex-row',
                      'minw-0',
                    ].join(' ')}
                  >
                    <h2 className="font-sans-2xs margin-bottom-0 text-bold">
                      Object ID:{' '}
                    </h2>
                    {objectId}
                  </li>
                  <li
                    className={[
                      'display-inline-flex',
                      'flex-wrap',
                      // 'ellipsed',
                      'flex-gap-xs',
                      'flex-row',
                      'white-space-pre-wrap',
                    ].join(' ')}
                  >
                    <h2 className="font-sans-2xs margin-bottom-0 text-bold">
                      Object URL:{' '}
                    </h2>
                    <span className="break-content line-height-sans-2">
                      {/**ellipsed-line-2  */}
                      {objectUrl}
                    </span>
                  </li>
                </ul>
              </div>
              {accountsAccessArray.accessGrantedToAccountAdminTools &&
                imageTypes.some((element) => {
                  if (element !== 'PDF') {
                    let objectType = filteredObjects.filter(
                      (o) => o.objectId == objectId
                    )?.[0]?.objectType
                    console.log(objectType)
                    if (objectType.includes(element)) return true
                    else return false
                  }
                }) && (
                  <>
                    <hr />
                    <div
                      className={[
                        'display-flex',
                        'flex-justify',
                        'flex-gap-sm',
                        'flex-column',
                        'padding-2',
                        'width-full',
                      ].join(' ')}
                    >
                      <IconLabelConditionalDisplay displayTo="Admins" />
                      <h2
                        className={[
                          'display-flex',
                          'flex-gap',
                          'flex-row',
                          'font-sans-2xs',
                          'margin-bottom-2',
                        ].join(' ')}
                      >
                        Background Image
                        <Link
                          className="margin-left-auto text-normal"
                          to={{
                            pathname: `/accounts/${loggedInUser.userName}/moderator-dashboard/background-images`,
                          }}
                        >
                          Manage...
                        </Link>
                      </h2>
                      <div
                        className={['display-flex', 'flex-column'].join(' ')}
                      >
                        <div
                          className={[
                            'display-flex',
                            'flex-align-center',
                            'flex-gap-sm',
                            'flex-row',
                          ].join(' ')}
                        >
                          <StatusPill
                            className={['height-3'].join(' ')}
                            label={status || 'None'}
                            status={
                              status &&
                              (status === 'active' ? 'success' : 'inactive')
                            }
                          />
                          {!status ? (
                            <ButtonBackgroundImageAction
                              action="add"
                              naId={naId}
                              objectId={objectId.toString()}
                              objectUrl={objectUrl}
                              onUpdate={heroImageStatusRequestHandler}
                              title={title}
                            />
                          ) : status === 'active' ? (
                            <ButtonBackgroundImageAction
                              action="deactivate"
                              objectId={objectId.toString()}
                              onUpdate={heroImageStatusRequestHandler}
                            />
                          ) : (
                            <ButtonBackgroundImageAction
                              action="activate"
                              objectId={objectId.toString()}
                              onUpdate={heroImageStatusRequestHandler}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </PopoverBody>
            <PopoverFooter hide={() => setActive(false)} id="edit-page" />
          </Popover>
        </ClickAwayListener>
      )}
    </div>
  )
}

ButtonAdminObjectOptions.defaultProps = {
  children: 'Manage...',
}

ButtonAdminObjectOptions.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
}
