import React, { Fragment } from 'react'
import queryString from 'query-string'

export function handleQuotes(query) {
  let handledQuery = query
  // Lonely quote
  if ((query.match(/"/g) || []).length === 1) {
    if (query.startsWith('"')) {
      handledQuery = query + '"'
    } else if (query.endsWith('"')) {
      handledQuery = '"' + query
    } else {
      handledQuery = query.replace(/"/g, '')
    }
  }
  // Uneven quotes, can't make assumptions about intended phrasing so we'll pair closest ones and remove the last remaining one
  else if ((query.match(/"/g) || []).length % 2 == 1) {
    handledQuery = query.replace(
      /"([^"]*)"/g,
      '"tempplaceholder$1tempplaceholder"'
    )
    handledQuery = handledQuery
      .replace(/"/g, '')
      .replace(/tempplaceholder/g, '"')
  }
  return handledQuery
}

export const updateURLParam = (q, navigate, param, value, reset = false) => {
  let query = q
  if (value === '' || !value) {
    delete query[param]
  } else {
    if (param === 'dates') {
      query['startDate'] = value[0]
      query['endDate'] = value[1]
      navigate(
        {
          search: `?${queryString.stringify(query)}`,
        },
        { replace: true }
      )
    } else query[param] = value
  }
  if (reset) delete query['page']

  navigate(
    {
      search: `?${queryString.stringify(query)}`,
    },
    { replace: true }
  )
}
