import React, { useEffect, useState } from 'react'
import { SERVER_URL } from '../../settings/globals'
import DataGrid from '../../DataGrid/DataGrid'
import './homepage_boxes.scss'
//imgs
import check_circle_outline from '../../../styles/assets/uswds/img/usa-icons/check_circle_outline.svg'
import edit from '../../../styles/assets/uswds/img/usa-icons/edit.svg'
import useFormatter from '../../../hooks/use-formatters'
import BoxesModal from './BoxesModal'
import useHttp from '../../../hooks/use-http'

const HomepageBoxes = () => {
  const { formatDateTime } = useFormatter()
  const [data, setData] = useState([])
  const [modalActive, setModalActive] = useState(null)

  const handleHomepageBoxesResp = (resp) => {
    const { boxes } = resp
    setData([...boxes])
  }

  const { sendRequest: handleHomepageBoxes } = useHttp(
    {
      url: `${SERVER_URL}/proxy/homepageBoxes`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleHomepageBoxesResp
  )

  useEffect(() => {
    handleHomepageBoxes()
  }, [])

  const onEdit = (e, idx) => {
    setModalActive(idx)
  }

  return (
    <div className="homepage_boxes">
      {data.map((item, idx) => (
        <div id={item.homepagebox_id} key={item.homepagebox_id}>
          <BoxesModal
            isShowing={true}
            heading={item.heading}
            body={item.text}
            onEdit={onEdit}
            id={idx}
            modalActive={modalActive}
            data={item}
            refresh={() => {
              handleHomepageBoxes()
            }}
          />
          <span
            className={`hpb-icon nac-icon display-inline-flex nac-icon--md nac-icon--primary ${
              /hbx1/i.test(item.homepagebox_id)
                ? 'nac-icon--person'
                : /hbx2/i.test(item.homepagebox_id)
                ? 'nac-icon--date'
                : 'nac-icon--comment'
            } nac-icon--thin nac-icon--bg-100`}
          ></span>
          <h2 className="hpb-heading">{item.heading}</h2>
          <div className="hpb-text">{item.text}</div>
          <div className="hpb-actions">
            <div>
              <DataGrid
                data={[data[idx]]}
                displayPager={false}
                showSort={false}
                displayHeaders={{
                  status: {
                    type: 'string',
                    sort: 'status',
                    label: 'Status',
                    value: '',
                    isVisible: true,
                    formatter() {
                      if (/^active$/i.test(this.value)) {
                        return (
                          <div
                            data-status={this.value}
                            className=" active usa-pill--success-darker border-success-darker border-2px display-inline-flex flex-align-center radius-md theme-success-darker usa-pill--success-darker padding-2px font-sans-3xs bg-success-lighter flex-justify-center margin-left-auto text-uppercase font-sans-5xs"
                          >
                            <span
                              title="Active"
                              className="text-success-darker text-bold"
                            >
                              active
                            </span>
                          </div>
                        )
                      } else if (/^inactive$/i.test(this.value)) {
                        return (
                          <div
                            data-status={this.value}
                            className="inactive usa-pill--base-darker bg-base-lighter border-2px display-inline-flex flex-align-center radius-md theme-success-darker usa-pill--success-darker padding-2px font-sans-3xs bg-success-lighter flex-justify-center margin-left-auto text-uppercase font-sans-5xs"
                          >
                            <span
                              title="Inactive"
                              className="theme-base-lighter--text text-bold"
                            >
                              inactive
                            </span>
                          </div>
                        )
                      }
                    },
                  },
                  actions: {
                    type: 'string',
                    sort: 'announcement_text',
                    label: 'Actions',
                    value: '',
                    isVisible: true,
                    formatter() {
                      return (
                        <div className="action">
                          <span className="action-item edit">
                            <img
                              title="Edit"
                              src={edit}
                              onClick={(e) => {
                                onEdit(e, idx)
                              }}
                            />
                          </span>
                          <span className={`action-item active`}>
                            <img
                              title="Make active"
                              src={check_circle_outline}
                            />
                          </span>
                        </div>
                      )
                    },
                  },
                  updated_at: {
                    type: 'string',
                    sort: 'updated_at',
                    label: 'Updated At',
                    value: '',
                    isVisible: true,
                    formatter() {
                      return (
                        <div>
                          <div className="action-date">
                            {formatDateTime(this.value)[0]}
                          </div>
                          <div className="action-time">
                            {formatDateTime(this.value)[1]}
                          </div>
                        </div>
                      )
                    },
                  },
                  user_name: {
                    type: 'string',
                    sort: 'user_name',
                    label: 'User',
                    value: '',
                    isVisible: true,
                    formatter() {
                      return this.value
                    },
                  },
                }}
              ></DataGrid>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default HomepageBoxes
