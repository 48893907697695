import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/index.scss'
import { ColorPropTypes } from '../settings/ColorPropTypes'
import { IconPropTypes } from '../settings/IconPropTypes'
import { defaultSizes } from '../settings/SizePropTypes'
import { Icon } from '../Icon/Icon'
import { returnIconAndLabelFromString } from '../../helper-functions/metadata-functions'
import useDetectPrinting from '../../hooks/use-detect-printing'

/**
 * Displays an icon with a label
 */
export const IconLabel = ({
  align,
  children,
  className,
  color,
  iconAnimation,
  iconName,
  iconPercent,
  iconPosition,
  iconSize,
  label,
  size,
  theme,
  ...props
}) => {
  let content = ''
  // Identify the correct iconName from the string provided
  content = returnIconAndLabelFromString(children)
  if (!children && !content && !label) return false

  const { isPrinting } = useDetectPrinting()

  return (
    <span
      className={[
        'display-inline-flex',
        `flex-align-${align}`,
        // 'flex-gap',
        'flex-row',
        `font-sans-${size}`,
        `${theme ? `theme-${theme}--text` : ''}`,
        `${color ? `text-${color}` : ''}`,
        `${iconPosition == 'right' ? 'flex-row-reverse' : ''}`,
        className,
      ].join(' ')}
    >
      <span
        className={`${
          children || label
            ? isPrinting
              ? 'margin-left-0 margin-right-0'
              : iconPosition == 'left'
              ? size == 'xs' || size == '2xs' || size == '3xs'
                ? 'margin-right-05'
                : 'margin-right-1'
              : size == 'xs' || size == '2xs' || size == '3xs'
              ? 'margin-left-05'
              : 'margin-left-1'
            : 'margin-right-0 margin-left-0'
        }
        display-flex
        flex-align-${align}`}
      >
        <Icon
          color={color ? color : null}
          iconName={
            iconName ? iconName : returnIconAndLabelFromString(children).icon
          }
          iconAnimation={iconAnimation}
          iconPercent={iconPercent}
          iconSize={iconSize ? iconSize : size}
          theme={theme}
          {...props}
        />
      </span>
      {label && children ? (
        <span>
          {label || returnIconAndLabelFromString(children).label}:{' '}
          {returnIconAndLabelFromString(children).label || children}
        </span>
      ) : (
        returnIconAndLabelFromString(children).label || children
      )}
    </span>
  )
}

IconLabel.defaultProps = {
  align: 'center',
  iconPercent: '100',
  iconPosition: 'left',
  size: 'sm',
}

IconLabel.propTypes = {
  align: PropTypes.string,
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * Additional classes to apply to the wrapper element
   */
  className: PropTypes.string,
  /**
   * Defines the color of the icon's SVG stroke as well as the label text.
   * This is based on a subset of the USWDS color utilities (https://designsystem.digital.gov/utilities/color/)
   */
  color: PropTypes.oneOf(ColorPropTypes),
  /**
   * Defines the CSS animation to apply to the icon
   */
  iconAnimation: PropTypes.oneOf(['rotate-cw']),
  /**
   * Defines the icon to use.
   * Names of icons should describe the icon and not the function, for maximum flexibility.
   */
  iconName: IconPropTypes,
  /**
   * Defines the background size in percent.
   * Allows for extra control over the size of the icon in relation to the label.
   */
  iconPercent: PropTypes.oneOf([
    '10',
    '20',
    '30',
    '40',
    '50',
    '60',
    '70',
    '80',
    '90',
    '100',
  ]),
  /**
   * Defines the position of the icon in relation to the label.
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * Defines the size of the icon. This is based on a subset of the USWDS size indicators.
   */
  iconSize: PropTypes.oneOf(defaultSizes),
  /**
   * The text to display next to the icon.
   */
  label: PropTypes.string,
  /**
   * Defines the size of the icon and text. This is based on a subset of the USWDS size indicators.
   */
  size: PropTypes.oneOf(defaultSizes),
  /**
   * Defines the theme (specifically the background image) on top of which this icon label will be displayed. This modifiees the color to use an accessible alternative.
   */
  theme: PropTypes.oneOf(ColorPropTypes),
}
