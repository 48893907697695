import React, { Fragment, useContext, useEffect, useState } from 'react'
import '../../styles/index.scss'
import { DEFAULT_MAX_CONTENT_WIDTH } from '../settings/globals'
import { useLocation } from 'react-router-dom'
//Context
import UserContext from '../../contexts/UserContext'
import AccountContext from '../../contexts/AccountContext'
//Components
import { Button } from '../Button/Button'
import Avatar from '../Avatar/Avatar'
import StaffPill from '../Pill/variants/StaffPill'
import useScreenSize from '../../hooks/use-screen-size'
import Modal, { ModalBody, ModalFooter } from '../Modal/Modal'
import { returnHumanDateFromTimestamp } from '../../helper-functions/date-functions'
import { IconLabelConditionalDisplay } from '../IconLabel/variants/IconLabelConditionalDisplay'
import { AccountNav } from './account_nav'
import Card from '../Card/Card'
import useModal from '../../hooks/use-modal'
import FlyOutMenu, {
  FlyOutMenuBody,
  FlyOutMenuFooter,
} from '../FlyOutMenu/FlyOutMenu'

const AccountSidebar = () => {
  const { screenSize } = useScreenSize()
  const [expanded, setExpanded] = useState(false)
  const setExpandedHandler = (bool) => {
    setExpanded(typeof bool === 'boolean' ? bool : !expanded)
  }
  const { pathname } = useLocation()
  // Close menu when on tablet size or smaller when navigating in the account
  useEffect(() => {
    if (screenSize !== 'desktop' && screenSize !== 'tablet-lg') {
      setExpandedHandler(false)
    }
  }, [pathname])
  return screenSize !== 'desktop' && screenSize !== 'tablet-lg' ? (
    <div
      className={[
        'bg-primary-dark',
        'padding-x-1',
        'padding-y-05 tablet:padding-y-0',
        'padding-y-05',
        'width-full',
      ].join(' ')}
    >
      <div
        className={[
          'display-flex',
          'flex-align-center',
          'flex-gap-xs tablet-lg:flex-gap-sm',
          'flex-justify-center',
          'flex-no-wrap',
          'flex-row',
          'font-sans-2xs',
          'margin-x-auto',
          `maxw-${DEFAULT_MAX_CONTENT_WIDTH}`,
          'minh-button',
          'text-base-lightest',
          'width-full',
        ].join(' ')}
      >
        <Button
          data-testid="nac-account--navigation-menu-button"
          iconName="menu"
          onClick={setExpandedHandler}
          reduced
          textOnly
          thin
          theme="white"
        >
          Account Navigation
        </Button>
        {expanded && (
          <FlyOutMenu
            hide={() => setExpandedHandler(false)}
            id="main-menu"
            title={'Accout Navigation'}
            position={'left'}
            theme={'white'}
          >
            <FlyOutMenuBody id="main-menu">
              <div
                className={[
                  'display-flex',
                  'flex-align-center',
                  'flex-gap-sm',
                  'flex-column',
                ].join(' ')}
                role="navigation"
              >
                <AccountHeader />
                <hr className={['border-base-lighter'].join(' ')} />
                <AccountNav />
              </div>
            </FlyOutMenuBody>
            <FlyOutMenuFooter hide={() => setExpandedHandler(false)} />
          </FlyOutMenu>
        )}
        <div className={['flex-1', 'margin-right-auto'].join(' ')}></div>
      </div>
    </div>
  ) : (
    <div
      className={[
        'display-flex',
        'flex-column',
        'grid-col-6',
        'maxw-card-lg',
        'minh-0',
        'position-relative',
        'text-base-darker',
      ].join(' ')}
    >
      <AccountHeader />
      <hr className={['border-base-lighter'].join(' ')} />
      <AccountNav />
    </div>
  )
}
AccountSidebar.propTypes = {}
export default AccountSidebar

/**
 * Identifies the account page currently displayed
 */
export const AccountHeader = () => {
  //Hooks
  const { screenSize } = useScreenSize()

  //Context
  const { accountsAccessArray } = useContext(UserContext) || {}
  const { account, loading } = useContext(AccountContext) || {}

  if (!account) return false

  //State
  const { createdAt, displayFullName, fullName, naraStaff, userId, userName } =
    account || {}

  return (
    <div
      className={['display-flex', 'flex-row', 'padding-2', 'width-full'].join(
        ' '
      )}
    >
      <div
        className={[
          'display-inline-flex',
          'flex-align-start',
          'flex-column desktop-lg:flex-row',
          'flex-gap',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            'display-flex',
            'flex-row',
            'width-full desktop-lg:width-auto',
          ].join(' ')}
        >
          {loading ? (
            <div
              className={[
                'block-load',
                'height-5',
                'radius-pill',
                'width-5',
              ].join(' ')}
            ></div>
          ) : (
            <Avatar userName={userName} size="md" />
          )}

          {screenSize !== 'desktop' && (
            <div className="margin-left-auto">
              {accountsAccessArray?.accessGrantedToAccountDetails && (
                <AdminDetails />
              )}
            </div>
          )}
        </div>
        <div
          className={[
            'display-inline-flex',
            'flex-align-start',
            'flex-column',
            'flex-gap-xs',
          ].join(' ')}
        >
          {loading ? (
            <div
              className={['block-load', 'height-2', 'width-card'].join(' ')}
            ></div>
          ) : (
            <h1
              className={[
                'display-inline-flex',
                'flex-align-center',
                'font-sans-md',
                'margin-bottom-0',
                'text-bold',
              ].join(' ')}
            >
              {displayFullName ? fullName : userName}
            </h1>
          )}
          {loading ? (
            <div
              className={['block-load', 'height-1', 'width-10'].join(' ')}
            ></div>
          ) : (
            <div
              className={[
                'display-inline-flex',
                'flex-align-center',
                'flex-gap-xs',
                'flex-row',
                'flex-wrap',
              ].join(' ')}
            >
              {naraStaff === 1 && <StaffPill />}
              <span
                className={[
                  'font-sans-3xs',
                  'text-base-dark',
                  'text-italic',
                ].join(' ')}
              >
                Member since {new Date(createdAt).getFullYear()}
              </span>
            </div>
          )}
        </div>
      </div>
      {screenSize === 'desktop' && (
        <div className="margin-left-auto">
          {accountsAccessArray?.accessGrantedToAccountDetails && (
            <AdminDetails />
          )}
        </div>
      )}
    </div>
  )
}
AccountHeader.propTypes = {}

/** Additional account information only to be accessed by nara moderators or admins */
export const AdminDetails = () => {
  //Context
  const { account } = useContext(AccountContext) || {}
  const { createdAt, fullName, lastLoggedInAt, role, userName } = account || {}

  //Hooks
  const { isShowing, toggle } = useModal()

  return (
    <>
      {' '}
      <Button
        className={['clickable', 'flex-justify-center', 'padding-0'].join(' ')}
        iconName="option2"
        iconOnly
        iconPercent="80"
        iconSize="sm"
        onClick={toggle}
        reduced
        shape="pill"
        srText="Access more details about this user"
        textOnly
        theme="base"
        thin
      />
      {isShowing && (
        <Modal
          className="width-max-content"
          hide={toggle}
          id="account-details"
          title="User account details"
        >
          <Fragment>
            <ModalBody id="modal-user-info">
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'flex-gap-sm',
                  'text-left',
                  'width-full',
                ].join(' ')}
              >
                <IconLabelConditionalDisplay displayTo="moderators" />
                <div className={['display-flex', 'flex-column'].join(' ')}>
                  <strong>Username</strong>
                  <p>{userName}</p>
                </div>
                <div className={['display-flex', 'flex-column'].join(' ')}>
                  <strong>Full Name</strong>
                  <p>{fullName}</p>
                </div>
                <div className={['display-flex', 'flex-column'].join(' ')}>
                  <strong>Registered Date</strong>
                  <p>{returnHumanDateFromTimestamp(createdAt)}</p>
                </div>
                <div className={['display-flex', 'flex-column'].join(' ')}>
                  <strong>Role</strong>
                  <p>{role}</p>
                </div>
                {lastLoggedInAt && (
                  <div className={['display-flex', 'flex-column'].join(' ')}>
                    <strong>Last Active Date</strong>
                    <p>{returnHumanDateFromTimestamp(lastLoggedInAt)}</p>
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter hide={toggle}>
              <div className={['display-flex', 'width-full'].join(' ')}>
                {/*<Button
                  className={['margin-right-auto '].join(' ')}
                  data-testid="nac-users_info-print"
                  disabled
                  iconName="fax"
                  iconSize="xs"
                  // onClick={() => alert('print!')}
                  reduced
                  size="2xs"
                  textOnly
                >
                  Print
                </Button>*/}
                <Button
                  className={['margin-left-auto '].join(' ')}
                  data-testid="nac-users_info-ok-close"
                  size="2xs"
                  onClick={toggle}
                  reduced
                >
                  OK
                </Button>
              </div>
            </ModalFooter>
          </Fragment>
        </Modal>
      )}
    </>
  )
}
AdminDetails.propTypes = {}
