import React from 'react'
// import { ActivityIndicator, View, Text, Dimensions } from 'react'

const Loader = (props) => {
  return (
    <div className="loading__wrapper"></div>
    // <ActivityIndicator animating={true} color={'black'} size="large" />
  )
}

export default Loader
