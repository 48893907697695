import React from 'react'
import PropTypes from 'prop-types'
import { Outlet, useLocation, useParams } from 'react-router-dom'
//Context
import ModerateContributionsContext from '../../../contexts/ModerateContributionsContext'
import ModerateComments from './ModerateComments'
import useModerateContributions from './use-moderate-contributions'

const ModeratorContextWrapper = (children) => {
  //Hooks
  const { page, moderationPage } = useParams()
  const { state } = useLocation()
  const moderatorState = useModerateContributions(state?.contributionType)
  return (
    <ModerateContributionsContext.Provider value={moderatorState}>
      <Outlet />
    </ModerateContributionsContext.Provider>
  )
}

ModeratorContextWrapper.propTypes = {
  children: PropTypes.any,
}
export default ModeratorContextWrapper
