/**
 * Schema for validating tag inputs
 */
export const tagInputSchema = {
  autoComplete: 'off',
  id: 'addTag',
  label: 'tag',
  loggedIn: true,
  maxlength: 100,
  placeholder: 'Enter new tags',
  preventDuplicates: true,
  required: true,
  srText: 'Tag input field',
  type: 'text',
}
