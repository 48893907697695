import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import UserContext from '../../contexts/UserContext'
import useScreenSize from '../../hooks/use-screen-size'
import BasicLink from '../BasicLink'
import { Button } from '../Button/Button'
import { DISABLE_LOGIN } from '../settings/globals'
import Tooltip from '../Tooltip/Tooltip'

const useHeader = () => {
  //Context
  const { login, logout } = useContext(UserContext) || {}
  //Hooks
  const { screenSize } = useScreenSize()
  //State
  const [expanded, setExpanded] = useState(false)

  const setExpandedHandler = (bool) => {
    setExpanded(typeof bool === 'boolean' ? bool : !expanded)
  }

  useEffect(() => {
    setExpandedHandler(false)
  }, [screenSize])

  const notLoggedInNavJSX = (
    <>
      {!DISABLE_LOGIN && (
        <li className={['flex-shrink-0'].join(' ')}>
          <Button
            data-testid="nac-header--login"
            displayClass="flex"
            onClick={login}
            reduced
            thin
            theme="primary"
            size="xs"
          >
            <span>Log in / Sign Up</span>
          </Button>
        </li>
      )}
    </>
  )

  const loggedInNavJSX = (
    <div>
      {!DISABLE_LOGIN && (
        <Button
          className={[
            'margin-bottom-neg-1',
            'margin-top-neg-1',
            'theme-primary-darker',
            'theme-primary-darker--text',
          ].join(' ')}
          data-testid="nac-header--logout"
          // iconName="action-outRight"
          // iconSize="2xs"
          onClick={logout}
          outline
          reduced
          size="3xs"
          theme="white"
          thin
        >
          Log out
        </Button>
      )}
    </div>
  )

  const navJSX = (
    <>
      {/** USABILITY: Present links in priority order.
       *  Higher-demand links should appear farther to the left,...
       *  ...and lower-demand links should appear farther to the right.
       */}
      {/** USABILITY: Highlight the current section.
       *  Show users where they are within the site by highlighting the current section.
       */}
      {navSchema.map((nav, index) => (
        <li className="display-flex flex-align-center flex-row" key={nav.id}>
          <NavLink
            className={[
              'usa-link--white',
              //
              'font-sans-sm',
            ].join(' ')}
            data-testid={`nac-header--${nav.id}`}
            onClick={() => setExpanded(false)}
            to={nav.path}
          >
            {nav.title}
          </NavLink>
        </li>
      ))}
      <li className="display-flex flex-align-center flex-row">
        <BasicLink
          className={[
            'usa-link--white',
            //
            'display-flex flex-align-center flex-row',
            'font-sans-sm',
          ].join(' ')}
          data-testid={`nac-header--help`}
          onClick={() => setExpanded(false)}
          target="_blank"
          href="https://www.archives.gov/research/catalog/help/using"
        >
          Help
        </BasicLink>
      </li>
    </>
  )

  return {
    expanded,
    loggedInNavJSX,
    navJSX,
    notLoggedInNavJSX,
    setExpandedHandler,
  }
}
export default useHeader

export const navSchema = [
  {
    // iconName: 'option-sliders',
    id: 'advanced-search',
    path: '/advanced-search',
    title: 'Advanced',
  },
  // {
  //   // iconName: 'circle-questions',
  //   id: 'help',
  //   path: '/help'
  //   title: 'Help',
  // },
]
