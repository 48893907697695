import React, { Fragment } from 'react'
import '../../../../../styles/index.scss'

export const stepsToSearchSimple = [
  {
    title: 'Locate the search box.',
    description: (
      <Fragment>
        Search boxes are available throughout the site, including on the{' '}
        <a href="/">home page</a>, and in the top header of most pages.
      </Fragment>
    ),
  },
  {
    title: 'Enter your search.',
    aside: (
      <Fragment>
        If you enter more than one word, the Catalog will look for results that
        contain <em>all</em> of the words entered.
      </Fragment>
    ),
    media: [
      {
        alt: 'This is meaningful alt text',
        caption: 'This is a caption',
        src: '/images/errors/12462518.jpg',
        type: 'image',
      },
    ],
  },
  {
    title: 'Start searching!',
    description: (
      <Fragment>
        <p>
          <strong>Press enter</strong> on your keyboard, or{' '}
          <strong>click on</strong> the magnifying glass icon, to begin the
          search.
        </p>
      </Fragment>
    ),
    media: [
      {
        alt: 'This is meaningful alt text',
        caption: 'This is a caption',
        src: 'RekMxGh9irc',
        type: 'youtube',
      },
    ],
  },
]

export const searchSteps = [
  {
    description:
      'Searching the Catalog is as simple as typing a word or two in one of the search boxes. In this section we will cover the most basic way to search. In later sections we will review more advanced search options.',
    id: 'account-log-in',
    steps: stepsToSearchSimple,
    title: 'How to search the Catalog.',
  },
]
