import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import {
  returnDateAndTimeObjFromTimestamp,
  timeSince,
} from '../../../helper-functions/date-functions'
import { convertCamelToSentence } from '../../../helper-functions/string-functions'
import BasicLink from '../../BasicLink'
import Highlighter from '../../Highlighter/Highlighter'
import { Icon } from '../../Icon/Icon'

import Truncate from '../../utilities/Truncate'

const useContributionTable = (data, search) => {
  const { feedPage } = useParams()
  const [table, setTable] = useState([])
  useEffect(() => {
    let newRows = []
    data?.map(
      ({
        contribution,
        contributionId,
        contributionType,
        createdAt,
        recordTitle,
        targetNaId,
        targetObjectId,
        updatedAt,
      }) => {
        newRows.push({
          type: <Icon iconName={contributionType} />,
          contributions: (
            <div
              className={['display-flex', 'flex-gap-sm', 'flex-row'].join(' ')}
            >
              <Icon iconName={contributionType} iconSize="xs" color="base" />
              {/*<div className="ellipsed-line-4">{contribution}</div>*/}
              <div className="overflow-hidden">
                <Truncate maxWords={20} term={search}>
                  {contribution.replace(/\r?\n|\r/g, ' ')}
                </Truncate>
              </div>
            </div>
          ),
          record: recordTitle ? (
            <Link
              className="ellipsed-line-2"
              to={`/id/${targetNaId}?${
                targetObjectId
                  ? `objectPanel=${contributionType}&contributionId=${contributionId}`
                  : `contributionId=${contributionId}`
              }`}
            >
              {recordTitle}
            </Link>
          ) : (
            <span className="font-sans-2xs text-base-dark text-italic">
              Not Available
            </span>
          ),
          naId: (
            <Highlighter search={search ? search : ''}>
              {targetNaId.toString()}
            </Highlighter>
          ),
          date: (
            <div className="display-flex flex-column">
              <span>
                {returnDateAndTimeObjFromTimestamp(updatedAt || createdAt).date}
              </span>
              <span>
                {returnDateAndTimeObjFromTimestamp(updatedAt || createdAt).time}
              </span>
              {/*<span>({timeSince(updatedAt || createdAt)})</span>*/}
            </div>
          ),
        })
      }
    )

    setTable(newRows)
  }, [data])

  const columns = useMemo(
    () => [
      {
        Header: feedPage
          ? convertCamelToSentence(feedPage).slice(0, -1)
          : 'Contribution',
        accessor: 'contributions',
        id: 'contributions',
        className: 'grid-col-6',
      },
      {
        Header: 'Record Title',
        accessor: 'record',
        id: 'record',
        className: 'grid-col-4',
      },
      {
        Header: 'Record NAID',
        accessor: 'naId',
        id: 'naId',
        className: 'width-10 tablet-lg:width-15',
      },
      {
        Header: 'Date',
        accessor: 'date',
        id: 'date',
        className: 'width-15 tablet-lg:width-card',
      },
    ],
    [data]
  )
  return { columns, table }
}

export default useContributionTable
