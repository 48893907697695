import React, { useState, useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import Portal from '../utilities/Portal'
import { isOutOfViewport } from '../../helper-functions/spatial-funcitons'
import { stripHtml } from '../../helper-functions/string-functions'
import useEventListener, { ESCAPE_KEYS } from '../../hooks/use-event-listener'
/**
 * Tooltips are a short descriptive message that appears when a user hovers or focuses on an element.
 *
 */
const Tooltip = ({
  children,
  className,
  content,
  delay,
  disabled,
  displayClass,
  position,
  show,
  ...props
}) => {
  let timeout
  const [width, setWidth] = useState(0)
  const [coords, setCoords] = useState({})
  const [active, setActive] = useState(false)
  const [bestPosition, setBestPosition] = useState(position)
  const [tipHeight, setTipHeight] = useState(0)

  const wrapper = useRef()
  const tip = useRef()

  useEffect(() => {
    if (tip?.current) {
      let rect = tip.current.getBoundingClientRect()
      setTipHeight(rect.height)
    }
    return () => setTipHeight(0)
  }, [active, tip.current])

  useEffect(() => {
    if (show) setActive(true)
    else setActive(false)
  }, [show])

  const offset = (el, position) => {
    //const tipRect = tip.current?.getBoundingClientRect()
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop,
      w = rect.width,
      h = rect.height
    const top = rect.top + scrollTop,
      right = rect.left + scrollLeft + w + 'px',
      bottom = rect.top + scrollTop + h + 'px',
      left = rect.left + 'px',
      centerVert = rect.top + scrollTop + h / 2 - tipHeight / 2,
      centerHoriz = rect.left + scrollLeft + w / 2
    setWidth(w)

    switch (position) {
      case 'rightCenter':
        return {
          top: centerVert,
          left: right,
          size: w,
        }
      case 'leftCenter':
        return {
          top: centerVert,
          left: left,
          size: w,
        }
      case 'topLeft':
        return {
          top: top,
          left: left,
          size: w,
        }
      case 'topCenter':
        return {
          top: top - tipHeight,
          left: centerHoriz,
          size: w,
        }
      case 'top':
        return {
          top: top,
          left: centerHoriz,
          size: w,
        }
      case 'topRight':
        return {
          top: top,
          left: right,
          size: w,
        }
      case 'bottomLeft':
        return {
          top: bottom,
          left: left,
          size: w,
        }
      case 'bottomCenter':
        return {
          top: bottom,
          left: centerHoriz,
          size: w,
        }
      case 'bottom':
        return {
          top: bottom,
          left: centerHoriz,
          size: w,
        }
      case 'bottomRight':
        return {
          top: bottom,
          left: right,
          size: w,
        }
      default:
        return {
          top: bottom,
          left: left,
          size: w,
        }
    }
  }
  const label = ''
  // const label =
  //   stripHtml(ReactDOMServer.renderToStaticMarkup(children)) +
  //   ', ' +
  //   stripHtml(ReactDOMServer.renderToStaticMarkup(content))

  const elements = React.Children.toArray(children).map((element) =>
    React.cloneElement(element, {
      'aria-label': label,
      'aria-describedby': active ? 'extrainfo' : null,
      onMouseEnter: (e) => {
        showTip(e)
      },
      onFocus: (e) => {
        showTip(e)
      },
      onMouseLeave: () => {
        hideTip()
      },
      onBlur: () => {
        hideTip()
      },
      onKeyPress: (e) => handleKeyPress(e),
      // role: 'tooltip',
    })
  )

  const showTip = () => {
    timeout = setTimeout(() => {
      handleSetCoords()
      setActive(true)
    }, delay)
  }

  const hideTip = () => {
    setCoords({})
    clearInterval(timeout)
    setActive(false)
  }

  const handleUpdatePosition = (elem) => {
    const isOut = isOutOfViewport(elem)
    if (isOut.left) setBestPosition('bottomLeft')
    if (isOut.right) setBestPosition('bottomRight')
  }

  const handleSetCoords = () => {
    setCoords(
      wrapper && wrapper.current ? offset(wrapper.current, bestPosition) : {}
    )
  }

  useEffect(() => {
    if (tipHeight > 0 || bestPosition) handleSetCoords()
  }, [tipHeight, bestPosition])

  useEffect(() => {
    if (tipHeight > 0 || bestPosition) handleSetCoords()
  }, [tipHeight, bestPosition])

  const handleKeyPress = ({ key }) => {
    if (active && ESCAPE_KEYS.includes(String(key))) hideTip()
  }

  const handleSetTip = (event) => {
    if (!wrapper.current || !active) return false
    if (!wrapper.current.contains(event.target)) {
      hideTip()
    }
  }

  useEventListener('keydown', handleKeyPress)
  useEventListener('mousemove', handleSetTip)
  useEventListener('resize', handleSetCoords)

  useEffect(() => {
    if (wrapper && wrapper.current && active) {
      setWidth(offset(wrapper.current).width)
    }
    if (tip && tip.current && active) {
      handleUpdatePosition(tip.current)
    }
    if (!active) setBestPosition(position)
    return () => {
      setWidth(0)
      setBestPosition(position)
    }
  }, [active])

  if (disabled) return children
  else {
    if (content) {
      return (
        <div
          className={[
            'tooltip-wrapper',
            displayClass ? `display-inline-${displayClass}` : 'display-block',
            tipHeight > 0 ? '' : 'display-hidden',
            className,
          ].join(' ')}
          ref={wrapper}
          {...props}
        >
          {elements}
          {active && (
            <Portal
              className={[
                active ? 'active' : '',
                `anim-duration-500ms`,
                //`anim-delay-${delay}ms`,
                'anim-opacity',
                'z-top',
              ].join(' ')}
            >
              <div
                data-parent-width={width + 'px'}
                className={[
                  'tooltip-tip',
                  //
                  // `anim-duration-500ms`,
                  // `anim-delay-${delay}ms`,
                  // 'anim-opacity',
                  'diplay-block',
                  'maxw-card-lg',
                  bestPosition ? bestPosition : position,
                  'z-top',
                ].join(' ')}
                id="extrainfo"
                ref={tip}
                role="tooltip"
                style={coords}
              >
                {content}
              </div>
            </Portal>
          )}
        </div>
      )
    }

    return elements
  }
}

Tooltip.defaultProps = {
  children: '',
  content: '',
  delay: 250,
  displayClass: 'flex',
  position: 'bottomCenter',
}

Tooltip.propTypes = {
  /**
   * Default position of the tooltip and alignment of the arrow
   * Will be overwritten if the tooltip is displayed outside of the viewport
   */
  position: PropTypes.oneOf([
    'topLeft',
    'topCenter',
    'top',
    'topRight',
    'rightTop',
    'rightCenter',
    'rightBottom',
    'right',
    'bottomRight',
    'bottomCenter',
    'bottomLeft',
    'bottom',
    'leftBottom',
    'leftCenter',
    'leftTop',
    'left',
  ]),
  /**
   * Time in milliseconds before tooltip is displayed
   */
  delay: PropTypes.oneOf([0, 250, 500, 750, 1000]),
  /**
   * When true, prevents the Tooltip from displaying
   */
  disabled: PropTypes.bool,
  displayClass: PropTypes.oneOf(['block', 'flex']),
  /**
   * JSX element(s), the parent of which triggers the tooltip
   */
  children: PropTypes.object,
  /**
   * Classes passed from parent component
   */
  className: PropTypes.string,
  /**
   * String of JSX within the tooltip
   */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /**
   * Force the tooltip to display
   */
  show: PropTypes.bool,
}

export default Tooltip
