import React from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
//Components
import DropdownField from '../../../form/DropdownField'
//Hooks
import { useSearchParams } from 'react-router-dom'
import useSortDropdown from './useSortDropdown'

export const DropdownSort = ({ id, label, schema, ...props }) => {
  const { active, changeHander } = useSortDropdown(schema)

  return (
    <DropdownField
      onChange={changeHander}
      value={schema.some(({ value }) => value === active) ? active : ''}
      label={label}
      id={id}
      data-testid={`nac-${id}`}
      {...props}
    >
      {schema.map(({ label, value }) => (
        <option data-testid={`nac-${id}-${value}`} key={value} value={value}>
          {label}
        </option>
      ))}
    </DropdownField>
  )
}

DropdownSort.defaultProps = {
  label: 'Sort by:',
}

DropdownSort.propTypes = {
  /**
   * Unique identifier for the sort dropdown
   */
  id: PropTypes.string,
  /**
   * Label displayed before the dropdown
   */
  label: PropTypes.string,
  /**
   * Defines the dropdown options and related url parameters
   */
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
}
