const { SEARCH_HOST } = require('../../config.js')

//API Defaults
export const SEARCH_URL = `${SEARCH_HOST}/proxy`
export const SERVER_URL = SEARCH_HOST
export const SEARCH_TIMEOUT = 10000 //time in milliseconds

//Search Defaults
export const DEFAULT_LIMIT = 20
export const DEFAULT_PAGE = 1
export const DEFAULT_AUTHORITY_RECORD_INDEX = 0

//Display/Copy Defaults
export const DEFAULT_COPY_LINK_TEXT = 'Copy shareable link'
export const DEFAULT_COPIED_LINK_TEXT = 'Link copied!'
export const DEFAULT_MAX_COPY_WIDTH = 'tablet-lg'
export const DEFAULT_MAX_CONTENT_WIDTH = 'desktop-lg margin-x-auto'

//IIIF Defaults
export const IIIF_PATH_THUMB = '/full/150,/0/default.jpg'
export const IIIF_PATH_MD = '/full/640,/0/default.jpg'
export const IIIF_PATH_LRG = '/full/1024,/0/default.jpg'

//Contribution Defaults
export const DEFAULT_CONTENTS_PANEL = false
export const DEFAULT_PANEL = null

//Transcription Defaults
export const TRANSCRIPTION_INACTIVITY_TIMEOUT_INTERVAL = 3300000 // 55 min: 55m * 60s * 1000ms = 3,300,000
// export const TRANSCRIPTION_INACTIVITY_TIMEOUT_INTERVAL = 300000 // 5 min: 5m * 60s * 1000ms = 300,000
export const TRANSCRIPTION_INACTIVITY_COUNTDOWN_TIME = 300000 // 5 min: 5m * 60s * 1000ms = 300,000
export const TRANSCRIPTION_AUTO_SAVE_INTERVAL = 1000
export const TRANSCRIPTION_INPUT_DEBOUNCE = 2000

//User Page Defaults
export const DEFAULT_FEED_PAGE = 1
export const DEFAULT_CONTRIBUTION_TABLE_LIMIT = 25
export const DEFAULT_CONTRIBUTION_FEED_LIMIT = 25
export const DEFAULT_CONTRIBUTION_VIEW = 'feed'

// Limits the number of naIds you can search for at one time
// in the online availability header Moderator Dashboard.
// A limit is necessary at this time because we are returning all records
// and "joining" the data from two databases, where we paginate on the frontend.
export const MAX_AVAILABILITY_RESULT_LIMIT = 1000

export const DISABLE_LOGIN = false
// SEARCH_HOST === 'https://preview.catalog.archives.gov' // SEARCH_HOST.indexOf('preview') > 0

//Highlighter
export const DEFAULT_HIGHLIGHTER_STATE = true

//JSON
export const DISABLE_JSON = SEARCH_HOST === 'https://catalog.archives.gov' // Only disabled for this URL

//Display content related to the rollover from the old site to NGC
export const ROLLOVER_CONTENT = true
