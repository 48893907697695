import React, { useContext } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import ExpandCollapseList from '../utilities/ExpandCollapseList'
import { Link } from 'react-router-dom'
import { Icon } from '../Icon/Icon'
import { SectionHeading } from '../SectionHeading/SectionHeading'
//Helpers
import {
  compareStrings,
  compareNumbers,
} from '../../helper-functions/sort-functions'
import SearchContext from '../../contexts/SearchContext'
import useHighlightList from '../../hooks/use-highlight-list'
import Highlighter from '../Highlighter/Highlighter'

const Subjects = ({ ...props }) => {
  const { record } = useContext(RecordContext) || {}

  const subjects = record.subjects
  let subjectObj = [
    { title: 'Organizations', items: [], iconName: 'group' },
    { title: 'People', items: [], iconName: 'person' },
    { title: 'Places', items: [], iconName: 'location' },
    { title: 'Media Types', items: [], iconName: 'target' },
    { title: 'Topics', items: [], iconName: 'bookmark' },
  ]

  if (subjects) {
    subjects
      .sort((a, b) => {
        return compareStrings(a.heading, b.heading)
      })
      .map((sub) => {
        let index = null
        switch (sub.authorityType) {
          case 'organization':
            index = 0
            break
          case 'person':
            index = 1
            break
          case 'geographicPlaceName':
            index = 2
            break
          case 'specificRecordsType':
            index = 3
            break
          case 'topicalSubject':
            index = 4
            break
        }

        index >= 0 &&
          subjectObj[index].items.push({
            heading: sub.heading,
            naId: sub.naId,
          })
        //index == 4 && (subjectObj[index].title = 'Topics')
      })
  }

  subjectObj = subjectObj
    ?.filter((sub) => {
      return sub.items.length > 0
    })
    .sort((a, b) => {
      return compareNumbers(a.items.length, b.items.length)
    })

  if (!subjects || subjectObj.length == 0) return false

  return (
    <div
      className={['flex-align-start', 'flex-column', 'width-full'].join(' ')}
    >
      <SectionHeading copyLink count={subjects.length} {...props}>
        Subjects and References
      </SectionHeading>
      <div
        className={['display-flex', 'flex-gap-lg', 'flex-row', 'grid-row'].join(
          ' '
        )}
      >
        {subjectObj.map((sub) => (
          <div
            className={[
              'display-flex',
              'flex-column',
              'tablet-lg:grid-col',
              'width-auto',
              'maxw-mobile',
            ].join(' ')}
            data-testid={`nac-description__subjects--${sub.title
              .toLowerCase()
              .replace(' ', '-')}`}
            key={sub.title}
          >
            <h3 className={[].join(' ')}>
              {sub.title}
              <span className="usa-sr-only">
                . This section contains {sub.items.length} items.
              </span>
            </h3>

            <Subject array={sub.items} />
          </div>
        ))}
      </div>
    </div>
  )
}

Subjects.defaultProps = {
  subjects: [],
}

Subjects.propTypes = {
  subjects: PropTypes.array,
}

export default Subjects

export const Subject = ({ array, ...props }) => {
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')
  const { sortedArray, count } = useHighlightList(q, array, ['heading'], 6)
  if (!sortedArray) return false
  return (
    <ul
      className={[
        'add-list-reset',
        'grid-col-6',
        'list-with-margin',
        'width-full',
      ].join(' ')}
    >
      <ExpandCollapseList flex={false} id="subjects-list" initial={count}>
        {sortedArray?.map((item) => (
          <li key={item.naId}>
            <Link
              className={`theme-white--link`}
              data-testid={`nac-subjects-link--${item.naId}`}
              to={{
                pathname: '/id/' + item.naId,
              }}
              state={{
                controlGroup: '0',
              }}
            >
              <div>
                <Icon iconName={array.iconName} iconSize="xs" />
                <Highlighter search={q}>{item.heading}</Highlighter>
                <span className="usa-sr-only">, </span>
              </div>
            </Link>
          </li>
        ))}
      </ExpandCollapseList>
    </ul>
  )
}

Subject.propTypes = {
  array: PropTypes.array,
}
