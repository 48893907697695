import React, { useContext, useEffect, useMemo, useState } from 'react'
import AccountContext from '../../../contexts/AccountContext'
import { convertCamelToSentence } from '../../../helper-functions/string-functions'
import StatusPill from '../../Pill/variants/StatusPill'
import { CellNumber } from './Insights'

const useAdminInsights = () => {
  const accountState = useContext(AccountContext)

  const [table, setTable] = useState([])

  const data = [
    {
      label: 'Active',
      id: 'total',
    },
    {
      label: 'Unique Records Contributed to',
      id: 'uniqueRecordsWith',
    },
    {
      label: 'Record-level',
      id: 'recordLevel',
    },
    {
      label: 'Object-level',
      id: 'objectLevel',
    },
    {
      label: 'Flagged',
      id: 'flagged',
      pill: true,
    },
    {
      label: 'Disabled',
      id: 'disabled',
      pill: true,
    },
    {
      label: 'Not Reviewed',
      id: 'unmoderated',
      pill: true,
    },
    {
      label: 'Reviewed',
      id: 'reviewed',
      pill: true,
    },
  ]
  useEffect(() => {
    let newRows = []
    const totals = accountState?.contributionTotals?.totals
    data.map(({ label, id, pill }) => {
      newRows.push({
        columnHeader: pill ? (
          <StatusPill status={id} icon label={label} />
        ) : (
          <span className="display-block padding-y-05 text-normal">
            {' '}
            {label}
          </span>
        ),
        comments: {
          count: totals?.[`${id}Comments`],
        },
        tags: {
          count: totals?.[`${id}Tags`],
        },
        transcriptions: {
          count: totals?.[`${id}Transcriptions`],
        },
        totals: {
          count:
            totals?.[
              `${
                id !== 'total'
                  ? `total${convertCamelToSentence(id)}`
                  : 'totalContributions'
              }`
            ],
        },
      })
    })

    setTable(newRows)
  }, [accountState?.contributionTotals])

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'columnHeader',
        id: 'columnHeader',
        // className: 'grid-col-1',
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        id: 'comments',
        className: 'text-center',
        Cell: (cell) => <CellNumber number={cell.value.count} />,
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        id: 'tags',
        className: 'text-center',
        Cell: (cell) => <CellNumber number={cell.value.count} />,
      },
      {
        Header: 'Transcriptions',
        accessor: 'transcriptions',
        id: 'transcriptions',
        className: 'text-center',
        Cell: (cell) => <CellNumber number={cell.value.count} />,
      },
      {
        Header: 'Totals',
        accessor: 'totals',
        id: 'totals',
        className: 'text-center',
        Cell: (cell) => <CellNumber number={cell.value.count} />,
      },
    ],
    [accountState]
  )
  return { columns, table }
}

export default useAdminInsights
