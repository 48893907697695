import React from 'react'
import '../../../styles/index.scss'
// import PropTypes from 'prop-types'
//Settings
import { description } from '../../settings/RecordPropTypes'
//Helpers
import { returnIconAndLabelFromString } from '../../../helper-functions/metadata-functions'

const Level = ({ record }) => {
  if (!record) return false

  let level = ''
  if (record.levelOfDescription) {
    if (record.levelOfDescription == 'recordGroup')
      level = `Record Group ${record.recordGroupNumber}`
    else if (record.levelOfDescription == 'collection')
      level = `Collection ${record.collectionIdentifier}`
    else level = returnIconAndLabelFromString(record.levelOfDescription).label
  } else if (record.authorityType)
    level = returnIconAndLabelFromString(record.authorityType).label

  if (!level) return false

  return (
    <span
      className={[
        'clearfix',
        'display-inline-block',
        'font-sans-2xs',
        'line-height-sans-3',
        'maxw-tablet',
        'text-base',
        'text-italic',
      ].join(' ')}
    >
      {level}
    </span>
  )
}

Level.defaultProps = {}

Level.propTypes = {
  /**
   * The entire data object for a single record
   */
  record: description,
}

export default Level
