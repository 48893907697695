import React, { useContext, useState } from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
//Settings
import { SEARCH_URL } from '../../../settings/globals'
//Context
import ContributionContext from '../../../../contexts/ContributionContext'
import UserContext from '../../../../contexts/UserContext'
//Components
import LogInPrompt from '../LogInPrompt'
import { Button } from '../../../Button/Button'
//Hooks
import useHttp from '../../../../hooks/use-http'
import useInput from '../../../../hooks/use-input'
//Helper Functions
import {
  validateInputFromSchema,
  removeExtraWhiteSpace,
  ValidationError,
} from '../../../form/Validate/Validate'
import { commentInputSchema } from '../../../../schemas/comment-input-schema'
import { IconLabel } from '../../../IconLabel/IconLabel'
import RecordContext from '../../../../contexts/RecordContext'
import { ListSeparator } from '../../../ListSeparator/ListSeparator'
import BasicLink from '../../../BasicLink'

const CommentInput = ({
  objectId,
  comment,
  defaultText,
  editing,
  parentCommentId,
  replying,
  setEditingHandler,
  setReplyingHandler,
  ...props
}) => {
  const { commentsDispatch } = useContext(ContributionContext)
  const { naId } = useContext(RecordContext)
  const { loggedInUser } = useContext(UserContext)

  const [commentInputError, setCommentInputError] = useState(null)

  const handleCommentInputError = (error) => {
    setCommentInputError(
      typeof error === 'string' ? new ValidationError(error) : error
    )
  }

  const {
    isValid: enteredCommentIsValid,
    value: enteredComment,
    inputBlurHandler: commentBlurHandler,
    reset: resetCommentInput,
    valueChangeHandler: commentChangedHandler,
  } = useInput(
    (value) => validateInputFromSchema(commentInputSchema, value),
    handleCommentInputError,
    editing ? comment.contribution : null
  )

  const sanitizedInput = removeExtraWhiteSpace(
    DOMPurify.sanitize(enteredComment)
  )

  // When replying, the parent is this comment
  const handleCommentResponse = (data) => {
    if (!data.contributionId) {
      setCommentInputError('Something went wrong. Please try again.')
      return false
    }

    if (loggedInUser.role === 'administrator') data.madeByAdministrator = 1
    if (
      loggedInUser.role === 'administrator' ||
      loggedInUser.role === 'moderator'
    )
      data.naraStaff = 1

    commentsDispatch({
      type: 'ADD_COMMENT',
      userId: data.userId,
      contribution: data.contribution,
      contributionId: data.contributionId,
      createdAt: data.responseTimestamp,
      // fullName: 'Generic NGC Moderator',
      madeByAdministrator: data.madeByAdministrator,
      naraStaff: data.naraStaff,
      parentCommentId: parentCommentId || null, //determines whether comment is a reply
      parentContributionId: comment.contributionId || null, //determines whether comment is a reply
      targetNaId: data.targetNaId,
      targetObjectId: data.targetObjectId,
      updatedAt: data.responseTimestamp,
      userName: loggedInUser?.userName,
    })

    resetCommentInput()
  }

  const { isLoading: postingComment, sendRequest: addCommentRequestHandler } =
    useHttp(
      {
        url: `${SEARCH_URL}/comments/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          comment: sanitizedInput,
          targetNaId: parseInt(naId),
          targetObjectId: objectId ? objectId : null,
          userId: loggedInUser?.userId,
          parentContributionId: comment.contributionId || null,
        },
      },
      handleCommentResponse,
      handleCommentInputError
    )

  // When editing, the parent is this comments parent
  const handleEditCommentResponse = (data) => {
    if (!data.contributionId) {
      setCommentInputError('Something went wrong. Please try again.')
      return false
    }

    commentsDispatch({
      type: 'EDIT_COMMENT',
      contribution: data.comment,
      updatedAt: data.responseTimestamp,
      contributionId: data.contributionId,
      parentContributionId: comment.parentContributionId || null,
    })

    resetCommentInput()
  }

  const { sendRequest: editCommentRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/comments/${comment.contributionId}`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        comment: sanitizedInput,
      },
    },
    handleEditCommentResponse,
    handleCommentInputError
  )

  const formSubmitionHandler = (event) => {
    //Prevent submittion of invalid comment or provide console warning
    event.preventDefault()
    if (!enteredCommentIsValid && commentInputError?.type === 'error') {
      //If this error is occuring, we are most likely dealing with a bad actor
      //Is there any way to log this behavior and/or flag this registered user?
      alert(
        'Failed to submit comment: The comment did not pass validation.',
        commentInputError?.message
      )
      return
    }
    if (!sanitizedInput) {
      alert(
        'Failed to submit comment: The sanitized input resulted in an empty string.'
      )
      return
    }
    if (!naId) {
      alert(
        'Failed to submit comment: The required attribute "parentID" was not provided.'
      )
      return
    }

    if (editing) {
      editCommentRequestHandler()
      setEditingHandler(false)
    } else if (replying) {
      addCommentRequestHandler()
      setReplyingHandler(false)
    } else {
      addCommentRequestHandler()
    }
  }

  const errorInputClasses =
    commentInputError?.type === 'error'
      ? 'invalid border-error-dark bg-error-lighter'
      : commentInputError?.type === 'warning'
      ? 'warning border-accent-warm-dark bg-accent-warm-lighter'
      : ''
  const errorMessageClasses =
    commentInputError?.type === 'error'
      ? 'text-error-dark'
      : commentInputError?.type === 'warning'
      ? 'text-warning-dark'
      : ''
  return (
    <>
      {loggedInUser?.userId ? (
        <form id="user-comment-input" onSubmit={formSubmitionHandler}>
          <div className="display-flex flex-column">
            <div
              className={[
                'display-flex',
                'flex-align-start',
                'flex-no-wrap',
                'flex-row',
                'flex-gap-sm',
              ].join(' ')}
            >
              <label
                htmlFor={commentInputSchema['id']}
                className={['flex-column', 'grid-col', 'margin-right-1'].join(
                  ' '
                )}
              >
                <p className="usa-sr-only">{commentInputSchema['srText']}</p>
                <input
                  aria-invalid={commentInputError?.type === 'error'}
                  aria-required={commentInputSchema['required']}
                  autoComplete={commentInputSchema['autoComplete']}
                  className={[
                    'usa-input',
                    errorInputClasses,
                    'bg-white',
                    'border-base-dark',
                    'border-1px',
                    'padding-left-2',
                    'padding-right-4',
                    'margin-top-05',
                    'maxw-full',
                    'radius-md',
                  ].join(' ')}
                  data-testid="nac-comments_input"
                  id={commentInputSchema['id']}
                  maxLength={commentInputSchema['maxlength']}
                  minLength={commentInputSchema['minlength']}
                  name={commentInputSchema['id']}
                  onBlur={commentBlurHandler}
                  onChange={commentChangedHandler}
                  // placeholder={
                  //   editing
                  //     ? 'Edit your comment'
                  //     : replying
                  //     ? 'Reply to this comment'
                  //     : commentInputSchema['placeholder']
                  // }
                  required={commentInputSchema['required']}
                  type={commentInputSchema['type']}
                  value={enteredComment}
                />
              </label>

              <Button
                aria-label={
                  editing
                    ? 'Edit this comment'
                    : replying
                    ? 'Reply to this comment'
                    : 'Add a new comment'
                }
                // className="margin-top-neg-2px"
                data-testid={`nac-comments_submit${
                  editing ? '-edit' : replying ? '-reply' : ''
                }`}
                disabled={
                  commentInputError?.type === 'error' ||
                  !enteredComment ||
                  postingComment
                }
                iconName="plus"
                iconSize="xs"
                size="3xs"
                type="submit"
                // reduced={!editing && !replying ? false : true}
                className="flex-shrink-0"
                thin
                outline
              >
                {editing
                  ? 'Update'
                  : comment.parentContributionId
                  ? 'Reply'
                  : 'Comment'}
              </Button>
              {(editing || replying) && (
                <Button
                  aria-label={'Cancel this action'}
                  className="flex-shrink-0"
                  data-testid={`nac-comments_${comment.parentContributionId}_cancel`}
                  iconName="close"
                  iconSize="xs"
                  onClick={() =>
                    editing
                      ? setEditingHandler(false)
                      : setReplyingHandler(false)
                  }
                  size="3xs"
                  theme="error-dark"
                  textOnly
                  // reduced
                  thin
                >
                  Cancel
                </Button>
              )}
            </div>
            <div className="display-inline-flex flex-column width-full">
              <p
                aria-atomic="true"
                aria-describedby={commentInputSchema['id']}
                className={[
                  errorMessageClasses,
                  commentInputError?.message ? 'active' : '',
                  'anim-duration-500ms',
                  'anim-opacity',
                  'flex-row',
                  'grid-row',
                  'font-sans-3xs',
                  'position-relative',
                  'margin-top-1',
                  'minh-2',
                ].join(' ')}
                data-testid="nac-comments_error"
                role="alert"
              >
                <IconLabel
                  color={
                    commentInputError?.type === 'error'
                      ? 'error-dark'
                      : commentInputError?.type === 'warning'
                      ? 'warning-dark'
                      : 'base-darker'
                  }
                  iconName={
                    commentInputError?.type === 'error' ||
                    commentInputError?.type === 'warning'
                      ? 'triangle-exclaim'
                      : null
                  }
                  iconSize="xs"
                  size="2xs"
                >
                  {commentInputError?.message}
                </IconLabel>
              </p>
              <div
                className={[
                  !commentInputError ? 'margin-top-neg-3' : '',
                  'font-sans-2xs',
                  'text-normal',
                ].join(' ')}
              >
                <ListSeparator color="base" distance="2px" size="xl">
                  <BasicLink
                    className={['flex-align-center'].join(' ')}
                    href="https://www.archives.gov/citizen-archivist/resources"
                  >
                    Need Help?
                  </BasicLink>
                  <BasicLink href="https://www.archives.gov/citizen-archivist/resources/tagging-policy">
                    Policy
                  </BasicLink>
                </ListSeparator>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <LogInPrompt />
      )}
    </>
  )
}

CommentInput.defaultProps = {
  comment: {},
  editing: false,
  replying: false,
  defaultText: '',
}

CommentInput.propTypes = {
  comment: PropTypes.object,
  comments: PropTypes.array,
  replying: PropTypes.bool,
  editing: PropTypes.bool,
  setEditingHandler: PropTypes.func,
  setReplyingHandler: PropTypes.func,
  defaultText: PropTypes.string,
  /**
   * When provided, assumes the comment is at the object-level.
   */
  objectId: PropTypes.number,
  parentCommentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default CommentInput
