import React from 'react'
import PropTypes from 'prop-types'
import '../../../styles/index.scss'
import { IconLabel } from '../IconLabel'

export const IconLabelConditionalDisplay = ({ displayTo, ...props }) => {
  return (
    <IconLabel
      iconName="locked"
      iconSize="2xs"
      size="3xs"
      color="base-dark"
      {...props}
    >
      <span className={['text-normal'].join(' ')}>
        Only {displayTo.toLowerCase()} can access this
      </span>
    </IconLabel>
  )
}

IconLabelConditionalDisplay.defaultProps = {
  displayTo: 'you',
}

IconLabelConditionalDisplay.propTypes = {
  /**
   * The generalized individual or group permissted to view the content
   */
  displayTo: PropTypes.string,
}
