import React, { useState, useEffect, useContext, useRef } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { ModalBody, ModalFooter } from '../../Modal/Modal'
import marked, { use } from 'marked'
import { SERVER_URL } from '../../settings/globals'
import PropTypes, { bool } from 'prop-types'
import { Button } from '../../Button/Button'
import UserContext from '../../../contexts/UserContext'
import useHttp from '../../../hooks/use-http'
import Card from '../../Card/Card'
import { Icon } from '../../Icon/Icon'
import previewIcon from '../../../styles/assets/uswds/img/usa-icons/visibility.svg'
import './BoxesModal.scss'
import Markdown from '../../Markdown/Markdown'

const BannerModal = ({
  toggle,
  data,
  edit,
  heading,
  body,
  onEdit,
  id,
  modalActive,
  refresh,
}) => {
  const closeRef = useRef(null)
  const [prevSavedText, setPrevSavedText] = useState(heading)
  const [prevNoteText, setPrevNoteText] = useState(body)
  const [open, setOpen] = useState(false)
  const [savedText, setSavedText] = useState(heading)
  const [noteText, setNoteText] = useState(body)
  const [isShowing, setIsShowing] = useState(false)
  const [preview, showPreview] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const { loggedInUser = '' } = useContext(UserContext) || {}

  useEffect(() => {
    if (modalActive === id) {
      setIsShowing(true)
    }
  }, [modalActive])

  const putData = `\\${data?.homepagebox_id}`

  onEdit(() => {})

  const { sendRequest } = useHttp(
    {
      url: `${SERVER_URL}/proxy/homepageBoxes/${putData}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        heading: savedText,
        text: noteText,
        status: 'ACTIVE',
        user_id: loggedInUser?.userId,
      },
    },
    () => {
      refresh()
      closeRef.current.click()
      useEffect(() => {
        setSavedText('')
        setNoteText('')
      })
    }
  )

  const handlePublish = () => {
    setPrevNoteText(noteText)
    setPrevSavedText(savedText)
    sendRequest()
    setIsShowing(false)
    handleClose()
  }

  const title = edit ? 'Update' : 'Create'

  const icons = ['person', 'date', 'comment']
  useEffect(() => {
    const links = Array.from(
      document.querySelectorAll('.announcement-modal.preview a')
    )
    links.forEach((item) => {
      item.setAttribute('target', '_blank')
    })
  }, [preview])

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning. This text will be immediately posted on the Homepage.
            Select Publish to proceed or Cancel.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handlePublish} autoFocus>
            Publish
          </Button>
        </DialogActions>
      </Dialog>
      {isShowing && (
        <div className="announcement-modal" id={`external-link`} size="lg">
          {preview && (
            <div
              className="announcement-modal preview"
              id={`external-link`}
              size="lg"
            >
              <ModalBody>
                <Card
                  className={[
                    // 'grid-col-12 tablet:grid-col-6 desktop-lg:grid-col-4',
                    'padding-2',
                    'margin-bottom-2',
                  ].join(' ')}
                >
                  <p
                    className={[
                      'display-flex',
                      'flex-align-center',
                      'flex-column',
                      'flex-gap',
                      'margin-x-auto',
                      'maxw-card-lg',
                    ].join(' ')}
                  >
                    <Icon iconName={icons[id]} iconSize="md" />
                    <h2 className="font-sans-md margin-bottom-0">
                      <div
                        className="preview-modal-title"
                        dangerouslySetInnerHTML={{
                          __html: marked(savedText),
                        }}
                      />
                    </h2>
                    <div
                      className="link-parser"
                      dangerouslySetInnerHTML={{
                        __html: marked(noteText),
                      }}
                    />
                  </p>
                </Card>
                <Button
                  className={[''].join(' ')}
                  data-testid={`link-external__close-modal`}
                  onClick={(e) => {
                    showPreview(false)
                  }}
                  outline
                  ref={closeRef}
                >
                  Cancel
                </Button>
              </ModalBody>
            </div>
          )}
          <ModalBody id={`external-url`}>
            <div
              className={[
                'display-flex',
                'flex-column',
                'flex-align-start',
                'flex-gap',
                'theme-white',
              ].join(' ')}
              data-testid={`link-external__modal`}
            >
              <div className="announcement-title"> Update Homepage Boxes </div>
              <div className="announcement-text">
                <div>Box Heading:</div>
                <textarea
                  className="announcement-textarea"
                  value={savedText}
                  onChange={(e) => {
                    setSavedText(e.target.value)
                  }}
                ></textarea>
              </div>
            </div>
            <div
              className={[
                'display-flex',
                'flex-column',
                'flex-align-start',
                'flex-gap',
                'theme-white',
              ].join(' ')}
              data-testid={`link-external__modal`}
            >
              <div className="announcement-text">
                Box Body:
                <textarea
                  className="announcement-textarea"
                  value={noteText}
                  onChange={(e) => {
                    setNoteText(e.target.value)
                  }}
                ></textarea>
              </div>
            </div>
          </ModalBody>
          <Markdown />
          <ModalFooter hide={toggle}>
            <div className={['flex-row', 'grid-row', 'flex-justify'].join(' ')}>
              <Button
                className={[''].join(' ')}
                data-testid={`link-external__close-modal`}
                onClick={(e) => {
                  setSavedText(prevSavedText)
                  setNoteText(prevNoteText)
                  setIsShowing(false)
                }}
                outline
                ref={closeRef}
              >
                Cancel
              </Button>
              <img
                className="preview-button"
                src={previewIcon}
                onClick={() => {
                  showPreview(true)
                }}
              />
              <Button
                disabled={!noteText || !savedText ? true : false}
                onClick={handleClickOpen}
                className={[''].join(' ')}
              >
                Publish
              </Button>
            </div>
          </ModalFooter>
        </div>
      )}
    </>
  )
}

BannerModal.defaultProps = {
  id: '',
  modalActive: '',
  onEdit: () => {},
  refresh: () => {},
  announcement: '',
  heading: '',
  body: '',
  data: {},
  edit: false,
  toggle: () => {},
  isShowing: false,
  getAnnouncements: () => {},
}

BannerModal.propTypes = {
  id: PropTypes.String,
  refresh: PropTypes.func,
  modalActive: PropTypes.String,
  onEdit: PropTypes.func,
  heading: PropTypes.String,
  body: PropTypes.String,
  announcement: PropTypes.any,
  data: PropTypes.object,
  edit: PropTypes.bool,
  toggle: () => {},
  isShowing: PropTypes.bool,
  getAnnouncements: PropTypes.func,
}

export default BannerModal
