import React, { Fragment, useEffect, useState } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import useCountdown from '../../hooks/use-countdown'
import DateTimeDisplay from './date_time_display'

const ShowCounter = ({ days, hours, minutes, seconds, ...props }) => {
  return (
    <div className="show-counter display-flex" {...props}>
      {days > 1000 ? (
        <div
          className={[
            'block-load',
            'height-2',
            'margin-x-auto',
            'width-10',
          ].join(' ')}
        ></div>
      ) : (
        <Fragment>
          {days > 0 && (
            <Fragment>
              <DateTimeDisplay
                value={days}
                type={'Days'}
                isDanger={days <= 3}
              />
              <p>:</p>
            </Fragment>
          )}
          {hours > 0 && (
            <Fragment>
              <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
              <p>:</p>
            </Fragment>
          )}
          <DateTimeDisplay value={minutes} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} isDanger={false} />
        </Fragment>
      )}
    </div>
  )
}

ShowCounter.propTypes = {
  days: PropTypes.number,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
}

const CountdownTimer = ({ targetDate, expiredAction, ...props }) => {
  if (!targetDate) return false
  const [futureInMs, setFutureInMs] = useState(
    new Date().getTime() + targetDate
  )

  const [days, hours, minutes, seconds] = useCountdown(
    futureInMs,
    expiredAction
  )
  // let timeOut = days + hours + minutes + seconds <= 0
  // if (timeOut) {
  //   expiredAction()
  // }
  return (
    <ShowCounter
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      {...props}
    />
  )
}

CountdownTimer.propTypes = {
  /**
   * The date in miliseconds towhich the timer is counting down
   */
  targetDate: PropTypes.number,
  /**
   * The function to execute once the timer has expired
   */
  expiredAction: PropTypes.func,
}

export default CountdownTimer
