import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import SearchContext from '../../../contexts/SearchContext'
import '../../../styles/index.scss'
// import PropTypes from 'prop-types'
import { description } from '../../settings/RecordPropTypes'

import Truncate from '../../utilities/Truncate'

const Description = ({ record, ...props }) => {
  if (!record) return false
  const [searchParams] = useSearchParams()
  const q = searchParams?.get('q') || ''

  if (
    !record.scopeAndContentNote &&
    !record.scopeNote &&
    !record.biographicalNote &&
    !record.administrativeHistoryNote
  )
    return false

  const [description, setDescription] = useState('')

  useEffect(() => {
    setDescription(
      record.scopeAndContentNote ||
        record.scopeNote ||
        record.biographicalNote ||
        record.administrativeHistoryNote
    )
  }, [record])

  if (!description) return false
  else
    return (
      <div
        className={[
          'clearfix',
          'display-inline-block',
          'font-sans-md',
          'line-height-sans-3',
          'maxw-tablet',
          'text-base-dark',
        ].join(' ')}
        data-testid="nac-result_description"
      >
        <Truncate highlight term={q}>
          {description}
        </Truncate>
      </div>
    )
}

Description.defaultProps = {}

Description.propTypes = {
  /**
   * The entire data object for a single record
   */
  record: description,
}

export default Description
