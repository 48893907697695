import { useRef, useCallback, useEffect } from 'react'
/**
 * Description....
 *
 * @return Boolean `true` if the component is mounted
 */
export const useMountedState = () => {
  const mountedRef = useRef(false)
  const isMounted = useCallback(() => mountedRef.current, [])
  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])
  return isMounted
}

export const useSafeAsync = () => {
  const isMounted = useMountedState()
  const safeAsync = useCallback((promise) => {
    return new Promise((resolve) => {
      promise.then((value) => {
        if (isMounted()) {
          resolve(value)
        }
      })
    })
  })

  return safeAsync
}

export const returnSortRequest = (param) => {
  if (!param) return ''
  let sort = param.split(':')

  if (sort[0] == '' || sort[0] == 'relevant') return ''
  if (
    !sort[1] ||
    (sort[1].toLowerCase() !== 'desc' && sort[1].toLowerCase() !== 'asc')
  )
    sort[1] = 'asc'

  if (sort[0].toLowerCase() == 'naId') {
    return 'naId:' + sort[1].toLowerCase()
  } else if (sort[0].toLowerCase() == 'title') {
    return 'title:' + sort[1].toLowerCase()
  }
}

export const removeEmptyObjectFields = (obj) => {
  return Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
}
