import React, { Fragment, useContext } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Hooks
import useDetectPrinting from '../../hooks/use-detect-printing'

const PrintPage = ({ children, ...props }) => {
  if (!children) return false

  const { isPrinting } = useDetectPrinting()
  if (!isPrinting) return children
  else
    return (
      <>
        <table className="print-table">
          <thead>
            <tr className="header-space">
              <td>
                <div>&nbsp;</div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{children}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr className="footer-space">
              <td></td>
            </tr>
          </tfoot>
        </table>
        {/*<div className="print-header">This is an example header</div>
    <div className="print-footer">This is an example footer</div>*/}
      </>
    )
}

PrintPage.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
}

export default PrintPage
