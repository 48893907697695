import React, { useContext, useEffect, useState } from 'react'
import '../../styles/index.scss'
import { DEFAULT_MAX_CONTENT_WIDTH } from '../settings/globals'
//Components
import { Button } from '../Button/Button'
import { ButtonSharePage } from '../Button/variants/ButtonSharePage'
import { PrintPageButton } from '../Button/variants/PrintPageButton'
//Hooks
import useDescriptionNav from './use-description-nav'
//Helpers
import { returnNumberWithCommas } from '../../helper-functions/number-functions'
import { returnNumberWithOrdinalSuffix } from '../../helper-functions/string-functions'
import RecordContext from '../../contexts/RecordContext'
import { useLocation } from 'react-router-dom'
import { ButtonAdminPageOptions } from '../Button/variants/ButtonAdminPageOptions'
import { Icon } from '../Icon/Icon'
import UserContext from '../../contexts/UserContext'
import { ButtonDisplayOptions } from '../Button/variants/ButtonDisplayOptions'
import { IconLabelConditionalDisplay } from '../IconLabel/variants/IconLabelConditionalDisplay'
import ContributionContext from '../../contexts/ContributionContext'
import TagGroup from './contributions/tags/tag_group'

const DescriptionNav = ({ ...props }) => {
  const { accountsAccessArray, displayAdminWorkbench } =
    useContext(UserContext) || {}
  const { loadingRecord, record } = useContext(RecordContext) || {}
  const { tags, activeTranscriptions, allTranscriptions } =
    useContext(ContributionContext) || {}
  const { state } = useLocation()
  const { backLink, nextHandler, prevHandler, resultIndex, total } =
    useDescriptionNav(state)

  const [adminTags, setAdminTags] = useState([])

  useEffect(() => {
    setAdminTags(
      JSON.parse(JSON.stringify(tags)).filter(
        (tag) => tag.madeByAdministrator && tag.status === 'active'
      )
    )
  }, [tags])

  return (
    <>
      {' '}
      <div
        className={[
          'no-print',
          'bg-primary-dark',
          'padding-x-1 tablet:padding-x-3',
          'padding-y-05 tablet:padding-y-0',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            'display-flex',
            'flex-align-center',
            'flex-gap-xs tablet-lg:flex-gap-sm',
            'flex-justify-center',
            'flex-no-wrap',
            'flex-row',
            'font-sans-2xs',
            'margin-x-auto',
            `maxw-${DEFAULT_MAX_CONTENT_WIDTH}`,
            'minh-button',
            'text-base-lightest',
            'width-full',
          ].join(' ')}
        >
          <div className={['flex-1', 'margin-right-auto'].join(' ')}>
            {backLink && (
              <Button
                data-testid={`nac-result-desc-nav--back-to-results`}
                iconName="prev"
                iconSize="xs"
                reduced
                size="2xs"
                theme="primary-dark"
                thin
                to={backLink}
              >
                <span className="display-none tablet:display-block">
                  Back to Search Results
                  <span className="usa-sr-only">.</span>
                </span>
                <span className="display-block tablet:display-none">
                  Back
                  <span className="usa-sr-only"> to Search Results.</span>
                </span>
              </Button>
            )}
          </div>
          {total && (
            <div
              className={[
                'display-flex',
                'flex-1',
                'flex-align-center',
                'flex-justify-center',
                'flex-gap-xs tablet-lg:flex-gap-sm',
                'flex-row',
              ].join(' ')}
            >
              <Button
                align="center"
                className="display-flex flex-justify-center"
                data-testid={`nac-result-desc-nav--pagination-prev`}
                disabled={resultIndex === 1 || loadingRecord}
                iconName="prev"
                iconOnly
                iconSize="xs"
                onClick={prevHandler}
                reduced
                size="2xs"
                srText={
                  resultIndex <= 1
                    ? 'You have reached the beginning of the search results'
                    : `Go to the ${returnNumberWithOrdinalSuffix(
                        resultIndex - 1
                      )} search result`
                }
                theme="primary-dark"
                thin
              />
              <span className="display-none tablet:display-block">Result</span>
              <span
                className={[
                  'display-flex',
                  'flex-align-center',
                  'flex-gap-xs tablet-lg:flex-gap-sm',
                  'flex-row',
                ].join(' ')}
              >
                <strong
                  className="font-sans-md"
                  data-testid="nac-result-desc-nav--result-index"
                >
                  {returnNumberWithCommas(resultIndex)}
                </strong>{' '}
                <em>of</em> {returnNumberWithCommas(total)}
              </span>{' '}
              <Button
                align="center"
                className="display-flex flex-justify-center"
                data-testid={`nac-result-desc-nav--pagination-next`}
                disabled={resultIndex === total || loadingRecord}
                iconName="next"
                iconOnly
                iconSize="xs"
                onClick={nextHandler}
                reduced
                size="2xs"
                srText={
                  resultIndex === total
                    ? 'You have reached the end of the search results'
                    : `Go to the ${returnNumberWithOrdinalSuffix(
                        resultIndex + 1
                      )} search result`
                }
                theme="primary-dark"
                thin
              />
            </div>
          )}
          <div
            className={[
              'display-flex',
              'flex-1',
              'flex-align-center',
              'flex-gap-xs',
              'flex-justify-end',
              'flex-row',
              'margin-left-auto',
            ].join(' ')}
          >
            <PrintPageButton
              data-testid="nac-description--print-page"
              theme="primary-lighter"
              results={[].concat(record)}
            />
            <ButtonSharePage
              data-testid="nac-description--share-page"
              paramsToRemove={['contributionId']}
            />
            <ButtonDisplayOptions
              data-testid="nac-search--share-page"
              theme="primary-lighter"
            />
            {accountsAccessArray.accessGrantedToModeratorTools && (
              <>
                <Icon iconName="pipe" />
                <ButtonAdminPageOptions data-testid="nac-description--options" />
              </>
            )}
          </div>
        </div>
      </div>
      {accountsAccessArray.accessGrantedToAccountAdminTools &&
        displayAdminWorkbench && (
          <div
            className={[
              'no-print',
              //
              'bg-base-lighter',
              'padding-x-1 tablet:padding-x-3',
              'padding-y-05 tablet:padding-y-0',
              'width-full',
            ].join(' ')}
          >
            <div
              className={[
                'display-flex',
                'flex-align-start',
                'flex-gap-sm',
                'flex-justify-center',
                'flex-no-wrap',
                'flex-column',
                'font-sans-2xs',
                'margin-x-auto',
                `maxw-${DEFAULT_MAX_CONTENT_WIDTH}`,
                'minh-button',
                adminTags?.length > 0 ? 'padding-top-2' : 'padding-y-2',
                'width-full',
              ].join(' ')}
            >
              {loadingRecord ? (
                <div
                  className={[
                    'display-flex',
                    'flex-align-start',
                    'flex-gap-sm',
                    'flex-column',
                    'margin-bottom-2',
                    'width-full',
                  ].join(' ')}
                >
                  <div
                    className={[
                      'block-load',
                      'height-105',
                      'radius-pill',
                      'width-card',
                    ].join(' ')}
                  ></div>{' '}
                  <div
                    className={[
                      'block-load',
                      'height-2',
                      'margin-top-neg-05',
                      'radius-pill',
                      'width-15',
                    ].join(' ')}
                  ></div>
                  <div
                    className={[
                      'block-load',
                      'height-2',
                      'margin-top-neg-05',
                      'radius-pill',
                      'width-15',
                    ].join(' ')}
                  ></div>
                  <div
                    className={['display-flex', 'flex-gap-sm', 'flex-row'].join(
                      ' '
                    )}
                  >
                    <div
                      className={[
                        'block-load',
                        'height-4',
                        'radius-pill',
                        'width-10',
                      ].join(' ')}
                    ></div>
                    <div
                      className={[
                        'block-load',
                        'height-4',
                        'radius-pill',
                        'width-10',
                      ].join(' ')}
                    ></div>
                  </div>
                </div>
              ) : (
                <>
                  <IconLabelConditionalDisplay displayTo="Admins" />
                  {record?.digitalObjects?.length > 0 && (
                    <div
                      className={[
                        'display-inline-flex',
                        'flex-gap-xs',
                        'flex-row',
                        'minw-0',
                      ].join(' ')}
                    >
                      <h2
                        className={['font-sans-xs', 'margin-bottom-0'].join(
                          ' '
                        )}
                      >
                        Available Transcriptions:{' '}
                      </h2>
                      {
                        [
                          ...new Map(
                            allTranscriptions
                              ?.filter(
                                (t) =>
                                  t.status === 'active' && t.contribution !== ''
                              )
                              .map((t) => [t.targetObjectId, t])
                          ).values(),
                        ]?.length
                      }
                      /{record?.digitalObjects?.length}
                    </div>
                  )}

                  <div
                    className={[
                      'display-inline-flex',
                      adminTags?.length > 0 ? 'flex-gap-0' : 'flex-gap-xs',
                      adminTags?.length > 0 ? 'flex-column' : 'flex-row',
                      'minw-0',
                    ].join(' ')}
                  >
                    <h2
                      className={['font-sans-xs', 'margin-bottom-0'].join(' ')}
                    >
                      Admin Tags:
                    </h2>{' '}
                    {adminTags?.length > 0 ? (
                      <div className="margin-bottom-neg-2 margin-top-neg-05">
                        <TagGroup label={false} tags={adminTags} />
                      </div>
                    ) : (
                      'No admin tags found'
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
    </>
  )
}
export default DescriptionNav
