import { compareNumbers } from '../../helper-functions/sort-functions'
import { returnNumberWithOrdinalSuffix } from '../../helper-functions/string-functions'

/**
 * All Congresses with specific beginning and ending dates
 * which cannot be programaticallly dtermined
 */
let earlyCongresses = [
  {
    label: 'March 4, 1789–March 3, 1791',
    value: '1',
  },
  {
    label: 'March 4, 1791–March 2, 1793',
    value: '2',
  },
  {
    label: 'March 4, 1793–March 3, 1795',
    value: '3',
  },
  {
    label: 'June 8, 1795–March 3, 1797',
    value: '4',
  },
  {
    label: 'March 4, 1797–March 3, 1799',
    value: '5',
  },
  {
    label: 'December 2, 1799–March 3, 1801',
    value: '6',
  },
  {
    label: 'March 4, 1801–March 3, 1803',
    value: '7',
  },
  {
    label: 'October 17, 1803–March 3, 1805',
    value: '8',
  },
  {
    label: 'December 2, 1805–March 3, 1807',
    value: '9',
  },
  {
    label: 'October 26, 1807–March 3, 1809',
    value: '10',
  },
  {
    label: 'March 4, 1809–March 3, 1811',
    value: '11',
  },
  {
    label: 'November 4, 1811–March 3, 1813',
    value: '12',
  },
  {
    label: 'May 24, 1813–March 3, 1815',
    value: '13',
  },
  {
    label: 'December 4, 1815–March 3, 1817',
    value: '14',
  },
  {
    label: 'March 4, 1817–March 3, 1819',
    value: '15',
  },
  {
    label: 'December 6, 1819–March 3, 1821',
    value: '16',
  },
  {
    label: 'December 3, 1821–March 3, 1823',
    value: '17',
  },
  {
    label: 'December 1, 1823–March 3, 1825',
    value: '18',
  },
  {
    label: 'March 4, 1825–March 3, 1827',
    value: '19',
  },
  {
    label: 'December 3, 1827–March 3, 1829',
    value: '20',
  },
  {
    label: 'March 4, 1829–March 3, 1831',
    value: '21',
  },
  {
    label: 'December 5, 1831–March 2, 1833',
    value: '22',
  },
  {
    label: 'December 2, 1833–March 3, 1835',
    value: '23',
  },
  {
    label: 'December 7, 1835–March 3, 1837',
    value: '24',
  },
  {
    label: 'March 4, 1837–March 3, 1839',
    value: '25',
  },
  {
    label: 'December 2, 1839–March 3, 1841',
    value: '26',
  },
  {
    label: 'March 4, 1841–March 3, 1843',
    value: '27',
  },
  {
    label: 'December 4, 1843–March 3, 1845',
    value: '28',
  },
  {
    label: 'March 4, 1845–March 3, 1847',
    value: '29',
  },
  {
    label: 'December 6, 1847–March 3, 1849',
    value: '30',
  },
  {
    label: 'March 5, 1849–March 3, 1851',
    value: '31',
  },
  {
    label: 'March 4, 1851–March 3, 1853',
    value: '32',
  },
  {
    label: 'March 4, 1853–March 3, 1855',
    value: '33',
  },
  {
    label: 'December 3, 1855–March 3, 1857',
    value: '34',
  },
  {
    label: 'March 4, 1857–March 3, 1859',
    value: '35',
  },
  {
    label: 'March 4, 1859–March 3, 1861',
    value: '36',
  },
  {
    label: 'March 4, 1861–March 3, 1863',
    value: '37',
  },
  {
    label: 'March 4, 1863–March 3, 1865',
    value: '38',
  },
  {
    label: 'March 4, 1865–March 3, 1867',
    value: '39',
  },
  {
    label: 'March 4, 1867–March 3, 1869',
    value: '40',
  },
  {
    label: 'March 4, 1869–March 3, 1871',
    value: '41',
  },
  {
    label: 'March 4, 1871–March 3, 1873',
    value: '42',
  },
  {
    label: 'March 4, 1873–March 3, 1875',
    value: '43',
  },
  {
    label: 'March 5, 1875–March 3, 1877',
    value: '44',
  },
  {
    label: 'March 5, 1877–March 3, 1879',
    value: '45',
  },
  {
    label: 'March 18, 1879–March 3, 1881',
    value: '46',
  },
  {
    label: 'March 4, 1881–March 3, 1883',
    value: '47',
  },
  {
    label: 'December 3, 1883–March 3, 1885',
    value: '48',
  },
  {
    label: 'March 4, 1885–March 3, 1887',
    value: '49',
  },
  {
    label: 'December 5, 1887–March 3, 1889',
    value: '50',
  },
  {
    label: 'March 4, 1889–March 3, 1891',
    value: '51',
  },
  {
    label: 'December 7, 1891–March 3, 1893',
    value: '52',
  },
  {
    label: 'March 4, 1893–March 3, 1895',
    value: '53',
  },
  {
    label: 'December 2, 1895–March 3, 1897',
    value: '54',
  },
  {
    label: 'March 4, 1897–March 3, 1899',
    value: '55',
  },
  {
    label: 'December 4, 1899–March 3, 1901',
    value: '56',
  },
  {
    label: 'March 4, 1901–March 3, 1903',
    value: '57',
  },
  {
    label: 'March 5, 1903–March 3, 1905',
    value: '58',
  },
  {
    label: 'March 4, 1905–March 3, 1907',
    value: '59',
  },
  {
    label: 'December 2, 1907–March 3, 1909',
    value: '60',
  },
  {
    label: 'March 4, 1909–March 3, 1911',
    value: '61',
  },
  {
    label: 'Apr 4, 1911–March 3, 1913',
    value: '62',
  },
  {
    label: 'March 4, 1913–March 3, 1915',
    value: '63',
  },
  {
    value: '64',
    label: 'December 6, 1915–March 3, 1917',
  },
  {
    label: 'March 5, 1917–March 3, 1919',
    value: '65',
  },
  {
    label: 'March 19, 1919–March 3, 1921',
    value: '66',
  },
  {
    label: 'March 4, 1921–March 3, 1923',
    value: '67',
  },
  {
    label: 'December 3, 1923–March 3, 1925',
    value: '68',
  },
  {
    label: 'March 4, 1925–March 4, 1927',
    value: '69',
  },
  {
    label: 'December 5, 1927–March 3, 1929',
    value: '70',
  },
  {
    label: 'March 4, 1929–March 3, 1931',
    value: '71',
  },
  {
    label: 'December 7, 1931–March 3, 1933',
    value: '72',
  },
  {
    label: 'March 4, 1933–June 18, 1935',
    value: '73',
  },
]

/**
 * Concatenates the pre-defined earlyCongresses array with a
 * dynamically generated array of remaining congresses in order
 * to produce an array which represents all congresses,
 * including their dates, beginning with the first congress,
 * and ending with the most recent
 */
export const congressArray = (defaultObj) => {
  //Convert known congress data into the expected format
  let congressArray = []
  if (defaultObj) congressArray.push(defaultObj)
  earlyCongresses.map((item) => {
    congressArray.push({
      value: parseInt(item.value),
      label: `${returnNumberWithOrdinalSuffix(item.value)} (${item.label})`,
      id: String(item.value),
    })
  })
  //Add remaining congresses, up to and including the current year
  let lastCongress = 74
  let lastCongressYear = 1935
  const thisYear = new Date().getFullYear()
  while (lastCongressYear <= thisYear) {
    let range = `(January  3, ${lastCongressYear}–January  3, ${
      lastCongressYear + 2
    })`
    if (lastCongressYear + 2 > thisYear)
      range = `(January  3, ${lastCongressYear}–)`
    congressArray.push({
      value: parseInt(lastCongress),
      label: `${returnNumberWithOrdinalSuffix(lastCongress)} ${range}`,
      id: String(lastCongress),
    })
    lastCongressYear = lastCongressYear + 2
    lastCongress++
  }
  congressArray
    .sort((a, b) => {
      return compareNumbers(a.value, b.value, false) // add false for desc order
    })
    .sort((a, b) => {
      if (a.isPlaceholder) return -1
      else return 0
    })
  //Sort so the most recent appears first in the list of options
  return congressArray
}

/**
 * Adds a default option to accompany the options array for the beginCongress input
 */
const beginCongressOptions = congressArray({
  id: 'beginCongress-default',
  label: 'Select a beginning congress',
  value: '',
  isPlaceholder: true,
})

/**
 * Adds a default option to accompany the options array for the endCongress input
 */
const endCongressOptions = congressArray({
  id: 'endCongress-default',
  label: 'Select an ending congress',
  value: '',
  isPlaceholder: true,
})

/**
 * Schema for building and validating the beginCongress input.
 */
export const beginCongressInputSchema = {
  allow: ['number'],
  id: 'beginCongress',
  label: 'Beginning Congress',
  options: beginCongressOptions,
  searchable: true,
  sr: 'Begin congress dropdown field',
  type: 'select',
}

/**
 * Schema for building and validating the endCongress input.
 * Note: that endCongress options are modified dynamically in the UI
 * in relation to the beginCongress selection
 */
export const endCongressInputSchema = {
  allow: ['number'],
  id: 'endCongress',
  label: 'Ending Congress',
  options: endCongressOptions,
  searchable: true,
  sr: 'End congress dropdown field',
  type: 'select',
}

/**
 * Schema for building the congress range selector based on
 * the defined beginCongress and endCongress inputs
 */
export const congressRangeSchema = {
  description:
    'Returns Congressional records which have a Congress number or range which intersects the selected range. Congressional records are official documents which record the proceedings and debates of the United States Congress.',
  id: 'congress',
  label: 'Records by Congress',
  options: [beginCongressInputSchema, endCongressInputSchema],
  reset: true,
  sr: 'Begin and end congress dropdown fields',
  type: 'range',
}
