import React, { useContext, useEffect } from 'react'
import { DEFAULT_PAGE, DEFAULT_PANEL } from '../settings/globals'
//Context
import ContributionContext from '../../contexts/ContributionContext'
import RecordContext from '../../contexts/RecordContext'
import UserContext from '../../contexts/UserContext'
//Hooks
import useUrlParams from '../../hooks/use-url-params'
import { useLocation } from 'react-router-dom'

const useContributionBar = (extractedCount) => {
  /**
   * TO DO: Improve this logic:
   * ....
   */
  const {
    commentCounts,
    currentActiveTranscription,
    currentTranscriptionIsLocked,
    tags,
  } = useContext(ContributionContext) || {}

  const { objectId } = useContext(RecordContext) || {}
  const { loggedInUser } = useContext(UserContext) || {}
  const tagCount =
    tags?.length && tags.filter((tag) => tag.targetObjectId == objectId).length
  let commentCount = commentCounts?.objectCommentCounts[objectId] || 0
  const transcription = currentActiveTranscription?.contribution

  /**
   * ^^^^
   * TO DO: Improve this logic!
   */

  // Get current params and param functions
  const { state } = useLocation()
  const { removeParams, updateParams, params } = useUrlParams(state)

  const objectPanel = params['objectPanel'] || DEFAULT_PANEL
  const objectPage = params['objectPage'] || DEFAULT_PAGE

  // Build tab-specific content for use in the display
  const tabs = [
    {
      title: 'Transcription',
      id: 'transcription',
      ariaLabel: `${
        objectPanel == 'transcription'
          ? 'Collapse the transcription panel.'
          : 'Expand the transcription panel.'
      } ${
        !transcription
          ? 'The transcription for this object has not been started.'
          : 'The transcription for this object is available.'
      }`,
      count: transcription ? 1 : 0,
      label:
        (!transcription
          ? loggedInUser?.userId && currentTranscriptionIsLocked
            ? 'In Progress'
            : 'Not Started'
          : 'Available') +
        (loggedInUser?.userId && currentTranscriptionIsLocked
          ? ' (Locked)'
          : ''),
      iconName:
        loggedInUser?.userId && currentTranscriptionIsLocked
          ? 'transcription-locked'
          : 'transcription',
    },
    {
      title: 'Tags',
      id: 'tag',
      ariaLabel: `${
        objectPanel == 'tag'
          ? 'Collapse the tag panel.'
          : 'Expand the tag panel.'
      } ${
        tagCount === 1
          ? 'There is 1 tag for this object.'
          : `There are ${tagCount} tags for this object.`
      }`,
      action: 'Log in to tag',
      count: tagCount || 0,
      label: tagCount || 0,
      iconName: 'tag',
    },
    {
      title: 'Comments',
      id: 'comment',
      icon: 'message',
      ariaLabel: `${
        objectPanel == 'comment'
          ? 'Collapse the comment panel.'
          : 'Expand the comment panel.'
      } ${
        commentCount === 1
          ? 'There is 1 comment for this object.'
          : `There are ${commentCount} comments for this object.`
      }`,
      action: 'Log in to comment',
      count: commentCount,
      label: commentCount,
      iconName: 'comment',
    },
    {
      title: 'Extracted Text',
      id: 'extracted',
      ariaLabel: `${
        objectPanel == 'extracted'
          ? 'Collapse the extraction panel.'
          : 'Expand the extraction panel.'
      } ${
        commentCount === 1
          ? 'There is 1 extractions for this object.'
          : `There are ${extractedCount} extractions for this object.`
      }`,
      action: 'Log in to comment',
      count: extractedCount ? 1 : 0,
      label: extractedCount ? 1 : 0,
      iconName: 'extractedtext',
    },
  ]

  const updatePanelHandler = (panel) => {
    if (panel === 'extracted' && !extractedCount) {
      return
    }

    let panelString = panel
    if (
      (panel === 'transcription' && objectPanel === 'extracted') ||
      (panel === 'extracted' && objectPanel === 'transcription')
    ) {
      panelString = `combo`
    }

    objectPanel === panel
      ? removeParams(['objectPanel'])
      : updateParams({
          objectPanel: panelString,
        })
  }

  return { objectPanel, tabs, updatePanelHandler }
}
export default useContributionBar
