import React, { Fragment, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import SearchContext from '../../../contexts/SearchContext'
import useDetectPrinting from '../../../hooks/use-detect-printing'
import '../../../styles/index.scss'
import Highlighter from '../../Highlighter/Highlighter'
import { Icon } from '../../Icon/Icon'
import { IconLabel } from '../../IconLabel/IconLabel'
import { description } from '../../settings/RecordPropTypes'

const ControlNumbers = ({ record, ...props }) => {
  if (!record || !record.naId) return ''

  const { isPrinting } = useDetectPrinting()

  const [searchParams] = useSearchParams()
  const controlNumbersParam =
    searchParams
      .get('controlNumbers')
      ?.split(',')
      .map((c) => c.trim()) || []
  const naIdParam =
    searchParams.get('naId')?.replace(/\s/g, '').split(',') || []
  const localIdParam =
    searchParams.get('localIdentifier')?.replace(/\s/g, '').split(',') || []

  const { queryParam } = returnQuotedAndSearcedTextArrays(searchParams)

  const containerIds = []
  record?.physicalOccurrences?.map((o) =>
    o?.mediaOccurrences.map(
      (m) => m?.containerId && containerIds.push(m.containerId)
    )
  )

  const naIdWasSearched =
    naIdParam?.indexOf(String(record.naId)) > -1 ||
    queryParam?.indexOf(String(record.naId)) > -1 ||
    controlNumbersParam?.indexOf(String(record.naId)) > -1

  //Local IDs
  let allLocalIds = record.localIdentifier?.replace(/\s/g, '')?.split(';') || []
  const searchedLocalIds = [
    ...(allLocalIds
      ? allLocalIds.filter((value) => controlNumbersParam?.includes(value))
      : []),
    ...(allLocalIds
      ? allLocalIds.filter((value) => localIdParam?.includes(value))
      : []),
    ...(allLocalIds
      ? allLocalIds.filter((value) => queryParam?.includes(value))
      : []),
  ]

  //HMS/MLR Numbers
  let allHms = []
  if (record.variantControlNumbers && record.variantControlNumbers.length > 0) {
    let i = 0
    record.variantControlNumbers.map((variant) => {
      if (variant.type == 'HMS/MLR Entry Number') {
        allHms.push(variant.number)
        i++
      }
    })
  }
  const searchedHms = [
    ...(allHms
      ? allHms.filter((value) => controlNumbersParam?.includes(value))
      : []),
    ...(allHms ? allHms.filter((value) => queryParam?.includes(value)) : []),
  ]

  return (
    <div
      className="flex-row grid-row margin-top-auto"
      data-testid="nac-result_ids"
    >
      {record.organizationNames && record.organizationNames.length - 1 > 0 && (
        <>
          <IconLabel align="start" color="base-dark" size="2xs">
            <span className="margin-top-05">
              {record.organizationNames.length - 1 > 1
                ? `${record.organizationNames.length - 1} Previous Names`
                : `1 Previous Name`}
            </span>
          </IconLabel>
          <Icon color="base" iconName="pipe" />
        </>
      )}
      <IconLabel
        align="start"
        color="base-dark"
        data-testid={`nac-result_naId-${record.naId}`}
        iconName="institution"
        iconPercent="70"
        iconSize="sm"
        size="2xs"
      >
        <span className="margin-top-05">
          NAID:{' '}
          <span>
            <Highlighter exact={naIdWasSearched}>
              <strong>{record.naId}</strong>
            </Highlighter>
          </span>
        </span>
      </IconLabel>
      {(record.levelOfDescription === 'item' ||
        record.levelOfDescription === 'fileUnit') &&
        containerIds.length > 0 && (
          <>
            <Icon color="base" iconName="pipe" />
            <IconLabel
              align="start"
              color="base-dark"
              data-testid={`nac-result_containerId-${record.naId}`}
              iconName="institution"
              iconPercent="70"
              iconSize="sm"
              size="2xs"
            >
              <span className="margin-top-05">
                {containerIds.length > 1 ? 'Container IDs' : 'Container ID'}:{' '}
                <strong>{containerIds.join(', ')}</strong>
              </span>
            </IconLabel>
          </>
        )}
      {returnMetaArrayLabels(
        allLocalIds,
        searchedLocalIds,
        'Local ID',
        isPrinting
      )}
      {returnMetaArrayLabels(allHms, searchedHms, 'HMS/MLR', isPrinting)}
    </div>
  )
}

ControlNumbers.defaultProps = {}

ControlNumbers.propTypes = {
  /**
   * The entire data object for a single record
   */
  record: description,
}

export default ControlNumbers

export const returnMetaArrayLabels = (
  array,
  highlighted,
  label,
  isPrinting,
  delineate = true,
  labelPlural = label + 's',
  max = 2
) => {
  if (
    (!array || array.length === 0) &&
    (!highlighted || highlighted.length === 0)
  )
    return false

  let newArray = []
  let offset = 0
  if (isPrinting) {
    newArray = array
  } else if (highlighted.length > 0) {
    if (highlighted.length <= max) {
      offset = max - highlighted.length
      newArray = [...highlighted, ...array.slice(0, offset)]
    } else newArray = [...highlighted]
  } else newArray = [...array.slice(0, max)]

  newArray = newArray.filter(uniqueValuesOnly)

  return (
    <>
      {newArray.length > 0 && (
        <>
          {delineate && <Icon color="base" iconName="pipe" />}
          <IconLabel
            align="start"
            color="base-dark"
            iconName="institution"
            iconPercent="70"
            iconSize="sm"
            size="2xs"
          >
            <span className="margin-top-05">
              {newArray.length > 1 ? labelPlural : label}:
              {newArray.map((num, index) => (
                //index < max && (
                <span data-testid={`nac-result_id-${num}`} key={num}>
                  <Highlighter exact={highlighted.includes(num)}>
                    <strong> {num}</strong>
                  </Highlighter>
                  {index < newArray.length - 1 && ', '}
                </span>
              ))}
              {array &&
                array.length > max &&
                array.length - newArray.length > 0 && (
                  <span
                    className="text-base font-sans-2xs text-italic flex-align-self-center"
                    data-testid="nac-result_ids-more"
                  >
                    , {`${array.length - newArray.length} more...`}
                  </span>
                )}
            </span>
          </IconLabel>
        </>
      )}
    </>
  )
}

export const uniqueValuesOnly = (value, index, self) => {
  return self.indexOf(value) === index
}

export const returnQuotedAndSearcedTextArrays = (params) => {
  // Manage queries by extracting quoted strings
  // and merging with remaining individual strings
  const queryParamQuoted =
    params
      ?.get('q')
      ?.match(/"(.*?)"/g)
      ?.map(function (n) {
        return n.replace(/"/g, '')
      })
      ?.filter(function (v) {
        return v !== ''
      }) || []

  let queryParam = []
  if (queryParamQuoted.length > 0) {
    queryParam =
      params
        ?.get('q')
        ?.replace(/"(.*?)"/g, '')
        ?.replace(/\s*(\b(and|or|not|\*)\b)\s*/gi, ' ')
        ?.split(' ')
        ?.filter(function (v) {
          return v !== ''
        }) || []
  } else
    queryParam =
      params
        .get('q')
        ?.replace(/\s*(\b(and|or|not|\*)\b)\s*/gi, ' ')
        ?.split(' ') || []
  queryParam = [...queryParam, ...queryParamQuoted].filter(uniqueValuesOnly)

  return { quoted: queryParamQuoted, queryParam }
}
