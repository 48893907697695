import React, { Fragment } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'

export const ObjectContributionsLoader = ({ type, ...props }) => {
  const widths = returnRandomArray(arrayOfSizes, 10)
  return (
    <>
      <div
        className={[
          'display-flex',
          'flex-column',
          'grid-col',
          'height-full',
          'overflow-hidden',
          'width-full',
        ].join(' ')}
      >
        {type == 'tag' && (
          <div
            className={[
              'flex-row',
              'grid-row',
              'padding-x-2',
              'padding-y-3',
            ].join(' ')}
          >
            {[...Array(18)].map((e, i) => (
              <div
                className={[
                  'margin-bottom-1',
                  'margin-right-1',
                  'padding-1',
                ].join(' ')}
                key={i}
              >
                <div
                  className={[
                    'block-load',
                    'float-left',
                    'height-2',
                    'padding-x-4',
                    `width-${widths[i]}`,
                  ].join(' ')}
                ></div>
              </div>
            ))}
          </div>
        )}
        {type == 'comment' && (
          <div
            className={[
              'flex-column',
              'grid-col',
              'padding-x-1',
              'padding-y-3',
            ].join(' ')}
          >
            {[...Array(3)].map((e, i) => (
              <Fragment key={i}>
                <div
                  className={[
                    'flex-align-center',
                    'flex-row',
                    'grid-row',
                    'margin-right-1',
                    'padding-1',
                  ].join(' ')}
                >
                  <div
                    className={[
                      'block-load',
                      'float-left',
                      'height-4',
                      'margin-bottom-0',
                      'margin-right-1',
                      'radius-pill',
                      'width-4',
                    ].join(' ')}
                  ></div>
                  <div
                    className={[
                      'block-load',
                      'float-left',
                      'height-2',
                      'margin-right-2',
                      'padding-x-4',
                      `width-6`,
                    ].join(' ')}
                  ></div>
                  <div
                    className={[
                      'block-load',
                      'float-left',
                      'height-1',
                      'padding-x-4',
                      `width-4`,
                    ].join(' ')}
                  ></div>
                </div>
                {[...Array(3)].map((e, i) => (
                  <div
                    className={[
                      'flex-align-center',
                      'flex-column',
                      'grid-col',
                      'margin-left-5',
                      'margin-bottom-05',
                      'margin-top-neg-2',
                      'padding-1',
                    ].join(' ')}
                    key={i}
                  >
                    <div
                      className={[
                        'block-load',
                        'float-left',
                        'height-1',
                        'padding-x-3',
                        `width-full`,
                      ].join(' ')}
                    ></div>
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
        )}
        {type == 'transcription' && (
          <div
            className={[
              'flex-column',
              'grid-col',
              'padding-x-1',
              'padding-y-2',
            ].join(' ')}
          >
            {[...Array(10)].map((e, i) => (
              <div
                className={[
                  'flex-align-center',
                  'flex-column',
                  'grid-col',
                  'margin-bottom-1',
                  'padding-1',
                ].join(' ')}
                key={i}
              >
                <div
                  className={[
                    'block-load',
                    'float-left',
                    'height-1',
                    'padding-x-3',
                    `width-full`,
                  ].join(' ')}
                ></div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

ObjectContributionsLoader.defaultProps = {}

ObjectContributionsLoader.propTypes = {
  type: PropTypes.oneOf(['comment', 'tag', 'transcription']),
}

export const returnRandomArray = (array, count) => {
  const element = array.slice()
  const output = []
  for (let i = 0; i < count; i++) {
    output.push(
      ...element.splice(Math.floor(Math.random() * element.length), 1)
    )
  }
  return output
}

export const arrayOfSizes = [
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  'card',
  'card-lg',
]
