import React, { useEffect, useState } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import { defaultSizes } from '../../settings/SizePropTypes'
import { description } from '../../settings/RecordPropTypes'

import { convertImageUrlToIiif } from '../../../helper-functions/string-functions'

import {
  returnIconAndLabelFromString,
  imageTypes,
} from '../../../helper-functions/metadata-functions'
import { IIIF_PATH_THUMB } from '../../settings/globals'

const Thumbnail = ({ record, size, ...props }) => {
  if (!record) return false

  /**
   * The general record or authority type, which defines the icon to display when no thumbnail image is available
   */
  const [type, setType] = useState(null)
  /**
   * The url of the thumbnail image.
   */
  const [thumb, setThumb] = useState(null)

  useEffect(() => {
    const {
      levelOfDescription,
      authorityType,
      generalRecordsTypes,
      firstDigitalObject,
    } = record

    const getTypeFromRecord = () => {
      if (
        levelOfDescription === 'recordGroup' ||
        levelOfDescription === 'collection'
      ) {
        return returnIconAndLabelFromString(levelOfDescription)?.icon
      } else if (authorityType) {
        return returnIconAndLabelFromString(authorityType)?.icon
      } else if (
        generalRecordsTypes &&
        generalRecordsTypes[0] === 'Moving Images' &&
        firstDigitalObject &&
        firstDigitalObject.length > 0 &&
        firstDigitalObject[0]?.objectType
      ) {
        return 'video'
      } else if (
        generalRecordsTypes &&
        generalRecordsTypes[0] === 'Sound Recordings' &&
        firstDigitalObject &&
        firstDigitalObject.length > 0 &&
        !firstDigitalObject[0]?.objectType
      ) {
        return 'items'
      } else if (generalRecordsTypes) {
        return returnIconAndLabelFromString(generalRecordsTypes[0])?.icon
      } else {
        return ''
      }
    }

    const setVideoType = () => {
      setType(getTypeFromRecord())
    }

    const setThumbnail = () => {
      if (firstDigitalObject && firstDigitalObject.length > 0) {
        const objects = firstDigitalObject.filter(
          ({ objectType }) =>
            objectType &&
            imageTypes.some((element) => objectType.includes(element))
        )

        if (objects.length > 0) {
          setThumb(
            convertImageUrlToIiif(objects[0].objectUrl) + IIIF_PATH_THUMB
          )
        }
      }
    }
    setVideoType()
    setThumbnail()
  }, [record])

  if (!type && !thumb) return false
  else
    return (
      <div
        className={[
          'bg-primary-lighter',
          'position-relative',
          'display-block',
          'nac-icon',
          `nac-icon--${size}`,
          'nac-icon--primary-light',
          'nac-icon--thick',
          'height-full',
          'nac-icon--bg-50',
          `nac-icon--${type}`,
        ].join(' ')}
        data-testid={`nac-results--thumbnail-${record.naId}`}
      >
        {thumb ? (
          <img
            className="object-fit-cover width-full height-full"
            loading="lazy"
            src={thumb}
            // Not including alt text here is a choice.
            // Because these images do not have concrete, well written descriptions, the only alternative would be to duplicate the record description as the alt text.
            // This would make for an undesirable experience as the description is already provided as part of the result.
            alt=""
          />
        ) : (
          ''
        )}
      </div>
    )
}

Thumbnail.defaultProps = {
  size: '2xl',
}

Thumbnail.propTypes = {
  /**
   * The entire data object for a single record
   */
  record: description,
  /**
   * Defines both the height and width of the thumbnail.
   * This is based on a subset of the USWDS size indicators.
   */
  size: PropTypes.oneOf(defaultSizes),
}

export default Thumbnail
