import React from 'react'
import PropTypes from 'prop-types'
// import usePager from "../business/usePage"
// import { SERVER_URL } from '../../settings/globals'
import { SERVER_URL } from '../settings/globals'
import './pagination.scss'

const Pagination = ({
  // data,
  firstPage,
  lastPage,
  pages,
  paged,
  currentIndex,
  setCurrentIndex,
  pageSize,
  prev,
  next,
  hasNext,
  hasPrev,
  setLimit,
  displayPager,
}) => {
  const newData = async (target) => {
    const result = target.value
    setLimit(parseInt(result))
  }

  return (
    <nav aria-label="navigate results">
      {displayPager && (
        <ul className="pagination pagination-lg">
          <li className="page-item">
            <div
              onClick={prev}
              className={`${
                hasPrev === false ? 'disabled' : null
              } usa-button no-print clickable flex-align-center radius-md display-flex outline-offset-0 width-full padding-105 font-sans-sm usa-button--primary flex-justify-center usa-button--thin text-normal   theme-primary theme-primary--text`}
            >
              Previous
            </div>
          </li>
          <li className="page-item">
            <div
              onClick={next}
              className={`${
                hasNext === false ? 'disabled' : null
              } usa-button no-print clickable flex-align-center radius-md display-flex outline-offset-0 width-full padding-105 font-sans-sm usa-button--primary flex-justify-center usa-button--thin text-normal   theme-primary theme-primary--text`}
            >
              Next
            </div>
          </li>
          <li>
            <select
              className="form-control"
              onChange={({ target }) => {
                newData(target)
              }}
            >
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="35">35</option>
              <option value="40">40</option>
              <option value="45">45</option>
              <option value="50">50</option>
            </select>
          </li>
        </ul>
      )}
    </nav>
  )
}
Pagination.defaultProps = {
  firstPage: 0,
  lastPage: 0,
  pages: 1,
  paged: [],
  currentIndex: 1,
  setCurrentIndex: [0],
  pageSize: 20,
  prev: () => {},
  next: () => {},
  updateLimit: () => {},
  hasNext: () => {},
  hasPrev: () => {},
  setLimit: () => {},
  displayPager: false,
}

Pagination.propTypes = {
  firstPage: PropTypes.number,
  lastPage: PropTypes.number,
  pages: PropTypes.number,
  paged: PropTypes.array,
  currentIndex: PropTypes.number,
  setCurrentIndex: PropTypes.number,
  pageSize: PropTypes.number,
  prev: PropTypes.func,
  next: PropTypes.func,
  updateLimit: PropTypes.func,
  hasNext: PropTypes.func,
  hasPrev: PropTypes.func,
  setLimit: PropTypes.func,
  displayPager: PropTypes.bool,
}
export default Pagination
