import { loginFaq } from '../FaqPage/faq-data/faq-login'

/**
 * This is where we aggregate each FAQ section into a consolidated FAQ page object array.
 * Here we provide top-level details that will be displayed on the main FAQ page,...
 * ...as well as identify which sections to include.
 * Any individual questions or sections flagged to be highlighted in the question/answer data...
 * ...(such as the most frequent questions) will be aggregated at the top level here,...
 * ...along with the overall word count, total question count, and time to read.
 */

export const faq = {
  breadcrumb: 'FAQ',
  icon: 'circle-questions',
  id: 'faq',
  pages: [],
  title: 'FAQ',
}

/**
 * @param {string} breadcrumbs Label displayed in the breadcrumbs at the top of the FAQ page
 */
faq.pages.push(
  {
    breadcrumb: 'Login',
    component: 'faq',
    description:
      'The National Archives works hard to ensure your account information is secure. To do this, we work closely with our Login.gov partners and do our best to make the process as easy as possible for you.',
    icon: 'name-tag',
    id: 'login',
    sections: loginFaq,
    title: 'Login.gov',
  }
  // {
  //   breadcrumb: 'Profile',
  //   component: 'faq',
  //   icon: 'person',
  //   id: 'profile',
  //   sections: loginFaq,
  //   title: 'Profile',
  // }
)

// Add additional information to each faq object for display
let totalWordCount = 0
let totalQuestions = 0

const countWords = (content) => {
  let wordCount = 0
  if (typeof content === 'string')
    wordCount += content.trim().split(/\s+/).length
  else if (Array.isArray(content)) {
    //(typeof content === 'object') {
    content?.map((cont) => {
      if (typeof cont === 'string') wordCount += cont.trim().split(/\s+/).length
      else
        cont.props.children.map((c) => {
          if (typeof c === 'string') wordCount += c.trim().split(/\s+/).length
          else if (typeof c.props.children === 'string')
            wordCount += c.props.children.trim().split(/\s+/).length
          else c.props.children.map((c2) => {})
        })
    })
  }
  return wordCount
}

const returnTimeToRead = (wordCount) => {
  const timeDecimal = wordCount / 200
  return `${Math.round(timeDecimal)} minute read`
  // const timeArray = timeDecimal.toString().split('.')
  // return `${timeArray.length > 1 && `${timeArray[0]} minute, `}${
  //   timeArray.length > 1 ? `${timeArray[1]} second` : `${timeArray[0]} second`
  // } read`
}

faq.pages.map((group) => {
  totalWordCount = 0
  totalQuestions = 0
  totalWordCount += countWords(group.label)
  totalWordCount += countWords(group.description)
  group.sections?.map((section) => {
    totalWordCount += countWords(section.title)
    totalWordCount += countWords(section.description)
    section.questions?.map((subsection) => {
      totalWordCount += countWords(subsection.question)
      totalWordCount += countWords(subsection.answer)
      totalQuestions++
    })
  })
  group.totalWordCount = totalWordCount
  group.totalQuestions = totalQuestions
  group.timeToRead = returnTimeToRead(totalWordCount)
})
