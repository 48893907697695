import React, { Fragment } from 'react'
import '../styles/index.scss'
import PropTypes from 'prop-types'
import { handleQuotes } from '../helper-functions/queryHandlers.js'

const NoResultFeedback = (props) => {
  return (
    <div className="nac-body bg-primary-lighter">
      <p className="margin-bottom-2" data-testid="nac-results_none-text">
        There are no search results found{' '}
        {props.term ? (
          <>
            using the search term: <em>{handleQuotes(props.term)}</em>
          </>
        ) : (
          'for your search'
        )}
      </p>
      <h2>What should I do next?</h2>
      <ul className="usa-list">
        <li>
          Check your spelling and perhaps try alternate spellings. If you are
          searching for a person&apos;s name, read our{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/research/genealogy/catalog-guide"
          >
            Search Tips for Family Historians.
          </a>
        </li>
        <li>
          If you used quotes around your keywords, try the search without them.
          Read more about{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/research/catalog/help/search-tips"
          >
            How to Search.
          </a>
        </li>
        <li>
          Your search may have been too specific. Try a broader topic or related
          topic. Visit our{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/research/topics"
          >
            Research Topics Pages
          </a>{' '}
          for more ideas.
        </li>
        <li>
          If you would like to request copies of military service records, use
          our separate online system called{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/veterans/military-service-records/"
          >
            eVetRecs
          </a>{' '}
          to make your request.
        </li>
        <li>
          The National Archives might not have what you are looking for. Read
          about{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/research/catalog/help/using#What_is_the_National_Archives"
          >
            What&apos;s here and what&apos;s not
          </a>
          {' and '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/research/catalog/help/using#What_Will_I_Find_in_the_National_Archives_Catalog"
          >
            What is included in this search?
          </a>
        </li>
      </ul>
    </div>
  )
}
NoResultFeedback.defaultProps = {
  term: '',
}

NoResultFeedback.propTypes = {
  term: PropTypes.string,
}
export default NoResultFeedback
