import React, { useContext } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Components
import StatusPill from '../Pill/variants/StatusPill'
import ScrollContext from '../../contexts/ScrollContext'
import {
  onlineAvailabilityAlertSchema,
  onlineAvailabilityPropTypes,
} from '../../schemas/input-schemas/input-schema-online-availability'

/**
 * This alert notifies a user when a parent-level record contains child-level records which have been fully or partially digitized.
 */
export const PillOnlineAvailability = ({ availability, ...props }) => {
  const { handleScrollTo } = useContext(ScrollContext) || {}
  let schema = onlineAvailabilityAlertSchema.filter(
    ({ id }) => id === availability
  )[0]
  if (!schema) return false
  return (
    <StatusPill
      className={['clickable', `usa-pill--${schema.type}`].join(' ')}
      data-testid="nac-record_online-availability-pill"
      icon
      label={schema?.label || schema?.title}
      onClick={() => handleScrollTo('online-availability')}
      status={schema?.type}
    />
  )
}

PillOnlineAvailability.defaultProps = {
  availability: 'notDigitized',
}

PillOnlineAvailability.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * Determines the online availability alert content.
   */
  availability: PropTypes.oneOf(onlineAvailabilityPropTypes),
}
