import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../../../styles/index.scss'
//Data
import { help } from './help-center-data/help-center-full-data'
//Components
import HelpCenterWrapper from './help-center-wrapper'
import { Button } from '../../Button/Button'
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs'
import { IconLabel } from '../../IconLabel/IconLabel'

const HelpCenterPage = ({ ...props }) => {
  const { pageId } = useParams()
  const [pageContent, setPageContent] = useState(null)
  // const [pageId, setPageId] = useState(useParams().pageId)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (pageId) {
      const thisPage = help.pages.filter((page) => {
        return page.id === pageId
      })[0]
      if (thisPage) setPageContent(thisPage)
      else
        setTimeout(function () {
          setError(true)
        }, 1000)
    }
  }, [pageId])

  return error ? (
    'There was an error!!'
  ) : pageContent ? (
    <HelpCenterWrapper>
      {/** Breadcrumbs */}
      <Breadcrumbs
        crumbs={[
          { title: 'Help', link: '/help' },
          { title: pageContent.breadcrumb },
        ]}
        delimeter="forward-slash"
      />

      {/** Page Title */}
      <div className={['display-flex', 'flex-column', 'flex-gap'].join(' ')}>
        <h2 className="margin-bottom-0">
          <IconLabel
            color="base-darker"
            iconName={pageContent.icon}
            iconSize="lg"
            size="xl"
            strokeThickness="thicker"
          >
            <strong>{pageContent.title}</strong>
          </IconLabel>
        </h2>
      </div>

      {pageContent.pages.map((page) => (
        <Fragment key={page.id}>
          <Button to={{ pathname: page.id }}>{page.title}</Button>
        </Fragment>
      ))}
    </HelpCenterWrapper>
  ) : (
    'Loading Content!!!'
  )
}

HelpCenterPage.defaultProps = {}

HelpCenterPage.propTypes = {}

export default HelpCenterPage
