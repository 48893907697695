import React, { useContext, useEffect, useMemo, useState } from 'react'
import '../../../../styles/index.scss'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
//Settings
import { TRANSCRIPTION_INPUT_DEBOUNCE } from '../../../../components/settings/globals'
//Schemas
import { transcriptionInputSchema } from '../../../../schemas/transcription-input-schema'
//Context
import ContributionContext from '../../../../contexts/ContributionContext'
import DigitalObjectContext from '../../../../contexts/DigitalObjectContext'
import ObjectSearchContext from '../../../../contexts/ObjectSearchContext'
import UserContext from '../../../../contexts/UserContext'
//Components
import { IconLabel } from '../../../IconLabel/IconLabel'
//Hooks
import useInput from '../../../../hooks/use-input'
//Helper Functions
import {
  validateInputFromSchema,
  ValidationError,
} from '../../../form/Validate/Validate'
import useTranscriptionEditor from '../../../../hooks/use-transcription-editor'
import RecordContext from '../../../../contexts/RecordContext'

const Transcription = ({ ...props }) => {
  const { isEditing } = props
  const objectSearchContext = useContext(ObjectSearchContext)
  const { loggedInUser } = useContext(UserContext)
  const {
    currentActiveTranscription,
    currentTranscription,
    loggedInUserIsEditing: editActive,
    currentTranscriptionIsLocked,
    enteredTranscriptionHandler,
    lockedTranscriptions,
    transcriptionsDispatch,
    transcriptionIsPublishing,
    transcriptionIsCanceling,
    unsavedEdits,
    setUnsavedEdits,
  } = useContext(ContributionContext)
  // const digitalObjectContext = useContext(DigitalObjectContext) || {}

  const [firstLoad, setFirstLoad] = useState(false)

  useEffect(() => {
    setFirstLoad(false)
  }, [])

  const loggedInUserIsEditing =
    currentTranscription?.userId === loggedInUser?.userId && isEditing
  // const {  } =
  //   useTranscriptionEditor(digitalObjectContext?.objectId)
  const recordContext = useContext(RecordContext)
  const [transcriptionInputError, setTranscriptionInputError] = useState(null)
  // const { loggedInUserIsEditing } = useTranscriptionEditor(
  //   recordContext?.objectId
  // )

  const handleTranscriptionInputError = (error) => {
    setTranscriptionInputError(
      typeof error === 'string' ? new ValidationError(error) : error
    )
  }
  const {
    isValid: enteredTranscriptionIsValid,
    value: enteredTranscription,
    inputBlurHandler: transcriptionBlurHandler,
    reset: resetTranscriptionInput,
    valueChangeHandler: transcriptionChangedHandler,
  } = useInput(
    (value) => validateInputFromSchema(transcriptionInputSchema, value),
    handleTranscriptionInputError
  )

  useEffect(() => {
    // Update the saved status so when an edit occurs "saved" goes away and does not need to wait for the debounce function.
    if (unsavedEdits) {
      transcriptionsDispatch({
        type: 'UPDATE_CURRENT_TRANSCRIPTION_IS_SAVED',
        transcriptionIsSaved: false,
      })
    }
  }, [unsavedEdits])

  const handleInputDebounce = (event) => {
    transcriptionChangedHandler(event)
    enteredTranscriptionHandler(DOMPurify.sanitize(event.target.value))
    setUnsavedEdits(false)
  }

  // Reduce slowness on the input by waiting to run related input change functions
  const debouncedChangeHandler = useMemo(
    () => debounce(handleInputDebounce, TRANSCRIPTION_INPUT_DEBOUNCE),
    []
  )

  const errorInputClasses =
    transcriptionInputError?.type === 'error'
      ? 'invalid border-error-dark bg-error-lighter'
      : transcriptionInputError?.type === 'warning'
      ? 'warning border-accent-warm-dark bg-accent-warm-lighter'
      : ''
  const errorMessageClasses =
    transcriptionInputError?.type === 'error'
      ? 'text-error-dark padding-y-2 padding-x-2'
      : transcriptionInputError?.type === 'warning'
      ? 'text-warning-dark padding-y-2 padding-x-2'
      : ''
  const messageColor = transcriptionInputError
    ? transcriptionInputError.type === 'error'
      ? 'error-dark'
      : transcriptionInputError.type === 'warning'
      ? 'warning-dark'
      : 'base-darker'
    : 'base-darker'
  const messageIcon =
    transcriptionInputError &&
    (transcriptionInputError.type === 'error' ||
      transcriptionInputError.type === 'warning')
      ? 'triangle-exclaim'
      : null

  return loggedInUserIsEditing && currentTranscription?.status === 'draft' ? (
    // return loggedInUserIsEditing &&s contributionContext.currentTranscription?.status === 'draft' ? (
    <div
      className={[
        'display-flex',
        'flex-column',
        'height-full',
        'width-full',
      ].join(' ')}
    >
      <textarea
        className={[
          'border-0',
          'height-full',
          'line-height-sans-4',
          'padding-x-3',
          'padding-y-2',
          'radius-0',
          'text-left',
          'width-full',
          transcriptionIsPublishing ? 'disabled' : '',
        ].join(' ')}
        data-testid="nac-transcriptions_input"
        defaultValue={
          currentActiveTranscription && currentActiveTranscription.contribution
        }
        disabled={transcriptionIsPublishing || transcriptionIsCanceling}
        id={transcriptionInputSchema['id']}
        maxLength={transcriptionInputSchema['maxLength']}
        minLength={transcriptionInputSchema['minlength']}
        name={transcriptionInputSchema['id']}
        onChange={(event) => {
          setUnsavedEdits(true)
          debouncedChangeHandler(event)
        }}
        onBlur={transcriptionBlurHandler}
        required={transcriptionInputSchema['required']}
      />
      {transcriptionInputError?.message && (
        <p
          aria-atomic="true"
          aria-describedby={transcriptionInputSchema['id']}
          className={[
            errorMessageClasses,
            transcriptionInputError && transcriptionInputError.message
              ? 'active'
              : '',
            'anim-duration-500ms',
            'anim-opacity',
            'bg-error-lighter',
            'flex-row',
            'font-sans-3xs',
            'grid-row',
            'position-relative',
            `text-${messageColor}`,
          ].join(' ')}
          data-testid="nac-transcriptions_error"
          role="alert"
        >
          <IconLabel
            color={messageColor}
            iconName={messageIcon}
            iconSize="xs"
            size="2xs"
          >
            {transcriptionInputError && transcriptionInputError.message}
          </IconLabel>
        </p>
      )}
    </div>
  ) : (
    <p
      style={{ wordWrap: 'break-word' }}
      className={[
        'display-inline-block',
        'line-height-sans-4',
        'padding-x-3',
        'padding-y-2',
        'text-left',
        'width-full',
      ].join(' ')}
    >
      {currentActiveTranscription && currentActiveTranscription.contribution}
      {/*
        <Highlighter search={objectSearchContext?.query}>
          {props.text}
        </Highlighter>
      */}
    </p>
  )
}

Transcription.propTypes = {
  isEditing: PropTypes.bool,
  // transcription: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  // .isRequired,
}

export default Transcription
