import React from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import queryString from 'query-string'
// import { description } from '../../settings/RecordPropTypes'
//Components
import Truncate from '../../utilities/Truncate'
import { IconLabel } from '../../IconLabel/IconLabel'
//Helpers
//Hooks
import { Link, useLocation } from 'react-router-dom'
import { returnSortedOrgNames } from '../../Pages/AuthorityPages/use_organizations'
// import RecordContext from '../../../contexts/RecordContext'

const OrganizationNames = ({ record, state, ...props }) => {
  if (!record || !record?.organizationNames) return ''

  /**
   * Scroll to (and highlight) the Org Name NAID in the url parameters
   */
  const { search } = useLocation()
  const { q } = queryString.parse(search)
  const organizationNames = returnSortedOrgNames(record).slice(1)

  return (
    <div className="display-flex flex-column flex-gap-xs">
      {organizationNames?.map((name) => (
        <IconLabel
          align="start"
          color="base-dark"
          iconName="direct-child"
          iconSize="xs"
          key={name.naId}
          size="2xs"
        >
          <strong>
            <Link
              to={`/id/${record.naId}?organizationNaId=${name.naId}`}
              state={{ ...state }}
            >
              <Truncate highlight maxWords={15} term={q?.trim()}>
                {name.heading}
              </Truncate>
            </Link>
          </strong>
        </IconLabel>
      ))}
    </div>
  )
}

OrganizationNames.defaultProps = {}

OrganizationNames.propTypes = {
  /**
   * The entire data object for a single record
   */
  record: PropTypes.object,
  /**
   * The link state to pass on click
   */
  state: PropTypes.object,
}

export default OrganizationNames
