import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SEARCH_URL } from '../../settings/globals'
//Context
import SearchContext from '../../../contexts/SearchContext'
//Components
import { Alert } from '../../Alert/Alert'
//Helpers
import { compareNumbers } from '../../../helper-functions/sort-functions'
import { returnDateDifference } from '../../../helper-functions/date-functions'
//Hooks
import useHttp from '../../../hooks/use-http'
import useUrlParams from '../../../hooks/use-url-params'

const useOrganizations = (record, location) => {
  if (!record) return false

  const { searchQuery } = useContext(SearchContext) || {}

  const naId = record.naId
  const [naIdsToGet, setNaIdsToGet] = useState('')
  const [naIdsRecieved, setNaIdsRecieved] = useState(null)
  let organization = record
  if (record.parent) organization = record.parent

  /**
   * Scroll to (and highlight) the Org Name NAID in the url parameters
   */ // Get current params and param functions
  const { params } = useUrlParams()

  // Create state to house meaningful values based on params
  const organizationNaId = params['organizationNaId'] || null
  // const { search, state } = useLocation()
  // const { organizationNaId } = queryString.parse(search)

  useEffect(() => {
    let naIds = []
    record.organizationNames?.map((name) => {
      name.successors?.map((successor) => {
        naIds.push(successor.naId)
      })
      name.predecessors?.map((predecessor) => {
        naIds.push(predecessor.naId)
      })
    })
    setNaIdsToGet(naIds.join(','))
  }, [record])

  useEffect(() => {
    if (naIdsToGet) requestParentOrgHandler()
  }, [naIdsToGet])

  const handleParentOrgResponse = (response) => {
    let results = []

    response.body.hits.hits.map((result) => {
      let record = result._source.record
      results.push({ child: record.naId, parent: record.parent?.naId })
    })
    setNaIdsRecieved(results)
  }

  // Parent Org NaId fetch request
  const {
    error: parentOrgError,
    isLoading: parentOrgLoading,
    sendRequest: requestParentOrgHandler,
  } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?naId=${naIdsToGet}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleParentOrgResponse
  )

  useEffect(() => {
    if (!organizationNaId) return false
    const element = document.getElementById(organizationNaId)
    if (!element) return false
    const handler = setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }, 500)
    return () => {
      clearTimeout(handler)
    }
  }, [organizationNaId])

  const orgNames = returnSortedOrgNames(organization, false)

  const orgNameCount = orgNames?.length
  const establishedYear = orgNames[0]?.establishDate?.year || '?'
  const abolishedYear = orgNames[orgNameCount - 1]?.abolishDate?.year || '?'

  const infoText =
    establishedYear === '?' && abolishedYear === '?' ? (
      ''
    ) : (
      <Alert>
        Organizations can change over time. This section shows how the{' '}
        <span className="text-bold text-italic">
          {orgNames[orgNameCount - 1]?.name?.replace(
            /(ca|U|S|St|[A-Z]{1})?\./g,
            function ($0, $1) {
              return $1 ? $0 : '.'
            }
          )}
        </span>{' '}
        changed over{' '}
        {returnDateDifference(
          orgNames[0]?.establishDate,
          orgNames[orgNameCount - 1]?.abolishDate
        )}
        ,
        {establishedYear && establishedYear === abolishedYear
          ? ` in ${establishedYear}.`
          : ` between ${establishedYear} and ${abolishedYear}.`}
      </Alert>
    )

  const returnRoles = (roles) => {
    const list = Object.keys(roles).filter((k) => {
      return roles[k]
    })
    return list
  }

  return {
    infoText,
    naId,
    naIdsRecieved,
    orgNameMatch: organizationNaId,
    organization,
    orgNameCount,
    orgNames,
    returnRoles,
    searchQuery,
  }
}
export default useOrganizations

export const returnSortedOrgNames = (org, newestFirst = false) => {
  if (!org) return []
  return (
    org.organizationNames
      //Is this the best way to ensure these are ordered correctly?
      ?.sort((a, b) => {
        return compareNumbers(
          a?.abolishDate?.logicalDate?.replace(/-/g, '') || '9999999',
          b?.abolishDate?.logicalDate?.replace(/-/g, '') || '1111111',
          newestFirst
        )
      })
  )
}
