import React, { Fragment, useContext } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import ExpandCollapseList from '../utilities/ExpandCollapseList'
import Prose from '../utilities/Prose'
//Helpers
import { compareStrings } from '../../helper-functions/sort-functions'
import SearchContext from '../../contexts/SearchContext'
import Highlighter from '../Highlighter/Highlighter'
import useHighlightList from '../../hooks/use-highlight-list'

const SectionMicroformPublications = ({ ...props }) => {
  const recordContext = useContext(RecordContext)
  const record = recordContext.record
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  const microformPublicationArray = record.microformPublications.sort(
    (a, b) => {
      return compareStrings(a.identifier, b.identifier)
    }
  )

  const { sortedArray, count } = useHighlightList(
    q,
    microformPublicationArray,
    ['identifier', 'title', 'note']
  )

  return (
    <div
      className={['flex-column', 'width-full'].join(' ')}
      data-testid="nac-description__microform-publications"
    >
      <>
        <h3 id="microformPublications-label">
          <span>
            {record.microformPublications.length > 1 ? (
              <>
                Microform Publications
                <span className="usa-sr-only">
                  . This section contains {record.microformPublications.length}{' '}
                  items
                </span>
              </>
            ) : (
              'Microform Publication'
            )}
          </span>
          <span className="usa-sr-only">,</span>
        </h3>
        <ol
          // aria-labelledby="microformPublications-label"
          className={[
            'border',
            'border-base-lighter',
            'font-sans-xs',
            'padding-left-2',
            'padding-top-2',
            'radius-md',
            'text-base-darkest',
          ].join(' ')}
        >
          <ExpandCollapseList id="microform-list" initial={count}>
            {sortedArray.map(({ title, note, identifier }) => (
              <li
                key={title + identifier}
                className={[
                  'margin-left-3',
                  'margin-right-2',
                  'maxw-tablet-lg',
                  'padding-bottom-2',
                  'padding-left-1',
                ].join(' ')}
              >
                <div
                  className={[
                    'display-flex',
                    'flex-column',
                    'flex-gap-sm',
                  ].join(' ')}
                >
                  <div
                    className={[
                      // 'display-inline-flex',
                      // 'flex-align-center',
                      'text-bold',
                      // 'width-full',
                    ].join(' ')}
                  >
                    <Highlighter search={q}>{title}</Highlighter>
                  </div>
                  {note && (
                    <Prose>
                      <Highlighter search={q}>{note}</Highlighter>
                      <span className="usa-sr-only">, </span>
                    </Prose>
                  )}
                  {identifier && (
                    <span
                      className={['font-sans-3xs', 'text-italic'].join(' ')}
                    >
                      Identifier:{' '}
                      <Highlighter search={q}>{identifier}</Highlighter>
                      <span className="usa-sr-only">, </span>
                    </span>
                  )}
                </div>
              </li>
            ))}
          </ExpandCollapseList>
        </ol>
      </>
    </div>
  )
}
export default SectionMicroformPublications
