import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import '../../../../styles/index.scss'
//Components
import Prose from '../../../utilities/Prose'
import { Button } from '../../../Button/Button'

const Faq = ({ data, index, onClick, ...props }) => {
  const [expanded, setExpanded] = useState(false)

  const handleFaqClick = () => {
    setExpanded(!expanded)
    if (!expanded) window.history.replaceState(null, null, `#${data.id}`)
  }
  const { hash } = useLocation()
  const ref = useRef()

  useEffect(() => {
    if (ref.current && hash && hash === `#${data.id}`) {
      setExpanded(true)
      ref.current.focus()
      ref.current.scrollIntoView({ behavior: 'smooth' })
    } else setExpanded(false)
  }, [])

  return (
    <div
      className={['overflow-hidden', expanded ? 'focus-within' : ''].join(' ')}
      id={data.id}
      ref={ref}
    >
      <h2 className={index === 0 ? 'margin-0' : 'usa-accordion__heading'}>
        <Button
          align="start"
          aria-controls={`filter-group__${index}`}
          aria-expanded={expanded}
          className={[
            'line-height-sans-4',
            'outline-0',
            'radius-0',
            'text-left',
            'width-full',
          ].join(' ')}
          textAlign="left"
          theme="base-darker"
          iconName={`${expanded ? 'minus' : 'plus'}`}
          onClick={handleFaqClick}
          size="md"
          textOnly
        >
          <span>{data.question}</span>
        </Button>
      </h2>
      {expanded && (
        <Prose
          className={[
            // 'bg-base-lightest',
            'flex-row',
            'grid-row',
            'padding-bottom-5',
            'padding-x-5',
            'padding-top-2',
            // 'usa-accordion__content',
          ].join(' ')}
          hidden={!expanded}
          id={`filter-group__${index}`}
        >
          {data.answer}
        </Prose>
      )}
    </div>
  )
}

Faq.defaultProps = {}

Faq.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Faq
