import { useContext } from 'react'
import DOMPurify from 'dompurify'
//Context
import UserContext from '../../../contexts/UserContext'
//Helpers
import { returnFirstLetterCapitalized } from '../../../helper-functions/string-functions'

export function ValidationError(message) {
  this.message = message
  this.type = 'error'
}

export function ValidationWarning(message) {
  this.message = message
  this.type = 'warning'
}

export const validateInputFromSchema = (schema, input, arrayToCompare) => {
  //Sanitize the input
  const sanitizedInput = DOMPurify.sanitize(input)
  if (!isEmpty(input) && isEmpty(sanitizedInput)) {
    throw new ValidationError(
      `This ${
        schema['errorLabel'] || schema['label'] || 'value'
      } is not allowed.`
    )
  }
  //Some characters were removed during sanitization
  if (!isEmpty(input) && !isEmpty(sanitizedInput) && input !== sanitizedInput) {
    throw new ValidationWarning(
      `Part of this input will be removed because it contains some content that is not allowed.`
    )
  }
  for (const [key, value] of Object.entries(schema)) {
    //Handle required inputs

    // This is now handled at the parent level due to the need to use hooks
    // if (key === 'loggedIn' && value) {
    //   if (!loggedInUser?.userName)
    //     throw new ValidationError(
    //       `Users must be logged in to enter a ${
    //         schema['errorLabel'] || schema['label'] || 'value'
    //       }.`
    //     )
    // }
    if (key === 'required' || (key === 'notEmpty' && value)) {
      if (isEmpty(sanitizedInput))
        throw new ValidationError(
          `Please enter a valid ${
            schema['errorLabel'] || schema['label'] || 'value'
          }.`
        )
    }
    //Handle unwanted duplication of values by comparing to the provided array
    if (key === 'preventDuplicates' && value) {
      if (isDuplicated(sanitizedInput, 'contribution', arrayToCompare))
        throw new ValidationError(
          `The ${
            schema['errorLabel'] || schema['label'] || 'value'
          } "${sanitizedInput}" already exists. Try entering a different ${
            schema['errorLabel'] || schema['label'] || 'value'
          }.`
        )
    }
    //Handle prop types and formats
    if (key === 'type') {
      switch (value) {
        case 'text':
          if (!String(sanitizedInput))
            throw new ValidationError('Only characters are allowed.')
          break
      }
    }

    if (key === 'allow') {
      value.map((v) => {
        switch (v) {
          case 'exactDate':
            if (input?.length < 10) {
              throw new ValidationWarning(
                'Exact dates require a year, month and day in the format YYYY/MM/DD'
              )
            }
            break
        }
      })
    }

    //Handle values which exceed maximum character counts
    if (key === 'maxlength') {
      if (sanitizedInput.length === value)
        throw new ValidationWarning(
          `You've reached the maximum of ${schema['maxlength']} characters.`
        )
      if (sanitizedInput.length > value)
        throw new ValidationError(
          `${returnFirstLetterCapitalized(
            schema['errorLabel'] || schema['label'] || 'Input'
          )}s cannot exceed ${schema['maxlength']} characters.`
        )
    }
    //Handle values which are less than minimum character counts
    if (key === 'minlength') {
      if (sanitizedInput.length < value)
        throw new ValidationError(
          `${returnFirstLetterCapitalized(
            schema['errorLabel'] || schema['label'] || 'Input'
          )}s must be at least ${schema['minlength']} characters long.`
        )
    }
  }
}

//Helpers
export const isDuplicated = (value, field, array, caseSensitive = false) => {
  if (!array) return false
  const filteredArray = array?.filter((arr) => {
    if (caseSensitive) return arr[field] === removeExtraWhiteSpace(value)
    else
      return (
        arr[field].toLowerCase() === removeExtraWhiteSpace(value.toLowerCase())
      )
  })
  return filteredArray.length !== 0
}

export const isEmpty = (value) => {
  if (!value) return true
  return value.trim() === ''
}

export const removeExtraWhiteSpace = (value) => {
  if (!value) return false
  return value.replace(/\s+/g, ' ').trim()
}
