import React, { forwardRef, useEffect, useRef } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Components
import { Button } from '../Button/Button'
import useEventListener, { ESCAPE_KEYS } from '../../hooks/use-event-listener'

/**
 *
 */

const Popover = forwardRef(
  (
    { className, children, hide, id, position, reduced, title, ...props },
    ref
  ) => {
    const content = useRef()

    useEffect(() => {
      if (content.current) {
        content.current.focus()
      }
    }, [content])

    const handleKeyPress = ({ key }) => {
      if (ESCAPE_KEYS.includes(String(key))) hide()
    }
    useEventListener('keydown', handleKeyPress)

    return (
      <>
        {/**
         * Identify the title.
         * Use aria-labelledby="id" on .usa-modal to associate a title
         * with the popover so that it’s read when opening the popover.
         * The id should belong to the id attribute on .usa-modal__heading.
         */}

        {/**
         * Descriptions.
         * Optionally, you may use aria-describedby="id" on .usa-modal
         * to associate descriptive text to the popover window
         * so that it’s read when opening the popover.
         * The id should belong to a paragraph or a brief piece of content.
         */}
        <div
          aria-describedby={`popover-${id}-description`}
          aria-labelledby={`popover-${id}-heading`}
          className={[
            'usa-modal',
            'popover-content',
            'bg-white',
            'border-base-lighter',
            'border-1px',
            'display-flex',
            'flex-column',
            'margin-x-auto',
            'margin-y-105',
            'maxw-mobile-lg',
            'radius-md',
            'position-absolute',
            'shadow-md',
            'text-base-darker',
            'text-left',
            'min-w-card-lg',
            'z-top',
            className,
          ].join(' ')}
          id={`popover-${id}`}
          ref={content}
          role="dialog"
          style={{
            bottom: position?.[0]?.bottom >= 0 ? position?.[0]?.bottom : 'auto',
            left: position?.[0]?.left >= 0 ? position?.[0]?.left : 'auto',
            right: position?.[0]?.right >= 0 ? position?.[0]?.right : 'auto',
            top: position?.[0]?.top >= 0 ? position?.[0]?.top : 'auto',
          }}
          tabIndex="0"
          {...props}
        >
          <div
            className={[
              'modal-header',
              'border-bottom-1px',
              'border-base-lighter',
              'display-flex',
              'flex-align-center',
              'minh-button',
              reduced ? 'padding-x-1' : 'padding-x-2 padding-y-1',
            ].join(' ')}
          >
            {title && (
              <h2
                className="margin-bottom-0 width-full"
                id={`popover-${id}-heading`}
              >
                {title}
              </h2>
            )}
          </div>
          {children}
        </div>
      </>
    )
  }
)

Popover.defaultProps = {}

Popover.propTypes = {
  /**
   * Additional styles passed to the component
   */
  className: PropTypes.string,
  /**
   * JSX element(s), The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * Function to execute when the backdrop or close buttons are clicked
   */
  hide: PropTypes.func.isRequired,
  /**
   * Each .usa-modal must have a unique id so that
   * openers can associate them with their aria-controls attribute.
   */
  id: PropTypes.string.isRequired,
  /**
   * The x and y coordinates of the popover
   * Defaults to the center of the screen
   */
  position: PropTypes.arrayOf(
    PropTypes.shape({
      bottom: PropTypes.number,
      left: PropTypes.number,
      right: PropTypes.number,
      top: PropTypes.number,
    })
  ),
  /**
   * Reduces the size and padding of the component wrapper
   */
  reduced: PropTypes.bool,
  /**
   * JSX element(s), text displayed in the header of of the popover component
   */
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
}

Popover.displayName = 'Popover'

export default Popover

export const PopoverBody = ({ children, id, reduced, ...props }) => {
  return (
    <div
      className={['modal-body', reduced ? 'padding-0' : 'padding-2'].join(' ')}
      {...props}
      data-testid={`nac_${id}_popover-body`}
      id={`popover-${id}-description`}
    >
      {children}
    </div>
  )
}

PopoverBody.propTypes = {
  /**
   * JSX element(s), The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * For use with aria-describedby="id" on
   * .usa-modal to associate descriptive text to the popover window
   * so that it’s read when opening the popover.
   */
  id: PropTypes.string.isRequired,
  /**
   * Reduces the size and padding of the component wrapper
   */
  reduced: PropTypes.bool,
}

export const PopoverFooter = ({ children, hide, id, reduced, ...props }) => {
  return (
    <>
      <div
        className={[
          'modal-footer',
          //
          children
            ? [
                'border-top-1px',
                'border-base-lighter',
                reduced ? 'padding-x-05' : 'padding-1',
              ].join(' ')
            : '',
          'display-flex',
        ].join(' ')}
        {...props}
      >
        {children}
      </div>
      {/**
       * Include the “X” close button at the end of the popover code.
       * CSS will display .usa-modal__close at the top right
       * of the popover window, but placing the close button at
       * the bottom of the popover will prevent some screen readers
       * from reading the close button first and allow users to
       * navigate directly to the main content instead.
       */}
      {hide && (
        <Button
          className={['position-absolute', 'right-2px', 'top-2px'].join(' ')}
          data-testid={`nac_${id}_popover-close-button`}
          displayClass="block"
          iconName="close"
          iconOnly
          iconSize="xs"
          onClick={hide}
          reduced
          srText="Close popover"
          textOnly
        />
      )}
    </>
  )
}

PopoverFooter.propTypes = {
  /**
   * JSX element(s), The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * Function to execute when the close button is clicked
   */
  hide: PropTypes.func.isRequired,
  /**
   * For unique test ids
   */
  id: PropTypes.string.isRequired,
  /**
   * Reduces the size and padding of the component wrapper
   */
  reduced: PropTypes.bool,
}
