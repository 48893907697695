import PropTypes from 'prop-types'

export let inputOptionsSchemaPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })
)
export let inputSchemaPropTypes = PropTypes.shape({
  autoComplete: PropTypes.bool,
  examples: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  label: PropTypes.string,
  maxlength: PropTypes.number,
  minlength: PropTypes.number,
  options: inputOptionsSchemaPropTypes,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  sr: PropTypes.string,
  type: PropTypes.oneOf([
    'date',
    'range',
    'text',
    'checkbox-group',
    'radio-group',
    'select',
    'date-search-toggle',
  ]),
})
