import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import '../../styles/index.scss'

/**
 * Buttons are used in any scenario where there is a single onClick action
 */
export const TableLoader = ({ ...props }) => {
  return (
    <div
      className={[
        'display-flex',
        'flex-column',
        'grid-col',
        'padding-x-2',
        'padding-top-2px',
        'width-full',
      ].join(' ')}
    >
      {[...Array(10)].map((e, i) => (
        <div
          className={[
            'display-flex',
            'flex-align-center',
            'flex-no-wrap',
            'flex-row',
            'margin-y-05',
            'padding-bottom-1px',
            'width-full',
          ].join(' ')}
          key={i}
        >
          {[...Array(3)].map((e, j) =>
            j == 0 ? (
              <div
                className={[
                  'display-flex',
                  'flex-align-center',
                  'flex-no-wrap',
                  'flex-row',
                  'margin-right-1',
                  'width-full',
                ].join(' ')}
                key={i + j}
              >
                <div className={['grid-col-auto', 'margin-right-1'].join(' ')}>
                  <div
                    className={[
                      'block-load',
                      'display-block',
                      'height-5',
                      'padding-05',
                      'width-5',
                    ].join(' ')}
                  ></div>
                </div>
                <div
                  style={{ transition: 'all 500ms' }}
                  className={['padding-105', 'width-full'].join(' ')}
                >
                  <div
                    className={[
                      'block-load',
                      'height-2',
                      `grid-col-${
                        i == 0 ? 6 : i > 2 && i % 2 == 0 ? i - 1 : i + 2
                        //Math.floor((Math.random() * (10 - 2 + 1))) + 2
                      }`,
                    ].join(' ')}
                  ></div>
                </div>
              </div>
            ) : (
              <div
                className={['grid-col-auto', 'margin-left-auto'].join(' ')}
                key={i + j}
              >
                <div className={['padding-x-3', 'width-15'].join(' ')}>
                  <div
                    className={['block-load', 'height-2', 'width-full'].join(
                      ' '
                    )}
                  ></div>
                </div>
              </div>
            )
          )}
        </div>
      ))}
    </div>
  )
}

TableLoader.defaultProps = {}

TableLoader.propTypes = {}
