/**
 * Schema for building and validating the online availability dropdown input
 * TO DO:
 */
export const onlineAvailabilitySchema = {
  allow: ['array', 'string'],
  description:
    'Manually sets the online availbility header for the given record. If set to none, the system will determine the availability to be either Not Digitized or Partially Digitized based on the number of digital objects within the child records.',
  id: 'onlineAvailability',
  label: 'Online Availability',
  multiSelect: true,
  options: [
    {
      description:
        'This record contains records, none of which are available online.',
      id: 'onlineAvailability-none',
      label: 'None (Automatic)',
      order: 1,
      value: '',
    },
    {
      description: 'This record is fully available online.',
      id: 'onlineAvailability-fullyDigitized',
      label: 'Fully Digitized',
      order: 2,
      value: 'fullyDigitized',
    },
    {
      description:
        'This record contains unrestricted records which are fully available online.',
      id: 'onlineAvailability-unrestrictedOnly',
      label: 'Partially Available Online',
      secondaryLabel: 'Unrestricted Only',
      order: 3,
      value: 'unrestrictedOnly',
    },
  ],
  searchable: false,
  sr: 'Online availability multi-select dropdown field',
  type: 'select',
}

export const onlineAvailabilityAlertSchema = [
  {
    description: 'This record is fully available online.',
    id: 'fullyDigitized',
    label: 'Fully Available Online',
    title: 'Fully Available Online',
    type: 'success',
  },
  {
    description:
      'This record contains records, some of which are available online.',
    id: 'partiallyDigitized',
    label: 'Partially Available Online',
    title: 'Partially Available Online',
    type: 'warning',
  },
  {
    description:
      'This record contains unrestricted records which are fully available online.',
    id: 'unrestrictedOnly',
    label: 'Partially Available Online',
    secondaryLabel: 'Unrestricted Only',
    title: 'Partially Available Online: Unrestricted Only',
    type: 'warning',
  },
  {
    description:
      'This record contains records, none of which are available online.',
    id: 'notDigitized',
    label: 'Not Yet Available Online',
    title: 'Not Yet Available Online',
    type: 'warning',
  },
]

export const onlineAvailabilityPropTypes =
  onlineAvailabilityAlertSchema?.reduce((a, b) => [...a, b.id], [])
