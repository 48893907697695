import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import ObjectSearchContext from '../../../../contexts/ObjectSearchContext'
import RecordContext from '../../../../contexts/RecordContext'
import ScrollContext from '../../../../contexts/ScrollContext'
import UserContext from '../../../../contexts/UserContext'
//Components
import Avatar from '../../../Avatar/Avatar'
import CommentActions from './comment_actions'
import CommentInput from './comment_input'
import CopyUrl from '../../../utilities/CopyUrl/CopyUrl'
import { Button } from '../../../Button/Button'
import { ListSeparator } from '../../../ListSeparator/ListSeparator'
//Helpers
import { timeSince } from '../../../../helper-functions/date-functions'
import { UserNameDisplay } from '../../../Account/public/activity_feed'
import StaffPill from '../../../Pill/variants/StaffPill'

const Comment = ({ comment, ...props }) => {
  if (!comment) return false
  const { loggedInUser, loggedInUserAvatar } = useContext(UserContext)
  const { activeContributionId, setActiveContributionId } =
    useContext(RecordContext) || {}
  const scrollContext = useContext(ScrollContext)

  const ref = useRef()

  const [active, setActive] = useState(false)

  const id = activeContributionId || null

  useEffect(() => {
    if (!ref.current) return false
    if (!id) return false
    const element = document.getElementById(id)
    if (comment.contributionId === id && element) {
      const handler1 = setTimeout(() => {
        let isObject = false
        if (comment.targetObjectId) {
          scrollContext.handleScrollTo('preview')
          isObject = true
        }

        setTimeout(() => {
          element.scrollIntoView({
            behavior: 'smooth',
            block: isObject ? 'nearest' : 'center',
            inline: isObject ? 'start' : 'center',
          })
          setActive(true)

          // setTimeout(() => {
          setActiveContributionId(null)
          // }, 2000)
        }, 500)
      }, 500)
      return () => {
        clearTimeout(handler1)
        setActive(false)
      }
    }
  }, [id])

  useEffect(() => {
    if (active && id === comment.contributionId) {
      const handler = setTimeout(() => {
        setActive(false)
      }, 1000)
      return () => {
        clearTimeout(handler)
      }
    }
  }, [active])

  // Used to determine # of active replies
  function commentCount(arr) {
    let c = 0
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].status == 'active') {
        c++
      }
    }
    return c
  }

  const [editing, setEditing] = useState(false)
  const [replying, setReplying] = useState(false)

  const setEditingHandler = (value) => {
    setEditing(value)
    setReplying(false)
  }
  const setReplyingHandler = (value) => {
    setReplying(value)
    setEditing(false)
  }

  if (
    comment.status == 'active' ||
    (comment.children && commentCount(comment.children) > 0)
  )
    return (
      <div
        className={[
          'display-flex',
          'flex-row',
          'flex-gap-sm',
          active ? 'active-highlight' : '',
          'highlightable',
          'padding-1',
        ].join(' ')}
        data-testid={`nac-comments_${comment.contributionId}`}
        key={comment.contributionId}
        id={comment.contributionId}
        ref={ref}
      >
        {loggedInUser && comment.userId === loggedInUser?.userId ? (
          <Avatar
            isMadeByAdministrator={comment.madeByAdministrator}
            isNaraStaff={loggedInUser?.naraStaff === 1}
            src={loggedInUserAvatar}
            userName={loggedInUser?.userName}
          />
        ) : (
          <Avatar
            isMadeByAdministrator={comment.madeByAdministrator}
            isNaraStaff={comment.naraStaff === 1}
            userName={comment.userName}
          />
        )}
        <div className="flex-col grid-col">
          <CopyUrl
            search={`${
              comment.targetPageNum ? `objectPage=${comment.targetPageNum}` : ''
            }${comment.targetPageNum && comment.targetObjectId ? '&' : ''}${
              comment.targetObjectId ? 'objectPanel=comment' : ''
            }${
              comment.contributionId
                ? `&contributionId=${comment.contributionId}`
                : ''
            }`}
          >
            <div
              className={[
                'display-flex',
                'flex-gap-xs',
                'flex-row',
                'margin-top-1',
                'font-sans-2xs',
                'text-left',
              ].join(' ')}
            >
              <ListSeparator delimeter="bullet" distance="05" color="base">
                {comment.madeByAdministrator ? (
                  <span>
                    <strong>{comment.userName} </strong>
                    <StaffPill />
                  </span>
                ) : (
                  <a
                    href={`/accounts/${comment.userName}`}
                    className="text-bold"
                  >
                    <strong>{comment.userName} </strong>
                    {comment.naraStaff === 1 && <StaffPill />}
                  </a>
                )}
                <span className="font-sans-2xs text-base-darker">
                  {timeSince(comment.createdAt)}
                </span>
              </ListSeparator>
            </div>
          </CopyUrl>
          <div
            className={['flex-col', 'grid-col', 'flex-gap', 'margin-y-1'].join(
              ' '
            )}
            tabIndex="0"
          >
            {!editing &&
              (comment.status === 'active'
                ? comment.contribution
                : comment.children &&
                  comment.children.length > 0 && (
                    <span
                      className={[
                        'font-sans-sm',
                        'text-base-dark',
                        'text-italic',
                      ].join(' ')}
                    >
                      [This Comment Has Been Removed]
                    </span>
                  ))}
            {loggedInUser &&
              (replying || editing ? (
                <CommentInput
                  comment={comment}
                  replying={replying}
                  editing={editing}
                  setEditingHandler={setEditingHandler}
                  setReplyingHandler={setReplyingHandler}
                />
              ) : (
                <CommentActions
                  setEditingHandler={setEditingHandler}
                  setReplyingHandler={setReplyingHandler}
                  comment={comment}
                />
              ))}
          </div>
        </div>
      </div>
    )
  else return ''
}

Comment.defaultProps = {
  comment: {},
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
}

export default Comment
