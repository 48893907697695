import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../../styles/index.scss'
import { usePagination, useRowSelect, useTable } from 'react-table'
import IndeterminateCheckbox from '../form/Checkbox/variants/IndeterminateCheckbox/IndeterminateCheckbox'
import { TableLoader } from '../ContentLoaders/TableLoader'
import CheckboxField from '../form/CheckboxField'
import useScreenSize from '../../hooks/use-screen-size'
import Pagination from '../utilities/Pagination'

/**
 * Tables are used to display tabular data, and may include a variety of interactions
 */
export const Table = ({
  caption,
  columns,
  data,
  hideColumns,
  initialPage,
  setPage,
  id,
  loading,
  pageCount: controlledPageCount,
  pageNum,
  ...props
}) => {
  const { screenSize } = useScreenSize()
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: initialPage > 0 ? initialPage - 1 : 0,
        //pageSize: 10,
        hiddenColumns: hideColumns ? hideColumns : [],
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     // Let's make a column for selection
    //     {
    //       id: `selection_${id}`,
    //       // The header can use the table's getToggleAllRowsSelectedProps method
    //       // to render a checkbox
    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div className="table-checkbox ">
    //           <IndeterminateCheckbox
    //             id={`table-header-selection_${id}`}
    //             {...getToggleAllPageRowsSelectedProps()}
    //           />
    //         </div>
    //       ),
    //       className: ' width-5',
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       Cell: ({ row }) => (
    //         <>
    //           <CheckboxField
    //             id={`${id}_${String(row.original?.id || row.id)}`}
    //             {...row.getToggleRowSelectedProps()}
    //           />
    //         </>
    //       ),
    //     },
    //     ...columns,
    //   ])
    // }
  )

  return (
    <>
      {/*controlledPageCount && (
        <div className={['display-flex', 'margin-left-auto'].join(' ')}>
          <Pagination
            current={pageIndex + 1}
            handleNextObject={nextPage}
            handlePrevObject={previousPage}
            iconOnly
            id="results-top"
            label="Page"
            navLabel="Table page navigation (top)"
            //outline
            reduced
            textOnly
            theme="base-darker"
            total={pageOptions.length}
            updateCurrentObject={setPage}
          />
        </div>
      )*/}
      <table
        className="nac-table radius-md text-base-darker"
        {...getTableProps()}
      >
        {caption && (
          <caption
            className={[
              'bg-base-lighter-opacity-40',
              'font-sans-sm',
              'padding-x-105', // screenSize !== 'desktop' ? 'padding-x-1' :
              'padding-y-2', // screenSize !== 'desktop' ? 'padding-y-05' :
              // 'text-bold',
              'text-left',
            ].join(' ')}
          >
            {caption}
          </caption>
        )}
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr key={Math.random()} {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column, index) => (
                    // Apply the header cell props
                    <th
                      className={[
                        'bg-base-lighter-opacity-40',
                        'font-sans-2xs',
                        index === 0
                          ? 'padding-left-105'
                          : index === headerGroup.headers.length - 1
                          ? 'padding-right-105'
                          : 'padding-x-1',
                        // : screenSize !== 'desktop'
                        // ? 'padding-x-1'
                        // : 'padding-x-105',
                        'padding-y-1',
                        // 'text-normal',
                        column.className,
                      ].join(' ')}
                      key={Math.random()}
                      {...column.getHeaderProps()}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>

        {!loading && (
          <tbody data-testid={id} {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <tr
                    className="hover:bg-base-light-opacity-10"
                    key={Math.random()}
                    {...row.getRowProps()}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, index) => {
                        // Apply the cell props
                        return (
                          <td
                            className={[
                              'font-sans-xs',
                              index === 0
                                ? 'padding-left-105'
                                : index === row.cells.length - 1
                                ? 'padding-right-105'
                                : screenSize !== 'desktop'
                                ? 'padding-x-1'
                                : 'padding-x-105',
                              'padding-y-1',
                              cell.column.className,
                            ].join(' ')}
                            key={Math.random()}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        )}
      </table>
      {loading && <TableLoader />}
      {controlledPageCount && controlledPageCount > 1 && (
        <>
          <hr
            className={[
              'border-base-lighter',
              'border-top-2px',
              'width-full',
            ].join(' ')}
          />
          <div
            className={[
              'display-flex',
              'margin-left-auto',
              'padding-bottom-1',
            ].join(' ')}
          >
            <Pagination
              current={pageNum}
              handleNextObject={() => {
                setPage(pageNum + 1)
              }}
              handlePrevObject={() => {
                setPage(pageNum - 1)
              }}
              iconOnly
              id={`table-bottom-${id}`}
              label="Page"
              navLabel="Table page navigation (bottom)"
              //outline
              reduced
              textOnly
              theme="base-darker"
              total={pageOptions.length}
              updateCurrentObject={setPage}
            />
          </div>
        </>
      )}
    </>
  )
}

Table.defaultProps = {
  setPage: () => {},
}

Table.propTypes = {
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Decsription of parameter
   */
  columns: PropTypes.array.isRequired,
  /**
   * Decsription of parameter
   */
  data: PropTypes.array.isRequired,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  hideColumns: PropTypes.array,
  setPage: PropTypes.func,
  loading: PropTypes.bool,
  initialPage: PropTypes.number,
  pageCount: PropTypes.number,
  pageNum: PropTypes.number,
  id: PropTypes.string,
  row: PropTypes.object,
}
