import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import '../../../styles/index.scss'
//Components
import { Button } from '../Button'
import ClickAwayListener from 'react-click-away-listener'
import {
  removeParamsFromObject,
  returnValidatedUrlParameters,
} from '../../../helper-functions/param-functions'
import Popover, { PopoverBody, PopoverFooter } from '../../Popover/Popover'
import { SocialButton } from './SocialButton'
import Tooltip from '../../Tooltip/Tooltip'
import { IconLabel } from '../../IconLabel/IconLabel'
import useScreenSize from '../../../hooks/use-screen-size'
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'

/**
 * Share Buttons provide a popover of options to share the current page
 */
export const ButtonSharePage = ({
  children,
  className,
  hash,
  params,
  paramsToRemove,
  path,
  ...props
}) => {
  //Hooks
  const { screenSize } = useScreenSize()

  //State
  const [active, setActive] = useState(false)
  const [copied, setCopied] = useState(false)

  const handleCopy = async () => {
    // let {validatedParams} = returnValidatedUrlParameters(
    //   queryString.parse(params || location.search)
    // )

    // validatedParams = removeParamsFromObject(paramsToRemove, validatedParams)

    // let paramString
    // if (queryString.stringify(validatedParams))
    //   paramString = `?${queryString.stringify(validatedParams)}`
    // else paramString = ''

    // const url = `${location.host}${location.pathname}${paramString}${
    await navigator.clipboard.writeText(window.location.href)
    setCopied(true)
  }

  const [buttonHeight, setButtonHeight] = useState(0)
  const buttonRef = useRef(null)
  const popoverRef = useRef(null)

  useEffect(() => {
    if (buttonRef?.current && !buttonHeight)
      setButtonHeight(buttonRef.current?.clientHeight)
  }, [buttonRef])

  const handleClickAway = () => {
    if (active) {
      setActive(false)
      setCopied(false)
    }
  }

  return (
    <div
      className={[
        'display-flex',
        'flex-align-center',
        'position-relative',
        className,
      ].join(' ')}
    >
      <Button
        active={active}
        data-testid="nac-objects--share-page-button"
        iconName="share"
        iconOnly={screenSize === 'mobile'}
        iconSize="xs"
        onClick={() => setActive(!active)}
        reduced
        ref={buttonRef}
        thin
        theme="primary-dark"
        size="2xs"
        srText={children}
      >
        {children}
      </Button>
      {active && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popover
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="width-card-lg"
            hide={() => setActive(false)}
            id="share-page"
            position={[{ right: 0, top: buttonHeight }]}
            title="Share Options"
            ref={popoverRef}
            tabIndex={0}
          >
            <>
              {/*} <PopoverBody id="share-page">
                <div
                  className={[
                    'display-flex',
                    'flex-justify',
                    'flex-row',
                    'padding-x-2',
                  ].join(' ')}
                >
                  <SocialButton socialIcon="facebook" />
                  <SocialButton socialIcon="twitter" />
                  <SocialButton socialIcon="letter" />
                </div>
                </PopoverBody>*/}
              <PopoverFooter hide={() => setActive(false)} id="share-page">
                <div>
                  <div
                    className={['display-flex', 'flex-gap-sm', 'flex-row'].join(
                      ' '
                    )}
                  >
                    <FacebookShareButton
                      className={'display-flex'}
                      data-testid="nac-share-facebook"
                      // When using localhost this gets caught as an invalid url
                      url={`${window.location.href}`}
                    >
                      <FacebookIcon size={'2.5rem'} />
                    </FacebookShareButton>
                    <RedditShareButton
                      className={'display-flex'}
                      data-testid="nac-share-reddit"
                      url={`${window.location.href}`}
                    >
                      <RedditIcon size={'2.5rem'} />
                    </RedditShareButton>
                    <TwitterShareButton
                      className={'display-flex'}
                      data-testid="nac-share-twitter"
                      url={`${window.location.href}`}
                    >
                      <TwitterIcon size={'2.5rem'} />
                    </TwitterShareButton>
                  </div>
                  <div
                    className={[
                      'display-flex',
                      'flex-align-center',
                      'flex-justify',
                      'margin-right-auto',
                      'position-relative',
                      'width-full',
                    ].join(' ')}
                  >
                    <Button
                      className={['margin-right-auto'].join(' ')}
                      data-testid="nac-share-copy"
                      iconName="link"
                      iconSize="2xs"
                      onClick={handleCopy}
                      size="2xs"
                      textOnly
                    >
                      Copy page link
                    </Button>
                    {copied && (
                      <div className="bg-success-lighter padding-x-1 radius-md">
                        <IconLabel
                          color="success-darker"
                          iconName="check"
                          iconSize="2xs"
                          size="3xs"
                        >
                          Copied!
                        </IconLabel>
                      </div>
                    )}
                  </div>
                </div>
              </PopoverFooter>
            </>
          </Popover>
        </ClickAwayListener>
      )}
    </div>
  )
}

ButtonSharePage.defaultProps = {
  children: 'Share...',
}

ButtonSharePage.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * Additional classes to apply to the wrapper
   */
  className: PropTypes.string,
  /**
   * Hash to include as part of the generated sharable link
   */
  hash: PropTypes.string,
  /**
   * Param string to include as part of the generated sharable link
   */
  params: PropTypes.string,
  /**
   * Params to remove from the provided param string, or the default location.search prior to generating the sharable link
   */
  paramsToRemove: PropTypes.array,
  /**
   * Url path strucuture to include as part of the generated sharable link
   */
  path: PropTypes.string,
}
