import React, { useContext, useEffect, useRef, useState } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import CatalogFilters from '../../form/filters/CatalogFilters'
import ClickAwayListener from 'react-click-away-listener'
import Popover, { PopoverFooter } from '../../Popover/Popover'

export const FilterButton = (props) => {
  const [active, setActive] = useState(false)
  const popoverRef = useRef(null)
  const [buttonHeight, setButtonHeight] = useState(0)
  const buttonRef = useRef(null)

  useEffect(() => {
    if (buttonRef?.current && !buttonHeight)
      setButtonHeight(buttonRef.current?.clientHeight)
  }, [buttonRef])

  const handleClickAway = () => {
    if (active) {
      setActive(false)
    }
  }

  return (
    <>
      <div
        className={[
          'display-flex',
          'flex-align-center',
          'position-relative',
          props.className,
        ].join(' ')}
      >
        <Button
          active={active}
          data-testid="nac-objects--filter-button"
          iconName="funnel"
          iconSize="xs"
          onClick={() => {
            setActive(!active)
          }}
          reduced
          ref={buttonRef}
          size="2xs"
          srText="Filter options for the current page"
          textOnly
          theme="base-darker"
        >
          Filters...
        </Button>
        {active && !props.loading && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Popover
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="padding-bottom-1 width-card-lg"
              hide={() => setActive(false)}
              id="filter-options"
              position={[{ left: 0, top: buttonHeight }]}
              ref={popoverRef}
              tabIndex={0}
              title="Filters"
            >
              <PopoverFooter
                hide={() => setActive(false)}
                id="filter-options-body"
              >
                <CatalogFilters
                  filters={props.filters}
                  handleUpdateValue={props.handleUpdateFilters}
                />
              </PopoverFooter>
            </Popover>
          </ClickAwayListener>
        )}
      </div>
    </>
  )
}

FilterButton.defaultProps = {}

FilterButton.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.object,
  handleUpdateFilters: PropTypes.func,
  loading: PropTypes.bool,
}
