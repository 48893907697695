import React from 'react'
import PropTypes from 'prop-types'
import '../../../styles/index.scss'
import { Button } from '../Button'
import useDownloadButton from './use-download-button'

/**
 * Download Buttons are used to download content to the user's device
 */
export const DownloadButton = ({ children, fileUrl, fileName, ...props }) => {
  if (!fileUrl) {
    console.warn('No file was provided to download')
    return false
  }

  const { handleObjectDownload } = useDownloadButton(fileUrl, fileName)

  return (
    <Button
      data-testid="nac-object-viewer--download-button"
      href={fileUrl}
      iconName="download"
      iconSize="xs"
      id="contents-tab"
      onClick={handleObjectDownload}
      reduced
      target="_blank"
      size="sm"
      theme="base-lighter"
      thin
      {...props}
    >
      {children ? children : 'Download'}
    </Button>
  )
}

DownloadButton.defaultProps = {
  fileUrl: null,
}

DownloadButton.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * The url of the file to be downloaded
   */
  fileUrl: PropTypes.string.isRequired,
  /**
   * The user-facing name of the file once it is downloaded
   */
  fileName: PropTypes.string,
}
