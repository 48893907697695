import React, { Fragment, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import { SectionHeading } from '../SectionHeading/SectionHeading'
import ExpandCollapseList from '../utilities/ExpandCollapseList'

import Section from './section'
import { Alert } from '../Alert/Alert'
import SearchContext from '../../contexts/SearchContext'
import Highlighter from '../Highlighter/Highlighter'

const SectionElectronicRecords = ({ files, ...props }) => {
  const recordContext = useContext(RecordContext)
  const record = recordContext.record
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')
  const fileArray = useMemo(() => {
    let output = []
    let designators = []
    if (files) {
      // Pull just designators from files, filter duplicates, and sort alphabetically
      designators = files
        .map((obj) => {
          return obj.objectDesignator
        })
        .filter(function onlyUnique(value, index, self) {
          return self.indexOf(value) === index
        })
        .sort((a, b) => (a > b ? 1 : -1))
      // Add a catch all designator
      designators.push(['Other Files'])

      // 'output' becomes:
      // output = [ [designator 1, file 1, file 2, ...],
      //            [designator 2, file 1, file 2, ...],
      //            ...
      //            [designator n, file 1, file 2, ...],
      //            ["Additional Files", file 1, file 2, ...] ]
      designators.forEach((d) => {
        output.push([d])
      })
      files.forEach((f) => {
        // If no designation, add to additional files
        // Else, push to its appropriate array
        if (!f.objectDesignator) {
          output[output.length - 1].push(f)
        } else {
          for (var i = 0; i < output.length; i++) {
            if (f.objectDesignator == output[i][0]) {
              output[i].push(f)
            }
          }
        }
      })
      if (output[output.length - 1].length <= 1) {
        output.splice(output.length - 1, 1)
      }
    }

    if (output.length === 0) return false
    return output
  }, [record])

  function fileSize(byteCount) {
    var sizes = ['B', 'KB', 'MB', 'GB']
    if (!byteCount) {
      return ''
    }
    var i = parseInt(Math.floor(Math.log(byteCount) / Math.log(1024)))
    return Math.round(byteCount / Math.pow(1024, i), 2) + ' ' + sizes[i]
  }

  if (!fileArray) return false
  let notes = []
  if (record?.physicalOccurrences)
    record?.physicalOccurrences?.map((occurrence) => {
      if (occurrence.mediaOccurrences)
        occurrence.mediaOccurrences.map((m) => {
          if (m.technicalAccessRequirementsNote)
            notes.push(m.technicalAccessRequirementsNote)
        })
    })
  return (
    <Section className={['bg-gray-10'].join(' ')}>
      <div
        className={[
          'flex-column',
          'width-full',
          files && files.length > 0
            ? [
                'tablet-lg:border-base-lighter',
                'tablet-lg:margin-right-4',
                'tablet-lg:padding-right-4',
              ].join(' ')
            : ['grid-col', 'tablet-lg:grid-col-6'].join(' '),
        ].join(' ')}
        data-testid="nac-description__files"
        {...props}
      >
        <SectionHeading
          count={files.length}
          data-testid="nac-description__electronic-records"
          id="section-files"
        >
          Files Available for Download
          {/*files.length > 1 ? 'Other Files' : 'Other File'*/}
        </SectionHeading>{' '}
        <div
          className={[
            'add-list-reset',
            'display-flex',
            'flex-gap-md',
            'flex-column',
          ].join(' ')}
        >
          {fileArray.map((fileGroup, index) => (
            <Fragment key={fileGroup[0] + index}>
              {fileGroup[0] && (
                <div
                  className={[
                    'add-list-reset',
                    'display-flex',
                    'flex-column',
                  ].join(' ')}
                  data-testid={`nac-description__electronic-records--${index}`}
                >
                  <h3 className="text-base-darker">{fileGroup[0]}</h3>
                  <ol
                    className={['display-flex', 'flex-column', 'flex-gap'].join(
                      ' '
                    )}
                  >
                    <ExpandCollapseList initial={5}>
                      {fileGroup.slice(1).map((file, index) => (
                        <Fragment key={file.objectFilename + index}>
                          {file && (
                            <li className="margin-left-2 line-height-sans-4">
                              <a
                                href={file.objectUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {file.objectDescription &&
                                  file.objectDescription?.indexOf('Download') <
                                    0 &&
                                  'Download '}
                                <Highlighter search={q}>
                                  {file.objectDescription ||
                                    file.objectFilename}
                                </Highlighter>
                              </a>
                              <p>
                                {file.objectDescription &&
                                file.objectFilename ? (
                                  <Highlighter search={q}>
                                    {file.objectFilename},{' '}
                                  </Highlighter>
                                ) : (
                                  ''
                                )}
                                {file.objectType},{' '}
                                {fileSize(file.objectFileSize)}
                              </p>
                            </li>
                          )}
                        </Fragment>
                      ))}
                    </ExpandCollapseList>
                  </ol>
                </div>
              )}
            </Fragment>
          ))}
          {notes.length > 0 &&
            notes.map((note) => (
              <Alert key={note} title="Technical Access Requirements Note">
                {note}
              </Alert>
            ))}
        </div>
      </div>
    </Section>
  )
}
export default SectionElectronicRecords

SectionElectronicRecords.defaultProps = {
  files: [],
}

SectionElectronicRecords.propTypes = {
  files: PropTypes.array,
}
