import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import '../../../styles/index.scss'
//Components
import { Button } from '../Button'
import ClickAwayListener from 'react-click-away-listener'
import {
  removeParamsFromObject,
  returnValidatedUrlParameters,
} from '../../../helper-functions/param-functions'
import Popover, { PopoverBody, PopoverFooter } from '../../Popover/Popover'
import { SocialButton } from './SocialButton'
import Tooltip from '../../Tooltip/Tooltip'
import { IconLabel } from '../../IconLabel/IconLabel'
import useScreenSize from '../../../hooks/use-screen-size'
import useHighlighter from '../../Highlighter/use-highlighter'
import CheckboxField from '../../form/CheckboxField'
import HighlighterContext from '../../../contexts/HighlighterContext'

/**
 * Display Buttons provide a popover of options to modify the display of the current page
 */
export const ButtonDisplayOptions = ({ children, ...props }) => {
  //Hooks
  const { screenSize } = useScreenSize()
  const { displayHighlights, setDisplayHighlightsHandler } =
    useContext(HighlighterContext)

  //State
  const [active, setActive] = useState(false)

  const [buttonHeight, setButtonHeight] = useState(0)
  const buttonRef = useRef(null)

  const handleClickAway = () => {
    if (active) {
      setActive(false)
    }
  }

  useEffect(() => {
    if (buttonRef?.current && !buttonHeight)
      setButtonHeight(buttonRef.current?.clientHeight)
  }, [buttonRef])

  return (
    <div
      className={[
        'display-flex',
        'flex-align-center',
        'position-relative',
      ].join(' ')}
      {...props}
    >
      <Button
        active={active}
        data-testid="nac-public-display-options_button"
        iconName="gear"
        iconOnly={screenSize === 'mobile'}
        iconSize="xs"
        onClick={() => setActive(!active)}
        reduced
        ref={buttonRef}
        thin
        theme="primary-dark"
        size="2xs"
        srText={children}
      >
        {children}
      </Button>
      {active && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popover
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="width-card-lg"
            data-testid="nac-public-display-options_popover"
            hide={() => setActive(false)}
            id="public-display-options"
            position={[{ right: 0, top: buttonHeight }]}
            title="Page Settings"
          >
            <PopoverFooter
              hide={() => setActive(false)}
              id="public-display-options"
            >
              <div
                className={[
                  'display-flex',
                  'flex-align-center',
                  'flex-justify',
                  'margin-right-auto',
                  'position-relative',
                  'width-full',
                ].join(' ')}
              >
                <CheckboxField
                  checked={displayHighlights}
                  data-testid="nac-public-display-options_highlighter"
                  id="highlighter-option"
                  label="Highlight Text"
                  onChange={setDisplayHighlightsHandler}
                  onKeyPress={setDisplayHighlightsHandler}
                  value="display-highlights"
                />
              </div>
            </PopoverFooter>
          </Popover>
        </ClickAwayListener>
      )}
    </div>
  )
}

ButtonDisplayOptions.defaultProps = {
  children: 'Options...',
}

ButtonDisplayOptions.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
}
