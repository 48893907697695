import React, { Fragment, useState, useRef, useEffect, forwardRef } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'

const ExpandCollapseString = ({
  bg,
  children,
  disabled,
  less,
  more,
  ...props
}) => {
  const ref = useRef()

  const [heightToShow, setHeightToShow] = useState('height-min-string')
  const [expanded, setExpanded] = useState(false)
  const [noExpand, setDisabled] = useState(disabled)

  const handleShowMore = () => {
    heightToShow === 'height-min-string'
      ? (setHeightToShow('height-auto'), setExpanded(true))
      : (setHeightToShow('height-min-string'), setExpanded(false))
  }
  useEffect(() => {
    if (ref.current.clientHeight < 200) {
      setDisabled(true)
      setHeightToShow('height-auto')
    }
  }, [children])

  return disabled ? (
    <div ref={ref}>{children}</div>
  ) : (
    <>
      <div
        className={[heightToShow, 'overflow-hidden', 'position-relative'].join(
          ' '
        )}
      >
        <div ref={ref}>{children}</div>
        {!expanded && !noExpand && (
          <div
            className={[
              bg
                ? `bg-gradient-${bg}-opacity-0--${bg}-opacity-100`
                : 'bg-gradient-white-opacity-0--white-opacity-100',
              'bottom-0',
              'height-15',
              'position-absolute',
              'width-full',
            ].join(' ')}
          ></div>
        )}
      </div>
      {!noExpand && (
        <div className="flex-row grid-row width-full">
          <button
            onClick={handleShowMore}
            className={[
              'bg-transparent',
              'border-0',
              'clickable',
              'font-sans-3xs',
              'linkable',
              'padding-1',
              'text-primary',
            ].join(' ')}
          >
            {expanded ? less : more}
          </button>
        </div>
      )}
    </>
  )
}

ExpandCollapseString.defaultProps = {
  disabled: false,
  less: 'Show less...',
  more: 'Show more...',
}

ExpandCollapseString.propTypes = {
  /**
   * The color to fade to
   */
  bg: PropTypes.string,
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  disabled: PropTypes.bool,
  less: PropTypes.string,
  more: PropTypes.string,
}

ExpandCollapseString.displayName = 'ExpandCollapseString'

export default ExpandCollapseString
