import React, { useContext } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../contexts/RecordContext'
import SearchContext from '../../contexts/SearchContext'
//Components
import { ListSeparator } from '../ListSeparator/ListSeparator'
import { returnSortedOrgNames } from '../Pages/AuthorityPages/use_organizations'
import Highlighter from '../Highlighter/Highlighter'

const SectionSubtitle = ({ theme, ...props }) => {
  const { record } = useContext(RecordContext)
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  let label = 'Other title'
  let labels = 'Other titles'

  let titles = record.otherTitles || record.useFor
  if (record.variantPersonNames) {
    let names = []
    record.variantPersonNames.map((name) => {
      names.push(name.heading)
    })
    titles = names
    label = 'Other name'
    labels = 'Other names'
  }

  if (record.useFor) {
    label = 'Used for'
    labels = 'Used for'
  }

  if (record.organizationNames || record.parent?.organizationNames) {
    let names = []
    let orgNames = returnSortedOrgNames(record.parent || record)
    orgNames?.map((name, index) => {
      if (index > 0) names.push(name.heading)
    })
    titles = names
    label = 'Other name'
    labels = 'Other names'
  }

  let allTitles = []
  if (titles)
    titles.map((title) => {
      if (!title) return false
      const newtitles = title.split('\n')
      newtitles.map((newtitle) => {
        allTitles.push(newtitle)
      })
    })
  else if (record.subtitle) allTitles = [record.subtitle]
  else return false
  if (!titles || titles.length === 0) return false
  return (
    titles?.length > 0 && (
      <div
        className={[
          'breadcrumbs',
          'display-flex',
          'flex-row',
          'grid-row',
          'flex-no-wrap',
          'font-sans-md',
          // 'line-height-sans-4',
          'text-bold',
          `theme-${theme}`,
        ].join(' ')}
        data-testid="nac-page-header--subtitle"
      >
        {/*<span
        className={[
          'font-sans-sm',
          'margin-right-1',
          'text-italic',
          `theme-${theme}--accent`,
        ].join(' ')}
      >
        {`${allTitles.length > 1 ? `${labels}` : label} `}:
      </span>*/}
        <ListSeparator
          align="middle"
          delimeter="forward-slash"
          display="inline"
          offset={1}
          size="md"
        >
          <span
            className={[
              'font-sans-sm',
              'margin-right-1',
              'text-italic',
              `theme-${theme}--accent`,
            ].join(' ')}
          >
            {`${allTitles.length > 1 ? `${labels}` : label}`}:
          </span>
          {allTitles.map((title, i) => (
            <span key={Math.random()}>
              <Highlighter search={q}>{title}</Highlighter>
            </span>
          ))}
        </ListSeparator>
      </div>
    )
  )
}

SectionSubtitle.defaultProps = {
  theme: 'base',
}

SectionSubtitle.propTypes = {
  theme: PropTypes.string,
}

export default SectionSubtitle
