import React, { useEffect } from 'react'
import marked from 'marked'
import './markdown.scss'

const Markdown = () => {
  const showHelp = (e) => {
    const list = document.querySelector('.markdown-list')
    list.classList.toggle('hidden')
  }

  useEffect(() => {
    const result = Array.from(document.querySelectorAll('.markdown-items a'))
    result.forEach((item) => {
      item.setAttribute('target', '_blank')
    })
  }, [])

  return (
    <div className="markdown">
      <div className="header" onClick={showHelp}>
        Formatter Help
      </div>
      <div className="markdown-list hidden">
        <div className="markdown-items">
          <div className="markdown-name">Bold</div>
          <div className="markdown-format">**bold text**</div>
          <div
            className="markdown-format-result"
            dangerouslySetInnerHTML={{
              __html: marked(`**bold text**`),
            }}
          ></div>
        </div>
        <div className="markdown-items">
          <div className="markdown-name">Italic</div>
          <div className="markdown-format">*italicized text*</div>
          <div
            className="markdown-format-result"
            dangerouslySetInnerHTML={{
              __html: marked(`*italicized text*`),
            }}
          ></div>
        </div>
        <div className="markdown-items">
          <div className="markdown-name">Underline</div>
          <div className="markdown-format">
            &lt;u&gt; underlined text &lt;/u&gt;
          </div>
          <div
            className="markdown-format-result"
            dangerouslySetInnerHTML={{
              __html: marked(`<u>underline text</u>`),
            }}
          ></div>
        </div>
        <div className="markdown-items">
          <div className="markdown-name">Link</div>
          <div className="markdown-format">
            [Archive](https://catalog.archives.gov/)
          </div>
          <div
            className="markdown-format-result"
            dangerouslySetInnerHTML={{
              __html: marked(`[Archive]('https://catalog.archives.gov/')`),
            }}
          ></div>
        </div>
        <div className="mark-down-ref icon-popout-window">
          For more mark down attributes, please refer to &nbsp;
          <a
            href={'https://www.markdownguide.org/cheat-sheet/'}
            target="_blank"
            rel="noreferrer"
          >
            The Markdown Guide
          </a>
        </div>
      </div>
    </div>
  )
}

export default Markdown
