import React, { Fragment, useContext, useEffect } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import { SectionHeading } from '../SectionHeading/SectionHeading'
import SectionPartOfCard from './section_part_of_card'

const SectionPartOf = ({ ...props }) => {
  const { record } = useContext(RecordContext) || {}

  return (
    <div className="width-full">
      <SectionHeading copyLink count={record.ancestors.length} {...props}>
        Part of
      </SectionHeading>
      <div
        className={`flex-row grid-row margin-bottom-3 border-1px border-base-lighter radius-md shadow-xs padding-3 ${
          record.ancestors.length == 3
            ? 'width-full'
            : record.ancestors.length == 2
            ? 'maxw-tablet'
            : 'maxw-mobile'
        }`}
      >
        {record.ancestors.map((ancestor, index) => (
          <SectionPartOfCard
            ancestor={ancestor}
            index={index}
            data-testid="nac-description__from__card"
            key={ancestor.naId + index}
          />
        ))}
      </div>
    </div>
  )
}

export default SectionPartOf
