import React from 'react'
import '../styles/index.scss'

const EmptySearchFeedback = () => {
  return (
    <div className="nac-body bg-primary-lighter">
      <p className="margin-bottom-2">There are no search terms entered.</p>
      <h2>What should I do next?</h2>
      <ul className="usa-list">
        <li>
          Enter a search term or terms. If you are searching for a person&apos;s
          name, read our{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/research/genealogy/catalog-guide"
          >
            Search Tips for Family Historians
          </a>
        </li>
        <li>
          Read more about{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/research/catalog/help/search-tips"
          >
            How to Search.
          </a>
        </li>
        <li>
          Visit our{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/research/topics"
          >
            Research Topics Pages
          </a>{' '}
          for more ideas.
        </li>
        <li>
          If you would like to request copies of military service records, use
          our separate online system called{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/veterans/military-service-records/"
          >
            eVetRecs
          </a>{' '}
          to make your request.
        </li>
        <li>
          Read about{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/research/whats-here.html"
          >
            What&apos;s here and what&apos;s not
          </a>
          {' and '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.archives.gov/research/search/help/whats-included.html"
          >
            What is included in this search?
          </a>
        </li>
      </ul>
    </div>
  )
}
export default EmptySearchFeedback
