import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/index.scss'

const ModerationContent = ({ children, ...props }) => {
  return (
    <div
      className={[
        'display-flex',
        'flex-column',
        'flex-gap-md',
        'margin-top-2',
        'minh-0',
        'text-base-darker',
        'width-full',
      ].join(' ')}
    >
      {children}
    </div>
  )
}

ModerationContent.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default ModerationContent
