import React, { useContext } from 'react'
import { useOutletContext } from 'react-router-dom'
import '../../../styles/index.scss'
//Context
import AccountContext from '../../../contexts/AccountContext'
//Components
import { StatTotals } from './AccountOverview'
import { ActivityFeed } from './activity_feed'
import AccountContent, { ContentTitle } from '../account_content'
import Card from '../../Card/Card'

const AccountTranscriptions = () => {
  const accountState = useContext(AccountContext)
  return (
    <AccountContent>
      <ContentTitle iconName="transcription" title="Transcriptions" />
      {/* <StatTotals />*/}
      <ActivityFeed />
    </AccountContent>
  )
}

export default AccountTranscriptions
