import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import { DEFAULT_LIMIT, SEARCH_URL } from '../../settings/globals'
//Components
import { Alert } from '../../Alert/Alert'
import { Button } from '../../Button/Button'
import { Icon } from '../../Icon/Icon'
import { Table } from '../../Table/Table'
import AccountContent, { ContentTitle } from '../account_content'
import Popover, { PopoverBody, PopoverFooter } from '../../Popover/Popover'
import Card from '../../Card/Card'
import RadioButtonField from '../../form/RadioButtonField'
import Tooltip from '../../Tooltip/Tooltip'
//Helpers
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
//Hooks
import useOnlineAvailability from './use-online-availability'
import useScreenSize from '../../../hooks/use-screen-size'
import useHttp from '../../../hooks/use-http'

const OnlineAvailability = () => {
  const { pathname } = useLocation()
  const {
    changeHandler,
    columns,
    columnsRecords,
    data,
    enteredSearch,
    errors,
    initialNaIds,
    loading,
    loadingRecords,
    page,
    pages,
    searchedNaIds,
    setPageHandler,
    submitHandler,
    table,
    tableRecords,
    tempLoading,
    total,
    totalSearched,
  } = useOnlineAvailability()

  return (
    <AccountContent>
      <ContentTitle iconName="check" title="Online Availability" />
      <>
        <div
          className={[
            'display-flex',
            'flex-gap',
            'flex-column',
            'width-full',
          ].join(' ')}
        >
          <div
            className={['display-flex', 'flex-gap-0', 'flex-column'].join(' ')}
          >
            <div
              className={[
                'display-flex',
                'flex-align-center',
                'flex-gap-0 tablet-lg:flex-gap',
                'flex-column tablet-lg:flex-row',
                'margin-bottom-0',
                'margin-right-auto',
                'width-full',
              ].join(' ')}
            >
              <h2
                className={[
                  'display-flex',
                  'flex-align-center',
                  'flex-gap-sm',
                  'flex-row',
                  'margin-bottom-0',
                  'margin-right-auto',
                ].join(' ')}
                id="search-label"
              >
                Search Availability by NAIDs
                <Tooltip
                  content={`Search for availability by NAID.`}
                  delay={500}
                  display="block"
                  id={`#availabilitySearchDescription`}
                  position="topCenter"
                >
                  <Button
                    align="center"
                    iconName="circle-questions"
                    iconSize="xs"
                    reduced
                    shape="pill"
                    srText="Contribution search details"
                    textOnly
                    theme="base-dark"
                  />
                </Tooltip>
              </h2>
            </div>

            <div
              className={[
                'display-flex',
                'flex-align-center',
                'flex-gap-lg',
                'flex-justify',
                'flex-row',
                // 'flex-column desktop-lg:flex-row',
              ].join(' ')}
            >
              <form
                className={[
                  'display-flex',
                  'flex-align-start',
                  'flex-column',
                  'width-full',
                ].join(' ')}
                onSubmit={submitHandler}
              >
                <label
                  className={[
                    'display-flex',
                    'flex-align-center',
                    'flex-no-wrap',
                    'flex-row',
                    'position-relative',
                    'width-full desktop-lg:width-mobile',
                  ].join(' ')}
                >
                  <input
                    aria-labelledby="search-label"
                    className={[
                      'usa-input',
                      'bg-white',
                      'border-base-light',
                      'border-1px',
                      'display-inline-block',
                      'margin-top-0',
                      'maxw-full',
                      'minh-5',
                      'padding-105',
                      'padding-left-4',
                      'radius-md',
                      'width-full',
                    ].join(' ')}
                    defaultValue={enteredSearch || searchedNaIds?.join(',')}
                    id="availabilitySearch"
                    name="availabilitySearch"
                    onChange={changeHandler}
                    type="search"
                  />
                  <Icon
                    className="left-2px position-absolute"
                    color="base"
                    iconName="search"
                    iconPercent="60"
                    iconSize="md"
                    onClick={submitHandler}
                  />
                </label>
              </form>
              {totalSearched > 1 && (
                <Button
                  className="flex-shrink-0"
                  data-index={1}
                  outline
                  size="xs"
                  thin
                  to={{
                    pathname: `/id/${searchedNaIds?.[0]}`,
                  }}
                  state={{
                    from: {
                      pathname: pathname,
                      search: `?naId=${searchedNaIds?.join(',')}&sort=naId:asc`, //entry:naId
                    },
                    results:
                      (totalSearched || total) > 1
                        ? {
                            index: 1,
                            total: totalSearched || total,
                          }
                        : null,
                  }}
                >
                  Review Records
                </Button>
              )}
            </div>
          </div>
          <hr className={['border-base-lighter'].join(' ')} />
          {!loading && errors.length > 0 && (
            <div>
              {errors.map((error) => (
                <Alert key={error?.description} slim type={error?.type}>
                  {error?.description}
                </Alert>
              ))}
            </div>
          )}
          <div
            className={['display-flex', 'flex-gap-sm', 'flex-column'].join(' ')}
          >
            {!totalSearched && (
              <h2 className="margin-bottom-0">
                Current Online Availability Statuses
              </h2>
            )}

            <div
              className={[
                'display-flex',
                'flex-align-center',
                'flex-column desktop-lg:flex-row',
              ].join(' ')}
            >
              {totalSearched > 0 ? (
                <div
                  className={[
                    'display-flex',
                    'font-sans-xs',
                    'margin-right-auto',
                    'minw-0',
                    'text-left',
                  ].join(' ')}
                >
                  <span
                    className={[
                      'display-block',
                      'font-sans-xs',
                      'margin-right-auto',
                      'text-left',
                      'width-full',
                    ].join(' ')}
                  >
                    Showing{' '}
                    {totalSearched < DEFAULT_LIMIT ? (
                      totalSearched === 1 ? (
                        <>
                          1 result for NAID{' '}
                          <strong>
                            <em>{searchedNaIds?.join(', ')}</em>
                          </strong>
                        </>
                      ) : (
                        <>{returnNumberWithCommas(totalSearched)} results </>
                      )
                    ) : (
                      ` ${page * DEFAULT_LIMIT - DEFAULT_LIMIT + 1}–${
                        totalSearched > page * DEFAULT_LIMIT
                          ? page * DEFAULT_LIMIT
                          : totalSearched
                      } of ${returnNumberWithCommas(totalSearched)} records `
                    )}
                    {/*totalSearched > 1 && (
                      <>
                        for NAIDs{' '}
                        <strong className="display-block ellipsed minw-0">
                          <em>{searchedNaIds?.join(', ')}</em>
                        </strong>
                      </>
                    )*/}
                  </span>
                </div>
              ) : (
                ''
              )}
              {/*
              // Sort and Filter dropdown skeletons
              <div
                className={[
                  'display-flex',
                  'flex-gap-sm',
                  'flex-no-wrap',
                  'margin-left-auto',
                  'margin-right-2px',
                  'margin-top-2px',
                ].join(' ')}
              >
                <DropdownSort
                  id="availability-filter"
                  label="Availability:"
                  schema={availabilityFilterDropdownSchema}
                />
                <DropdownSort
                  id="availability-sort"
                  schema={availabilitySortDropdownSchema}
                />
              </div>*/}
            </div>
            {searchedNaIds?.length > 0
              ? tableRecords.length > 0 && (
                  <>
                    <Card
                      className={['padding-0', 'tablet-lg:padding-0'].join(' ')}
                    >
                      <Table
                        className="padding-0"
                        columns={columnsRecords}
                        data={tableRecords.slice(
                          (page - 1) * DEFAULT_LIMIT,
                          page * DEFAULT_LIMIT
                        )}
                        id="nac-record-table"
                        loading={loadingRecords || loading || tempLoading}
                        initialPage={page}
                        pageCount={pages}
                        setPage={setPageHandler}
                      />
                    </Card>
                  </>
                )
              : data.length > 0 && (
                  <div className="overflow-x-scroll margin-x-neg-2 tablet:margin-x-neg-0 padding-bottom-2 tablet-lg:padding-bottom-0">
                    <Card
                      className={[
                        'padding-0',
                        'tablet-lg:padding-0',
                        'margin-x-2',
                        'tablet-lg:margin-x-0',
                      ].join(' ')}
                      style={{ minWidth: '40rem' }}
                    >
                      <Table
                        className="padding-0"
                        columns={columns}
                        data={table}
                        id="nac-availability-table"
                        loading={loading || tempLoading}
                        initialPage={page}
                        pageCount={pages}
                        setPage={setPageHandler}
                      />
                    </Card>
                  </div>
                )}
          </div>
        </div>
      </>
    </AccountContent>
  )
}

export default OnlineAvailability

export const useOnlineAvailabilityActions = (
  availability,
  naId,
  onSuccess = () => {}
) => {
  const onSubmitHandler = () => {
    if (!availability && naId) deleteRequestHandler()
    else updateRequestHandler()
  }

  const updateAvailabilityResponseHandler = (response) => {
    onSuccess()
  }

  const { sendRequest: updateRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/online-availability/update`,
      method: 'PUT',
      body: { availability: availability, naId: naId, status: 'active' },
      headers: {
        'Content-Type': 'application/json',
      },
    },
    updateAvailabilityResponseHandler
  )

  const { sendRequest: deleteRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/online-availability/delete?naId=${naId}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    updateAvailabilityResponseHandler
  )
  return { onSubmitHandler }
}

export const OnlineAvailabilityTableButton = ({
  availability,
  naId,
  onUpdate,
  ...props
}) => {
  //State
  const [active, setActive] = useState(false)
  const [checked, setChecked] = useState(availability)

  //Hooks
  const { screenSize } = useScreenSize()
  const { onSubmitHandler } = useOnlineAvailabilityActions(
    checked,
    naId,
    onUpdate
  )

  //Refs
  const buttonRef = useRef(null)
  const popoverRef = useRef(null)

  const handleSetChecked = (value) => {
    if (value !== checked) {
      setChecked(value)
    } else {
      setChecked(availability)
    }
  }

  const onBlurHandler = (event) => {
    if (!event.relatedTarget && !popoverRef?.current?.contains(event.target)) {
      setActive(false)
      setChecked(availability)
    }
  }
  const onUpdateHandler = (event) => {
    setActive(false)
    onSubmitHandler()
  }

  return (
    <div
      className={[
        'display-flex',
        'flex-justify-start',
        'flex-row',
        'position-relative',
      ].join(' ')}
    >
      <Button
        active={active}
        className="flex-justify-center height-full"
        iconName="edit"
        iconOnly
        iconPercent={screenSize !== 'desktop' ? '100' : '80'}
        iconSize={screenSize !== 'desktop' ? 'xs' : 'sm'}
        onClick={() => setActive(!active)}
        reduced
        ref={buttonRef}
        srText={'Edit Availability Status'}
        textOnly
        theme="primary-dark"
        thin
        size="2xs"
      />

      {active && (
        <Popover
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="width-card-lg"
          hide={() => setActive(false)}
          id="share-page"
          onBlur={onBlurHandler}
          position={[{ right: 18, top: 14 }]}
          reduced
          title="Set Status"
          ref={popoverRef}
          tabIndex={0}
        >
          <PopoverBody reduced>
            <RadioButtonField
              checked={checked}
              groupId="one"
              handleSetChecked={() => handleSetChecked('fullyDigitized')}
              id="fullyDigitized"
              label="Fully Available Online"
              value="fullyDigitized"
            />
            <RadioButtonField
              checked={checked}
              groupId="one"
              handleSetChecked={() => handleSetChecked('unrestrictedOnly')}
              id="unrestrictedOnly"
              label="Unrestricted Only"
              value="unrestrictedOnly"
            />
            <RadioButtonField
              checked={checked}
              groupId="one"
              handleSetChecked={() => handleSetChecked('')}
              id="none"
              label="None"
              value=""
            />
          </PopoverBody>
          <PopoverFooter hide={() => setActive(false)} id="share-page" reduced>
            <Button
              className="margin-left-auto"
              disabled={checked === availability}
              onClick={onUpdateHandler}
              reduced
              thin
              size="2xs"
            >
              Update
            </Button>
          </PopoverFooter>
        </Popover>
      )}
    </div>
  )
}

OnlineAvailabilityTableButton.propTypes = {
  /**
   * Current availability status of the given NAID
   */
  availability: PropTypes.string,
  /**
   * NAID of the record to action
   */
  naId: PropTypes.string,
  /**
   * Function to run once the availability status has been successfully updated
   */
  onUpdate: PropTypes.func,
}
