import React from 'react'
import './styles/index.scss'

const Test = () => {
  return (
    <div
      className={[
        'display-flex',
        'flex-column',
        'flex-gap',
        'minh-0',
        'padding-4',
        'width-full',
      ].join(' ')}
    >
      TEST
    </div>
  )
}

export default Test
