import { useEffect, useState } from 'react'

const useCountdown = (targetDate, expiredAction) => {
  let countDownDate
  if (!targetDate) countDownDate = null
  else countDownDate = new Date(targetDate).getTime()

  const [countDown, setCountDown] = useState(new Date(targetDate).getTime())
  useEffect(() => {
    if (countDownDate > 0) {
      const interval = setInterval(() => {
        setCountDown(countDownDate - new Date().getTime())
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [countDownDate])

  const [days, hours, minutes, seconds] = getReturnValues(countDown)
  const timeOut = days + hours + minutes + seconds <= 0

  useEffect(() => {
    if (timeOut) expiredAction()
  }, [timeOut])

  return [days, hours, minutes, seconds]
}

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

  return [days, hours, minutes, seconds]
}

export default useCountdown
