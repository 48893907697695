import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import {
  useLocation,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import '../../../styles/index.scss'
//Context
import AccountContext from '../../../contexts/AccountContext'
import UserContext from '../../../contexts/UserContext'
//Components
import AccountContent, { ContentTitle } from '../account_content'
import Modal, { ModalBody, ModalFooter } from '../../Modal/Modal'
import DropdownField from '../../form/DropdownField'
import StatusPill from '../../Pill/variants/StatusPill'
import Tooltip from '../../Tooltip/Tooltip'
import { Button } from '../../Button/Button'
//Hooks
import useJustificationPopup from './use-justification-popup'
import useNotesPopup from './use-notes-popup'
import useScreenSize from '../../../hooks/use-screen-size'
import { Alert } from '../../Alert/Alert'
import ModerationContent from '../moderation_content'
import { FeedItem } from '../public/activity_feed'
import useInput from '../../../hooks/use-input'
import useModal from '../../../hooks/use-modal'
import StatCards, { StatCard } from '../stat_cards'
import useModerationTotals from './use-moderation-totals'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
import { convertCamelToSentence } from '../../../helper-functions/string-functions'
import {
  validateInputFromSchema,
  ValidationError,
} from '../../form/Validate/Validate'
// Schemas
import { moderateContributionsSchema } from '../../../schemas/input-schemas/input-schema-moderate-contributions'
import Select from '../../form/select'

const ModeratorDashboard = () => {
  //Context
  const accountState = useOutletContext() || {}
  if (!accountState) return false
  const { accountsAccessArray } = useContext(UserContext) || {}
  if (!accountsAccessArray.accessGrantedToModeratorTools) return false

  return (
    <AccountContext.Provider value={accountState}>
      <ModerationContent>
        <ContentTitle iconName="tool" title="Recent Moderations" />
        ModeratorDashboard
      </ModerationContent>
    </AccountContext.Provider>
  )
}

export default ModeratorDashboard

export const ModerationJustificationPopup = ({
  action,
  contribution,
  disabled,
  modifyHandler,
  ...props
}) => {
  const [contributionId, setContributionId] = useState(null)
  const setContributionIdHandler = (id) => {
    setContributionId(id)
  }
  //Hooks
  const { screenSize } = useScreenSize()
  const { isShowing, toggle } = useModal()
  const {
    details,
    justifications,
    justificationValue,
    loadingJustifications,
    handleJustificationChange,
  } = useJustificationPopup(
    action,
    contribution,
    isShowing,
    setContributionIdHandler,
    contributionId
  )

  const [schema, setSchema] = useState({
    allow: ['array', 'string'],
    id: 'justifications',
    label: 'Justification',
    options: [],
    sr: 'Transcription version dropdown field',
    type: 'select',
  })

  useEffect(() => {
    if (justifications) {
      const opts = []
      justifications?.map(({ justification, id }, index) => {
        opts.push({
          id: id,
          label: justification,
          order: index + 1,
          value: id,
        })
      })

      setSchema((prev) => ({ ...prev, options: opts }))
    }
  }, [justifications])

  const [notesInputError, setNotesInputError] = useState(null)

  const handleNotesInputError = (error) => {
    setNotesInputError(
      typeof error === 'string' ? new ValidationError(error) : error
    )
  }

  const { value: enteredNotes, valueChangeHandler: notesChangedHandler } =
    useInput(
      (value) => validateInputFromSchema(moderateContributionsSchema, value),
      handleNotesInputError
    )

  const notesInput = useRef(null)
  useEffect(() => {
    if (notesInput.current) {
      notesInput.current.focus()
    }
  }, [enteredNotes])

  return (
    <Fragment>
      <Tooltip className="flex-align-center" content={details.button}>
        <Button
          className="flex-justify-center height-full"
          disabled={disabled}
          iconName={details.icon}
          iconOnly
          iconPercent={screenSize !== 'desktop' ? '100' : '80'}
          iconSize={screenSize !== 'desktop' ? 'xs' : 'sm'}
          onClick={toggle}
          reduced
          srText={`${action} this ${
            contribution ? contribution.contributionType : 'contribution'
          } with a justification.`}
          textOnly
          theme="base-dark"
          thin
        />
      </Tooltip>
      {isShowing && (
        <Modal
          hide={toggle}
          id="modal-action-justfication"
          size="lg"
          title={details.title}
        >
          <Fragment>
            <ModalBody id="modal-action-justfication">
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'flex-gap',
                  'text-left',
                  'width-full',
                ].join(' ')}
              >
                {details.alert && (
                  <Alert icon={false} slim type="warning">
                    {details.alert}
                  </Alert>
                )}
                <div>
                  {contribution.contributionType === 'transcription' &&
                  action === 'revert' ? (
                    details.description
                  ) : (
                    <>
                      <p>{details.description}</p>
                      <FeedItem
                        className="bg-base-lightest-opacity-50"
                        item={contribution}
                        reduced
                      />
                    </>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter hide={toggle}>
              <div
                className={[
                  'display-flex',
                  'flex-gap-sm',
                  'flex-row',
                  'width-full',
                ].join(' ')}
              >
                {loadingJustifications ? (
                  <div className={['padding-1'].join(' ')}>
                    <div
                      className={[
                        'display-flex',
                        'flex-column',
                        'flex-gap-xs',
                        'margin-bottom-2',
                        'text-left',
                        'width-full',
                      ].join(' ')}
                    >
                      <div
                        className={['block-load', 'height-2', 'width-8'].join(
                          ' '
                        )}
                      ></div>
                      <div
                        className={[
                          'block-load',
                          'height-3',
                          'width-full',
                        ].join(' ')}
                      ></div>
                    </div>
                    <div
                      className={[
                        'display-flex',
                        'flex-column',
                        'flex-gap-sm',
                        'text-left',
                        'width-full',
                      ].join(' ')}
                    >
                      <div
                        className={[
                          'block-load',
                          'height-2',
                          'margin-bottom-1',
                          'width-8',
                        ].join(' ')}
                      ></div>
                      <div
                        className={[
                          'block-load',
                          'height-6',
                          'margin-bottom-4',
                          'width-full',
                        ].join(' ')}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div className={['width-full'].join(' ')}>
                    {justifications ? (
                      <>
                        <label
                          htmlFor={moderateContributionsSchema['id']}
                          className={[
                            'display-flex',
                            'flex-column',
                            'margin-right-1',
                          ].join(' ')}
                        >
                          <h2
                            className={[
                              'display-flex',
                              'flex-gap',
                              'flex-row',
                              'font-sans-xs',
                              'margin-bottom-05',
                            ].join(' ')}
                          >
                            {schema?.label}
                          </h2>
                          <div
                            className={[
                              'display-flex',
                              'flex-column',
                              'width-full',
                            ].join(' ')}
                          >
                            <Select
                              className="margin-bottom-0"
                              onChange={handleJustificationChange}
                              error={null}
                              // ref={ref}
                              schema={schema.options}
                              selected={schema?.options.filter(
                                (o) => o.id === justificationValue
                              )}
                              {...props}
                            />
                          </div>
                        </label>
                        {justifications?.map((justification) => {
                          if (justification?.id == justificationValue)
                            return (
                              <p
                                className="bg-base-lightest-opacity-50 border-base-lighter border-1px font-sans-xs margin-top-1 padding-1 radius-md"
                                dangerouslySetInnerHTML={{
                                  __html: justification.description,
                                }}
                                key={justification?.id}
                              />
                            )
                        })}
                      </>
                    ) : (
                      'No justifications found.'
                    )}
                  </div>
                )}
                <div className="width-full">
                  <label
                    htmlFor={moderateContributionsSchema['id']}
                    className={[
                      'display-flex',
                      'flex-column',
                      'margin-right-1',
                    ].join(' ')}
                  >
                    <span className="usa-sr-only">
                      {moderateContributionsSchema['srText']}
                    </span>
                    <h2
                      className={[
                        'display-flex',
                        'flex-gap',
                        'flex-row',
                        'font-sans-xs',
                        'margin-bottom-05',
                      ].join(' ')}
                    >
                      Moderator Notes
                    </h2>
                    <input
                      aria-required={moderateContributionsSchema['required']}
                      autoComplete={moderateContributionsSchema['autoComplete']}
                      autoFocus
                      className={[
                        'usa-input',
                        'bg-white',
                        'border-base-dark',
                        'border-1px',
                        'padding-left-2',
                        'padding-right-4',
                        'margin-bottom-3',
                        'margin-top-0',
                        'maxw-full',
                        'radius-md',
                      ].join(' ')}
                      data-testid="nac-tags_input"
                      id={moderateContributionsSchema['id']}
                      maxLength={moderateContributionsSchema['maxlength']}
                      name={moderateContributionsSchema['id']}
                      onChange={notesChangedHandler}
                      required={moderateContributionsSchema['required']}
                      type={moderateContributionsSchema['type']}
                      value={enteredNotes}
                      ref={notesInput}
                    />
                  </label>
                </div>
              </div>
              <div className={['display-flex', 'width-full'].join(' ')}>
                <Button
                  className={['margin-right-auto '].join(' ')}
                  data-testid="nac-users_justification-cancel"
                  iconSize="xs"
                  onClick={toggle}
                  reduced
                  size="2xs"
                  textOnly
                >
                  Cancel
                </Button>
                <Button
                  className={['margin-left-auto '].join(' ')}
                  data-testid="nac-users_justification-accept"
                  disabled={
                    loadingJustifications ||
                    (contribution.contributionType === 'transcription' &&
                      (!contributionId ||
                        contributionId === contribution.contributionId))
                  }
                  size="2xs"
                  onClick={() => {
                    if (contribution.contributionType === 'transcription') {
                      modifyHandler(
                        contributionId,
                        justificationValue,
                        enteredNotes
                      )
                    } else {
                      modifyHandler(
                        action,
                        contribution,
                        justificationValue,
                        enteredNotes
                      )
                    }
                    toggle()
                  }}
                  reduced
                >
                  {details.button}
                </Button>
              </div>
            </ModalFooter>
          </Fragment>
        </Modal>
      )}
    </Fragment>
  )
}

ModerationJustificationPopup.defaultProps = {}

ModerationJustificationPopup.propTypes = {
  action: PropTypes.string,
  contribution: PropTypes.object,
  disabled: PropTypes.bool,
  justifications: PropTypes.array,
  modifyHandler: PropTypes.func,
}

export const ModerationNotePopup = ({
  moderationType,
  justificationId,
  ...props
}) => {
  //Hooks
  const {
    details,
    loadingNotes,
    setShowingModerationNotePopup,
    showingModerationNotePopup,
  } = useNotesPopup(justificationId)
  const { screenSize } = useScreenSize()

  return (
    <Fragment>
      <Tooltip className="flex-align-center" content="View Notes">
        <Button
          className="clickable flex-justify-center  height-full padding-05"
          iconName="note"
          iconOnly
          iconPercent={screenSize !== 'desktop' ? '100' : '80'}
          iconSize={screenSize !== 'desktop' ? 'xs' : 'sm'}
          onClick={() => setShowingModerationNotePopup(true)}
          reduced
          srText="Access more moderation notes"
          textOnly
          theme="base-dark"
          thin
        />
      </Tooltip>
      {showingModerationNotePopup && (
        <Modal
          hide={() => setShowingModerationNotePopup(false)}
          size="lg"
          title="Moderation details"
        >
          <Fragment>
            <ModalBody id="modal-moderation-note">
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'text-left',
                  'width-full',
                ].join(' ')}
              >
                <div className="padding-1">
                  <h3>Action</h3>
                  <div>
                    This contribution has been{' '}
                    <StatusPill status={moderationType} />.
                  </div>
                </div>
                {justificationId && details ? (
                  <Fragment>
                    <div className="padding-1">
                      <h3>Justification</h3>
                      <p>{details.justification}</p>
                    </div>
                    <div className="padding-1">
                      <h3>Description</h3>
                      <p
                        className={[
                          'bg-base-lightest-opacity-50',
                          'border-base-lighter',
                          'border-1px',
                          'font-sans-xs',
                          'margin-top-1',
                          'padding-1',
                          'radius-md',
                        ].join(' ')}
                        dangerouslySetInnerHTML={{
                          __html: details.description,
                        }}
                      />
                    </div>
                  </Fragment>
                ) : (
                  <p>No justification was found.</p>
                )}
              </div>
            </ModalBody>
            <ModalFooter hide={() => setShowingModerationNotePopup(false)}>
              <div className={['display-flex', 'width-full'].join(' ')}>
                {/*<Button
                  className={['margin-right-auto '].join(' ')}
                  data-testid="nac-users_info-print"
                  disabled
                  iconName="fax"
                  iconSize="xs"
                  // onClick={() => alert('print!')}
                  reduced
                  size="2xs"
                  textOnly
                >
                  Print
              </Button>*/}
                <Button
                  className={['margin-left-auto '].join(' ')}
                  data-testid="nac-users_info-ok-close"
                  size="2xs"
                  onClick={() => setShowingModerationNotePopup(false)}
                  reduced
                >
                  OK
                </Button>
              </div>
            </ModalFooter>
          </Fragment>
        </Modal>
      )}
    </Fragment>
  )
}

ModerationNotePopup.defaultProps = {}

ModerationNotePopup.propTypes = {
  /**
   * References a specific description from the justiication table in the SQL database
   */
  justificationId: PropTypes.number,
  /**
   * Specified to which moderator action this note applies
   */
  moderationType: PropTypes.oneOf([
    'disabled',
    'enabled',
    'flagged',
    'unmoderated',
    'reviewed',
  ]),
}

export const StatFilters = ({ contributionType, ...props }) => {
  if (!contributionType) return false

  //Hooks
  const { page, userName } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    data: totals,
    error: errorTotals,
    loading: loadingTotals,
  } = useModerationTotals(contributionType)
  const { state } = useLocation()

  return (
    <StatCards>
      {overviewSchema.map(({ field, icon, label, route }) => (
        <StatCard
          active={
            !searchParams.get('status')
              ? route === ''
              : searchParams.get('status') === route
          }
          className="height-full  text-normal"
          iconName={icon || contributionType}
          key={route}
          label={
            `${label} ${convertCamelToSentence(contributionType)}s`
            // parseInt(totals?.[field]) > 1 || parseInt(totals?.[field]) === 0
            //   ? label[1]
            //   : label[0]
          }
          loading={loadingTotals}
          to={{
            search: route ? `status=${route}` : '',
          }}
          state={state}
          stat={returnNumberWithCommas(totals?.[field] || 0)}
        />
      ))}
    </StatCards>
  )
}
StatFilters.propTypes = {
  contributionType: PropTypes.string,
}

export const overviewSchema = [
  {
    field: 'total',
    icon: '',
    label: ['Total'],
    route: '',
  },
  {
    field: 'flagged',
    icon: 'flag',
    label: ['Flagged'],
    route: 'flagged',
  },
  {
    field: 'unmoderated',
    icon: 'thumb-up-off',
    label: ['Not Reviewed'],
    route: 'unmoderated',
  },
  {
    field: 'reviewed',
    icon: 'thumb-up',
    label: ['Reviewed'],
    route: 'reviewed',
  },
  {
    field: 'disabled',
    icon: 'stop',
    label: ['Disabled'],
    route: 'disabled',
  },
  // TODO: Re-enable/delete following the UI changes for admin tag deletion
  // This element will add a filter by madeByAdministrator to the moderator dashboard
  {
    field: 'madeByAdministrator',
    icon: 'institution',
    label: ['Administrator'],
    route: 'madeByAdministrator',
  },
  /* {
    icon: 'text',
    label: ['Unique Record', 'Unique Records'],
    route: 'records',
  },*/
]
