import { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

const useExport = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()

  const [naIdsToIncludeInExport, setNaIdsToIncludeInExport] = useState([])
  const [naIdsToExcludeFromExport, setNaIdsToExcludeFromExport] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [pageChecked, setPageChecked] = useState(null)

  //TO DO: Enable once we figure out how to prevent this on update to specific params, such as sort, limit, and page
  // useEffect(() => {
  //   clearCheckedHandler()
  // }, [searchParams])

  // Functions
  const setCheckedResultHandler = (naId) => {
    if (allChecked) {
      setNaIdsToExcludeFromExport((prev) => {
        if (prev.includes(naId)) return prev.filter((p) => p !== naId)
        else return [...prev, naId]
      })
    } else {
      setNaIdsToIncludeInExport((prev) => {
        if (prev.includes(naId)) return prev.filter((p) => p !== naId)
        else return [...prev, naId]
      })
    }
  }

  const setNaIdsToIncludeInExportHandler = (naIds, reset = false) => {
    if (reset) setNaIdsToIncludeInExport(naIds)
    else if (allChecked && naIdsToExcludeFromExport.length > 0) {
      setNaIdsToExcludeFromExport(
        naIdsToExcludeFromExport.filter((naId) => !naIds.includes(naId))
      )
    } else
      setNaIdsToIncludeInExport((prev) => {
        const ids = prev.concat(naIds)
        return [...new Set(ids)]
      })
  }

  const setAllCheckedHandler = (bool) => {
    if (bool) {
      setAllChecked(bool)
      setNaIdsToExcludeFromExport([])
    } else setAllChecked((prev) => !prev)
  }

  const clearCheckedHandler = () => {
    setAllChecked(false)
    setNaIdsToIncludeInExport([])
    setNaIdsToExcludeFromExport([])
  }

  useEffect(() => {
    if (allChecked) {
      setNaIdsToExcludeFromExport([])
      setNaIdsToIncludeInExport([])
    }
  }, [allChecked])

  useEffect(() => {
    clearCheckedHandler()
  }, [pathname])

  return {
    allChecked,
    naIdsToIncludeInExport,
    naIdsToExcludeFromExport,
    clearCheckedHandler,
    setAllCheckedHandler,
    setCheckedResultHandler,
    setNaIdsToIncludeInExportHandler,
    setNaIdsToExcludeFromExport,
  }
}
export default useExport
