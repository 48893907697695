import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import ClickAwayListener from 'react-click-away-listener'
import Popover, { PopoverBody, PopoverFooter } from '../../Popover/Popover'
import RadioButtonField from '../../form/RadioButtonField'
import { SEARCH_URL } from '../../settings/globals'
import { useSafeAsync } from '../../../helper-functions/query-functions'
import CircularProgress from '@mui/material/CircularProgress'
import ExportContext from '../../../contexts/ExportContext'
import { IconLabel } from '../../IconLabel/IconLabel'
import useHttp from '../../../hooks/use-http'
import useScreenSize from '../../../hooks/use-screen-size'
import SearchContext from '../../../contexts/SearchContext'
import useFormatter from '../../../hooks/use-formatters'
import PrintSearchResults from '../../PrintSearchResults.js'
import PopupWindow from '../../PopupWindow/PopupWindow.js'
import PrintDescriptionRecord from '../../description/PrintDescriptionRecord.js'
import { Link } from 'react-router-dom'

export const PrintPageButton = ({ results }, props) => {
  //   const { activeResults: results } = useContext(SearchContext)
  const { screenSize } = useScreenSize()
  const [active, setActive] = useState(false)
  const [printActive, setPrintActive] = useState(false)
  const popoverRef = useRef(null)
  const popoverPrintRef = useRef(null)
  const [buttonHeight, setButtonHeight] = useState(0)
  const buttonRef = useRef(null)
  const printButtonRef = useRef(null)
  const [checkedDetails, setCheckedDetails] = useState('brief')
  const [checkedFormat, setCheckedFormat] = useState('csv')
  const [printThumbnail, setPrintThumbnail] = useState('yes')
  const safeAsync = useSafeAsync()
  const [timedOut, setTimedOut] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [printing, setPrinting] = useState(false)
  const [queryParams, setQueryParams] = useState()
  const [error, setError] = useState(false)
  const [data, setData] = useState([])
  const [displayExport, setDisplayExport] = useState(false)
  const { formatDateTime } = useFormatter()
  const [openPopUp, setOpenPopup] = useState(false)
  const [showFull, setShowFull] = useState(false)

  useEffect(() => {
    if (buttonRef?.current && !buttonHeight)
      setButtonHeight(buttonRef.current?.clientHeight)
  }, [buttonRef])

  const {
    allChecked,
    naIdsToIncludeInExport,
    naIdsToExcludeFromExport,
    setNaIdsToIncludeInExportHandler,
  } = useContext(ExportContext) || {}

  const disabled =
    (allChecked && naIdsToExcludeFromExport.length === props.total) ||
    (!allChecked && naIdsToIncludeInExport.length === 0)

  const [checkedFrom, setCheckedFrom] = useState(disabled ? 'page' : 'current')

  useEffect(() => {
    if (disabled) setCheckedFrom('page')
    else setCheckedFrom('current')
  }, [disabled])

  const responsePrintHandler = (data) => {
    let results = []
    data.body.hits.hits.map((result) => {
      let record = { ...result._source.record, ...result.fields }
      results.push(record)
    })

    setData([...results])
  }

  const errorPrintHandler = (error) => {
    if (error) {
      setError(true)
      setPrinting(false)
    }
  }

  const createQueryString = (queryObj) => {
    const result = Object.entries(queryObj)
      .map((_m) => {
        if (!_m.includes('')) {
          return _m
            .join(' ')
            .replace(/^/, '&')
            .replace(/(?<=[a-z]\s)/, '=')
            .replace(/\s/, '')
        }
      })
      .join('')
      .replace(/&/, '')
    return result
  }

  const getQueryParams = () => {
    let _query = {}

    _query.naId = results[0]?.naId
    if (/brief/gi.test(checkedDetails)) {
      _query.abbreviated = true
    }
    _query.naId
    const result = createQueryString(_query)
    return result
  }

  const getQueryParamsMsg = () => {
    let _query = {}

    delete _query.abbreviated
    _query.naId = [].concat(results[0]?.naId)
    const result = createQueryString(_query)
    return result
  }

  const { sendRequest: requestPrintHandler } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?${getQueryParams()}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    responsePrintHandler,
    errorPrintHandler
  )

  const printFullPdf = () => {
    if (/page/gi.test(checkedFrom)) {
      requestPrintHandler()
    }
    setShowFull(true)
  }

  const printPDF = () => {
    requestPrintHandler()
    setDisplayExport(true)
    handleClickPrintAway(false)
  }

  const handleSetShowThumbnails = (e) => {
    setPrintThumbnail(e)
  }

  // Flatten arrays of objects recursively
  const flattenArray = (ob) => {
    var toReturn = {}
    for (var i in ob) {
      if (!ob[i]) continue
      if (typeof ob[i] == 'object' && ob[i] !== null) {
        var flatObject = flattenArray(ob[i])
        for (var x in flatObject) {
          if (typeof flatObject[x] !== 'function') {
            if (!flatObject[x]) continue
            toReturn[i + '.' + x] = flatObject[x]
          }
        }
      } else {
        toReturn[i] = ob[i]
      }
    }
    return toReturn
  }

  const handleClickPrintAway = () => {
    if (printActive) {
      setPrintActive(false)
    }
  }

  const handleSetCheckedDetails = (value) => {
    if (value !== checkedDetails) {
      setCheckedDetails(value)
    }
  }

  const handleSetCheckedFrom = (value) => {
    if (value !== checkedFrom) {
      setCheckedFrom(value)
    }
  }

  // Clears selected naIds when switching between export selections such as current, all, or page
  useEffect(() => {
    setNaIdsToIncludeInExportHandler([])
  }, [checkedFrom])

  return (
    <div
      className={[
        'display-flex',
        'flex-align-center',
        'position-relative',
        props.className,
      ].join(' ')}
    >
      <Button
        active={printActive}
        data-testid="nac-objects--print-search-button"
        iconName="printer"
        iconOnly={screenSize === 'mobile'}
        iconSize="xs"
        onClick={() => setPrintActive(!printActive)}
        reduced
        ref={printButtonRef}
        thin
        theme="primary-dark"
        size="2xs"
        srText="Print options for selected"
      >
        Print
      </Button>
      {printActive && (
        <ClickAwayListener onClickAway={handleClickPrintAway}>
          <Popover
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="width-card-lg"
            hide={() => setPrintActive(false)}
            id="print-options"
            position={[{ right: 0, top: buttonHeight }]}
            ref={popoverPrintRef}
            tabIndex={0}
            title="Print Options"
          >
            <>
              <PopoverBody id="print-options-body">
                <div
                  className={[
                    'display-flex',
                    'flex-column',
                    'flex-gap-sm',
                  ].join(' ')}
                >
                  <fieldset
                    role="radiogroup"
                    id="printFrom"
                    className="usa-fieldset flex-column grid-col padding-0 font-sans-2xs"
                  >
                    <label
                      className={`flex-row flex-align-center grid-row padding-bottom-1 text-base-dark text-normal font-sans-2xs`}
                      htmlFor="printFrom"
                    >
                      Export:
                    </label>
                    <RadioButtonField
                      checked={checkedFrom}
                      data-testid="nac-print-search-brief"
                      disabled={printing}
                      groupId="printFrom"
                      handleSetChecked={handleSetCheckedFrom}
                      id="page"
                      label={'Current Page'}
                      value="page"
                    />
                    <RadioButtonField
                      checked={checkedFrom}
                      data-testid="nac-export-search-brief"
                      disabled={
                        naIdsToIncludeInExport.length > 0 ? false : true
                      }
                      groupId="printFrom"
                      handleSetChecked={handleSetCheckedFrom}
                      id="current"
                      label={'Current Selection'}
                      value="current"
                    />
                  </fieldset>
                  <hr />
                  <fieldset
                    role="radiogroup"
                    id="exportDetails"
                    className="usa-fieldset flex-column grid-col padding-0 font-sans-2xs"
                  >
                    <label
                      className={`flex-row flex-align-center grid-row padding-bottom-1 text-base-dark text-normal font-sans-2xs`}
                      htmlFor="exportDetails"
                    >
                      Include:
                    </label>
                    <RadioButtonField
                      checked={checkedDetails}
                      data-testid="nac-export-search-brief"
                      disabled={printing}
                      groupId="printDetails"
                      handleSetChecked={handleSetCheckedDetails}
                      id="brief"
                      label={'Brief Details'}
                      value="brief"
                    />
                    <RadioButtonField
                      checked={checkedDetails}
                      data-testid="nac-export-search-full"
                      // disabled={true}
                      groupId="printDetails"
                      handleSetChecked={handleSetCheckedDetails}
                      id="full"
                      label={
                        <span>
                          <span>Full Details</span>
                        </span>
                      }
                      value="full"
                    />
                  </fieldset>
                  <hr />
                  <fieldset
                    role="radiogroup"
                    id="printFormat"
                    className="usa-fieldset flex-column grid-col padding-0 font-sans-2xs"
                  >
                    <label
                      className={`flex-row flex-align-center grid-row padding-bottom-1 text-base-dark text-normal font-sans-2xs`}
                      htmlFor="exportFormat"
                    >
                      Include Thumbnails:
                    </label>
                    <RadioButtonField
                      checked={printThumbnail}
                      data-testid="nac-export-print-brief"
                      // disabled={exporting}
                      groupId="includeThumbnails"
                      handleSetChecked={handleSetShowThumbnails}
                      id="showThumb"
                      label={'Yes'}
                      value="yes"
                    />
                    <RadioButtonField
                      checked={printThumbnail}
                      data-testid="nac-export-print-brief"
                      // disabled={exporting}
                      groupId="includeThumbnails"
                      handleSetChecked={handleSetShowThumbnails}
                      id="noShowThumb"
                      label={'No'}
                      value="no"
                    />
                  </fieldset>
                </div>
              </PopoverBody>
              <PopoverFooter
                hide={() => setPrintActive(false)}
                id="printer-options"
              >
                <div
                  className={[
                    'margin-right-auto',
                    'position-relative',
                    'width-full',
                  ].join(' ')}
                >
                  <Button
                    data-testid="nac-print-all"
                    disabled={
                      naIdsToIncludeInExport.length > 100 ||
                      (props.total > 100 && allChecked) ||
                      openPopUp
                        ? true
                        : false
                    }
                    size="2xs"
                    onClick={() => {
                      ;/full/gi.test(checkedDetails)
                        ? printFullPdf()
                        : printPDF()
                    }}
                  >
                    Print
                  </Button>
                  <div>
                    {naIdsToIncludeInExport.length > 100 ||
                      (props.total > 100 && allChecked && (
                        <div className="export-message">
                          To print more than 100 records, please use the Export
                          function.
                        </div>
                      ))}
                  </div>
                  {printing ? (
                    <div
                      className={[
                        'display-flex',
                        'flex-align-center',
                        'flex-justify',
                        'margin-right-auto',
                        'position-relative',
                      ].join(' ')}
                    >
                      <CircularProgress
                        style={{
                          color: '#005EA2',
                          height: '20px',
                          width: '20px',
                          marginLeft: '4px',
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {error ? (
                    <p
                      aria-atomic="true"
                      className={[
                        'text-error-dark',
                        'flex-row',
                        'grid-row',
                        'font-sans-3xs',
                        'position-relative',
                        'margin-top-1',
                        'minh-2',
                      ].join(' ')}
                      data-testid="nac-print_error"
                      role="alert"
                    >
                      <IconLabel
                        color={'error-dark'}
                        iconName={'triangle-exclaim'}
                        iconSize="xs"
                        size="xs"
                      >
                        Error, try again
                      </IconLabel>
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </PopoverFooter>
            </>
          </Popover>
        </ClickAwayListener>
      )}
      {data && data?.length > 0 && /brief/gi.test(checkedDetails) && (
        // data.map((itm, idx) => {
        <PrintSearchResults
          window={window}
          msg={`Here are the selected results for your search for <a target='_blank' href=${`${results[0]?.naId}
                      }`}>${results[0]?.title}</a>
            on ${formatDateTime()[0]}. Click the link to see the full details
              in the Catalog at catalog.archives.gov/
            `}
          setThumbs={printThumbnail}
          data-page-total={null}
          data-result-total={null}
          data-testid="nac-results-print"
          limit={null}
          main
          page={null}
          setData={setData}
          results={data}
          total={0}
        />
        // }
      )}
      {showFull && /full/gi.test(checkedDetails) && (
        <PopupWindow
          window={window}
          msg={`Here are the selected results for your search for <a target='_blank' href=${`${results[0]?.naId}
                    }`}>${results[0]?.title}</a>
          on ${formatDateTime()[0]}. Click the link to see the full details
            in the Catalog at catalog.archives.gov/
          `}
          setThumbs={printThumbnail}
          setData={setShowFull}
        >
          <div>
            {results &&
              results.length > 0 &&
              results.map((item, idx) => {
                return (
                  <PrintDescriptionRecord
                    key={idx}
                    naId={item.naId}
                    checkedDetails={{
                      checked: checkedDetails,
                      allChecked: allChecked,
                      checkedFrom: checkedFrom,
                      //   pageDetails: {
                      //     limit: props.limit,
                      //     page: props.search.page,
                      //     q: props.search.q,
                      //   },
                    }}
                  />
                )
              })}
          </div>
        </PopupWindow>
      )}
    </div>
  )
}

PrintPageButton.defaultProps = {}

PrintPageButton.propTypes = {
  results: PropTypes.array,
  total: PropTypes.number,
  className: PropTypes.string,
}
