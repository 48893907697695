import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { convertSentenceToCamel } from '../../../helper-functions/string-functions'

const FilterGroup = ({
  children,
  index,
  isOpen,
  label,
  onClick,
  totalOptions,
  ...props
}) => {
  const [expanded, setExpanded] = useState(isOpen)

  const handleFilterGroupClick = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <h2 className="usa-accordion__heading">
        <button
          aria-controls={`filter-group__${index}`}
          aria-expanded={expanded}
          className={[
            'usa-accordion__button',
            //
            'font-sans-2xs',
            'nac-icon-grey',
            'nac-icon__minus',
            expanded ? '' : 'margin-bottom-neg-1',
            'padding-2',
            'text-base-darker',
            'text-bold',
          ].join(' ')}
          onClick={handleFilterGroupClick}
          data-testid={`nac-filters--accordion--${convertSentenceToCamel(
            label
          )}`}
        >
          {label}
        </button>
      </h2>
      {expanded && (
        <div
          className={[
            'bg-base-lightest',
            'flex-row',
            'grid-row',
            'padding-0',
            'usa-accordion__content',
          ].join(' ')}
          hidden={!expanded}
          id={`filter-group__${index}`}
        >
          {children}
        </div>
      )}
    </>
  )
}

FilterGroup.defaultProps = {
  children: '',
  label: '',
  index: 0,
  totalOptions: 0,
  isOpen: true,
  onClick: () => {},
}

FilterGroup.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  totalOptions: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default FilterGroup
