import { useRef, useState } from 'react'
import useEventListener from '../../hooks/use-event-listener'

/**
 * The interactive Overlay prevents users from unintentionally zooming
 * in or out on a digital object during a scroll.
 */

const useInteractOverlay = () => {
  /* The overlay displays when...
   * ...the interact state if false and...
   * ...the image preview area is hovered
   * When the overlay is clicked, the interactive toggle becomes true
   * and the overlay is removed.
   * The interactive toggle resets to false (thus displaying the
   * overlay on hover once again) if...
   * ...the users cursor exits the larger preview wrapper.
   */
  const preview = useRef()

  // Determines if the image can be interacted with
  const [interact, setInteract] = useState(false)

  const setInteractHandler = (bool) => {
    setInteract(bool)
  }

  const resetInteractHandler = (event) => {
    if (event.target === preview?.current) {
      setInteract(false)
    }
  }

  // Moving the cursor outside of the larger preview area will
  // reset the interaction overlay to prevent scroll issues
  useEventListener('mouseleave', resetInteractHandler, preview?.current)

  return { interact, preview, setInteractHandler }
}
export default useInteractOverlay
