import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { description } from '../../settings/RecordPropTypes'
import '../../../styles/index.scss'
import { IconLabel } from '../../IconLabel/IconLabel'
import {
  returnActiveFromDates,
  returnDateDifference,
} from '../../../helper-functions/date-functions'
import { useSearchParams } from 'react-router-dom'
import Highlighter from '../../Highlighter/Highlighter'

const Creators = ({ record, ...props }) => {
  if (!record || (!record.ancestors && !record.creators)) return false
  const [searchParams] = useSearchParams()
  const q = searchParams?.get('q') || ''
  const creators = searchParams?.get('creators') || ''
  //If a creator is specified, highlight it here
  const search = `${q} ${creators}`

  const [createdBy, setCreatedBy] = useState([])
  const creatorsArray = record.creators
  const ancestorsArray = record.ancestors

  useEffect(() => {
    const createdByArray = []
    if (creatorsArray) {
      creatorsArray?.map((by) => {
        if (by.authorityType == 'organization') {
          createdByArray.push({
            type: 'group',
            title: by.heading,
            start: by.establishDate,
            end: by.abolishDate,
          })
        }
        if (by.authorityType == 'person') {
          createdByArray.push({
            type: 'person',
            title: by.heading,
            start: by.birthDate,
            end: by.deathDate,
          })
        }
      })
    } else if (ancestorsArray) {
      ancestorsArray?.map((by) => {
        if (by.creators)
          by.creators.map((creator) => {
            if (creator.authorityType == 'organization') {
              createdByArray.push({
                type: 'group',
                title: creator.heading || creator.title,
                start: creator.establishDate,
                end: creator.abolishDate,
              })
            }
            if (creator.authorityType == 'person') {
              createdByArray.push({
                type: 'person',
                title: creator.heading || creator.title,
                start: creator.birthDate,
                end: creator.deathDate,
              })
            }
          })
      })
    }
    setCreatedBy(createdByArray)
  }, [record])
  let short = true

  const returnCreatorString = (creator, id) => {
    const splitCreator = creator.split(/(ca|U|S|St|[A-Z]{1})\./)
    const newCreator = splitCreator.map((str, index) =>
      index <= splitCreator.length - 2 ? <p key={id + index}>{str}.</p> : ''
    )
    return newCreator[0] ? newCreator : <p key={id}>{creator}</p>
  }

  // Sort results so that the most recent is first, followed by predecessors in chronological order
  createdBy.sort((a, b) => {
    if (b.creator?.endDate && a.creator?.endDate)
      b.creator?.endDate?.year - a.creator?.endDate?.year
  })

  let justTitles = ''
  createdBy.map(
    (by, index) =>
      (justTitles +=
        by.title +
        (createdBy.length > 1 && index < createdBy.length - 1 ? '; ' : ''))
  )

  return (
    <>
      <div className="flex-row grid-row" data-testid="nac-result_creators">
        {short ? (
          <>
            {createdBy.map((by, index) =>
              index == 0 ? (
                <IconLabel
                  key={by.title}
                  align="start"
                  color="base-dark"
                  iconName={by.type || 'person'}
                  iconPercent="70"
                  iconSize="sm"
                  size="2xs"
                >
                  <span className="margin-top-05">
                    Creator{createdBy.length > 1 ? 's' : ''}:{' '}
                    <strong>
                      <Highlighter search={search}>{justTitles}</Highlighter>
                    </strong>
                  </span>
                </IconLabel>
              ) : (
                ''
              )
            )}
          </>
        ) : (
          <div className="margin-bottom-4">
            <h2 className="font-serif-2 text-normal margin-bottom-2">
              Created/Maintained by
            </h2>
            <ul className="add-list-reset">
              {createdBy.map((by) => (
                <li key={by.title}>
                  <h3>{by.title}</h3>
                  <div className="flex-row grid-row margin-right-auto margin-bottom-3">
                    <span
                      className={`flex-none flex-column grid-col nac-icons nac-icon__${
                        by.type || 'person'
                      } margin-right-2 width-2 height-2`}
                    ></span>
                    <div className="flex-column grid-col margin-right-2 maxw-mobile">
                      {returnCreatorString(by.title, by.naId)}
                    </div>{' '}
                    {by.end || by.start ? (
                      <>
                        <span className="flex-none flex-column grid-col nac-icons nac-icon__date margin-right-2 width-2 height-2"></span>
                        <div className="flex-column grid-col">
                          {returnActiveFromDates(by.end, by.start)}
                          <br />
                          <span className="text-italic font-sans-3xs">
                            {returnDateDifference(by.start, by.end)}
                          </span>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  )
}

Creators.defaultProps = {
  recordGroup: false,
}

Creators.propTypes = {
  /**
   * The entire data object for a single record
   */
  record: description,
}

export default Creators
