import { useEffect, useState } from 'react'
import { SEARCH_URL } from '../../settings/globals'
//Hooks
import useHttp from '../../../hooks/use-http'

const useNotesPopup = (justificationId) => {
  //State
  const [loadingNotes, setLoadingNotes] = useState(false)
  const [showingModerationNotePopup, setShowingModerationNotePopup] =
    useState(false)
  const [details, setDetails] = useState(null)

  /**
   * Request latest justifications data
   */
  const handleNotesResponse = (results) => {
    const justificationDetails = results?.filter(
      (justification) => justification.id === justificationId
    )
    if (justificationDetails?.length > 0) {
      setDetails(justificationDetails[0])
    }
    setTimeout(() => {
      setLoadingNotes(false)
    }, [500])
  }

  useEffect(() => {
    if (details || loadingNotes) return false
    const abortController = new AbortController()
    if (showingModerationNotePopup) {
      setLoadingNotes(true)
      requestNotesHandler(abortController)
    }
    return () => {
      setLoadingNotes(false)
      abortController.abort()
    }
  }, [showingModerationNotePopup])

  const { sendRequest: requestNotesHandler } = useHttp(
    {
      url: `${SEARCH_URL}/justifications?correspondingTable=contributions`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleNotesResponse
  )

  return {
    details,
    loadingNotes,
    setShowingModerationNotePopup,
    showingModerationNotePopup,
  }
}

export default useNotesPopup
