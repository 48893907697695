import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import UserContext from '../../../contexts/UserContext'
import { returnHumanDateFromTimestamp } from '../../../helper-functions/date-functions'
import { convertCamelToSentence } from '../../../helper-functions/string-functions'
import useHttp from '../../../hooks/use-http'
import useInput from '../../../hooks/use-input'
import useScreenSize from '../../../hooks/use-screen-size'
import { Button } from '../../Button/Button'
import CommentStatusEdited from '../../description/contributions/comments/comment_status_edited'
import StatusPill from '../../Pill/variants/StatusPill'
import {
  DEFAULT_CONTRIBUTION_TABLE_LIMIT,
  DEFAULT_PAGE,
  SEARCH_URL,
} from '../../settings/globals'
import Tooltip from '../../Tooltip/Tooltip'
import {
  ModerationJustificationPopup,
  ModerationNotePopup,
} from './ModeratorDashboard'
import useJustificationPopup from './use-justification-popup'
import DOMPurify from 'dompurify'
// Helpers
import {
  removeExtraWhiteSpace,
  validateInputFromSchema,
} from '../../form/Validate/Validate'
import usePrevious from '../../../hooks/use-previous'

const useModerateContributions = (contributionType) => {
  if (!contributionType) return false
  //Hooks
  const { screenSize } = useScreenSize()
  const [searchParams, setSearchParams] = useSearchParams()

  //State
  const [data, setData] = useState(null)
  const [total, setTotal] = useState(null)
  const [loadingData, setLoadingData] = useState(false)
  const [loadingSearchData, setLoadingSearchData] = useState(false)
  const [moderationStatus, setModerationStatus] = useState(null)
  const [page, setPage] = useState(1)
  // const [pages, setPages] = useState(1)
  const [submittedValue, setSubmittedValue] = useState('')
  const [table, setTable] = useState([])
  const [searchError, setSearchError] = useState(null)
  //Modification States
  const [body, setBody] = useState(null)
  const [modifying, setModifying] = useState(false)
  // True when the initial search results are loading
  const [loadingSearched, setLoadingSearched] = useState(false)
  const [resetData, setResetData] = useState(false)
  const [query, setQuery] = useState(null)
  const [toast, setToast] = useState(null)
  const [toastList, setToastList] = useState([])
  const type = usePrevious(contributionType)

  const searchErrorHandler = (error) => {
    setSearchError(error)
  }

  const handleSetPage = (num) => {
    if (!loadingData && !loadingSearchData) {
      setLoadingData(true)
      setPage(num)
    }
  }

  const {
    isValid: enteredSearchIsValid,
    value: enteredSearch,
    inputBlurHandler: blurHandler,
    reset: resetInput,
    valueChangeHandler: changeHandler,
  } = useInput(
    (value) => validateInputFromSchema({ type: 'text' }, value),
    searchErrorHandler
  )

  const sanitizedInput = removeExtraWhiteSpace(
    DOMPurify.sanitize(enteredSearch)
  )

  const submitHandler = (event) => {
    event.preventDefault()
    if (!loadingData || !loadingSearched) {
      setResetData(true)
      setLoadingSearched(true)
      setSubmittedValue(sanitizedInput)
      setPage(DEFAULT_PAGE)
    }
  }

  const [revertContributionId, setRevertContributionId] = useState('')
  const [revertJustificationId, setRevertJustificationId] = useState('')
  const [revertEnteredNotes, setRevertEnteredNotes] = useState('')

  const { loggedInUser } = useContext(UserContext) || {}

  useEffect(() => {
    setModerationStatus(searchParams.get('status') || null)
  }, [searchParams])

  useEffect(() => {
    const abortController = new AbortController()

    if (type && type !== contributionType) {
      // Used to distinguish if we're navigating between moderation pages
      // If so, clear any previous search value
      setPage(1)
      setSubmittedValue(null)
      resetInput()
    }
    setLoadingData(true)
    contributionRequestHandler(abortController)

    return () => {
      abortController.abort()
    }
  }, [contributionType, moderationStatus, submittedValue, page])

  const contributionResponseHandler = (results) => {
    console.log(results)
    if (moderationStatus) {
      results.filter((result) =>
        moderationStatus === 'madeByAdministrator'
          ? result.madeByAdministrator == 1
          : result.status === moderationStatus
      )
    }
    if (results?.[0]?.total) {
      setTotal(results[0].total)
    } else {
      setTotal(null)
    }
    setData(results)
    setLoadingData(false)
    setLoadingSearchData(false)
  }

  const pages = Math.ceil(data?.length / DEFAULT_CONTRIBUTION_TABLE_LIMIT)

  useEffect(() => {
    if (data) setTableHandler(data)
  }, [data])

  let moderatorQuery =
    contributionType === 'transcription'
      ? 'status=active'
      : 'status=active,inactive'
  switch (moderationStatus) {
    case 'flagged':
      moderatorQuery = 'flagged=1&status=active'
      break
    case 'reviewed':
      moderatorQuery = 'reviewed=1&status=active'
      break
    case 'unmoderated':
      moderatorQuery = 'reviewed=0&flagged=0&status=active'
      break
    case 'disabled':
      moderatorQuery = 'status=inactive'
      break
    case 'madeByAdministrator':
      moderatorQuery = 'madeByAdministrator=1'
      break
  }
  if (moderatorQuery) moderatorQuery += '&'

  const { sendRequest: contributionRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/contributions?${
        contributionType ? `contributionType=${contributionType}&` : ''
      }${moderatorQuery}${
        submittedValue
          ? contributionType === 'transcription'
            ? `targetNaId=${
                parseInt(submittedValue) ? submittedValue : `${submittedValue}`
              }&includeTotalCount=true&`
            : `q=${
                parseInt(submittedValue) ? submittedValue : `${submittedValue}`
              }&`
          : ''
      }isModeratorDashboardRequest=1&page=${page}&limit=${DEFAULT_CONTRIBUTION_TABLE_LIMIT}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    contributionResponseHandler
  )

  /**
   * Build the table rows from the response
   */
  const setTableHandler = (dataArray) => {
    if (!dataArray) return false
    let newRows = []
    dataArray.map((data) => {
      const status = getStatus(data)
      newRows.push({
        col1: {
          contribution: data.contribution,
          contributionId: data.contributionId,
          targetNaId: data.targetNaId,
          edited:
            data.contributionType === 'comment' &&
            data.updatedAt &&
            data.createdAt !== data.updatedAt,
        },
        col2: { status: status, userName: data.userName },
        col3: {
          status: status,
          timestamp: data.updatedAt || data.createdAt,
        },
        col4: { status: status },
        col5: { note: data.justificationId, status: status },
        col6: { status: status, contribution: data },
      })
    })
    setTable(newRows)
  }

  const columns = useMemo(
    () => [
      {
        Header: convertCamelToSentence(contributionType),
        accessor: 'col1',
        Cell: (cell) => {
          return (
            <Fragment>
              <Link
                className={[
                  'font-sans-xs',
                  'theme-white--link',
                  'word-break-word',
                ].join(' ')}
                to={{
                  pathname: `/id/${cell.value.targetNaId}`,
                  search: `?contributionId=${cell.value.contributionId}`,
                }}
              >
                <span className="display-block ellipsed-line-10">
                  {String(cell.value.contribution)}
                </span>
              </Link>{' '}
              {cell.value.edited ? <CommentStatusEdited /> : ''}
            </Fragment>
          )
        },
        id: 'contribution',
        className: 'width-full',
      },
      {
        Header: 'User',
        accessor: 'col2',
        Cell: (cell) => {
          return (
            <Link
              className={[
                'ellipsed-line-2',
                'font-sans-3xs',
                'theme-white--link',
                'width-full',
              ].join(' ')}
              to={{
                pathname: '/accounts/' + cell.value.userName,
              }}
            >
              {String(cell.value.userName)}
            </Link>
          )
        },
        id: 'user',
        className: 'grid-col-3',
      },
      {
        Header: 'Date Added',
        accessor: 'col3',
        id: 'date',
        Cell: (cell) => {
          return (
            <span className="font-sans-3xs">
              {
                returnHumanDateFromTimestamp(cell.value.timestamp).split(
                  'at'
                )[0]
              }
              <br />
              at{' '}
              {
                returnHumanDateFromTimestamp(cell.value.timestamp).split(
                  'at'
                )[1]
              }
            </span>
          )
        },
        className: 'width-12',
      },
      {
        Header: 'Status',
        accessor: 'col4',
        Cell: (cell) => {
          return <StatusPill status={cell.value.status} />
        },
        id: 'status',
        className:
          screenSize !== 'desktop' ? 'text-center width-8' : 'width-10', //vertical-align-top
      },
      {
        Header: 'Actions',
        accessor: 'col6',
        id: 'actions',
        Cell: (cell) => {
          return (
            <div
              className={[
                'display-flex',
                'flex-justify-end',
                // 'flex-gap-2xs',
                'flex-row',
              ].join(' ')}
            >
              {cell.value.status === 'flagged' ? (
                <ModerationJustificationPopup
                  action="unflag"
                  contribution={cell.value.contribution}
                  modifyHandler={modifyHandler}
                />
              ) : cell.value.status === 'reviewed' ? (
                <Tooltip className="flex-align-center" content="Remove Review">
                  <Button
                    className="flex-justify-center height-full"
                    iconName="thumb-up-off"
                    iconOnly
                    iconPercent={screenSize !== 'desktop' ? '100' : '80'}
                    iconSize={screenSize !== 'desktop' ? 'xs' : 'sm'}
                    onClick={() =>
                      modifyHandler('unreview', cell.value.contribution)
                    }
                    reduced
                    srText={`Remove the review from this ${contributionType}.`}
                    textOnly
                    theme="base-dark"
                    thin
                  />
                </Tooltip>
              ) : cell.value.status !== 'disabled' ? (
                <Tooltip className="flex-align-center" content="Review">
                  <Button
                    className="flex-justify-center height-full"
                    iconName="thumb-up"
                    iconOnly
                    iconPercent={screenSize !== 'desktop' ? '100' : '80'}
                    iconSize={screenSize !== 'desktop' ? 'xs' : 'sm'}
                    onClick={() =>
                      modifyHandler('review', cell.value.contribution)
                    }
                    reduced
                    srText={`Review this ${contributionType}.`}
                    textOnly
                    theme="base-dark"
                    thin
                  />
                </Tooltip>
              ) : (
                ''
              )}
              {cell.value.contribution.contributionType === 'transcription' ? (
                <ModerationJustificationPopup
                  action="revert"
                  contribution={cell.value.contribution}
                  disabled={
                    cell.value.contribution.contributionSequence === 1 ||
                    cell.value.contribution.contributionId ===
                      cell.value.contribution.parentContributionId
                  }
                  modifyHandler={revertHandler}
                />
              ) : cell.value.status === 'disabled' ? (
                <ModerationJustificationPopup
                  action="enable"
                  contribution={cell.value.contribution}
                  modifyHandler={modifyHandler}
                />
              ) : (
                <ModerationJustificationPopup
                  action="disable"
                  contribution={cell.value.contribution}
                  modifyHandler={modifyHandler}
                />
              )}
              <ModerationJustificationPopup
                action="flag"
                contribution={cell.value.contribution}
                disabled={
                  cell.value.contribution.status === 'disabled' ||
                  cell.value.contribution.flagged === 1
                }
                modifyHandler={modifyHandler}
              />
              {/* 
                TODO: Re-enable/delete following following the UI changes for admin tag deletion 
                This element adds an extra action next to all madeByAdministartor elements in the moderator dashboard
                This extra action uses justifications with type 'remove'
              */}
              {/* {cell.value.contribution.madeByAdministrator ? (
                <ModerationJustificationPopup
                  action="remove"
                  contribution={cell.value.contribution}
                  modifyHandler={modifyHandler}
                />
              ) : (
                ''
              )} */}
            </div>
          )
        },
        className: screenSize !== 'desktop' ? 'width-15' : 'width-card',
      },
      {
        Header: 'Notes',
        accessor: 'col5',
        Cell: (cell) => {
          return (
            <Fragment>
              {cell.value.note && (
                <ModerationNotePopup
                  justificationId={cell.value.note}
                  moderationType={cell.value.status}
                />
              )}
            </Fragment>
          )
        },
        id: 'notes',
        className: 'width-8',
      },
    ],
    [screenSize, table]
  )

  const modifyHandler = (
    action,
    contribution,
    justificationId,
    actionNotes
  ) => {
    if (!action || !contribution || !contribution.contributionId) {
      return false
    }

    let body,
      query,
      toast = { color: '', description: '', iconName: '' }
    switch (action) {
      case 'enable':
        if (!justificationId) justificationId = 7
        query = 'enable=true'
        toast.color = 'accent-cool'
        toast.description = (
          <Fragment>
            <strong>Enabled</strong>: {contribution.contribution}
          </Fragment>
        )
        toast.iconName = 'thumb-up'
        break
      case 'review':
        query = 'review=true'
        toast.color = 'accent-cool'
        toast.description = (
          <Fragment>
            <strong>Reviewed</strong>: {contribution.contribution}
          </Fragment>
        )
        toast.iconName = 'thumb-up'
        break
      case 'unflag':
        if (!justificationId) justificationId = 12
        query = 'flag=false'
        toast.color = 'accent-cool'
        toast.description = (
          <Fragment>
            <strong>Removed Flag</strong>: {contribution.contribution}
          </Fragment>
        )
        toast.iconName = 'flag-off'
        break
      case 'unreview':
        query = 'review=false'
        toast.color = 'warning'
        toast.description = (
          <Fragment>
            <strong>Removed Review</strong>: {contribution.contribution}
          </Fragment>
        )
        toast.iconName = 'thumb-up-off'
        break
      case 'flag':
        if (!justificationId) justificationId = 11
        query = 'flag=true'
        toast.color = 'error'
        toast.description = (
          <Fragment>
            <strong>Flagged</strong>: {contribution.contribution}
          </Fragment>
        )
        toast.iconName = 'flag'
        break
      case 'disable':
        if (!justificationId) justificationId = 4
        query = 'disable=true'
        toast.color = 'base'
        toast.description = (
          <Fragment>
            <strong>Disabled</strong>: {contribution.contribution}
          </Fragment>
        )
        toast.iconName = 'stop'
        break
      case 'revert':
        if (!justificationId) justificationId = 4
        toast.color = 'base'
        toast.description = (
          <Fragment>
            <strong>Reverted</strong>: version{' '}
            {contribution.contributionSequence}
          </Fragment>
        )
        toast.iconName = 'rotate-left-clock'
        break
    }

    if (justificationId) {
      body = {
        contributionId: contribution?.contributionId,
        justificationId: justificationId,
        userId: loggedInUser?.userId,
        // TODO: action notes
        actionNotes: actionNotes || '',
      }
    } else {
      body = {
        contributionId: contribution?.contributionId,
      }
    }
    setBody(body)
    setQuery(query)
    setToast(toast)
  }

  useEffect(() => {
    if (body && query) {
      setModifying(true)
      contributionModifyRequestHandler()
    }
  }, [body, query])

  useEffect(() => {
    if (revertContributionId && revertJustificationId) {
      revertRequest()
    }
  }, [revertContributionId, revertJustificationId])

  const revertHandler = (contributionId, justificationId, enteredNotes) => {
    setRevertEnteredNotes(enteredNotes)
    setRevertContributionId(contributionId)
    setRevertJustificationId(justificationId)
  }

  const { sendRequest: revertRequest } = useHttp({
    url: `${SEARCH_URL}/transcriptions/${revertContributionId}/justificationId/${revertJustificationId}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      userId: loggedInUser.userId,
      actionNotes: revertEnteredNotes,
    },
  })

  const contributionModifyResponseHandler = (response) => {
    const status = query.split('=')
    let newData = data.map((x) => {
      return { ...x }
    })

    if (moderationStatus) {
      setData(
        newData.filter((data) => {
          return data.contributionId !== response.contributionId
        })
      )
    } else {
      newData.map((data) => {
        if (data.contributionId === response.contributionId) {
          switch (status[0]) {
            case 'enable':
              data.status = 'active'
              data.reviewed = 1
              data.flagged = 0
              break
            case 'disable':
              data.status = 'inactive'
              data.reviewed = 1
              data.flagged = 0
              break
            case 'flag':
              data.flagged = status[1].toLowerCase() === 'true' ? 1 : 0
              break
            case 'review':
              data.reviewed = status[1].toLowerCase() === 'true' ? 1 : 0
              break
          }
          if (response.justificationId)
            data.justificationId = parseInt(response.justificationId)
        }

        return data
      })
      setData(newData)
    }

    if (toast) {
      if (toastList.find((l) => l.id === response.contributionId))
        setToastList(
          toastList.map((l) => {
            if (l.id === response.contributionId) {
              l.color = toast.color
              l.description = toast.description
              l.iconName = toast.iconName
            }
            return l
          })
        )
      else setToastList([...toastList, toast])
    }
    setModifying(false)
  }

  const { sendRequest: contributionModifyRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/contributions?${query}`,
      method: 'PUT',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    contributionModifyResponseHandler
  )

  /**
   * HELPERS
   */
  const getStatus = (data) => {
    let status = 'unmoderated'
    if (data.status === 'inactive') status = 'disabled'
    else if (data.flagged) status = 'flagged'
    else if (data.reviewed) status = 'reviewed'
    return status
  }

  return {
    body,
    columns,
    data,
    loadingData,
    loadingSearchData,
    modifyHandler,
    submitHandler,
    changeHandler,
    enteredSearch,
    query,
    table,
    toastList,
    handleSetPage,
    pages,
    page,
    total,
  }
}
export default useModerateContributions
