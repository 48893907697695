import { useEffect, useReducer, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { DEFAULT_LIMIT, DEFAULT_PAGE } from '../components/settings/globals'
//Reducers
import { formReducer } from '../reducers/form_reducer'
//Helpers
import { returnValidatedUrlParameters } from '../helper-functions/param-functions'

const useSearch = () => {
  const { search, pathname } = useLocation()
  const queryParams = queryString.parse(search)

  // Global state variables to be used by multiple components
  const [activeResults, setActiveResults] = useState(null)
  const [activeResultIndex, setActiveResultIndex] = useState(null)
  const [activeResultPage, setActiveResultPage] = useState(null)
  const [activeQueryParams, setActiveQueryParams] = useState(queryParams)
  const [activeFromPathname, setActiveFromPathname] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (
      pathname !== '/' &&
      pathname.indexOf('/advanced-search') == -1 &&
      pathname.indexOf('/id') == -1
    )
      handleActiveQueryParams()
  }, [search])

  const handleSearchChange = (event) => {
    event.preventDefault()
    setSearchQuery(event.target.value)
    setActiveQueryParams(queryParams)
  }
  const handleActiveQueryParams = (params) => {
    if (!params) params = queryParams
    //Validate each parameter
    //Remove problem parameters and log warnings
    //Return remaining params
    const { validatedParams } = returnValidatedUrlParameters(params)
    //Add missing parameters
    if (!validatedParams.page) validatedParams.page = DEFAULT_PAGE
    if (!validatedParams.limit) validatedParams.limit = DEFAULT_LIMIT
    //delete validatedParams.ancestorNaId
    //Apply remaining parameters
    setActiveQueryParams(validatedParams)
    if (searchQuery !== params.q) {
      setSearchQuery(params.q)
    }
  }

  return {
    activeFromPathname,
    setActiveFromPathname,
    activeQueryParams,
    setActiveQueryParams,
    activeResults,
    setActiveResults,
    activeResultIndex,
    setActiveResultIndex,
    activeResultPage,
    setActiveResultPage,
    handleSearchChange,
    handleActiveQueryParams,
    searchQuery,
    setSearchQuery,
    total,
    setTotal,
  }
}
export default useSearch
