import React, { Fragment, useContext, useState, setState } from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import ContributionContext from '../../../../contexts/ContributionContext'
import UserContext from '../../../../contexts/UserContext'
//Components
import { Button } from '../../../Button/Button'
//Helpers
import CommentInput from './comment_input'
//Hooks
import useHttp from '../../../../hooks/use-http'
//Settings
import { SEARCH_URL } from '../../../settings/globals'

const CommentActions = ({
  comment,
  setEditingHandler,
  setReplyingHandler,
  ...props
}) => {
  const contributionContext = useContext(ContributionContext)
  const { loggedInUser } = useContext(UserContext)

  const [commentDeleteError, setCommentDeleteError] = useState(null)

  const handleRemoveComment = (data) => {
    if (!data.contributionId) {
      setCommentDeleteError('Something went wrong. Please try again.')
      return false
    }

    contributionContext.commentsDispatch({
      type: 'REMOVE_COMMENT',
      contributionId: comment.contributionId,
      parentContributionId: comment.parentContributionId || null,
    })
  }

  const { sendRequest: removeCommentRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/contributions?remove=true`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        actionNotes: 'User deleted their own comment.',
        contributionId: comment.contributionId,
        userId: comment.userId,
      },
    },
    handleRemoveComment
  )

  if (loggedInUser?.userId) {
    return (
      <div
        className={[
          'flex-align-center',
          'flex-row',
          'flex-gap-sm',
          'grid-row',
          'margin-top-1',
          'text-left',
        ].join(' ')}
      >
        {comment.status == 'active' && (
          <Fragment>
            {/* Able to reply if this is a parent comment */}
            {!comment.parentContributionId && (
              <Button
                aria-label="Reply to this comment"
                data-testid={`nac-comments_${comment.contributionId}_reply`}
                onClick={() => setReplyingHandler(true)}
                outline
                reduced
                size="3xs"
                thin
              >
                Reply
              </Button>
            )}
            {/* Able to edit and delete if you are the owner */}
            {comment.userId == loggedInUser?.userId && (
              <Fragment>
                <Button
                  aria-label="Edit this comment"
                  data-testid={`nac-comments_${comment.contributionId}_edit`}
                  iconName="edit"
                  iconSize="2xs"
                  // onClick={() => setEditing(true)}
                  onClick={() => setEditingHandler(true)}
                  reduced
                  size="3xs"
                  textOnly
                  theme="base"
                  thin
                >
                  Edit
                </Button>
                <Button
                  aria-label="Remove this comment permanently"
                  iconName="trash"
                  iconSize="2xs"
                  onClick={() =>
                    confirm(
                      'Are you sure you want to delete this comment?\n\n"' +
                        comment.contribution +
                        '"'
                    )
                      ? removeCommentRequestHandler()
                      : ''
                  }
                  reduced
                  size="3xs"
                  textOnly
                  theme="error-dark"
                  thin
                >
                  Remove
                </Button>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    )
  } else {
    return ''
  }
}

CommentActions.defaultProps = {
  comment: {},
}

CommentActions.propTypes = {
  comment: PropTypes.object.isRequired,
  setEditingHandler: PropTypes.func,
  setReplyingHandler: PropTypes.func,
}

export default CommentActions
