import React, { forwardRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import '../../styles/index.scss'
import { IconPropTypes } from '../settings/IconPropTypes'
import { defaultSizes } from '../settings/SizePropTypes'
import { IconLabel } from '../IconLabel/IconLabel'
import { ColorPropTypes } from '../settings/ColorPropTypes'

/**
 * Pills should be used for scenarios where multiple interactions need to take place (i.e. tabbing to and selected multiple child elements), but does not require an action.
 */

export const Pill = forwardRef(
  (
    {
      children,
      className,
      filled,
      iconName,
      iconPosition,
      iconSize,
      radius,
      reduced,
      size,
      theme,
      thin,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={[
          `usa-pill--${theme}`,
          //
          `${
            filled
              ? [`bg-${theme}`, `theme-${theme}--text`].join(' ')
              : [`border-${theme}`, thin ? 'border-1px' : 'border-2px'].join(
                  ' '
                )
          }`,
          'display-inline-flex',
          'flex-align-center',
          `radius-${radius}`,
          `theme-${theme}`,
          `usa-pill--${theme}`,
          `${
            reduced
              ? size === '2xs' || size === '3xs' || size === '4xs'
                ? 'padding-2px'
                : 'padding-05'
              : 'padding-1'
          }`,
          `${size ? `font-sans-${size}` : ''}`,
          className,
        ].join(' ')}
        {...props}
        ref={ref}
      >
        {iconName ? (
          <IconLabel
            align="center"
            color={filled ? null : theme}
            iconName={iconName}
            iconPosition={iconPosition}
            iconSize={iconSize ? iconSize : size}
            size={size}
            theme={filled ? theme : null}
          >
            <span
              className={[
                !filled ? `text-${theme}` : '',
                thin ? 'text-normal' : 'text-bold',
              ].join(' ')}
            >
              {children}
            </span>
          </IconLabel>
        ) : children ? (
          <span
            className={[
              !filled && theme ? `text-${theme}` : '',
              thin ? 'text-normal' : 'text-bold',
            ].join(' ')}
          >
            {children}
          </span>
        ) : (
          ''
        )}
      </div>
    )
  }
)

Pill.defaultProps = {
  filled: false,
  iconName: null,
  iconPosition: 'left',
  radius: 'pill',
  size: 'sm',
  theme: 'primary',
  thin: false,
}

Pill.propTypes = {
  /**
   * Additional classes provided to the component for use on the wrapper element
   */
  className: PropTypes.string,
  /**
   * Whether the pill contains a fill. If false, an outline is displayed instead
   */
  filled: PropTypes.bool,
  /**
   * Defines the color of the button. This is based on a subset of the USWDS color utilities (https://designsystem.digital.gov/utilities/color/)
   */
  theme: PropTypes.oneOf(ColorPropTypes),
  /**
   * Defines the size of the button. This is based on a subset of the USWDS size indicators.
   */
  size: PropTypes.oneOf(defaultSizes),
  /**
   * Defines the size of the icon. This is based on a subset of the USWDS size indicators.
   */
  iconSize: PropTypes.oneOf(defaultSizes),
  /**
   * Defines the icon to use.
   */
  iconName: IconPropTypes,
  /**
   * Defines the position of the icon in the button.
   */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /**
   * The button text.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Defines the shape of the pill via border radius
   */
  radius: PropTypes.oneOf(['pill', 'md']),
  /**
   * Determines if the button has less padding
   */
  reduced: PropTypes.bool,
  thin: PropTypes.bool,
}

Pill.displayName = 'Pill'
