import PropTypes from 'prop-types'

export const onlineAvailability = ['fullyDigitized', 'unrestrictedOnly', '']

export const generalRecordsTypes = [
  'Architectural and Engineering Drawings',
  'Artifacts',
  'Data Files',
  'Maps and Charts',
  'Moving Images',
  'Photographs and other Graphic Materials',
  'Sound Recordings',
  'Textual Records',
  'Web Pages',
]

export const authorityType = [
  //ADD ALL
  'geographicPlaceName',
  'organization',
  'organizationName',
  'person',
  'specificRecordsType',
  'topicalSubject',
]

export const levelOfDescription = [
  'collection',
  'fileUnit',
  'item',
  'recordGroup',
  'series',
]

export const recordType = ['description', 'authority']

export let description = PropTypes.shape({
  accessRestriction: PropTypes.shape({
    status: PropTypes.string,
  }),
  ancestors: PropTypes.arrayOf(
    PropTypes.shape({
      creators: PropTypes.arrayOf(
        PropTypes.shape({
          authorityType: PropTypes.string,
          creatorType: PropTypes.string,
          establishDate: PropTypes.shape({
            day: PropTypes.number,
            logicalDate: PropTypes.string,
            month: PropTypes.number,
            year: PropTypes.number,
          }),
          heading: PropTypes.string,
          naId: PropTypes.number,
        })
      ),
      distance: PropTypes.number,
      levelOfDescription: PropTypes.string,
      inclusiveEndDate: PropTypes.shape({
        day: PropTypes.number,
        logicalDate: PropTypes.string,
        month: PropTypes.number,
        year: PropTypes.number,
      }),
      inclusiveStartDate: PropTypes.shape({
        day: PropTypes.number,
        logicalDate: PropTypes.string,
        month: PropTypes.number,
        year: PropTypes.number,
      }),
      naId: PropTypes.number,
      recordGroupNumber: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  audiovisual: PropTypes.string,
  dataControlGroup: PropTypes.shape({
    groupCd: PropTypes.string,
    groupId: PropTypes.string,
    groupName: PropTypes.string,
  }),
  digitalObjects: PropTypes.arrayOf(
    PropTypes.shape({
      extractedTextScoreUrl: PropTypes.string,
      extractedTextUrl: PropTypes.string,
      objectFileSize: PropTypes.number,
      objectFilename: PropTypes.string,
      objectId: PropTypes.string,
      objectType: PropTypes.string,
      objectUrl: PropTypes.string,
      technicalMetadataUrl: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    })
  ),
  generalRecordsTypes: PropTypes.arrayOf(PropTypes.oneOf(generalRecordsTypes)),
  levelOfDescription: PropTypes.oneOf(levelOfDescription),
  microformPublications: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      note: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  naId: PropTypes.number,
  otherTitles: PropTypes.arrayOf(PropTypes.string),
  physicalOccurrences: PropTypes.arrayOf(
    PropTypes.shape({
      copyStatus: PropTypes.string,
      mediaOccurrences: PropTypes.arrayOf(
        PropTypes.shape({
          generalMediaTypes: PropTypes.arrayOf(PropTypes.string),
          physicalRestrictionNote: PropTypes.string,
          specificMediaType: PropTypes.string,
        })
      ),
      referenceUnits: PropTypes.arrayOf(
        PropTypes.shape({
          address1: PropTypes.string,
          address2: PropTypes.string,
          city: PropTypes.string,
          email: PropTypes.string,
          fax: PropTypes.string,
          mailCode: PropTypes.string,
          name: PropTypes.string,
          phone: PropTypes.string,
          postalCode: PropTypes.string,
          state: PropTypes.string,
        })
      ),
    })
  ),
  productionDates: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.number,
      logicalDate: PropTypes.string,
      month: PropTypes.number,
      year: PropTypes.number,
    })
  ),
  //ADD ALL
  recordType: PropTypes.oneOf(recordType),
  subjects: PropTypes.arrayOf(
    PropTypes.shape({
      authorityType: PropTypes.oneOf(authorityType),
      heading: PropTypes.string,
      naId: PropTypes.number,
    })
  ),
  title: PropTypes.string,
  useRestriction: PropTypes.shape({
    status: PropTypes.string,
  }),
  variantControlNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
      number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      type: PropTypes.string,
    })
  ),
})

export let descriptionResults = PropTypes.arrayOf(description)
