/**
 * Checks if value evaluates as a number
 * @param   {String || Number} number Number without commas
 * @returns {String}                  Number with commas
 */
export const returnNumberWithCommas = (number) => {
  //Strip all existing commas and spaces from strings
  if (typeof number == 'string')
    number = number.replace(/,/g, '').replace(/\s/g, '')
  //Convert numeric values to strings
  if (typeof number == 'number') number = number.toString()
  if (!number) return false
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Checks if value evaluates as a number
 * @param   {String || Number || Boolean} value Value to be tested. If not of type number, a string is evalulated.
 * @returns {Bool}                           Returns 'true' if value can be evaluated as a number
 *                                           Returns 'false' if value is a bool, string, or something that cannot be evaluated as a number.
 */
export const isNumericValue = (value) => {
  if (typeof value == 'number') return true
  if (typeof value !== 'string') return false
  if (checkBool(value)) return false
  else if (checkNumber(value)) return true
}

/**
 * Checks if a string value is not a boolean or a number
 * @param   {String} value Value to be tested.
 * @returns {Bool}         Returns 'true' if value cannot be evaluated as a number or boolean.
 *                         Returns 'false' if value is a bool or number.
 */
export const isStringValue = (value) => {
  if (typeof value !== 'string') return false
  else if (checkBool(value)) return false
  // else if (checkNumber(value)) return false
  else return true
}

/**
 * Checks if a value is a boolean
 * @param   {String || Boolean} value Value to be tested.
 * @returns {Bool}                    Returns 'true' if value can be evaluated as a boolean.
 *                                    Returns 'false' if value is a number or other string.
 */
export const isBoolValue = (value) => {
  if (typeof value == 'boolean') return true
  else if (checkBool(value)) return true
  else if (checkNumber(value)) return true
  else return false
}

/**
 * Checks if a string value is a number
 * @param   {String} value Value to be tested.
 * @returns {Bool}         Returns 'true' if value is a number.
 *                         Returns 'false' if value is not a number.
 */
export const checkNumber = (value) => {
  if (typeof value !== 'string') return false
  if (!isNaN(value) && !isNaN(parseFloat(value))) return true
}

/**
 * Checks if a string value is a booleans
 * @param   {String} value Value to be tested.
 * @returns {Bool}         Returns 'true' if value is a boolean.
 *                         Returns 'false' if value is not a boolean.
 */
export const checkBool = (value) => {
  if (typeof value !== 'string') return false
  if (value.toLowerCase() == 'true' || value.toLowerCase() == 'false')
    return true
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export const returnRandomInteger = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * Consistently converts a string into an integer
 * @param {String} str
 * @returns
 */
export const returnIntegerFromString = (str, array) => {
  if (!array) array = [1, 2, 3]
  var hash = returnHashFromString(str)
  var index = hash % array.length
  return array[index]
}

/**
 * Creates a simple hash based on a provided string
 * @param {String} str
 * @returns
 */
export const returnHashFromString = (str) => {
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    var charCode = str.charCodeAt(i)
    hash += charCode
  }
  return hash
}

/**
 *
 * @param {String} str
 * @returns
export const returnIntegerFromString = (str) => {
  let hash = 0
  if (str.length === 0) return hash
  let chr
  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
}
 */
