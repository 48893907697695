import React, { useContext } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import SearchContext from '../../contexts/SearchContext'
//Components
import DropdownField from './DropdownField'

const CatalogSort = ({ active, handleUpdateValue, ...props }) => {
  const searchContext = useContext(SearchContext)
  const handleOnChange = (event) => {
    let value = event.target.value
    handleUpdateValue(value)
  }

  return (
    <DropdownField
      onChange={handleOnChange}
      value={active}
      label="Sort by:"
      //icon="sort"
      id="sort-results"
      data-testid="nac-results--sort"
      {...props}
    >
      <option data-testid="nac-results--sort-relevant" value="relevant">
        Most Relevant
      </option>
      <option data-testid="nac-results--sort-title-asc" value="title:asc">
        Title (Alphabetically, A–Z)
      </option>
      <option data-testid="nac-results--sort-title-desc" value="title:desc">
        Title (Alphabetically, Z–A)
      </option>
      <option data-testid="nac-results--sort-naId-asc" value="naId:asc">
        NARA ID (Lowest First)
      </option>
      <option data-testid="nac-results--sort-naId-desc" value="naId:desc">
        NARA ID (Highest First)
      </option>
    </DropdownField>
  )
}
CatalogSort.defaultProps = {
  handleUpdateValue: () => {},
  active: 'relevant',
}

CatalogSort.propTypes = {
  handleUpdateValue: () => {},
  active: PropTypes.string.isRequired,
}

export default CatalogSort
