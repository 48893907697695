import React, { useContext, useEffect, useState } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import Prose from '../utilities/Prose'
import { Icon } from '../Icon/Icon'
import SearchContext from '../../contexts/SearchContext'
import Highlighter from '../Highlighter/Highlighter'

/**
 * Use Restriction alert users if the use of the materials is restricted.
 *
 * Use Restriction Note: https://www.archives.gov/research/catalog/lcdrg/elements/usenote.html
 * Specific Use Restriction: https://www.archives.gov/research/catalog/lcdrg/elements/specificuse.html
 * Use Restriction Status: https://www.archives.gov/research/catalog/lcdrg/elements/use.html
 *
 * NOTE: According to stakeholders, this data is problematic as we
 * cannot say with confidence that it is accurate for any given record.
 */

const SectionUseRestriction = ({ data, ...props }) => {
  const { record } = useContext(RecordContext)
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  // Prevent the original response from being mutated
  const [useRestriction, setUseRestriction] = useState(null)

  // Mutate the array to include details from the useRestrictionAuthorityList array
  useEffect(() => {
    if (!data && (!record || !record.useRestriction)) return false
    let t = null,
      d = null,
      i = null,
      restriction = data || record.useRestriction
    useRestrictionAuthorityList.some(({ status, description, icon, title }) => {
      if (restriction.status === status) {
        if (description) {
          d = restriction.specificUseRestrictions
            ? `${
                restriction.specificUseRestrictions.length > 1
                  ? `${description} for the following reasons:`
                  : `${description} for the following reason:`
              }`
            : `${description}.`
        }
        t = title
        i = icon
      }
    })
    restriction.title = t
    restriction.description = d
    restriction.icon = i
    setUseRestriction(restriction)
  }, [record])

  return useRestriction ? (
    <div
      className={['display-flex', 'flex-column', 'flex-gap-xs'].join(' ')}
      {...props}
    >
      <div
        className={[
          'display-flex',
          'flex-row',
          'flex-gap-sm',
          'width-full',
        ].join(' ')}
      >
        <Icon
          color="base-darker"
          iconName={useRestriction.icon}
          iconPercent="80"
          iconSize="sm"
          strokeThickness="thick"
        />
        <div
          className={[
            'display-flex',
            'flex-column',
            'flex-gap-sm',
            'padding-top-2px',
          ].join(' ')}
        >
          <h2 className={['text-base-darker', 'margin-bottom-0'].join(' ')}>
            Use: {useRestriction.title}
            <span className="usa-sr-only">, </span>
          </h2>
        </div>
      </div>
      <div className="margin-left-4">
        {useRestriction.description && (
          <Prose
            className="margin-bottom-1"
            data-testid="nac-description__use--description"
          >
            {useRestriction.description}
          </Prose>
        )}
        {useRestriction.specificUseRestrictions && (
          <ul className={['margin-left-2'].join(' ')}>
            {useRestriction.specificUseRestrictions.map((restriction) => (
              <li
                key={restriction}
                className={[
                  'nac-list-icon',
                  'nac-list-icon--base-darker',
                  'nac-list-icon--asterisk',
                  //
                  'font-sans-xs',
                  'margin-bottom-1',
                ].join(' ')}
              >
                <Prose data-testid="nac-description__use--specific">
                  <Highlighter search={q}>{restriction}</Highlighter>
                </Prose>
                <span className="usa-sr-only">, </span>
              </li>
            ))}
          </ul>
        )}
        {useRestriction.note && (
          <Prose data-testid="nac-description__use--note">
            <Highlighter search={q}>{useRestriction.note}</Highlighter>
          </Prose>
        )}
      </div>
    </div>
  ) : (
    ''
  )
}

SectionUseRestriction.propTypes = {
  /**
   * Use Restriction data object which can be optionally provided
   * for display outside of the regular description page flow,
   * such as on a help page.
   *
   * NOTE: This array structure matches the examples provided in the
   * useRestrictionAuthorityList array
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      useRestriction: PropTypes.shape({
        note: PropTypes.string,
        // https://www.archives.gov/research/catalog/lcdrg/authority_lists/specificuselist.html
        specificAccessRestrictions: PropTypes.oneOf([
          'Copyright',
          'Donor Restrictions',
          'Public Law 101-246',
          'Service Mark',
          'Trademark',
          'Other',
        ]),
        // https://www.archives.gov/research/catalog/lcdrg/authority_lists/uselist.html
        status: PropTypes.oneOf([
          'Restricted - Fully',
          'Restricted - Partly',
          'Restricted - Possibly',
          'Undetermined',
          'Unrestricted',
        ]),
      }),
    })
  ),
}

export default SectionUseRestriction

/**
 * Use Restriction global details for contextual help and display
 *
 * NOTE: The status (which comes from DAS) is different from
 * the display title.
 *
 * NOTE: The description is informed by the documentation on Archives.gov
 * with some modifications
 */
export const useRestrictionAuthorityList = [
  //Restricted - Fully
  {
    description: 'Use of all of these archival materials is restricted',
    example: {
      naId: 20015869,
      useRestriction: {
        note: 'This item has Donor Restrictions.',
        specificUseRestrictions: ['Donor Restrictions'],
        status: 'Restricted - Fully',
      },
    },
    icon: 'locked',
    status: 'Restricted - Fully',
    title: 'Fully Restricted',
  },
  //Restricted - Partly
  {
    description: 'Use of some of these archival materials is restricted',
    example: {
      naId: 24496719,
      useRestriction: {
        note: 'Some or all of the records may be subject to copyright restrictions.  Researchers should contact the copyright holder for further information.',
        specificUseRestrictions: ['Copyright'],
        status: 'Restricted - Partly',
      },
    },
    icon: 'unlocked-asterisk',
    status: 'Restricted - Partly',
    title: 'Partially Restricted',
  },
  //Restricted - Possibly
  {
    description: 'Use of these archival materials may be restricted',
    example: {
      naId: 23812513,
      useRestriction: {
        note: 'Some or all of the records may be subject to copyright restrictions. Researchers should contact the publisher for further information.',
        specificUseRestrictions: ['Copyright'],
        status: 'Restricted - Possibly',
      },
    },
    icon: 'unlocked-question',
    status: 'Restricted - Possibly',
    title: 'Possibly Restricted',
  },
  //Undetermined
  {
    // description: 'Use of these archival materials is unclear at this time',
    // description: 'It is unknown if the archival materials are restricted',
    example: {
      naId: 23812743,
      useRestriction: {
        note: 'This series has not been completely processed therefore use and reproduction restrictions have not been determined.',
        status: 'Undetermined',
      },
    },
    icon: 'unlocked-question',
    status: 'Undetermined',
    title: 'Undetermined',
  },
  //Unrestricted
  {
    example: {
      naId: 24496991,
      useRestriction: {
        status: 'Unrestricted',
      },
    },
    icon: 'unlocked',
    status: 'Unrestricted',
    title: 'Unrestricted',
  },
]

/**
 * Specific Use Restriction descriptions for use with contextual help display
 *
 * NOTE: The description is informed by the documentation on Archives.gov
 * with some modifications
 * https://www.archives.gov/research/catalog/lcdrg/authority_lists/specificuselist.html
 */
export const specificUseRestrictionAuthorityList = [
  {
    label: 'Copyright',
    description:
      'Indicates these archival materials have a copyright restriction regarding their use.',
  },
  {
    label: 'Donor Restrictions',
    description:
      'Indicates these archival materials have a donor restriction regarding their use.',
  },
  {
    label: 'Public Law 101-246',
    description:
      'Issued February 16, 1990, this law provides for the domestic release and distribution of U.S. Information Agency motion pictures, films, videotapes, and other materials 12 years after initial dissemination overseas, or, if not disseminated, 12 years from the preparation of the material.',
  },
  {
    label: 'Service Mark',
    description:
      'Indicates these archival materials have a service mark restriction regarding their use.',
  },
  {
    label: 'Trademark',
    description:
      'Indicates these archival materials have a trademark restriction regarding their use. ',
  },
  {
    label: 'Other',
    description:
      'Indicates these archival materials include specific use restriction(s) as explained in the note provided.',
  },
]
