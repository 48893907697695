import React, { useEffect, useMemo, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import '../../../styles/index.scss'
//Components
import StatusPill from '../../Pill/variants/StatusPill'
//Helpers
import { returnDateAndTimeObjFromTimestamp } from '../../../helper-functions/date-functions'
import {
  removeExtraWhiteSpace,
  validateInputFromSchema,
} from '../../form/Validate/Validate'
//Hooks
import useHttp from '../../../hooks/use-http'
import useInput from '../../../hooks/use-input'
//Settings
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  IIIF_PATH_THUMB,
  MAX_AVAILABILITY_RESULT_LIMIT,
  SEARCH_URL,
} from '../../settings/globals'
import useUrlParams from '../../../hooks/use-url-params'
import { compareNumbers } from '../../../helper-functions/sort-functions'
import { BackgroundImageTableButton } from './BackgroundImages'
import { convertImageUrlToIiif } from '../../../helper-functions/string-functions'

const useBackgroundImages = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { removeParams, updateParams } = useUrlParams()
  const sort = searchParams.get('sort')
  const naIds = searchParams.get('naId')

  const [data, setData] = useState([])
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [table, setTable] = useState([])
  const [total, setTotal] = useState(null)

  const [page, setPage] = useState(
    parseInt(searchParams.get('page')) || DEFAULT_PAGE
  )
  const [limit, setLimit] = useState(DEFAULT_LIMIT)

  useEffect(() => {
    setTotal(null)
    setLoading(true)
  }, [])

  useEffect(() => {
    if (loading) {
      heroImageRequestHandler()
    }
  }, [loading])

  const heroImageResponseHandler = (results) => {
    setData(results)
    setTotal(results[0].total)

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  let queryParamsArray = []
  if (limit) queryParamsArray.push(`limit=${limit}`)
  if (sort) queryParamsArray.push(`sort=${sort}`)
  if (page) queryParamsArray.push(`page=${page}`)
  let queryParams = ''
  if (queryParamsArray?.length > 0)
    queryParams = `?${queryParamsArray?.join('&')}`

  const { sendRequest: heroImageRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/image-carousel/all${queryParams}`,
    },
    heroImageResponseHandler
  )

  useEffect(() => {
    let newRows = []
    data?.map(
      ({ createdAt, id, naId, objectId, objectUrl, status, title }, index) => {
        let schema = heroImageSchema.filter(({ id }) => id === status)[0]
        newRows.push({
          image: (
            <img src={convertImageUrlToIiif(objectUrl) + IIIF_PATH_THUMB} />
          ),
          actions: { objectId, status },
          status: (
            <StatusPill
              // icon
              label={schema?.label}
              status={schema?.type}
            />
          ),
          date: (
            <div className={['display-flex', 'flex-column'].join(' ')}>
              <span>{returnDateAndTimeObjFromTimestamp(createdAt).date}</span>
              <span>{returnDateAndTimeObjFromTimestamp(createdAt).time}</span>
            </div>
          ),
          naId: naId,
          objectId: objectId,
          title: (
            <Link
              className="ellipsed-line-2"
              to={`/id/${naId}?objectId=${objectId}`}
            >
              {title}
            </Link>
          ),
        })
      }
    )
    {
      /* <div
              className={['display-flex', 'flex-column', 'theme-white'].join(
                ' '
              )}
            >
              <div className="font-sans-2xs">
                <ListSeparator distance="2px">
                  <span>NAID: {naId}</span>
                  <span>Object ID: {objectId}</span>
                </ListSeparator>
              </div>*/
    }
    setTable(newRows)
  }, [data, loading])

  const columns = useMemo(
    () => [
      {
        Header: 'Image',
        accessor: 'image',
        id: 'image',
        className: 'grid-col-2',
      },
      {
        Header: 'NAID',
        accessor: 'naId',
        id: 'naId',
        className: 'width-10',
      },
      {
        Header: 'Object ID',
        accessor: 'objectId',
        id: 'objectId',
        className: 'width-10',
      },
      {
        Header: 'Title',
        accessor: 'title',
        id: 'title',
        className: 'grid-col-4',
      },
      {
        Header: 'Added',
        accessor: 'date',
        id: 'date',
        className: 'width-10',
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        className: 'width-8',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        id: 'actions',
        Cell: (cell) => {
          return (
            <BackgroundImageTableButton
              objectId={String(cell.value.objectId)}
              status={cell.value.status}
              onUpdate={() => setLoading(true)}
            />
          )
        },
        className: 'width-10',
      },
    ],
    [data]
  )

  const pages = Math.ceil(total / limit)

  const setPageHandler = (newPage) => {
    if (!loading) {
      setPage(newPage)
      setLoading(true)
    }
  }

  useEffect(() => {
    if (page > 1) updateParams({ page: page })
    else removeParams(['page'])
  }, [page])

  return {
    columns,
    data,
    errors,
    loading,
    page,
    pages,
    setPageHandler,
    table,
    total,
  }
}

export default useBackgroundImages

export const TableCellLoading = () => {
  return (
    <div className={['block-load', 'height-2', 'width-full'].join(' ')}></div>
  )
}

export const NotAvailableText = ({ reduced, ...props }) => {
  return (
    <span className="font-sans-2xs text-base-dark text-italic">
      {reduced ? 'N/A' : 'Not Available'}
    </span>
  )
}
NotAvailableText.propTypes = {
  reduced: PropTypes.bool,
}

export const heroImageSchema = [
  {
    description: 'This image is currently in rotation on the home page.',
    id: 'active',
    label: 'Active',
    type: 'success',
  },
  {
    description: 'This image is currently out of rotation on the home page.',
    id: 'inactive',
    label: 'Inactive',
    type: 'inactive',
  },
]
