import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../../Tooltip/Tooltip'
import { Button } from '../../Button/Button'
import {
  DEFAULT_COPY_LINK_TEXT,
  DEFAULT_COPIED_LINK_TEXT,
} from '../../settings/globals'
import useHovered from '../../../hooks/use-hovered'

const CopyUrl = ({ children, id, hide, search, ...props }) => {
  if (!children) return false

  const mouseMoveRef = useRef()
  const buttonRef = useRef()

  const [tooltipText, setTooltipText] = useState(DEFAULT_COPY_LINK_TEXT)
  const { active } = useHovered(mouseMoveRef, buttonRef)

  useEffect(() => {
    if (!active) setTooltipText(DEFAULT_COPY_LINK_TEXT)
  }, [active])

  const handleCopy = async () => {
    const url = location.host + location.pathname
    const text = `${url}${search ? `?${search}` : ''}${id ? `#${id}` : ''}`
    setTooltipText(DEFAULT_COPIED_LINK_TEXT)
    await navigator.clipboard.writeText(text)
  }

  const CopyUrlWrapper = `${children.type}`

  return (
    <CopyUrlWrapper
      id={id}
      className={[
        'copy-to-clipboard',
        'display-inline-flex',
        'flex-align-center',
        'flex-gap-sm',
        'flex-row',
      ].join(' ')}
      onClick={handleCopy}
      ref={mouseMoveRef}
      {...props}
    >
      {children.props.children}{' '}
      <span
        role="presentation"
        className={[
          active ? 'active' : '', // `${!hide || (hide && active) ? 'active' : ''}`,
          'anim-duration-500ms',
          'anim-opacity',
          'copy-to-clipboard--btn',
          'display-inline-flex',
          'margin-left-1',
          // active ? 'opacity-0' : '',
        ].join(' ')}
      >
        {
          // NOTE: This anchor link icon does not need to meet 508 Success Criterion 2.5.5
          // concerning Target Size, as detailed here:
          // Example 3: Anchor Link
          // The target is an in-page link and the target is less than 44 by 44 CSS pixels.
          // Users can scroll the screen using browser functions so target size does not need to be met.
          // (https://www.w3.org/WAI/WCAG21/Understanding/target-size.html)
        }
        <Tooltip content={tooltipText} position="rightCenter">
          <Button
            iconName="link"
            iconOnly
            iconPercent="80"
            iconSize="xs"
            outline
            reduced
            ref={buttonRef}
            shape="pill"
            srText="Copy shareable link"
            theme="primary"
          />
        </Tooltip>
      </span>
    </CopyUrlWrapper>
  )
}

CopyUrl.defaultProps = {
  children: '',
  id: null,
  hide: true,
}

CopyUrl.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.string,
  hide: PropTypes.bool,
  search: PropTypes.string,
}

export default CopyUrl
