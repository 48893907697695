import React, {
  forwardRef,
  useContext,
  useEffect,
  useState,
  useRef,
  Fragment,
  useImperativeHandle,
} from 'react'
import '../../styles/index.scss'
//PropTypes
import PropTypes from 'prop-types'
import { inputSchemaPropTypes } from '../../schemas/input-schemas/input-schema-prop-types'
//Context
// import SearchContext from '../../contexts/SearchContext'
import { Icon } from '../Icon/Icon'
import TextInput from './text_input'
import { ListSeparator } from '../ListSeparator/ListSeparator'
import useInput from '../../hooks/use-input'
import {
  validateInputFromSchema,
  ValidationError,
} from '../form/Validate/Validate'
import FormContext from '../../contexts/FormContext'
import Select from './select'
import FormField from './FormField'
import { dayOptions } from '../../schemas/input-schemas/input-schema-dates'
import { Button } from '../Button/Button'
import Tooltip from '../Tooltip/Tooltip'
import InputMessage from './input_message'

const DateInputField = forwardRef(({ schema, ...props }, ref) => {
  const { formDispatch, formFields, formParams, setTouched, touched } =
    useContext(FormContext) || {}
  const [inputError, setInputError] = useState(null) //State
  const [days, setDays] = useState(
    schema.id === 'recurringDate' ? schema.options[1] : schema.options[2]
  )
  const isUserInitiatedUpdate = useRef(false)

  const years = schema.options[0]
  const months =
    schema.id === 'recurringDate' ? schema.options[0] : schema.options[1]
  const [selected, setSelected] = useState({
    year: years.options?.filter((o) => o.isPlaceholder) || [years.options?.[0]],
    month: months?.options?.filter((o) => o.isPlaceholder) || [
      months?.options?.[0],
    ],
    day: days?.options?.filter((o) => o.isPlaceholder) || [days?.options?.[0]],
  })
  const [value, setValue] = useState(null)

  const selectHandler = (event, id, schema) => {
    setTouched(true)
    let newOption = null
    schema.options.map((o) => {
      if (id === o.id) newOption = [o]
    })
    setSelected({ ...selected, [schema.id]: newOption })
  }

  const storedValue = formFields?.[schema.id] ? formFields[schema.id] : ''

  const inputErrorHandler = (error) => {
    setInputError(
      typeof error === 'string' ? new ValidationError(error) : error
    )
  }

  const {
    inputBlurHandler: blurHandler,
    valueChangeHandler: changeHandler,
    reset: resetHandler,
  } = useInput(
    (value) => validateInputFromSchema(schema, value),
    inputErrorHandler,
    storedValue
  )

  const reset = () => {
    resetHandler()
    formDispatch({
      type: 'REMOVE_FORM_FIELDS',
      id: schema.id,
    })
    sessionStorage.removeItem('day')
    sessionStorage.removeItem('daySelected')
    setSelected({
      year: years.options?.filter((o) => o.isPlaceholder) || [
        years.options?.[0],
      ],
      month: months?.options?.filter((o) => o.isPlaceholder) || [
        months?.options?.[0],
      ],
      day: days?.options?.filter((o) => o.isPlaceholder) || [
        days?.options?.[0],
      ],
    })
  }
  useEffect(() => {
    // Is a leap year selected
    var isLeapyear =
      (parseInt(selected['year']?.[0]?.value) % 4 === 0 &&
        parseInt(selected['year']?.[0]?.value) % 100 !== 0) ||
      parseInt(selected['year']?.[0]?.value) % 400 === 0
    if (!selected['month']?.[0]?.days) return false

    const newOptions = dayOptions.map((x) => {
      return { ...x }
    }) // Prevent mutation of original state.filter(

    const schemaArrayIndex = schema.id === 'recurringDate' ? 1 : 2
    schema.options[schemaArrayIndex].options = newOptions.filter(
      (o) =>
        o.isPlaceholder === true ||
        parseInt(o.order) <= selected['month'][0].days
    )
    // Prevents having dates outside of month's selection of days
    if (
      selected['month']?.[0]?.days < parseInt(selected['day']?.[0]?.value) &&
      selected['month']?.[0].value !== '02'
    ) {
      setSelected({
        ...selected,
        day: days.options?.filter(
          (o) => parseInt(o.value) == selected['month']?.[0]?.days
        ),
      })
    } else if (
      // Corrects days for leap year
      selected['month']?.[0]?.days < parseInt(selected['day']?.[0]?.value) &&
      selected['month']?.[0].value === '02' &&
      (isLeapyear || selected['year']?.[0]?.isPlaceholder)
    ) {
      setSelected({
        ...selected,
        day: [
          {
            id: 'day-29',
            label: '29',
            order: 29,
            value: '29',
          },
        ],
      })
    } else if (
      !isLeapyear &&
      selected['month']?.[0].value === '02' &&
      parseInt(selected['day']?.[0]?.value) > 28 &&
      !selected['year']?.[0]?.isPlaceholder
    ) {
      setSelected({
        ...selected,
        day: [
          {
            id: 'day-28',
            label: '28',
            order: 28,
            value: '28',
          },
        ],
      })
    }
    // Prevents from selecting the 29th of February when its not a leap year
    if (
      selected['month']?.[0].value === '02' &&
      !isLeapyear &&
      !selected['year']?.[0]?.isPlaceholder
    ) {
      var newDays = days
      newDays.options.pop()
      setDays(newDays)
    }
  }, [selected])

  useEffect(() => {
    formDispatch({
      type: 'SET_FORM_ERRORS',
      id: schema.id,
      error: inputError || null,
    })
  }, [inputError])

  const updateValue = (id, value) => {
    formDispatch({
      type: 'UPDATE_FORM_FIELD',
      id: id,
      value: value,
    })
  }

  //https://reactjs.org/docs/hooks-reference.html#useimperativehandle
  useImperativeHandle(ref, () => ({
    error: inputError,
    reset: resetHandler,
    value: value,
  }))

  /**
   * When the form is reset, clear the input
   */
  useEffect(() => {
    if (!touched) reset()
  }, [touched])

  const [disable, setDisable] = useState(false)
  useEffect(() => {
    let count = 0
    let v = []

    for (const key in selected) {
      if (!selected[key][0]?.isPlaceholder) {
        count++
        v.push(selected[key][0]?.value)
      } else if (schema.id === 'recurringDate') {
        v.push(selected[key][0]?.value)
      }
    }

    if (schema.id === 'endDate' && selected['year']?.[0]?.value) {
      const year = selected['year'][0].value

      if (!selected['month']?.[0]?.value) {
        const defaultMonth = '12'
        const lastDayOfMonth = new Date(year, defaultMonth, 0).getDate()

        setSelected((prevSelected) => {
          const newSelected = {
            ...prevSelected,
            month: [
              schema.options[1].options.find((o) => o.value === defaultMonth),
            ],
            day: [
              schema.options[2].options.find(
                (o) => o.value === lastDayOfMonth.toString()
              ),
            ],
          }

          if (JSON.stringify(newSelected) !== JSON.stringify(prevSelected)) {
            return newSelected
          } else {
            return prevSelected
          }
        })

        v = [year, defaultMonth, lastDayOfMonth.toString()]
      } else {
        const selectedMonth = selected['month'][0].value
        const year = selected['year'][0].value
        const previousDay = sessionStorage.getItem('day')
        const selectedDay =
          selected['day'] && selected['day'][0]
            ? selected['day'][0].value
            : 'null'
        const lastDayOfMonth = new Date(year, selectedMonth, 0).getDate()
        if (previousDay !== null && selectedDay !== previousDay) {
          sessionStorage.setItem('daySelected', true)
        }
        sessionStorage.setItem('day', lastDayOfMonth)
        const daySelected = sessionStorage.getItem('daySelected')
        if (previousDay < lastDayOfMonth && daySelected === null) {
          setSelected((prevSelected) => ({
            ...prevSelected,
            day: [
              schema.options[2].options.find(
                (o) => o.value === lastDayOfMonth.toString()
              ),
            ],
          }))
        }
      }
    }
    if (schema.id === 'startDate') {
      if (selected['year']?.[0]?.value && !selected['month']?.[0]?.value) {
        setSelected((prevSelected) => ({
          ...prevSelected,
          month: [schema.options[1].options.find((o) => o.value === '01')],
          day: [schema.options[2].options.find((o) => o.value === '01')],
        }))

        v.push('01', '01')
      }
    }

    if (count > 0) {
      setDisable(false)
    } else {
      setDisable(true)
    }

    setValue(v.join('-'))
  }, [selected, schema])
  useEffect(() => {
    if (schema.id === 'recurringDate') {
      schema = schema.options.map((option) => {
        delete option.requires
        return option
      })
    }
  }, [schema])

  useEffect(() => {
    updateValue(schema.id, value)
    changeHandler(null, value)
  }, [value])

  const errorInputClasses =
    inputError?.type === 'error'
      ? 'invalid border-error-dark bg-error-lighter'
      : inputError?.type === 'warning'
      ? 'warning border-accent-warm-dark bg-accent-warm-lighter'
      : ''
  const inputClasses = [
    // 'usa-input',
    'border-0',
    'margin-0',
    'text-center',
    'width-4',
  ].join(' ')

  return (
    <div
      className={[
        'display-inline-flex',
        'flex-align-start',
        'flex-justify-start',
        'flex-row',
        'maxw-full',
        'minw-mobile',
      ].join(' ')}
    >
      <label
        className={[
          'display-flex',
          'flex-gap',
          'flex-column',
          'width-full',
        ].join(' ')}
        htmlFor={schema.id}
      >
        <div
          className={[
            'display-flex',
            'flex-align-center',
            'flex-justify',
            'flex-row',
            'flex-gap-xs',
            'minh-button',
            'margin-bottom-neg-2',
          ].join(' ')}
        >
          <h2
            className={[
              'display-flex',
              'flex-gap',
              'flex-row',
              'margin-bottom-0',
            ].join(' ')}
          >
            {schema.label}{' '}
            {schema.description && (
              <Tooltip
                content={schema.description}
                delay={500}
                display="block"
                id={'#' + schema.id + 'description'}
                position="topCenter"
              >
                <Button
                  align="center"
                  iconName="circle-questions"
                  iconOnly
                  iconSize="xs"
                  reduced
                  shape="pill"
                  srText={`${schema.label} details`}
                  textOnly
                  theme="base-dark"
                />
              </Tooltip>
            )}
          </h2>
          <Button
            className={['margin-bottom-neg-2', 'margin-top-neg-2'].join(' ')}
            disabled={disable}
            onClick={reset}
            reduced
            textOnly
            thin
          >
            Reset fields
          </Button>
        </div>
        <div
          className={[
            'mobile-lg:display-flex',
            'flex-align-start',
            'flex-justify',
            'flex-row',
            'flex-gap-xs',
            'minh-button',
            'margin-bottom-neg-2',
          ].join(' ')}
        >
          {schema.options.map((option, index) => (
            <Fragment key={option.id}>
              <div className="display-flex flex-column width-full">
                <h3>{option.label} </h3>
                <Select
                  className={errorInputClasses}
                  disabled={
                    option.requires &&
                    option.requires.filter((o) => selected[o][0].isPlaceholder)
                      .length > 0
                  }
                  onBlur={blurHandler}
                  onChange={(e, id) => selectHandler(e, id, option)}
                  // options={option.id === 'day' ? dayOpts : null}
                  error={inputError}
                  multiSelect={false}
                  schema={option.options}
                  search={option.searchable}
                  selected={selected[option.id]}
                  {...props}
                />
              </div>
              {index + 1 < schema.options.length && (
                <span className="display-flex flex-align-center mobile-lg:margin-top-4">
                  <Icon
                    color="base"
                    iconName="forward-slash"
                    iconPercent="80"
                  />
                </span>
              )}
            </Fragment>
          ))}
        </div>

        <InputMessage schema={schema} message={inputError} />
      </label>
    </div>
  )
})

DateInputField.defaultProps = {}

DateInputField.propTypes = {
  schema: inputSchemaPropTypes,
}

DateInputField.displayName = 'DateInputField'
export default DateInputField
