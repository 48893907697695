import React from 'react'

const useFormatter = () => {
  const formatDateTime = (dateTime = Date.now()) => {
    const date = new Date(dateTime)
    const [result, timeResult] = date.toLocaleString().split(',')
    return [result, timeResult]
  }

  return {
    formatDateTime,
  }
}
export default useFormatter
