import React, { Fragment } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
// Hooks
import useModal from '../../../hooks/use-modal'
//Components
import Modal, { ModalBody, ModalFooter } from '../../Modal/Modal'
import { Button } from '../../Button/Button'
import { IconLabel } from '../../IconLabel/IconLabel'
// Helpers
import { compareNumbers } from '../../../helper-functions/sort-functions'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'
import { returnHMSFromSeconds } from '../../../helper-functions/date-functions'
import {
  archivedCopySortOrder,
  returnCustomOrder,
} from './section_archived_copies'

const DetailsModal = ({ copy, data, ...props }) => {
  const { isShowing, toggle } = useModal()

  const media = data.mediaOccurrences
    ?.sort((a, b) =>
      compareNumbers(a.reelTapeDiscNumber, b.reelTapeDiscNumber, false)
    )
    ?.sort((a, b) => compareNumbers(a.elementNumber, b.elementNumber, false))

  const totalRunningTime = returnHMSFromSeconds(parseInt(data.totalRunningTime))

  // runningTimeSeconds is an object like: {runningTimeSeconds: 12345}
  const FormattedRunningTime = (runningTimeSeconds) => {
    const runningTime = returnHMSFromSeconds(
      parseInt(runningTimeSeconds.runningTimeSeconds)
    )
    return (
      <span>
        {returnNumberWithCommas(runningTimeSeconds.runningTimeSeconds)} seconds
        (
        {runningTime.hours > 0 && (
          <Fragment>
            {runningTime.hours > 1 ? `${runningTime.hours} hours` : '1 hour'}
            {runningTime.hours > 0 && runningTime.minutes > 0
              ? runningTime.seconds > 0
                ? ', '
                : ' and '
              : ''}{' '}
          </Fragment>
        )}
        {runningTime.minutes > 0 && (
          <Fragment>
            {runningTime.minutes > 1
              ? `${runningTime.minutes} minutes`
              : '1 minute'}
            {(runningTime.hours > 0 || runningTime.minutes > 0) &&
            runningTime.minutes > 0
              ? ' and '
              : ''}{' '}
          </Fragment>
        )}
        {runningTime.seconds > 1
          ? `${runningTime.seconds} seconds`
          : '1 second'}
        )
      </span>
    )
  }

  return (
    <>
      <Button
        className="margin-left-1"
        iconName="option2"
        data-testid="nac-description__details-modal-button"
        iconOnly
        onClick={toggle}
        outline
        reduced
        srText={`Access additional details for copy ${copy}`}
        thin
      />
      {isShowing && (
        //TO DO: Title that makes more sense....
        <Modal
          hide={toggle}
          id={`detail-copy-${copy}`}
          size="lg"
          title={`Copy ${copy}`}
        >
          <ModalBody id={`detail-copy-${copy}`}>
            <div
              data-testid="nac-description__details-modal"
              className={['display-flex', 'flex-column', 'flex-gap-md'].join(
                ' '
              )}
            >
              <div
                className={['display-flex', 'flex-row', 'flex-gap'].join(' ')}
              >
                <div
                  className={['display-flex', 'flex-column', 'width-12'].join(
                    ' '
                  )}
                >
                  Purpose:
                </div>
                <div
                  className={[
                    'display-flex',
                    'flex-column',
                    'flex-gap-sm',
                    'width-full',
                  ].join(' ')}
                >
                  {data.copyStatus
                    ?.split('-')
                    ?.sort(returnCustomOrder(archivedCopySortOrder, 'index'))
                    ?.map((status) => (
                      <Fragment key={status}>
                        <IconLabel
                          color="base-darkest"
                          iconName={status.toLowerCase()}
                          iconSize="xs"
                        >
                          <strong>{status}</strong>
                        </IconLabel>
                        {copyStatusDescriptions.map((desc, index) => (
                          <Fragment key={desc.label + index}>
                            {status === desc.label && (
                              <span className="margin-left-3">
                                {desc.description}
                              </span>
                            )}
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}
                </div>
              </div>
              {media && (
                <div
                  className={['display-flex', 'flex-row', 'flex-gap'].join(' ')}
                >
                  <div
                    className={['display-flex', 'flex-column', 'width-12'].join(
                      ' '
                    )}
                  >
                    Types of Media:
                  </div>
                  <div
                    className={[
                      'display-flex',
                      'flex-column',
                      'flex-gap',
                      'margin-left-3',
                      'width-full',
                    ].join(' ')}
                  >
                    {media.map((occurrence, i) => (
                      <div
                        key={`mediaOccurrence-${i}`}
                        className={[
                          'display-flex',
                          'flex-column',
                          'flex-gap-xs',
                        ].join(' ')}
                      >
                        <strong>{occurrence.specificMediaType}</strong>
                        {mediaOccurrencesDescriptions.map((desc, index) => (
                          <Fragment key={desc.label + index}>
                            {occurrence[desc.id] && (
                              <span>
                                {desc.id == 'runningTime' ? (
                                  <div>
                                    Running Time:{' '}
                                    <FormattedRunningTime
                                      runningTimeSeconds={
                                        occurrence.runningTime
                                      }
                                    />
                                  </div>
                                ) : (
                                  desc.label + ': ' + occurrence[desc.id]
                                )}
                              </span>
                            )}
                          </Fragment>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {totalRunningTime && (
                <div
                  className={['display-flex', 'flex-row', 'flex-gap'].join(' ')}
                >
                  <div
                    className={['display-flex', 'flex-column', 'width-12'].join(
                      ' '
                    )}
                  >
                    Total Running Time:
                  </div>
                  <div
                    className={[
                      'display-flex',
                      'flex-column',
                      'flex-gap-xs',
                      'margin-left-3',
                      'width-full',
                    ].join(' ')}
                  >
                    <FormattedRunningTime
                      runningTimeSeconds={data.totalRunningTime}
                    />
                  </div>
                </div>
              )}
              {data.totalFootage && (
                <div
                  className={['display-flex', 'flex-row', 'flex-gap'].join(' ')}
                >
                  <div
                    className={['display-flex', 'flex-column', 'width-12'].join(
                      ' '
                    )}
                  >
                    Total Footage Length:
                  </div>
                  <div
                    className={[
                      'display-flex',
                      'flex-column',
                      'flex-gap-xs',
                      'margin-left-3',
                      'width-full',
                    ].join(' ')}
                  >
                    {returnNumberWithCommas(data.totalFootage)} feet{' '}
                  </div>
                </div>
              )}
              {data.extent && (
                <div
                  className={['display-flex', 'flex-row', 'flex-gap'].join(' ')}
                >
                  <div
                    className={['display-flex', 'flex-column', 'width-12'].join(
                      ' '
                    )}
                  >
                    Extent:
                  </div>
                  <div
                    className={[
                      'display-flex',
                      'flex-column',
                      'margin-left-3',
                      'width-full',
                    ].join(' ')}
                  >
                    <p>{data.extent}</p>
                  </div>
                </div>
              )}
              {data.holdingsMeasurements && (
                <div
                  className={['display-flex', 'flex-row', 'flex-gap'].join(' ')}
                >
                  <div
                    className={['display-flex', 'flex-column', 'width-12'].join(
                      ' '
                    )}
                  >
                    Count:
                  </div>
                  <div
                    className={[
                      'display-flex',
                      'flex-column',
                      'flex-gap-xs',
                      'margin-left-3',
                      'width-full',
                    ].join(' ')}
                  >
                    {data.holdingsMeasurements.map((item, i) => (
                      <p key={`holdingsMeasurements-${i}`}>
                        {(item.count ? item.count : '') +
                          ' ' +
                          (item.type ? item.type : '')}
                      </p>
                    ))}
                  </div>
                </div>
              )}
              {data.physicalOccurrenceNote && (
                <div
                  className={['display-flex', 'flex-row', 'flex-gap'].join(' ')}
                >
                  <div
                    className={['display-flex', 'flex-column', 'width-12'].join(
                      ' '
                    )}
                  >
                    Notes:
                  </div>
                  <div
                    className={[
                      'display-flex',
                      'flex-column',
                      'margin-left-3',
                      'width-full',
                    ].join(' ')}
                  >
                    <p>{data.physicalOccurrenceNote}</p>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter hide={toggle} />
        </Modal>
      )}
    </>
  )
}
DetailsModal.propTypes = {
  copy: PropTypes.number,
  data: PropTypes.object,
}

export default DetailsModal

const specificMediaTypesDescriptions = [
  {
    label: 'Aperture Card',
    description:
      'An 80 column tabulating size paper card with a hole, or aperture, containing one frame of 35 mm microfilm. Generally used for engineering drawings, maps, charts, and X rays. Use Dimension to include additional information on aperture cards.',
  },
  {
    label: 'Artifact',
    description: null,
  },
  {
    label: 'Artwork',
    description: null,
  },
  {
    label: 'Audio Cartridge',
    description: null,
  },
  {
    label: 'Audio Cassette',
    description: null,
  },
  {
    label: 'Audio Disk',
    description: null,
  },
  {
    label: 'Audio MiniDisc (MD)',
    description: null,
  },
  {
    label: 'Audio Tape/Reel',
    description: null,
  },
  {
    label: 'Bound Volume',
    description:
      'Books, magazines, and other media types in which pages are bound together to form a single volume. Examples include manuals, publications, and notebooks.',
  },
  {
    label: 'Cards',
    description: 'Use for index cards or punch cards.',
  },
  {
    label: 'Cylinder',
    description:
      'Cylinders or disks of rubber, wax, metal, vinyl, etc., specially prepared with a spiral grove that reproduces sound when played on a machine with a needle or stylus. Generally used for phonographs and records. Use Base, Format, or Process to include additional information on cylinders.',
  },
  {
    label: 'Dictation Belt',
    description:
      'Dictation belts are the media used in dictation machines, which record human speech for transcription. Use Format to include additional information on dictation belts.',
  },
  {
    label: 'Digital Audio Tape (DAT)',
    description:
      'Magnetizable material in tape form, on which sound recordings are digitally encoded. DAT was developed by Sony and Phillips in the mid-1980s.',
  },
  {
    label: 'Digital Print',
    description:
      'Images captured using a digital image capture device (e.g. digital camera, scanner) that are physically rendered for viewing. Use Base, Color, Dimension, Emulsion, or Process to include additional information on digital prints.',
  },
  {
    label: 'Film Reel',
    description:
      'Film reel is a general term used for film supplied on its own reel and not contained in a cartridge or cassette. The film is threaded, usually by hand, through the mechanism of its machine and then is wound on a separate take-up reel. Use Base, Color, Dimension, Format, Process, Recording Speed, Roll Type, Sound Type, or Wind to include additional information on film reels.',
  },
  {
    label: 'Filmstrip',
    description:
      'A strip of film bearing a sequence of images for projection as still pictures. Use Base, Color, Dimension, or Process to include additional information on filmstrips.',
  },
  {
    label: 'Interpositive Transparency',
    description:
      'Positive photographic transparencies used as an intermediate step in making copies of negatives.',
  },
  {
    label: 'Linocut',
    description: null,
  },
  {
    label: 'Magnetic Disk',
    description: null,
  },
  {
    label: 'Magnetic Disk: Floppy Disk',
    description:
      'Thin, round, flexible disks with magnetic surfaces for storing and retrieving data.',
  },
  {
    label: 'Magnetic Disk: Hard Drive',
    description:
      'Devices that house a fixed (non-removable) hard disk for storage and retrieval of digital computer data.',
  },
  {
    label: 'Magnetic Tape Cassette',
    description: null,
  },
  {
    label: 'Magnetic Tape Reel',
    description:
      'NARA uses open-reel tape on one-half inch 9-track tape reels recorded at 1600 or 6250 bytes per inch (bpi) for preservation of electronic records.',
  },
  {
    label: 'Manual Print',
    description: null,
  },
  {
    label: 'Microfiche',
    description:
      'A flat, transparent sheet of film with miniaturized images arranged in a grid pattern. Use Dimension or Process to include additional information on microfiche.',
  },
  {
    label: '[MF]',
    description: null,
  },
  {
    label: 'Microfilm',
    description:
      'A fine-grain, high-resolution film in roll form containing miniaturized images in sequential order. Use Dimension or Process to include additional information on microfilm.',
  },
  {
    label: '[MF]',
    description: null,
  },
  {
    label: 'Microopaque',
    description:
      'An opaque medium, such as translucent paper stock, containing microimages arranged in a grid pattern. Also called microcard or microprint. Use Dimension or Process to include additional information on microopaques.',
  },
  {
    label: 'Negative',
    description:
      'A transparent support, such as glass plate or piece of film, on which the tones or colors of an image are reversed from their appearance in nature. Directly opposite to a "positive" image (photograph) or transparency. Use Color, Dimension, Emulsion, or Process to include additional information on negatives.',
  },
  {
    label: 'Networked Storage',
    description:
      'Data storage or a data storage device that is accessible on a computer network, including network-attached storage (NAS) and storage area networks (SAN).',
  },
  {
    label: 'Networked Storage',
    description: null,
  },
  {
    label: 'Optical Disk: Compact Disk (CD)',
    description:
      'Optical disk on which programs, data, or music are digitally encoded for a laser beam to scan, decode, and transmit to a playback system, computer monitor, or television set.',
  },
  {
    label: 'Optical Disk: Digital Versatile Disk (DVD)',
    description:
      'Optical disks the same size as compact disks, but designed to hold approximately seven times more digital information, including multiple layers. DVDs are generally one-sided, each side holding 4.7 gigabytes of information, most often video.',
  },
  {
    label: 'Original Positive',
    description: null,
  },
  {
    label: 'Paper',
    description:
      'Use Base, Color, Dimension, or Process to include additional information on paper.',
  },
  {
    label: 'Parchment',
    description:
      'Parchment historically is made from unsplit calfskin that has been specially treated for use as a writing medium and in binding. Parchment may be made from lambskin or goatskin. The term is used interchangeably with "vellum".',
  },
  {
    label: 'Photographic Print',
    description:
      'Photographs generally printed from a negative. Do not confuse with manual prints, original positives, photomechanical prints, or digital prints. Use Base, Color, Dimension, Emulsion, or Process to include additional information on photographic prints.',
  },
  {
    label: 'Photomechanical Print',
    description: null,
  },
  {
    label: 'Professional Disc (PFD)',
    description: null,
  },
  {
    label: 'Slide',
    description:
      'Positive transparencies in mounts suitable for projection, usually 35mm film in a mount of 2 by 2 inches.',
  },
  {
    label: 'Tracing Cloth',
    description:
      'Fine, thin cotton or linen cloth, coated on one or both sides with starch or a starch mixture, used especially by architects and designers for making tracings in ink or pencil.',
  },
  {
    label: 'Transparency',
    description:
      'Images designed for viewing by transmitted light. Most often refers to transparent positive photographs; can also refer to transparent non-photographic images that are designed for projection viewing or as intermediates for reproduction. Use Base, Color, Dimension, or Process to include additional information on transparencies.',
  },
  {
    label: 'USB Flash Drive',
    description:
      "A portable flash memory data storage device which connects to a computer's USB port.",
  },
  {
    label: 'Video Cassette',
    description:
      'Recordings on videotape, which is a magnetic tape that records images and sound electronically, contained in cassette.',
  },
  {
    label: 'Video Open Reel',
    description:
      'Recordings on videotape, which is a magnetic tape that records images and sound electronically, contained on an open reel.',
  },
  {
    label: 'Videodisk',
    description:
      'Analog or digital optical disks on which images or sound are recorded by a laser. Use Format or Process to include additional information on videodisks.',
  },
  {
    label: 'Wire Recording',
    description:
      'Recordings of sound on a spool of wire rather than on magnetic tape.',
  },
]

// This information is detailed in the Swagger data model documentation
const mediaOccurrencesDescriptions = [
  {
    id: 'base',
    label: 'Base',
    description:
      'The substance of the media used to physically carry or hold the information in the archival item. Also called the substrate.',
    //'Provides the physical characteristics of the archival materials, which helps establish conservation, storage, retrieval, reproduction, and use requirements.',
    examples: [
      'Photographic Paper: Resin-coated',
      'Cellulose Acetate',
      'Paper',
    ],
  },
  {
    id: 'color',
    label: 'Color',
    description:
      'The absence or presence of colors, besides black, white and gray, of the specific media type.',
    examples: ['Color', 'Black-and-White'],
  },
  {
    id: 'containerId',
    label: 'Container Id',
    description:
      'The identifier or number for the individual container storing each specific media type.',
    examples: ['Box 6829', '27'],
  },
  {
    id: 'depth',
    label: 'Depth',
    description:
      'The distance in inches measured from front to back of a specific media type.',
    examples: [6.75, 10],
  },
  {
    id: 'dimension',
    label: 'Dimension',
    description:
      'The standard size of the physical items of a specific media type. Provides information needed for reproduction.',
    examples: ['3x5 inch', '1/2 inch', 'Varied'],
  },
  {
    id: 'elementNumber',
    label: 'Element Number',
    description:
      'Indicates the sequence of the media occurrence in relation to the other media occurrences that make up the physical occurrence. Specifically, it is the number assigned to an individual physical component that makes up a reel, tape, or disc. Commonly used when a film reel is made up of separate sound and picture elements, or when a film reel is composed of A and B rolls. For example, a film reel may be composed of an image reel and a soundtrack reel. The image reel could be designated element number 1, and the soundtrack reel could be designated element number 2.',
    examples: [1, 2, 3],
  },

  {
    id: 'emulsion',
    label: 'Emulsion',
    description:
      'The type of coating that is bonded to and supported by the base of the specific media type.',
    examples: ['Metal Evaporation', 'Ferric Oxide'],
  },
  {
    id: 'footage',
    label: 'Footage',
    description:
      'The length in feet for an individual roll or reel in an audiovisual item.',
    examples: [502, 465],
  },
  {
    id: 'format',
    label: 'Format',
    description:
      'The format of the media comprising the audiovisual materials. For example, the format of videotape can be VHS or Betamax, the format for sound recordings can be Master or Stamper Matrix, or the format for motion pictures can be Duplicate Negative Composite or Master Projection Print Composite.',
    examples: ['Dication Belt: Magnabelt', 'Sound: Mass Produced Pressing'],
  },
  {
    id: 'height',
    label: 'Height',
    description:
      'The distance in inches measured from top to bottom of the specific media type.',
    examples: [11.75, 3],
  },
  {
    id: 'width',
    label: 'Width',
    description:
      'The distance in inches measured from side to side of the specific media type.',
    examples: [8.5, 4.5],
  },
  {
    id: 'otherPreservationCharacteristics',
    label: 'Other Preservation Characteristics',
    description:
      'Additional information about the physical nature of the archival materials needed for preservation purposes.',
    examples: ['Artifacts: Copper alloy', 'Fastners/records adhered together'],
  },
  {
    id: 'mediaOccurrenceNote',
    description:
      'Explanations or significant information relating to the media occurrence of the archival materials.',
    examples: [
      'This reel contains music and sound effects.',
      'These images were made by the albumen and collodion processes.',
    ],
    label: 'Media Occurrence Note',
  },
  {
    id: 'physicalRestrictionNote',
    description:
      'Information regarding any physical restrictions that apply to the particular specific media type of the archival materials. Physical restrictions are primarily preservation restrictions.',
    examples: [
      'Because these records are stored offsite, researchers must request the records 48 hours in advance.',
      'This film reel has shrunk and may not be viewed.',
    ],
    label: 'Physical Restriction Note',
  },
  {
    id: 'pieceCount',
    description:
      'The exact number of physical items comprising the specific media type of the archival materials.',
    examples: ['3', '10'],
    label: 'Piece Count',
  },
  {
    id: 'process',
    description:
      'The technical or manual means by which the specific media type was created.',
    examples: ['Artwork: Chalk', 'Photocopy'],
    label: 'Process',
  },
  {
    id: 'recordingSpeed',
    label: 'Recording Speed',
    description:
      'The speed at which audio recordings are recorded or reproduced. For audio tapes, the measurement is inches per second (ips). For audio discs or cylinders, the measurement is revolutions per minute (rpm).',
    examples: ['Audio Disk: 120 rpm', 'Audio Tape: 15 ips'],
  },
  {
    id: 'reelTapeDiscNumber',
    label: 'Reel Tape Disc Number',
    description: null,
    examples: [44, 3],
  },
  {
    id: 'roll',
    label: 'Roll',
    description:
      'Indication of the roll used in a motion picture. In creating a film, alternate scenes are typically assembled in checkerboard fashion on two or more different rolls with black leaders between scenes to facilitate fades and dissolves and to ultimately produce a single strip master positive from which a duplicating negative (dupe neg) can then be made.',
    examples: ['A Roll', 'B Roll'],
  },
  {
    id: 'runningTime',
    label: 'Running Time',
    description:
      'The duration in seconds of the individual parts (reels, tapes, rolls) of an audiovisual item.',
    examples: [7200, 290],
  },
  {
    id: 'soundtrackConfiguration',
    label: 'Sound Track Configuration',
    description:
      'The recording configuration of a sound recording or motion picture soundtrack.',
    examples: ['Film: Variable Area, Push-pull', 'Sound: Dual'],
  },
  {
    id: 'soundtrackLanguage',
    label: 'Sound Track Language',
    description:
      'The language of the soundtrack for a motion picture or video recording.',
    examples: ['Dutch', 'German'],
  },
  {
    id: 'tapeThickness',
    label: 'Tape Thickness',
    description:
      'The thickness of the tape on which the audiovisual item has been recorded.',
    examples: ['0.5 mil', '1.0 mil'],
  },
  {
    id: 'totalFootage',
    label: 'Total Footage',
    description: '',
  },
  {
    id: 'technicalAccessRequirementsNote',
    description:
      'A description of the equipment needed to view the specific media type, such as a light table to view aerial film, or hardware and software to view archival materials on electronic media.',
    examples: [
      'An open reel computer tape drive is required.',
      'A light table is needed to view these aerial photographs.',
    ],
    label: 'Technical Access Requirements Note',
  },
  {
    id: 'wind',
    label: 'Wind',
    description: 'The playback direction of the reel/roll.',
    examples: ['A Wind', 'B Wind'],
  },
]

const copyStatusDescriptions = [
  {
    label: 'Reference',
    description: 'Used for consultation by both NARA staff and researchers.',
  },
  {
    label: 'Reproduction',
    description:
      'Used to create exact copies of the record in form and content, but not necessarily in size and appearance. Also known as the "intermediate copy," especially in motion picture film descriptions.',
  },
  {
    label: 'Preservation',
    description:
      'Used in the processes and operations involved in the stabilization and protection of records against damage or deterioration.',
  },
]
