import React, { Fragment } from 'react'
import { returnHumanFileSize } from './file-functions'
import { returnHumanDate, returnHumanDateFromSlashDate } from './date-functions'
import { Link } from 'react-router-dom'
import { ListSeparator } from '../components/ListSeparator/ListSeparator'
import Prose from '../components/utilities/Prose'
import Highlighter from '../components/Highlighter/Highlighter'

/**
 * Render JSX list element containing the human-readable byte size
 * @param {string || array} data [Required] String or array of content to be displayed or converted
 * @param {string} label [Required]         String that is used to label the returned size
 *                                          default: ''
 * @param {string} format                   String that represents the final intended format of the metadata
 *                                          default: ''
 * @return JSX with a formatted string.
 */
export function renderMetaDataListElement(data, label, format = '') {
  if (!data || data.length == 0) return false
  if (!label) return false
  if (typeof label !== 'string') return false
  if (format && typeof format !== 'string') return false

  const formatTypes = ['file', 'date']
  if (format && formatTypes.indexOf(format) == -1) {
    return false
  }
  let finalString = data
  if (format) {
    switch (format) {
      case 'file':
        if (typeof data !== 'string') return false
        finalString = returnHumanFileSize(data)
        break
      case 'date':
        if (typeof data !== 'object') return false
        finalString = returnHumanDate(data)
        break
    }
  }

  return (
    <li key={Math.random()} className="flex-row grid-row">
      <span className="flex-column grid-col">{label}:</span>{' '}
      <span className="flex-column grid-col flex-justify-right">
        {finalString}
      </span>
    </li>
  )
}

export const imageTypes = [
  'BMP',
  'GIF',
  'JP2',
  'JPG',
  'PNG',
  'PDF',
  'TIFF',
  'DNG',
]
export const fileTypes = ['PDF']
export const videoTypes = ['MOV', 'MP4', 'WMV']
export const audioTypes = ['MP3', 'WAV']

/**
 * Render JSX list element containing the human-readable byte size
 * @param {string} object [Required]  Data object to render as individual list elements
 *                                    format: [
 *                                      { data: object?.objectFileSize, label: 'File Size', format: 'file' },
 *                                      { ... }
 *                                    ]
 * @param {string} label              String that is used to label the returned size
 *                                    default: ''
 * @return JSX with a formatted list elements.
 */
export function renderMetaDataList(object, label) {
  if (!object) return false
  if (!label) return false
  if (typeof object !== 'object') return false
  if (typeof label !== 'string') return false

  var cleanObject = object.filter(function (obj) {
    return typeof obj.data !== 'undefined' && typeof obj.label !== 'undefined'
  })
  if (cleanObject.length == 0) return false

  return (
    <ul className="flex-column grid-col list-with-margin add-list-reset">
      <li>
        <h3>{label}</h3>
      </li>
      {cleanObject.map((obj) =>
        renderMetaDataListElement(obj.data, obj.label, obj.format)
      )}
    </ul>
  )
}

/**
 * Return the string used for icon identification and generation based on the text provided
 * @param {string} string [Required] The default string representing the record or media type
 * @return String of the record type.
 */
export function returnIconAndLabelFromString(string, id) {
  if (!string) return false
  if (typeof string !== 'string') return false

  const stringSwitch = (string) => {
    let type = { icon: '', label: string }
    switch (string) {
      case 'Architectural and Engineering Drawings':
        type.icon = 'arch'
        break
      case 'Artifacts':
        type.icon = 'box-3d'
        break
      case 'Audio Cassette':
        type.icon = 'audio-cassett'
        break
      case 'Audio Tape/Reel':
        type.icon = 'reel'
        break
      case 'authority':
        type.label = 'Authority Records'
        break
      case 'beginCongress':
        type.label = 'Begin Congress'
        break
      case 'Bound Volume':
        type.icon = 'book'
        break
      case 'collection':
        type.icon = 'boxes'
        type.label = 'Collection'
        break
      case 'collectionIdentifier':
        type.label = 'Collection Identifier'
        break
      case 'contributionType=tag&contribution':
        type.label = 'Tag'
        break
      case 'controlNumbers':
        type.label = 'Control Number'
        break
      case 'creators':
        type.label = 'Creator'
        break
      case 'Data Files':
        type.icon = 'data'
        break
      case 'dataSource':
        type.label = 'Source'
        break
      case 'description':
        type.label = 'Archival Descriptions'
        break
      case 'endCongress':
        type.label = 'End Congress'
        break
      case 'endDate':
        type.label = 'To'
        break
      case 'exactDate':
        type.label = 'Exact Date'
        break
      case 'fileUnit':
        type.icon = 'folder'
        type.label = 'File Unit'
        break
      case 'Film Reel':
        type.icon = 'reel'
        break
      case 'fullyDigitized':
        type.icon = 'check'
        type.label = 'Fully Digitized'
        break
      case 'geographicPlaceName':
        type.icon = 'location'
        type.label = 'Place'
        break
      case 'geographicReference':
        type.label = 'Place'
        break
      case 'Image':
        type.icon = 'image'
        break
      case 'image':
        type.icon = 'image'
        break
      case 'item':
        type.icon = 'items'
        type.label = 'Item'
        break
      case 'itemAv':
        type.icon = 'items'
        type.label = 'Item'
        break
      case 'levelOfDescription':
        type.label = 'Level'
        break
      case 'localIdentifier':
        type.label = 'Local ID'
        break
      case 'Maps and Charts':
        type.icon = 'map'
        break
      case 'Moving Images':
        type.icon = 'items'
        break
      case 'Negative':
        type.icon = 'negative'
        break
      case 'notDigitized':
        type.icon = 'close'
        type.label = 'Not Digitized'
        break
      case 'object':
        type.icon = 'grid'
        type.label = 'Object'
        break
      case 'objectType':
        type.label = 'Format'
        break
      case 'Optical Disk: Compact Disk (CD)':
        type.icon = 'cd'
        type.label = 'CD'
        break
      case 'Optical Disk: Compact Disk':
        type.icon = 'cd'
        type.label = 'CD'
        break
      case 'Optical Disk: Digital Versatile Disk':
        type.icon = 'cd'
        type.label = 'DVD'
        break
      case 'organization':
        type.icon = 'group'
        type.label = 'Organization'
        break
      case 'organizationName':
        type.icon = 'group'
        type.label = 'Organization Name'
        break
      case 'Paper':
        type.icon = 'paper'
        break
      case 'partiallyDigitized':
        type.icon = 'triangle-exclaim'
        type.label = 'Partially Available Online'
        break
      case 'person':
        type.icon = 'person'
        type.label = 'Person'
        break
      case 'Photographic Print':
        type.icon = 'photo'
        break
      case 'Photomechanical Print':
        type.icon = 'photo'
        break
      case 'Photographs and other Graphic Materials':
        type.icon = 'camera'
        break
      case 'Preservation':
        type.icon = 'preservation'
        break
      case 'recordGroup':
        type.icon = 'boxes'
        type.label = 'Record Group'
        break
      case 'recordGroupNumber':
        type.label = 'Record Group Number'
        break
      case 'recurringDateDay':
        type.label = 'Recurring Day'
        break
      case 'recurringDateMonth':
        type.label = 'Recurring Month'
        break
      case 'Reproduction':
        type.icon = 'reproduction'
        break
      case 'Reference':
        type.icon = 'reference'
        break
      case 'referenceUnits':
        type.icon = 'building'
        type.label = 'Location'
        break
      case 'series':
        type.icon = 'box'
        type.label = 'Series'
        break
      case 'Slide':
        type.icon = 'slide'
        break
      case 'Sound Recordings':
        type.icon = 'audio'
        break
      case 'specificRecordsType':
        type.icon = 'target'
        type.label = 'Specific Record Type'
        break
      case 'startDate':
        type.label = 'From'
        break
      case 'Textual Records':
        type.icon = 'text'
        break
      case 'title':
        type.label = 'Title'
        break
      case 'topicalSubject':
        type.icon = 'bookmark'
        type.label = 'Topic'
        break
      case 'Transparency':
        type.icon = 'transparency'
        break
      case 'typeOfMaterials':
        type.label = 'Material'
        break
      case 'Video':
        type.icon = 'video'
        break
      case 'Video Cassette':
        type.icon = 'vhs'
        type.label = 'VHS'
        break
      case 'Video Open Reel':
        type.icon = 'reel'
        break
      case 'Web Pages':
        type.icon = 'globe'
        break
      case 'unrestrictedOnly':
        type.icon = 'triangle-exclaim'
        type.label = 'Unrestricted Only'
        break
      case 'tagContribution':
        type.label = 'Tag'
        break
      case 'commentContribution':
        type.label = 'Comment'
        break
      case 'extractedTextContribution':
        type.label = 'Extracted Text'
        break
      case 'transcriptionContribution':
        type.label = 'Transcription'
        break
      default:
        type.icon = ''
        type.label = string
    }
    return type
  }
  let iconLabelArray = []
  const multiSelect = [
    'collectionIdentifier',
    'levelOfDescription',
    'objectType',
    'recordGroupNumber',
    'referenceUnits',
    'typeOfMaterials',
  ]
  if (multiSelect.indexOf(id) > -1) string = string.split(',')
  if (Array.isArray(string)) {
    string.map((s) => {
      iconLabelArray.push(stringSwitch(s))
    })
  } else iconLabelArray = stringSwitch(string)
  return iconLabelArray
}

/**
 * Return the string translation of record location from the provided mailing abbreviation
 * Abbreviation definitions from https://www.archives.gov/research/guide-fed-records/index-numeric/nara-abbreviations.html
 * @param {string} string [Required] The default string representing the location of the record
 * @return String of the record location name.
 */
export function returnHolderAndLocationFromMailCode(mailCode) {
  if (!mailCode) return false
  if (typeof mailCode !== 'string') return false
  let location = { name: '', city: '' }
  switch (mailCode) {
    case 'RDT1': // This as not part of the abbreviations page specified above.
      // We need to address these inconsistencies
      location.name = 'Textual Archives Services Division - Archives I'
      location.city = 'Washington, DC'
      break
    case 'NWCT1':
      location.name = 'Textual Archives Services Division - Archives I'
      location.city = 'Washington, DC'
      break
    case 'RDT2': // This as not part of the abbreviations page specified above.
      // We need to address these inconsistencies
      location.name = 'Textual Archives Services Division - Archives II'
      location.city = 'College Park, MD'
      break
    case 'NWCT2':
      location.name = 'Textual Archives Services Division - Archives II'
      location.city = 'College Park, MD'
      break
    case 'RDSC': // This as not part of the abbreviations page specified above.
      // We need to address these inconsistencies
      location.name = 'Cartographic and Architectural Records - Archives II'
      location.city = 'College Park, MD'
      break
    case 'NWCS-C':
      location.name = 'Cartographic and Architectural Records - Archives I'
      location.city = 'Washington, DC'
      break
    case 'RDSM':
      location.name = 'Motion Picture, Sound, and Video Records - Archives II'
      location.city = 'College Park, MD'
      break
    case 'NWCS-M':
      location.name = 'Motion Picture, Sound, and Video Records - Archives I'
      location.city = 'Washington, DC'
      break
    case 'RDSS': // This as not part of the abbreviations page specified above.
      // We need to address these inconsistencies
      location.name = 'Still Picture Records - Archives II'
      location.city = 'College Park, MD'
      break
    case 'NWCS-S':
      location.name = 'Still Picture Records - Archives I'
      location.city = 'Washington, DC'
      break
    case 'LL': // This as not part of the abbreviations page specified above.
      // We need to address these inconsistencies
      location.name = 'Center for Legislative Archives'
      location.city = 'Washington, DC'
      break
    case 'NWL':
      location.name = 'Center for Legislative Archives'
      location.city = 'Washington, DC'
      break
    case 'NWC':
      location.name = 'Access Programs'
      location.city = 'Washington, DC'
      break
    case 'NWME':
      location.name = 'Electronic and Special Media Records Services'
      location.city = 'Washington, DC'
      break
    case 'NR':
      location.name = 'Office of Regional Records Services'
      location.city = ''
      break
    case 'RE-BO': // This as not part of the abbreviations page specified above.
      // We need to address these inconsistencies
      location.name =
        'National Archives and Records Administration - Northeast Region (Boston)'
      location.city = 'Waltham, MA'
      break
    case 'NRABA':
      location.name =
        'National Archives and Records Administration - Northeast Region (Boston)'
      location.city = 'Waltham, MA'
      break
    case 'NRAN':
      location.name =
        'National Archives and Records Administration - Northeast Region (New York City)'
      break
    case 'NRBPA':
      location.name =
        'National Archives and Records Administration - Mid Atlantic Region (Center City Philadelphia)'
      break
    case 'NRCAA':
      location.name =
        'National Archives and Records Administration - Southeast Region'
      break
    case 'NRDA':
      location.name =
        'National Archives and Records Administration - Great Lakes Region (Chicago)'
      break
    case 'RM-KC': // This as not part of the abbreviations page specified above.
      // We need to address these inconsistencies
      location.name =
        'National Archives and Records Administration - Central Plains Region (Kansas City)'
      location.city = 'Kansas City, MO'
      break
    case 'NREKA':
      location.name =
        'National Archives and Records Administration - Central Plains Region (Kansas City)'
      location.city = 'Kansas City, MO'
      break
    case 'NRFFA':
      location.name =
        'National Archives and Records Administration - Southwest Region'
      break
    case 'NRGDA':
      location.name =
        'National Archives and Records Administration - Rocky Mountain Region'
      break
    case 'NRHLA':
      location.name =
        'National Archives and Records Administration - Pacific Region (Laguna Niguel)'
      break
    case 'NRHSA':
      location.name =
        'National Archives and Records Administration - Pacific Region (San Francisco)'
      break
    case 'NRIA':
      location.name =
        'National Archives and Records Administration - Pacific Alaska Region (Anchorage)'
      break
    case 'NRISA':
      location.name =
        'National Archives and Records Administration - Pacific Alaska Region (Seattle)'
      break
    case 'NL':
      location.name = 'Office of Presidential Libraries'
      break
    case 'NLDDE':
      location.name = 'Dwight D. Eisenhower Library'
      break
    case 'NLFDR':
      location.name = 'Franklin D. Roosevelt Library'
      break
    case 'NLGB':
      location.name = 'George Bush Presidential Library'
      break
    case 'NLGRF':
      location.name = 'Gerald R. Ford Library'
      break
    case 'NLHH':
      location.name = 'Herbert Hoover Library'
      break
    case 'NLHST':
      location.name = 'Harry S. Truman Library'
      break
    case 'LP-JC': // This as not part of the abbreviations page specified above.
      // We need to address these inconsistencies
      location.name = 'Jimmy Carter Library'
      location.city = 'Atlanta, GA'
      break
    case 'NLJC':
      location.name = 'Jimmy Carter Library'
      location.city = 'Atlanta, GA'
      break
    case 'NLJFK':
      location.name = 'John F. Kennedy Library'
      break
    case 'NLLBJ':
      location.name = 'Lyndon B. Johnson Library'
      break
    case 'NLMS':
      location.name = 'Presidential Materials Staff'
      break
    case 'NLRNS':
      location.name = 'Richard Nixon Library'
      break
    case 'NLRNS2': //This is not the correct mail code; there was a duplicate of NLRNS on the abbreviation site referenced above.
      // This needs to be verified and corrected!
      location.name = 'Richard Nixon Library - College Park'
      break
    case 'NLRR':
      location.name = 'Ronald Reagan Library'
      break
    case 'NLWJC':
      location.name = 'William J. Clinton Presidential'
      break
    case 'USMA':
      location.name = 'U.S. Military Academy, West Point'
      break
    case 'USNA':
      location.name = 'U.S. Naval Academy, Annapolis'
      break
    default:
      location.name = ''
  }
  return location
}

/**
 * Return an object containing the correct access label, icon, and theme based on the provided access text
 * @param {string} accessText [Required] The default string representing the location of the record
 * @return String of the record location name.
 */
export function returnAccessArrayFromAccessString(accessText) {
  let accessObj = {
    label: '',
    icon: '',
    theme: '',
  }

  switch (accessText) {
    case 'Unrestricted':
      accessObj.label = 'Unrestricted'
      accessObj.icon = 'unrestricted'
      accessObj.theme = 'success'
      break
    case 'Restricted - Partly':
      accessObj.label = 'Partially Restricted'
      accessObj.icon = 'restricted-partially'
      accessObj.theme = 'warning'
      break
    case 'Restricted - Possibly':
      accessObj.label = 'Possibly Restricted'
      accessObj.icon = 'restricted-possibly'
      accessObj.theme = 'warning'
      break
    case 'Restricted - Fully':
      accessObj.label = 'Fully Restricted'
      accessObj.icon = 'restricted-fully'
      accessObj.theme = 'error'
      break
    default:
      accessObj.label = 'Unknown'
      accessObj.icon = 'restricted-possibly'
      accessObj.theme = 'base-darker'
  }
  return accessObj
}

/** Render JSX element displaying the scannable Organization Name followed by the human-readable date.
 * @param {object} org [Required]     Object of data to be modified and displayed
 * @return JSX
 */
export function returnFormattedOrgNames(org, id, organizationNaId, query) {
  let splitName = []
  let newName = {}
  let name = org.name || org.heading
  if (name) {
    splitName = name
      .replace(/(ca|U|S|St|No|[A-Z]{1})?\./g, function ($0, $1) {
        return $1 ? $0 : '||'
      })
      .split(/\|\|/g)

    newName = splitName.map((str, index) => (
      <span key={org + index} className="display-block text-bold">
        <Highlighter search={query}>{str}</Highlighter>
        {index < splitName.length - 1 && '.'}
      </span>
    ))
    return newName[0] ? (
      <div className="line-height-sans-4">
        {newName}
        {(org.establishDate || org.abolishDate) && (
          <span className="display-block margin-top-1 text-base-darker">
            {returnHumanDate(org.establishDate) || '?'}–
            {returnHumanDate(org.abolishDate)}
          </span>
        )}
        {org.variantOrganizationNames && (
          <Prose>
            <div className="font-sans-2xs text-base-dark text-bold margin-top-1">
              <span className="text-italic margin-right-1">Also known as:</span>
              <ListSeparator
                color="ink"
                distance="2px"
                align="middle"
                delimeter="forward-slash"
              >
                {org.variantOrganizationNames.map((name) => (
                  <span key={name}>{name.replace(/\.$/, '')}</span>
                ))}
              </ListSeparator>
            </div>
          </Prose>
        )}
      </div>
    ) : (
      <span key={org.naId} className="display-block text-bold">
        <Highlighter search={query}>{org.name}</Highlighter>
      </span>
    )
  } else {
    splitName = org
      .replace(/(ca|U|S|St|No|[A-Z]{1})?\./g, function ($0, $1) {
        return $1 ? $0 : '||'
      })
      .split(/\|\|/g)
    splitName.splice(-1, 1)
    newName = splitName.map((str, index) => (
      <span key={org + index} className="display-block text-bold">
        <Highlighter search={query}>{str}</Highlighter>.
      </span>
    ))
    let dates = null
    const dateMatch = org.match(/\((.*?)\)/)
    if (dateMatch) {
      dates = dateMatch[1].split(' - ')
    }

    return newName[0] ? (
      <>
        <Link
          id={`org-link-${id}`}
          to={{
            pathname: `/id/${id}`,
            search: `${
              organizationNaId ? `?organizationNaId=${organizationNaId}` : ''
            }`,
          }}
        >
          <div className="line-height-sans-4">{newName}</div>
        </Link>
        {dates && (
          <span className="text-base-darker margin-top-1 display-block">
            {dates.map((date, index) => (
              <Fragment key={date}>
                {returnHumanDateFromSlashDate(date) ||
                  (index < dates.length - 1 ? '?' : '')}
                {index < dates.length - 1 && '–'}
              </Fragment>
            ))}
          </span>
        )}
      </>
    ) : (
      <Link
        key={org}
        id={`org-link-${id}`}
        to={{
          pathname: `/id/${id}`,
          search: `${
            organizationNaId ? `?organizationNaId=${organizationNaId}` : ''
          }`,
        }}
      >
        <div className="line-height-sans-4">
          <Highlighter search={query}>{org}</Highlighter>
        </div>
      </Link>
    )
  }
}

export const splitOrgs = (object, search) => {
  return (
    <Link to={`/id/${object.naId}`}>
      <span>
        <Highlighter search={search}>{object.heading}</Highlighter>
      </span>
    </Link>
  )
}

/**
 * Return the string used for icon identification and generation based on the text provided
 * @param {string} string [Required] The default string representing the record or media type
 * @return String of the record type.
 */
export function returnValueAndLabelFromString(string) {
  if (!string) return false
  if (typeof string !== 'string') return false

  const stringSwitch = (string) => {
    let type = { label: string, val: string }
    switch (string) {
      case 'Audio/Visual (RealMedia Video Stream)':
        type.label = 'RealMedia Video Stream (.rm)'
        type.val = 'RealMedia Video Stream'
        break
      case 'Audio/Visual File (AVI)':
        type.label = 'AVI File (.avi)'
        type.val = 'avi'
        break
      case 'Audio/Visual File (MOV)':
        type.label = 'MOV File (.mov)'
        type.val = 'mov'
        break
      case 'Audio/Visual File (MP4)':
        type.label = 'MP4 File (.mp4)'
        type.val = 'mp4'
        break
      case 'Audio/Visual File (MPG)':
        type.label = 'MPG File (.mpg)'
        type.val = 'mpg'
        break
      case 'Audio/Visual File (WMV)':
        type.label = 'WMV File (.wmv)'
        type.val = 'wmv'
        break
      case 'ASCII Text':
        type.label = 'ASCII Text (.txt)'
        type.val = 'ascii'
        break
      case 'EBCDIC Binary':
        type.label = 'EBCDIC Binary File'
        type.val = 'ebcdic binary'
        break
      case 'EBCDIC Text':
        type.label = 'EBCDIC Text File'
        type.val = 'ebcdic text'
        break
      case 'Microsoft Word Document':
        type.label = 'Microsoft Word Document (.doc, .docx)'
        type.val = 'Word'
        break
      case 'Microsoft Excel Spreadsheet':
        type.label = 'Microsoft Excel Spreadsheet (.xls, .xlsx)'
        type.val = 'Excel'
        break
      case 'Microsoft PowerPoint Document':
        type.label = 'Microsoft PowerPoint Document (.ppt)'
        type.val = 'PowerPoint'
        break
      case 'Microsoft Publisher Document':
        type.label = 'Microsoft Publisher Document (.pub)'
        type.val = 'Publisher'
        break
      case 'Microsoft Write Document':
        type.label = 'Microsoft Write Document (.wri)'
        type.val = 'write'
        break
      case 'Rich Text Format (RTF)':
        type.label = 'Rich Text Format (.rtf)'
        type.val = 'rtf'
        break
      case 'Comma-separated values (CSV)':
        type.label = 'Text Document, Comma-Separated Values (.csv)'
        type.val = 'csv'
        break
      case 'XML - Extensible Markup Language':
        type.label = '"Extensible Markup Language" (.xml)'
        type.val = 'xml'
        break
      case 'Image (BMP)':
        type.label = 'Bitmap Image (.bmp)'
        type.val = 'bmp'
        break
      case 'Image (GIF)':
        type.label = 'GIF Image (.gif)'
        type.val = 'gif'
        break
      case 'Image (JPG)':
        type.label = 'JPEG Image (.jpg, .jpeg)'
        type.val = 'jpg'
        break
      case 'Image (JP2)':
        type.label = 'JPEG 2000 Image (.jp2)'
        type.val = 'jp2'
        break
      case 'Image (PNG)':
        type.label = 'PNG Image (.png)'
        type.val = 'png'
        break
      case 'Image (TIFF)':
        type.label = 'TIFF Image (.tiff)'
        type.val = 'tiff'
        break
      case 'Image (DNG)':
        type.label = 'DNG Image (.dng)'
        type.val = 'dng'
        break
      case 'Sound File (MP3)':
        type.label = 'MP3 Sound File (.mp3)'
        type.val = 'mp3'
        break
      case 'Sound File (WAV)':
        type.label = 'WAV Sound File (.wav)'
        type.val = 'wav'
        break
      case 'Portable Document File (PDF)':
        type.label = 'Portable Document Format (.pdf)'
        type.val = 'pdf'
        break
      case 'ZIP':
        type.label = 'Compressed File (.zip)'
        type.val = 'zip'
        break
      case 'HTML':
        type.label = 'Web Page (.html, .htm)'
        type.val = 'html'
        break
    }
    return type
  }
  return stringSwitch(string)
}
