import React, { useEffect, useState } from 'react'
import useHttp from '../../hooks/use-http'
import marked from 'marked'
import { SERVER_URL } from '../settings/globals'
import '../Account/restricted/announcements.scss'

const Banner = () => {
  const [data, setData] = useState([])
  const handleAnnouncmentResponse = (resp) => {
    const { announcements } = resp
    setData(announcements)
  }

  const { sendRequest } = useHttp(
    {
      url: `${SERVER_URL}/proxy/announcements/latest`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleAnnouncmentResponse
  )

  useEffect(() => {
    const announcements = document.querySelectorAll('.announcement-banner a')
    if (announcements)
      announcements.forEach((item) => {
        item.setAttribute('target', '_blank')
        item.classList.add('icon-popout-window-white')
      })
  }, [data])

  useEffect(() => {
    sendRequest()
  }, [])

  const banners = data.map((banner, index) => {
    return (
      <div className="announcement-banner" key={banner.announcement_id}>
        <div
          dangerouslySetInnerHTML={{
            __html: marked(banner.announcement_text),
          }}
        />
        {}
      </div>
    )
  })

  return <div>{data && data.length > 0 && <div>{banners}</div>}</div>
}

export default Banner
