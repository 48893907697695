import React, { useEffect, useState } from 'react'
import BannerModal from '../BannerModal/BannerModal'
import PropTypes from 'prop-types'
import Pager from '../pager/pagination'
import useSort from '../../hooks/useSort'
import usePager from '../../hooks/usePager'
import useModal from '../../hooks/use-modal'

import './data-grid.scss'

const DataGrid = ({
  data,
  actions,
  displayHeaders,
  showAdmin,
  prev,
  next,
  setLimit,
  limit,
  getAnnouncements,
  hasNext,
  hasPrev,
  setSortBy,
  setOrderBy,
  displayPager,
  showSort,
}) => {
  // const navigate = useNavigate()
  const { sortAsc, sortDesc } = useSort()
  // const [updatedData] = useState<{}[][]>([data])
  // const [intersects] = useState(displayHeaders)
  const [headers, setheaders] = useState({})
  const [modded, setModded] = useState([])
  const {
    pager,
    firstPage,
    lastPage,
    setPaged,
    paged,
    setCurrentIndex,
    setPageSize,
    pageSize,
    currentIndex,
    ssrPager,
  } = usePager()

  const { isShowing, toggle } = useModal()
  useEffect(() => {
    configDataSet()
  }, [data])

  useEffect(() => {
    if (modded.length > 0) {
      const result = ssrPager(modded)
      setPaged(result)
    }
  }, [modded, pageSize])

  const configDataSet = () => {
    // @ts-ignore
    const result = Object.entries(displayHeaders).reduce((ac, cv) => {
      // @ts-ignore
      ac[cv[0]] = cv[1]
      // @ts-ignore
      return ac
    }, {})

    // @ts-ignore
    setheaders(result)
    const vals = data
      .map((m) => {
        // @ts-ignore
        const _result = Object.keys(result).reduce((ac, cv) => {
          // @ts-ignore
          ac[cv] = Object.assign(
            {},
            // @ts-ignore
            { ...displayHeaders[cv] },
            // @ts-ignore
            { value: m[cv] }
          )
          return ac
        }, {})
        return _result
      })
      // @ts-ignore
      .reduce((ac, cv) => {
        // @ts-ignore
        return ac.concat(cv)
      }, [])

    // @ts-ignore
    setModded(vals)
  }

  const camelCase = (val) => {
    const result = val
      .toLowerCase()
      .replace(/(?<=[-\s])\D/gi, (c) => {
        return c.toUpperCase()
      })
      .replaceAll(/[-\s]/gi, '')
    return result
  }

  return (
    <div className="data-grid ">
      <BannerModal
        toggle={toggle}
        getAnnouncements={getAnnouncements}
        isShowing={isShowing}
      />

      <Pager
        firstPage={firstPage}
        lastPage={lastPage}
        paged={paged.length}
        pages={paged.length}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        pageSize={setPageSize}
        prev={prev}
        next={next}
        hasPrev={hasPrev}
        hasNext={hasNext}
        setLimit={setLimit}
        displayPager={displayPager}
      ></Pager>
      {displayPager && (
        <div className="search-caption">
          <div className="admin-panel">
            <div
              onClick={toggle}
              className="usa-button no-print clickable flex-align-center radius-md display-flex outline-offset-0 width-full padding-105 font-sans-sm usa-button--primary flex-justify-center usa-button--thin text-normal   theme-primary theme-primary--text"
            >
              Add Announcement
            </div>
            {showAdmin}
          </div>
        </div>
      )}
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {Object.values(headers).length > 0 &&
                Object.values(headers).map((c, idx) => {
                  // @ts-ignore
                  if (c['isVisible'] === true) {
                    return (
                      <th key={idx}>
                        <div className="header">
                          {/* @ts-ignore */}
                          {c['label']}
                          <div
                            className={`sort ${camelCase(
                              c['label'].toLowerCase()
                            )}`}
                          >
                            {showSort && (
                              <div
                                onClick={(e) => {
                                  setOrderBy('asc')
                                  setSortBy(c['sort'])
                                  // setModded([...sortAsc(c['sort'], modded)])
                                }}
                              >
                                &uarr;
                              </div>
                            )}
                            {showSort && (
                              <div
                                onClick={(e) => {
                                  setOrderBy('desc')
                                  setSortBy(c['sort'])
                                  // setModded([...sortDesc(c['sort'], modded)])
                                }}
                              >
                                &darr;
                              </div>
                            )}
                          </div>
                        </div>
                      </th>
                    )
                  }
                })}
            </tr>
          </thead>
          <tbody>
            {paged.length > 0 &&
              // Object.values(paged[currentIndex]).map((row, idx) => {
              Object.values(paged).map((row, idx) => {
                return (
                  <tr key={idx} data-announcment={JSON.stringify(row)}>
                    {Object.keys(displayHeaders).map((item, idx) => {
                      if (row[item]['isVisible']) {
                        return (
                          <>
                            <td data-meta-info={row[item]['value']} key={idx}>
                              {row[item].formatter()}
                            </td>
                          </>
                        )
                      }
                    })}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

DataGrid.defaultProps = {
  data: [],
  actions: '',
  displayHeaders: '',
  showAdmin: false,
  prev: () => {},
  next: () => {},
  updateLimit: () => {},
  limit: 20,
  setLimit: 0,
  getAnnouncements: () => {},
  hasNext: false,
  hasPrev: false,
  setSortBy: () => {},
  setOrderBy: () => {},
  displayPager: true,
  showSort: true,
}

DataGrid.propTypes = {
  data: PropTypes.array,
  actions: PropTypes.func,
  displayHeaders: PropTypes.string,
  showAdmin: PropTypes.bool,
  prev: PropTypes.func,
  next: PropTypes.func,
  limit: PropTypes.number,
  setLimit: PropTypes.number,
  getAnnouncements: PropTypes.func,
  hasNext: PropTypes.bool,
  hasPrev: PropTypes.bool,
  setSortBy: PropTypes.func,
  setOrderBy: PropTypes.func,
  displayPager: PropTypes.bool,
  showSort: PropTypes.bool,
}

export default DataGrid
