import React, { Fragment, useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../../contexts/RecordContext'
//Components
import { Icon } from '../../Icon/Icon'
import { IconLabel } from '../../IconLabel/IconLabel'
import { ListSeparator } from '../../ListSeparator/ListSeparator'
import { SectionHeading } from '../../SectionHeading/SectionHeading'
//Helpers
import { allFalse } from '../../../helper-functions/eval-functions'
import { compareNumbers } from '../../../helper-functions/sort-functions'
import { convertCamelToSentence } from '../../../helper-functions/string-functions'
import { returnFormattedOrgNames } from '../../../helper-functions/metadata-functions'
import useOrganizations, { returnSortedOrgNames } from './use_organizations'
import SearchContext from '../../../contexts/SearchContext'
import SectionJurisdictions from '../../description/section_jurisdictions'
import SectionProgramAreas from '../../description/section_program_areas'
import SectionPersonalReference from '../../description/section_personal-reference'

export const OrganizationPage = ({ record, ...props }) => {
  const {
    infoText,
    naId,
    naIdsRecieved,
    orgNameCount,
    orgNameMatch,
    orgNames,
    returnRoles,
    searchQuery,
  } = useOrganizations(record)

  return (
    <div>
      <SectionHeading copyLink count={orgNameCount} {...props}>
        {orgNameCount > 1 ? 'Organization Names' : 'Organization Name'}
      </SectionHeading>
      {infoText}
      <div
        className={[
          'display-flex',
          'flex-column',
          'flex-gap-lg',
          'width-full',
        ].join(' ')}
      >
        <ul className="add-list-reset">
          {orgNames.map((org, index) => (
            <Fragment key={org.heading + '-' + index}>
              {/*Display name*/}
              <li
                className={['margin-bottom-3', 'display-flex', 'flex-row'].join(
                  ' '
                )}
              >
                <div
                  className={[
                    'width-full',
                    index < orgNameCount - 1
                      ? 'border-base-lighter border-bottom-1px padding-bottom-3'
                      : '',
                  ].join(' ')}
                >
                  <div
                    className={[
                      parseInt(orgNameMatch) === parseInt(org.naId)
                        ? 'bg-warning-light-opacity-50 padding-2'
                        : '',
                    ].join(' ')}
                    id={org.naId}
                  >
                    {returnFormattedOrgNames(org, null, null, searchQuery)}
                    {!allFalse(org.role) && (
                      <div
                        className="margin-top-1"
                        data-testid="nac-organizations__roles"
                      >
                        <IconLabel
                          align="start"
                          color="primary"
                          iconName="name-tag"
                          iconSize="sm"
                        >
                          <ListSeparator color="ink" size="2xl" align="middle">
                            {returnRoles(org.role).map((role) => (
                              <Link
                                to={{
                                  pathname: '/search',
                                  search: `q=record.${
                                    role === 'reference' ? 'subject' : role
                                  }s.naId:${org.naId}`,
                                }}
                                className="font-sans-xs"
                                key={role}
                              >
                                {returnRole(role)}{' '}
                                {org.linkCounts &&
                                  `(${
                                    org.linkCounts[role] ||
                                    org.linkCounts.subject
                                  })`}
                              </Link>
                            ))}
                          </ListSeparator>
                        </IconLabel>
                      </div>
                    )}
                  </div>
                  {org.predecessors && (
                    <div
                      className={[
                        // 'border-base-lighter',
                        // 'border-left-1px',
                        'margin-left-1',
                        'margin-top-4',
                        'margin-bottom-2',
                        'padding-left-3',
                      ].join(' ')}
                      data-testid="nac-organizations__predecessors"
                    >
                      <span className="display-block font-sans-xs margin-y-1 text-base-dark">
                        Preceded By
                        {org.predecessors.length > 1 &&
                          ` (${org.predecessors.length})`}
                      </span>
                      <ul className={['add-list-reset'].join(' ')}>
                        {org.predecessors.map((predecessor, index) => (
                          <li
                            key={predecessor.heading}
                            className={[
                              'display-flex',
                              'flex-column',
                              'margin-y-2',
                              'padding-bottom-2',
                              'width-full',
                            ].join(' ')}
                          >
                            <div className="width-full">
                              {naIdsRecieved &&
                                naIdsRecieved.map((recieved) => {
                                  if (predecessor.naId === recieved.child) {
                                    predecessor.naId = recieved.parent
                                    predecessor.organizationNaId =
                                      recieved.child
                                  }
                                })}
                              {returnFormattedOrgNames(
                                predecessor.heading,
                                predecessor.naId,
                                predecessor.organizationNaId,
                                searchQuery
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {org.successors && (
                    <div
                      className={[
                        // 'border-base-lighter',
                        // 'border-left-1px',
                        'margin-left-1',
                        'margin-top-4',
                        'margin-bottom-2',
                        'padding-left-3',
                      ].join(' ')}
                      data-testid="nac-organizations__successors"
                    >
                      <span className="display-block font-sans-xs margin-y-1 text-base-dark">
                        Succeeded By
                        {org.successors.length > 1 &&
                          ` (${org.successors.length})`}
                      </span>
                      <ul className={['add-list-reset'].join(' ')}>
                        {org.successors.map((successor, index) => (
                          <li
                            key={successor.heading}
                            className={[
                              'display-flex',
                              'flex-column',
                              'margin-y-2',
                              'padding-bottom-2',
                              'width-full',
                              index + 1 < org.successors.length
                                ? 'border-base-lighter border-bottom-1px padding-bottom-3'
                                : '',
                            ].join(' ')}
                          >
                            <div className="width-full">
                              {naIdsRecieved &&
                                naIdsRecieved.map((recieved) => {
                                  if (successor.naId === recieved.child) {
                                    successor.naId = recieved.parent
                                    successor.organizationNaId = recieved.child
                                  }
                                })}
                              {returnFormattedOrgNames(
                                successor.heading,
                                successor.naId,
                                successor.organizationNaId,
                                searchQuery
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </li>
            </Fragment>
          ))}
        </ul>

        {record.personalReferences && (
          <SectionPersonalReference
            data-testid="nac-section__personal-references"
            id="personal-references"
          />
        )}
        {record.jurisdictions && (
          <SectionJurisdictions
            data-testid="nac-section__jurisdictions"
            id="jurisdictions"
          />
        )}
        {record.programAreas && (
          <SectionProgramAreas
            data-testid="nac-section__program-areas"
            id="program-areas"
          />
        )}
      </div>
    </div>
  )
}

OrganizationPage.defaultProps = {}

OrganizationPage.propTypes = {
  record: PropTypes.object.isRequired,
}

export const returnRole = (role) => {
  switch (role) {
    case 'contributor':
      return 'Archival Descriptions to which this Organization Contributed'
    case 'creator':
      return 'Archival Descriptions Created by this Organization'
    case 'donor':
      return 'Archival Descriptions Donated by this Organization'
    case 'reference':
      return 'Archival Descriptions that Reference this Organization'
  }
}
