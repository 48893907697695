import React, { useState, useEffect, useContext, useRef } from 'react'
import Modal, { ModalBody, ModalFooter } from '../Modal/Modal'
import { SERVER_URL } from '../settings/globals'
import PropTypes, { bool } from 'prop-types'
import { Button } from '../Button/Button'
import UserContext from '../../contexts/UserContext'
import useModal from '../../hooks/use-modal'
import useHttp from '../../hooks/use-http'
import DOMPurify from 'dompurify'
import './BannerModal.scss'
import Markdown from '../Markdown/Markdown'

const BannerModal = ({
  userId,
  toggle,
  data,
  announcement,
  edit,
  isShowing,
  getAnnouncements,
}) => {
  useEffect(() => {
    if (data.length && announcement) {
      const initialData = data.find(
        (row) => row.announcement_id === announcement.value
      )
      setId(initialData?.announcement_id)
      setSavedText(initialData?.announcement_text)
      setNoteText(initialData?.notes)

      setPrevSavedText(initialData?.announcement_text)
      setPrevNoteText(initialData?.notes)
    } else {
      setId('')
      setSavedText('')
      setNoteText('')
    }
  }, [data, announcement])

  const closeRef = useRef(null)

  const [prevSavedText, setPrevSavedText] = useState('')
  const [prevNoteText, setPrevNoteText] = useState('')

  const [savedText, setSavedText] = useState('')
  const [noteText, setNoteText] = useState('')
  const [id, setId] = useState('')

  const { loggedInUser = '' } = useContext(UserContext) || {}

  let putData = ''
  if (edit) {
    putData = `\\${id}`
  }

  const { sendRequest } = useHttp(
    {
      url: `${SERVER_URL}/proxy/announcements${putData}`,
      method: edit ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: {
        announcement_text: DOMPurify.sanitize(savedText),
        notes: noteText,
        user_id: loggedInUser.userId,
      },
    },
    () => {
      getAnnouncements()
      closeRef.current.click()
      useEffect(() => {
        setId('')
        setSavedText('')
        setNoteText('')
      })
    }
  )

  const title = edit ? 'Update' : 'Create'

  return (
    <>
      {isShowing && (
        <div className="announcement-modal" id={`external-link`} size="lg">
          <ModalBody id={`external-url`}>
            <div
              className={[
                'display-flex',
                'flex-column',
                'flex-align-start',
                'flex-gap',
                'theme-white',
              ].join(' ')}
              data-testid={`link-external__modal`}
            >
              <div className="announcement-title"> {title} Banner </div>
              <div className="announcement-text">
                <div>Announcement Text:</div>
                <textarea
                  className="announcement-textarea"
                  value={savedText}
                  onChange={(e) => {
                    setSavedText(e.target.value)
                  }}
                ></textarea>
              </div>
            </div>
            <div
              className={[
                'display-flex',
                'flex-column',
                'flex-align-start',
                'flex-gap',
                'theme-white',
              ].join(' ')}
              data-testid={`link-external__modal`}
            >
              <div className="announcement-text">
                Notes:
                <textarea
                  className="announcement-textarea"
                  value={noteText}
                  onChange={(e) => {
                    setNoteText(e.target.value)
                  }}
                ></textarea>
              </div>
            </div>
          </ModalBody>
          <Markdown />
          <ModalFooter hide={toggle}>
            <div className={['flex-row', 'grid-row', 'flex-justify'].join(' ')}>
              <Button
                className={[''].join(' ')}
                data-testid={`link-external__close-modal`}
                onClick={(e) => {
                  toggle(e)
                  setId('')
                  setSavedText(prevNoteText)
                  setNoteText(prevSavedText)
                }}
                outline
                ref={closeRef}
              >
                Exit
              </Button>
              <Button
                onClick={() => {
                  setPrevNoteText(noteText)
                  setPrevSavedText(savedText)
                  sendRequest()
                }}
                className={[''].join(' ')}
              >
                Save
              </Button>
            </div>
          </ModalFooter>
        </div>
      )}
    </>
  )
}

BannerModal.defaultProps = {
  announcement: '',
  data: {},
  userId: '',
  edit: false,
  toggle: () => {},
  isShowing: false,
  getAnnouncements: () => {},
}

BannerModal.propTypes = {
  announcement: PropTypes.any,
  data: PropTypes.object,
  userId: PropTypes.string,
  edit: PropTypes.bool,
  toggle: () => {},
  isShowing: PropTypes.bool,
  getAnnouncements: PropTypes.func,
}

export default BannerModal
