//COLOR PROP TYPES
export const ColorsOnDark = [
  'primary-light',
  'primary-lighter',
  'secondary-light',
  'secondary-lighter',
  'accent-cool-light',
  'accent-cool-lighter',
  'accent-warm-light',
  'accent-warm-lighter',
  'base-light',
  'base-lighter',
  'base-lightest',
  'white',
  'gray-5',
  'gray-10',
  'success-light',
  'error-light',
  'warning',
  'warning-light',
]

export const ColorPropTypes = [
  // 'accent-cool',
  // 'accent-cool-dark',
  'accent-cool-darker',
  'accent-cool-light',
  // 'accent-cool-lighter',
  'accent-warm',
  'accent-warm-dark',
  // 'accent-warm-darker',
  // 'accent-warm-light',
  'accent-warm-lighter',
  'base',
  'base-dark',
  'base-darker',
  'base-darkest',
  'base-light',
  'base-lighter',
  'base-lightest',
  // 'black',
  'error',
  // 'error-light',
  // 'error-lighter',
  'error-dark',
  // 'error-darker',
  'emergency',
  // 'emergency-dark',
  // 'gray-5',
  // 'gray-10',
  // 'gray-30',
  // 'gray-50',
  // 'gray-70',
  // 'gray-90',
  'ink',
  'primary',
  'primary-dark',
  'primary-darker',
  'primary-light',
  'primary-lighter',
  'primary-vivid',
  // 'secondary',
  // 'secondary-dark',
  // 'secondary-darker',
  // 'secondary-light',
  // 'secondary-lighter',
  // 'secondary-vivid',
  // 'success',
  'success-lighter',
  'success-light',
  'success-dark',
  'success-darker',
  'warning',
  'warning-light',
  'warning-dark',
  'white',
  '',
]
