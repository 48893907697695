/**
 * Schema for building and validating start date inputs
 */

export let yearOptions = [
  {
    isPlaceholder: true,
    id: 'year-isDefault',
    label: 'YYYY',
    value: '',
  },
]
const currentYear = new Date().getFullYear()
let startYear = 1700
while (startYear <= currentYear) {
  yearOptions.push({
    id: `year-${startYear}`,
    label: startYear.toString(),
    value: startYear.toString(),
  })
  startYear++
}

export const monthOptions = [
  {
    isPlaceholder: true,
    id: 'month-isDefault',
    label: 'MM',
    value: '',
  },
  {
    id: 'month-jan-1',
    label: '01 - January',
    order: 1,
    value: '01',
    days: 31,
  },
  {
    id: 'month-feb-2',
    label: '02 - February',
    value: '02',
    order: 2,
    days: 29,
  },
  {
    id: 'month-mar-3',
    label: '03 - March',
    value: '03',
    order: 3,
    days: 31,
  },
  {
    id: 'month-apr-4',
    label: '04 - April',
    value: '04',
    order: 4,
    days: 30,
  },
  {
    id: 'month-may-5',
    label: '05 - May',
    value: '05',
    order: 5,
    days: 31,
  },
  {
    id: 'month-jun-6',
    label: '06 - June',
    value: '06',
    order: 6,
    days: 30,
  },
  {
    id: 'month-jul-7',
    label: '07 - July',
    value: '07',
    order: 7,
    days: 31,
  },
  {
    id: 'month-aug-8',
    label: '08 - August',
    value: '08',
    order: 8,
    days: 31,
  },
  {
    id: 'month-sep-9',
    label: '09 - September',
    value: '09',
    order: 9,
    days: 30,
  },
  {
    id: 'month-oct-10',
    label: '10 - October',
    value: '10',
    order: 10,
    days: 31,
  },
  {
    id: 'month-nov-11',
    label: '11 - November',
    value: '11',
    order: 11,
    days: 30,
  },
  {
    id: 'month-dec-12',
    label: '12 - December',
    value: '12',
    order: 12,
    days: 31,
  },
]

export let dayOptions = [
  {
    isPlaceholder: true,
    id: 'day-isDefault',
    label: 'DD',
    value: '',
  },
]
let startDay = 1
while (startDay <= 31) {
  dayOptions.push({
    id: `day-${startDay}`,
    label: `${startDay < 10 ? '0' : ''}${startDay.toString()}`,
    order: startDay,
    value: `${startDay < 10 ? '0' : ''}${startDay.toString()}`,
  })
  startDay++
}

export const dateYearSchema = {
  allow: ['string'],
  id: 'year',
  label: 'Year',
  options: yearOptions,
  maxLength: 4,
  minLength: 4,
  multiSelect: false,
  searchable: true,
  type: 'select',
}

export const dateMonthSchema = {
  allow: ['string'],
  id: 'month',
  label: 'Month',
  options: monthOptions,
  maxLength: 9,
  minLength: 3,
  multiSelect: false,
  searchable: true,
  requires: ['year'],
  type: 'select',
}

export const dateDaySchema = {
  allow: ['string'],
  id: 'day',
  label: 'Day',
  options: dayOptions,
  maxLength: 2,
  minLength: 1,
  multiSelect: false,
  searchable: true,
  requires: ['year', 'month'],
  type: 'select',
}

export const dateSearchSchema = {
  allow: ['date'],
  autoComplete: false,
  examples: ['2020', '2020 July', '2020 July, 4'],
  id: 'startDate',
  label: 'Exact Date',
  options: [dateYearSchema, dateMonthSchema, dateDaySchema],
  errorLabel: 'date',
  notEmpty: true,
  selectable: true,
  sr: 'Date input field',
  type: 'select',
}

export const exactDateSearchSchema = {
  allow: ['exactDate'],
  autoComplete: false,
  examples: ['2002 / 08 - August / 08', '2020 / 05 - May / 04'],
  id: 'exactDate',
  label: 'Exact Date',
  options: [dateYearSchema, dateMonthSchema, dateDaySchema],
  errorLabel: 'date',
  selectable: true,
  sr: 'Exact date input field',
  type: 'date',
}

export const startDateSearchSchema = {
  allow: ['date'],
  autoComplete: false,
  id: 'startDate',
  label: 'From',
  options: [dateYearSchema, dateMonthSchema, dateDaySchema],
  errorLabel: 'date',
  notEmpty: true,
  selectable: true,
  sr: 'Start date input field',
  type: 'date',
}

export const endDateSearchSchema = {
  allow: ['date'],
  autoComplete: false,
  examples: ['2020', '2020 / 07 - July', '2020 / 07 July / 04'],
  id: 'endDate',
  label: 'To',
  options: [dateYearSchema, dateMonthSchema, dateDaySchema],
  errorLabel: 'date',
  notEmpty: true,
  selectable: true,
  sr: 'End date input field',
  type: 'date',
}

export const recurringDateSchema = {
  allow: ['recurringDate'],
  autoComplete: false,
  examples: ['04 - April / 01', '07 - July /', '/ 25'],
  id: 'recurringDate',
  label: 'Recurring Month And/Or Day',
  multiSelect: false,
  options: [dateMonthSchema, dateDaySchema],
  selectable: true,
  sr: 'Recurring date month input field',
  type: 'date',
}

export const dateSearchToggleSchema = {
  allow: ['dateSearchToggle'],
  id: 'dateSearchToggle',
  label: 'Date Search',
  options: [
    {
      id: 'range',
      label: 'Range',
      show: [startDateSearchSchema, endDateSearchSchema],
    },
    {
      id: 'exact',
      label: 'Exact',
      show: [exactDateSearchSchema],
    },
    {
      id: 'recurring',
      label: 'Recurring',
      show: [recurringDateSchema],
    },
  ],
  sr: 'Toggle between date search types',
  type: 'date-search-toggle',
}
