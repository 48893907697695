import React, { Fragment } from 'react'

/**
 * Determine if all values in an object are false
 * @param {object} object Object containing boolean values
 *
 * @return Boolean representing whether all options were false or not.
 */
export function allFalse(object) {
  for (var i in object) {
    if (!!object[i] === true) return false
  }
  return true
}

export const returnMailToJSX = (string) => {
  const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})/g
  const stringArray = string.split(regex)
  let jsxArray = []
  stringArray.map((str) => {
    jsxArray.push([
      <Fragment key={str}>
        {str.match(regex) ? (
          <a key={str} href={`mailto:${str}`}>
            {str}
          </a>
        ) : (
          str
        )}
      </Fragment>,
    ])
  })
  return jsxArray
}

export const returnIfArraysEqual = (a, b) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export const reduceArray = (array) => {
  if (!Array.isArray(array) && !array.length) return false
  var map = {},
    node,
    roots = [],
    i

  for (i = 0; i < array.length; i += 1) {
    map[array[i].contributionId] = i // initialize the map
    array[i].children = [] // initialize the children
  }

  for (i = 0; i < array.length; i += 1) {
    node = array[i]
    if (node.parentContributionId) {
      // if you have dangling branches check that map[node.parentId] exists
      array[map[node.parentContributionId]]?.children.push(node)
    } else {
      roots.push(node)
    }
  }
  return roots
}

export const childrenAreNull = ({ children }) => {
  if (!React.Children.count(children)) return true
}
