import React, { useContext } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
import AccountContext from '../../../contexts/AccountContext'
import Card from '../../Card/Card'
import { IconLabelConditionalDisplay } from '../../IconLabel/variants/IconLabelConditionalDisplay'
import { Table } from '../../Table/Table'
import AccountContent, { ContentTitle } from '../account_content'
import useInsights from './use-insights'
import useAdminInsights from './use-admin-insights'
import { IconLabel } from '../../IconLabel/IconLabel'
import UserContext from '../../../contexts/UserContext'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'

const Insights = () => {
  const accountState = useContext(AccountContext)
  const { accountsAccessArray } = useContext(UserContext)

  const { columns, table } = useInsights(accountState)
  return (
    <AccountContent>
      <ContentTitle iconName="action-bulb-on" title="Insights" />

      <div className="overflow-x-scroll margin-x-neg-2 tablet:margin-x-neg-0 padding-bottom-2 tablet-lg:padding-bottom-0">
        <Card
          className={[
            'padding-0',
            'tablet-lg:padding-0',
            'margin-x-2',
            'tablet-lg:margin-x-0',
          ].join(' ')}
          style={{ minWidth: '36rem' }}
        >
          <Table
            caption={
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'flex-gap-sm',
                  'text-bold',
                ].join(' ')}
              >
                <h2 className="margin-0">Contributions Over Time</h2>
                <IconLabelConditionalDisplay />
              </div>
            }
            className="padding-0"
            columns={columns}
            data={table}
            id="nac-user-contribution-details-table"
          />
        </Card>
      </div>
      {accountsAccessArray?.accessGrantedToAdminAccountDetails && (
        <AdminInsights />
      )}
    </AccountContent>
  )
}

export default Insights

export const LoggedInUserInsights = () => {}

export const AdminInsights = () => {
  const { columns, table } = useAdminInsights()

  return (
    <>
      <hr className={['border-base-lighter'].join(' ')} />
      <div className="overflow-x-scroll margin-x-neg-2 tablet:margin-x-neg-0 padding-bottom-2 tablet-lg:padding-bottom-0">
        <Card
          className={[
            'padding-0',
            'tablet-lg:padding-0',
            'margin-x-2',
            'tablet-lg:margin-x-0',
          ].join(' ')}
          style={{ minWidth: '36rem' }}
        >
          <Table
            caption={
              <div
                className={[
                  'display-flex',
                  'flex-column',
                  'flex-gap-sm',
                  'text-bold',
                ].join(' ')}
              >
                {/**@{userData.userName}&rsquo;s */}
                Contribution Stats by Type
                <IconLabelConditionalDisplay displayTo="moderators" />
              </div>
            }
            className="padding-0"
            columns={columns}
            data={table}
            id="nac-user-contribution-details-table"
          />
        </Card>
      </div>
    </>
  )
}

export const CellNumber = ({ number, ...props }) => {
  return number > -1 ? (
    <span className={[number > 0 ? '' : 'text-base-light'].join(' ')}>
      {returnNumberWithCommas(number)}
    </span>
  ) : (
    <div
      className={['display-flex', 'flex-justify-center', 'width-full'].join(
        ' '
      )}
    >
      <div className={['block-load', 'height-1', 'width-3'].join(' ')}></div>
    </div>
  )
}

CellNumber.propTypes = {
  /**
   * The number to display
   */
  number: PropTypes.number,
}
