/**
 * Schema for validating username input
 */
export const usernameInputSchema = {
  autoComplete: false,
  description: 'Username',
  id: 'changeUsername',
  label: 'Username',
  loggedIn: true,
  maxlength: 32,
  minlength: 2,
  placeholder: 'New Username',
  required: true,
  sr: 'Username input field',
  type: 'text',
  regex: '^[A-Za-z0-9\\s]*$',
}
