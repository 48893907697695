import React, { useContext } from 'react'
import { useOutletContext } from 'react-router-dom'
import '../../../styles/index.scss'
//Context
import AccountContext from '../../../contexts/AccountContext'
//Components
import { StatTotals } from './AccountOverview'
import UniqueTags from './unique_tags'
import { ActivityFeed } from './activity_feed'
import AccountContent, { ContentTitle } from '../account_content'
import Card from '../../Card/Card'

const AccountTags = () => {
  return (
    <AccountContent>
      <ContentTitle iconName="tag" title="Tags" />
      {/* <StatTotals />*/}
      <div className={['display-flex', 'flex-gap-md', 'flex-column'].join(' ')}>
        <UniqueTags />
        <ActivityFeed />
      </div>
    </AccountContent>
  )
}

export default AccountTags
