import React, { Fragment, useMemo } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'

import { IconLabel } from '../IconLabel'
import { returnAllDatesFromRecordData } from '../../../helper-functions/date-functions'
import { ListSeparator } from '../../ListSeparator/ListSeparator'

export const DateLabel = ({ record, theme, ...props }) => {
  if (!record || record.length == 0) return false

  ////DEFINITIONS
  /////////////////////////

  // Inclusive Dates:
  // The time period in which the record was created, maintained, or accumulated by the creator.

  // Coverage Dates:
  // The time period covered by the subject(s) of the record.

  // Production Date:
  // The date a record was produced

  // Broadcast Date:
  // The date a record was broadcast

  // Release Date:
  // The date a record was released

  // Birth Date:
  // The date a person was born

  // Death Date:
  // The date a person died

  // Compile all dates associated with a single record
  const dates = useMemo(() => {
    const dateArray = returnAllDatesFromRecordData(record)
    if (dateArray.length > 0) return dateArray
  }, [record])

  return dates && dates.length > 0 ? (
    <IconLabel
      data-testid="nac-page-header--date"
      iconName="date"
      label=""
      theme={theme}
      {...props}
    >
      <ListSeparator>
        {dates.map((date) => (
          <Fragment key={date.type}>
            {date.label}: {date.date}
          </Fragment>
        ))}
      </ListSeparator>
    </IconLabel>
  ) : (
    false
  )
}

DateLabel.defaultProps = {
  theme: 'base-darker',
}

DateLabel.propTypes = {
  record: PropTypes.object,
  theme: PropTypes.string,
}
