import React, { useContext, useMemo } from 'react'
import '../../styles/index.scss'
//Context
import RecordContext from '../../contexts/RecordContext'
import SearchContext from '../../contexts/SearchContext'
//Components
import { SectionHeading } from '../SectionHeading/SectionHeading'
import { Link } from 'react-router-dom'
import { Icon } from '../Icon/Icon'
import ExpandCollapseList from '../utilities/ExpandCollapseList'
import useHighlightList from '../../hooks/use-highlight-list'
import Highlighter from '../Highlighter/Highlighter'
import { IconLabel } from '../IconLabel/IconLabel'

const SectionPersonalReference = ({ ...props }) => {
  const { record } = useContext(RecordContext) || {}
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  const personalReferences = useMemo(() => {
    if (record.personalReferences) {
      return record.personalReferences
    }
    return false
  }, [record])

  const { sortedArray, count } = useHighlightList(
    q,
    personalReferences,
    ['heading'],
    6
  )

  if (!personalReferences) return false

  return (
    <div
      className={['flex-align-start', 'flex-column', 'width-full'].join(' ')}
    >
      <SectionHeading copyLink count={personalReferences.length} {...props}>
        {personalReferences.length > 1
          ? 'Personal References'
          : 'Personal Reference'}
      </SectionHeading>

      <ul
        className={[
          'add-list-reset',
          'grid-col-6',
          'list-with-margin',
          'width-full',
        ].join(' ')}
      >
        <ExpandCollapseList
          flex={false}
          id="personalReferences-list"
          initial={count}
        >
          {sortedArray.map((item) => (
            <li key={item.naId}>
              <Link
                className={`theme-white--link`}
                data-testid={`nac-personalReferences-link--${item.naId}`}
                to={{
                  pathname: '/id/' + item.naId,
                }}
                state={{
                  controlGroup: '0',
                }}
              >
                <IconLabel
                  align="start"
                  iconSize="sm"
                  iconPercent="80"
                  iconName="person"
                >
                  <span>
                    <Highlighter search={q}>{item.heading}</Highlighter>
                    <span className="usa-sr-only">, </span>
                  </span>
                </IconLabel>
              </Link>
            </li>
          ))}
        </ExpandCollapseList>
      </ul>
    </div>
  )
}

export default SectionPersonalReference
