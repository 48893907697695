import React, { useContext, useEffect, useState } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import Prose from '../utilities/Prose'
import { Icon } from '../Icon/Icon'
import SearchContext from '../../contexts/SearchContext'
import Highlighter from '../Highlighter/Highlighter'

/**
 * Access Restriction alert users if the access of the materials is restricted.
 *
 * Access Restriction Note: https://www.archives.gov/research/catalog/lcdrg/elements/accessnote.html
 * Specific Access Restriction: https://www.archives.gov/research/catalog/lcdrg/elements/specificaccess.html
 * Access Restriction Status: https://www.archives.gov/research/catalog/lcdrg/elements/access.html
 *
 * NOTE: According to stakeholders, this data is problematic as we
 * cannot say with confidence that it is accurate for any given record.
 */

const SectionAccessRestriction = ({ data, ...props }) => {
  const { record } = useContext(RecordContext)
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  // Prevent the original response from being mutated
  const [accessRestriction, setAccessRestriction] = useState(null)

  // Mutate the array to include details from the accessRestrictionAuthorityList array
  useEffect(() => {
    if (!data && (!record || !record.accessRestriction)) return false
    let t = null,
      d = null,
      i = null,
      restriction = data || record.accessRestriction
    accessRestrictionAuthorityList.some(
      ({ status, description, icon, title }) => {
        if (restriction.status == status) {
          if (description) {
            d =
              restriction.specificAccessRestrictions && description
                ? `${
                    restriction.specificAccessRestrictions.length > 1
                      ? `${description} for the following reasons:`
                      : `${description} for the following reason:`
                  }`
                : `${description}.`
          }
          t = title
          i = icon
        }
      }
    )
    restriction.title = t
    restriction.description = d
    restriction.icon = i
    setAccessRestriction(restriction)
  }, [record])

  return accessRestriction ? (
    <div
      className={['display-flex', 'flex-column', 'flex-gap-xs'].join(' ')}
      {...props}
    >
      <div
        className={[
          'display-flex',
          'flex-row',
          'flex-gap-sm',
          'width-full',
        ].join(' ')}
      >
        <Icon
          color="base-darker"
          iconName={accessRestriction.icon}
          iconPercent="80"
          iconSize="sm"
          strokeThickness="thick"
        />
        <div
          className={[
            'display-flex',
            'flex-column',
            'flex-gap-sm',
            'padding-top-2px',
          ].join(' ')}
        >
          <h2 className={['text-base-darker', 'margin-bottom-0'].join(' ')}>
            {' '}
            Access:{' '}
            <Highlighter search={q}>{accessRestriction.title}</Highlighter>
            <span className="usa-sr-only">, </span>
          </h2>
        </div>
      </div>
      <div className="margin-left-4">
        {accessRestriction.description && (
          <Prose
            className="margin-bottom-1"
            data-testid="nac-description__access--description"
          >
            {accessRestriction.description}
          </Prose>
        )}
        {accessRestriction.specificAccessRestrictions && (
          <ul className={['margin-left-2'].join(' ')}>
            {accessRestriction.specificAccessRestrictions.map(
              ({ restriction, securityClassification }) => (
                <li
                  key={restriction}
                  className={[
                    'nac-list-icon',
                    'nac-list-icon--base-darker',
                    'nac-list-icon--asterisk',
                    //
                    'font-sans-xs',
                    'margin-bottom-1',
                  ].join(' ')}
                >
                  <Prose data-testid="nac-description__access--specific">
                    <Highlighter search={q}>{restriction}</Highlighter>
                  </Prose>
                  <Prose
                    className={[
                      'display-block',
                      'font-sans-3xs',
                      'text-italic',
                      'width-full',
                    ].join(' ')}
                    data-testid="nac-description__access--classification"
                  >
                    <Highlighter search={q}>
                      {securityClassification}
                    </Highlighter>
                  </Prose>
                  <span className="usa-sr-only">, </span>
                </li>
              )
            )}
          </ul>
        )}
        {accessRestriction.note && (
          <Prose data-testid="nac-description__access--note">
            <Highlighter search={q}>{accessRestriction.note}</Highlighter>
          </Prose>
        )}
      </div>
    </div>
  ) : (
    ''
  )
}

SectionAccessRestriction.defaultProps = {
  data: null,
}

SectionAccessRestriction.propTypes = {
  /**
   * Access Restriction data object which can be optionally provided
   * for display outside of the regular description page flow,
   * such as on a help page.
   *
   * This array structure matches the examples provided in the
   * accessRestrictionAuthorityList array
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      useRestriction: PropTypes.shape({
        note: PropTypes.string,
        specificAccessRestrictions: PropTypes.arrayOf(
          PropTypes.shape({
            // https://www.archives.gov/research/catalog/lcdrg/authority_lists/specificaccesslist.html
            restriction: PropTypes.oneOf([
              'Donated - Security Classified',
              'Donated - Statute',
              'Donor Restricted',
              'Executive Privilege',
              'Freedom of Information Act (FOIA)',
              'FOIA (b)(1) National Security',
              'FOIA (b)(2) Internal Personnel Rules and Practices',
              'FOIA (b)(3) Statute',
              'FOIA (b)(4) Trade Secrets and Commercial or Financial Information',
              'FOIA (b)(5) Inter-agency or Intra-agency Memorandums or Letters Not Available by Law',
              'FOIA (b)(6) Personal Information',
              'FOIA (b)(7) Law Enforcement',
              'FOIA (b)(7a) Law Enforcement',
              'FOIA (b)(7b) Law Enforcement',
              'FOIA (b)(7c) Law Enforcement',
              'FOIA (b)(7d) Law Enforcement',
              'FOIA (b)(7e) Law Enforcement',
              'FOIA (b)(7f) Law Enforcement',
              'FOIA (b)(8) Regulation or Supervision of Financial Institutions',
              'FOIA (b)(9) Geological or Geophysical Information and Data',
              'House Rule',
              'John F. Kennedy Assassination Records Collection Act',
              'Presidential Records Act (p)(1) National Security Classified',
              'Presidential Records Act (p)(2) Appointments to Federal Office',
              'Presidential Records Act (p)(3) Statute',
              'Presidential Records Act (p)(4) Trade Secrets and Commercial or Financial Information',
              'Presidential Records Act (p)(5) Confidential Communications',
              'Presidential Records Act (p)(6) Personal Privacy',
              'Presidential Records Act (PRA)',
              'Presidential Recordings and Materials Preservation Act of 1974 (PRMPA)',
              'PRM - Personal Record Misfile',
              'PRMPA - Individual Rights Pending (C)',
              'PRMPA - Investigatory Information (F)',
              'PRMPA - National Security Classified (B)',
              'PRMPA - Non-Historical Information (H)',
              'PRMPA - Personal Information (G)',
              'PRMPA - Personal Privacy (D)',
              'PRMPA - Statute (A)',
              'PRMPA - Trade Secrets and Commercial or Financial Information (E)',
              'Senate',
              'Other',
            ]),
            // https://www.archives.gov/research/catalog/lcdrg/authority_lists/securitylist.html
            securityClassification: PropTypes.oneOf([
              'Confidential',
              'Restricted Data/Formerly Restricted Data',
              'Secret',
              'Top Secret',
              'Unmarked',
            ]),
          })
        ),
        // https://www.archives.gov/research/catalog/lcdrg/authority_lists/accesslist.html
        status: PropTypes.oneOf([
          'Restricted - Fully',
          'Restricted - Partly',
          'Restricted - Possibly',
          'Undetermined',
          'Unrestricted',
        ]),
      }),
    })
  ),
}

export default SectionAccessRestriction

/**
 * Access Restriction details for use with contextual help and display
 *
 * NOTE: The status (which comes from DAS) is different from
 * the display title.
 *
 * NOTE: The description is informed by the documentation on Archives.gov
 * with some modifications
 */
export const accessRestrictionAuthorityList = [
  // Restricted - Fully
  {
    description: 'Access to all of these archival materials is restricted',
    example: {
      naId: 20014425,
      accessRestriction: {
        note: 'This series has not completed the declassification process. In order to access the documents, the research must file a FOIA request.',
        specificAccessRestrictions: [
          {
            restriction: 'FOIA (b)(1) National Security',
            securityClassification: 'Confidential',
          },
        ],
        status: 'Restricted - Fully',
      },
    },
    icon: 'locked',
    status: 'Restricted - Fully',
    title: 'Fully Restricted',
  },
  // Restricted - Partly
  {
    description: 'Access to some of these archival materials is restricted',
    example: {
      naId: 23812513,
      accessRestriction: {
        note: '50 U.S.C. 403g',
        specificAccessRestrictions: [
          {
            restriction: 'FOIA (b)(1) National Security',
            securityClassification: 'Secret',
          },
          {
            restriction: 'FOIA (b)(3) Statute',
          },
          {
            restriction: 'FOIA (b)(6) Personal Information',
          },
          {
            restriction: 'Presidential Records Act (p)(6) Personal Privacy',
          },
          {
            restriction: 'FOIA (b)(7c) Law Enforcement',
          },
          {
            restriction: 'FOIA (b)(7e) Law Enforcement',
          },
          {
            restriction: 'FOIA (b)(7f) Law Enforcement',
          },
          {
            restriction: 'PRM - Personal Record Misfile',
          },
        ],
        status: 'Restricted - Partly',
      },
    },
    icon: 'unlocked-asterisk',
    status: 'Restricted - Partly',
    title: 'Partially Restricted',
  },
  // Restricted - Possibly
  {
    description: 'Access to these archival materials may be restricted',
    example: {
      naId: 24496909,
      accessRestriction: {
        note: "This file unit might contain documents restricted in accordance with applicable executive order(s), which governs National Security policies, applicable statutes/agency restrictions, and material which has been closed in accordance with the donor's deed of gift.",
        specificAccessRestrictions: [
          {
            restriction: 'Donated - Security Classified',
            securityClassification: 'Top Secret',
          },
        ],
        status: 'Restricted - Possibly',
      },
    },
    icon: 'unlocked-question',
    status: 'Restricted - Possibly',
    title: 'Possibly Restricted',
  },
  // Undetermined
  {
    // description: 'It is unknown if the archival materials are restricted',
    // description: 'Access to these archival materials is unclear at this time',
    example: {
      naId: 23812670,
      accessRestriction: {
        note: 'This series contains unprocessed materials and may have access restrictions. Unprocessed materials are not made available to researchers.',
        status: 'Undetermined',
      },
    },
    icon: 'unlocked-question',
    status: 'Undetermined',
    title: 'Undetermined',
  },
  // Unrestricted
  {
    example: {
      naId: 24496682,
      accessRestriction: {
        status: 'Unrestricted',
      },
    },
    icon: 'unlocked',
    status: 'Unrestricted',
    title: 'Unrestricted',
  },
]

/**
 * Specific Access Restriction descriptions for use with contextual help display
 *
 * NOTE: The description is informed by the documentation on Archives.gov
 * with some modifications
 * https://www.archives.gov/research/catalog/lcdrg/authority_lists/specificaccesslist.html
 */
export const specificAccessRestrictionAuthorityList = [
  {
    label: 'Donated - Security Classified',
    description:
      'Indicates these archival materials were donated with national security restrictions.',
  },
  {
    label: 'Donated - Statute',
    description:
      'Indicates these archival materials were donated with statutory restrictions.',
  },
  {
    label: 'Donor Restricted',
    description:
      'Indicates that these archival materials include access restrictions from the donor.',
  },
  {
    label: 'Executive Privilege',
    description:
      'Indicates that these archival materials are presidential or vice presidential records for which a claim of Executive Privilege has been invoked by the incumbent President or a former President or Vice President.',
  },
  {
    label: 'Freedom of Information Act (FOIA)',
    description:
      'Indicates that these archival materials include FOIA restriction(s), of which the specific restriction is unknown.',
  },
  {
    label: 'FOIA (b)(1) National Security',
    description:
      'Indicates that these archival materials include national security restrictions, such as Confidential, Secret, and Top Secret.',
  },
  {
    label: 'FOIA (b)(2) Internal Personnel Rules and Practices',
    description: null,
  },
  {
    label: 'FOIA (b)(3) Statute',
    description:
      'Indicates that these archival materials include statutory restrictions.',
  },
  {
    label: 'FOIA (b)(4) Trade Secrets and Commercial or Financial Information',
    description:
      'Indicates that these archival materials include trade secret and commercial or financial information that must remain closed.',
  },
  {
    label:
      'FOIA (b)(5) Inter-agency or Intra-agency Memorandums or Letters Not Available by Law',
    description: null,
  },
  {
    label: 'FOIA (b)(6) Personal Information',
    description:
      'Indicates that these archival materials include personal information that invades the privacy of living individuals.',
  },
  {
    label: 'FOIA (b)(7) Law Enforcement',
    description:
      'Indicates that these archival materials include law enforcement information.',
  },
  {
    label: 'FOIA (b)(7a) Law Enforcement',
    description:
      'Indicates that these archival materials include law enforcement information that could reasonably be expected to interfere with enforcement proceedings.',
  },
  {
    label: 'FOIA (b)(7b) Law Enforcement',
    description:
      'Indicates that these archival materials include law enforcement information that would deprive a person of a right to a fair trial or an impartial adjudication.',
  },
  {
    label: 'FOIA (b)(7c) Law Enforcement',
    description:
      'Indicates that these archival materials include law enforcement information that could reasonably be expected to constitute an unwarranted invasion of personal privacy.',
  },
  {
    label: 'FOIA (b)(7d) Law Enforcement',
    description:
      'Indicates that these archival materials include law enforcement information that could reasonably be expected to disclose the identity of confidential source, including a state, local, or foreign agency or authority or any private institution that furnished information on a confidential basis, and, in the case of a record or information compiled by a criminal law enforcement authority in the course of a criminal investigation or by an agency conducting a lawful national security intelligence investigation, information furnished by a confidential source.',
  },
  {
    label: 'FOIA (b)(7e) Law Enforcement',
    description:
      'Indicates that these archival materials include law enforcement information that would disclose techniques and procedures for law enforcement investigations or prosecutions or would disclose guidelines for law enforcement investigations or prosecutions if such disclosure could reasonably be expected to risk circumvention of the law.',
  },
  {
    label: 'FOIA (b)(7f) Law Enforcement',
    description:
      'Indicates that these archival materials include law enforcement information that could reasonably be expected to endanger the life or physical safety or any individual.',
  },
  {
    label: 'FOIA (b)(8) Regulation or Supervision of Financial Institutions',
    description: null,
  },
  {
    label: 'FOIA (b)(9) Geological or Geophysical Information and Data',
    description: null,
  },
  {
    label: 'House Rule',
    description:
      'Indicates that these archival materials are from the House of Representatives and are subject to restrictions imposed by the House of Representatives.',
  },
  {
    label: 'John F. Kennedy Assassination Records Collection Act',
    description: null,
  },
  {
    label: 'Presidential Records Act (p)(1) National Security Classified',
    description:
      'Indicates that these archival materials are Presidential records with national security restrictions.',
  },
  {
    label: 'Presidential Records Act (p)(2) Appointments to Federal Office',
    description:
      'Indicates that these archival materials are Presidential records relating to appointments to Federal office.',
  },
  {
    label: 'Presidential Records Act (p)(3) Statute',
    description:
      'Indicates that these archival materials include Presidential records with statutory restrictions. ',
  },
  {
    label:
      'Presidential Records Act (p)(4) Trade Secrets and Commercial or Financial Information',
    description:
      'Indicates that these archival materials include Presidential records with trade secret and commercial or financial information that must remain closed.',
  },
  {
    label: 'Presidential Records Act (p)(5) Confidential Communications',
    description:
      'Indicates that these archival materials include Presidential records with confidential communications requesting or submitting advice among the President and his advisers.',
  },
  {
    label: 'Presidential Records Act (p)(6) Personal Privacy',
    description:
      'Indicates that these archival materials include Presidential records with personal information that invades the privacy of living individuals.',
  },
  {
    label: 'Presidential Records Act (PRA)',
    description:
      'Indicates that these archival materials include Presidential records during the 5 year period of initial closure specified by the PRA or when the specific PRA restriction is unknown.',
  },
  {
    label:
      'Presidential Recordings and Materials Preservation Act of 1974 (PRMPA)',
    description: 'Indicates that the specific PRMPA restriction is unknown.',
  },
  {
    label: 'PRM - Personal Record Misfile',
    description:
      'Indicates that these archival materials include PRA withdrawal sheets for textual records that are withheld from public access. This indicates the legal status of the record, in that these personal materials were misfiled in a PRA records series and can not be released under PRA or FOIA, only later under a subsequent deed of gift.',
  },
  {
    label: 'PRMPA - Individual Rights Pending (C)',
    description:
      "Indicates that these archival materials are from the Nixon Presidential Materials with a pending or approved claim whose release would violate an individual's rights.",
  },
  {
    label: 'PRMPA - Investigatory Information (F)',
    description:
      'Indicates that these archival materials are from the Nixon Presidential Materials and whose release would disclose investigatory information compiled for law enforcement purposes.',
  },
  {
    label: 'PRMPA - National Security Classified (B)',
    description:
      'Indicates that these archival materials are from the Nixon Presidential Materials with national security restrictions.',
  },
  {
    label: 'PRMPA - Non-Historical Information (H)',
    description:
      'Indicates that these archival materials are from the Nixon Presidential Materials and whose release would disclose non-historical material.',
  },
  {
    label: 'PRMPA - Personal Information (G)',
    description:
      'Indicates that these archival materials are from the Nixon Presidential Materials and whose release would disclose private or personal material.',
  },
  {
    label: 'PRMPA - Personal Privacy (D)',
    description:
      'Indicates that these archival materials are from the Nixon Presidential Materials and whose release would constitute a clearly unwarranted invasion of privacy or libel of a living person.',
  },
  {
    label: 'PRMPA - Statute (A)',
    description:
      'Indicates that these archival materials are from the Nixon Presidential Materials and whose release would violate a Federal Statute or agency policy.',
  },
  {
    label: 'PRMPA - Trade Secrets and Commercial or Financial Information (E)',
    description:
      'Indicates that these archival materials are from the Nixon Presidential Materials and whose release would disclose trade secrets or confidential commercial or financial information.',
  },
  {
    label: 'Senate',
    description:
      'Indicates that these archival materials are from the Senate and are subject to restrictions imposed by the Senate.',
  },
  {
    label: 'Other',
    description:
      'Indicates that these archival materials include specific access restriction(s) as explained in the note provided.',
  },
]
