import React, { Fragment } from 'react'
import '../../../../styles/index.scss'
import PropTypes from 'prop-types'
//Components
import Comment from './Comment'
import ExpandCollapseList from '../../../utilities/ExpandCollapseList'
//Helpers
import { compareDates } from '../../../../helper-functions/sort-functions'

const CommentGroup = ({ comments, collapse, ...props }) => {
  function hasActiveChildren(childrenArr) {
    for (let i = 0; i < childrenArr.length; i++) {
      if (childrenArr[i].status == 'active') return true
    }
    return false
  }

  return (
    <div
      className={[
        'display-flex',
        'flex-justify-start',
        'flex-column',
        'flex-gap-xs',
        'grid-col',
        'margin-top-1',
      ].join(' ')}
      data-testid="nac-comments_added-comments-display"
    >
      <ExpandCollapseList
        flex={false}
        disabled={!collapse}
        id="comments-list"
        initial={2}
        more="show all..."
      >
        {comments
          .filter((comment) => {
            return (
              comment.status == 'active' || hasActiveChildren(comment.children)
            )
          })
          .sort((a, b) => {
            return compareDates(a.createdAt, b.createdAt)
          })
          .map((comment, i) => (
            <Fragment key={comment.contributionId}>
              {!comment.parentContributionId && <Comment comment={comment} />}
              {comment.children && comment.children.length > 0 && (
                <div className="flex-column margin-left-5">
                  <ExpandCollapseList
                    disabled={!collapse}
                    flex={false}
                    id="replies-list"
                    initial={2}
                    more="show all..."
                  >
                    {comment.children
                      .sort((a, b) => {
                        return compareDates(b.createdAt, a.createdAt)
                      })
                      .map(
                        (child) =>
                          child.parentContributionId && (
                            <Comment
                              key={child.contributionId}
                              comment={child}
                            />
                          )
                      )}
                  </ExpandCollapseList>
                </div>
              )}
            </Fragment>
          ))}
      </ExpandCollapseList>
    </div>
  )
}

CommentGroup.defaultProps = {
  comments: [],
  collapse: false,
}

CommentGroup.propTypes = {
  comments: PropTypes.array.isRequired,
  collapse: PropTypes.bool,
}

export default CommentGroup
