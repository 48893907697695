import React from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//import Highlighter from './Highlighter'
import {
  countWords,
  truncateBefore,
  truncateAfter,
  truncate,
} from '../../helper-functions/utility-functions'
import Highlighter from '../Highlighter/Highlighter'

const Truncate = ({ children, highlight, maxWords, term, ...props }) => {
  if (!children || typeof children !== 'string') return false

  // Get quoted text in array
  // Doing this first preserves booleans, spaces, and modifiers which reside within quotes
  const quotedArray =
    term
      ?.match(/"(.*?)"/g)
      ?.map((v) => v.replace(/"/g, ''))
      ?.filter(function (v) {
        return v !== ''
      }) || []

  // Get individual remaining strings, but remove booleans and other modifiers
  let searchArray = []
  if (quotedArray.length > 0) {
    searchArray =
      term
        ?.replace(/"(.*?)"/g, '')
        ?.replace(/\s*(\b(and|or|\*)\b)\s*/gi, ' ')
        ?.replace(/\s*(\b(not)\b)\s*\w*\s*/gi, ' ')
        ?.split(' ')
        ?.filter(function (v) {
          return v !== ''
        }) || []
  } else
    searchArray =
      term
        ?.replace(/\s*(\b(and|or|\*)\b)\s*/gi, ' ')
        ?.replace(/\s*(\b(not)\b)\s*\w*\s*/gi, ' ')
        ?.split(' ') || []

  const combinedTerms = [...quotedArray, ...searchArray]
  const firstTerm = combinedTerms[0]
  const index = children.toUpperCase().indexOf(firstTerm?.toUpperCase())
  let middle = ''
  if (index > -1) middle = children.substring(index, index + firstTerm.length)
  if (countWords(children) <= maxWords) {
    return highlight && index > -1 ? (
      <Highlighter search={term}>{children}</Highlighter>
    ) : (
      <>{children}</>
    )
  }

  const before = truncate(
    truncateBefore(children, firstTerm),
    maxWords / 2,
    true
  )
  const after = truncate(truncateAfter(children, firstTerm), maxWords / 2)
  const doesContain = index > -1

  if (!firstTerm || !doesContain) {
    return highlight ? (
      <Highlighter search={term}>{truncate(children, maxWords)}</Highlighter>
    ) : (
      <>{truncate(children, maxWords)}</>
    )
  }

  //TO DO: Return term from children. Otherwise, the term will be overwritten by whatever case was introduced in the search. ex: Searching "OF" will replace the match "of" with the all caps term
  return highlight ? (
    <>
      <Highlighter search={term}>{before}</Highlighter>
      {middle && <Highlighter search={term}>{middle}</Highlighter>}
      <Highlighter search={term}>{after}</Highlighter>
    </>
  ) : (
    <>{before + '' + middle + '' + after}</>
  )
}

Truncate.defaultProps = {
  children: '',
  highlight: true,
  maxWords: 40,
  term: '',
}

Truncate.propTypes = {
  children: PropTypes.string,
  highlight: PropTypes.bool,
  maxWords: PropTypes.number,
  term: PropTypes.string,
}

export default Truncate
