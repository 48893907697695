import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SEARCH_URL } from '../../settings/globals'
//Hooks
import useHttp from '../../../hooks/use-http'
//Helpers
import { convertCamelToSentence } from '../../../helper-functions/string-functions'
import { FeedItem } from '../public/activity_feed'
import Card from '../../Card/Card'
import Select from '../../form/select'
import {
  returnHumanDateFromTimestamp,
  timeSince,
} from '../../../helper-functions/date-functions'
import Avatar from '../../Avatar/Avatar'
import { compareNumbers } from '../../../helper-functions/sort-functions'

const useJustificationPopup = (
  action,
  contribution,
  isShowing,
  setValue,
  contributionId
) => {
  //State
  const [justifications, setJustifications] = useState(null)
  const [loadingJustifications, setLoadingJustifications] = useState(false)

  const [justificationValue, setJustificationValue] = useState(null)

  const [data, setData] = useState(null)
  /**
   * Request latest justifications data
   */
  const handleJustificationsResponse = (results) => {
    const filteredJustifications = results?.filter(
      (j) =>
        (action === 'revert' && j.action === 'disable') || j.action === action
    )
    setJustifications(filteredJustifications)
    setJustificationValue(filteredJustifications?.[0]?.id)

    setTimeout(() => {
      setLoadingJustifications(false)
    }, [500])
  }

  useEffect(() => {
    if (justifications || loadingJustifications) return false
    const abortController = new AbortController()
    if (isShowing) {
      setLoadingJustifications(true)
      requestJustificationsHandler(abortController)
    }
    return () => {
      abortController.abort()
    }
  }, [isShowing])

  const { sendRequest: requestJustificationsHandler } = useHttp(
    {
      url: `${SEARCH_URL}/justifications?correspondingTable=contributions`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    handleJustificationsResponse
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState([])
  const [schema, setSchema] = useState({
    allow: ['array', 'string'],
    id: 'transcriptionVersion',
    label: 'Transcription Versions',
    options: [
      {
        details: [
          {
            label: (
              <div className="display-flex flex-align-center flex-gap-sm flex-row">
                <Avatar userName={contribution.userName} size="xs" />
                <strong>@{contribution.userName}</strong>
              </div>
            ),
          },
          {
            label: `${returnHumanDateFromTimestamp(
              contribution?.updatedAt || contribution?.createdAt
            )} (${timeSince(
              contribution?.updatedAt || contribution?.createdAt
            )})`,
          },
        ],
        id: `version-${contribution.contributionSequence}`,
        label: `Version ${contribution.contributionSequence}`,
        order: 1,
        value: contribution.contributionId,
        contribution: contribution,
      },
    ],
    searchable: true,
    sr: 'Transcription version dropdown field',
    type: 'select',
  })

  /**
   * Request transcription versions
   */
  useEffect(() => {
    if (justifications && !data) setLoading(true)
  }, [justifications])

  useEffect(() => {
    const abortController = new AbortController()
    if (loading) requestHandler(abortController)
    return () => {
      abortController.abort()
    }
  }, [loading])

  const responseHandler = (data) => {
    setData(data)

    let opt = []
    data
      .sort((a, b) =>
        compareNumbers(a.contributionSequence, b.contributionSequence)
      )
      .map((d, i) => {
        opt.push({
          details: [
            {
              label: (
                <div className="display-flex flex-align-center flex-gap-sm flex-row">
                  <Avatar userName={d.userName} size="xs" />
                  <strong>@{d.userName}</strong>
                </div>
              ),
            },
            {
              label: `${returnHumanDateFromTimestamp(
                d?.updatedAt || d?.createdAt
              )} (${timeSince(d?.updatedAt || d?.createdAt)})`,
            },
          ],
          id: `version-${d.contributionSequence}`,
          label: `Version ${d.contributionSequence}`,
          order: i + 1,
          value: d.contributionId,
          contribution: d,
        })
      })

    setSchema((prev) => ({ ...prev, options: opt }))
    setTimeout(() => {
      setLoading(false)
    }, [500])
  }

  const errorHandler = (error) => {
    setError(error)
  }

  const changeHander = (event, val) => {
    const v = val || event?.target?.value
    const newOption = schema.options.filter((o) => o.id === v)
    setValue(newOption?.[0]?.contribution.contributionId || null)
  }

  const { sendRequest: requestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/transcriptions/history/${contribution.parentContributionId}?sort=createdAt:desc`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    responseHandler,
    errorHandler
  )

  let details = {}
  const type = contribution ? contribution.contributionType : 'contribution'

  switch (action) {
    case 'unflag':
      details.alert =
        'Flagging a contribution will not remove it from the Catalog search or display. Only users with Moderator permissions can see a flagged status.'
      details.button = 'Remove Flag'
      details.title = 'Remove flag?'
      details.description = `You are about to remove a flag from the following ${type}:`
      details.info = `If you remove this flag, the status of this ${type} will be set to "reviewed," and it will be associated with the selected justification.`
      details.icon = 'flag-off'
      break
    case 'flag':
      details.alert =
        'Flagging a contribution will not remove it from the Catalog search or display. Only users with Moderator permissions can see a flagged status.'
      details.button = `Flag ${convertCamelToSentence(type)}`
      details.description = `You are about to flag to the following ${type}:`
      details.icon = 'flag'
      details.title = `Flag ${type}?`
      break
    case 'disable':
      details.alert =
        'Disabling a contribution will remove it from both the search and display for all Catalog users.'
      details.button = `Disable ${convertCamelToSentence(type)}`
      details.description = `You are about to disable the following ${type}:`
      details.icon = 'stop'
      details.title = `Disable ${type}?`
      break
    case 'remove':
      details.alert =
        'This action will permanently remove a contribution from the search, display, and moderator dashboard.'
      details.button = `Remove ${convertCamelToSentence(type)}`
      details.description = `You are about to remove the following ${type}:`
      details.icon = 'trash'
      details.title = `Remove ${type}?`
      break
    case 'enable':
      details.alert =
        'Enabling a contribution will make it available in the search and display for all Catalog users.'
      details.button = `Enable ${convertCamelToSentence(type)}`
      details.description = `You are about to enable the following ${type}:`
      details.icon = 'rotate-left'
      details.title = `Enable ${type}?`
      break
    case 'revert':
      details.alert =
        'Reverting a transcription will make the selected version available in the search and display for all Catalog users.'
      details.button = `Revert ${convertCamelToSentence(type)}`
      details.description = (
        <>
          <h2>Compare and Select a Version</h2>
          <div
            className={[
              'display-flex',
              'flex-gap',
              'flex-row',
              'minw-tablet-lg',
            ].join(' ')}
          >
            <div
              className={['display-flex', 'flex-column', 'width-full'].join(
                ' '
              )}
            >
              {loading || !schema || !data ? (
                <>
                  <div
                    className={[
                      'display-flex',
                      'flex-justify-center',
                      'flex-column',
                      'height-8',
                      'width-card',
                    ].join(' ')}
                  >
                    <div
                      className={['block-load', 'height-2', 'width-card'].join(
                        ' '
                      )}
                    ></div>
                  </div>
                  <Card>
                    <div
                      className={[
                        'display-flex',
                        'flex-column',
                        'flex-gap',
                      ].join(' ')}
                    >
                      <div
                        className={[
                          'block-load',
                          'height-4',
                          'width-full',
                        ].join(' ')}
                      ></div>
                    </div>
                  </Card>
                </>
              ) : (
                <>
                  {schema.options && (
                    <Select
                      className="margin-bottom-0"
                      onChange={changeHander}
                      schema={schema?.options}
                      selected={schema?.options.filter((o) => {
                        return (
                          o.contribution?.contributionId ===
                          (contributionId || contribution.contributionId)
                        )
                      })}
                    />
                  )}
                  <FeedItem
                    collapse={false}
                    className="bg-base-lightest-opacity-50  margin-top-1"
                    item={
                      data?.filter(
                        (d) => d.contributionId === contributionId
                      )[0] || contribution
                    }
                    reduced
                    reducedDate={false}
                  />
                </>
              )}
            </div>
            <div
              className={['display-flex', 'flex-column', 'width-full'].join(
                ' '
              )}
            >
              <div
                className={['height-5', 'margin-top-1', 'width-full'].join(' ')}
              >
                Version {contribution.contributionSequence} (current):
              </div>
              <FeedItem
                collapse={false}
                className="bg-base-lightest-opacity-50"
                item={contribution}
                reduced
                reducedDate={false}
              />
            </div>
          </div>
        </>
      )
      details.icon = 'rotate-left-clock'
      details.title = `Revert ${type}?`
      break
  }

  const handleJustificationChange = (event, val) => {
    const v = val || event?.target?.value
    setJustificationValue(v)
  }

  return {
    details,
    justifications,
    justificationValue,
    loadingJustifications,
    handleJustificationChange,
  }
}

export default useJustificationPopup
