import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Icon } from '../../Icon/Icon'
import { Link } from 'react-router-dom'
import { timeSince } from '../../../helper-functions/date-functions'
import StatusPill from '../../Pill/variants/StatusPill'
import useHttp from '../../../hooks/use-http'
import {
  SEARCH_URL,
  DEFAULT_PAGE,
  DEFAULT_CONTRIBUTION_TABLE_LIMIT,
} from '../../settings/globals'
import Tooltip from '../../Tooltip/Tooltip'

const useNotifications = (accountState) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [table, setTable] = useState([])
  const [notificationNaIds, setNotificationNaIds] = useState([])
  const [notifications, setNotifications] = useState([])
  const [records, setRecords] = useState([])
  const [lastViewedNotiesAt, setLastViewedNotiesAt] = useState('')
  const [updateLastViewedNotiesAt, setUpdateLastViewedNotiesAt] = useState(null)
  const [showPrevious, setShowPrevious] = useState(false)
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [limit, setLimit] = useState(DEFAULT_CONTRIBUTION_TABLE_LIMIT)
  const [total, setTotal] = useState(null)

  const typeFunc = (type, s) => {
    let desc = 'Notification type not found'
    let action = ''
    let status = ''

    if ('active' == s && 'reply' == type) {
      action = 'Reply'
      desc = 'Someone replied to your comment'
      status = 'reviewed'
    } else if ('inactive' == s && 'reply' == type) {
      action = 'Disabled'
      desc = 'Your reply was disabled by an Administrator'
      status = 'disabled'
    } else if ('active' == s && 'comment' == type) {
      action = 'Enabled'
      desc = 'Your comment was enabled by an Administrator'
      status = 'active'
    } else if ('inactive' == s && 'comment' == type) {
      action = 'Disabled'
      desc = 'Your comment was disabled by an Administrator'
      status = 'disabled'
    } else if ('active' == s && 'transcriptionEdit' == type) {
      action = 'Modified'
      desc = 'Someone contributed to your transcription'
      status = 'warning'
    } else if ('inactive' == s && 'moderated' == type) {
      action = 'Disabled'
      desc = 'Your tag was disabled by an Administrator'
      status = 'disabled'
    } else if ('active' == s && 'moderated' == type) {
      action = 'Enabled'
      desc = 'Your tag was enabled by an Administrator'
      status = 'success'
    }

    return { desc, action, status }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Contribution',
        accessor: 'contribution',
        id: 'contribution',
        className: 'grid-col-6 overflow-hidden',
      },
      {
        Header: 'Action',
        accessor: 'action',
        id: 'action',
        className: 'grid-col-2',
      },
      {
        Header: 'Record Title',
        accessor: 'targetNaId',
        id: 'targetNaId',
        className: 'grid-col-4',
      },
      {
        Header: 'User',
        accessor: 'userId',
        id: 'userId',
        className: 'width-10 tablet-lg:width-15',
      },
      {
        Header: 'Last Modified',
        accessor: 'updatedAt',
        id: 'updatedAt',
        className: 'width-15 tablet-lg:width-card',
      },
    ],
    [accountState?.notificationTotals]
  )

  const errorHandler = (error) => {
    setError('error', error)
  }

  const handleSetPage = (num) => {
    setPage(num)
  }

  const notificationsResponseHandler = (results) => {
    if (showPrevious) {
      setNotifications(results.notifications)
      setTotal(results?.notifications?.[0]?.total)
    } else {
      setNotifications(
        results.notifications.splice(0, results?.notificationCount)
      )
      setTotal(
        accountState?.notificationTotals?.notificationCount
          ? accountState?.notificationTotals?.notificationCount
          : 0
      )
    }
    setTimeout(() => {
      setLoading(false)
    }, [500])
  }

  const { sendRequest: requestNotificationsHandler } = useHttp(
    {
      url: `${SEARCH_URL}/users/notifications/${accountState?.account?.userId}${
        !showPrevious ? '?date=' + updateLastViewedNotiesAt + '&' : '?'
      }page=${page}&limit=${limit}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    notificationsResponseHandler,
    errorHandler
  )

  const lastViewedNotiesResponseHandler = (results) => {
    const date = new Date(results.lastViewedNotificationsAt)
    const dateFormatted = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0]
    setLastViewedNotiesAt(results.lastViewedNotificationsAt)
    setUpdateLastViewedNotiesAt(dateFormatted)
    putLastViewedNotificationsAt()
  }

  const { sendRequest: requestLastViewedNotiesHandler } = useHttp(
    {
      url: `${SEARCH_URL}/users/lastViewedNotificationsAt/${
        accountState?.account?.userId
      }${
        !showPrevious
          ? '?date=' + accountState?.account?.lastViewedNotificationsAt
          : ''
      }`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    lastViewedNotiesResponseHandler,
    errorHandler
  )

  const updateLastViewedResponseHandler = (results) => {
    // console.log('update complete')
    // console.log(results)
  }

  const { sendRequest: putLastViewedNotificationsAt } = useHttp(
    {
      url: `${SEARCH_URL}/users/lastViewedNotificationsAt/${accountState?.account?.userId}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    updateLastViewedResponseHandler,
    errorHandler
  )

  const responseHandler = (results) => {
    const records = Object.assign(
      {},
      ...results.body.hits.hits.map((r) => ({
        [r._source.record.naId]: r._source.record.title,
      }))
    )
    setRecords(records)
  }

  const { sendRequest: recordRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/records/search?naId=${notificationNaIds}&sourceIncludes=naId,title`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    responseHandler,
    errorHandler
  )

  /** Map & render the table data */
  useEffect(() => {
    const tableData =
      notifications >= 0
        ? []
        : notifications.map((r) => {
            return {
              contribution: (
                <div
                  className={['display-flex', 'flex-gap-sm', 'flex-row'].join(
                    ' '
                  )}
                >
                  <Icon
                    iconName={r.contributionType}
                    iconSize="xs"
                    color="base"
                  />
                  {r.contribution}
                </div>
              ),
              action: (
                <Tooltip
                  content={typeFunc(r.notificationType, r.status).desc}
                  delay={500}
                  display="block"
                  position="topCenter"
                >
                  <StatusPill
                    label={typeFunc(r.notificationType, r.status).action}
                    status={typeFunc(r.notificationType, r.status).status}
                  />
                </Tooltip>
              ),
              targetNaId: (
                <Fragment>
                  <Link
                    className="ellipsed-line-2"
                    to={{
                      pathname: `/id/${r.targetNaId}`,
                      search: `?contributionId=${r.contributionId}`,
                    }}
                  >
                    {String(records[r.targetNaId])}
                  </Link>
                </Fragment>
              ),
              userId: r.madeByAdministrator ? (
                <div className="display-flex flex-column">
                  <span>NARA Citizen Archivist</span>
                </div>
              ) : (
                <Link
                  className={[
                    'ellipsed-line-2',
                    'font-sans-3xs',
                    'theme-white--link',
                    'width-full',
                  ].join(' ')}
                  to={{
                    pathname: '/accounts/' + r.userName,
                  }}
                >
                  {r.userName}
                </Link>
              ),
              updatedAt: (
                <div className="align-right">
                  <span>{timeSince(r.notificationDate || r.updatedAt)}</span>
                </div>
              ),
            }
          })
    setTable(tableData)
  }, [records])

  /** Pull record titles by naId */
  useEffect(() => {
    if (notificationNaIds.length > 0) {
      recordRequestHandler(notificationNaIds)
    }
  }, [notificationNaIds])

  /** Map naIds out and put them in the state to pull record data */
  useEffect(() => {
    const naIds =
      notifications >= 0 ? [] : notifications.map((r) => r.targetNaId)
    setNotificationNaIds(naIds)
  }, [notifications])

  /**
   *  Query for the last time notifications were viewed
   */
  useEffect(() => {
    if (accountState?.account) {
      requestLastViewedNotiesHandler()
    }
  }, [accountState?.account])

  /** Pull notifications themselves that were created AFTER the last viewed date */
  useEffect(() => {
    if (accountState?.account && updateLastViewedNotiesAt) {
      requestNotificationsHandler()
    }
  }, [updateLastViewedNotiesAt, showPrevious, page])

  return {
    columns,
    table,
    lastViewedNotiesAt,
    showPrevious,
    page,
    limit,
    total,
    handleSetPage,
    setShowPrevious,
  }
}

export default useNotifications
