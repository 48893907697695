import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/index.scss'
import { IconLabel } from '../IconLabel/IconLabel'

const AccountContent = ({ children, ...props }) => {
  return (
    <div
      className={[
        'display-flex',
        'flex-column',
        'flex-gap-md',
        'margin-top-2',
        'minh-0',
        'minw-0',
        'text-base-darker',
        'width-full',
      ].join(' ')}
    >
      {children}
    </div>
  )
}

AccountContent.propTypes = {
  /**
   * The content provided to the component.
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default AccountContent

export const ContentTitle = ({ iconName, title }) => {
  return (
    <IconLabel
      color="base-darker"
      iconName={iconName}
      iconSize="md"
      strokeThickness="thick"
    >
      <h1>{title}</h1>
    </IconLabel>
  )
}
ContentTitle.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
}
