import React, { useContext, useEffect, useState } from 'react'
import '../../../styles/index.scss'
import PropTypes from 'prop-types'
// import SearchContext from '../../../contexts/SearchContext'
import { handleQuotes } from '../../../helper-functions/queryHandlers'
import { returnNumberWithCommas } from '../../../helper-functions/number-functions'

const Summary = ({ label, limit, page, term, time, total, ...props }) => {
  if (!total) return false

  // const searchContext = useContext(SearchContext)
  // const query = searchContext.activeQueryParams.q

  const [summary, setSummary] = useState('')

  useEffect(() => {
    if (total == 0) return ''
    let s = total > 1 ? 's' : ''
    let limitNum = ''
    if (limit * page < total) {
      limitNum = `${returnNumberWithCommas(
        limit * (page - 1) + 1
      )}–${returnNumberWithCommas(limit * page)}`
    } else if (total > 1) {
      limitNum = `${returnNumberWithCommas(
        limit * (page - 1) + 1
      )}–${returnNumberWithCommas(total)}`
    } else {
      limitNum = '1'
    }
    let forTerm = term ? 'for ' : ''
    let forQuery = term ? (
      <strong className="text-base-darker">
        {' '}
        <em>{handleQuotes(term)} </em>
      </strong>
    ) : label ? (
      `within this ${label} `
    ) : (
      ''
    )
    setSummary(
      <>
        {limitNum} of {returnNumberWithCommas(total)} result{s} {forTerm}{' '}
        {label ? forQuery : forQuery}
        in {time} seconds.
      </>
    )
  }, [term, page, limit])

  if (!summary) return false
  else
    return (
      <span className={['font-sans-2xs', 'text-base-dark'].join(' ')}>
        {summary}
      </span>
    )
}

Summary.defaultProps = {
  label: null,
}

Summary.propTypes = {
  label: PropTypes.string,
  limit: PropTypes.number,
  page: PropTypes.number,
  term: PropTypes.string,
  time: PropTypes.string,
  total: PropTypes.number,
}

export default Summary
