import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '../../styles/index.scss'
import CopyUrl from '../utilities/CopyUrl/CopyUrl'
import { IconLabel } from '../IconLabel/IconLabel'
import { convertSentenceToCamel } from '../../helper-functions/string-functions'

/**
 * Section Headings are used to label each section of content on a page
 */
export const SectionHeading = ({
  children,
  copyLink,
  count,
  tag,
  ...props
}) => {
  const HeadingTag = `${tag}`
  const id = { ...props.id }
  const heading = (
    <HeadingTag
      className={[
        'display-inline-flex',
        'flex-align-center',
        'flex-gap-sm',
        'flex-row',
      ].join(' ')}
      {...props}
    >
      {children}
      {count > 1 && (
        <IconLabel color="base-light" iconName="pipe">
          <span
            aria-hidden="true"
            className={['section-count', 'text-base-dark', 'text-normal'].join(
              ' '
            )}
          >
            {count}
          </span>
          <span className={['usa-sr-only'].join(' ')}>
            . This section contains {count} list items.
          </span>
        </IconLabel>
      )}
    </HeadingTag>
  )

  return copyLink && { ...props.id } ? (
    <CopyUrl id={id} {...props}>
      {heading}
    </CopyUrl>
  ) : (
    heading
  )
}

SectionHeading.defaultProps = {
  copyLink: false,
  id: '',
  tag: 'h2',
}

SectionHeading.propTypes = {
  /**
   * The content provided to the section heading.
   */
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]),
  /**
   * Determines if the heading can be clicked to copy a link which anchors to the heading location
   */
  copyLink: PropTypes.bool,
  /**
   * The number of items in the section, displayed after the heading text
   */
  count: PropTypes.number,
  /**
   * Unique identifier to link to
   */
  id: PropTypes.string,
  /**
   * The HTML heading tag
   */
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
}

//export const Button = ButtonComponent
