import React, { useContext } from 'react'
import '../../../styles/index.scss'
import AccountContext from '../../../contexts/AccountContext'
import AccountContent, { ContentTitle } from '../account_content'

const Downloads = () => {
  const accountState = useContext(AccountContext)

  return (
    <AccountContent>
      <ContentTitle iconName="action-cloud-down" title="Downloads" />
    </AccountContent>
  )
}

export default Downloads
