import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../styles/index.scss'
//Context
//Components
import { Button } from './Button/Button'
import { IconLabel } from './IconLabel/IconLabel'
import FormField from './form/FormField'
import RepeatableField from './form/RepeatableField'
import FormContext from '../contexts/FormContext'
//Schemas
import {
  advancedSearchConditionsSchema,
  advancedSearchSchema,
} from '../schemas/input-schemas/input-schema-advanced-search'
//Hooks
import useForm from '../hooks/use-form'
import Card from './Card/Card'
import DateInputField from './form/date_input_field'
import PropTypes from 'prop-types'

const AdvancedSearchPage = ({ ...props }) => {
  // Tracks the conditional logic around which form fields to display
  const [condition, setCondition] = useState(
    'description&availableOnline=false'
  )
  const [currentSchema, setCurrentSchema] = useState([])

  let includedArray = null
  advancedSearchConditionsSchema.options.map((a) => {
    if (a?.value === condition) includedArray = a?.show || null
  })

  //Hooks
  const navigate = useNavigate()
  const formState = useForm(includedArray, (params) =>
    navigate(
      {
        pathname: '/search',
        search: `${params.length > 0 ? `?${params.join('&')}` : ''}`,
      },
      {
        state: { advancedSearchForm: formState.formFields },
      },
      sessionStorage.removeItem('day'),
      sessionStorage.removeItem('daySelected')
    )
  )
  const setConditionHandler = (id, value) => {
    formState.formDispatch({
      type: 'UPDATE_FORM_FIELD',
      id: id,
      value: value,
    })
    setCondition(value)
  }

  useEffect(() => {
    sessionStorage.setItem('titles', 'National Archives Catalog')
    if (formState.formFields?.recurringDate) {
      const recurringDate = formState.formFields?.recurringDate?.split('-')
      formState.formFields.recurringDateMonth = `${recurringDate[1]}`
      formState.formFields.recurringDateDay = `${recurringDate[2]}`
      delete formState.formFields.recurringDate
    }
  }, [formState])

  useEffect(() => {
    let schema = JSON.parse(JSON.stringify(advancedSearchSchema)) // Prevents mutation
    setCurrentSchema(
      schema.filter(function f(o) {
        if (!includedArray) return true
        else if (o.schema) {
          return (o.schema = o.schema.filter(f))
        } else if (includedArray?.includes(o.id)) return true
      })
    )
  }, [condition])

  useEffect(() => {
    setTimeout(() => {
      formState.formDispatch({
        type: 'UPDATE_FORM_FIELD',
        id: 'keywords',
        value: {
          q: sessionStorage.getItem('search'),
        },
      })
    }, 1000)
  }, [])

  return (
    <FormContext.Provider value={formState}>
      <div
        className="nac-body bg-primary-lighter tablet:padding-x-3 padding-y-6 theme-primary-lighter"
        role="main"
      >
        <div
          className={[
            'display-flex',
            'flex-column',
            'flex-align-start',
            'flex-justify-center',
            'flex-gap',
            'maxw-tablet-lg',
            'width-full',
            // 'width-mobile-lg',
          ].join(' ')}
        >
          <Link
            data-testid="nac-link--basic-search"
            to="/"
            className={[
              'display-flex',
              'flex-row',
              'flex-align-center',
              'grid-row',
            ].join(' ')}
          >
            <IconLabel iconName="prev">Return to Basic Search</IconLabel>
          </Link>
          <h1 className="padding-x-1">Advanced Search</h1>

          <form
            className={['position-relative', 'width-full'].join(' ')}
            data-testid="nac-advanced-search--form"
            id="advanced-search"
            onSubmit={formState.formSubmitHandler}
          >
            <Card>
              <h2
                className={[
                  'display-flex',
                  'flex-align-start',
                  'flex-gap',
                  'flex-column',
                ].join(' ')}
              >
                Search for
                <div
                  className={[
                    'mobile-lg:display-flex',
                    'flex-gap-0',
                    'flex-row',
                  ].join(' ')}
                >
                  {advancedSearchConditionsSchema.options.map(
                    (option, index) => (
                      <div key={option.id} id={option.id}>
                        <Button
                          className={[
                            'radius-md',
                            index === 0 ? 'mobile-lg:radius-left-md' : '',
                            index ===
                            advancedSearchConditionsSchema.options.length - 1
                              ? 'mobile-lg:radius-right-md'
                              : '',
                            index !==
                            advancedSearchConditionsSchema.options.length - 1
                              ? 'margin-right-neg-2px'
                              : '',
                            'height-full',
                            'mobile-lg:radius-0',
                          ].join(' ')}
                          onClick={() =>
                            setConditionHandler(
                              advancedSearchConditionsSchema.id,
                              option.value
                            )
                          }
                          outline={condition !== option.value}
                          shape="0"
                        >
                          {option.label}
                        </Button>
                      </div>
                    )
                  )}
                </div>
                with:
              </h2>

              {/*formState.formErrors && (
                <Alert
                  slim
                  title="The following errors were found."
                  type="error"
                >
                  {Object.values(formState.formErrors).map(({ message }) => (
                    <li key={message}>{message}</li>
                  ))}
                </Alert>
                  )*/}
              <div
                className={['display-flex', 'flex-gap-sm', 'flex-column'].join(
                  ' '
                )}
              >
                {currentSchema.map((field) => {
                  if (field?.type === 'repeatable')
                    return (
                      <RepeatableField
                        id={field.id}
                        key={field.id}
                        label={field.label}
                        description={field.description}
                        schema={field.schema}
                      />
                    )
                  else if (field?.type === 'date')
                    return <DateInputField key={field.id} schema={field} />
                  else return <FormField key={field.id} schema={field} />
                })}

                <div
                  className={['display-flex', 'flex-row', 'flex-gap-sm'].join(
                    ' '
                  )}
                >
                  <Button
                    data-testid="nac-advanced-search--submit"
                    disabled={
                      formState.formIsEmpty ||
                      formState.formErrors ||
                      formState.formFields?.recurringDate === '--'
                    }
                    type="submit"
                  >
                    <span>Search</span>
                  </Button>
                  <Button
                    data-testid="nac-advanced-search--clearall"
                    disabled={
                      (formState.formIsEmpty && !formState.touched) ||
                      formState.formFields?.recurringDate === '--'
                    }
                    outline
                    onClick={formState.formResetHandler}
                  >
                    Reset Form
                  </Button>
                </div>
              </div>
            </Card>
          </form>
        </div>
      </div>
    </FormContext.Provider>
  )
}

AdvancedSearchPage.defaultProps = {}

AdvancedSearchPage.propTypes = {
  searchState: PropTypes.any,
}

export default AdvancedSearchPage
