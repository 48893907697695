import { useContext, useEffect, useState } from 'react'
import { SEARCH_URL } from '../../settings/globals'
import ModerateContributionsContext from '../../../contexts/ModerateContributionsContext'
//Hooks
import useHttp from '../../../hooks/use-http'

const useModerationTotals = (contributionType) => {
  const [data, setData] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const { body, query } = useContext(ModerateContributionsContext) || {}

  useEffect(() => {
    if (contributionType && !loading && !data.total) {
      setLoading(true)
    }
  }, [contributionType, body, query])

  useEffect(() => {
    const abortController = new AbortController()
    if (loading) contributionTotalsRequestHandler(abortController)

    return () => {
      abortController.abort()
    }
  }, [loading])

  const contributionTotalsResponseHandler = (results) => {
    if (!results)
      setError({
        title: 'No content',
        status: 204,
        description: 'No totals were returned',
        id: 'NO_CONTENT',
      })
    setLoading(false)
    setData(results?.[0])
  }

  const errorHandler = (error) => {
    setError(error)
  }

  const { sendRequest: contributionTotalsRequestHandler } = useHttp(
    {
      url: `${SEARCH_URL}/contributions?report=statusTotals${
        contributionType ? `&contributionType=${contributionType}` : ''
      }`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    contributionTotalsResponseHandler,
    errorHandler
  )

  return { data, error, loading }
}

export default useModerationTotals
