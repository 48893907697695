import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
} from 'react'
import L from 'leaflet'
import 'leaflet-iiif'
import 'leaflet/dist/leaflet.css'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import Video from '../Video/Video'
const config = require('../../config.js')

import { convertImageUrlToIiif } from '../../helper-functions/string-functions'
import {
  audioTypes,
  imageTypes,
  videoTypes,
} from '../../helper-functions/metadata-functions'
import { IconLabel } from '../IconLabel/IconLabel'
import { Button } from '../Button/Button'
import PDFViewer from '../PDFViewer/PDFViewer'

export const ObjectPreview = forwardRef(
  ({ interact, object, setInteractHandler, ...props }, ref) => {
    //hover
    // if (!object) return false

    // TODO: Temp Comment Out for now Coordinates Highlights
    //const { search } = props

    const [type, setType] = useState(returnObjectTypeFromObject(object))

    //TODO: Temp Comment Out for now Coordinates Highlights
    /* const [transcriptionData, setTranscriptionData] = useState([]) */
    const [Boxes, setBoxes] = useState([])

    //  TODO: Temp Comment Out for now Coordinates Highlights
    /*const [transcriptionState, setTranscriptionState] = useState(false) */

    useEffect(() => {
      if (object) setType(returnObjectTypeFromObject(object))
    }, [object])

    let map, iiifLayers, p1, p2
    const initMap = () => {
      /**
       * TO DO: The loading error...
       * "Uncaught TypeError: Cannot read properties of undefined (reading 'parentNode')"
       * ...occurs here.
       * Figure out why, and fix it. Somthing to do with the map.remove()
       */
      if (type !== 'Image') return false
      if (map != null || typeof map != 'undefined' || !object) {
        try {
          map?.remove()
        } catch (error) {
          console.log(error)
        }
      }
      let container = L.DomUtil.get('map')
      if (container != null) {
        container._leaflet_id = null
      }
      map = L.map('map', {
        attributionControl: false,
        center: [0, 0],
        crs: L.CRS.Simple,
        zoom: 0,
        minZoom: 0.4,
      })

      let tileLayer = L.tileLayer
        .iiif(convertImageUrlToIiif(object?.objectUrl) + '/info.json', {
          tileOptions: {
            transparent: true,
          },
        })
        .addTo(map)

      map.on('zoomstart', function () {
        tileLayer.setOpacity(0)
      })

      map.on('zoomend', function () {
        tileLayer.setOpacity(1)
      })
    }
    const mapRef = useCallback(() => {
      initMap()
    }, [object])

    // TODO: Temp Comment Out for now Coordinates Highlights
    /*  const loadTranscriptions = (transcription, s3Url) => {
      setTranscriptionData(transcription)
    } */

    // TODO: Temp Comment Out for now Coordinates Highlights
    /* const getTranscriptionData = (s3Url) => {
      const cachedItem = JSON.parse(localStorage.getItem(s3Url))
      if (cachedItem) {
        loadTranscriptions(cachedItem, s3Url)
        return cachedItem
      }

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'LErYqrzMW32XiVm1HNloCaMq2e27bZDb2XVYlZzW',
        },
        body: JSON.stringify({ s3Url }),
      }
      fetch(
        'https://dev.nac-mvp.archives.gov/api/v2/fetch-coords',
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            localStorage.setItem(s3Url, JSON.stringify(data))
            loadTranscriptions(data, s3Url)
          }
        })
        .catch(() => {
          console.log('Image may not have valid transcription data.')
        })
    } */

    // TODO: Temp Comment Out for now Coordinates Highlights
    //useEffect(() => {
    //  getTranscriptionData(object?.objectUrl)
    //}, [object?.objectUrl])

    // TODO: Temp Comment Out for now Coordinates Highlights
    /*function showTranscriptions(data, image, size) {
      const { Blocks } = data
      const searchArray = search.split(' ').map((v) => v.toLowerCase())

      const filteredBlocks = Blocks.filter(
        (block) =>
          block.BlockType === 'WORD' &&
          block.Confidence > 59 &&
          searchArray.includes(block.Text.toLowerCase())
      )

      const dom = filteredBlocks.map((wordData) => {
        const {
          Geometry: {
            BoundingBox: { Height, Left, Top, Width },
          },
        } = wordData
        const LeftInPx = Left * size.width
        const TopInPx = Top * size.height
        const width = Width * size.width
        const height = Height * size.height
        return (
          <div
            key={wordData.Id}
            style={{
              position: 'absolute',
              opacity: '.5',
              top: TopInPx,
              left: LeftInPx,
              height: height,
              width: width,
              background: 'green',
            }}
          />
        )
      })
      setBoxes(dom)
    }
    */

    return object ? (
      <div
        className={[
          'object-view__preview-object',
          //
          'bg-base-darkest',
          'display-flex',
          'flex-align-center',
          'flex-column',
          'flex-justify-center',
          'grid-col',
          'minh-0',
          // 'padding-1',
          'width-full',
        ].join(' ')}
      >
        <div
          className={[
            'preview-object',
            //
            'bg-base-darkest',
            'display-flex',
            'flex-align-center',
            'flex-justify-center',
            'height-full',
            'minh-0',
            'position-relative',
            'width-full',
            interact ? 'active' : 'inactive',
          ].join(' ')}
          id="digital-object-preview"
          role="tabpanel"
        >
          {type == 'Video' && (
            <Video
              file={object?.objectUrl}
              format={returnFileFormat(object?.objectType)}
              {...props}
            />
          )}
          {type == 'PDF' && <PDFViewer file={object?.objectUrl} />}
          {(type == 'Image' || type == 'PDF') && (
            <>
              {!interact && (
                <div
                  className={[
                    // 'interact-overlay',
                    'anim-opacity-0',
                    'anim-duration-250ms',
                    'anim-delay-0',
                    'bg-base-darkest-opacity-0',
                    'bg-transparent',
                    'clickable',
                    'display-flex',
                    'flex-align-center',
                    'flex-justify-center',
                    'flex-row',
                    'height-full',
                    'position-absolute',
                    'text-bold',
                    'text-transparent',
                    'width-full',
                    'z-500',
                  ].join(' ')}
                  data-testid="nac-digital-objects__interact-overlay"
                  onClick={() => setInteractHandler(true)}
                  // ref={ref}
                >
                  <Button
                    // iconAnimation="rotate-cw"
                    iconName="gear"
                    onClick={() => setInteractHandler(true)}
                    outlineColor="transparent"
                    theme="transparent"
                  >
                    Click to interact
                  </Button>
                  {/* //TODO: Temp Comment Out for now Coordinates Highlights */}
                  {/* <Button
                    style={{ position: 'relative', left: 20 }}
                    // iconAnimation="rotate-cw"
                    iconName="gear"
                    onClick={() => setTranscriptionState(true)}
                    outline
                    theme="white"
                  >
                    View Transcriptions
                  </Button> */}
                </div>
              )}
              {/* //TODO: Temp Comment Out for now Coordinates Highlights */}
              {/* {transcriptionState && (
                <div>
                  <Button
                    style={{ position: 'relative', left: -100 }}
                    iconName="gear"
                    onClick={() => setTranscriptionState(false)}
                    outline
                    theme="white"
                  >
                    Return to zoom
                  </Button>
                  <div style={{ position: 'relative', maxWidth: 800 }}>
                    {Boxes}
                    <img
                      src={object?.objectUrl}
                      onLoad={(e) => {
                        showTranscriptions(
                          transcriptionData,
                          object.objectUrl,
                          {
                            height: e.target.offsetHeight,
                            width: e.target.offsetWidth,
                          }
                        )
                      }}
                    />
                  </div>
                </div>
              )} */}

              {/* //TODO: Temp Comment Out for now Coordinates Highlights */}
              {/*type == 'Image' && !transcriptionState && (
                <div
                  className={[
                    'bg-transparent',
                    'height-full',
                    'overflow-hidden',
                    'width-full',
                  ].join(' ')}
                  key={object?.objectUrl}
                  id="map"
                  ref={mapRef}
                  {...props}
                ></div>
                )*/}
              {type == 'Image' && (
                <div
                  className={[
                    'bg-transparent',
                    'height-full',
                    'overflow-hidden',
                    'width-full',
                  ].join(' ')}
                  key={object?.objectUrl}
                  id="map"
                  ref={mapRef}
                  {...props}
                ></div>
              )}
            </>
          )}
          {/**TO DO: Create an Audio component */}
          {type == 'Audio File' && (
            <audio controls key={object?.objectUrl}>
              <source
                src={object?.objectUrl}
                type={`audio/${returnFileFormat(object?.objectType)}`}
              />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      </div>
    ) : (
      ''
    )
  }
)

ObjectPreview.defaultProps = {
  object: {},
}

ObjectPreview.propTypes = {
  //  TODO: Temp Comment Out for now Coordinates Highlights
  // search: PropTypes.string,
  setInteractHandler: PropTypes.func,
  hover: PropTypes.bool,
  interact: PropTypes.bool,
  object: PropTypes.object?.isRequired,
}

ObjectPreview.displayName = 'ObjectPreview'

export const returnFileFormat = (string) => {
  if (!string) return
  let format = ''
  const regExp = /\(([^)]+)\)/
  let match = regExp.exec(string)
  format = match?.[1].toLowerCase()
  if (!format)
    format = string?.split(/[#?]/)[0]?.split('.')?.pop()?.trim().toLowerCase()
  return format
}

export const returnObjectTypeFromObject = (object) => {
  if (!object || (!object.objectType && !object.objectFilename)) return 'Object'
  else if (
    returnFileFormat(object?.objectType || object?.objectFilename) == 'pdf'
  )
    return 'PDF'
  else if (
    imageTypes.some(
      (element) =>
        object?.objectType?.includes(element) ||
        object?.objectFilename?.includes(element)
    )
  )
    return 'Image'
  else if (
    videoTypes.some(
      (element) =>
        object?.objectType?.includes(element) ||
        object?.objectFilename?.includes(element)
    )
  )
    return 'Video'
  else if (
    audioTypes.some(
      (element) =>
        object?.objectType?.includes(element) ||
        object?.objectFilename?.includes(element)
    )
  )
    return 'Audio File'
}
