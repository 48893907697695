import React, { Fragment, useContext, useEffect, useState } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import RecordContext from '../../contexts/RecordContext'
//Components
import BasicLink from '../BasicLink'
import Prose from '../utilities/Prose'
import Tooltip from '../Tooltip/Tooltip'
import { Icon } from '../Icon/Icon'
//Helpers
import { compareStrings } from '../../helper-functions/sort-functions'
import SearchContext from '../../contexts/SearchContext'
import Highlighter from '../Highlighter/Highlighter'
import useHighlightList from '../../hooks/use-highlight-list'

/**
 * Helps users locate finding aids to the record group, collection, or archival materials
 * and identifies the kind of finding aids available.
 *
 * Finding Aid File Type: https://www.archives.gov/research/catalog/lcdrg/elements/findingfile.html
 * Finding Aid Note: https://www.archives.gov/research/catalog/lcdrg/elements/findingnote.html
 * Finding Aid Source: https://www.archives.gov/research/catalog/lcdrg/elements/findingsource.html
 * Finding Aid Type: https://www.archives.gov/research/catalog/lcdrg/elements/findingtype.html
 * Finding Aid URL: https://www.archives.gov/research/catalog/lcdrg/elements/findingurl.html
 */

const SectionFindingAids = ({ data, info, ...props }) => {
  const { record } = useContext(RecordContext)
  if (!data && (!record || !record.findingAids)) return false
  const { activeQueryParams } = useContext(SearchContext) || {}
  const q = activeQueryParams.q || sessionStorage.getItem('search')

  // Prevent the original response from being mutated
  // const [findingAids, setFindingAids] = useState(null)

  // Mutate the array to sort and include details from the findingAidTypeAuthorityList array
  let findingAids = data || JSON.parse(JSON.stringify(record.findingAids))
  findingAids = findingAids
    .sort((a, b) => {
      return compareStrings(a.findingAidType, b.findingAidType)
    })
    .map((aid) => {
      findingAidTypeAuthorityList.some(({ label, description }) => {
        if (aid.findingAidType === label) aid.description = description
      })
      return aid
    })

  const { sortedArray, count } = useHighlightList(q, findingAids, [
    'description',
    'findingAidType',
    'note',
    'source',
    // 'url',
    'urlDescription',
  ])

  return sortedArray ? (
    <div
      className={['flex-column', 'width-full'].join(' ')}
      data-testid="nac-description__finding-aids"
    >
      <>
        <h3 id="findingAids-label">
          <span>
            {findingAids.length > 1 ? (
              <>
                Finding Aids
                <span className="usa-sr-only">
                  . This section contains {findingAids.length} items
                </span>
              </>
            ) : (
              'Finding Aid'
            )}
          </span>
          <span className="usa-sr-only">,</span>
        </h3>
        <ol
          className={[
            'border',
            'border-base-lighter',
            'font-sans-xs',
            'padding-left-2',
            'padding-top-2',
            'radius-md',
            'text-base-darkest',
          ].join(' ')}
        >
          {sortedArray.map(
            (
              {
                description,
                findingAidType,
                note,
                source,
                url,
                urlDescription,
              },
              index
            ) => (
              <li
                key={note + source}
                className={[
                  'margin-left-3',
                  'margin-right-2',
                  'maxw-tablet-lg',
                  'padding-bottom-2',
                  'padding-left-1',
                ].join(' ')}
              >
                <span
                  className={[
                    'display-flex',
                    'flex-column',
                    'flex-gap-sm',
                  ].join(' ')}
                >
                  <span
                    className={[
                      'display-inline-flex',
                      'flex-align-center',
                      'text-bold',
                      'width-full',
                    ].join(' ')}
                  >
                    <BasicLink
                      aria-describedby={
                        info ? `#finding-aid-description-${index}` : ''
                      }
                      className="margin-right-1"
                      href={url}
                      title={urlDescription}
                    >
                      <Highlighter search={q}>{findingAidType}</Highlighter>
                    </BasicLink>
                    {
                      // NOTE: This help icon does not need to meet 508 Success Criterion 2.5.5
                      // concerning Target Size, as detailed here:
                      // Example 7: Help icon
                      // A help icon within or at the end of a sentence does not need to meet
                      // the 44 by 44 CSS pixels requirements. The icon at the end of the
                      // sentence is considered to be part of the sentence.
                      // (https://www.w3.org/WAI/WCAG21/Understanding/target-size.html)
                    }
                    {info && description && (
                      <Tooltip
                        content={description}
                        delay={500}
                        display="block"
                        id={`#finding-aid-description-${index}`}
                        position="bottomCenter"
                      >
                        <Icon
                          iconPercent="80"
                          color="base-dark"
                          iconName="circle-questions"
                          iconSize="sm"
                        />
                      </Tooltip>
                    )}
                  </span>
                  {note && (
                    <Prose>
                      <span className="usa-sr-only">,</span>
                      <Highlighter search={q}>{note}</Highlighter>
                    </Prose>
                  )}
                  {source && (
                    <>
                      <span
                        className={['font-sans-3xs', 'text-italic'].join(' ')}
                      >
                        <span className="usa-sr-only">,</span> Source:{' '}
                        <Highlighter search={q}>{source}</Highlighter>
                      </span>
                    </>
                  )}
                </span>
                <span className="usa-sr-only">,</span>
              </li>
            )
          )}
        </ol>
      </>
    </div>
  ) : (
    ''
  )
}

SectionFindingAids.defaultProps = {
  info: false,
}

SectionFindingAids.propTypes = {
  /**
   * Finding Aids data object which can be optionally provided
   * for display outside of the regular description page flow,
   * such as on a help page.
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      findingAids: PropTypes.arrayOf(
        PropTypes.shape({
          findingAidType: PropTypes.oneOf([
            'Calendar',
            'Catalog',
            'Container List',
            'Database',
            'Doc. Package',
            'Filing Plan',
            'Folder List',
            'Index',
            'Inventory',
            'Item List',
            'Log',
            'Manuscript Register',
            'Prelim. Checklist',
            'Register',
            'Select List',
            'Shelf List',
          ]),
          note: PropTypes.string,
          source: PropTypes.string,
        })
      ),
    })
  ),
  /**
   * When true, an info icon will display to the right of the Finding Aid Type label,
   * which displays a tooltip on hover containing the description of that type,
   * as detailed in the findingAidTypeAuthorityList array, if available
   */
  info: PropTypes.bool,
}

export default SectionFindingAids

/**
 * Finding Aid Type descriptions for use with contextual help display
 *
 * NOTE: The description is informed by the documentation on Archives.gov
 * with some modifications
 * https://www.archives.gov/research/catalog/lcdrg/authority_lists/findinglist.html
 */
export const findingAidTypeAuthorityList = [
  {
    label: 'Calendar',
    description:
      'A chronological list of individual documents, either selective or comprehensive, usually with a description providing such information as writer, recipient, date, place, summary of content, label of document, and page or leaf count.',
  },
  {
    label: 'Catalog',
    description:
      'A listing of materials with descriptive details, usually arranged systematically. Catalogs are produced in a variety of formats, including book, card, microform, or electronic.',
  },
  {
    label: 'Container List',
    description:
      'A listing of materials by container, meant to facilitate retrieval. A container list normally includes the title of the series or file, start and end of each container, and the inclusive dates of the materials contained therein. Also known as a box list.',
  },
  {
    label: 'Database',
    description:
      'A structured assembly of logically related data, usually machine-readable data, designed to meet various applications but managed independently of them. A database may be designed to emulate any other finding aid label.',
  },
  {
    label: 'Doc. Package',
    description:
      'A compilation of materials that enables the user to understand the structure and/or content of the electronic records being described. A documentation package may include materials produced by the creator and/or NARA, such as a listing of the contents, data layouts, manuals, user notes, and publications.',
  },
  {
    label: 'Filing Plan',
    description:
      'A classification plan or scheme for the physical arrangement, storage, and retrieval of files. A filing plan is often identified by the label of symbols used (e.g. alphabetical, numerical, alpha-numerical, decimal). Use for agency-created filing plans, not NARA-created finding aids.',
  },
  {
    label: 'Folder List',
    description:
      'A list prepared by the creator or an archives or manuscript repository detailing the titles of folders contained in one or more records center cartons/containers or archives boxes/containers.',
  },
  {
    label: 'Index',
    description:
      'A systematically arranged list providing access to the contents of a file, document, or groups of documents, consisting of entries giving enough information to trace or locate each entry by means of a page number or other symbol.',
  },
  {
    label: 'Inventory',
    description:
      'Inventories only apply to Record Group or Collection descriptions. As a basic archival finding aid, an inventory generally includes a brief administrative history of the organization(s) as well as series descriptions of their records. Minimum descriptions generally include title, inclusive dates, quantity, arrangement, relationships to other series, and scope and content notes. Inventories may also include appendices that provide supplementary information such as container lists, folder lists, glossaries, etc. In record management, an inventory is a listing of the volume,scope, and complexity of the records, usually compiled for the purpose of creating a records schedule. Use for preliminary inventories.',
  },
  {
    label: 'Item List',
    description:
      'A listing of all items or a selection of items from a record group, collection or series.',
  },
  {
    label: 'Log',
    description:
      'A listing of sequential data, especially about activities or transactions that occur in a system. Use for logbook.',
  },
  {
    label: 'Manuscript Register',
    description:
      'The finding aid developed in the style of the Manuscript Division of the Library of Congress to describe groups of papers and records by giving their provenance and conditions of access and use;scope and general content, including inclusive dates and bulk dates; a biographical note about the person, family group, or organization whose material it is; its arrangement; a folder list; and on occasion, selective indexes. Registers only apply to collection descriptions.',
  },
  {
    label: 'Other',
    description:
      'Use if the finding aid label is not available in this list. Describe the finding aid label in the Finding Aid Note.',
  },
  {
    label: 'Prelim. Checklist',
    description:
      'Use for archival materials that have been described in a preliminary checklist. A checklist is a list of documents prepared for the purposes of identification and control. Do not confuse with preliminary inventory, for which you should use inventory.',
  },
  {
    label: 'Register',
    description:
      'A list, often in the form of a volume, of items, names, events, actions, etc. The entries are usually in numerical or chronological sequence. Registers often accomplish the legal function of providing evidence of facts and acts and may also serve as a finding aids to records, such as a register of letters sent.',
  },
  {
    label: 'Select List',
    description:
      'A published finding aid listing items, such as textual documents, photographs, maps, etc., that bring together information from across record groups, collections, or series relating to a particular topic.',
  },
  {
    label: 'Shelf List',
    description:
      'A list of the holdings in a library, records center, or archives, arranged in the order of the contents of each shelf.',
  },
]
