export const onlineAvailabilitySchema = [
  {
    description:
      'This record has been digitized and is now fully available online.',
    id: 'fullyDigitized',
    label: 'Fully Available Online',
    title: 'Fully Available Online',
    type: 'success',
  },
  {
    description:
      'This record contains records, some of which are available online.',
    id: 'partiallyDigitized',
    label: 'Partially Available Online',
    title: 'Partially Available Online',
    type: 'warning',
  },
  {
    description:
      'The unrestricted records in this record are fully available online.',
    id: 'unrestrictedOnly',
    label: 'Partially Available Online',
    secondaryLabel: 'Unrestricted Only',
    title: 'Partially Available Online: Unrestricted Only',
    type: 'warning',
  },
  {
    description:
      'This record contains records, none of which are available online.',
    id: 'notDigitized',
    label: 'Not Yet Available Online',
    title: 'Not Yet Available Online',
    type: 'warning',
  },
]
