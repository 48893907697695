import React, { useContext } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
//Context
import ContributionContext from '../../contexts/ContributionContext'
//Components
import CommentInput from './contributions/comments/comment_input'
import CommentGroup from './contributions/comments/comment_group'
import { SectionHeading } from '../SectionHeading/SectionHeading'

const SectionComments = ({ disabled, ...props }) => {
  const { comments: commentsArray } = useContext(ContributionContext)
  const comments = commentsArray?.filter((comment) => {
    return comment && !comment.targetObjectId
  })

  // Used for total section count
  function commentCount(arr) {
    let c = 0
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].status == 'active') {
        c++
      }
      if (arr[i].children) {
        c += commentCount(arr[i].children)
      }
    }
    return c
  }

  return disabled && (!comments || comments?.length === 0) ? (
    ''
  ) : (
    <div className={['width-full'].join(' ')} {...props}>
      <SectionHeading copyLink count={comments ? commentCount(comments) : 0}>
        Comments
      </SectionHeading>
      {!disabled && <CommentInput comments={comments} />}
      <CommentGroup comments={comments} collapse />
    </div>
  )
}

export default SectionComments

SectionComments.defaultProps = {
  disabled: false,
}

SectionComments.propTypes = {
  /**
   * Stops contributions by preventing the users from accessing the input.
   */
  disabled: PropTypes.bool,
}
