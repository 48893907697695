import React from 'react'
import '../../styles/index.scss'
import BasicLink from '../BasicLink'
import Logo from '../Logo/Logo'
import { SEARCH_HOST } from '../../config'
import { NavLink } from 'react-router-dom'

function Footer() {
  const primaryLinks = [
    {
      ariaLabel: 'Open the Veterans page on Archives.gov in a new tab',
      href: 'https://www.archives.gov/veterans',
      title: "Veterans' Service Records",
    },
    {
      ariaLabel: 'Open the FOIA page on Archives.gov in a new tab',
      href: 'https://www.archives.gov/foia/',
      title: 'Freedom of Information Act (FOIA)',
    },
    {
      ariaLabel: 'Open the usa.gov website in a new tab',
      href: 'https://www.usa.gov/',
      title: 'USA.gov',
    },
    {
      ariaLabel: 'Open the Archives API documentation website in a new tab',
      href:
        SEARCH_HOST === 'http://localhost:5001'
          ? `http://localhost:5000/api/v2/api-docs/`
          : `${SEARCH_HOST}/api/v2/api-docs/`,
      title: 'API Documentation',
    },
  ]

  const secondaryLinks = [
    {
      ariaLabel: 'Open the Contact page on Archives.gov in a new tab',
      href: 'https://www.archives.gov/contact',
      title: 'Contact Us',
    },
    {
      ariaLabel: 'Open the Privacy page on Archives.gov in a new tab',
      href: 'https://www.archives.gov/global-pages/privacy.html',
      title: 'Privacy Policy',
    },
    {
      ariaLabel: 'Open the Accessibility page on Archives.gov in a new tab',
      href: 'https://www.archives.gov/global-pages/accessibility',
      title: 'Accessibility',
    },
  ]

  return (
    <div
      data-testid="nac-footer"
      id="nac-footer"
      size="slim"
      role="contentinfo"
      className={[
        'bg-primary-darker',
        'flex-column',
        'grid-col',
        'padding-1',
        'text-base-lighter',
        'theme-primary-darker',
      ].join(' ')}
    >
      <div className={['flex-justify', 'flex-row', 'grid-row'].join(' ')}>
        <Logo layout="vertical"></Logo>
        <div
          className={[
            'display-flex',
            'flex-column',
            'flex-gap-sm',
            'flex-justify-end',
            'font-sans-md',
            'grid-col-auto',
            'margin-right-2',
            'padding-left-4',
          ].join(' ')}
        >
          <NavLink
            className={['usa-link--white', 'font-sans-sm'].join(' ')}
            data-testid="nac-header--help"
            to="/help/faq/login"
          >
            Help with Login.gov
          </NavLink>
          {primaryLinks.map((link) => (
            <BasicLink
              className={['usa-link--white'].join(' ')}
              key={link.href}
              href={link.href}
            >
              {link.title}
            </BasicLink>
          ))}
        </div>
      </div>
      <div
        className={[
          'border-primary-dark',
          'border-top-1px',
          'grid-row',
          'margin-top-2',
          'padding-x-4',
          'padding-y-2',
          'text-base-lighter',
          'flex-align-center',
        ].join(' ')}
      >
        <BasicLink
          className={[
            'usa-link--white',
            'flex-column',
            'font-sans-2xs',
            'grid-col',
          ].join(' ')}
          key={`https://www.archives.gov/`}
          href={`https://www.archives.gov/`}
        >
          U.S. National Archives and Records Administration
        </BasicLink>
        <div
          className={[
            'flex-column',
            'flex-gap-sm',
            'flex-no-wrap',
            'grid-col-12',
            'tablet:grid-col-8',
          ].join(' ')}
        >
          <div
            className={[
              'tablet:display-flex',
              'flex-row',
              'flex-gap',
              'flex-justify-end',
              'flex-no-wrap',
              'font-sans-xs',
              'tablet:grid-row',
            ].join(' ')}
          >
            {secondaryLinks.map((link) => (
              <div key={link.href}>
                <BasicLink
                  className={['usa-link--white'].join(' ')}
                  href={link.href}
                >
                  {link.title}
                </BasicLink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
