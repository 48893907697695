import { useEffect, useState } from 'react'
import { DEFAULT_HIGHLIGHTER_STATE } from '../settings/globals'

/**
 * Works with the highlighter components and highlighter form fields
 * to determine whether the highlighter component should be used to modify the display
 * as well as to toggle the highlighter display on or off.
 */
const useHighlighter = () => {
  // States
  // If no option exists in session storage,
  // set the initial highlighter state to be the default
  const [displayHighlights, setDisplayHighlights] = useState(
    DEFAULT_HIGHLIGHTER_STATE
  )

  // Functions
  const setDisplayHighlightsHandler = () => {
    setDisplayHighlights((prev) => !prev)
  }

  useEffect(() => {
    if (sessionStorage.getItem('displayHighlights'))
      setDisplayHighlights(
        sessionStorage.getItem('displayHighlights') == 'true'
      )
  }, [])

  useEffect(() => {
    sessionStorage.setItem('displayHighlights', displayHighlights)
  }, [displayHighlights])

  return {
    displayHighlights,
    setDisplayHighlightsHandler,
  }
}
export default useHighlighter
