import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import PropTypes from 'prop-types'
import { returnIconAndLabelFromString } from '../../../helper-functions/metadata-functions'
import { Button } from '../../Button/Button'

const SelectedFilters = ({ handleUpdateValue, ...props }) => {
  const { search } = useLocation()
  const query = queryString.parse(search)

  const handleFilterClick = (filter, options, option) => {
    const newOptions = options
      .split(',')
      .filter((ele) => ele !== option)
      .toString()
    handleUpdateValue(filter, newOptions)
  }

  const [params, setParams] = useState({})

  useEffect(() => {
    updateParams()
  }, [search])

  const updateParams = () => {
    let nonFilterParams = ['q', 'sort', 'page', 'limit', 'availableOnline']

    // Filter out non-filter parameters and remove keys with empty string or undefined values
    let newParams = Object.keys(query)
      .filter((key) => !nonFilterParams.includes(key))
      .reduce((obj, key) => {
        if (query[key] !== '') {
          obj[key] = query[key]
        }
        return obj
      }, {})

    setParams(newParams || {})
  }

  if (Object.keys(params).length > 0) {
    return Object.entries(params).map(([key, values]) => (
      <div key={values}>
        {values.split(',').map((value) => {
          const valueLabel = returnIconAndLabelFromString(value, key)
          if (!valueLabel) {
            return null
          }
          return (
            <div key={value} className="margin-right-1 margin-bottom-05">
              <Button
                data-testid={`nac-selected-filtler--${key}-${value}`}
                iconName="close"
                iconSize="xs"
                iconPosition="right"
                reduced
                size="3xs"
                theme="primary-lighter"
                thin
                shape="pill"
                onClick={() => {
                  handleFilterClick(key, values, value)
                }}
                {...props}
              >
                <span className="font-sans-2xs line-height-sans-3">
                  <span className="text-base-dark margin-x-1">
                    {returnIconAndLabelFromString(key).label}
                  </span>
                  <span className="text-base-darker text-bold">
                    {Array.isArray(valueLabel)
                      ? valueLabel
                          .reduce((a, b) => [...a, b.label], [])
                          .join(', ')
                      : valueLabel.label}
                  </span>
                </span>
              </Button>
            </div>
          )
        })}
      </div>
    ))
  } else {
    return null
  }
}

SelectedFilters.propTypes = {
  handleUpdateValue: PropTypes.func.isRequired,
}

export default SelectedFilters
