import React, { useRef, useEffect } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import { Alert } from '../Alert/Alert'

const Video = ({ file, format, ...props }) => {
  // Trigger a load event for the video element whenever there's a new URL
  // https://stackoverflow.com/questions/41303012/updating-source-url-on-html5-video-with-react
  let url = file

  const isSupported = (format) => {
    return format === 'mp4'
  }

  const videoRef = useRef()
  const previousUrl = useRef(url)

  useEffect(() => {
    if (previousUrl.current === url) {
      return
    }
    if (videoRef.current) {
      videoRef.current.load()
    }
    previousUrl.current = url
  }, [url])
  return isSupported(format) ? (
    <video
      width="400"
      controls
      className="height-full width-auto"
      ref={videoRef}
      {...props}
    >
      <source src={file} type={`video/${format || 'mp4'}`} />
      Your browser does not support HTML5 video.
    </video>
  ) : (
    <Alert title="Unsupported file type" type="warning">
      The video is available, but cannot be played in the browser.
    </Alert>
  )
}

Video.defaultProps = {
  file: null,
  format: null,
}

Video.propTypes = {
  file: PropTypes.string,
  format: PropTypes.string,
}

export default Video
