import React from 'react'
import PrintDescriptionRecord from './description/PrintDescriptionRecord'
import PropTypes from 'prop-types'
import { useLocation, useSearchParams } from 'react-router-dom'

const FullDescription = ({ ...props }) => {
  const { state } = useLocation()
  const _query = window.location.search.match(/\d+/gi)
  const totalQuery = state ?? _query

  console.log(state)

  return (
    <div>
      {totalQuery?.length > 0 &&
        totalQuery.map((naid, idx) => {
          return <PrintDescriptionRecord naId={naid} key={idx} />
        })}
    </div>
  )
}

FullDescription.defaultProps = {}
FullDescription.propTypes = {
  naid: PropTypes.number,
}

export default React.memo(FullDescription)
