//pagination
import React, { useState, useRef, useEffect } from 'react'
import '../../styles/index.scss'
import PropTypes from 'prop-types'
import { Button } from '../Button/Button'
import { returnNumberWithCommas } from '../../helper-functions/number-functions'

const Pagination = ({
  handleNextObject,
  handlePrevObject,
  updateCurrentObject,
  current,
  total,
  loop,
  border,
  theme,
  label,
  iconOnly,
  id,
  navLabel,
  outline,
  textOnly,
  reduced,
  screenSize,
  ...props
}) => {
  const [pageInput, setPageInput] = useState(current || 1)
  const [currentPage, setCurrentPage] = useState(current)

  const didMountRef = useRef(false)
  useEffect(() => {
    if (didMountRef.current) {
      setPageInput(current)
    } else didMountRef.current = true
    setCurrentPage(current)
  }, [current])

  const handlePageInputSubmit = (event) => {
    event.preventDefault()
    let value = event?.target[0]?.value || event?.target?.value

    if (value && value > 0 && value <= total) {
      setPageInput(parseInt(value))
      updateCurrentObject(pageInput)
      setCurrentPage(value)
    } else if (!value) {
      setPageInput('')
    }
  }

  const handlePageInputChange = (event) => {
    event.preventDefault()
    let value = event?.target[0]?.value || event?.target?.value

    if (value && value > 0 && value <= total) {
      setPageInput(parseInt(value))
    } else if (!value) {
      setPageInput('')
    }
  }

  const handleClickPrev = () => {
    if (!loop) {
      if (current > 0) handlePrevObject()
    } else handlePrevObject()
  }

  const handleClickNext = () => {
    if (!loop) {
      if (current < total) handleNextObject()
    } else handleNextObject()
  }

  if (total > 0) {
    return (
      <div
        className={[
          'object-pagination__wrapper',
          `pagination-${theme}`,
          'flex-column',
          'grid-col-auto',
        ].join(' ')}
        data-testid={`nac-pagination-wrapper--${id}`}
      >
        <nav
          role="navigation"
          aria-label={navLabel}
          className={[
            'flex-align-center',
            iconOnly ? 'flex-gap-xs' : 'flex-gap',
            'flex-justify-center',
            'flex-no-wrap',
            'flex-row',
            'font-sans-xs',
            'grid-row',
          ].join(' ')}
        >
          <Button
            aria-label={`Go to page ${current - 1 <= 0 ? total : current - 1}`}
            data-testid={`nac-${id}--pagination-prev`}
            disabled={!loop && currentPage == 1}
            iconName="prev"
            iconOnly={iconOnly}
            iconSize="xs"
            onClick={() => handleClickPrev()}
            outline={outline}
            reduced={reduced}
            size="2xs"
            textOnly={textOnly}
            theme={theme}
          >
            Prev
          </Button>
          <form
            data-testid={`nac_form-pagination_${id}`}
            onSubmit={(e) => handlePageInputSubmit(e)}
          >
            <div
              className={[
                'flex-align-center',
                'flex-justify-center',
                'flex-no-wrap',
                'flex-row',
                'font-sans-sm',
                'grid-row',
                'line-height-sans-3',
                'text-base-lighter',
                'width-full minw-4',
              ].join(' ')}
            >
              <label
                className="usa-sr-only"
                htmlFor={`object-page-input_${id}`}
              >
                Jump to page number
              </label>
              <span
                className={[
                  'font-sans-xs',
                  'margin-right-1',
                  `text-${theme}`,
                ].join(' ')}
              >
                {label}
              </span>
              <input
                value={pageInput}
                onChange={(e) => handlePageInputChange(e)}
                min="1"
                max={total}
                type="number"
                id={`object-page-input_${id}`}
                data-testid={`nac-${id}--pagination-input`}
                name="object-page-input"
                className={[
                  'usa-input',
                  'bg-transparent',
                  'border',
                  'border-base-light',
                  'font-sans-xs',
                  'height-4',
                  'margin-right-05',
                  'margin-top-0',
                  'minw-4',
                  'pagination-input',
                  'padding-05',
                  'radius-md',
                  'text-right',
                  `text-${theme}`,
                  'width-auto',
                ].join(' ')}
              />
              <span
                className={[
                  'flex-none',
                  'font-sans-2xs',
                  'minw-4',
                  'text-center',
                  `text-${theme}`,
                  'width-auto',
                ].join(' ')}
              >
                of {returnNumberWithCommas(total)}
              </span>
            </div>
          </form>

          <Button
            aria-label={`Go to page ${
              current + 1 >= total ? '1' : current + 1
            }`}
            data-testid={`nac-${id}--pagination-next`}
            disabled={!loop && currentPage >= total}
            iconName="next"
            iconOnly={iconOnly}
            iconPosition="right"
            iconSize="xs"
            onClick={() => handleClickNext()}
            outline={outline}
            reduced={reduced}
            size="2xs"
            textOnly={textOnly}
            theme={theme}
          >
            Next
          </Button>
        </nav>
      </div>
    )
  } else {
    return false
  }
}

Pagination.defaultProps = {
  handleNextObject: () => {},
  handlePrevObject: () => {},
  updateCurrentObject: () => {},
  current: 0,
  total: 0,
  loop: false,
  border: false,
  theme: 'primary',
  label: '',
  id: '',
  navLabel: 'Navigation',
  outline: false,
  textOnly: false,
  reduced: false,
  screenSize: 'desktop',
}

Pagination.propTypes = {
  handleNextObject: PropTypes.func,
  handlePrevObject: PropTypes.func,
  updateCurrentObject: PropTypes.func,
  current: PropTypes.number,
  total: PropTypes.number,
  loop: PropTypes.bool,
  border: PropTypes.bool,
  theme: PropTypes.string,
  label: PropTypes.string,
  iconOnly: PropTypes.bool,
  id: PropTypes.string.isRequired,
  navLabel: PropTypes.string,
  outline: PropTypes.bool,
  textOnly: PropTypes.bool,
  reduced: PropTypes.bool,
  screenSize: PropTypes.oneOf(['desktop', 'tablet-lg', 'tablet', 'mobile']),
}
export default Pagination
