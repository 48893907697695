/**
 * Schema for validating moderation notes.
 * Used when a moderator deactivates, activates, flags, or unflags a contribution.
 */
export const moderateContributionsSchema = {
  autoComplete: 'off',
  id: 'addModerationNote',
  label: 'moderation note',
  maxlength: 250,
  placeholder: 'Additional notes',
  required: false,
  srText: 'Additional notes input field',
  type: 'text',
}
