import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import '../../../styles/index.scss'
import { errorList } from './errors.js'
import { IconLabel } from '../../IconLabel/IconLabel'
/**
 * Error pages describe system and API errors in a user-friendly way.
 * It is important that these pages relay the error status and message,
 * as well as describe the exact issue in laymans terms,
 * and provide actionable next steps for the user.
 *
 * TO DO: Make limit an environment variable so it can be more dynamic instead of hardcoded
 */
const ErrorPage = ({ error, ...props }) => {
  if (error.error) {
    error = error.error
  }
  //Create a state to hold the relevant error description from errorList
  const [err, setErr] = useState([])
  //True when screen size is equivalent to desktop-lg (1200px) or greater
  const [isDesktop, setIsDesktop] = useState(false)
  //True when screen size is equivalent to tablet (640px) or greater
  const [isTablet, setIsTablet] = useState(false)
  //True when screen size is less than tablet (640px)
  const [isMobile, setIsMobile] = useState(false)
  //Return the array of error messages described in the errorList comopnent
  const list = errorList(error)

  useEffect(() => {
    //Return new variable containing the errorList item which matches the provided error param, if applicable
    if (!error.id) error.id = 'DEFAULT'
    const filtered = list.filter((element) => {
      return element.id == error.id
    })
    if (filtered.length > 0) setErr(filtered)
    else setErr([list[0]])
  }, [error])

  useEffect(() => {
    handleSetSizes()
    window.addEventListener('resize', handleSetSizes)
    return () => window.removeEventListener('resize', handleSetSizes)
  }, [])

  const handleSetSizes = () => {
    if (window.innerWidth > 1200) {
      setIsDesktop(true)
      setIsTablet(false)
      setIsMobile(false)
    } else if (window.innerWidth > 640) {
      setIsDesktop(false)
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsDesktop(false)
      setIsTablet(false)
      setIsMobile(true)
    }
  }

  return (
    <main
      className={[
        'nac-body',
        //
        'bg-base-lighter',
      ].join(' ')}
    >
      {err &&
        err.map((item) => (
          <div
            key={item.title + error.status}
            className={[
              'error-page',
              'bg-white',
              'display-flex',
              'flex-column tablet:flex-row desktop-lg:flex-row',
              'tablet:flex-gap desktop-lg:flex-gap-lg',
              'height-tablet',
              'maxw-desktop',
              'width-full',
            ].join(' ')}
          >
            <div
              className={[
                'display-flex',
                'flex-column',
                'grid-col-12 tablet:grid-col-6',
              ].join(' ')}
            >
              {/** Error  Message and Content */}
              <div
                className={[
                  'content',
                  'order-1 tablet:order-0',
                  'padding-bottom-10 tablet:padding-bottom-2 desktop-lg:padding-bottom-2',
                  'padding-left-2 tablet:padding-left-3 desktop-lg:padding-left-5',
                  'padding-right-2 tablet:padding-right-2 desktop-lg:padding-right-4',
                  'padding-top-0 tablet:padding-top-6 desktop-lg:padding-top-8',
                  'white-space-pre-wrap',
                ].join(' ')}
              >
                <IconLabel
                  color="emergency"
                  data-testid="nac-error--status"
                  iconName="triangle-exclaim"
                  iconSize={`${isDesktop || isTablet ? 'lg' : 'md'}`}
                  strokeThickness={`${
                    isDesktop || isTablet ? 'thicker' : 'thick'
                  }`}
                  size={`${isDesktop || isTablet ? 'lg' : 'md'}`}
                  weight="bold"
                >
                  <span
                    className="usa-sr-only"
                    id="errorPageLabelStatus"
                  >{`Alert! There was an error with a status of ${
                    error.status || item.status
                  }, ${error.id || item.id}`}</span>
                  <strong aria-labelledby="errorPageLabelStatus" aria-hidden>
                    {error.status || item.status
                      ? `${error.status || item.status}: `
                      : ''}
                    {error.id || item.id}
                  </strong>
                </IconLabel>
                <h1
                  className={[
                    'page-title',
                    'padding-top-2',
                    'padding-bottom-4',
                  ].join(' ')}
                >
                  {item.title}
                </h1>
                <h2 className="page-subtitle padding-bottom-1">
                  {error.description || error.message || item.desc}
                </h2>
                {error.action && <p>{error.action}</p>}
                {item.action}
              </div>

              {/** Image Caption */}
              <section
                className={[
                  'error-caption',
                  'border-emergency tablet:border-emergency',
                  'border-top-05 tablet:border-right-05 tablet:border-top-0',
                  'margin-bottom-0 tablet:margin-bottom-6 desktop-lg:margin-bottom-10',
                  'margin-right-0 tablet:margin-right-4',
                  'margin-top-0 tablet:margin-top-3 desktop-lg:margin-top-8',
                  'order-0 tablet:order-1',
                  'width-full',
                ].join(' ')}
              >
                <div
                  className={[
                    'error-caption-text',
                    //
                    'display-flex',
                    'flex-column',
                    'flex-gap-sm',
                    'font-sans-3xs',
                    'text-base',
                    'padding-x-2',
                    'padding-y-1',
                    'text-right',
                  ].join(' ')}
                >
                  <span
                    className="usa-sr-only"
                    id="errorPageLabelCaption"
                  >{`This error page includes a decorative image from an archived
                    record. The caption for this image reads: ${item.imageContent.title}.
                    The National Archives Identifier for this record is ${item.imageContent.naId}.`}</span>
                  <div
                    aria-labelledby="errorPageLabelCaption"
                    aria-hidden
                    className={[
                      'ellipsed tablet:not-ellipsed',
                      'flex-align-self-end',
                      'font-sans-2xs',
                      'maxw-card-lg',
                      'text-base-dark',
                      'text-bold',
                      'text-italic',
                    ].join(' ')}
                  >
                    {item.imageContent.title}
                  </div>
                  <div
                    aria-labelledby="errorPageLabelCaption"
                    aria-hidden
                    className="flex-align-self-end maxw-card-lg text-bold"
                  >
                    NAID: {item.imageContent.naId}
                  </div>
                </div>
              </section>
            </div>

            {/** Image */}
            <section
              className={[
                'error-image',
                'block-load-anim',
                //
                'display-flex',
                'flex-column',
                'grid-col-12 tablet:grid-col-6',
                'height-card-lg tablet:height-auto',
                'order-first tablet:order-last',
                'padding-0',
              ].join(' ')}
            >
              <img
                src={item.imageContent.image}
                alt={`This image depicts ${item.imageContent.alt}`}
                className="object-fit-cover width-full height-full"
              />
            </section>
          </div>
        ))}
    </main>
  )
}

ErrorPage.defaultProps = {
  error: null,
}

ErrorPage.propTypes = {
  /** The error array object returned from the API or fetch method */
  error: PropTypes.object /*PropTypes.shape({
    status: PropTypes.number,
    title: PropTypes.string,
  }),*/,
}

export default ErrorPage

export const displayImages = [
  {
    alt: 'Interior of Building showing progress in construction of Handley-Page machine. Standard Aircraft Corporation, Elizabeth, New Jersey',
    id: 0,
    naId: 17340115,
    src: 'https://test.nac-mvp.archives.gov/iiif/3/opastorage%2Flive%2F15%2F3401%2F17340115%2Fcontent%2Fstillpix%2F165-WW_OPA_1st_Batch_533461%2FBOX-12%2FFOLDER-A%2F165-WW-12A-4.jpg/pct:30,5,65,70/max/0/default.jpg',
    title:
      'Airplanes - Manufacturing Plants - Aeroplane manufacture. Interior of Building showing progress in construction of Handley-Page machine. Standard Aircraft Corporation, Elizabeth, New Jersey',
  },
  {
    alt: 'A view of ROBART I, an autonomous sentry robot developed by Lieutenant Commander Bart Everett, at the Naval Surface Weapons Center',
    id: 1,
    naId: 6380551,
    src: 'https://test.nac-mvp.archives.gov/iiif/3/opastorage%2Flive%2F51%2F3805%2F6380551%2Fcontent%2Farcmedia%2Fstillpix%2F330-cfd%2F1984%2FDN-SN-84-01253.jpeg/pct:0,5,100,80/max/0/default.jpg',
    title:
      'A view of ROBART I, an autonomous sentry robot developed by Lieutenant Commander Bart Everett, at the Naval Surface Weapons Center',
  },
]
